import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ACCOUNTING_CONTRACTS,
  ACCOUNTING_CONTRACTS_ALL,
  ACCOUNTING_CONTRACTS_ASSIGN,
  ACCOUNTING_CONTRACTS_CREATE,
  ACCOUNTING_CONTRACTS_IMPORT,
  ACCOUNTING_CONTRACTS_LIST,
  ACCOUNTING_CONTRACTS_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'
import { createState } from '@src/helpers/createState'

export const ACCOUNTING_CONTRACTSS = GLOBAL_STATE

// export const accountingContractsImportReducer = createState(ACCOUNTING_CONTRACTS_IMPORT)

export const { reducer: accountingContractsReducer } = createSlice({
  name: ACCOUNTING_CONTRACTSS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.accountingContracts.pending, state => {
        state[ACCOUNTING_CONTRACTS].loading = true
      })
      .addCase(actionCreators.accountingContracts.rejected, (state, action) => {
        state[ACCOUNTING_CONTRACTS].loading = false
        state[ACCOUNTING_CONTRACTS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountingContracts.fulfilled, (state, action) => {
        state[ACCOUNTING_CONTRACTS].loading = false
        state[ACCOUNTING_CONTRACTS].error = null
        state[ACCOUNTING_CONTRACTS].data = action.payload
      })

    builder
      .addCase(actionCreators.accountingContractsAssign.pending, state => {
        state[ACCOUNTING_CONTRACTS_ASSIGN].loading = true
      })
      .addCase(actionCreators.accountingContractsAssign.rejected, (state, action) => {
        state[ACCOUNTING_CONTRACTS_ASSIGN].loading = false
        state[ACCOUNTING_CONTRACTS_ASSIGN].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountingContractsAssign.fulfilled, (state, action) => {
        state[ACCOUNTING_CONTRACTS_ASSIGN].loading = false
        state[ACCOUNTING_CONTRACTS_ASSIGN].error = null
        state[ACCOUNTING_CONTRACTS_ASSIGN].data = action.payload
      })

    // builder
    // .addCase(actionCreators.accountingContractsAll.pending, state => {
    //   state[ACCOUNTING_CONTRACTS_ALL].loading = true
    // })
    // .addCase(actionCreators.accountingContractsAll.rejected, (state, action) => {
    //   state[ACCOUNTING_CONTRACTS_ALL].loading = false
    //   state[ACCOUNTING_CONTRACTS_ALL].error = action.payload as AxiosError
    // })
    // .addCase(actionCreators.accountingContractsAll.fulfilled, (state, action) => {
    //   state[ACCOUNTING_CONTRACTS_ALL].loading = false
    //   state[ACCOUNTING_CONTRACTS_ALL].error = null
    //   state[ACCOUNTING_CONTRACTS_ALL].data = action.payload
    // })

    builder
      .addCase(actionCreators.accountingContractsCreate.pending, state => {
        state[ACCOUNTING_CONTRACTS_CREATE].loading = true
      })
      .addCase(actionCreators.accountingContractsCreate.rejected, (state, action) => {
        state[ACCOUNTING_CONTRACTS_CREATE].loading = false
        state[ACCOUNTING_CONTRACTS_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountingContractsCreate.fulfilled, (state, action) => {
        state[ACCOUNTING_CONTRACTS_CREATE].loading = false
        state[ACCOUNTING_CONTRACTS_CREATE].error = null
        state[ACCOUNTING_CONTRACTS_CREATE].data = action.payload
      })

    // builder
    // .addCase(actionCreators.accountingContractsImport.pending, state => {
    //   state[ACCOUNTING_CONTRACTS_IMPORT].loading = true
    // })
    // .addCase(actionCreators.accountingContractsImport.rejected, (state, action) => {
    //   state[ACCOUNTING_CONTRACTS_IMPORT].loading = false
    //   state[ACCOUNTING_CONTRACTS_IMPORT].error = action.payload as AxiosError
    // })
    // .addCase(actionCreators.accountingContractsImport.fulfilled, (state, action) => {
    //   state[ACCOUNTING_CONTRACTS_IMPORT].loading = false
    //   state[ACCOUNTING_CONTRACTS_IMPORT].error = null
    //   state[ACCOUNTING_CONTRACTS_IMPORT].data = action.payload
    // })

    builder
      .addCase(actionCreators.accountingContractsList.pending, state => {
        state[ACCOUNTING_CONTRACTS_LIST].loading = true
      })
      .addCase(actionCreators.accountingContractsList.rejected, (state, action) => {
        state[ACCOUNTING_CONTRACTS_LIST].loading = false
        state[ACCOUNTING_CONTRACTS_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountingContractsList.fulfilled, (state, action) => {
        state[ACCOUNTING_CONTRACTS_LIST].loading = false
        state[ACCOUNTING_CONTRACTS_LIST].error = null
        state[ACCOUNTING_CONTRACTS_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.accountingContractsTypes.pending, state => {
        state[ACCOUNTING_CONTRACTS_TYPES].loading = true
      })
      .addCase(actionCreators.accountingContractsTypes.rejected, (state, action) => {
        state[ACCOUNTING_CONTRACTS_TYPES].loading = false
        state[ACCOUNTING_CONTRACTS_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountingContractsTypes.fulfilled, (state, action) => {
        state[ACCOUNTING_CONTRACTS_TYPES].loading = false
        state[ACCOUNTING_CONTRACTS_TYPES].error = null
        state[ACCOUNTING_CONTRACTS_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.accountingContractsClear, state => {
      state[ACCOUNTING_CONTRACTS] = defaultState()
    })

    builder.addCase(actionCreators.accountingContractsListClear, state => {
      state[ACCOUNTING_CONTRACTS_LIST] = defaultState()
    })

    builder.addCase(actionCreators.accountingContractsTypesClear, state => {
      state[ACCOUNTING_CONTRACTS_TYPES] = defaultState()
    })
  },
})
