import { useState } from 'react'
import { dispatchActions } from '../helpers/dispatchActions'

export function useActionModal(
  onOpen = () => {},
  onClose = () => {},
  dispatchArgs = { actions: [], dispatch: () => {}, fetchParams: {} },
) {
  const [state, setState] = useState({
    state: false,
  })

  function onStateOpen() {
    onOpen()

    setState({
      state: true,
    })

    dispatchActions(dispatchArgs)
  }

  function onStateClose() {
    onClose()

    return setState({
      state: false,
    })
  }

  return [state, onStateOpen, onStateClose]
}
