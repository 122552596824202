import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { DEBIT_CREATE, DEBIT_DELETE, DEBIT_LIST, DEBIT_OWN } from '../constants/actionTypes'
import * as API from '../constants/api'

export const debitList = createAsyncThunk(
  DEBIT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.DEBIT}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.debitList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const debitOwn = createAsyncThunk(
  DEBIT_OWN,
  async (params: PayloadCreatorParams<`${typeof API.DEBIT_OWN}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.debitOwn(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const debitCreate = createAsyncThunk(
  DEBIT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.DEBIT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.debitCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const debitDelete = createAsyncThunk(
  DEBIT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.DEBIT}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.debitDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const debitListClear = createAction(`${DEBIT_LIST}/clear`)
