import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  COURSE_TRANSFER,
  COURSE_TRANSFER_COURSES,
  COURSE_TRANSFER_INFO,
  COURSE_TRANSFER_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const Selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const courseTransferSelector = createSelector(Selectors, prop(COURSE_TRANSFER))

export const courseTransferListSelector = createSelector(Selectors, prop(COURSE_TRANSFER_LIST))

export const courseTransferCoursesSelector = createSelector(Selectors, prop(COURSE_TRANSFER_COURSES))

export const courseTransferInfoSelector = createSelector(Selectors, prop(COURSE_TRANSFER_INFO))
