import { DefaultState } from '@src/redux/types'

import {
  GENERAL_APPLICATION,
  GENERAL_APPLICATION_ALL,
  GENERAL_APPLICATION_CREATE,
  GENERAL_APPLICATION_DELETE,
  GENERAL_APPLICATION_FIELD_BATCH,
  GENERAL_APPLICATION_FIELD_BATCH_CREATE,
  GENERAL_APPLICATION_LIST,
  GENERAL_APPLICATION_REQUEST,
  GENERAL_APPLICATION_REQUEST_EDIT,
  GENERAL_APPLICATION_STATUS,
  GENERAL_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [GENERAL_APPLICATION]: DefaultState<`${typeof API.GENERAL_APPLICATION_FORM}{id}`, 'get'>
  [GENERAL_APPLICATION_ALL]: DefaultState<`${typeof API.GENERAL_APPLICATION_ALL}`, 'get'>
  [GENERAL_APPLICATION_CREATE]: DefaultState<`${typeof API.GENERAL_APPLICATION_FORM}`, 'post'>
  [GENERAL_APPLICATION_DELETE]: DefaultState<`${typeof API.GENERAL_APPLICATION_FORM}{id}`, 'delete'>
  [GENERAL_APPLICATION_FIELD_BATCH]: DefaultState<`${typeof API.GENERAL_APPLICATION_FIELD_BATCH}{id}`, 'get'>
  [GENERAL_APPLICATION_FIELD_BATCH_CREATE]: DefaultState<`${typeof API.GENERAL_APPLICATION_FIELD_BATCH}`, 'post'>
  [GENERAL_APPLICATION_LIST]: DefaultState<`${typeof API.GENERAL_APPLICATION}`, 'get'>
  [GENERAL_APPLICATION_REQUEST]: DefaultState<`${typeof API.GENERAL_APPLICATION_REQUEST}`, 'post'>
  [GENERAL_APPLICATION_REQUEST_EDIT]: DefaultState<`${typeof API.GENERAL_APPLICATION_REQUEST_EDIT}`, 'get'>
  [GENERAL_APPLICATION_STATUS]: DefaultState<`${typeof API.GENERAL_APPLICATION_STATUS}`, 'put'>
  [GENERAL_APPLICATION_TYPES]: DefaultState<`${typeof API.GENERAL_APPLICATION_TYPES}`, 'get'>
} = () => ({
  [GENERAL_APPLICATION]: defaultState(),
  [GENERAL_APPLICATION_ALL]: defaultState(),
  [GENERAL_APPLICATION_CREATE]: defaultState(),
  [GENERAL_APPLICATION_DELETE]: defaultState(),
  [GENERAL_APPLICATION_FIELD_BATCH]: defaultState(),
  [GENERAL_APPLICATION_FIELD_BATCH_CREATE]: defaultState(),
  [GENERAL_APPLICATION_LIST]: defaultState(),
  [GENERAL_APPLICATION_REQUEST]: defaultState(),
  [GENERAL_APPLICATION_STATUS]: defaultState(),
  [GENERAL_APPLICATION_TYPES]: defaultState(),
  [GENERAL_APPLICATION_REQUEST_EDIT]: defaultState(),
})
