import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  ST_COURSE_APPLICATION,
  ST_COURSE_APPLICATION_ALL,
  ST_COURSE_APPLICATION_CREATE,
  ST_COURSE_APPLICATION_DELETE,
  ST_COURSE_APPLICATION_FIELD_BATCH,
  ST_COURSE_APPLICATION_FIELD_BATCH_CREATE,
  ST_COURSE_APPLICATION_LIST,
  ST_COURSE_APPLICATION_REQUEST,
  ST_COURSE_APPLICATION_REQUEST_ALL,
  ST_COURSE_APPLICATION_REQUEST_EDIT,
  ST_COURSE_APPLICATION_REQUEST_UPDATE,
  ST_COURSE_APPLICATION_STATUS,
  ST_COURSE_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ST_COURSE = GLOBAL_STATE

export const { reducer: stCourseReducer } = createSlice({
  name: ST_COURSE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.stCourseApplication.pending, state => {
        state[ST_COURSE_APPLICATION].loading = true
      })
      .addCase(actionCreators.stCourseApplication.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION].loading = false
        state[ST_COURSE_APPLICATION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplication.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION].loading = false
        state[ST_COURSE_APPLICATION].error = null
        state[ST_COURSE_APPLICATION].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationAll.pending, state => {
        state[ST_COURSE_APPLICATION_ALL].loading = true
      })
      .addCase(actionCreators.stCourseApplicationAll.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_ALL].loading = false
        state[ST_COURSE_APPLICATION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationAll.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_ALL].loading = false
        state[ST_COURSE_APPLICATION_ALL].error = null
        state[ST_COURSE_APPLICATION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationCreate.pending, state => {
        state[ST_COURSE_APPLICATION_CREATE].loading = true
      })
      .addCase(actionCreators.stCourseApplicationCreate.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_CREATE].loading = false
        state[ST_COURSE_APPLICATION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationCreate.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_CREATE].loading = false
        state[ST_COURSE_APPLICATION_CREATE].error = null
        state[ST_COURSE_APPLICATION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationDelete.pending, state => {
        state[ST_COURSE_APPLICATION_DELETE].loading = true
      })
      .addCase(actionCreators.stCourseApplicationDelete.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_DELETE].loading = false
        state[ST_COURSE_APPLICATION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationDelete.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_DELETE].loading = false
        state[ST_COURSE_APPLICATION_DELETE].error = null
        state[ST_COURSE_APPLICATION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationFieldBatch.pending, state => {
        state[ST_COURSE_APPLICATION_FIELD_BATCH].loading = true
      })
      .addCase(actionCreators.stCourseApplicationFieldBatch.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_FIELD_BATCH].loading = false
        state[ST_COURSE_APPLICATION_FIELD_BATCH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationFieldBatch.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_FIELD_BATCH].loading = false
        state[ST_COURSE_APPLICATION_FIELD_BATCH].error = null
        state[ST_COURSE_APPLICATION_FIELD_BATCH].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationFieldBatchCreate.pending, state => {
        state[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].loading = true
      })
      .addCase(actionCreators.stCourseApplicationFieldBatchCreate.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationFieldBatchCreate.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].error = null
        state[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationList.pending, state => {
        state[ST_COURSE_APPLICATION_LIST].loading = true
      })
      .addCase(actionCreators.stCourseApplicationList.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_LIST].loading = false
        state[ST_COURSE_APPLICATION_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationList.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_LIST].loading = false
        state[ST_COURSE_APPLICATION_LIST].error = null
        state[ST_COURSE_APPLICATION_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationRequest.pending, state => {
        state[ST_COURSE_APPLICATION_REQUEST].loading = true
      })
      .addCase(actionCreators.stCourseApplicationRequest.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST].loading = false
        state[ST_COURSE_APPLICATION_REQUEST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationRequest.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST].loading = false
        state[ST_COURSE_APPLICATION_REQUEST].error = null
        state[ST_COURSE_APPLICATION_REQUEST].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationRequestAll.pending, state => {
        state[ST_COURSE_APPLICATION_REQUEST_ALL].loading = true
      })
      .addCase(actionCreators.stCourseApplicationRequestAll.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST_ALL].loading = false
        state[ST_COURSE_APPLICATION_REQUEST_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationRequestAll.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST_ALL].loading = false
        state[ST_COURSE_APPLICATION_REQUEST_ALL].error = null
        state[ST_COURSE_APPLICATION_REQUEST_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationRequestEdit.pending, state => {
        state[ST_COURSE_APPLICATION_REQUEST_EDIT].loading = true
      })
      .addCase(actionCreators.stCourseApplicationRequestEdit.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST_EDIT].loading = false
        state[ST_COURSE_APPLICATION_REQUEST_EDIT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationRequestEdit.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST_EDIT].loading = false
        state[ST_COURSE_APPLICATION_REQUEST_EDIT].error = null
        state[ST_COURSE_APPLICATION_REQUEST_EDIT].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationRequestUpdate.pending, state => {
        state[ST_COURSE_APPLICATION_REQUEST_UPDATE].loading = true
      })
      .addCase(actionCreators.stCourseApplicationRequestUpdate.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST_UPDATE].loading = false
        state[ST_COURSE_APPLICATION_REQUEST_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationRequestUpdate.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_REQUEST_UPDATE].loading = false
        state[ST_COURSE_APPLICATION_REQUEST_UPDATE].error = null
        state[ST_COURSE_APPLICATION_REQUEST_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationChangeStatus.pending, state => {
        state[ST_COURSE_APPLICATION_STATUS].loading = true
      })
      .addCase(actionCreators.stCourseApplicationChangeStatus.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_STATUS].loading = false
        state[ST_COURSE_APPLICATION_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationChangeStatus.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_STATUS].loading = false
        state[ST_COURSE_APPLICATION_STATUS].error = null
        state[ST_COURSE_APPLICATION_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.stCourseApplicationTypes.pending, state => {
        state[ST_COURSE_APPLICATION_TYPES].loading = true
      })
      .addCase(actionCreators.stCourseApplicationTypes.rejected, (state, action) => {
        state[ST_COURSE_APPLICATION_TYPES].loading = false
        state[ST_COURSE_APPLICATION_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stCourseApplicationTypes.fulfilled, (state, action) => {
        state[ST_COURSE_APPLICATION_TYPES].loading = false
        state[ST_COURSE_APPLICATION_TYPES].error = null
        state[ST_COURSE_APPLICATION_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.stCourseApplicationClear, state => {
      state[ST_COURSE_APPLICATION] = defaultState()
    })

    builder.addCase(actionCreators.stCourseApplicationAllClear, state => {
      state[ST_COURSE_APPLICATION_ALL] = defaultState()
    })

    builder.addCase(actionCreators.stCourseApplicationFieldBatchClear, state => {
      state[ST_COURSE_APPLICATION_FIELD_BATCH] = defaultState()
    })

    builder.addCase(actionCreators.stCourseApplicationListClear, state => {
      state[ST_COURSE_APPLICATION_LIST] = defaultState()
    })

    builder.addCase(actionCreators.stCourseApplicationRequestEditClear, state => {
      state[ST_COURSE_APPLICATION_REQUEST_EDIT] = defaultState()
    })

    builder.addCase(actionCreators.stCourseApplicationRequestUpdateClear, state => {
      state[ST_COURSE_APPLICATION_REQUEST_UPDATE] = defaultState()
    })

    builder.addCase(actionCreators.stCourseApplicationTypesClear, state => {
      state[ST_COURSE_APPLICATION_TYPES] = defaultState()
    })
  },
})
