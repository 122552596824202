import { STORAGE_TOKEN } from '../constants/storage'

export const getStorage = local => (local ? localStorage : sessionStorage)

export const clearLocalStorage = () => {
  localStorage.clear()
  console.warn('localStorage has been cleared')
}

export const getToken = () => localStorage.getItem(STORAGE_TOKEN) || sessionStorage.getItem(STORAGE_TOKEN)

export const setToken = (token, local = true) => {
  const storage = getStorage(local)

  storage.setItem(STORAGE_TOKEN, token)
}

export const removeToken = () => {
  localStorage.removeItem(STORAGE_TOKEN)
  sessionStorage.removeItem(STORAGE_TOKEN)
}
export const removeUserInfo = () => {
  localStorage.removeItem('userInfo')
}
