import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  CONTRACT_AGREEMENT_ACCEPT,
  CONTRACT_AGREEMENT_ALL,
  CONTRACT_AGREEMENT_CURRENT,
  CONTRACT_AGREEMENT_OPEN,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const CONTRACT_AGREEMENT = GLOBAL_STATE

export const { reducer: contractAgreementReducer } = createSlice({
  name: CONTRACT_AGREEMENT,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.contractAgreementAccept.pending, state => {
        state[CONTRACT_AGREEMENT_ACCEPT].loading = true
      })
      .addCase(actionCreators.contractAgreementAccept.rejected, (state, action) => {
        state[CONTRACT_AGREEMENT_ACCEPT].loading = false
        state[CONTRACT_AGREEMENT_ACCEPT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.contractAgreementAccept.fulfilled, (state, action) => {
        state[CONTRACT_AGREEMENT_ACCEPT].loading = false
        state[CONTRACT_AGREEMENT_ACCEPT].error = null
        state[CONTRACT_AGREEMENT_ACCEPT].data = action.payload
      })

    builder
      .addCase(actionCreators.contractAgreementAll.pending, state => {
        state[CONTRACT_AGREEMENT_ALL].loading = true
      })
      .addCase(actionCreators.contractAgreementAll.rejected, (state, action) => {
        state[CONTRACT_AGREEMENT_ALL].loading = false
        state[CONTRACT_AGREEMENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.contractAgreementAll.fulfilled, (state, action) => {
        state[CONTRACT_AGREEMENT_ALL].loading = false
        state[CONTRACT_AGREEMENT_ALL].error = null
        state[CONTRACT_AGREEMENT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.contractAgreementCurrent.pending, state => {
        state[CONTRACT_AGREEMENT_CURRENT].loading = true
      })
      .addCase(actionCreators.contractAgreementCurrent.rejected, (state, action) => {
        state[CONTRACT_AGREEMENT_CURRENT].loading = false
        state[CONTRACT_AGREEMENT_CURRENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.contractAgreementCurrent.fulfilled, (state, action) => {
        state[CONTRACT_AGREEMENT_CURRENT].loading = false
        state[CONTRACT_AGREEMENT_CURRENT].error = null
        state[CONTRACT_AGREEMENT_CURRENT].data = action.payload
      })

    builder
      .addCase(actionCreators.contractAgreementOpen.pending, state => {
        state[CONTRACT_AGREEMENT_OPEN].loading = true
      })
      .addCase(actionCreators.contractAgreementOpen.rejected, (state, action) => {
        state[CONTRACT_AGREEMENT_OPEN].loading = false
        state[CONTRACT_AGREEMENT_OPEN].error = action.payload as AxiosError
      })
      .addCase(actionCreators.contractAgreementOpen.fulfilled, (state, action) => {
        state[CONTRACT_AGREEMENT_OPEN].loading = false
        state[CONTRACT_AGREEMENT_OPEN].error = null
        state[CONTRACT_AGREEMENT_OPEN].data = action.payload
      })

    builder.addCase(actionCreators.contractAgreementAllClear, state => {
      state[CONTRACT_AGREEMENT_ALL] = defaultState()
    })

    builder.addCase(actionCreators.contractAgreementCurrentClear, state => {
      state[CONTRACT_AGREEMENT_CURRENT] = defaultState()
    })

    builder.addCase(actionCreators.contractAgreementOpenClear, state => {
      state[CONTRACT_AGREEMENT_OPEN] = defaultState()
    })
  },
})
