import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  E_LIBRARY,
  E_LIBRARY_ALL,
  E_LIBRARY_CREATE,
  E_LIBRARY_DELETE,
  E_LIBRARY_LIST,
  E_LIBRARY_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const eLibrarySelector = createSelector(selectors, prop(E_LIBRARY))

export const eLibraryAllSelector = createSelector(selectors, prop(E_LIBRARY_ALL))

export const eLibraryCreateSelector = createSelector(selectors, prop(E_LIBRARY_CREATE))

export const eLibraryDeleteSelector = createSelector(selectors, prop(E_LIBRARY_DELETE))

export const eLibraryListSelector = createSelector(selectors, prop(E_LIBRARY_LIST))

export const eLibraryTypesSelector = createSelector(selectors, prop(E_LIBRARY_TYPES))
