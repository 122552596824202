import Avatar from '@components/avatar' 
import { Check } from 'react-feather'
import { useIntl } from 'react-intl'

const SuccessToast = ({ text = '' }) => {
  const { formatMessage: msg } = useIntl()

  return (
    <>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='success' icon={<Check size={12} />} />
          <h6 className='toast-title'>{msg({ id: 'success' })}!</h6>
        </div>
        {/*<small className='text-muted'>11 Min Ago</small>*/}
      </div>
      <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {msg({ id: text || 'success' })}
        </span>
      </div>
    </>
  )
}

export default SuccessToast
