import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const city: ApiFunc<`${typeof API.CITY}{id}`, 'get'> = data =>
  instance.get(`${API.CITY}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const cityCreate: ApiFunc<`${typeof API.CITY}`, 'post'> = data => instance.post(API.CITY, data)

export const cityDelete: ApiFunc<`${typeof API.CITY}{id}`, 'delete'> = data =>
  instance.delete(`${API.CITY}${'id' in data ? prop('id', data) : data.name}`)

export const cityList: ApiFunc<`${typeof API.CITY}`, 'get'> = data =>
  instance.get(API.CITY, {
    params: data,
  })

export const cityAll: ApiFunc<`${typeof API.CITY_ALL}`, 'get'> = data =>
  instance.get(API.CITY_ALL, {
    params: data,
  })
