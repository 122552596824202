import { DefaultState } from '@src/redux/types'

import {
  CALENDAR_EVENT,
  CALENDAR_EVENT_ALL,
  CALENDAR_EVENT_CREATE,
  CALENDAR_EVENT_DELETE,
  CALENDAR_EVENT_LIST,
  CALENDAR_EVENT_LIST_STUDENT,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [CALENDAR_EVENT]: DefaultState<`${typeof API.CALENDAR_EVENT}{id}`, 'get'>
  [CALENDAR_EVENT_ALL]: DefaultState<`${typeof API.CALENDAR_EVENT_ALL}`, 'get'>
  [CALENDAR_EVENT_CREATE]: DefaultState<`${typeof API.CALENDAR_EVENT}`, 'post'>
  [CALENDAR_EVENT_DELETE]: DefaultState<`${typeof API.CALENDAR_EVENT}{id}`, 'delete'>
  [CALENDAR_EVENT_LIST]: DefaultState<`${typeof API.CALENDAR_EVENT}`, 'get'>
  [CALENDAR_EVENT_LIST_STUDENT]: DefaultState<`${typeof API.CALENDAR_EVENT_STUDENT}`, 'get'>
} = () => ({
  [CALENDAR_EVENT]: defaultState(),
  [CALENDAR_EVENT_ALL]: defaultState(),
  [CALENDAR_EVENT_CREATE]: defaultState(),
  [CALENDAR_EVENT_DELETE]: defaultState(),
  [CALENDAR_EVENT_LIST]: defaultState(),
  [CALENDAR_EVENT_LIST_STUDENT]: defaultState(),
})
