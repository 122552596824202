import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  CALENDAR_EVENT,
  CALENDAR_EVENT_ALL,
  CALENDAR_EVENT_CREATE,
  CALENDAR_EVENT_LIST,
  CALENDAR_EVENT_LIST_STUDENT,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const calendarEventSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const calendarEventAllSelector = createSelector(calendarEventSelectors, prop(CALENDAR_EVENT_ALL))

export const calendarStudentEventAllSelector = createSelector(calendarEventSelectors, prop(CALENDAR_EVENT_LIST_STUDENT))

export const calendarEventSelector = createSelector(calendarEventSelectors, prop(CALENDAR_EVENT))

export const calendarEventCreateSelector = createSelector(calendarEventSelectors, prop(CALENDAR_EVENT_CREATE))

export const calendarEventListSelector = createSelector(calendarEventSelectors, prop(CALENDAR_EVENT_LIST))
