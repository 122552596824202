import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

function PageLoader({ children, loading }) {
  return (
    <Fragment>
      {loading && (
        <div className='text-center m-5'>
          <Spinner color='primary' />
        </div>
      )}

      {!loading && children}
    </Fragment>
  )
}

PageLoader.defaultProps = {
  loading: false,
}

PageLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.bool]),
  loading: PropTypes.bool,
}

export default PageLoader
