import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  INSTITUTION_PAGE_SETTINGS,
  INSTITUTION_PAGE_SETTINGS_ALL,
  INSTITUTION_PAGE_SETTINGS_CREATE,
  INSTITUTION_PAGE_SETTINGS_DELETE,
  INSTITUTION_PAGE_SETTINGS_IDENTITY,
  INSTITUTION_PAGE_SETTINGS_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const INSTITUTION = GLOBAL_STATE

export const { reducer: institutionPageSettingsReducer } = createSlice({
  name: INSTITUTION,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.institutionPageSettings.pending, state => {
        state[INSTITUTION_PAGE_SETTINGS].loading = true
      })
      .addCase(actionCreators.institutionPageSettings.rejected, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS].loading = false
        state[INSTITUTION_PAGE_SETTINGS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.institutionPageSettings.fulfilled, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS].loading = false
        state[INSTITUTION_PAGE_SETTINGS].error = null
        state[INSTITUTION_PAGE_SETTINGS].data = action.payload
      })

    builder
      .addCase(actionCreators.institutionPageSettingsAll.pending, state => {
        state[INSTITUTION_PAGE_SETTINGS_ALL].loading = true
      })
      .addCase(actionCreators.institutionPageSettingsAll.rejected, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_ALL].loading = false
        state[INSTITUTION_PAGE_SETTINGS_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.institutionPageSettingsAll.fulfilled, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_ALL].loading = false
        state[INSTITUTION_PAGE_SETTINGS_ALL].error = null
        state[INSTITUTION_PAGE_SETTINGS_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.institutionPageSettingsCreate.pending, state => {
        state[INSTITUTION_PAGE_SETTINGS_CREATE].loading = true
      })
      .addCase(actionCreators.institutionPageSettingsCreate.rejected, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_CREATE].loading = false
        state[INSTITUTION_PAGE_SETTINGS_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.institutionPageSettingsCreate.fulfilled, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_CREATE].loading = false
        state[INSTITUTION_PAGE_SETTINGS_CREATE].error = null
        state[INSTITUTION_PAGE_SETTINGS_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.institutionPageSettingsDelete.pending, state => {
        state[INSTITUTION_PAGE_SETTINGS_DELETE].loading = true
      })
      .addCase(actionCreators.institutionPageSettingsDelete.rejected, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_DELETE].loading = false
        state[INSTITUTION_PAGE_SETTINGS_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.institutionPageSettingsDelete.fulfilled, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_DELETE].loading = false
        state[INSTITUTION_PAGE_SETTINGS_DELETE].error = null
        state[INSTITUTION_PAGE_SETTINGS_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.institutionPageSettingsIdentity.pending, state => {
        state[INSTITUTION_PAGE_SETTINGS_IDENTITY].loading = true
      })
      .addCase(actionCreators.institutionPageSettingsIdentity.rejected, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_IDENTITY].loading = false
        state[INSTITUTION_PAGE_SETTINGS_IDENTITY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.institutionPageSettingsIdentity.fulfilled, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_IDENTITY].loading = false
        state[INSTITUTION_PAGE_SETTINGS_IDENTITY].error = null
        state[INSTITUTION_PAGE_SETTINGS_IDENTITY].data = action.payload
      })

    builder
      .addCase(actionCreators.institutionPageSettingsList.pending, state => {
        state[INSTITUTION_PAGE_SETTINGS_LIST].loading = true
      })
      .addCase(actionCreators.institutionPageSettingsList.rejected, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_LIST].loading = false
        state[INSTITUTION_PAGE_SETTINGS_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.institutionPageSettingsList.fulfilled, (state, action) => {
        state[INSTITUTION_PAGE_SETTINGS_LIST].loading = false
        state[INSTITUTION_PAGE_SETTINGS_LIST].error = null
        state[INSTITUTION_PAGE_SETTINGS_LIST].data = action.payload
      })

    builder.addCase(actionCreators.institutionPageSettingsClear, state => {
      state[INSTITUTION_PAGE_SETTINGS] = defaultState()
    })

    builder.addCase(actionCreators.institutionPageSettingsAllClear, state => {
      state[INSTITUTION_PAGE_SETTINGS_ALL] = defaultState()
    })

    builder.addCase(actionCreators.institutionPageSettingsIdentityClear, state => {
      state[INSTITUTION_PAGE_SETTINGS_IDENTITY] = defaultState()
    })

    builder.addCase(actionCreators.institutionPageSettingsListClear, state => {
      state[INSTITUTION_PAGE_SETTINGS_LIST] = defaultState()
    })
  },
})
