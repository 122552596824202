import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const lesson: ApiFunc<`${typeof API.LESSON}{id}`, 'get'> = data =>
  instance.get(`${API.LESSON}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const lessonCreate: ApiFunc<`${typeof API.LESSON}`, 'post'> = data => instance.post(API.LESSON, data)

export const lessonHomeworkUpload: ApiFunc<`${typeof API.LESSON_HOMEWORK_UPLOAD}`, 'post'> = data =>
  instance.post(API.LESSON_HOMEWORK_UPLOAD, data)

export const lessonSorting: ApiFunc<`${typeof API.LESSON_SORTING}`, 'put'> = data =>
  instance.put(API.LESSON_SORTING, data)

export const lessonDelete: ApiFunc<`${typeof API.LESSON}{id}`, 'delete'> = data =>
  instance.delete(`${API.LESSON}${'id' in data ? prop('id', data) : data?.name}`)

export const lessonList: ApiFunc<`${typeof API.LESSON}`, 'get'> = data =>
  instance.get(API.LESSON, {
    params: data,
  })

export const lessonAll: ApiFunc<`${typeof API.LESSON_ALL}`, 'get'> = data =>
  instance.get(API.LESSON_ALL, {
    params: data,
  })

export const lessonAssessment: ApiFunc<`${typeof API.LESSON_ASSESSMENT}{id}`, 'get'> = data => {
  return instance.get(`${API.LESSON_ASSESSMENT}${'id' in data ? prop('id', data) : data?.name}`, {
    params: data,
  })
}

export const lessonAssessmentAll: ApiFunc<`${typeof API.LESSON_ASSESSMENT_ALL}`, 'get'> = data => {
  return instance.get(API.LESSON_ASSESSMENT_ALL, {
    params: data,
  })
}

export const lessonMarkAssessment: ApiFunc<`${typeof API.LESSON_ASSESSMENT}`, 'post'> = data =>
  instance.post(API.LESSON_ASSESSMENT, data)

export const lessonArticulateCreate: ApiFunc<`${typeof API.LESSON_ARTICULATE}`, 'post'> = data =>
  instance.post(API.LESSON_ARTICULATE, data)

// export const lessonArticulate: ApiFunc<`${typeof API.LESSON_ARTICULATE}{id}`, 'get'> = data =>
//   instance.get(`${API.LESSON_ARTICULATE}${'id' in data ? data.id : ''}`, {
//     params: data,
//   })

export const lessonArticulateAll: ApiFunc<`${typeof API.LESSON_ARTICULATE_ALL}{cclsModuleId}`, 'get'> = data =>
  instance.get(`${API.LESSON_ARTICULATE_ALL}${'cclsModuleId' in data ? data.cclsModuleId : ''}`, {
    params: data,
  })

export const lessonArticulateProgress: ApiFunc<`${typeof API.LESSON_ARTICULATE_PROGRESS}`, 'post'> = data =>
  instance.post(API.LESSON_ARTICULATE_PROGRESS, data)

export const lessonArticulateDelete: ApiFunc<`${typeof API.LESSON_ARTICULATE}{id}`, 'delete'> = data =>
  instance.delete(`${API.LESSON}${'id' in data ? prop('id', data) : data?.name}`)
