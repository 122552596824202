import { DefaultState } from '@src/redux/types'

import { CITY, CITY_ALL, CITY_CREATE, CITY_DELETE, CITY_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [CITY]: DefaultState<`${typeof API.CITY}{id}`, 'get'>
  [CITY_ALL]: DefaultState<`${typeof API.CITY_ALL}`, 'get'>
  [CITY_CREATE]: DefaultState<`${typeof API.CITY}`, 'post'>
  [CITY_DELETE]: DefaultState<`${typeof API.CITY}{id}`, 'delete'>
  [CITY_LIST]: DefaultState<`${typeof API.CITY}`, 'get'>
} = () => ({
  [CITY]: defaultState(),
  [CITY_ALL]: defaultState(),
  [CITY_CREATE]: defaultState(),
  [CITY_DELETE]: defaultState(),
  [CITY_LIST]: defaultState(),
})
