import { propOr } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import { AvField } from 'availity-reactstrap-validation-safe'
import { Col, FormGroup, FormText, Label } from 'reactstrap'
import classNames from 'classnames'

const AvInputField = React.forwardRef((props, ref) => {
  const {
    placeholder,
    label,
    type,
    autoComplete,
    onKeyPress,
    min,
    max,
    customError,
    row,

    handleChange = () => {},
    register,
    value,
    onChange,
    name,
    touched,
    error,
    isRequired = false,
    disabled,
  } = props

  const smLabel = propOr(12, 'label', row)
  const smInput = propOr(12, 'input', row)

  function change(e) {
    onChange(e)
    handleChange(e)
  }

  return (
    <FormGroup row={Boolean(row)}>
      {label && (
        <Label for='select_field' sm={smLabel}>
          {label}
        </Label>
      )}

      <Col sm={smInput}>
        <AvField
          {...register(name)}
          value={value}
          onChange={change}
          name={name}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          type={type}
          min={min}
          max={max}
          disabled={disabled}
          autoComplete={autoComplete}
          innerRef={ref}
          className={classNames((touched && error) || (touched && customError) ? 'is-invalid' : '')}
        />
      </Col>

      {(touched && error) || (touched && customError) ? (
        <FormText color='primary'>{error || customError}</FormText>
      ) : null}
    </FormGroup>
  )
})

AvInputField.defaultProps = {
  type: 'text',
  required: false,
}

AvInputField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  onKeyPress: PropTypes.func,
  required: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  customOnChange: PropTypes.func,
  customError: PropTypes.string,
  row: PropTypes.object,
  handleChange: PropTypes.func,
  register: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.object,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default AvInputField
