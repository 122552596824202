/* eslint-disable */
import { forEach, isEmpty, prop, propOr } from 'ramda'
import { Dispatch } from 'redux'

type actionObjType = {
  action: () => Object;
  params: Object;
}

type dispatchArgsType = {
  actions: actionObjType[];
  dispatch: Dispatch;
  fetchParams: Object;
}

export function dispatchActions(dispatchArgs: dispatchArgsType): void {
  const { dispatch } = dispatchArgs
  !isEmpty(prop('actions', dispatchArgs)) &&
  forEach((actionObj = {}) => {
    const action: Function = propOr(() => {}, 'action', actionObj)
    const params: Object = propOr({}, 'params', actionObj)
    dispatch(action(params))
  })(prop('actions', dispatchArgs))
}
