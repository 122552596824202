import { API_URL } from '@src/constants/api'
import { ApiFunc, getInstance } from '@src/helpers/httpClient'
import { getToken } from '@src/helpers/storageToken'
import axios from 'axios'

import * as API from '../constants/api'

const instance = getInstance()

export const paymentsList: ApiFunc<`${typeof API.PAYMENTS}`, 'get'> = data =>
  instance.get(API.PAYMENTS, {
    params: data,
  })

export const paymentsCreate: ApiFunc<`${typeof API.PAYMENTS}`, 'post'> = data => instance.post(API.PAYMENTS, data)

export const paymentsImport: ApiFunc<`${typeof API.PAYMENTS_IMPORT}`, 'post'> = data => {
  return axios.request({
    method: 'POST',
    url: `${API_URL}${API.PAYMENTS_IMPORT}`,
    data: (data as unknown as { formData: FormData }).formData,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      institution: '1',
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const paymentsFineImport: ApiFunc<`${typeof API.PAYMENTS_FINE_IMPORT}`, 'post'> = data => {
  return axios.request({
    method: 'POST',
    url: `${API_URL}${API.PAYMENTS_FINE_IMPORT}`,
    data: (data as unknown as { formData: FormData }).formData,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      institution: '1',
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const paymentsDelete: ApiFunc<`${typeof API.PAYMENTS}{id}`, 'delete'> = data =>
  instance.delete(`${API.PAYMENTS}${'id' in data ? data.id : ''}`)
