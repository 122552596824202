import * as roles from '@constants/userRoles'
import { lazy } from 'react'

import { ROLES } from '../constants/route'

export const RolesRoutes = [
  {
    path: ROLES,
    component: lazy(() => import('../views/Roles')),
    meta: {
      access: [roles.SUPER_ADMIN, roles.DOCUMENT_MANAGEMENT_MODERATOR],
    },
  },
]
