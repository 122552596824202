import { lazy } from 'react'
import { LIBRARY } from '../constants/route'
import * as ROLES from '@constants/userRoles'

export const LibraryRoutes = [
  {
    path: LIBRARY,
    component: lazy(() => import('../views/Library')),
    exact: true,
    className: 'email-application',
    appLayout: true,
    // meta: {
    //   access: [ROLES.SUPER_ADMIN],
    // },
  },
]
