import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { INTRO_VIDEOS } from '../constants/actionTypes'
import * as API from '../constants/api'

export const introVideos = createAsyncThunk(
  INTRO_VIDEOS,
  async (params: PayloadCreatorParams<`${typeof API.VIDEO_TUTORIAL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.videoTutorial(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const introVideosClear = createAction(`${INTRO_VIDEOS}/clear`)
