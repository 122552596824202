export const GLOBAL_STATE = 'AssessmentOffence'

export const OFFENCE = 'OFFENCE' as const
export const OFFENCE_LIST = 'OFFENCE_LIST' as const
export const OFFENCE_CREATE = 'OFFENCE_CREATE' as const
export const OFFENCE_ALL = 'OFFENCE_ALL' as const
export const OFFENCE_DELETE = 'OFFENCE_DELETE' as const
export const OFFENCE_INVIGILATION = 'OFFENCE_INVIGILATION' as const
export const OFFENCE_INVIGILATION_CREATE = 'OFFENCE_INVIGILATION_CREATE' as const
export const OFFENCE_INVIGILATION_ALL = 'OFFENCE_INVIGILATION_ALL' as const
export const OFFENCE_INVIGILATION_DELETE = 'OFFENCE_INVIGILATION_DELETE' as const
export const OFFENCE_INVIGILATION_CONSULTATIONS = 'OFFENCE_INVIGILATION_CONSULTATIONS' as const
export const OFFENCE_INVIGILATION_CONSULTATIONS_CREATE = 'OFFENCE_INVIGILATION_CONSULTATIONS_CREATE' as const
export const OFFENCE_INVIGILATION_CONSULTATIONS_ALL = 'OFFENCE_INVIGILATION_CONSULTATIONS_ALL' as const
export const OFFENCE_INVIGILATION_CONSULTATIONS_DELETE = 'OFFENCE_INVIGILATION_CONSULTATIONS_DELETE' as const
export const OFFENCE_INVIGILATION_MINUTES = 'OFFENCE_INVIGILATION_MINUTES' as const
export const OFFENCE_INVIGILATION_MINUTES_CREATE = 'OFFENCE_INVIGILATION_MINUTES_CREATE' as const
export const OFFENCE_INVIGILATION_MINUTES_ALL = 'OFFENCE_INVIGILATION_MINUTES_ALL' as const
export const OFFENCE_INVIGILATION_MINUTES_DELETE = 'OFFENCE_INVIGILATION_MINUTES_DELETE' as const
export const OFFENCE_TYPE = 'OFFENCE_TYPE' as const
export const OFFENCE_TYPE_CREATE = 'OFFENCE_TYPE_CREATE' as const
export const OFFENCE_TYPE_ALL = 'OFFENCE_TYPE_ALL' as const
export const OFFENCE_TYPE_DELETE = 'OFFENCE_TYPE_DELETE' as const
export const OFFENCE_REPORT_LIST = 'OFFENCE_REPORT_LIST' as const
export const OFFENCE_REPORT_ALL = 'OFFENCE_REPORT_ALL' as const
export const OFFENCE_REPORT_CREATE = 'OFFENCE_REPORT_CREATE' as const
export const OFFENCE_CHANGE_STATUS = 'OFFENCE_CHANGE_STATUS' as const
export const OFFENCE_CHANGE_SUBJECT_BOARD_STATUS = 'OFFENCE_CHANGE_SUBJECT_BOARD_STATUS' as const
export const OFFENCE_PUBLISH = 'OFFENCE_PUBLISH' as const
export const OFFENCE_UNPUBLISH = 'OFFENCE_UNPUBLISH' as const
export const REPORT_MISCONDUCT = 'REPORT_MISCONDUCT' as const
