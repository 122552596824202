import { lazy } from 'react'
import { ACHIEVEMENT_RECORDS, ACHIEVEMENT_RECORDS_INFO } from '../constants/route'

import {
  AR_COMMITTEE,
  MODERATOR_VIEWER,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_HELPER,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@constants/userRoles'

export const AchievementRecordsRoutes = [
  {
    path: ACHIEVEMENT_RECORDS,
    component: lazy(() => import('../views/AchievementRecordsPage')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, MODERATOR_VIEWER, AR_COMMITTEE, REGISTRAR_HELPER],
    },
  },
  {
    path: ACHIEVEMENT_RECORDS_INFO,
    component: lazy(() => import('../components/AchievementRecordsInfo')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, TEST_STUDENT, REGISTRAR_HEAD, REGISTRAR_OFFICER, MODERATOR_VIEWER, AR_COMMITTEE, REGISTRAR_HELPER],
    },
  },
]
