import { isNil } from 'ramda'

import { STORAGE_INSTITUTION, STORAGE_SELECTED_INSTITUTION } from '../constants/storage'

export const getInstitution = () => {
  try {
    const institution = localStorage.getItem(STORAGE_INSTITUTION)
    if (!isNil(institution)) {
      return JSON.parse(institution)
    }
  } catch (e) {
    console.error('Could not retrieve institution info: ', e)
  }
  return null
}

export const getInstitutionOption = () => {
  try {
    const selectedInstitution = localStorage.getItem(STORAGE_SELECTED_INSTITUTION)
    if (selectedInstitution) {
      return JSON.parse(selectedInstitution)
    }
  } catch (e) {
    console.error('Could not retrieve selected institution: ', e)
  }
  return null
}

export const setInstitutionOption = institution => {
  localStorage.setItem(STORAGE_SELECTED_INSTITUTION, institution)
}

export const removeInstitutionOption = () => {
  localStorage.removeItem(STORAGE_SELECTED_INSTITUTION)
}

export const setInstitutionId = param => {
  localStorage.setItem(STORAGE_INSTITUTION, param)
}

export const removeInstitution = () => {
  localStorage.removeItem(STORAGE_INSTITUTION)
}
