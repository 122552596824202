export const GLOBAL_STATE = 'StudentCV'

export const STUDENT_CV = 'STUDENT_CV' as const
export const STUDENT_CV_PRINCIPAL = 'STUDENT_CV_PRINCIPAL' as const
export const STUDENT_CV_CREATE = 'STUDENT_CV_CREATE' as const
export const STUDENT_CV_LIST = 'STUDENT_CV_LIST' as const
export const STUDENT_CV_CURATOR = 'STUDENT_CV_CURATOR' as const
export const STUDENT_CV_HARD_SKILLS = 'STUDENT_CV_HARD_SKILLS' as const
export const STUDENT_CV_SOFT_SKILLS = 'STUDENT_CV_SOFT_SKILLS' as const
export const STUDENT_CV_COMMENTS = 'STUDENT_CV_COMMENTS' as const
export const STUDENT_CV_GENERATE_OBJECTIVE = 'STUDENT_CV_GENERATE_OBJECTIVE' as const
export const STUDENT_CV_GENERATE_CV = 'STUDENT_CV_GENERATE_CV' as const
export const STUDENT_CV_CHANGE_STATUS = 'STUDENT_CV_CHANGE_STATUS' as const
