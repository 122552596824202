import { RootState } from '@src/redux/store'
import { pathOr, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  LESSON,
  LESSON_ALL,
  LESSON_ARTICULATE,
  LESSON_ARTICULATE_ALL,
  LESSON_ARTICULATE_CREATE,
  LESSON_ARTICULATE_DELETE,
  LESSON_ASSESSMENT,
  LESSON_ASSESSMENT_ALL,
  LESSON_CREATE,
  LESSON_HOMEWORK_UPLOAD,
  LESSON_LIST,
  LESSON_MARK_ASSESSMENT,
} from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const lessonSelector = createSelector(selectors, prop(LESSON))

export const lessonListSelector = createSelector(selectors, prop(LESSON_LIST))

export const lessonAllSelector = createSelector(selectors, prop(LESSON_ALL))

export const lessonCreateSelector = createSelector(selectors, prop(LESSON_CREATE))

export const lessonHomeworkUploadSelector = createSelector(selectors, prop(LESSON_HOMEWORK_UPLOAD))

export const lessonAssessmentSelector = createSelector(selectors, prop(LESSON_ASSESSMENT))

export const lessonAssessmentAllSelector = createSelector(selectors, prop(LESSON_ASSESSMENT_ALL))

export const lessonMarkAssessmentSelector = createSelector(selectors, prop(LESSON_MARK_ASSESSMENT))

export const lessonAllOptionsSelector = createSelector(lessonAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({
          label: `${propOr('', 'name', item)} (${pathOr('', ['calendar_event_info', 'title'], item)})`,
          value: prop('id', item),
          children: Array.isArray(item.children)
            ? item.children.map(child => ({
                label: pathOr('', ['type', 'title'], child),
                value: prop('id', child),
              }))
            : [],
        }))
      : [],
  }
})

export const lessonArticulateAllSelector = createSelector(selectors, data => data[LESSON_ARTICULATE_ALL])

// export const lessonArticulateSelector = createSelector(selectors, data => data[LESSON_ARTICULATE])

export const lessonArticulateCreateSelector = createSelector(selectors, data => data[LESSON_ARTICULATE_CREATE])

export const lessonArticulateDeleteSelector = createSelector(selectors, data => data[LESSON_ARTICULATE_DELETE])
