import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const country: ApiFunc<`${typeof API.COUNTRY}{id}`, 'get'> = data =>
  instance.get(`${API.COUNTRY}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const countryCreate: ApiFunc<`${typeof API.COUNTRY}`, 'post'> = data => instance.post(API.COUNTRY, data)

export const countryDelete: ApiFunc<`${typeof API.COUNTRY}{id}`, 'delete'> = data =>
  instance.delete(`${API.COUNTRY}${'id' in data ? prop('id', data) : data.name}`)

export const countryList: ApiFunc<`${typeof API.COUNTRY}`, 'get'> = data =>
  instance.get(API.COUNTRY, {
    params: data,
  })

export const countryAll: ApiFunc<`${typeof API.COUNTRY_ALL}`, 'get'> = data =>
  instance.get(API.COUNTRY_ALL, {
    params: data,
  })
