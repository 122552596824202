import { DefaultState } from '@src/redux/types'

import { STAFF, STAFF_ALL, STAFF_DELETE, STAFF_LIST, STAFF_USERNAME } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [STAFF]: DefaultState<`${typeof API.STAFF}{id}`, 'get'>
  [STAFF_ALL]: DefaultState<`${typeof API.STAFF_ALL}`, 'get'>
  [STAFF_DELETE]: DefaultState<`${typeof API.STAFF}{id}`, 'delete'>
  [STAFF_LIST]: DefaultState<`${typeof API.STAFF}`, 'get'>
  [STAFF_USERNAME]: DefaultState<`${typeof API.STAFF_USERNAME}{username}`, 'get'>
} = () => ({
  [STAFF]: defaultState(),
  [STAFF_ALL]: defaultState(),
  [STAFF_DELETE]: defaultState(),
  [STAFF_LIST]: defaultState(),
  [STAFF_USERNAME]: defaultState(),
})
