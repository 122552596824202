import { DefaultState } from '@src/redux/types'

import { JOB_OFFER_CREATE, JOB_OFFER_LIST, JOB_OFFER_STUDENT_ALL } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [JOB_OFFER_CREATE]: DefaultState<`${typeof API.JOB_OFFER}`, 'post'>
  [JOB_OFFER_LIST]: DefaultState<`${typeof API.JOB_OFFER}`, 'get'>
  [JOB_OFFER_STUDENT_ALL]: DefaultState<`${typeof API.JOB_OFFER_STUDENT_ALL}`, 'get'>
} = () => ({
  [JOB_OFFER_CREATE]: defaultState(),
  [JOB_OFFER_LIST]: defaultState(),
  [JOB_OFFER_STUDENT_ALL]: defaultState(),
})
