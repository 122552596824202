import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const role: ApiFunc<`${typeof API.ROLE}{id}`, 'get'> = data =>
  instance.get(`${API.ROLE}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const roleCreate: ApiFunc<`${typeof API.ROLE}`, 'post'> = data => instance.post(API.ROLE, data)

export const roleDelete: ApiFunc<`${typeof API.ROLE}{id}`, 'delete'> = data =>
  instance.delete(`${API.ROLE}${'id' in data ? prop('id', data) : data?.name}`)

export const roleList: ApiFunc<`${typeof API.ROLE}`, 'get'> = data =>
  instance.get(API.ROLE, {
    params: data,
  })

export const roleAll: ApiFunc<`${typeof API.ROLE_ALL}`, 'get'> = data =>
  instance.get(API.ROLE_ALL, {
    params: data,
  })
