import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const course: ApiFunc<`${typeof API.COURSE}{id}`, 'get'> = data =>
  instance.get(`${API.COURSE}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const courseCreate: ApiFunc<`${typeof API.COURSE}`, 'post'> = data => instance.post(API.COURSE, data)

export const courseDelete: ApiFunc<`${typeof API.COURSE}{id}`, 'delete'> = data =>
  instance.delete(`${API.COURSE}${'id' in data ? prop('id', data) : data.name}`)

export const courseList: ApiFunc<`${typeof API.COURSE}`, 'get'> = data =>
  instance.get(API.COURSE, {
    params: data,
  })

export const courseAll: ApiFunc<`${typeof API.COURSE_ALL}`, 'get'> = data =>
  instance.get(API.COURSE_ALL, {
    params: data,
  })
