export const GLOBAL_STATE = 'StCourseAdmission'

export const ST_COURSE_APPLICATION = 'ST_COURSE_APPLICATION' as const
// export const ST_COURSE_APPLICATIONS = 'ST_COURSE_APPLICATIONS' as const
export const ST_COURSE_APPLICATION_CREATE = 'ST_COURSE_APPLICATION_CREATE' as const
export const ST_COURSE_APPLICATION_DELETE = 'ST_COURSE_APPLICATION_DELETE' as const
export const ST_COURSE_APPLICATION_LIST = 'ST_COURSE_APPLICATION_LIST' as const
export const ST_COURSE_APPLICATION_ALL = 'ST_COURSE_APPLICATION_ALL' as const
export const ST_COURSE_APPLICATION_TYPES = 'ST_COURSE_APPLICATION_TYPES' as const
export const ST_COURSE_APPLICATION_STATUS = 'ST_COURSE_APPLICATION_STATUS' as const

export const ST_COURSE_APPLICATION_FIELD_BATCH = 'ST_COURSE_APPLICATION_FIELD_BATCH' as const
export const ST_COURSE_APPLICATION_FIELD_BATCH_CREATE = 'ST_COURSE_APPLICATION_FIELD_BATCH_CREATE' as const

// category
// export const ST_COURSE_APPLICATION_CATEGORY = 'ST_COURSE_APPLICATION_CATEGORY' as const
// export const ST_COURSE_APPLICATION_CATEGORY_CREATE = 'ST_COURSE_APPLICATION_CATEGORY_CREATE' as const
// export const ST_COURSE_APPLICATION_CATEGORY_DELETE = 'ST_COURSE_APPLICATION_CATEGORY_DELETE' as const
// export const ST_COURSE_APPLICATION_CATEGORY_LIST = 'ST_COURSE_APPLICATION_CATEGORY_LIST' as const
// export const ST_COURSE_APPLICATION_CATEGORY_ALL = 'ST_COURSE_APPLICATION_CATEGORY_ALL' as const

// request
export const ST_COURSE_APPLICATION_REQUEST = 'ST_COURSE_APPLICATION_REQUEST' as const
export const ST_COURSE_APPLICATION_REQUEST_ALL = 'ST_COURSE_APPLICATION_REQUEST_ALL' as const
export const ST_COURSE_APPLICATION_REQUEST_EDIT = 'ST_COURSE_APPLICATION_REQUEST_EDIT' as const
export const ST_COURSE_APPLICATION_REQUEST_UPDATE = 'ST_COURSE_APPLICATION_REQUEST_UPDATE' as const
