export const PENDING = 'PENDING'
export const SUPPORTED = 'SUPPORTED'
export const REJECTED = 'REJECTED'
export const REJECTED_2 = 'REJECTED_2'
export const REJECTED_3 = 'REJECTED_3'
export const CAN_UPDATE = 'CAN_UPDATE'

export const ecOutcomeStatusTypes = {
  [SUPPORTED]: 'i. claim supported',
  [PENDING]: 'ii. claim acknowledged and pending, evidence to follow',
  [REJECTED]: 'iii. claim rejected',
  [REJECTED_2]: 'iv. claim rejected, reasons for non-submission of evidence not accepted',
  [REJECTED_3]: 'v. claim rejected, deadline for submission not met',
  [CAN_UPDATE]: 'available to update',
}
