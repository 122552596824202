import { join } from 'ramda/es'

const phoneValidator = (value, min = 11, max = 50) => {
  if (!value) return { message: 'This field is required', type: 'required' }
  const numbers = value.match(/([0-9])/g)
  const formatted = numbers && join('', numbers)
  if (!formatted || !value) return { message: 'This field is required', type: 'required' }
  if (!(formatted.length > min && formatted.length < max)) {
    return { message: 'Please enter valid phone number', type: 'invalid' }
  }
}
export default phoneValidator
