export const GLOBAL_STATE = 'GeneralAdmission'

export const GENERAL_APPLICATION = 'GENERAL_APPLICATION' as const
export const GENERAL_APPLICATION_CREATE = 'GENERAL_APPLICATION_CREATE' as const
export const GENERAL_APPLICATION_DELETE = 'GENERAL_APPLICATION_DELETE' as const
export const GENERAL_APPLICATION_LIST = 'GENERAL_APPLICATION_LIST' as const
export const GENERAL_APPLICATION_ALL = 'GENERAL_APPLICATION_ALL' as const
export const GENERAL_APPLICATION_TYPES = 'GENERAL_APPLICATION_TYPES' as const
export const GENERAL_APPLICATION_STATUS = 'GENERAL_APPLICATION_STATUS' as const

export const GENERAL_APPLICATION_FIELD_BATCH = 'GENERAL_APPLICATION_FIELD_BATCH' as const
export const GENERAL_APPLICATION_FIELD_BATCH_CREATE = 'GENERAL_APPLICATION_FIELD_BATCH_CREATE' as const

// category
// export const GENERAL_APPLICATION_CATEGORY = 'GENERAL_APPLICATION_CATEGORY' as const
// export const GENERAL_APPLICATION_CATEGORY_CREATE = 'GENERAL_APPLICATION_CATEGORY_CREATE' as const
// export const GENERAL_APPLICATION_CATEGORY_DELETE = 'GENERAL_APPLICATION_CATEGORY_DELETE' as const
// export const GENERAL_APPLICATION_CATEGORY_LIST = 'GENERAL_APPLICATION_CATEGORY_LIST' as const
// export const GENERAL_APPLICATION_CATEGORY_ALL = 'GENERAL_APPLICATION_CATEGORY_ALL' as const

// request
export const GENERAL_APPLICATION_REQUEST = 'GENERAL_APPLICATION_REQUEST' as const
export const GENERAL_APPLICATION_REQUEST_ALL = 'GENERAL_APPLICATION_REQUEST_ALL' as const
export const GENERAL_APPLICATION_REQUEST_EDIT = 'GENERAL_APPLICATION_REQUEST_EDIT' as const
export const GENERAL_APPLICATION_REQUEST_UPDATE = 'GENERAL_APPLICATION_REQUEST_UPDATE' as const
