import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  DEPARTMENT,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_LABELS,
  DEPARTMENT_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const department = createAsyncThunk(
  DEPARTMENT,
  async (params: PayloadCreatorParams<`${typeof API.DEPARTMENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.department(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const departmentCreate = createAsyncThunk(
  DEPARTMENT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.DEPARTMENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.departmentCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const departmentDelete = createAsyncThunk(
  DEPARTMENT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.DEPARTMENT}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.departmentDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const departmentList = createAsyncThunk(
  DEPARTMENT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.DEPARTMENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.departmentList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const departmentAll = createAsyncThunk(
  DEPARTMENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.DEPARTMENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.departmentAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const departmentLabels = createAsyncThunk(
  DEPARTMENT_LABELS,
  async (params: PayloadCreatorParams<`${typeof API.DEPARTMENT_LABELS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.departmentLabels(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const departmentClear = createAction(`${DEPARTMENT}/clear`)

export const departmentListClear = createAction(`${DEPARTMENT_LIST}/clear`)

export const departmentAllClear = createAction(`${DEPARTMENT_ALL}/clear`)

export const departmentLabelsClear = createAction(`${DEPARTMENT_LABELS}/clear`)
