import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  LESSON,
  LESSON_ALL,
  LESSON_ARTICULATE,
  LESSON_ARTICULATE_ALL,
  LESSON_ARTICULATE_CREATE,
  LESSON_ARTICULATE_DELETE,
  LESSON_ASSESSMENT,
  LESSON_ASSESSMENT_ALL,
  LESSON_CREATE,
  LESSON_DELETE,
  LESSON_HOMEWORK_UPLOAD,
  LESSON_LIST,
  LESSON_MARK_ASSESSMENT,
  LESSON_SORTING,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const LESSONS = GLOBAL_STATE

export const { reducer: lessonReducer } = createSlice({
  name: LESSONS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.lesson.pending, state => {
        state[LESSON].loading = true
      })
      .addCase(actionCreators.lesson.rejected, (state, action) => {
        state[LESSON].loading = false
        state[LESSON].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lesson.fulfilled, (state, action) => {
        state[LESSON].loading = false
        state[LESSON].error = null
        state[LESSON].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonAll.pending, state => {
        state[LESSON_ALL].loading = true
      })
      .addCase(actionCreators.lessonAll.rejected, (state, action) => {
        state[LESSON_ALL].loading = false
        state[LESSON_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonAll.fulfilled, (state, action) => {
        state[LESSON_ALL].loading = false
        state[LESSON_ALL].error = null
        state[LESSON_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonAssessment.pending, state => {
        state[LESSON_ASSESSMENT].loading = true
      })
      .addCase(actionCreators.lessonAssessment.rejected, (state, action) => {
        state[LESSON_ASSESSMENT].loading = false
        state[LESSON_ASSESSMENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonAssessment.fulfilled, (state, action) => {
        state[LESSON_ASSESSMENT].loading = false
        state[LESSON_ASSESSMENT].error = null
        state[LESSON_ASSESSMENT].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonAssessmentAll.pending, state => {
        state[LESSON_ASSESSMENT_ALL].loading = true
      })
      .addCase(actionCreators.lessonAssessmentAll.rejected, (state, action) => {
        state[LESSON_ASSESSMENT_ALL].loading = false
        state[LESSON_ASSESSMENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonAssessmentAll.fulfilled, (state, action) => {
        state[LESSON_ASSESSMENT_ALL].loading = false
        state[LESSON_ASSESSMENT_ALL].error = null
        state[LESSON_ASSESSMENT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonCreate.pending, state => {
        state[LESSON_CREATE].loading = true
      })
      .addCase(actionCreators.lessonCreate.rejected, (state, action) => {
        state[LESSON_CREATE].loading = false
        state[LESSON_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonCreate.fulfilled, (state, action) => {
        state[LESSON_CREATE].loading = false
        state[LESSON_CREATE].error = null
        state[LESSON_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonDelete.pending, state => {
        state[LESSON_DELETE].loading = true
      })
      .addCase(actionCreators.lessonDelete.rejected, (state, action) => {
        state[LESSON_DELETE].loading = false
        state[LESSON_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonDelete.fulfilled, (state, action) => {
        state[LESSON_DELETE].loading = false
        state[LESSON_DELETE].error = null
        state[LESSON_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonHomeworkUpload.pending, state => {
        state[LESSON_HOMEWORK_UPLOAD].loading = true
      })
      .addCase(actionCreators.lessonHomeworkUpload.rejected, (state, action) => {
        state[LESSON_HOMEWORK_UPLOAD].loading = false
        state[LESSON_HOMEWORK_UPLOAD].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonHomeworkUpload.fulfilled, (state, action) => {
        state[LESSON_HOMEWORK_UPLOAD].loading = false
        state[LESSON_HOMEWORK_UPLOAD].error = null
        state[LESSON_HOMEWORK_UPLOAD].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonList.pending, state => {
        state[LESSON_LIST].loading = true
      })
      .addCase(actionCreators.lessonList.rejected, (state, action) => {
        state[LESSON_LIST].loading = false
        state[LESSON_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonList.fulfilled, (state, action) => {
        state[LESSON_LIST].loading = false
        state[LESSON_LIST].error = null
        state[LESSON_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonMarkAssessment.pending, state => {
        state[LESSON_MARK_ASSESSMENT].loading = true
      })
      .addCase(actionCreators.lessonMarkAssessment.rejected, (state, action) => {
        state[LESSON_MARK_ASSESSMENT].loading = false
        state[LESSON_MARK_ASSESSMENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonMarkAssessment.fulfilled, (state, action) => {
        state[LESSON_MARK_ASSESSMENT].loading = false
        state[LESSON_MARK_ASSESSMENT].error = null
        state[LESSON_MARK_ASSESSMENT].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonSorting.pending, state => {
        state[LESSON_SORTING].loading = true
      })
      .addCase(actionCreators.lessonSorting.rejected, (state, action) => {
        state[LESSON_SORTING].loading = false
        state[LESSON_SORTING].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonSorting.fulfilled, (state, action) => {
        state[LESSON_SORTING].loading = false
        state[LESSON_SORTING].error = null
        state[LESSON_SORTING].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonArticulateCreate.pending, state => {
        state[LESSON_ARTICULATE_CREATE].loading = true
      })
      .addCase(actionCreators.lessonArticulateCreate.rejected, (state, action) => {
        state[LESSON_ARTICULATE_CREATE].loading = false
        state[LESSON_ARTICULATE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonArticulateCreate.fulfilled, (state, action) => {
        state[LESSON_ARTICULATE_CREATE].loading = false
        state[LESSON_ARTICULATE_CREATE].error = null
        state[LESSON_ARTICULATE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonArticulateDelete.pending, state => {
        state[LESSON_ARTICULATE_DELETE].loading = true
      })
      .addCase(actionCreators.lessonArticulateDelete.rejected, (state, action) => {
        state[LESSON_ARTICULATE_DELETE].loading = false
        state[LESSON_ARTICULATE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonArticulateDelete.fulfilled, (state, action) => {
        state[LESSON_ARTICULATE_DELETE].loading = false
        state[LESSON_ARTICULATE_DELETE].error = null
        state[LESSON_ARTICULATE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.lessonArticulateAll.pending, state => {
        state[LESSON_ARTICULATE_ALL].loading = true
      })
      .addCase(actionCreators.lessonArticulateAll.rejected, (state, action) => {
        state[LESSON_ARTICULATE_ALL].loading = false
        state[LESSON_ARTICULATE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.lessonArticulateAll.fulfilled, (state, action) => {
        state[LESSON_ARTICULATE_ALL].loading = false
        state[LESSON_ARTICULATE_ALL].error = null
        state[LESSON_ARTICULATE_ALL].data = action.payload
      })

    // builder
    //   .addCase(actionCreators.lessonArticulate.pending, state => {
    //     state[LESSON_ARTICULATE].loading = true
    //   })
    //   .addCase(actionCreators.lessonArticulate.rejected, (state, action) => {
    //     state[LESSON_ARTICULATE].loading = false
    //     state[LESSON_ARTICULATE].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.lessonArticulate.fulfilled, (state, action) => {
    //     state[LESSON_ARTICULATE].loading = false
    //     state[LESSON_ARTICULATE].error = null
    //     state[LESSON_ARTICULATE].data = action.payload
    //   })

    // builder.addCase(actionCreators.lessonArticulateClear, state => {
    //   state[LESSON_ARTICULATE] = defaultState()
    // })

    builder.addCase(actionCreators.lessonArticulateAllClear, state => {
      state[LESSON_ARTICULATE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.lessonClear, state => {
      state[LESSON] = defaultState()
    })

    builder.addCase(actionCreators.lessonAllClear, state => {
      state[LESSON_ALL] = defaultState()
    })

    builder.addCase(actionCreators.lessonAssessmentClear, state => {
      state[LESSON_ASSESSMENT] = defaultState()
    })

    builder.addCase(actionCreators.lessonAssessmentAllClear, state => {
      state[LESSON_ASSESSMENT_ALL] = defaultState()
    })

    builder.addCase(actionCreators.lessonHomeworkUploadClear, state => {
      state[LESSON_HOMEWORK_UPLOAD] = defaultState()
    })

    builder.addCase(actionCreators.lessonListClear, state => {
      state[LESSON_LIST] = defaultState()
    })
  },
})
