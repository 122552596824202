import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { DEBIT_CREATE, DEBIT_DELETE, DEBIT_LIST, DEBIT_OWN, GLOBAL_STATE } from '../constants/actionTypes'

const Selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const debitListSelector = createSelector(Selectors, prop(DEBIT_LIST))

export const debitOwnSelector = createSelector(Selectors, prop(DEBIT_OWN))

export const debitCreateSelector = createSelector(Selectors, prop(DEBIT_CREATE))

export const debitDeleteSelector = createSelector(Selectors, prop(DEBIT_DELETE))
