import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  GENERAL_APPLICATION,
  GENERAL_APPLICATION_ALL,
  GENERAL_APPLICATION_CREATE,
  GENERAL_APPLICATION_DELETE,
  GENERAL_APPLICATION_FIELD_BATCH,
  GENERAL_APPLICATION_FIELD_BATCH_CREATE,
  GENERAL_APPLICATION_LIST,
  GENERAL_APPLICATION_REQUEST,
  GENERAL_APPLICATION_REQUEST_ALL,
  GENERAL_APPLICATION_REQUEST_EDIT,
  GENERAL_APPLICATION_REQUEST_UPDATE,
  GENERAL_APPLICATION_STATUS,
  GENERAL_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const generalApplication = createAsyncThunk(
  GENERAL_APPLICATION,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_FORM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplication(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationCreate = createAsyncThunk(
  GENERAL_APPLICATION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_FORM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationDelete = createAsyncThunk(
  GENERAL_APPLICATION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_FORM}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationList = createAsyncThunk(
  GENERAL_APPLICATION_LIST,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationAll = createAsyncThunk(
  GENERAL_APPLICATION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationTypes = createAsyncThunk(
  GENERAL_APPLICATION_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationChangeStatus = createAsyncThunk(
  GENERAL_APPLICATION_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationFieldBatch = createAsyncThunk(
  GENERAL_APPLICATION_FIELD_BATCH,
  async (
    params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_FIELD_BATCH}{id}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.generalApplicationFieldBatch(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationFieldBatchCreate = createAsyncThunk(
  GENERAL_APPLICATION_FIELD_BATCH_CREATE,
  async (
    params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_FIELD_BATCH}`, 'post'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.generalApplicationFieldBatchCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationRequest = createAsyncThunk(
  GENERAL_APPLICATION_REQUEST,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_REQUEST}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationRequestCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationRequestAll = createAsyncThunk(
  GENERAL_APPLICATION_REQUEST_ALL,
  async (params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_REQUEST_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.generalApplicationRequestAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationRequestEdit = createAsyncThunk(
  GENERAL_APPLICATION_REQUEST_EDIT,
  async (
    params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_REQUEST_EDIT}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.generalApplicationRequestEdit(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationRequestUpdate = createAsyncThunk(
  GENERAL_APPLICATION_REQUEST_UPDATE,
  async (
    params: PayloadCreatorParams<`${typeof API.GENERAL_APPLICATION_REQUEST_UPDATE}`, 'put'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.generalApplicationRequestUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const generalApplicationClear = createAction(`${GENERAL_APPLICATION}/clear`)

export const generalApplicationListClear = createAction(`${GENERAL_APPLICATION_LIST}/clear`)

export const generalApplicationAllClear = createAction(`${GENERAL_APPLICATION_ALL}/clear`)

export const generalApplicationTypesClear = createAction(`${GENERAL_APPLICATION_TYPES}/clear`)

export const generalApplicationFieldBatchClear = createAction(`${GENERAL_APPLICATION_FIELD_BATCH}/clear`)

export const generalApplicationRequestEditClear = createAction(`${GENERAL_APPLICATION_REQUEST_EDIT}/clear`)

export const generalApplicationRequestUpdateClear = createAction(`${GENERAL_APPLICATION_REQUEST_UPDATE}/clear`)
