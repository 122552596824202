// ** React Imports
// ** Internationalization Context
import { useAppSelector, useSignal } from '@hooks'
import { SignalConsumer } from '@src/components/SignalConsumer'
import { LOGIN } from '@src/constants/routes'
import { authUserInfo } from '@store/selector'
import { IntlContext } from '@utility/context/Internationalization'
import { pathOr } from 'ramda'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
// ** Third Party Components
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const defaultOptions = [
  {
    label: 'Uz',
    value: 'uz',
  },
  {
    label: 'Ru',
    value: 'ru',
  },
  {
    label: 'En',
    value: 'en',
  },
]

const IntlDropdown = ({ liStyles = {} }) => {
  const params = useLocation()
  // ** Context
  const intlContext = useContext(IntlContext)
  const userInfo = useAppSelector(authUserInfo)
  const localeSignal = useSignal(pathOr(intlContext?.locale_option?.label, ['selected_locale', 'label'], userInfo))

  return (
    <UncontrolledDropdown tag='li' className='dropdown-language nav-item' style={liStyles}>
      <DropdownToggle tag='a' className='nav-link' onClick={e => e.preventDefault()}>
        {/*<ReactCountryFlag*/}
        {/*  className='country-flag flag-icon'*/}
        {/*  countryCode={intlContext.locale === 'en' ? 'us' : intlContext.locale}*/}
        {/*  svg*/}
        {/*/>*/}
        <SignalConsumer signal={localeSignal}>{({ value }) => <span>{value}</span>}</SignalConsumer>
      </DropdownToggle>
      <DropdownMenu className='mt-0' right>
        {(userInfo?.locales || defaultOptions).map(item => (
          <DropdownItem
            key={item.value}
            tag='div'
            onClick={() => {
              if (params.pathname === LOGIN) {
                intlContext.switchLocaleLocally(item)
              } else {
                intlContext.switchLanguageServer(item)
              }
              localeSignal.value = item.label
            }}
          >
            <span>{item.label}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
