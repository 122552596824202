import { DefaultState } from '@src/redux/types'

import * as actionTypes from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ data: null, loading: false, error: null })

export const initialState = (): {
  [actionTypes.GENERATE_TRANSCRIPT]: DefaultState<`${typeof API.GENERATE_TRANSCRIPT}`, 'post'>
  [actionTypes.STUDENT]: DefaultState<`${typeof API.STUDENT}{id}`, 'get'>
  [actionTypes.STUDENT_DELETE]: DefaultState<`${typeof API.STUDENT}{ids}`, 'delete'>
  [actionTypes.STUDENT_PROFILE]: DefaultState<`${typeof API.STUDENT_PROFILE}`, 'get'>
  [actionTypes.STUDENT_PROFILE_UPDATE]: DefaultState<`${typeof API.STUDENT_PROFILE}`, 'put'>
  [actionTypes.STUDENT_LIST]: DefaultState<`${typeof API.STUDENT}`, 'get'>
  [actionTypes.STUDENT_ACHIEVEMENT_RECORDS]: DefaultState<`/${typeof API.ACHIEVEMENT_RECORDS_STUDENT}{id}`, 'get'>
  [actionTypes.STUDENT_ALL]: DefaultState<`${typeof API.STUDENT_ALL}`, 'get'>
  [actionTypes.STUDENT_GUARD_CREATE]: DefaultState<`${typeof API.STUDENT_GUARD}`, 'post'>
  [actionTypes.STUDENT_DOCUMENT]: DefaultState<`${typeof API.STUDENT_DOCUMENT}`, 'post'>
  [actionTypes.STUDENT_SEND_SMS]: DefaultState<`${typeof API.STUDENT_SEND_SMS}`, 'post'>
  [actionTypes.STUDENT_EXPORT_DATA]: DefaultState<`${typeof API.STUDENT_EXPORT_DATA}`, 'post'>
  [actionTypes.STUDENT_TRANSFER]: DefaultState<`${typeof API.STUDENT_TRANSFER}`, 'post'>
  [actionTypes.STUDENT_RESUME_STUDY]: DefaultState<`${typeof API.STUDENT_RESUME_STUDY}`, 'post'>
  [actionTypes.STUDENT_DEBIT_DETAIL_CREATE]: DefaultState<`${typeof API.STUDENT_DEBIT_DETAIL}`, 'post'>
  [actionTypes.STUDENT_DEBIT_DETAIL_DELETE]: DefaultState<`${typeof API.STUDENT_DEBIT_DETAIL}{id}`, 'delete'>
  [actionTypes.STUDENT_CALL_LETTER_DELETE]: DefaultState<`${typeof API.STUDENT_CALL_LETTER_DELETE}{id}`, 'delete'>
  [actionTypes.STUDENT_GUARD_LIST]: DefaultState<`${typeof API.STUDENT_GUARD}`, 'get'>
  [actionTypes.STUDENT_USERNAME]: DefaultState<`${typeof API.STUDENT_USERNAME}{username}`, 'get'>
  [actionTypes.STUDENT_PERSONAL_INFO]: DefaultState<`${typeof API.STUDENT_PERSONAL_INFO}`, 'put'>
  [actionTypes.STUDENT_CONTACT_INFO]: DefaultState<`${typeof API.STUDENT_CONTACT_INFO}`, 'put'>
  [actionTypes.STUDENT_STATUS_UPDATE]: DefaultState<`${typeof API.STUDENT_STATUS}`, 'put'>
  [actionTypes.STUDENT_MARK_UPDATE]: DefaultState<`${typeof API.STUDENT_MARK_UPDATE}`, 'put'>
} => ({
  [actionTypes.GENERATE_TRANSCRIPT]: defaultState(),
  [actionTypes.STUDENT]: defaultState(),
  [actionTypes.STUDENT_PROFILE]: defaultState(),
  [actionTypes.STUDENT_PROFILE_UPDATE]: defaultState(),
  [actionTypes.STUDENT_DELETE]: defaultState(),
  [actionTypes.STUDENT_LIST]: defaultState(),
  [actionTypes.STUDENT_ACHIEVEMENT_RECORDS]: defaultState(),
  [actionTypes.STUDENT_ALL]: defaultState(),
  [actionTypes.STUDENT_GUARD_CREATE]: defaultState(),
  [actionTypes.STUDENT_DOCUMENT]: defaultState(),
  [actionTypes.STUDENT_SEND_SMS]: defaultState(),
  [actionTypes.STUDENT_EXPORT_DATA]: defaultState(),
  [actionTypes.STUDENT_TRANSFER]: defaultState(),
  [actionTypes.STUDENT_RESUME_STUDY]: defaultState(),
  [actionTypes.STUDENT_DEBIT_DETAIL_CREATE]: defaultState(),
  [actionTypes.STUDENT_DEBIT_DETAIL_DELETE]: defaultState(),
  [actionTypes.STUDENT_CALL_LETTER_DELETE]: defaultState(),
  [actionTypes.STUDENT_GUARD_LIST]: defaultState(),
  [actionTypes.STUDENT_USERNAME]: defaultState(),
  [actionTypes.STUDENT_PERSONAL_INFO]: defaultState(),
  [actionTypes.STUDENT_CONTACT_INFO]: defaultState(),
  [actionTypes.STUDENT_STATUS_UPDATE]: defaultState(),
  [actionTypes.STUDENT_MARK_UPDATE]: defaultState(),
})
