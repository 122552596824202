import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '@helpers/createState'

const reducer = combineReducers({
  [STATE.PLAYER_STATE]: createState(actionTypes.SET_PLAYER),
  [STATE.PLAYER_PIP]: createState(actionTypes.SET_PLAYER_PIP),
})

export const PLAYER = STATE.GLOBAL_STATE

export default reducer
