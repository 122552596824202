import { lazy } from 'react'
import * as ROUTE from '../constants/route'
import * as ROLES from '@constants/userRoles'

export const HRAdmissionRoutes = [
  {
    path: ROUTE.HR_MY_APPLICATIONS,
    component: lazy(() => import('../views/MyApplications')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.HR_APPLICATIONS,
    component: lazy(() => import('../views/AdminApplications')),
    exact: true,
    meta: {
      access: [ROLES.ADMISSION_MODERATOR, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.HR_MY_APPLICATIONS_CREATE,
    component: lazy(() => import('../views/MyApplicationsCreate')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.HR_APPLICATIONS_EDIT,
    component: lazy(() => import('../views/AdminApplicationsEdit')),
    exact: true,
    meta: {
      access: [ROLES.ADMISSION_MODERATOR, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.HR_ADMIN_APPLICATION_FORM,
    component: lazy(() => import('../views/AdminAdmissionApplication')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.HR_ADMIN_APPLICATION_CREATE,
    component: lazy(() => import('../views/AdminAdmissionApplicationCreate')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.HR_ADMIN_APPLICATION_CATEGORY,
    component: lazy(() => import('../views/AdminAdmissionApplicationCategory')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.HR_HEAD, ROLES.HR_MANAGER, ROLES.SUPER_ADMIN],
    },
  },
]
