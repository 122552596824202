import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const state: ApiFunc<`${typeof API.STATE}{id}`, 'get'> = data =>
  instance.get(`${API.STATE}${'id' in data ? data.id : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const stateCreate: ApiFunc<`${typeof API.STATE}`, 'post'> = data => instance.post(API.STATE, data)

export const stateDelete: ApiFunc<`${typeof API.STATE}{id}`, 'delete'> = data =>
  instance.delete(`${API.STATE}${'id' in data ? data.id : data?.name}`)

export const stateList: ApiFunc<`${typeof API.STATE}`, 'get'> = data =>
  instance.get(API.STATE, {
    params: data,
  })

export const stateAll: ApiFunc<`${typeof API.STATE_ALL}`, 'get'> = data =>
  instance.get(API.STATE_ALL, {
    params: data,
  })
