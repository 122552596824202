import { CSSProperties, ChangeEvent, InputHTMLAttributes, ReactNode, forwardRef, useEffect } from 'react'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { CustomInput, FormGroup, FormGroupProps, FormText, Label, LabelProps } from 'reactstrap'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'value'> & {
  label?: ReactNode
  error?: FieldError
  handleChange?: (value: boolean, name: string) => void
  name: string
  inline?: boolean
  inlineLabel?: ReactNode
  className?: string
  id?: string
  disabled?: boolean
  formGroupStyles?: CSSProperties
  labelProps?: LabelProps
  onUnmount?: () => void
  value?: boolean | string | string[] | number
  formGroupProps?: FormGroupProps
  required?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement> | boolean) => void
}

const CheckboxField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    value,
    onChange,
    name,
    error,
    inline,
    inlineLabel,
    className,
    required,
    id = '',
    onUnmount,
    disabled = false,
    formGroupStyles = {},
    handleChange,
    labelProps = {},
    formGroupProps = {},
  } = props

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.checked)
    handleChange && handleChange(e.target.checked, e.target.name)
  }

  return (
    <FormGroup {...formGroupProps} style={formGroupStyles}>
      {label && (
        <Label {...htmlFor} {...labelProps}>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}

      <CustomInput
        checked={!!value || false}
        onChange={change}
        // defaultChecked={defaultChecked}
        type='checkbox'
        id={id || name}
        name={name}
        inline={inline}
        className={className}
        invalid={!!error}
        label={inlineLabel}
        {...htmlId}
        disabled={disabled}
        innerRef={ref}
      />

      {error && (
        <FormText color='danger'>
          <FormattedMessage id='form_required' />
        </FormText>
      )}
    </FormGroup>
  )
})

export default CheckboxField
