import { isNil } from 'ramda'

import { EDUCATION_FORM, STORAGE_SELECTED_EDUCATION_FORM } from '../constants/storage'

export const getEduForm = () => {
  try {
    const eduForm = localStorage.getItem(EDUCATION_FORM)
    if (!isNil(eduForm)) {
      return JSON.parse(eduForm)
    }
  } catch (e) {
    console.error('Could not retrieve education form: ', e)
  }
  return null
}

export const getSelectedEduForm = () => {
  try {
    const selectedEduForm = localStorage.getItem(STORAGE_SELECTED_EDUCATION_FORM)
    if (selectedEduForm) {
      return JSON.parse(selectedEduForm)
    }
  } catch (e) {
    console.error('Could not retrieve selected education form: ', e)
  }
  return null
}

export const setSelectedEduForm = param => {
  localStorage.setItem(STORAGE_SELECTED_EDUCATION_FORM, param)
}

export const removeSelectedEduForm = () => {
  localStorage.removeItem(STORAGE_SELECTED_EDUCATION_FORM)
}

export const removeEduForm = () => {
  localStorage.removeItem(EDUCATION_FORM)
}

export const setEduForm = param => localStorage.setItem(EDUCATION_FORM, param)
