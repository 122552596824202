import { ASSESSMENT_OFFENCE, offenceReducer } from '@module/AcademicMisconduct/redux/reducer'
import { ACADEMIC_YEARS, academicYearReducer } from '@module/AcademicYears/redux/reducer'
import { ACCOUNTS, accountReducer } from '@module/Account/redux/reducer'
import { ACCOUNTING_CONTRACTSS, accountingContractsReducer } from '@module/Accounting/redux/reducer'
import { ACHIEVEMENTS_RECORDS, achievementRecordsReducer } from '@module/AchievementRecords/redux/reducer'
import { ASSESSMENT, assessmentReducer } from '@module/Assessment/redux/reducer'
import { ASSESSMENTS_EC, assessmentECReducer } from '@module/AssessmentEC/redux/reducer'
import { ASSESSMENT_MARK, assessmentMarkReducer } from '@module/AssessmentMarks/redux/reducer'
import { ATTENDANCES, attendanceReducer } from '@module/Attendance/redux/reducer'
import { ATTENDANCE_REASONS, attendanceReasonReducer } from '@module/AttendanceReason/redux/reducer'
import { AUTH, authReducer } from '@module/Auth/redux/reducer'
import { CALENDAR, calendarReducer } from '@module/Calendar/redux/reducer'
import { CATEGORYS, categoryReducer } from '@module/Category/redux/reducer'
import { CITYS, cityReducer } from '@module/City/redux/reducer'
import Mail, { MAIL } from '@module/ComposeEmail/redux/reducer'
import { CONTRACT_AGREEMENT, contractAgreementReducer } from '@module/ContractInformation/redux/reducer'
import { COUNTRYS, countryReducer } from '@module/Country/redux/reducer'
import { COURSE_CAMPAIGNS, courseCampaignReducer } from '@module/Course/activate/redux/reducer'
import { COURSE_CREATES, courseCreateReducer } from '@module/Course/create/redux/reducer'
import { COURSE_TRANSERS, courseTransferReducer } from '@module/CourseTransfer/redux/reducer'
import { DEBIT, debitReducer } from '@module/Debit/redux/reducer'
import { DEPARTMENTS, departmentReducer } from '@module/Departments/redux/reducer'
import { EDUCATION_PLACES, educationPlaceReducer } from '@module/EducationPlace/redux/reducer'
import { ENROLLMENTS, enrollmentReducer } from '@module/Enrollment/redux/reducer'
import { FEEDBACK, feedbackReducer } from '@module/Feedback/redux/reducer'
import { MODULE_FILES, moduleFileReducer } from '@module/Files/ModuleFiles/redux/reducer'
import { FORMS, formReducer } from '@module/Forms/redux/reducer'
import { GENERAL_ADMISSION, generalAdmissionReducer } from '@module/GeneralAdmission/redux/reducer'
import { GROUPING, groupReducer } from '@module/Groups/redux/reducer'
import { GUEST_USERS, guestsReducer } from '@module/GuestUsers/redux/reducer'
import { HR, hrReducer } from '@module/HRAdmission/redux/reducer'
import { INSTITUTION, institutionPageSettingsReducer } from '@module/Institution/redux/reducer'
import { INTERNAL_EXAM_STATE, internalExamReducer } from '@module/InternalExam/redux/reducer'
import { INTERNAL_EXAM_PLACES, internalExamPlaceReducer } from '@module/InternalExamPlace/redux/reducer'
import { INTTRO_VIDEO, introVideosReducer } from '@module/IntroVideos/redux/reducer'
import { JOB_OFFER, jobOfferReducer } from '@module/JobOffer/redux/reducer'
import { JOB_POSITIONS, jobPositionReducer } from '@module/JobPosition/redux/reducer'
import { LESSONS, lessonReducer } from '@module/Lesson/redux/reducer'
import { LIBRARY, eLibraryReducer } from '@module/Library/redux/reducer'
import { MODULES_OUTCOMES, moduleOutcomeReducer } from '@module/ModuleOutcome/redux/reducer'
import { CCLS_MODULES, cclsModuleReducer } from '@module/Modules/activate/redux/reducer'
import { MODULE, modulesReducer } from '@module/Modules/create/redux/reducer'
import { OPEN_LINK, openLinkReducer } from '@module/OpenLink/redux/reducer'
import { EXTRNAL_PAYMENTS, paymentsReducer } from '@module/Payments/redux/reducer'
import Player, { PLAYER } from '@module/Player/redux/reducer'
import { LEVEL, levelReducer } from '@module/QualityAssurance/redux/level/reducer'
import { REPORTS, reportReducer } from '@module/Reports/redux/reducer'
import { ROLES, roleReducer } from '@module/Roles/redux/reducer'
import { SEMESTER, semesterReducer } from '@module/Semester/redux/reducer'
import { ST_COURSE, stCourseReducer } from '@module/ShortCourseAdmission/redux/reducer'
import { SHORT_TERM_COURSE, stcReducer } from '@module/ShortTermCourse/redux/reducer'
import {
  SHORT_COURSE_REGISTRATION,
  shortCourseRegistrationReducer,
} from '@module/ShortTermCourseRegistration/redux/reducer'
import { STAFFS, staffReducer } from '@module/Staff/redux/reducer'
import { STATES, stateReducer } from '@module/State/redux/reducer'
import { STATS, statsReducer } from '@module/Stats/redux/reducer'
import { STUDENT, studentReducer } from '@module/Student/redux/reducer'
import { STUDENT_ADMISSION, studentAdmissionReducer } from '@module/StudentAdmission/redux/reducer'
import { STUDENT_CVS, studentCVReducer } from '@module/StudentCV/redux/reducer'
import { STUDENT_DASHBOARD, studentDashboardReducer } from '@module/StudentDashboard/redux/reducer'
import { STUDENT_DOCUMENT, studentDocsReducer } from '@module/StudentDocs/redux/reducer'
import { TIMETABLE, timetableReducer } from '@module/Timetable/redux/reducer'
import { TYPE, typesReducer } from '@module/Types/redux/reducer'
import { VACANCY_POSITIONS, vacancyPositionReducer } from '@module/VacancyPosition/redux/reducer'
import { layoutReducer } from '@store/layout/reducer'
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import { history } from './store'

const rootReducer = combineReducers({
  router: connectRouter(history),
  layout: layoutReducer,
  [ASSESSMENT_OFFENCE]: offenceReducer,
  [ACADEMIC_YEARS]: academicYearReducer,
  [ACCOUNTS]: accountReducer,
  [ACCOUNTING_CONTRACTSS]: accountingContractsReducer,
  [ACHIEVEMENTS_RECORDS]: achievementRecordsReducer,
  [ASSESSMENT]: assessmentReducer,
  [ASSESSMENTS_EC]: assessmentECReducer,
  [ASSESSMENT_MARK]: assessmentMarkReducer,
  [ATTENDANCES]: attendanceReducer,
  [ATTENDANCE_REASONS]: attendanceReasonReducer,
  [AUTH]: authReducer,
  [CALENDAR]: calendarReducer,
  [CATEGORYS]: categoryReducer,
  [CITYS]: cityReducer,
  [CONTRACT_AGREEMENT]: contractAgreementReducer,
  [COUNTRYS]: countryReducer,
  [COURSE_CAMPAIGNS]: courseCampaignReducer,
  [COURSE_CREATES]: courseCreateReducer,
  [COURSE_TRANSERS]: courseTransferReducer,
  [DEBIT]: debitReducer,
  [DEPARTMENTS]: departmentReducer,
  [EDUCATION_PLACES]: educationPlaceReducer,
  [ENROLLMENTS]: enrollmentReducer,
  [FEEDBACK]: feedbackReducer,
  [MODULE_FILES]: moduleFileReducer,
  [FORMS]: formReducer,
  [GENERAL_ADMISSION]: generalAdmissionReducer,
  [GROUPING]: groupReducer,
  [GUEST_USERS]: guestsReducer,
  [HR]: hrReducer,
  [INSTITUTION]: institutionPageSettingsReducer,
  [INTERNAL_EXAM_PLACES]: internalExamPlaceReducer,
  [INTERNAL_EXAM_STATE]: internalExamReducer,
  [INTTRO_VIDEO]: introVideosReducer,
  [JOB_OFFER]: jobOfferReducer,
  [JOB_POSITIONS]: jobPositionReducer,
  [LESSONS]: lessonReducer,
  [LIBRARY]: eLibraryReducer,
  [MODULES_OUTCOMES]: moduleOutcomeReducer,
  [CCLS_MODULES]: cclsModuleReducer,
  [MAIL]: Mail,
  [MODULE]: modulesReducer,
  [OPEN_LINK]: openLinkReducer,
  [PLAYER]: Player,
  [EXTRNAL_PAYMENTS]: paymentsReducer,
  [LEVEL]: levelReducer,
  [REPORTS]: reportReducer,
  [ROLES]: roleReducer,
  [SEMESTER]: semesterReducer,
  [ST_COURSE]: stCourseReducer,
  [SHORT_TERM_COURSE]: stcReducer,
  [SHORT_COURSE_REGISTRATION]: shortCourseRegistrationReducer,
  [STAFFS]: staffReducer,
  [STATES]: stateReducer,
  [STATS]: statsReducer,
  [STUDENT]: studentReducer,
  [STUDENT_ADMISSION]: studentAdmissionReducer,
  [STUDENT_CVS]: studentCVReducer,
  [STUDENT_DASHBOARD]: studentDashboardReducer,
  [STUDENT_DOCUMENT]: studentDocsReducer,
  [TIMETABLE]: timetableReducer,
  [TYPE]: typesReducer,
  [VACANCY_POSITIONS]: vacancyPositionReducer,
})

const reducers = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { router, navbar, layout } = state
    state = { router, navbar, layout }
  }
  return rootReducer(state, action)
}

export default reducers
