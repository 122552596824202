import * as ROLES from '@constants/userRoles'
import { lazy } from 'react'

import { INTERNAL_EXAM_APPLICANTS_PAGE, INTERNAL_EXAM_PLACE } from '../constants/route'

export const InternalExamPlaceRoutes = [
  {
    path: INTERNAL_EXAM_PLACE,
    component: lazy(() => import('../views/InternalExamPlace')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
  {
    path: INTERNAL_EXAM_APPLICANTS_PAGE,
    component: lazy(() => import('../views/InternalExamApplicantsPage')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
]
