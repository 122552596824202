import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const vacancyPositionList: ApiFunc<`${typeof API.VACANCY_POSITION}`, 'get'> = data =>
  instance.get(API.VACANCY_POSITION, {
    params: data,
  })

export const vacancyPositionCreate: ApiFunc<`${typeof API.VACANCY_POSITION}`, 'post'> = data =>
  instance.post(API.VACANCY_POSITION, data)

export const vacancyPositionDelete: ApiFunc<`${typeof API.VACANCY_POSITION}{id}`, 'delete'> = data =>
  instance.delete(`${API.VACANCY_POSITION}${'id' in data ? data.id : data?.name}`)

export const vacancyPositionGetByID: ApiFunc<`${typeof API.VACANCY_POSITION}{id}`, 'get'> = data =>
  instance.get(`${API.VACANCY_POSITION}${'id' in data ? data.id : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const vacancyPositionAll: ApiFunc<`${typeof API.VACANCY_POSITION_ALL}`, 'get'> = data =>
  instance.get(API.VACANCY_POSITION_ALL, {
    params: data,
  })

export const vacancyPositionActivate: ApiFunc<`${typeof API.VACANCY_POSITION_ACTIVATE}{id}`, 'put'> = data =>
  instance.put(`${API.VACANCY_POSITION_ACTIVATE}${'id' in data ? data.id : data?.name}`)
