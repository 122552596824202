import { lazy } from 'react'
import { STAFF, STAFF_INFO, STAFF_INFO_PUBLIC } from '../constants/route'
import {
  MODERATOR_VIEWER,
  MODULE_LEADER,
  PROGRAMME_MANAGER,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_HELPER,
  SUPER_ADMIN,
  TEACHER,
  TEACHING_AND_LEARNING_OFFICER,
} from '@constants/userRoles'

export const StaffRoutes = [
  {
    path: STAFF_INFO_PUBLIC,
    component: lazy(() => import('../views/StaffPublicProfile')),
    exact: true,
    meta: {
      layout: 'blank',
      isPublic: true,
    },
  },
]
