import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  EDUCATION_PLACE,
  EDUCATION_PLACE_ALL,
  EDUCATION_PLACE_CREATE,
  EDUCATION_PLACE_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const educationPlaceSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const educationPlaceSelector = createSelector(educationPlaceSelectors, prop(EDUCATION_PLACE))

export const educationPlaceListSelector = createSelector(educationPlaceSelectors, prop(EDUCATION_PLACE_LIST))

export const educationPlaceAllSelector = createSelector(educationPlaceSelectors, prop(EDUCATION_PLACE_ALL))

export const educationPlaceCreateSelector = createSelector(educationPlaceSelectors, prop(EDUCATION_PLACE_CREATE))
