export const SHORT_COURSE_REGISTRATION = '/short_term_course/registration/' as const

// export const SHORT_COURSE_REGISTRATION_FORM = '/short_term_course_registration_form/' as const
// export const SHORT_COURSE_REGISTRATION_TYPES = `${SHORT_COURSE_REGISTRATION}types` as const
// export const SHORT_COURSE_REGISTRATION_STATUS = `${SHORT_COURSE_REGISTRATION}status/` as const
// export const SHORT_COURSE_REGISTRATION_ALL = `${SHORT_COURSE_REGISTRATION_FORM}all` as const
// export const SHORT_COURSE_REGISTRATION_FIELD_BATCH = `${SHORT_COURSE_REGISTRATION_FORM}field/batch/` as const

// export const SHORT_COURSE_REGISTRATION_CATEGORY = `${SHORT_COURSE_REGISTRATION_FORM}category/` as const
// export const SHORT_COURSE_REGISTRATION_CATEGORY_ALL = `${SHORT_COURSE_REGISTRATION_CATEGORY}all/` as const

// export const SHORT_COURSE_REGISTRATION_REQUEST = SHORT_COURSE_REGISTRATION
// export const SHORT_COURSE_REGISTRATION_REQUEST_ALL = `${SHORT_COURSE_REGISTRATION_REQUEST}all/` as const
// export const SHORT_COURSE_REGISTRATION_REQUEST_EDIT = `${SHORT_COURSE_REGISTRATION_REQUEST}edit/` as const
// export const SHORT_COURSE_REGISTRATION_REQUEST_UPDATE = `${SHORT_COURSE_REGISTRATION_REQUEST}update` as const

// export const SHORT_COURSE_REGISTRATION_SIGNUP = `${SHORT_COURSE_REGISTRATION_FORM}signup` as const
// export const SHORT_COURSE_REGISTRATION_SMS_SEND = `${SHORT_COURSE_REGISTRATION_FORM}sms/send` as const
