import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as Api from '../api'
import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const internalExam = createAsyncThunk(
  TYPES.INTERNAL_EXAM,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.internalExam(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamList = createAsyncThunk(
  TYPES.INTERNAL_EXAM_LIST,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.internalExamList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamAll = createAsyncThunk(
  TYPES.INTERNAL_EXAM_ALL,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await Api.internalExamAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamCreate = createAsyncThunk(
  TYPES.INTERNAL_EXAM_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await Api.internalExamCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const internalExamPlace = createAsyncThunk(
//   TYPES.INTERNAL_EXAM_PLACE,
//   async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await Api.internalExamPlace(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const internalExamPlaceList = createAsyncThunk(
//   TYPES.INTERNAL_EXAM_PLACE_LIST,
//   async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await Api.internalExamPlaceList(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const internalExamPlaceAll = createAsyncThunk(
//   TYPES.INTERNAL_EXAM_PLACE_ALL,
//   async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE_ALL}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await Api.internalExamPlaceAll(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const internalExamPlaceCreate = createAsyncThunk(
//   TYPES.INTERNAL_EXAM_PLACE_CREATE,
//   async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await Api.internalExamPlaceCreate(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params.onSettled && params.onSettled()
//     }
//   }
// )

export const internalExamClear = createAction(`${TYPES.INTERNAL_EXAM}/clear`)

export const internalExamAllClear = createAction(`${TYPES.INTERNAL_EXAM_ALL}/clear`)

export const internalExamListClear = createAction(`${TYPES.INTERNAL_EXAM_LIST}/clear`)
