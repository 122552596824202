import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  JOB_OFFER_CHANGE_STATUS,
  JOB_OFFER_CREATE,
  JOB_OFFER_LIST,
  JOB_OFFER_STUDENT_ALL,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const jobOfferList = createAsyncThunk(
  JOB_OFFER_LIST,
  async (params: PayloadCreatorParams<`${typeof API.JOB_OFFER}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.jobOfferList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobOfferCreate = createAsyncThunk(
  JOB_OFFER_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.JOB_OFFER}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.jobOfferCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobOfferChangeStatus = createAsyncThunk(
  JOB_OFFER_CHANGE_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.JOB_OFFER_CHANGE_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.jobOfferChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobOfferStudentAll = createAsyncThunk(
  JOB_OFFER_STUDENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.JOB_OFFER_STUDENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.jobOfferStudentAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobOfferListClear = createAction(`${JOB_OFFER_LIST}/clear`)

export const jobOfferStudentAllClear = createAction(`${JOB_OFFER_STUDENT_ALL}/clear`)
