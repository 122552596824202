import { lazy } from 'react'
import { INSTITUTION_PAGE_SETTINGS } from '../constants/route'
import * as roles from '@constants/userRoles'

export const InstitutionRoutes = [
  {
    path: INSTITUTION_PAGE_SETTINGS,
    component: lazy(() => import('../views/InstitutionPageSettings')),
    meta: {
      access: [roles.SUPER_ADMIN],
    },
  },
]
