import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, SEMESTER_ALL } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const SEMESTER = GLOBAL_STATE

export const { reducer: semesterReducer } = createSlice({
  name: SEMESTER,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.semesterAll.pending, state => {
        state[SEMESTER_ALL].loading = true
      })
      .addCase(actionCreators.semesterAll.rejected, (state, action) => {
        state[SEMESTER_ALL].loading = false
        state[SEMESTER_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.semesterAll.fulfilled, (state, action) => {
        state[SEMESTER_ALL].loading = false
        state[SEMESTER_ALL].error = null
        state[SEMESTER_ALL].data = action.payload
      })

    builder.addCase(actionCreators.semesterAllClear, state => {
      state[SEMESTER_ALL] = defaultState()
    })
  },
})
