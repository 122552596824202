export const GLOBAL_STATE = 'Admission' as const

export const APPLICATION = 'APPLICATION' as const
export const APPLICATION_CREATE = 'APPLICATION_CREATE' as const
export const APPLICATION_DELETE = 'APPLICATION_DELETE' as const
export const APPLICATION_LIST = 'APPLICATION_LIST' as const
export const APPLICATION_ALL = 'APPLICATION_ALL' as const
export const APPLICATION_TYPES = 'APPLICATION_TYPES' as const
export const APPLICATION_STATUS = 'APPLICATION_STATUS' as const

export const APPLICATION_FIELD_BATCH = 'APPLICATION_FIELD_BATCH' as const
export const APPLICATION_FIELD_BATCH_CREATE = 'APPLICATION_FIELD_BATCH_CREATE' as const

// category
// export const APPLICATION_CATEGORY = 'APPLICATION_CATEGORY' as const
// export const APPLICATION_CATEGORY_CREATE = 'APPLICATION_CATEGORY_CREATE' as const
// export const APPLICATION_CATEGORY_DELETE = 'APPLICATION_CATEGORY_DELETE' as const
// export const APPLICATION_CATEGORY_LIST = 'APPLICATION_CATEGORY_LIST' as const
// export const APPLICATION_CATEGORY_ALL = 'APPLICATION_CATEGORY_ALL' as const

// request
export const APPLICATION_REQUEST = 'APPLICATION_REQUEST' as const
export const APPLICATION_REQUEST_ALL = 'APPLICATION_REQUEST_ALL' as const
export const APPLICATION_REQUEST_EDIT = 'APPLICATION_REQUEST_EDIT' as const
export const APPLICATION_REQUEST_UPDATE = 'APPLICATION_REQUEST_UPDATE' as const
