export const FORM = '/form/' as const
export const FORM_ALL = `${FORM}all` as const
export const FORM_ACTION_TYPE = `${FORM}action_type/` as const
export const FORM_FIELD = `${FORM}field/` as const
export const FORM_FIELD_BATCH = `${FORM_FIELD}batch/` as const
export const FORM_TYPE = `${FORM}type/` as const
export const FORM_TYPE_ALL = `${FORM_TYPE}all` as const
export const FORM_TYPE_ACTIVATE = `${FORM_TYPE}activate/` as const
export const FORM_REQUEST = `${FORM}request/` as const
export const FORM_REQUEST_ALL = `${FORM_REQUEST}all` as const
export const FORM_REQUEST_CHANGE_STATUS = `${FORM_REQUEST}change/status` as const
export const FORM_REQUEST_FIELDS = `${FORM_REQUEST}fields` as const
export const FORM_REQUEST_CERTIFICATE = `/certificate/` as const
