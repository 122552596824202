import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const generalApplication: ApiFunc<`${typeof API.GENERAL_APPLICATION_FORM}{id}`, 'get'> = data =>
  instance.get(`${API.GENERAL_APPLICATION_FORM}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const generalApplicationCreate: ApiFunc<`${typeof API.GENERAL_APPLICATION_FORM}`, 'post'> = data =>
  instance.post(API.GENERAL_APPLICATION_FORM, data)

export const generalApplicationDelete: ApiFunc<`${typeof API.GENERAL_APPLICATION_FORM}{id}`, 'delete'> = data =>
  instance.delete(`${API.GENERAL_APPLICATION_FORM}${'id' in data ? prop('id', data) : data.name}`)

export const generalApplicationList: ApiFunc<`${typeof API.GENERAL_APPLICATION}`, 'get'> = data =>
  instance.get(API.GENERAL_APPLICATION, {
    params: data,
  })

export const generalApplicationAll: ApiFunc<`${typeof API.GENERAL_APPLICATION_ALL}`, 'get'> = data =>
  instance.get(API.GENERAL_APPLICATION_ALL, {
    params: data,
  })

export const generalApplicationTypes: ApiFunc<`${typeof API.GENERAL_APPLICATION_TYPES}`, 'get'> = data =>
  instance.get(API.GENERAL_APPLICATION_TYPES, {
    params: data,
  })

export const generalApplicationStatus: ApiFunc<`${typeof API.GENERAL_APPLICATION_STATUS}`, 'put'> = data =>
  instance.put(API.GENERAL_APPLICATION_STATUS, data)

export const generalApplicationFieldBatchCreate: ApiFunc<
  `${typeof API.GENERAL_APPLICATION_FIELD_BATCH}`,
  'post'
> = data => instance.post(API.GENERAL_APPLICATION_FIELD_BATCH, data)

export const generalApplicationFieldBatch: ApiFunc<`${typeof API.GENERAL_APPLICATION_FIELD_BATCH}{id}`, 'get'> = data =>
  instance.get(`${API.GENERAL_APPLICATION_FIELD_BATCH}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

//request
export const generalApplicationRequestCreate: ApiFunc<`${typeof API.GENERAL_APPLICATION_REQUEST}`, 'post'> = data =>
  instance.post(API.GENERAL_APPLICATION_REQUEST, data)

export const generalApplicationRequestAll: ApiFunc<`${typeof API.GENERAL_APPLICATION_REQUEST_ALL}`, 'get'> = data =>
  instance.get(API.GENERAL_APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const generalApplicationRequestEdit: ApiFunc<`${typeof API.GENERAL_APPLICATION_REQUEST_EDIT}`, 'get'> = data =>
  instance.get(API.GENERAL_APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const generalApplicationRequestUpdate: ApiFunc<
  `${typeof API.GENERAL_APPLICATION_REQUEST_UPDATE}`,
  'put'
> = data => instance.put(API.GENERAL_APPLICATION_REQUEST_UPDATE, data)

//category
// export const generalApplicationCategory: ApiFunc<`${typeof API.GENERAL_APPLICATION_CATEGORY}`, 'get'>: ApiFunc<`${typeof API.GENERAL_APPLICATION}`, 'get'> = data =>
//   instance.get(`${API.GENERAL_APPLICATION_CATEGORY}${'id' in data ? prop('id', data) : data.name}`, {
//     params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
//   })

// export const generalApplicationCategoryCreate: ApiFunc<`${typeof API.GENERAL_APPLICATION}`, 'get'> = data => instance.post(API.GENERAL_APPLICATION_CATEGORY, data)

// export const generalApplicationCategoryDelete: ApiFunc<`${typeof API.GENERAL_APPLICATION}`, 'get'> = data =>
//   instance.delete(`${API.GENERAL_APPLICATION_CATEGORY}${'id' in data ? prop('id', data) : data.name}`)

// export const generalApplicationCategoryList: ApiFunc<`${typeof API.GENERAL_APPLICATION}`, 'get'> = data =>
//   instance.get(API.GENERAL_APPLICATION_CATEGORY, {
//     params: data,
//   })

// export const generalApplicationCategoryAll: ApiFunc<`${typeof API.GENERAL_APPLICATION}`, 'get'> = data =>
//   instance.get(API.GENERAL_APPLICATION_CATEGORY_ALL, {
//     params: data,
//   })
