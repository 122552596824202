import { actionPending, actionFulfilled, actionEdit, actionRejected, actionClear, actionCancel } from './createState'

export function createAction(actionName) {
  return {
    pending: actionPending(actionName),
    fulfilled: actionFulfilled(actionName),
    edit: actionEdit(actionName),
    rejected: actionRejected(actionName),
    clear: actionClear(actionName),
    cancel: actionCancel(actionName),
  }
}
