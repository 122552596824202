import { DefaultState } from '@src/redux/types'

import {
  ASSESSMENT_ATTEMPT_CREATE,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_BULK,
  ASSESSMENT_COMPONENT_CREATE,
  ASSESSMENT_COMPONENT_DELETE,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_LIST,
  ASSESSMENT_STUDENT,
  ASSESSMENT_STUDENT_CRITERIA_DETAILS,
  ASSESSMENT_STUDENT_UPDATED,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_SUBMIT,
  ATTEMPT_DELETE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ASSESSMENT_ATTEMPT_CREATE]: DefaultState<`${typeof API.ASSESSMENT_ATTEMPT}`, 'post'>
  [ASSESSMENT_ATTEMPT_ITEM]: DefaultState<`${typeof API.ASSESSMENT_ATTEMPT}`, 'get'>
  [ASSESSMENT_BULK]: DefaultState<`${typeof API.ASSESSMENT_BULK}`, 'post'>
  [ASSESSMENT_COMPONENT_CREATE]: DefaultState<`${typeof API.ASSESSMENT_COMPONENT}`, 'post'>
  [ASSESSMENT_COMPONENT_DELETE]: DefaultState<`${typeof API.ASSESSMENT_COMPONENT}{id}`, 'delete'>
  [ASSESSMENT_COPY]: DefaultState<`${typeof API.ASSESSMENT_COPY}`, 'post'>
  [ASSESSMENT_CREATE]: DefaultState<`${typeof API.ASSESSMENT}`, 'post'>
  [ASSESSMENT_CRITERIA_LIST]: DefaultState<`${typeof API.ASSESSMENT_CRITERIA_LIST}`, 'get'>
  [ASSESSMENT_CRITERIA_TYPES]: DefaultState<`${typeof API.ASSESSMENT_CRITERIA_TYPES}`, 'get'>
  [ASSESSMENT_CRITERIA_UPDATE]: DefaultState<`${typeof API.ASSESSMENT_CRITERIA}`, 'post'>
  [ASSESSMENT_LIST]: DefaultState<`${typeof API.ASSESSMENT}`, 'get'>
  [ASSESSMENT_STUDENT]: DefaultState<`${typeof API.ASSESSMENT_STUDENT}`, 'get'>
  [ASSESSMENT_STUDENT_UPDATED]: DefaultState<`${typeof API.ASSESSMENT_STUDENT_UPDATED}`, 'get'>
  [ASSESSMENT_STUDENT_CRITERIA_DETAILS]: DefaultState<`${typeof API.ASSESSMENT_STUDENT_CRITERIA_DETAILS}`, 'get'>
  [ASSESSMENT_STUDENT_UPLOAD]: DefaultState<`${typeof API.ASSESSMENT_STUDENT_UPLOAD}`, 'post'>
  [ASSESSMENT_SUBMIT]: DefaultState<`${typeof API.ASSESSMENT_SUBMIT}`, 'post'>
  [ATTEMPT_DELETE]: DefaultState<`${typeof API.ASSESSMENT_ATTEMPT}`, 'delete'>
} = () => ({
  [ASSESSMENT_ATTEMPT_CREATE]: defaultState(),
  [ASSESSMENT_ATTEMPT_ITEM]: defaultState(),
  [ASSESSMENT_BULK]: defaultState(),
  [ASSESSMENT_COMPONENT_CREATE]: defaultState(),
  [ASSESSMENT_COMPONENT_DELETE]: defaultState(),
  [ASSESSMENT_COPY]: defaultState(),
  [ASSESSMENT_CREATE]: defaultState(),
  [ASSESSMENT_CRITERIA_LIST]: defaultState(),
  [ASSESSMENT_CRITERIA_TYPES]: defaultState(),
  [ASSESSMENT_CRITERIA_UPDATE]: defaultState(),
  [ASSESSMENT_LIST]: defaultState(),
  [ASSESSMENT_STUDENT_CRITERIA_DETAILS]: defaultState(),
  [ASSESSMENT_STUDENT]: defaultState(),
  [ASSESSMENT_STUDENT_UPDATED]: defaultState(),
  [ASSESSMENT_STUDENT_UPLOAD]: defaultState(),
  [ASSESSMENT_SUBMIT]: defaultState(),
  [ATTEMPT_DELETE]: defaultState(),
})
