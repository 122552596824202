import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  FORM,
  FORM_ACTION_TYPE,
  FORM_ALL,
  FORM_CREATE,
  FORM_DELETE,
  FORM_FIELD_BATCH,
  FORM_FIELD_BATCH_CREATE,
  FORM_FIELD_CREATE,
  FORM_LIST,
  FORM_REQUEST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CERTIFICATE,
  FORM_REQUEST_CHANGE_STATUS,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_DELETE,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_LIST,
  FORM_TYPE,
  FORM_TYPE_ALL,
  FORM_TYPE_CREATE,
  FORM_TYPE_DELETE,
  FORM_TYPE_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const FORMS = GLOBAL_STATE

export const { reducer: formReducer } = createSlice({
  name: FORMS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.form.pending, state => {
        state[FORM].loading = true
      })
      .addCase(actionCreators.form.rejected, (state, action) => {
        state[FORM].loading = false
        state[FORM].error = action.payload as AxiosError
      })
      .addCase(actionCreators.form.fulfilled, (state, action) => {
        state[FORM].loading = false
        state[FORM].error = null
        state[FORM].data = action.payload
      })

    builder
      .addCase(actionCreators.formActionType.pending, state => {
        state[FORM_ACTION_TYPE].loading = true
      })
      .addCase(actionCreators.formActionType.rejected, (state, action) => {
        state[FORM_ACTION_TYPE].loading = false
        state[FORM_ACTION_TYPE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formActionType.fulfilled, (state, action) => {
        state[FORM_ACTION_TYPE].loading = false
        state[FORM_ACTION_TYPE].error = null
        state[FORM_ACTION_TYPE].data = action.payload
      })

    builder
      .addCase(actionCreators.formAll.pending, state => {
        state[FORM_ALL].loading = true
      })
      .addCase(actionCreators.formAll.rejected, (state, action) => {
        state[FORM_ALL].loading = false
        state[FORM_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formAll.fulfilled, (state, action) => {
        state[FORM_ALL].loading = false
        state[FORM_ALL].error = null
        state[FORM_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.formCreate.pending, state => {
        state[FORM_CREATE].loading = true
      })
      .addCase(actionCreators.formCreate.rejected, (state, action) => {
        state[FORM_CREATE].loading = false
        state[FORM_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formCreate.fulfilled, (state, action) => {
        state[FORM_CREATE].loading = false
        state[FORM_CREATE].error = null
        state[FORM_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.formDelete.pending, state => {
        state[FORM_DELETE].loading = true
      })
      .addCase(actionCreators.formDelete.rejected, (state, action) => {
        state[FORM_DELETE].loading = false
        state[FORM_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formDelete.fulfilled, (state, action) => {
        state[FORM_DELETE].loading = false
        state[FORM_DELETE].error = null
        state[FORM_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.formFieldBatch.pending, state => {
        state[FORM_FIELD_BATCH].loading = true
      })
      .addCase(actionCreators.formFieldBatch.rejected, (state, action) => {
        state[FORM_FIELD_BATCH].loading = false
        state[FORM_FIELD_BATCH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formFieldBatch.fulfilled, (state, action) => {
        state[FORM_FIELD_BATCH].loading = false
        state[FORM_FIELD_BATCH].error = null
        state[FORM_FIELD_BATCH].data = action.payload
      })

    builder
      .addCase(actionCreators.formFieldBatchCreate.pending, state => {
        state[FORM_FIELD_BATCH_CREATE].loading = true
      })
      .addCase(actionCreators.formFieldBatchCreate.rejected, (state, action) => {
        state[FORM_FIELD_BATCH_CREATE].loading = false
        state[FORM_FIELD_BATCH_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formFieldBatchCreate.fulfilled, (state, action) => {
        state[FORM_FIELD_BATCH_CREATE].loading = false
        state[FORM_FIELD_BATCH_CREATE].error = null
        state[FORM_FIELD_BATCH_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.formFieldCreate.pending, state => {
        state[FORM_FIELD_CREATE].loading = true
      })
      .addCase(actionCreators.formFieldCreate.rejected, (state, action) => {
        state[FORM_FIELD_CREATE].loading = false
        state[FORM_FIELD_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formFieldCreate.fulfilled, (state, action) => {
        state[FORM_FIELD_CREATE].loading = false
        state[FORM_FIELD_CREATE].error = null
        state[FORM_FIELD_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.formList.pending, state => {
        state[FORM_LIST].loading = true
      })
      .addCase(actionCreators.formList.rejected, (state, action) => {
        state[FORM_LIST].loading = false
        state[FORM_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formList.fulfilled, (state, action) => {
        state[FORM_LIST].loading = false
        state[FORM_LIST].error = null
        state[FORM_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequest.pending, state => {
        state[FORM_REQUEST].loading = true
      })
      .addCase(actionCreators.formRequest.rejected, (state, action) => {
        state[FORM_REQUEST].loading = false
        state[FORM_REQUEST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequest.fulfilled, (state, action) => {
        state[FORM_REQUEST].loading = false
        state[FORM_REQUEST].error = null
        state[FORM_REQUEST].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestAll.pending, state => {
        state[FORM_REQUEST_ALL].loading = true
      })
      .addCase(actionCreators.formRequestAll.rejected, (state, action) => {
        state[FORM_REQUEST_ALL].loading = false
        state[FORM_REQUEST_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestAll.fulfilled, (state, action) => {
        state[FORM_REQUEST_ALL].loading = false
        state[FORM_REQUEST_ALL].error = null
        state[FORM_REQUEST_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestCertificate.pending, state => {
        state[FORM_REQUEST_CERTIFICATE].loading = true
      })
      .addCase(actionCreators.formRequestCertificate.rejected, (state, action) => {
        state[FORM_REQUEST_CERTIFICATE].loading = false
        state[FORM_REQUEST_CERTIFICATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestCertificate.fulfilled, (state, action) => {
        state[FORM_REQUEST_CERTIFICATE].loading = false
        state[FORM_REQUEST_CERTIFICATE].error = null
        state[FORM_REQUEST_CERTIFICATE].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestChangeStatus.pending, state => {
        state[FORM_REQUEST_CHANGE_STATUS].loading = true
      })
      .addCase(actionCreators.formRequestChangeStatus.rejected, (state, action) => {
        state[FORM_REQUEST_CHANGE_STATUS].loading = false
        state[FORM_REQUEST_CHANGE_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestChangeStatus.fulfilled, (state, action) => {
        state[FORM_REQUEST_CHANGE_STATUS].loading = false
        state[FORM_REQUEST_CHANGE_STATUS].error = null
        state[FORM_REQUEST_CHANGE_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestCreate.pending, state => {
        state[FORM_REQUEST_CREATE].loading = true
      })
      .addCase(actionCreators.formRequestCreate.rejected, (state, action) => {
        state[FORM_REQUEST_CREATE].loading = false
        state[FORM_REQUEST_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestCreate.fulfilled, (state, action) => {
        state[FORM_REQUEST_CREATE].loading = false
        state[FORM_REQUEST_CREATE].error = null
        state[FORM_REQUEST_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestDelete.pending, state => {
        state[FORM_REQUEST_DELETE].loading = true
      })
      .addCase(actionCreators.formRequestDelete.rejected, (state, action) => {
        state[FORM_REQUEST_DELETE].loading = false
        state[FORM_REQUEST_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestDelete.fulfilled, (state, action) => {
        state[FORM_REQUEST_DELETE].loading = false
        state[FORM_REQUEST_DELETE].error = null
        state[FORM_REQUEST_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestFields.pending, state => {
        state[FORM_REQUEST_FIELDS].loading = true
      })
      .addCase(actionCreators.formRequestFields.rejected, (state, action) => {
        state[FORM_REQUEST_FIELDS].loading = false
        state[FORM_REQUEST_FIELDS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestFields.fulfilled, (state, action) => {
        state[FORM_REQUEST_FIELDS].loading = false
        state[FORM_REQUEST_FIELDS].error = null
        state[FORM_REQUEST_FIELDS].data = action.payload
      })

    builder
      .addCase(actionCreators.formRequestList.pending, state => {
        state[FORM_REQUEST_LIST].loading = true
      })
      .addCase(actionCreators.formRequestList.rejected, (state, action) => {
        state[FORM_REQUEST_LIST].loading = false
        state[FORM_REQUEST_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formRequestList.fulfilled, (state, action) => {
        state[FORM_REQUEST_LIST].loading = false
        state[FORM_REQUEST_LIST].error = null
        state[FORM_REQUEST_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.formType.pending, state => {
        state[FORM_TYPE].loading = true
      })
      .addCase(actionCreators.formType.rejected, (state, action) => {
        state[FORM_TYPE].loading = false
        state[FORM_TYPE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formType.fulfilled, (state, action) => {
        state[FORM_TYPE].loading = false
        state[FORM_TYPE].error = null
        state[FORM_TYPE].data = action.payload
      })

    builder
      .addCase(actionCreators.formTypeAll.pending, state => {
        state[FORM_TYPE_ALL].loading = true
      })
      .addCase(actionCreators.formTypeAll.rejected, (state, action) => {
        state[FORM_TYPE_ALL].loading = false
        state[FORM_TYPE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formTypeAll.fulfilled, (state, action) => {
        state[FORM_TYPE_ALL].loading = false
        state[FORM_TYPE_ALL].error = null
        state[FORM_TYPE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.formTypeCreate.pending, state => {
        state[FORM_TYPE_CREATE].loading = true
      })
      .addCase(actionCreators.formTypeCreate.rejected, (state, action) => {
        state[FORM_TYPE_CREATE].loading = false
        state[FORM_TYPE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formTypeCreate.fulfilled, (state, action) => {
        state[FORM_TYPE_CREATE].loading = false
        state[FORM_TYPE_CREATE].error = null
        state[FORM_TYPE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.formTypeDelete.pending, state => {
        state[FORM_TYPE_DELETE].loading = true
      })
      .addCase(actionCreators.formTypeDelete.rejected, (state, action) => {
        state[FORM_TYPE_DELETE].loading = false
        state[FORM_TYPE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formTypeDelete.fulfilled, (state, action) => {
        state[FORM_TYPE_DELETE].loading = false
        state[FORM_TYPE_DELETE].error = null
        state[FORM_TYPE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.formTypeList.pending, state => {
        state[FORM_TYPE_LIST].loading = true
      })
      .addCase(actionCreators.formTypeList.rejected, (state, action) => {
        state[FORM_TYPE_LIST].loading = false
        state[FORM_TYPE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.formTypeList.fulfilled, (state, action) => {
        state[FORM_TYPE_LIST].loading = false
        state[FORM_TYPE_LIST].error = null
        state[FORM_TYPE_LIST].data = action.payload
      })

    builder.addCase(actionCreators.formClear, state => {
      state[FORM] = defaultState()
    })

    builder.addCase(actionCreators.formAllClear, state => {
      state[FORM_ALL] = defaultState()
    })

    builder.addCase(actionCreators.formFieldBatchClear, state => {
      state[FORM_FIELD_BATCH] = defaultState()
    })

    builder.addCase(actionCreators.formListClear, state => {
      state[FORM_LIST] = defaultState()
    })

    builder.addCase(actionCreators.formRequestAllClear, state => {
      state[FORM_REQUEST_ALL] = defaultState()
    })

    builder.addCase(actionCreators.formRequestClear, state => {
      state[FORM_REQUEST] = defaultState()
    })

    builder.addCase(actionCreators.formRequestFieldsClear, state => {
      state[FORM_REQUEST_FIELDS] = defaultState()
    })

    builder.addCase(actionCreators.formRequestListClear, state => {
      state[FORM_REQUEST_LIST] = defaultState()
    })

    builder.addCase(actionCreators.formTypeAllClear, state => {
      state[FORM_TYPE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.formTypeListClear, state => {
      state[FORM_TYPE_LIST] = defaultState()
    })
  },
})
