import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  INTERNAL_EXAM_APPLICANTS_LIST,
  INTERNAL_EXAM_PLACE,
  INTERNAL_EXAM_PLACE_ALL,
  INTERNAL_EXAM_PLACE_CREATE,
  INTERNAL_EXAM_PLACE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const internalExamPlace = createAsyncThunk(
  INTERNAL_EXAM_PLACE,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.internalExamPlace(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamPlaceAll = createAsyncThunk(
  INTERNAL_EXAM_PLACE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.internalExamPlaceAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamPlaceCreate = createAsyncThunk(
  INTERNAL_EXAM_PLACE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.internalExamPlaceCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const internalExamPlaceDelete = createAsyncThunk(
//   INTERNAL_EXAM_PLACE_DELETE,
//   async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'delete'>, { rejectWithValue }) => {
//     try {
//       const response = await api.internalExamPlaceDelete(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const internalExamPlaceList = createAsyncThunk(
  INTERNAL_EXAM_PLACE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_PLACE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.internalExamPlaceList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamApplicantsList = createAsyncThunk(
  INTERNAL_EXAM_APPLICANTS_LIST,
  async (params: PayloadCreatorParams<`${typeof API.INTERNAL_EXAM_APPLICANTS}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.internalExamApplicantsList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const internalExamPlaceClear = createAction(`${INTERNAL_EXAM_PLACE}/clear`)

export const internalExamPlaceListClear = createAction(`${INTERNAL_EXAM_PLACE_LIST}/clear`)

export const internalExamPlaceAllClear = createAction(`${INTERNAL_EXAM_PLACE_ALL}/clear`)

export const internalExamApplicantsListClear = createAction(`${INTERNAL_EXAM_APPLICANTS_LIST}/clear`)
