export const GLOBAL_STATE = 'Lesson'

export const LESSON = 'LESSON' as const
export const LESSON_CREATE = 'LESSON_CREATE' as const
export const LESSON_DELETE = 'LESSON_DELETE' as const
export const LESSON_LIST = 'LESSON_LIST' as const
export const LESSON_ALL = 'LESSON_ALL' as const
export const LESSON_SORTING = 'LESSON_SORTING' as const
export const LESSON_HOMEWORK_UPLOAD = 'LESSON_HOMEWORK_UPLOAD' as const
export const LESSON_ASSESSMENT = 'LESSON_ASSESSMENT' as const
export const LESSON_ASSESSMENT_ALL = 'LESSON_ASSESSMENT_ALL' as const
export const LESSON_MARK_ASSESSMENT = 'LESSON_MARK_ASSESSMENT' as const
export const LESSON_ARTICULATE = 'LESSON_ARTICULATE' as const
export const LESSON_ARTICULATE_ALL = 'LESSON_ARTICULATE_ALL' as const
export const LESSON_ARTICULATE_CREATE = 'LESSON_ARTICULATE_CREATE' as const
export const LESSON_ARTICULATE_DELETE = 'LESSON_ARTICULATE_DELETE' as const
export const LESSON_ARTICULATE_PROGRESS = 'LESSON_ARTICULATE_PROGRESS' as const
