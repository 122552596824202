import { lazy } from 'react'
import { STUDENT_DASHBOARD } from '../constants/route'
import {BACHELOR_STUDENT, MASTER_STUDENT, TEST_STUDENT} from '@constants/userRoles'

export const StudentDashboardRoutes = [
  {
    path: STUDENT_DASHBOARD,
    component: lazy(() => import('../views/StudentDashboard')),
    meta: {
      access: [BACHELOR_STUDENT, TEST_STUDENT, MASTER_STUDENT],
    },
    componentProps: {
      type: 'DASHBOARD',
    },
  },
]
