import React, { forwardRef, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { components as reactSelectComponents } from 'react-select'
import { isEmpty, map, path, prop, propOr, length } from 'ramda/es'
import classNames from 'classnames'
import { Button, FormGroup, FormText, Label } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Check } from 'react-feather'

import { selectThemeColors } from '@utils'
import { skinSelector } from '../../redux/selector'

const createOption = (label) => ({
  label,
  value: label,
})

const CustomControl = ({ children, ...props }) => {
  function appendOption() {
    !isEmpty(prop('options', props)) &&
    props.selectProps.handleCreateOption(path(['selectProps', 'inputValue'], props))
  }

  return (
    <reactSelectComponents.Control {...props}>
      {
        path(['selectProps', 'inputValue'], props) ? (
          <Button
            color='flat-primary'
            onClick={appendOption}
            style={{
              padding: '0.5rem 0.8rem 0.5rem 0.8rem',
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
              borderTopLeftRadius: '0.2rem',
              borderBottomLeftRadius: '0.2rem',
            }}
          >
            <Check size={16} />
          </Button>
        ) : null
      }
      {children}
    </reactSelectComponents.Control>
  )
}

const components = {
  DropdownIndicator: null,
  Control: CustomControl,
}

const TagInputField = forwardRef((props, ref) => {
  const {
    label,
    disabled,
    className,
    name,
    onValueChange = () => {},
    onInputValueChange = () => {},
    error,
    isSubmitted,
    value,
    onChange = () => {},
    onFocus = () => {},
    handleFocus = () => {},
    isClearable,
    id,
    normalize,
    shouldUpdateValue,
    manualValue = [],
    formGroupStyles = {},
  } = props

  const skin = useSelector(skinSelector)
  const [state, setState] = useState({
    inputValue: '',
    value: [],
  })

  const valueLength = length(value || [])
  const stateValueLength = length(propOr([], 'value', state))

  const customStyles = {
    control: provided => {
      return {
        ...provided,
        alignItems: 'stretch',
      }
    },
    input: provided => {
      return {
        ...provided,
        color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b',
      }
    },
  }

  useEffect(() => {
    if (normalize) {
      const normalized = map(prop('value'))(propOr([], 'value', state))
      onChange(normalized)
      onValueChange(normalized)
    } else {
      onChange(prop('value', state))
      onValueChange(prop('value', state))
    }
  }, [stateValueLength])

  useEffect(() => {
    Array.isArray(value) && !isEmpty(value) &&
    setState({
      inputValue: '',
      value: map(value => ({ label: value, value }))(value),
    })
  }, [valueLength])

  // useEffect(() => {
  //   if (state.value && !isEmpty(state.value) && shouldUpdateValue) {
  //     setState({
  //       inputValue: '',
  //       value: manualValue,
  //     })
  //   }
  // }, [shouldUpdateValue, state.value])

  function handleValueChange(value) {
    setState(prevState => ({ ...prevState, value }))
  }

  function handleInputChange(inputValue) {
    setState(prevState => ({ ...prevState, inputValue }))
    onInputValueChange(inputValue)
  }

  function handleCreateOption(newOption) {
    if (!newOption) return
    setState({
      inputValue: '',
      value: [...propOr([], 'value', state), createOption(newOption)],
    })
  }

  function handleKeyDown(e) {
    const { inputValue } = state
    if (!inputValue) return
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        handleCreateOption(inputValue)
        e.preventDefault()
    }
  }

  const htmlFor = id ? { for: id } : {}
  const htmlId = id ? { id } : {}

  return (
    <FormGroup style={formGroupStyles}>
      {label && <Label {...htmlFor}>{label}</Label>}
      <CreatableSelect
        {...props}
        name={name}
        components={components}
        inputValue={prop('inputValue', state)}
        styles={customStyles}
        isClearable={isClearable}
        isMulti
        menuIsOpen={false}
        value={prop('value', state)}
        onInputChange={handleInputChange}
        onChange={handleValueChange}
        handleCreateOption={handleCreateOption}
        onKeyDown={handleKeyDown}
        classNamePrefix='select'
        className={classNames(
          error
            ? `react-select-error is-invalid react-select ${className}`
            : `react-select ${className}`,
        )}
        ref={ref}
        isDisabled={disabled}
        {...htmlId}
        theme={selectThemeColors}
      />
      {error && (
        <FormText color='primary' style={{ paddingLeft: '15px' }}>
          <FormattedMessage id='form_required' />
        </FormText>
      )}
    </FormGroup>
  )
})

export default TagInputField
