import { trim } from 'ramda/es'

const textInputFieldValidator = (value, min = 0, max = 9999) => {
  if (!value) return { message: 'required', type: 'required' }
  if (!isNaN(min) && !isNaN(max)) {
    return !(trim(value).length > min && trim(value).length < max) ? { message: `Value must be more than ${min} and less than ${max}`, type: 'range' } : null
  }
  if (!isNaN(min)) {
    return trim(value).length <= min ? { message: `Value must be more than ${min}`, type: 'min' } : null
  }
  if (!isNaN(max)) {
    return trim(value).length >= max ? { message: `Value must not be more than ${max}`, type: 'max' } : null
  }
}
export default textInputFieldValidator
