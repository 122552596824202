import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_LIST,
  FEEDBACK_STATUS,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const feedbackAll = createAsyncThunk(
  FEEDBACK_ALL,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackCreate = createAsyncThunk(
  FEEDBACK_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackList = createAsyncThunk(
  FEEDBACK_LIST,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackTypes = createAsyncThunk(
  FEEDBACK_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackUpdate = createAsyncThunk(
  FEEDBACK_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK_UPDATE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackEdit = createAsyncThunk(
  FEEDBACK_EDIT,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK_EDIT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackEdit(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackStatus = createAsyncThunk(
  FEEDBACK_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.FEEDBACK_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.feedbackStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const feedbackAllClear = createAction(`${FEEDBACK_ALL}/clear`)

export const feedbackListClear = createAction(`${FEEDBACK_LIST}/clear`)

export const feedbackTypesClear = createAction(`${FEEDBACK_TYPES}/clear`)

export const feedbackEditClear = createAction(`${FEEDBACK_EDIT}/clear`)
