import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, LEVEL_ALL } from '../../constants/level/actionTypes'

const levelSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

// export const levelSelector = createSelector(path([GLOBAL_STATE, LEVEL]), data => data)

// export const levelListSelector = createSelector(path([GLOBAL_STATE, LEVEL_LIST]), data => data)

export const levelAllSelector = createSelector(levelSelectors, prop(LEVEL_ALL))

export const levelAllOptionsSelector = createSelector(levelAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({ label: item.title || '', value: item.id }))
      : [],
  }
})

// export const levelCreateSelector = createSelector(path([GLOBAL_STATE, LEVEL_CREATE]), data => data)
