import themeConfig from '@configs/themeConfig'
import * as isUser from '@helpers/isUser'
import { setToken } from '@helpers/storageToken'
import { useAppDispatch, useAppSelector } from '@hooks'
import { useSkin } from '@hooks/useSkin'
import IntlDropdown from '@layouts/components/navbar/IntlDropdown'
import { getLanguage } from '@src/helpers/storageLanguage'
import * as actions from '@store/actions'
import * as selectors from '@store/selector'
import '@styles/base/pages/page-auth.scss'
import { prop, propOr } from 'ramda'
import { useCallback } from 'react'
import { Moon, Sun } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Alert, CardText, CardTitle, Col, Row } from 'reactstrap'
import styled from 'styled-components'

import { LoginForm } from '../components/LoginForm'
import { emailRegex } from '../regex'

const FormWrapper = styled('div')`
  @media (max-width: 640px) {
    width: 100%;
  }
  margin-left: auto;
  margin-right: auto;
  width: 540px;
`

const Login = () => {
  const dispatch = useAppDispatch()
  const { formatMessage: msg } = useIntl()
  const [skin, setSkin] = useSkin()

  const userInfo = useAppSelector(selectors.authUserInfo)
  const auth = useAppSelector(selectors.authSelector)
  const login = useAppSelector(selectors.authLogin)
  const token = prop('token', auth)
  // const { setInstitution, setEduForm } = useContext(UserSettingsContext)

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    control,
    formState: { errors },
  } = useForm()

  const ThemeToggler = useCallback(() => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }, [skin])

  const illustration = skin === 'dark' ? 'login-v2-dark.svg' : 'login-v2.svg',
    source = require(`@src/assets/images/pages/${illustration}`).default
  const shouldUpdatePass = isUser.isUpdatePassword(userInfo)

  function onSubmit(data) {
    if (shouldUpdatePass) {
      token && setToken(token)
      dispatch(
        actions.accountPasswordReset({
          username: prop('username', data),
          new_password: prop('password', data),
          auth,
        })
      )
    } else {
      const phone = data.phone ? data.phone.match(/([0-9])/g).join('') : undefined
      dispatch(
        actions.authLogin({
          ...data,
          phone,
          locale: getLanguage(),
          type: phone ? 'PHONE' : String(data.username).toLowerCase().match(emailRegex) ? 'EMAIL' : 'ID',
        })
      )
      // .unwrap()
      // .then(res => {
      //   res.redirect_url ? dispatch(push(res.redirect_url)) : amI(GUEST_USER) && dispatch(push(MY_APPLICATIONS))
      //   res.user_info?.selected_locale && intlContext.switchLanguage(res.user_info?.selected_locale.value)
      //   setEduForm(res.user_info.selected_education_form)
      //   setInstitution(res.user_info.selected_institution)
      // })
    }
  }

  return (
    <div className='auth-wrapper auth-v2'>
      <Row className='auth-inner m-0'>
        <Col
          className='d-none d-lg-flex align-items-center p-5'
          lg='8'
          sm='12'
          style={{
            background: `url(${themeConfig.app.loginBgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Link className='brand-logo' to='/'>
            <Row className='flex-row'>
              <Col sm={4} md={12}>
                <img
                  className='img-fluid'
                  src={themeConfig.app[skin === 'dark' ? 'appLogoLight' : 'appLogo']}
                  alt='logo'
                />
              </Col>
            </Row>
          </Link>

          <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
            {/*<img className='img-fluid' src={source} alt='Login V2' />*/}
          </div>
        </Col>

        <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>
          <div className='custom-additional-box'>
            <ul className='nav align-items-center justify-content-center'>
              <li className='nav-item mr-1 cursor-pointer'>
                <ThemeToggler />
              </li>
              <IntlDropdown className='cursor-pointer' />
            </ul>
          </div>
          <FormWrapper className='px-xl-2'>
            <CardTitle tag='h2' className='font-weight-bold mb-1'>
              {msg({ id: 'welcomeText' })}
            </CardTitle>

            <CardText className='mb-2'>
              {msg({ id: shouldUpdatePass ? 'Please enter new password' : 'Please sign-in to your account' })}
            </CardText>
            <CardText tag='div'>
              <Alert color='primary' isOpen={!!login}>
                <div className='alert-body'>
                  <span className='ml-1'>
                    <strong>{propOr('', 'status', login)}:</strong> {propOr('', 'message', login)}
                  </span>
                </div>
              </Alert>
            </CardText>

            <LoginForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              control={control}
              watch={watch}
              unregister={unregister}
              shouldUpdatePass={shouldUpdatePass}
            />

            {/* <div className='divider'>
              <div className='divider-text text-uppercase'>{msg({ id: 'or' })}</div>
            </div>

            <div className='text-center'>
              <Link to='/registration' className='text-primary'>
                {msg({ id: 'Create a new account' })}
              </Link>
            </div> */}

            {/*<p className='text-center mt-2'>*/}
            {/*  <span className='mr-25'>New on our platform?</span>*/}
            {/*  <Link to='/'>*/}
            {/*    <span>Create an account</span>*/}
            {/*  </Link>*/}
            {/*</p>*/}

            {/*<div className='divider my-2'>*/}
            {/*  <div className='divider-text'>or</div>*/}
            {/*</div>*/}

            {/*<div className='auth-footer-btn d-flex justify-content-center'>*/}
            {/*  <Button.Ripple color='facebook'>*/}
            {/*    <Facebook size={14} />*/}
            {/*  </Button.Ripple>*/}
            {/*  <Button.Ripple color='twitter'>*/}
            {/*    <Twitter size={14} />*/}
            {/*  </Button.Ripple>*/}
            {/*  <Button.Ripple color='google'>*/}
            {/*    <Mail size={14} />*/}
            {/*  </Button.Ripple>*/}
            {/*  <Button.Ripple className='mr-0' color='github'>*/}
            {/*    <GitHub size={14} />*/}
            {/*  </Button.Ripple>*/}
            {/*</div>*/}
          </FormWrapper>
        </Col>
      </Row>
    </div>
  )
}

export default Login
