import { DefaultState } from '@src/redux/types'

import { STUDENT_CV, STUDENT_CV_CURATOR, STUDENT_CV_LIST, STUDENT_CV_PRINCIPAL } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [STUDENT_CV]: DefaultState<`${typeof API.STUDENT_CV}{id}`, 'get'>
  [STUDENT_CV_LIST]: DefaultState<`${typeof API.STUDENT_CV}`, 'get'>
  [STUDENT_CV_PRINCIPAL]: DefaultState<`${typeof API.STUDENT_CV_PRINCIPAL}`, 'get'>
  [STUDENT_CV_CURATOR]: DefaultState<`${typeof API.STUDENT_CV_CURATOR}`, 'get'>
} = () => ({
  [STUDENT_CV]: defaultState(),
  [STUDENT_CV_LIST]: defaultState(),
  [STUDENT_CV_PRINCIPAL]: defaultState(),
  [STUDENT_CV_CURATOR]: defaultState(),
})
