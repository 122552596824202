import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_ALL,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_DELETE,
  ACADEMIC_YEAR_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const academicYear = createAsyncThunk(
  ACADEMIC_YEAR,
  async (params: PayloadCreatorParams<`${typeof API.ACADEMIC_YEAR}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.academicYear(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const academicYearCreate = createAsyncThunk(
  ACADEMIC_YEAR_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ACADEMIC_YEAR}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.academicYearCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const academicYearDelete = createAsyncThunk(
  ACADEMIC_YEAR_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ACADEMIC_YEAR}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.academicYearDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const academicYearList = createAsyncThunk(
  ACADEMIC_YEAR_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ACADEMIC_YEAR}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.academicYearList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const academicYearAll = createAsyncThunk(
  ACADEMIC_YEAR_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ACADEMIC_YEAR_ALL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.academicYearAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const academicYearClear = createAction(`${ACADEMIC_YEAR}/clear`)

export const academicYearAllClear = createAction(`${ACADEMIC_YEAR_ALL}/clear`)

export const academicYearListClear = createAction(`${ACADEMIC_YEAR_LIST}/clear`)
