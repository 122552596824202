import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_DELETE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_LIST,
} from '../constants/actionTypes'

const stcSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const stcSelector = createSelector(stcSelectors, prop(STC))
export const stcCreateSelector = createSelector(stcSelectors, prop(STC_CREATE))
export const stcListSelector = createSelector(stcSelectors, prop(STC_LIST))
export const stcAllSelector = createSelector(stcSelectors, prop(STC_ALL))

// category
export const stcCategorySelector = createSelector(stcSelectors, prop(STC_CATEGORY))
export const stcCategoryCreateSelector = createSelector(stcSelectors, prop(STC_CATEGORY_CREATE))
export const stcCategoryDeleteSelector = createSelector(stcSelectors, prop(STC_CATEGORY_DELETE))
export const stcCategoryListSelector = createSelector(stcSelectors, prop(STC_CATEGORY_LIST))
export const stcCategoryAllSelector = createSelector(stcSelectors, prop(STC_CATEGORY_ALL))

// step
export const stcStepSelector = createSelector(stcSelectors, prop(STC_STEP))
export const stcStepListSelector = createSelector(stcSelectors, prop(STC_STEP_LIST))
export const stcStepAllSelector = createSelector(stcSelectors, prop(STC_STEP_ALL))
