import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { prop } from 'ramda'

import {
  AUTH_LOGIN,
  SIGN_UP,
  SMS_SEND,
  TOKEN,
  USER_INFO,
  USER_INFO_HISTORY,
  VERIFY_EMAIL,
} from '../constants/actionTypes'
import { AUTH } from './reducer'

export const authSelector = (state: RootState) => prop(AUTH, state)

export const authToken = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[TOKEN])

export const authUserInfo = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[USER_INFO])

export const userInfoHistorySelector = createSelector(
  authSelector,
  (state: ReturnType<typeof authSelector>) => state[USER_INFO_HISTORY]
)

export const userAcademicYears = createSelector(
  authUserInfo,
  (state: ReturnType<typeof authUserInfo>) => state?.academic_years
)

export const authLogin = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[AUTH_LOGIN])

export const authSignUp = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[SIGN_UP])

export const smsSend = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[SMS_SEND])

export const emailVerify = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[VERIFY_EMAIL])

export const rolesMapSelector = createSelector(authUserInfo, selected => selected?.roles_map)

export const rolesSelector = createSelector(authUserInfo, selected => selected?.roles)

export const institutionsSelector = createSelector(authUserInfo, selected => selected?.institutions || [])

export const educationFormsSelector = createSelector(authUserInfo, selected => selected?.education_forms || [])

export const selectedInstitutionSelector = createSelector(authUserInfo, selected => selected?.selected_institution)
export const selectedEducationFormSelector = createSelector(authUserInfo, selected => selected?.selected_education_form)
