import { lazy } from 'react'
import { MODULES_OUTCOME } from '../constants/route'
import {
  MODERATOR_VIEWER,
  MODULE_LEADER,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  SUPER_ADMIN,
} from '@constants/userRoles'

export const ModulesOutcomeRoutes = [
  {
    path: MODULES_OUTCOME,
    component: lazy(() => import('../views/ModulesOutcome')),
    meta: {
      access: [SUPER_ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, MODERATOR_VIEWER, MODULE_LEADER],
    },
  },
]
