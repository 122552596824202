import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, STAFF, STAFF_ALL, STAFF_DELETE, STAFF_LIST, STAFF_USERNAME } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const STAFFS = GLOBAL_STATE

export const { reducer: staffReducer } = createSlice({
  name: STAFFS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.staff.pending, state => {
        state[STAFF].loading = true
      })
      .addCase(actionCreators.staff.rejected, (state, action) => {
        state[STAFF].loading = false
        state[STAFF].error = action.payload as AxiosError
      })
      .addCase(actionCreators.staff.fulfilled, (state, action) => {
        state[STAFF].loading = false
        state[STAFF].error = null
        state[STAFF].data = action.payload
      })

    builder
      .addCase(actionCreators.staffAll.pending, state => {
        state[STAFF_ALL].loading = true
      })
      .addCase(actionCreators.staffAll.rejected, (state, action) => {
        state[STAFF_ALL].loading = false
        state[STAFF_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.staffAll.fulfilled, (state, action) => {
        state[STAFF_ALL].loading = false
        state[STAFF_ALL].error = null
        state[STAFF_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.staffDelete.pending, state => {
        state[STAFF_DELETE].loading = true
      })
      .addCase(actionCreators.staffDelete.rejected, (state, action) => {
        state[STAFF_DELETE].loading = false
        state[STAFF_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.staffDelete.fulfilled, (state, action) => {
        state[STAFF_DELETE].loading = false
        state[STAFF_DELETE].error = null
        state[STAFF_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.staffList.pending, state => {
        state[STAFF_LIST].loading = true
      })
      .addCase(actionCreators.staffList.rejected, (state, action) => {
        state[STAFF_LIST].loading = false
        state[STAFF_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.staffList.fulfilled, (state, action) => {
        state[STAFF_LIST].loading = false
        state[STAFF_LIST].error = null
        state[STAFF_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.staffUsername.pending, state => {
        state[STAFF_USERNAME].loading = true
      })
      .addCase(actionCreators.staffUsername.rejected, (state, action) => {
        state[STAFF_USERNAME].loading = false
        state[STAFF_USERNAME].error = action.payload as AxiosError
      })
      .addCase(actionCreators.staffUsername.fulfilled, (state, action) => {
        state[STAFF_USERNAME].loading = false
        state[STAFF_USERNAME].error = null
        state[STAFF_USERNAME].data = action.payload
      })

    builder.addCase(actionCreators.staffAllClear, state => {
      state[STAFF_ALL] = defaultState()
    })

    builder.addCase(actionCreators.staffListClear, state => {
      state[STAFF_LIST] = defaultState()
    })

    builder.addCase(actionCreators.staffUsernameClear, state => {
      state[STAFF_USERNAME] = defaultState()
    })
  },
})
