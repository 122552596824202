import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'
import { IntlContext } from '@utility/context/Internationalization'
import 'animate.css/animate.css'
import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

function useConfirmText(props = {}) {
  const { formatMessage: msg } = useIntl()
  const { locale } = useContext(IntlContext)

  const defaultConfirmText = useMemo(
    () => ({
      title: msg({ id: 'Modal.confirmText' }),
      // text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: msg({ id: 'cancel' }),
      confirmButtonText: msg({ id: 'yes' }),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      allowEscapeKey: false,
      allowOutsideClick: false,
      buttonsStyling: false,
    }),
    [locale]
  )

  const success = useMemo(
    () => ({
      icon: 'success',
      title: msg({ id: 'Deleted!' }),
      text: msg({ id: 'Your file has been deleted' }),
      customClass: {
        confirmButton: 'btn btn-success',
      },
    }),
    [locale]
  )

  const error = useMemo(
    () => ({
      title: msg({ id: 'Error' }),
      text: msg({ id: 'Sorry cannot perform Your request' }),
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }),
    [locale]
  )

  const defaultThen = res => {
    if (res.value) {
      MySwal.fire(success)
    }
  }

  const { confirmText = {} } = props

  const handleConfirmText = (then, options = {}) => {
    return MySwal.fire({ ...defaultConfirmText, ...confirmText, ...options }).then(
      then ? res => then({ res, success, error }) : defaultThen
    )
  }

  return { MySwal, handleConfirmText }
}

export default useConfirmText
