import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_ALL,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_DELETE,
  ACADEMIC_YEAR_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ACADEMIC_YEARS = GLOBAL_STATE

export const { reducer: academicYearReducer } = createSlice({
  name: ACADEMIC_YEARS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.academicYear.pending, state => {
        state[ACADEMIC_YEAR].loading = true
      })
      .addCase(actionCreators.academicYear.rejected, (state, action) => {
        state[ACADEMIC_YEAR].loading = false
        state[ACADEMIC_YEAR].error = action.payload as AxiosError
      })
      .addCase(actionCreators.academicYear.fulfilled, (state, action) => {
        state[ACADEMIC_YEAR].loading = false
        state[ACADEMIC_YEAR].error = null
        state[ACADEMIC_YEAR].data = action.payload
      })

    builder
      .addCase(actionCreators.academicYearAll.pending, state => {
        state[ACADEMIC_YEAR_ALL].loading = true
      })
      .addCase(actionCreators.academicYearAll.rejected, (state, action) => {
        state[ACADEMIC_YEAR_ALL].loading = false
        state[ACADEMIC_YEAR_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.academicYearAll.fulfilled, (state, action) => {
        state[ACADEMIC_YEAR_ALL].loading = false
        state[ACADEMIC_YEAR_ALL].error = null
        state[ACADEMIC_YEAR_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.academicYearCreate.pending, state => {
        state[ACADEMIC_YEAR_CREATE].loading = true
      })
      .addCase(actionCreators.academicYearCreate.rejected, (state, action) => {
        state[ACADEMIC_YEAR_CREATE].loading = false
        state[ACADEMIC_YEAR_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.academicYearCreate.fulfilled, (state, action) => {
        state[ACADEMIC_YEAR_CREATE].loading = false
        state[ACADEMIC_YEAR_CREATE].error = null
        state[ACADEMIC_YEAR_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.academicYearDelete.pending, state => {
        state[ACADEMIC_YEAR_DELETE].loading = true
      })
      .addCase(actionCreators.academicYearDelete.rejected, (state, action) => {
        state[ACADEMIC_YEAR_DELETE].loading = false
        state[ACADEMIC_YEAR_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.academicYearDelete.fulfilled, (state, action) => {
        state[ACADEMIC_YEAR_DELETE].loading = false
        state[ACADEMIC_YEAR_DELETE].error = null
        state[ACADEMIC_YEAR_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.academicYearList.pending, state => {
        state[ACADEMIC_YEAR_LIST].loading = true
      })
      .addCase(actionCreators.academicYearList.rejected, (state, action) => {
        state[ACADEMIC_YEAR_LIST].loading = false
        state[ACADEMIC_YEAR_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.academicYearList.fulfilled, (state, action) => {
        state[ACADEMIC_YEAR_LIST].loading = false
        state[ACADEMIC_YEAR_LIST].error = null
        state[ACADEMIC_YEAR_LIST].data = action.payload
      })

    builder.addCase(actionCreators.academicYearClear, state => {
      state[ACADEMIC_YEAR] = defaultState()
    })

    builder.addCase(actionCreators.academicYearAllClear, state => {
      state[ACADEMIC_YEAR_ALL] = defaultState()
    })

    builder.addCase(actionCreators.academicYearListClear, state => {
      state[ACADEMIC_YEAR_LIST] = defaultState()
    })
  },
})
