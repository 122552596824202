import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { CITY, CITY_ALL, CITY_CREATE, CITY_DELETE, CITY_LIST, GLOBAL_STATE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const CITYS = GLOBAL_STATE

export const { reducer: cityReducer } = createSlice({
  name: CITYS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.city.pending, state => {
        state[CITY].loading = true
      })
      .addCase(actionCreators.city.rejected, (state, action) => {
        state[CITY].loading = false
        state[CITY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.city.fulfilled, (state, action) => {
        state[CITY].loading = false
        state[CITY].error = null
        state[CITY].data = action.payload
      })

    builder
      .addCase(actionCreators.cityAll.pending, state => {
        state[CITY_ALL].loading = true
      })
      .addCase(actionCreators.cityAll.rejected, (state, action) => {
        state[CITY_ALL].loading = false
        state[CITY_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cityAll.fulfilled, (state, action) => {
        state[CITY_ALL].loading = false
        state[CITY_ALL].error = null
        state[CITY_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.cityCreate.pending, state => {
        state[CITY_CREATE].loading = true
      })
      .addCase(actionCreators.cityCreate.rejected, (state, action) => {
        state[CITY_CREATE].loading = false
        state[CITY_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cityCreate.fulfilled, (state, action) => {
        state[CITY_CREATE].loading = false
        state[CITY_CREATE].error = null
        state[CITY_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.cityDelete.pending, state => {
        state[CITY_DELETE].loading = true
      })
      .addCase(actionCreators.cityDelete.rejected, (state, action) => {
        state[CITY_DELETE].loading = false
        state[CITY_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cityDelete.fulfilled, (state, action) => {
        state[CITY_DELETE].loading = false
        state[CITY_DELETE].error = null
        state[CITY_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.cityList.pending, state => {
        state[CITY_LIST].loading = true
      })
      .addCase(actionCreators.cityList.rejected, (state, action) => {
        state[CITY_LIST].loading = false
        state[CITY_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cityList.fulfilled, (state, action) => {
        state[CITY_LIST].loading = false
        state[CITY_LIST].error = null
        state[CITY_LIST].data = action.payload
      })

    builder.addCase(actionCreators.cityClear, state => {
      state[CITY] = defaultState()
    })

    builder.addCase(actionCreators.cityAllClear, state => {
      state[CITY_ALL] = defaultState()
    })

    builder.addCase(actionCreators.cityListClear, state => {
      state[CITY_LIST] = defaultState()
    })
  },
})
