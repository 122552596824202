export const GLOBAL_STATE = 'Group'

export const GROUP_ALL = 'GROUP_ALL' as const
export const GROUP_ALL_SHORT = 'GROUP_ALL_SHORT' as const
export const GROUP_ALL_FOR_ASSESSMENT = 'GROUP_ALL_FOR_ASSESSMENT' as const
export const GROUP_LIST = 'GROUP_LIST' as const
export const GROUP_ASSIGN = 'GROUP_ASSIGN' as const
export const GROUP_ASSIGN_MENTOR = 'GROUP_ASSIGN_MENTOR' as const
export const GROUP_ASSIGNED_LIST = 'GROUP_ASSIGNED_LIST' as const
export const GROUP_EDIT = 'GROUP_EDIT' as const
export const GROUP_DELETE = 'GROUP_DELETE' as const
export const GROUP_SHUFFLE = 'GROUP_SHUFFLE' as const
export const GROUP_STUDENT_TRANSFER = 'GROUP_STUDENT_TRANSFER' as const
export const GROUP_EXPORT = 'GROUP_EXPORT' as const
