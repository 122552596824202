export const GLOBAL_STATE = 'ShortTermCourse' as const

export const STC = 'STC' as const
export const STC_CREATE = 'STC_CREATE' as const
export const STC_DELETE = 'STC_DELETE' as const
export const STC_LIST = 'STC_LIST' as const
export const STC_ALL = 'STC_ALL' as const

// category
export const STC_CATEGORY = 'STC_CATEGORY' as const
export const STC_CATEGORY_CREATE = 'STC_CATEGORY_CREATE' as const
export const STC_CATEGORY_DELETE = 'STC_CATEGORY_DELETE' as const
export const STC_CATEGORY_LIST = 'STC_CATEGORY_LIST' as const
export const STC_CATEGORY_ALL = 'STC_CATEGORY_ALL' as const

// step
export const STC_STEP = 'STC_STEP' as const
export const STC_STEP_CREATE = 'STC_STEP_CREATE' as const
export const STC_STEP_DELETE = 'STC_STEP_DELETE' as const
export const STC_STEP_LIST = 'STC_STEP_LIST' as const
export const STC_STEP_ALL = 'STC_STEP_ALL' as const
