import { RootState } from '@src/redux/store'
import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_ALL,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const academicYearSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const academicYearSelector = createSelector(academicYearSelectors, prop(ACADEMIC_YEAR))

export const academicYearAllSelector = createSelector(academicYearSelectors, prop(ACADEMIC_YEAR_ALL))

export const academicYearListSelector = createSelector(academicYearSelectors, prop(ACADEMIC_YEAR_LIST))

export const academicYearCreateSelector = createSelector(academicYearSelectors, prop(ACADEMIC_YEAR_CREATE))

export const academicYearAllOptionsSelector = createSelector(academicYearAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({ label: propOr('', 'name', item), value: item.id }))
      : [],
  }
})
