import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  DEPARTMENT,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_LABELS,
  DEPARTMENT_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const DEPARTMENTS = GLOBAL_STATE

export const { reducer: departmentReducer } = createSlice({
  name: DEPARTMENTS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.department.pending, state => {
        state[DEPARTMENT].loading = true
      })
      .addCase(actionCreators.department.rejected, (state, action) => {
        state[DEPARTMENT].loading = false
        state[DEPARTMENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.department.fulfilled, (state, action) => {
        state[DEPARTMENT].loading = false
        state[DEPARTMENT].error = null
        state[DEPARTMENT].data = action.payload
      })

    builder
      .addCase(actionCreators.departmentAll.pending, state => {
        state[DEPARTMENT_ALL].loading = true
      })
      .addCase(actionCreators.departmentAll.rejected, (state, action) => {
        state[DEPARTMENT_ALL].loading = false
        state[DEPARTMENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.departmentAll.fulfilled, (state, action) => {
        state[DEPARTMENT_ALL].loading = false
        state[DEPARTMENT_ALL].error = null
        state[DEPARTMENT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.departmentCreate.pending, state => {
        state[DEPARTMENT_CREATE].loading = true
      })
      .addCase(actionCreators.departmentCreate.rejected, (state, action) => {
        state[DEPARTMENT_CREATE].loading = false
        state[DEPARTMENT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.departmentCreate.fulfilled, (state, action) => {
        state[DEPARTMENT_CREATE].loading = false
        state[DEPARTMENT_CREATE].error = null
        state[DEPARTMENT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.departmentDelete.pending, state => {
        state[DEPARTMENT_DELETE].loading = true
      })
      .addCase(actionCreators.departmentDelete.rejected, (state, action) => {
        state[DEPARTMENT_DELETE].loading = false
        state[DEPARTMENT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.departmentDelete.fulfilled, (state, action) => {
        state[DEPARTMENT_DELETE].loading = false
        state[DEPARTMENT_DELETE].error = null
        state[DEPARTMENT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.departmentLabels.pending, state => {
        state[DEPARTMENT_LABELS].loading = true
      })
      .addCase(actionCreators.departmentLabels.rejected, (state, action) => {
        state[DEPARTMENT_LABELS].loading = false
        state[DEPARTMENT_LABELS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.departmentLabels.fulfilled, (state, action) => {
        state[DEPARTMENT_LABELS].loading = false
        state[DEPARTMENT_LABELS].error = null
        state[DEPARTMENT_LABELS].data = action.payload
      })

    builder
      .addCase(actionCreators.departmentList.pending, state => {
        state[DEPARTMENT_LIST].loading = true
      })
      .addCase(actionCreators.departmentList.rejected, (state, action) => {
        state[DEPARTMENT_LIST].loading = false
        state[DEPARTMENT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.departmentList.fulfilled, (state, action) => {
        state[DEPARTMENT_LIST].loading = false
        state[DEPARTMENT_LIST].error = null
        state[DEPARTMENT_LIST].data = action.payload
      })

    builder.addCase(actionCreators.departmentClear, state => {
      state[DEPARTMENT] = defaultState()
    })

    builder.addCase(actionCreators.departmentAllClear, state => {
      state[DEPARTMENT_ALL] = defaultState()
    })

    builder.addCase(actionCreators.departmentLabelsClear, state => {
      state[DEPARTMENT_LABELS] = defaultState()
    })

    builder.addCase(actionCreators.departmentListClear, state => {
      state[DEPARTMENT_LIST] = defaultState()
    })
  },
})
