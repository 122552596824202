import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import * as actionTypes from '../constants/actionTypes'
import * as actions from './actions'
import { defaultState, initialState } from './initialState'

export const INTERNAL_EXAM_STATE = 'InternalExam'

const internalExamSlice = createSlice({
  name: INTERNAL_EXAM_STATE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actions.internalExam.pending, state => {
        state[actionTypes.INTERNAL_EXAM].loading = true
      })
      .addCase(actions.internalExam.rejected, (state, action) => {
        state[actionTypes.INTERNAL_EXAM].loading = false
        state[actionTypes.INTERNAL_EXAM].error = action.payload as AxiosError
      })
      .addCase(actions.internalExam.fulfilled, (state, action) => {
        state[actionTypes.INTERNAL_EXAM].loading = false
        state[actionTypes.INTERNAL_EXAM].error = null
        state[actionTypes.INTERNAL_EXAM].data = action.payload
      })

    builder
      .addCase(actions.internalExamAll.pending, state => {
        state[actionTypes.INTERNAL_EXAM_ALL].loading = true
      })
      .addCase(actions.internalExamAll.rejected, (state, action) => {
        state[actionTypes.INTERNAL_EXAM_ALL].loading = false
        state[actionTypes.INTERNAL_EXAM_ALL].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamAll.fulfilled, (state, action) => {
        state[actionTypes.INTERNAL_EXAM_ALL].loading = false
        state[actionTypes.INTERNAL_EXAM_ALL].error = null
        state[actionTypes.INTERNAL_EXAM_ALL].data = action.payload
      })

    builder
      .addCase(actions.internalExamCreate.pending, state => {
        state[actionTypes.INTERNAL_EXAM_CREATE].loading = true
      })
      .addCase(actions.internalExamCreate.rejected, (state, action) => {
        state[actionTypes.INTERNAL_EXAM_CREATE].loading = false
        state[actionTypes.INTERNAL_EXAM_CREATE].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamCreate.fulfilled, (state, action) => {
        state[actionTypes.INTERNAL_EXAM_CREATE].loading = false
        state[actionTypes.INTERNAL_EXAM_CREATE].error = null
        state[actionTypes.INTERNAL_EXAM_CREATE].data = action.payload
      })

    builder
      .addCase(actions.internalExamList.pending, state => {
        state[actionTypes.INTERNAL_EXAM_LIST].loading = true
      })
      .addCase(actions.internalExamList.rejected, (state, action) => {
        state[actionTypes.INTERNAL_EXAM_LIST].loading = false
        state[actionTypes.INTERNAL_EXAM_LIST].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamList.fulfilled, (state, action) => {
        state[actionTypes.INTERNAL_EXAM_LIST].loading = false
        state[actionTypes.INTERNAL_EXAM_LIST].error = null
        state[actionTypes.INTERNAL_EXAM_LIST].data = action.payload
      })

    // builder
    //   .addCase(actions.internalExamPlace.pending, state => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE].loading = true
    //   })
    //   .addCase(actions.internalExamPlace.rejected, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE].error = action.payload as AxiosError
    //   })
    //   .addCase(actions.internalExamPlace.fulfilled, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE].error = null
    //     state[actionTypes.INTERNAL_EXAM_PLACE].data = action.payload
    //   })

    // builder
    //   .addCase(actions.internalExamPlaceAll.pending, state => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_ALL].loading = true
    //   })
    //   .addCase(actions.internalExamPlaceAll.rejected, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_ALL].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE_ALL].error = action.payload as AxiosError
    //   })
    //   .addCase(actions.internalExamPlaceAll.fulfilled, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_ALL].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE_ALL].error = null
    //     state[actionTypes.INTERNAL_EXAM_PLACE_ALL].data = action.payload
    //   })

    // builder
    //   .addCase(actions.internalExamPlaceCreate.pending, state => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_CREATE].loading = true
    //   })
    //   .addCase(actions.internalExamPlaceCreate.rejected, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_CREATE].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE_CREATE].error = action.payload as AxiosError
    //   })
    //   .addCase(actions.internalExamPlaceCreate.fulfilled, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_CREATE].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE_CREATE].error = null
    //     state[actionTypes.INTERNAL_EXAM_PLACE_CREATE].data = action.payload
    //   })

    // builder
    //   .addCase(actions.internalExamPlaceList.pending, state => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_LIST].loading = true
    //   })
    //   .addCase(actions.internalExamPlaceList.rejected, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_LIST].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE_LIST].error = action.payload as AxiosError
    //   })
    //   .addCase(actions.internalExamPlaceList.fulfilled, (state, action) => {
    //     state[actionTypes.INTERNAL_EXAM_PLACE_LIST].loading = false
    //     state[actionTypes.INTERNAL_EXAM_PLACE_LIST].error = null
    //     state[actionTypes.INTERNAL_EXAM_PLACE_LIST].data = action.payload
    //   })

    builder.addCase(actions.internalExamClear, state => {
      state[actionTypes.INTERNAL_EXAM] = defaultState()
    })

    builder.addCase(actions.internalExamAllClear, state => {
      state[actionTypes.INTERNAL_EXAM_ALL] = defaultState()
    })

    builder.addCase(actions.internalExamListClear, state => {
      state[actionTypes.INTERNAL_EXAM_LIST] = defaultState()
    })
  },
})

export const { reducer: internalExamReducer } = internalExamSlice
