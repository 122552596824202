import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { CITY, CITY_ALL, CITY_CREATE, CITY_DELETE, CITY_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const city = createAsyncThunk(
  CITY,
  async (params: PayloadCreatorParams<`${typeof API.CITY}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.city(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cityCreate = createAsyncThunk(
  CITY_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.CITY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.cityCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cityDelete = createAsyncThunk(
  CITY_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.CITY}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.cityDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cityList = createAsyncThunk(
  CITY_LIST,
  async (params: PayloadCreatorParams<`${typeof API.CITY}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.cityList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cityAll = createAsyncThunk(
  CITY_ALL,
  async (params: PayloadCreatorParams<`${typeof API.CITY_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.cityAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cityClear = createAction(`${CITY}/clear`)

export const cityListClear = createAction(`${CITY_LIST}/clear`)

export const cityAllClear = createAction(`${CITY_ALL}/clear`)
