import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const intance = getInstance()

export const institutionPageSettings: ApiFunc<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'> = data =>
  intance.get(API.INSTITUTION_PAGE_SETTINGS, {
    params: data,
  })

export const institutionPageSettingsCreate: ApiFunc<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'post'> = data =>
  intance.post(API.INSTITUTION_PAGE_SETTINGS, data)

export const institutionPageSettingsDelete: ApiFunc<`${typeof API.INSTITUTION_PAGE_SETTINGS}{id}`, 'delete'> = data =>
  intance.delete(`${API.INSTITUTION_PAGE_SETTINGS}${'id' in data ? prop('id', data) : data?.name}`)

export const institutionPageSettingsList: ApiFunc<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'> = data =>
  intance.get(API.INSTITUTION_PAGE_SETTINGS, {
    params: data,
  })

export const institutionPageSettingsAll: ApiFunc<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'> = data =>
  intance.get(API.INSTITUTION_PAGE_SETTINGS, {
    params: data,
  })

export const institutionPageSettingsIdentity: ApiFunc<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'> = data =>
  intance.get(`${API.INSTITUTION_PAGE_SETTINGS}${'identity' in data ? prop('identity', data) : data?.name}`, {
    params: 'identity' in data ? dissoc('identity', data) : dissoc('name', data),
  })
