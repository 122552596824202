import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormText, Label } from 'reactstrap'
import classNames from 'classnames'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '@styles/react/libs/editor/editor.scss'

const EditorField = forwardRef((props, ref) => {
  const {
    value,
    onChange: onEditorContentChange = () => {},
    label,
    hideToolbar,
    name,
    disabled = false,
    error,
    toolBar = {},
    editorClassName = '',
    uploadImageCallBack = () => {},
    msg = () => {},
    formGroupProps = {},
    labelProps = {},
    formTextProps = {
      color: 'danger',
    },
    dependencies = [],
  } = props

  const [state, setState] = useState({
    editorState: EditorState.createEmpty(),
  })

  useEffect(() => {
    if (value) {
      const { contentBlocks, entityMap } = htmlToDraft(value)
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      setState({
        editorState: EditorState.createWithContent(contentState),
      })
    }
  }, [...dependencies])

  function onEditorStateChange(editorState) {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onEditorContentChange(html)
    setState({ editorState })
    return html
  }

  return (
    <FormGroup {...formGroupProps}>
      {label && <Label {...labelProps}>{label}</Label>}
      <Editor
        name={name}
        editorState={state.editorState}
        readOnly={disabled}
        wrapperClassName={classNames({ 'rich-editor-wrapper': !error }, { 'rich-editor-wrapper-invalid': error })}
        editorClassName={`${editorClassName} rich-editor`}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            defaultSize: {
              height: '200',
              width: '300',
            },
          },
          ...toolBar,
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        onTab={() => {}}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName={hideToolbar ? 'hide-toolbar' : ''}
        editorRef={ref}
        stripPastedStyles={true}
      />

      {error && (
        <FormText {...formTextProps}>
          {msg({ id: error?.type || 'form_required' })}
        </FormText>
      )}
    </FormGroup>
  )
})

EditorField.defaultProps = {
  hideToolbar: false,
}

EditorField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.string, PropTypes.node]),
  hideToolbar: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string,
  }),
  toolBar: PropTypes.object,
  editorClassName: PropTypes.string,
  uploadImageCallBack: PropTypes.func,
  msg: PropTypes.func,
  formGroupProps: PropTypes.object,
  labelProps: PropTypes.object,
  formTextProps: PropTypes.object,
  dependencies: PropTypes.array,
}

export default EditorField
