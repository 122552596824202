import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const enrollmentSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const enrollmentSelector = createSelector(enrollmentSelectors, prop(ENROLLMENT))

export const enrollmentCreateSelector = createSelector(enrollmentSelectors, prop(ENROLLMENT_CREATE))

export const enrollmentListSelector = createSelector(enrollmentSelectors, prop(ENROLLMENT_LIST))

export const enrollmentAllSelector = createSelector(enrollmentSelectors, prop(ENROLLMENT_ALL))

export const enrollmentStatusSelector = createSelector(enrollmentSelectors, prop(ENROLLMENT_STATUS))

export const enrollmentCoursesSelector = createSelector(enrollmentSelectors, prop(ENROLLMENT_COURSES))
