import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  JOB_POSITION,
  JOB_POSITION_ACTIVATE,
  JOB_POSITION_ALL,
  JOB_POSITION_CREATE,
  JOB_POSITION_DELETE,
  JOB_POSITION_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const jobPosition = createAsyncThunk(
  JOB_POSITION,
  async (params: PayloadCreatorParams<`${typeof API.JOB_POSITION}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.jobPosition(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobPositionActivate = createAsyncThunk(
  JOB_POSITION_ACTIVATE,
  async (params: PayloadCreatorParams<`${typeof API.JOB_POSITION_ACTIVATE}{id}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.jobPositionActivate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobPositionAll = createAsyncThunk(
  JOB_POSITION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.JOB_POSITION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.jobPositionAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobPositionCreate = createAsyncThunk(
  JOB_POSITION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.JOB_POSITION}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.jobPositionCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobPositionDelete = createAsyncThunk(
  JOB_POSITION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.JOB_POSITION}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.jobPositionDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobPositionList = createAsyncThunk(
  JOB_POSITION_LIST,
  async (params: PayloadCreatorParams<`${typeof API.JOB_POSITION}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.jobPositionList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const jobPositionClear = createAction(`${JOB_POSITION}/clear`)

export const jobPositionAllClear = createAction(`${JOB_POSITION_ALL}/clear`)

export const jobPositionListClear = createAction(`${JOB_POSITION_LIST}/clear`)
