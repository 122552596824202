import { equals, prop } from 'ramda'
import {
  CSSProperties,
  ChangeEvent,
  ComponentPropsWithRef,
  FocusEvent,
  ReactNode,
  WheelEvent,
  forwardRef,
  useEffect,
} from 'react'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { FormGroup, FormGroupProps, FormText, Input, Label } from 'reactstrap'

type Props = ComponentPropsWithRef<'input'> & {
  handleChange?: (value: string | number) => void
  label?: ReactNode
  error?: FieldError
  toUppercase?: boolean
  name: string
  type?: 'text' | 'number' | 'textarea'
  formGroupStyles?: CSSProperties
  formGroupClassNames?: string
  invalid?: boolean
  definite?: number
  customErrorMessage?: boolean
  formGroupProps?: FormGroupProps
  normalize?: (e: ChangeEvent<HTMLInputElement>, inputChange: (value: string | number) => void) => void
  onChange: (e: ChangeEvent<HTMLInputElement> | string | number) => void
  onUnmount?: () => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

const InputField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    placeholder,
    type = 'text',
    pattern,
    autoFocus,
    autoComplete,
    disabled,
    readOnly,
    maxLength,
    onKeyPress = () => {},
    className,
    style = {},
    customErrorMessage,
    min,
    id = '',
    handleChange = () => {},
    value,
    onChange,
    name,
    error,
    toUppercase = false,
    formGroupStyles,
    formGroupClassNames,
    normalize,
    required,
    onUnmount,
    definite,
    invalid,
    formGroupProps = {},
    title = '',
    onBlur,
  } = props

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  function change(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    if (normalize) {
      normalize(e, inputChange)
    } else {
      // value = isNil(value) ? value : type === 'number' ? Number(value) : value
      if (toUppercase && typeof value === 'string') {
        onChange(value.toUpperCase())
        handleChange(value.toUpperCase())
      } else {
        onChange(value)
        handleChange(value)
      }
    }
  }

  const onWheel = equals(type, 'number')
    ? {
        onWheel: (e: WheelEvent<HTMLInputElement>) => {
          e && e.currentTarget.blur()
        },
      }
    : {}

  function inputChange(value: string | number) {
    onChange(value)
    handleChange(value)
  }

  const formGroupStylesProps = formGroupStyles ? { style: formGroupStyles } : {}
  const formGroupClassNamesProps = formGroupClassNames ? { className: formGroupClassNames } : {}

  return (
    <FormGroup {...formGroupStylesProps} {...formGroupClassNamesProps} {...formGroupProps}>
      {label && (
        <Label {...htmlFor}>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}

      <Input
        value={value}
        onBlur={onBlur}
        name={name}
        label={label}
        title={title}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
        readOnly={readOnly}
        min={min}
        // defaultValue={value}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        maxLength={maxLength}
        onChange={change}
        onKeyPress={onKeyPress}
        className={className}
        {...onWheel}
        {...htmlId}
        style={{ ...style, textTransform: toUppercase ? 'uppercase' : 'initial' }}
        innerRef={ref}
        invalid={!!invalid || !!error}
        required={required}
      />

      {error &&
        (typeof error === 'string' ? (
          <FormText color='danger'>{error}</FormText>
        ) : (
          <FormText color='danger'>
            {customErrorMessage ? (
              equals(prop('type', error), 'min') ? (
                `Value should not be less than ${min}`
              ) : equals(prop('type', error), 'max') ? (
                `Value should not exceed ${maxLength}`
              ) : equals(prop('message', error), 'definite') ? (
                `Value should be ${definite} characters long`
              ) : (
                prop('message', error)
              )
            ) : (
              <FormattedMessage
                id={
                  equals(prop('type', error), 'range')
                    ? 'number_range'
                    : prop('message', error) === 'notAscii'
                    ? 'asciiError'
                    : 'form_required'
                }
                values={{ min, max: maxLength }}
              />
            )}
          </FormText>
        ))}
    </FormGroup>
  )
})

export default InputField
