import { ApiFunc, getInstance } from '@src/helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const attendanceReason: ApiFunc<`${typeof API.ATTENDANCE_REASON}{id}`, 'get'> = data =>
  instance.get(`${API.ATTENDANCE_REASON}${'id' in data ? data.id : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const attendanceReasonAll: ApiFunc<`${typeof API.ATTENDANCE_REASON_ALL}`, 'get'> = data =>
  instance.get(API.ATTENDANCE_REASON_ALL, { params: data })

export const attendanceReasonCreate: ApiFunc<`${typeof API.ATTENDANCE_REASON}`, 'post'> = data =>
  instance.post(API.ATTENDANCE_REASON, data)

export const attendanceReasonDelete: ApiFunc<`${typeof API.ATTENDANCE_REASON}{id}`, 'delete'> = data =>
  instance.delete(`${API.ATTENDANCE_REASON}${'id' in data ? data.id : data.name}`)

export const attendanceReasonList: ApiFunc<`${typeof API.ATTENDANCE_REASON}`, 'get'> = data =>
  instance.get(API.ATTENDANCE_REASON, { params: data })
