import { RootState } from '@src/redux/store'
import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, SEMESTER_ALL } from '../constants/actionTypes'

const semesterSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const semesterAllSelector = createSelector(semesterSelectors, prop(SEMESTER_ALL))

export const semesterAllOptionsSelector = createSelector(semesterAllSelector, semesterAll => ({
  loading: semesterAll.loading,
  error: semesterAll.error,
  options: Array.isArray(semesterAll.data)
    ? semesterAll.data.map(item => ({ label: item.title || '', value: item.id }))
    : [],
}))
