import { trim, match, isEmpty } from 'ramda/es'

const regEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

const emailValidation = (value) => {
  if (!value) return { message: 'required', type: 'required' }
  if (trim(value).length === 0) {
    return { message: 'required', type: 'required' }
  }
  if (isEmpty(match(regEx, value))) {
    return { message: 'Please enter a valid E-mail', type: 'invalid' }
  }
}

export default emailValidation
