import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const achievementRecordsList: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS}`, 'get'> = data =>
  instance.get(API.ACHIEVEMENT_RECORDS, {
    params: data,
  })

export const achievementRecordsAll: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS_ALL}`, 'get'> = data =>
  instance.get(API.ACHIEVEMENT_RECORDS_ALL, {
    params: data,
  })

export const achievementRecordsTypes: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS_TYPES}`, 'get'> = data =>
  instance.get(API.ACHIEVEMENT_RECORDS_TYPES, {
    params: data,
  })

export const achievementRecord: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS}{id}`, 'get'> = data =>
  instance.get(`${API.ACHIEVEMENT_RECORDS}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const achievementRecordsChangeStatus: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS_CHANGE_STATUS}`, 'put'> = data =>
  instance.put(API.ACHIEVEMENT_RECORDS_CHANGE_STATUS, data)

export const achievementRecordsStudent: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS_STUDENT}{id}`, 'get'> = data =>
  instance.get(`${API.ACHIEVEMENT_RECORDS_STUDENT}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const achievementRecordsCreate: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS}`, 'post'> = data =>
  instance.post(API.ACHIEVEMENT_RECORDS, data)

export const achievementRecordsDelete: ApiFunc<`${typeof API.ACHIEVEMENT_RECORDS}{id}`, 'delete'> = data =>
  instance.delete(`${API.ACHIEVEMENT_RECORDS}${'id' in data ? prop('id', data) : data.name}`)
