import { Target } from '@src/hooks/useSignal'
import { ReactNode } from 'react'
import { useSyncExternalStore } from 'use-sync-external-store/shim'

type Props<Snapshot> = {
  signal: Target<Snapshot>
  children: ({ value, signal }: { value: Snapshot; signal: Target<Snapshot> }) => ReactNode
}

export function SignalConsumer<Snapshot>({ children, signal }: Props<Snapshot>) {
  const signalValues = useSyncExternalStore(signal.pureSubscribe, signal.getValue)

  return children({ value: signalValues, signal })
}
