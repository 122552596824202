import { useAppDispatch, useAppSelector, useCheckRoles, useLayout } from '@hooks'
import { useRouterTransition } from '@hooks/useRouterTransition'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import LayoutWrapper from '@layouts/components/layout-wrapper'
import Login from '@module/Auth/views/Login'
import UpdatePassword from '@module/Auth/views/UpdatePassword'
import EnrollmentPage from '@module/Enrollment/views/EnrollmentPage'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
// ** Utils
import { isUserLoggedIn } from '@utils'
import { ConnectedRouter } from 'connected-react-router'
import queryString from 'query-string'
import { equals, pathOr, prop, propOr, type } from 'ramda'
import { Suspense, lazy, useEffect } from 'react'
// ** Router Components
import { Redirect, Route, Switch } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import { PageLoader } from '../components/Loaders'
import * as ROUTES from '../constants/routes'
import * as isUser from '../helpers/isUser'
import { getToken } from '../helpers/storageToken'
import { getUserInfo } from '../helpers/storageUserInfo'
import { history } from '../history'
import * as actions from '../redux/actions'
import * as selectors from '../redux/selector'
import { ResolvePublicRoutes } from './resolvePublicRoutes'
import { DefaultRoute, Routes } from './routes'

const Register = lazy(() => import('@module/Auth/views/Register'))
const EmailVerification = lazy(() => import('@module/Auth/views/EmailVerification'))

const Router = () => {
  // ** Hooks
  const [layout, setLayout] = useLayout()
  const [transition, setTransition] = useRouterTransition()
  const dispatch = useAppDispatch()

  // ** Default Layout
  const DefaultLayout = layout === 'horizontal' ? 'HorizontalLayout' : 'VerticalLayout'

  // ** All of the available layouts
  const Layouts = { BlankLayout, VerticalLayout, HorizontalLayout }

  // ** Current Active Item
  const currentActiveItem = null

  // const userInfo = JSON.parse(localStorage.getItem('userInfo') || '')

  useEffect(() => {
    if (getToken()) {
      dispatch(actions.authUserInfo())
    }
    return () => {
      dispatch(actions.authUserInfoClear())
    }
  }, [])

  // ** Return Filtered Array of Routes & Paths
  const LayoutRoutesAndPaths = layout => {
    const LayoutRoutes = []
    const LayoutPaths = []

    if (Routes) {
      Routes.filter(route => {
        // ** Checks if Route layout or Default layout matches current layout
        if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
          LayoutRoutes.push(route)
          LayoutPaths.push(route.path)
        }
      })
    }

    return { LayoutRoutes, LayoutPaths }
  }

  const NotAuthorized = lazy(() => import('@src/views/NotAuthorized'))

  // ** Init Error Component
  const Error = lazy(() => import('@src/views/Error'))

  /**
   ** Final Route Component Checks for Login & User Role and then redirects to the route
   */
  const FinalRoute = props => {
    const route = props.route

    let action, resource

    const userInfo = useAppSelector(selectors.authUserInfo)

    const { amI } = useCheckRoles()

    // ** Assign vars based on route meta
    if (route.meta) {
      action = route.meta.action ? route.meta.action : null
      resource = route.meta.resource ? route.meta.resource : null
    }

    if (
      (!getToken() && route.meta === undefined) ||
      (!getToken() && route.meta && !route.meta.authRoute && !route.meta.publicRoute)
    ) {
      userInfo && dispatch(actions.authUserInfoClear())
      /**
       ** If user is not Logged in & route meta is undefined
       ** OR
       ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
       ** Then redirect user to login
       */
      if (window.location.hostname === 'admission.isft.uz' || window.location.hostname === 'transfer.isft.uz') {
        return <Redirect to={ROUTES.REGISTER} />
      }
      return <Redirect to='/login' />
    } else if (isUser.isUpdatePassword(userInfo)) {
      return <Redirect to='/update-password' />
    } else if (
      getToken() &&
      isUser.isEnrollmentRequired(userInfo) &&
      !isUser.isUpdatePassword(userInfo) &&
      !isUser.isContractAcceptance(userInfo)
    ) {
      return <Redirect to='/enrollment' />
    } else if (
      !isUser.isUpdatePassword(userInfo) &&
      !isUser.isEnrollmentRequired(userInfo) &&
      isUser.isVerifyEmail(userInfo)
    ) {
      return <Redirect to={ROUTES.EMAIL_VERIFICATION} />
    }

    if (
      route.meta &&
      route.meta.authRoute &&
      getToken() &&
      (!isUser.isEnrollmentRequired(userInfo) ||
        !isUser.isUpdatePassword(userInfo) ||
        isUser(isUser.isContractAcceptance(userInfo)))
    ) {
      // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
      return <Redirect to='/' />
    } else if (isUserLoggedIn() && userInfo && prop('meta', route) && !amI(pathOr([], ['meta', 'access'], route))) {
      // ** If user is Logged in and doesn't have ability to visit the page redirect the user to Not Authorized
      return <Redirect to='/not-authorized' />
    } else if (
      userInfo &&
      !route?.meta?.privateRoute &&
      (!prop('meta', route) ? true : amI(pathOr([], ['meta', 'access'], route))) &&
      ((!isUser.isEnrollmentRequired(userInfo) &&
        !isUser.isVerifyEmail(userInfo) &&
        !isUser.isUpdatePassword(userInfo)) ||
        isUser.isContractAcceptance(userInfo))
    ) {
      // ** If none of the above render component
      return <route.component {...props} />
    }
    if (route.path === '/reset-password-request' || route.path === '/reset-password') {
      return <route.component {...props} />
    } else {
      return (
        <PageLoader loading>
          <div />
        </PageLoader>
      )
    }
    // else if (isUserLoggedIn() && !ability.can(action || 'read', resource)) {
    //   // ** If user is Logged in and doesn't have ability to visit the page redirect the user to Not Authorized
    //   return <Redirect to='/misc/not-authorized' />
    // }
  }

  function RequiredActionsRender(props) {
    const { Component = [], reqAction } = props
    const userInfo = useAppSelector(selectors.authUserInfo)
    // if (!getToken() || isUser.isUpdatePassword(userInfo)) {
    //   return <Redirect to='/login' />
    // } else
    if (
      equals(reqAction, 'ENROLLMENT') &&
      isUser.isEnrollmentRequired(userInfo) &&
      !isUser.isContractAcceptance(userInfo) &&
      !isUser.isUpdatePassword(userInfo)
    ) {
      // userInfo && isUser.isUpdatePassword(userInfo) && clearLocalStorage()
      return (
        <Layouts.BlankLayout>
          <Component {...props} />
        </Layouts.BlankLayout>
      )
    }
    if (equals(reqAction, 'UPDATE_PASSWORD') && isUser.isUpdatePassword(userInfo)) {
      return (
        <Layouts.BlankLayout>
          <Component {...props} />
        </Layouts.BlankLayout>
      )
    } else if (!getToken()) {
      return <Redirect to='/login' />
    } else {
      return <Redirect to='/' />
    }
  }

  // ** Return Route to Render
  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      // ** Convert Layout parameter to Layout Component
      // ? Note: make sure to keep layout and component name equal

      const LayoutTag = Layouts[layout]

      // ** Get Routes and Paths of the Layout
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout)

      // ** We have freedom to display different layout for different route
      // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
      // ** that we want to implement like VerticalLayout or HorizontalLayout
      // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

      // ** RouterProps to pass them to Layouts
      const routerProps = {}

      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag
            routerProps={routerProps}
            layout={layout}
            setLayout={setLayout}
            transition={transition}
            setTransition={setTransition}
            currentActiveItem={currentActiveItem}
          >
            <Switch>
              {LayoutRoutes.map(route => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact === true}
                    render={props => {
                      // ** Assign props to routerProps
                      Object.assign(routerProps, {
                        ...props,
                        meta: route.meta,
                      })

                      return (
                        <Suspense
                          fallback={
                            <div
                              className='header-navbar floating-nav text-center'
                              style={{
                                marginTop: '15rem',
                                background: 'transparent',
                              }}
                            >
                              <Spinner color='primary' />
                            </div>
                          }
                        >
                          {/* Layout Wrapper to add classes based on route's layout, appLayout and className */}
                          <LayoutWrapper
                            layout={DefaultLayout}
                            transition={transition}
                            setTransition={setTransition}
                            /* Conditional props */
                            /*eslint-disable */
                            {...(route.appLayout
                              ? {
                                  appLayout: route.appLayout,
                                }
                              : {})}
                            {...(route.meta
                              ? {
                                  routeMeta: route.meta,
                                }
                              : {})}
                            {...(route.className
                              ? {
                                  wrapperClass: route.className,
                                }
                              : {})}
                            /*eslint-enable */
                          >
                            {/*<route.component {...props} />*/}
                            <FinalRoute route={route} {...props} />
                          </LayoutWrapper>
                        </Suspense>
                      )
                    }}
                  />
                )
              })}
            </Switch>
          </LayoutTag>
        </Route>
      )
    })
  }

  return (
    <ConnectedRouter basename={process.env.REACT_APP_BASENAME} history={history}>
      <Switch>
        {/* If user is logged in Redirect user to DefaultRoute else to login */}

        {/* <Route
          exact
          path='/'
          render={() => {
            return isUserLoggedIn() ? <Redirect to={DefaultRoute} /> : <Redirect to='/login' />
          }}
        /> */}

        <Route
          exact
          path={ROUTES.REGISTER}
          render={props => {
            if (!getToken()) {
              return (
                <Layouts.BlankLayout>
                  <Register {...props} />
                </Layouts.BlankLayout>
              )
            } else {
              return <Redirect to='/' />
            }
          }}
        />

        <Route
          exact
          path={ROUTES.EMAIL_VERIFICATION}
          render={props => {
            const query = queryString.parse(pathOr('', ['location', 'search'], props))
            const token = propOr('', 'token', query)
            const userInfo = getUserInfo() // when WS is used user info should be taken from redux-state, as it won't trigger a rerender of <EmailVerification />
            return (token && equals(type(token), 'String') && token.length >= 32) || isUser.isVerifyEmail(userInfo) ? (
              <Layouts.BlankLayout>
                <Suspense
                  fallback={
                    <div
                      className='header-navbar floating-nav text-center'
                      style={{
                        marginTop: '15rem',
                        background: 'transparent',
                      }}
                    >
                      <Spinner color='primary' />
                    </div>
                  }
                >
                  <EmailVerification {...props} />
                </Suspense>
              </Layouts.BlankLayout>
            ) : userInfo ? (
              <Redirect to={DefaultRoute} />
            ) : (
              <Redirect to={ROUTES.LOGIN} />
            )
          }}
        />

        <Route
          exact
          path='/login'
          render={props => {
            if (!getToken()) {
              return (
                <Layouts.BlankLayout>
                  <Login {...props} />
                </Layouts.BlankLayout>
              )
            } else {
              return <Redirect to='/' />
            }
          }}
        />

        <Route
          exact
          path='/update-password'
          render={props => <RequiredActionsRender {...props} Component={UpdatePassword} reqAction='UPDATE_PASSWORD' />}
        />

        <Route
          exact
          path='/enrollment'
          render={props => <RequiredActionsRender {...props} Component={EnrollmentPage} reqAction='ENROLLMENT' />}
        />

        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to={DefaultRoute} />
          }}
        />

        {/* Not Auth Route */}

        <Route
          exact
          path='/not-authorized'
          render={props => (
            <Layouts.BlankLayout>
              <NotAuthorized />
            </Layouts.BlankLayout>
          )}
        />

        {ResolvePublicRoutes(Layouts, Route)}

        {ResolveRoutes()}

        {/* NotFound Error page */}

        <Route path='*' component={Error} />
      </Switch>
    </ConnectedRouter>
  )
}

export default Router
