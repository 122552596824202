import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  REPORT,
  REPORT_CREATE,
  REPORT_FIELDS,
  REPORT_FIELDS_FILTER,
  REPORT_LIST,
} from '../constants/actionTypes'

const reportSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

// export const reportAllSelector = createSelector(path([GLOBAL_STATE, REPORT_ALL]), data => data)

export const reportListSelector = createSelector(reportSelectors, prop(REPORT_LIST))

export const reportSelector = createSelector(reportSelectors, prop(REPORT))

export const reportCreateSelector = createSelector(reportSelectors, prop(REPORT_CREATE))

export const reportFieldsSelector = createSelector(reportSelectors, prop(REPORT_FIELDS))

export const reportFieldsFilterSelector = createSelector(reportSelectors, prop(REPORT_FIELDS_FILTER))
