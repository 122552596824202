import './styles.scss'

function FileTypeIcon({ color = 'var(--gray-dark)', type = 'file', width = '38', height = '36' }) {
  return (
    <div className='file-type-icon'>
      <svg width={width} height={height} viewBox='0 0 29 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.1107 0H3.62215C1.62272 0 0 1.6128 0 3.6V32.4C0 34.3872 1.62272 36 3.62215 36H25.355C27.3544 36 28.9772 34.3872 28.9772 32.4V10.8L18.1107 0ZM18.1107 12.6C17.1092 12.6 16.2997 12.6 16.2997 12.6V3.6L25.355 12.6H18.1107Z'
          fill={color}
        />
      </svg>
      <span className='file-type-icon-text text-uppercase'>{type}</span>
    </div>
  )
}

export default FileTypeIcon
