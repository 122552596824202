import themeConfig from '@configs/themeConfig'
import * as isUser from '@helpers/isUser'
import { useAppDispatch, useAppSelector } from '@hooks'
import { useSkin } from '@hooks/useSkin'
import { UserSettingsContext } from '@src/utility/context/UserSettings'
import * as actions from '@store/actions'
import * as selectors from '@store/selector'
import '@styles/base/pages/page-auth.scss'
import { prop } from 'ramda'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { CardText, CardTitle, Col, Row } from 'reactstrap'

import { UpdatePasswordForm } from '../components/UpdatePasswordForm'

const UpdatePassword = () => {
  const dispatch = useAppDispatch()
  const [skin] = useSkin()
  const userInfo = useAppSelector(selectors.authUserInfo)
  const auth = useAppSelector(selectors.authSelector)
  const { formatMessage: msg } = useIntl()
  const { setEduForm, setInstitution } = useContext(UserSettingsContext)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const illustration = skin === 'dark' ? 'login-v2-dark.svg' : 'login-v2.svg',
    source = require(`@src/assets/images/pages/${illustration}`).default
  const shouldUpdatePass = isUser.isUpdatePassword(userInfo)

  function onSubmit(data) {
    dispatch(
      actions.authPasswordReset({
        new_password: prop('password', data),
      })
    )
      .unwrap()
      .then(res => {
        setInstitution(res.user_info.selected_institution)
        setEduForm(res.user_info.selected_education_form)
      })
  }

  return (
    <div className='auth-wrapper auth-v2'>
      <Row className='auth-inner m-0'>
        <Link className='brand-logo' to='/'>
          <img
            style={{ maxWidth: '250px' }}
            src={themeConfig.app[skin === 'dark' ? 'appLogoLight' : 'appLogo']}
            alt='logo'
          />
        </Link>

        <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
          <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
            <img className='img-fluid' src={source} alt='Login V2' />
          </div>
        </Col>

        <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>
          <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
            <CardTitle tag='h2' className='font-weight-bold mb-1'>
              {msg({ id: 'welcomeText' })}!
            </CardTitle>

            <CardText className='mb-2'>{msg({ id: 'Please enter new password' })}</CardText>

            <UpdatePasswordForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              control={control}
              shouldUpdatePass={shouldUpdatePass}
            />
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default UpdatePassword
