// ** Third Party Components
import classnames from 'classnames'
import { ElementType, Fragment, ReactNode } from 'react'
import { Spinner } from 'reactstrap'

import './ui-loader.scss'

type Props = {
  blocking?: boolean
  loader: ReactNode
  className?: string
  tag?: ElementType
  overlayColor?: string
  children: ReactNode
}

const UILoader = ({ children, blocking = false, loader, className, tag, overlayColor }: Props) => {
  const Tag = tag!

  return (
    <Tag
      className={classnames('ui-loader', { [className ? className : '']: className, show: blocking })}
      style={{ width: '100%', height: '100%' }}
    >
      {children}
      {blocking ? (
        <Fragment>
          <div
            className='overlay' /*eslint-disable */
            {...(blocking && overlayColor ? { style: { backgroundColor: overlayColor } } : {})}
            /*eslint-enable */
          ></div>
          <div className='loader'>{loader}</div>
        </Fragment>
      ) : null}
    </Tag>
  )
}

export default UILoader

UILoader.defaultProps = {
  tag: 'div',
  blocking: false,
  loader: <Spinner color='primary' />,
}
