import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  INSTITUTION_PAGE_SETTINGS,
  INSTITUTION_PAGE_SETTINGS_ALL,
  INSTITUTION_PAGE_SETTINGS_CREATE,
  INSTITUTION_PAGE_SETTINGS_DELETE,
  INSTITUTION_PAGE_SETTINGS_IDENTITY,
  INSTITUTION_PAGE_SETTINGS_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const institutionPageSettings = createAsyncThunk(
  INSTITUTION_PAGE_SETTINGS,
  async (params: PayloadCreatorParams<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.institutionPageSettings(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const institutionPageSettingsCreate = createAsyncThunk(
  INSTITUTION_PAGE_SETTINGS_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.institutionPageSettingsCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const institutionPageSettingsDelete = createAsyncThunk(
  INSTITUTION_PAGE_SETTINGS_DELETE,
  async (
    params: PayloadCreatorParams<`${typeof API.INSTITUTION_PAGE_SETTINGS}{id}`, 'delete'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.institutionPageSettingsDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const institutionPageSettingsList = createAsyncThunk(
  INSTITUTION_PAGE_SETTINGS_LIST,
  async (params: PayloadCreatorParams<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.institutionPageSettingsList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const institutionPageSettingsAll = createAsyncThunk(
  INSTITUTION_PAGE_SETTINGS_ALL,
  async (params: PayloadCreatorParams<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.institutionPageSettingsAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const institutionPageSettingsIdentity = createAsyncThunk(
  INSTITUTION_PAGE_SETTINGS_IDENTITY,
  async (params: PayloadCreatorParams<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.institutionPageSettingsIdentity(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const institutionPageSettingsClear = createAction(`${INSTITUTION_PAGE_SETTINGS}/clear`)

export const institutionPageSettingsListClear = createAction(`${INSTITUTION_PAGE_SETTINGS_LIST}/clear`)

export const institutionPageSettingsAllClear = createAction(`${INSTITUTION_PAGE_SETTINGS_ALL}/clear`)

export const institutionPageSettingsIdentityClear = createAction(`${INSTITUTION_PAGE_SETTINGS_IDENTITY}/clear`)
