import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  E_LIBRARY,
  E_LIBRARY_ALL,
  E_LIBRARY_CREATE,
  E_LIBRARY_DELETE,
  E_LIBRARY_LIST,
  E_LIBRARY_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const eLibrary = createAsyncThunk(
  E_LIBRARY,
  async (params: PayloadCreatorParams<`${typeof API.E_LIBRARY}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.eLibrary(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const eLibraryAll = createAsyncThunk(
  E_LIBRARY_ALL,
  async (params: PayloadCreatorParams<`${typeof API.E_LIBRARY_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.eLibraryAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const eLibraryCreate = createAsyncThunk(
  E_LIBRARY_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.E_LIBRARY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.eLibraryCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const eLibraryDelete = createAsyncThunk(
  E_LIBRARY_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.E_LIBRARY}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.eLibraryDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const eLibraryList = createAsyncThunk(
  E_LIBRARY_LIST,
  async (params: PayloadCreatorParams<`${typeof API.E_LIBRARY}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.eLibraryList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const eLibraryTypes = createAsyncThunk(
  E_LIBRARY_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.E_LIBRARY_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.eLibraryTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const eLibraryClear = createAction(`${E_LIBRARY}/clear`)

export const eLibraryAllClear = createAction(`${E_LIBRARY_ALL}/clear`)

export const eLibraryListClear = createAction(`${E_LIBRARY_LIST}/clear`)

export const eLibraryTypesClear = createAction(`${E_LIBRARY_TYPES}/clear`)
