// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = (): boolean => {
  try {
    const item = window.localStorage.getItem('menuCollapsed')
    return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
  } catch (e) {
    console.warn(e)
  }
  return false
  //** Parse stored json or if none return initialValue
}

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  skin: 'light',
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  navbar: themeConfig.layout.navbar.type,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    layoutContentWidth: (state, action) => {
      state.contentWidth = action.payload
    },
    layoutMenuCollapsed: {
      reducer(state, action: PayloadAction<boolean>) {
        state.menuCollapsed = action.payload
      },
      prepare(value: boolean) {
        window.localStorage.setItem('menuCollapsed', `${value}`)
        return { payload: value }
      },
    },
    layoutMenuHidden: {
      reducer(state, action: PayloadAction<boolean>) {
        state.menuHidden = action.payload
      },
      prepare(value: boolean) {
        window.localStorage.setItem('menuHidden', `${value}`)
        return { payload: value }
      },
    },
    trackCurrentSkin: (state, action) => {
      state.skin = action.payload
    },
    setNavbarType: (state, action: PayloadAction<'static' | 'sticky' | 'floating' | 'hidden'>) => {
      state.navbar = action.payload
    },
  },
})

export const {
  reducer: layoutReducer,
  actions: { layoutContentWidth, layoutMenuHidden, trackCurrentSkin, layoutMenuCollapsed, setNavbarType },
} = layoutSlice
