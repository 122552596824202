import { Helmet } from 'react-helmet'

function GoogleTag() {
  return (
    <Helmet>
      <script id='google-tag-script'>{` 
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TH77LSFC');
      `}</script>
      <noscript id='google-tag-script'>
        {`<iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-TH77LSFC'
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>`}
      </noscript>
    </Helmet>
  )
}

export default GoogleTag
