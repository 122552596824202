import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { COURSE, COURSE_ALL, COURSE_CREATE, COURSE_LIST, GLOBAL_STATE } from '../constants/actionTypes'

const courseCreateSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const courseSelector = createSelector(courseCreateSelectors, prop(COURSE))

export const courseAllSelector = createSelector(courseCreateSelectors, prop(COURSE_ALL))

export const courseListSelector = createSelector(courseCreateSelectors, prop(COURSE_LIST))

export const courseCreateSelector = createSelector(courseCreateSelectors, prop(COURSE_CREATE))
export const courseAllOptionsSelector = createSelector(courseAllSelector, courseAll => {
  return {
    loading: courseAll.loading,
    error: courseAll.error,
    options: Array.isArray(courseAll.data)
      ? courseAll.data.map(item => ({ label: item.title || '', value: item.id }))
      : [],
  }
})
