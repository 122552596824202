import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { prop } from 'ramda'

import { TIMETABLE, TIMETABLE_ALL, TIMETABLE_GENERATE } from '../constants/actionTypes'
import { TIMETABLE as GLOBAL_STATE } from './reducer'

const timetableGlobalSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const timetableSelector = createSelector(timetableGlobalSelector, prop(TIMETABLE))

export const timetableAllSelector = createSelector(timetableGlobalSelector, prop(TIMETABLE_ALL))

export const timetableGenerateSelector = createSelector(timetableGlobalSelector, prop(TIMETABLE_GENERATE))
