const HR = '/hr/' as const
export const HR_APPLICATION_FORM = `${HR}application_form/` as const
export const HR_APPLICATION = `${HR}application/` as const
export const HR_APPLICATION_TYPES = `${HR_APPLICATION}types` as const
export const HR_APPLICATION_STATUS = `${HR_APPLICATION}status/` as const
export const HR_APPLICATION_ALL = `${HR_APPLICATION_FORM}all` as const
export const HR_APPLICATION_FIELD_BATCH = `${HR_APPLICATION_FORM}field/batch/` as const

// export const HR_APPLICATION_CATEGORY = `${HR_APPLICATION_FORM}category/`
// export const HR_APPLICATION_CATEGORY_ALL = `${HR_APPLICATION_CATEGORY}all/`

export const HR_APPLICATION_REQUEST = HR_APPLICATION
export const HR_APPLICATION_REQUEST_ALL = `${HR_APPLICATION_REQUEST}all` as const
export const HR_APPLICATION_REQUEST_EDIT = `${HR_APPLICATION_REQUEST}edit` as const
export const HR_APPLICATION_REQUEST_UPDATE = `${HR_APPLICATION_REQUEST}update` as const

export const HR_APPLICATION_SIGNUP = `${HR_APPLICATION_FORM}signup` as const
export const HR_APPLICATION_SMS_SEND = `${HR_APPLICATION_FORM}sms/send` as const
