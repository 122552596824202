import { DefaultState } from '@src/redux/types'

import {
  ACCOUNTING_CONTRACTS,
  ACCOUNTING_CONTRACTS_ASSIGN,
  ACCOUNTING_CONTRACTS_CREATE,
  ACCOUNTING_CONTRACTS_IMPORT,
  ACCOUNTING_CONTRACTS_LIST,
  ACCOUNTING_CONTRACTS_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ACCOUNTING_CONTRACTS]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS}{id}`, 'get'>
  //   [ACCOUNTING_CONTRACTS_ALL]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS_ALL}`, 'get'>
  [ACCOUNTING_CONTRACTS_ASSIGN]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS_ASSIGN}`, 'post'>
  [ACCOUNTING_CONTRACTS_CREATE]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS}`, 'post'>
  [ACCOUNTING_CONTRACTS_IMPORT]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS_IMPORT}`, 'post'>
  [ACCOUNTING_CONTRACTS_LIST]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS_LIST}`, 'get'>
  [ACCOUNTING_CONTRACTS_TYPES]: DefaultState<`${typeof API.ACCOUNTING_CONTRACTS_TYPES}`, 'get'>
} = () => ({
  [ACCOUNTING_CONTRACTS]: defaultState(),
  //   [ACCOUNTING_CONTRACTS_ALL]: defaultState(),
  [ACCOUNTING_CONTRACTS_ASSIGN]: defaultState(),
  [ACCOUNTING_CONTRACTS_CREATE]: defaultState(),
  [ACCOUNTING_CONTRACTS_IMPORT]: defaultState(),
  [ACCOUNTING_CONTRACTS_LIST]: defaultState(),
  [ACCOUNTING_CONTRACTS_TYPES]: defaultState(),
})
