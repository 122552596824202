import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACCOUNT,
  ACCOUNT_CREATE,
  ACCOUNT_LIST,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const accountSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const accountSelector = createSelector(accountSelectors, prop(ACCOUNT))

export const accountCreateSelector = createSelector(accountSelectors, prop(ACCOUNT_CREATE))

export const accountListSelector = createSelector(accountSelectors, prop(ACCOUNT_LIST))

export const accountTypesSelector = createSelector(accountSelectors, prop(ACCOUNT_TYPES))

export const accountProfileUpdateSelector = createSelector(accountSelectors, prop(ACCOUNT_PROFILE_UPDATE))
