import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const semesterAll: ApiFunc<`${typeof API.SEMESTER_ALL}`, 'get'> = data =>
  instance.get(API.SEMESTER_ALL, {
    params: data,
  })
