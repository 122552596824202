import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_MARK_STATUS,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const assessmentMarkSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const assessmentMarkStatusSelector = createSelector(assessmentMarkSelectors, prop(ASSESSMENT_MARK_STATUS))

export const assessmentAmendmentSelector = createSelector(assessmentMarkSelectors, prop(ASSESSMENT_AMENDMENT))

export const assessmentAmendmentAllSelector = createSelector(assessmentMarkSelectors, prop(ASSESSMENT_AMENDMENT_ALL))

export const assessmentAmendmentListSelector = createSelector(assessmentMarkSelectors, prop(ASSESSMENT_AMENDMENT_LIST))

export const assessmentAmendmentCreateSelector = createSelector(
  assessmentMarkSelectors,
  prop(ASSESSMENT_AMENDMENT_CREATE)
)
