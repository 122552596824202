import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const account: ApiFunc<`${typeof API.ACCOUNT}{id}`, 'get'> = data =>
  instance.get(`${API.ACCOUNT}${'id' in data ? prop('id', data) : prop('name', data)}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const accountTypes: ApiFunc<`${typeof API.ACCOUNT_TYPES}`, 'get'> = data =>
  instance.get(API.ACCOUNT_TYPES, {
    params: data,
  })

export const accountCreate: ApiFunc<`${typeof API.ACCOUNT}`, 'post'> = data => instance.post(API.ACCOUNT, data)

export const accountDelete: ApiFunc<`${typeof API.ACCOUNT}{id}`, 'delete'> = data =>
  instance.delete(`${API.ACCOUNT}${'id' in data ? prop('id', data) : data?.name}`)

export const accountList: ApiFunc<`${typeof API.ACCOUNT}`, 'get'> = data =>
  instance.get(API.ACCOUNT, {
    params: data,
  })

export const accountPasswordReset: ApiFunc<`${typeof API.ACCOUNT_PASSWORD_RESET}`, 'put'> = data =>
  instance.put(API.ACCOUNT_PASSWORD_RESET, data)

export const accountRequiredAction: ApiFunc<`${typeof API.ACCOUNT_REQUIRED_ACTION}`, 'put'> = data =>
  instance.put(API.ACCOUNT_REQUIRED_ACTION, data)

export const accountProfilePic: ApiFunc<`${typeof API.ACCOUNT_PROFILE_PIC}`, 'put'> = data =>
  instance.put(API.ACCOUNT_PROFILE_PIC, data)

export const accountProfileUpdate: ApiFunc<`${typeof API.ACCOUNT_PROFILE_UPDATE}`, 'put'> = data =>
  instance.put(API.ACCOUNT_PROFILE_UPDATE, data)
