/* eslint-disable */
import { find, prop, propEq } from 'ramda'

type OptionOfStringTypes = {
  [key: string]: string
}

type Option = {
  label: string;
  value: string | number;
}

export function defaultOptionCreator(initialValue: string | number = '', types: OptionOfStringTypes | Option[]): Option | [] {
  if (!initialValue) {
    return []
  }
  if (!Array.isArray(types)) {
    return {
      label: prop(initialValue, types),
      value: initialValue,
    }
  } else {
    return find((type: Option) => propEq('value', initialValue)(type))(types) || []
  }
}
