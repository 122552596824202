import { lazy } from 'react'
import { VACANCY_POSITION } from '../constants/route'
import * as roles from '@constants/userRoles'

export const VacancyPositionRoutes = [
  {
    path: VACANCY_POSITION,
    component: lazy(() => import('../views/VacancyPositions')),
    meta: {
      access: [roles.SUPER_ADMIN, roles.HR_HEAD, roles.HR_MANAGER, roles.ADMIN],
    },
  },
]
