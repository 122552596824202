import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const offenceList: ApiFunc<`${typeof API.OFFENCE}`, 'get'> = data =>
  instance.get(API.OFFENCE, {
    params: data,
  })

export const offenceReportList: ApiFunc<`${typeof API.OFFENCE_REPORT}`, 'get'> = data =>
  instance.get(API.OFFENCE_REPORT, {
    params: data,
  })

export const offenceReportCreate: ApiFunc<`${typeof API.OFFENCE_REPORT}`, 'post'> = data =>
  instance.post(API.OFFENCE_REPORT, data)

export const offenceReportAll: ApiFunc<`${typeof API.OFFENCE_REPORT_ALL}`, 'get'> = data =>
  instance.get(API.OFFENCE_REPORT_ALL, {
    params: data,
  })

export const offenceCreate: ApiFunc<`${typeof API.OFFENCE}`, 'post'> = data => instance.post(API.OFFENCE, data)

export const offencePublish: ApiFunc<`${typeof API.OFFENCE_PUBLISH}`, 'put'> = data => {
  return instance.put(API.OFFENCE_PUBLISH, data)
}
export const offenceUnpublish: ApiFunc<`${typeof API.OFFENCE_UNPUBLISH}`, 'put'> = data =>
  instance.put(API.OFFENCE_UNPUBLISH, data)

export const offence: ApiFunc<`${typeof API.OFFENCE}{id}`, 'get'> = data =>
  instance.get(`${API.OFFENCE}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const offenceDelete: ApiFunc<`${typeof API.OFFENCE}{id}`, 'delete'> = data =>
  instance.delete(`${API.OFFENCE}${'id' in data ? prop('id', data) : data?.name}`)

export const offenceAll: ApiFunc<`${typeof API.OFFENCE_ALL}`, 'get'> = data =>
  instance.get(API.OFFENCE_ALL, {
    params: data,
  })

export const offenceInvigilationList: ApiFunc<`${typeof API.OFFENCE_INVIGILATION}`, 'get'> = data =>
  instance.get(API.OFFENCE_INVIGILATION, {
    params: data,
  })

export const offenceInvigilationCreate: ApiFunc<`${typeof API.OFFENCE_INVIGILATION}`, 'post'> = data =>
  instance.post(API.OFFENCE_INVIGILATION, data)

export const offenceInvigilation: ApiFunc<`${typeof API.OFFENCE_INVIGILATION}{id}`, 'get'> = data =>
  instance.get(`${API.OFFENCE_INVIGILATION}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const offenceInvigilationDelete: ApiFunc<`${typeof API.OFFENCE_INVIGILATION}{id}`, 'delete'> = data =>
  instance.delete(`${API.OFFENCE_INVIGILATION}${'id' in data ? prop('id', data) : prop('name', data)}`)

export const offenceInvigilationAll: ApiFunc<`${typeof API.OFFENCE_INVIGILATION_ALL}`, 'get'> = data =>
  instance.get(API.OFFENCE_INVIGILATION_ALL, {
    params: data,
  })

export const offenceInvigilationMinutesList: ApiFunc<`${typeof API.OFFENCE_INVIGILATION_MINUTES}`, 'get'> = data =>
  instance.get(API.OFFENCE_INVIGILATION_MINUTES, {
    params: data,
  })

export const offenceInvigilationMinutesCreate: ApiFunc<`${typeof API.OFFENCE_INVIGILATION_MINUTES}`, 'post'> = data =>
  instance.post(API.OFFENCE_INVIGILATION_MINUTES, data)

export const offenceInvigilationMinutes: ApiFunc<`${typeof API.OFFENCE_INVIGILATION_MINUTES}{id}`, 'get'> = data =>
  instance.get(`${API.OFFENCE_INVIGILATION_MINUTES}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const offenceInvigilationMinutesDelete: ApiFunc<
  `${typeof API.OFFENCE_INVIGILATION_MINUTES}{id}`,
  'delete'
> = data =>
  instance.delete(`${API.OFFENCE_INVIGILATION_MINUTES}${'id' in data ? prop('id', data) : prop('name', data)}`)

export const offenceInvigilationMinutesAll: ApiFunc<`${typeof API.OFFENCE_INVIGILATION_MINUTES_ALL}`, 'get'> = data =>
  instance.get(API.OFFENCE_INVIGILATION_MINUTES_ALL, {
    params: data,
  })

export const offenceInvigilationConsultationsList: ApiFunc<
  `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}`,
  'get'
> = data =>
  instance.get(API.OFFENCE_INVIGILATION_CONSULTATIONS, {
    params: data,
  })

export const offenceInvigilationConsultationsCreate: ApiFunc<
  `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}`,
  'post'
> = data => instance.post(API.OFFENCE_INVIGILATION_CONSULTATIONS, data)

export const offenceInvigilationConsultations: ApiFunc<
  `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}{id}`,
  'get'
> = data =>
  instance.get(`${API.OFFENCE_INVIGILATION_CONSULTATIONS}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const offenceInvigilationConsultationsDelete: ApiFunc<
  `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}{id}`,
  'delete'
> = data =>
  instance.delete(`${API.OFFENCE_INVIGILATION_CONSULTATIONS}${'id' in data ? prop('id', data) : prop('name', data)}`)

export const offenceInvigilationConsultationsAll: ApiFunc<
  `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS_ALL}`,
  'get'
> = data =>
  instance.get(API.OFFENCE_INVIGILATION_CONSULTATIONS_ALL, {
    params: data,
  })

export const offenceTypeList: ApiFunc<`${typeof API.OFFENCE_TYPE}`, 'get'> = data =>
  instance.get(API.OFFENCE_TYPE, {
    params: data,
  })

export const offenceTypeCreate: ApiFunc<`${typeof API.OFFENCE_TYPE}`, 'post'> = data =>
  instance.post(API.OFFENCE_TYPE, data)

export const offenceType: ApiFunc<`${typeof API.OFFENCE_TYPE}{id}`, 'get'> = data =>
  instance.get(`${API.OFFENCE_TYPE}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const offenceTypeDelete: ApiFunc<`${typeof API.OFFENCE_TYPE}{id}`, 'delete'> = data =>
  instance.delete(`${API.OFFENCE_TYPE}${'id' in data ? prop('id', data) : prop('name', data)}`)

export const offenceTypeAll: ApiFunc<`${typeof API.OFFENCE_TYPE_ALL}`, 'get'> = data =>
  instance.get(API.OFFENCE_TYPE_ALL, {
    params: data,
  })
export const offenceChangeStatus: ApiFunc<`${typeof API.OFFENCE_CHANGE_STATUS}`, 'put'> = data =>
  instance.put(API.OFFENCE_CHANGE_STATUS, data)

export const offenceChangeSubjectBoardStatus: ApiFunc<
  `${typeof API.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS}`,
  'put'
> = data => instance.put(API.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS, data)

export const reportMisconduct: ApiFunc<`${typeof API.REPORT_MISCONDUCT}`, 'post'> = data =>
  instance.post(API.REPORT_MISCONDUCT, data)
