import { equals, omit, prop, propOr } from 'ramda/es'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Input, FormText, Label, NavItem, NavLink, Nav } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { indexedMap } from '../../helpers/indexedMap'
import classNames from 'classnames'
import { useWindowSize } from 'react-use'

const MLInputField = props => {
  const { width } = useWindowSize()
  const {
    label,
    placeholder,
    type,
    autoFocus,
    autoComplete,
    disabled,
    maxLength,
    definite,
    onKeyPress = () => {},
    className,
    rows,
    row,
    style = {},
    min,
    colWrapperClass,
    id = '',
    handleChange = () => {},
    register,
    value,
    name,
    error,
    formGroupStyle = {},
    customErrorMessage = false,
    defaultValue,
    rules = {},

    tabItem,
    tabs,
    activeTab,
    toggleTab,
  } = props

  const validate = prop('validate', rules)

  const rulesOverride = validate
    ? {
        ...rules,
        validate: value => {
          return validate(value, `${name}.${tabItem}`)
        },
      }
    : rules

  const registered = register(`${name}.${tabItem}`, rulesOverride)

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  const smLabel = propOr(12, 'label', row)
  const smInput = propOr(12, 'input', row)
  // const isEmptyValue =
  //   typeof value === 'string' ? isEmpty(trim(value)) : !(typeof value === 'number')
  // const errStyles = isRequired && isEmptyValue && touched ? { borderColor: '#c9321f' } : {}
  // const errStyles = error && (touched || isSubmitted) ? { borderColor: '#c9321f' } : {}

  function change({ e, onChange }) {
    let { value } = e.target
    value = type === 'number' ? +value : value
    onChange(e)
    handleChange(value)
  }

  return (
    <FormGroup style={formGroupStyle}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        {label && <Label {...htmlFor}>{label}</Label>}
        <Nav tabs className='m-0'>
          {indexedMap((tab, idx) => {
            // const Icon = propOr(Circle, prop('tab_type', tab), iconEnum)
            return (
              <Fragment key={propOr(idx, 'tab_type', tab)}>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classNames({
                      active: activeTab === idx,
                    })}
                    style={{ padding: width < 992 && width > 768 ? '0.358rem 0.5rem' : '' }}
                    onClick={() => {
                      toggleTab(idx)
                    }}
                  >
                    {/*<Icon size={20} />*/}
                    <span className='align-middle' style={{ fontSize: '12px' }}>
                      {tab}
                    </span>
                  </NavLink>
                </NavItem>
              </Fragment>
            )
          })(tabs)}
        </Nav>
      </div>
      <Input
        {...omit(['ref', 'onChange', 'value'], registered)}
        label={label}
        placeholder={placeholder}
        type={type}
        min={min}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        maxLength={maxLength}
        onChange={e => {
          change({ e, onChange: prop('onChange', registered) })
        }}
        onKeyPress={onKeyPress}
        className={className}
        rows={rows}
        {...htmlId}
        style={{ ...style }}
        innerRef={prop('ref', registered)}
        invalid={!!error}
      />
      {error && (
        <FormText color='primary'>
          {customErrorMessage ? (
            equals(prop('type', error), 'min') ? (
              `Value should not be less than ${min}`
            ) : equals(prop('type', error), 'max') ? (
              `Value should not exceed ${maxLength}`
            ) : equals(prop('message', error), 'definite') ? (
              `Value should be ${definite} characters long`
            ) : (
              prop('message', error)
            )
          ) : (
            <FormattedMessage id={equals(prop('message', error), 'range') ? 'number_range' : 'form_required'} />
          )}
        </FormText>
      )}
    </FormGroup>
  )
}

MLInputField.defaultProps = {
  type: 'text',
  disabled: false,
  maxLength: '1000',
  style: {},
}

MLInputField.propTypes = {
  handleChange: PropTypes.func,
  register: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.object,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  customErrorMessage: PropTypes.bool,
}

export default MLInputField
