import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  ST_COURSE_APPLICATION,
  ST_COURSE_APPLICATION_ALL,
  ST_COURSE_APPLICATION_FIELD_BATCH,
  ST_COURSE_APPLICATION_LIST,
  ST_COURSE_APPLICATION_REQUEST_ALL,
  ST_COURSE_APPLICATION_REQUEST_EDIT,
  ST_COURSE_APPLICATION_REQUEST_UPDATE,
  ST_COURSE_APPLICATION_STATUS,
  ST_COURSE_APPLICATION_TYPES,
} from '../constants/actionTypes'

const stCourseAdmissionSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const stCourseApplicationSelector = createSelector(stCourseAdmissionSelectors, prop(ST_COURSE_APPLICATION))

export const stCourseApplicationListSelector = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_LIST)
)

export const stCourseApplicationTypesSelector = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_TYPES)
)

export const stCourseApplicationStatusSelector = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_STATUS)
)

export const stCourseApplicationAllSelector = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_ALL)
)
export const stCourseApplicationFieldBatchSelector = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_FIELD_BATCH)
)

// request
export const stCourseApplicationRequestAll = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_REQUEST_ALL)
)
export const stCourseApplicationRequestEdit = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_REQUEST_EDIT)
)

export const stCourseApplicationRequestUpdate = createSelector(
  stCourseAdmissionSelectors,
  prop(ST_COURSE_APPLICATION_REQUEST_UPDATE)
)
