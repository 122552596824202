import { DefaultState } from '@src/redux/types'

import {
  E_LIBRARY,
  E_LIBRARY_ALL,
  E_LIBRARY_CREATE,
  E_LIBRARY_DELETE,
  E_LIBRARY_LIST,
  E_LIBRARY_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [E_LIBRARY]: DefaultState<`${typeof API.E_LIBRARY}{id}`, 'get'>
  [E_LIBRARY_ALL]: DefaultState<`${typeof API.E_LIBRARY_ALL}`, 'get'>
  [E_LIBRARY_CREATE]: DefaultState<`${typeof API.E_LIBRARY}`, 'post'>
  [E_LIBRARY_DELETE]: DefaultState<`${typeof API.E_LIBRARY}{id}`, 'delete'>
  [E_LIBRARY_LIST]: DefaultState<`${typeof API.E_LIBRARY}`, 'get'>
  [E_LIBRARY_TYPES]: DefaultState<`${typeof API.E_LIBRARY_TYPES}`, 'get'>
} = () => ({
  [E_LIBRARY]: defaultState(),
  [E_LIBRARY_ALL]: defaultState(),
  [E_LIBRARY_CREATE]: defaultState(),
  [E_LIBRARY_DELETE]: defaultState(),
  [E_LIBRARY_LIST]: defaultState(),
  [E_LIBRARY_TYPES]: defaultState(),
})
