import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, ROLE, ROLE_ALL, ROLE_CREATE, ROLE_DELETE, ROLE_LIST } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ROLES = GLOBAL_STATE

export const { reducer: roleReducer } = createSlice({
  name: ROLES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.role.pending, state => {
        state[ROLE].loading = true
      })
      .addCase(actionCreators.role.rejected, (state, action) => {
        state[ROLE].loading = false
        state[ROLE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.role.fulfilled, (state, action) => {
        state[ROLE].loading = false
        state[ROLE].error = null
        state[ROLE].data = action.payload
      })

    builder
      .addCase(actionCreators.roleAll.pending, state => {
        state[ROLE_ALL].loading = true
      })
      .addCase(actionCreators.roleAll.rejected, (state, action) => {
        state[ROLE_ALL].loading = false
        state[ROLE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.roleAll.fulfilled, (state, action) => {
        state[ROLE_ALL].loading = false
        state[ROLE_ALL].error = null
        state[ROLE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.roleCreate.pending, state => {
        state[ROLE_CREATE].loading = true
      })
      .addCase(actionCreators.roleCreate.rejected, (state, action) => {
        state[ROLE_CREATE].loading = false
        state[ROLE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.roleCreate.fulfilled, (state, action) => {
        state[ROLE_CREATE].loading = false
        state[ROLE_CREATE].error = null
        state[ROLE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.roleDelete.pending, state => {
        state[ROLE_DELETE].loading = true
      })
      .addCase(actionCreators.roleDelete.rejected, (state, action) => {
        state[ROLE_DELETE].loading = false
        state[ROLE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.roleDelete.fulfilled, (state, action) => {
        state[ROLE_DELETE].loading = false
        state[ROLE_DELETE].error = null
        state[ROLE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.roleList.pending, state => {
        state[ROLE_LIST].loading = true
      })
      .addCase(actionCreators.roleList.rejected, (state, action) => {
        state[ROLE_LIST].loading = false
        state[ROLE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.roleList.fulfilled, (state, action) => {
        state[ROLE_LIST].loading = false
        state[ROLE_LIST].error = null
        state[ROLE_LIST].data = action.payload
      })

    builder.addCase(actionCreators.roleClear, state => {
      state[ROLE] = defaultState()
    })

    builder.addCase(actionCreators.roleAllClear, state => {
      state[ROLE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.roleListClear, state => {
      state[ROLE_LIST] = defaultState()
    })
  },
})
