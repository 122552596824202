import { DefaultState } from '@src/redux/types'

import {
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [MODULE_FILE]: DefaultState<`${typeof API.MODULE_FILE}{id}`, 'get'>
  [MODULE_FILE_ALL]: DefaultState<`${typeof API.MODULE_FILE_ALL}`, 'get'>
  [MODULE_FILE_CREATE]: DefaultState<`${typeof API.MODULE_FILE}`, 'post'>
  [MODULE_FILE_DELETE]: DefaultState<`${typeof API.MODULE_FILE}{id}`, 'delete'>
  [MODULE_FILE_LIST]: DefaultState<`${typeof API.MODULE_FILE}`, 'get'>
} = () => ({
  [MODULE_FILE]: defaultState(),
  [MODULE_FILE_ALL]: defaultState(),
  [MODULE_FILE_CREATE]: defaultState(),
  [MODULE_FILE_DELETE]: defaultState(),
  [MODULE_FILE_LIST]: defaultState(),
})
