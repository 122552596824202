import { DefaultState } from '@src/redux/types'

import { STATS } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [STATS]: DefaultState<`${typeof API.STATS}`, 'get'>
} = () => ({
  [STATS]: defaultState(),
})
