import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const studentCV: ApiFunc<`${typeof API.STUDENT_CV}{id}`, 'get'> = data =>
  instance.get(`${API.STUDENT_CV}${'id' in data ? data.id : ''}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const studentCVPrincipal: ApiFunc<`${typeof API.STUDENT_CV_PRINCIPAL}`, 'get'> = data =>
  instance.get(API.STUDENT_CV_PRINCIPAL, {
    params: data,
  })

export const studentCVCreate: ApiFunc<`${typeof API.STUDENT_CV}`, 'post'> = data => instance.post(API.STUDENT_CV, data)

export const studentCVList: ApiFunc<`${typeof API.STUDENT_CV}`, 'get'> = data =>
  instance.get(API.STUDENT_CV, {
    params: data,
  })

export const studentCVComments: ApiFunc<`${typeof API.STUDENT_CV_REVIEW}`, 'post'> = data =>
  instance.post(API.STUDENT_CV_REVIEW, data)

export const studentCVCurator: ApiFunc<`${typeof API.STUDENT_CV_CURATOR}`, 'get'> = data =>
  instance.get(API.STUDENT_CV_CURATOR, {
    params: data,
  })

export const studentCVHardSkills: ApiFunc<`${typeof API.STUDENT_CV_HARD_SKILLS}`, 'get'> = data =>
  instance.get(API.STUDENT_CV_HARD_SKILLS, {
    params: data,
  })

export const studentCVSoftSkills: ApiFunc<`${typeof API.STUDENT_CV_SOFT_SKILLS}`, 'get'> = data =>
  instance.get(API.STUDENT_CV_SOFT_SKILLS, {
    params: data,
  })

export const studentCVGenerateObjective: ApiFunc<`${typeof API.STUDENT_CV_GENERATE_OBJECTIVE}`, 'post'> = data =>
  instance.post(API.STUDENT_CV_GENERATE_OBJECTIVE, data)

export const studentCVGenerateCV: ApiFunc<`${typeof API.STUDENT_CV_GENERATE_CV}`, 'post'> = data =>
  instance.post(API.STUDENT_CV_GENERATE_CV, data)

export const studentCVChangeStatus: ApiFunc<`${typeof API.STUDENT_CV_CHANGE_STATUS}`, 'put'> = data =>
  instance.put(API.STUDENT_CV_CHANGE_STATUS, data)
