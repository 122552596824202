import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, omit, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const form: ApiFunc<`${typeof API.FORM}{id}`, 'get'> = data =>
  instance.get(`${API.FORM}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const formCreate: ApiFunc<`${typeof API.FORM}`, 'post'> = data => instance.post(API.FORM, data)

export const formDelete: ApiFunc<`${typeof API.FORM}{id}`, 'delete'> = data =>
  instance.delete(`${API.FORM}${'id' in data ? prop('id', data) : data.name}`)

export const formList: ApiFunc<`${typeof API.FORM}`, 'get'> = data =>
  instance.get(API.FORM, {
    params: data,
  })

export const formAll: ApiFunc<`${typeof API.FORM_ALL}`, 'get'> = data =>
  instance.get(API.FORM_ALL, {
    params: data,
  })

export const formActionType: ApiFunc<`${typeof API.FORM_ACTION_TYPE}`, 'get'> = data =>
  instance.get(API.FORM_ACTION_TYPE, {
    params: data,
  })

export const formFieldCreate: ApiFunc<`${typeof API.FORM_FIELD}`, 'post'> = data =>
  instance.post(API.FORM_FIELD, {
    params: data,
  })

export const formFieldUpdate: ApiFunc<`${typeof API.FORM_FIELD}{id}`, 'put'> = data =>
  instance.put(`${API.FORM_FIELD}${'id' in data ? prop('id', data) : data.name}`, {
    params: data,
  })

// export const formFieldDelete: ApiFunc<`${typeof API.FORM_FIELD}`, 'delete'> = data =>
//   instance.delete(API.FORM_FIELD, {
//     params: data,
//   })

export const formFieldBatch: ApiFunc<`${typeof API.FORM_FIELD_BATCH}{id}`, 'get'> = data =>
  instance.get(`${API.FORM_FIELD_BATCH}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const formFieldBatchCreate: ApiFunc<`${typeof API.FORM_FIELD_BATCH}`, 'post'> = data =>
  instance.post(API.FORM_FIELD_BATCH, data)

// formType
export const formType: ApiFunc<`${typeof API.FORM_TYPE}{id}`, 'get'> = data =>
  instance.get(`${API.FORM_TYPE}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const formTypeCreate: ApiFunc<`${typeof API.FORM_TYPE}`, 'post'> = data => instance.post(API.FORM_TYPE, data)

export const formTypeDelete: ApiFunc<`${typeof API.FORM_TYPE}{id}`, 'delete'> = data =>
  instance.delete(`${API.FORM_TYPE}${'id' in data ? prop('id', data) : data.name}`)

export const formTypeList: ApiFunc<`${typeof API.FORM_TYPE}`, 'get'> = data =>
  instance.get(API.FORM_TYPE, {
    params: data,
  })

export const formTypeAll: ApiFunc<`${typeof API.FORM_TYPE_ALL}`, 'get'> = data =>
  instance.get(API.FORM_TYPE_ALL, {
    params: data,
  })

// formRequest
export const formRequest: ApiFunc<`${typeof API.FORM_REQUEST}{id}`, 'get'> = data => {
  return instance.get(`${API.FORM_REQUEST}${'id' in data ? prop('id', data) : data.name}`, {
    params: omit(['id', 'openModal'], data),
  })
}

export const formRequestCreate: ApiFunc<`${typeof API.FORM_REQUEST}`, 'post'> = data =>
  instance.post(API.FORM_REQUEST, data)

export const formRequestDelete: ApiFunc<`${typeof API.FORM_REQUEST}{id}`, 'delete'> = data =>
  instance.delete(`${API.FORM_REQUEST}${'id' in data ? prop('id', data) : data.name}`)

export const formRequestList: ApiFunc<`${typeof API.FORM_REQUEST}`, 'get'> = data =>
  instance.get(API.FORM_REQUEST, {
    params: data,
  })

export const formRequestAll: ApiFunc<`${typeof API.FORM_REQUEST_ALL}`, 'get'> = data =>
  instance.get(API.FORM_REQUEST_ALL, {
    params: data,
  })

export const formRequestChangeStatus: ApiFunc<`${typeof API.FORM_REQUEST_CHANGE_STATUS}`, 'post'> = data =>
  instance.post(API.FORM_REQUEST_CHANGE_STATUS, data)

export const formRequestFields: ApiFunc<`${typeof API.FORM_REQUEST_FIELDS}`, 'post'> = data =>
  instance.post(API.FORM_REQUEST_FIELDS, data)

export const formRequestCertificate: ApiFunc<`${typeof API.FORM_REQUEST_CERTIFICATE}`, 'post'> = data =>
  instance.post(API.FORM_REQUEST_CERTIFICATE, data)
