import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ACCOUNT,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_LIST,
  ACCOUNT_PASSWORD_RESET,
  ACCOUNT_PROFILE_PIC,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_REQUIRED_ACTIONS,
  ACCOUNT_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ACCOUNTS = GLOBAL_STATE

export const { reducer: accountReducer } = createSlice({
  name: ACCOUNTS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.account.pending, state => {
        state[ACCOUNT].loading = true
      })
      .addCase(actionCreators.account.rejected, (state, action) => {
        state[ACCOUNT].loading = false
        state[ACCOUNT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.account.fulfilled, (state, action) => {
        state[ACCOUNT].loading = false
        state[ACCOUNT].error = null
        state[ACCOUNT].data = action.payload
      })

    builder
      .addCase(actionCreators.accountCreate.pending, state => {
        state[ACCOUNT_CREATE].loading = true
      })
      .addCase(actionCreators.accountCreate.rejected, (state, action) => {
        state[ACCOUNT_CREATE].loading = false
        state[ACCOUNT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountCreate.fulfilled, (state, action) => {
        state[ACCOUNT_CREATE].loading = false
        state[ACCOUNT_CREATE].error = null
        state[ACCOUNT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.accountDelete.pending, state => {
        state[ACCOUNT_DELETE].loading = true
      })
      .addCase(actionCreators.accountDelete.rejected, (state, action) => {
        state[ACCOUNT_DELETE].loading = false
        state[ACCOUNT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountDelete.fulfilled, (state, action) => {
        state[ACCOUNT_DELETE].loading = false
        state[ACCOUNT_DELETE].error = null
        state[ACCOUNT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.accountList.pending, state => {
        state[ACCOUNT_LIST].loading = true
      })
      .addCase(actionCreators.accountList.rejected, (state, action) => {
        state[ACCOUNT_LIST].loading = false
        state[ACCOUNT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountList.fulfilled, (state, action) => {
        state[ACCOUNT_LIST].loading = false
        state[ACCOUNT_LIST].error = null
        state[ACCOUNT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.accountPasswordReset.pending, state => {
        state[ACCOUNT_PASSWORD_RESET].loading = true
      })
      .addCase(actionCreators.accountPasswordReset.rejected, (state, action) => {
        state[ACCOUNT_PASSWORD_RESET].loading = false
        state[ACCOUNT_PASSWORD_RESET].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountPasswordReset.fulfilled, (state, action) => {
        state[ACCOUNT_PASSWORD_RESET].loading = false
        state[ACCOUNT_PASSWORD_RESET].error = null
        state[ACCOUNT_PASSWORD_RESET].data = action.payload
      })

    builder
      .addCase(actionCreators.accountProfilePic.pending, state => {
        state[ACCOUNT_PROFILE_PIC].loading = true
      })
      .addCase(actionCreators.accountProfilePic.rejected, (state, action) => {
        state[ACCOUNT_PROFILE_PIC].loading = false
        state[ACCOUNT_PROFILE_PIC].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountProfilePic.fulfilled, (state, action) => {
        state[ACCOUNT_PROFILE_PIC].loading = false
        state[ACCOUNT_PROFILE_PIC].error = null
        state[ACCOUNT_PROFILE_PIC].data = action.payload
      })

    builder
      .addCase(actionCreators.accountProfileUpdate.pending, state => {
        state[ACCOUNT_PROFILE_UPDATE].loading = true
      })
      .addCase(actionCreators.accountProfileUpdate.rejected, (state, action) => {
        state[ACCOUNT_PROFILE_UPDATE].loading = false
        state[ACCOUNT_PROFILE_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountProfileUpdate.fulfilled, (state, action) => {
        state[ACCOUNT_PROFILE_UPDATE].loading = false
        state[ACCOUNT_PROFILE_UPDATE].error = null
        state[ACCOUNT_PROFILE_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.accountRequiredActions.pending, state => {
        state[ACCOUNT_REQUIRED_ACTIONS].loading = true
      })
      .addCase(actionCreators.accountRequiredActions.rejected, (state, action) => {
        state[ACCOUNT_REQUIRED_ACTIONS].loading = false
        state[ACCOUNT_REQUIRED_ACTIONS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountRequiredActions.fulfilled, (state, action) => {
        state[ACCOUNT_REQUIRED_ACTIONS].loading = false
        state[ACCOUNT_REQUIRED_ACTIONS].error = null
        state[ACCOUNT_REQUIRED_ACTIONS].data = action.payload
      })

    builder
      .addCase(actionCreators.accountTypes.pending, state => {
        state[ACCOUNT_TYPES].loading = true
      })
      .addCase(actionCreators.accountTypes.rejected, (state, action) => {
        state[ACCOUNT_TYPES].loading = false
        state[ACCOUNT_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.accountTypes.fulfilled, (state, action) => {
        state[ACCOUNT_TYPES].loading = false
        state[ACCOUNT_TYPES].error = null
        state[ACCOUNT_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.accountClear, state => {
      state[ACCOUNT] = defaultState()
    })

    builder.addCase(actionCreators.accountListClear, state => {
      state[ACCOUNT_LIST] = defaultState()
    })

    builder.addCase(actionCreators.accountTypesClear, state => {
      state[ACCOUNT_TYPES] = defaultState()
    })
  },
})
