import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { CITY, CITY_ALL, CITY_CREATE, CITY_DELETE, CITY_LIST, GLOBAL_STATE } from '../constants/actionTypes'

const citySelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const citySelector = createSelector(citySelectors, prop(CITY))

export const cityListSelector = createSelector(citySelectors, prop(CITY_LIST))

export const cityAllSelector = createSelector(citySelectors, prop(CITY_ALL))

export const cityCreateSelector = createSelector(citySelectors, prop(CITY_CREATE))

export const cityDeleteSelector = createSelector(citySelectors, prop(CITY_DELETE))
