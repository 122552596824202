import * as ROLES from '@constants/userRoles'
import { lazy } from 'react'

import { JOB_OFFER } from '../constants/route'

export const JobOfferRoutes = [
  {
    path: JOB_OFFER,
    component: lazy(() => import('../views/JobOfferList')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.BACHELOR_STUDENT,
        ROLES.STAFF,
        ROLES.MS_STUDENT,
        ROLES.MASTER_STUDENT,
        ROLES.TEST_STUDENT,
      ],
    },
  },
]
