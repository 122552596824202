import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  HR_APPLICATION,
  HR_APPLICATION_ALL,
  HR_APPLICATION_CREATE,
  HR_APPLICATION_DELETE,
  HR_APPLICATION_FIELD_BATCH,
  HR_APPLICATION_FIELD_BATCH_CREATE,
  HR_APPLICATION_LIST,
  HR_APPLICATION_REQUEST_ALL,
  HR_APPLICATION_REQUEST_CREATE,
  HR_APPLICATION_REQUEST_EDIT,
  HR_APPLICATION_REQUEST_UPDATE,
  HR_APPLICATION_STATUS,
  HR_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const hrApplication = createAsyncThunk(
  HR_APPLICATION,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_FORM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplication(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationCreate = createAsyncThunk(
  HR_APPLICATION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_FORM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationDelete = createAsyncThunk(
  HR_APPLICATION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_FORM}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationList = createAsyncThunk(
  HR_APPLICATION_LIST,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationAll = createAsyncThunk(
  HR_APPLICATION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationTypes = createAsyncThunk(
  HR_APPLICATION_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationChangeStatus = createAsyncThunk(
  HR_APPLICATION_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationFieldBatch = createAsyncThunk(
  HR_APPLICATION_FIELD_BATCH,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_FIELD_BATCH}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationFieldBatch(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationFieldBatchCreate = createAsyncThunk(
  HR_APPLICATION_FIELD_BATCH_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_FIELD_BATCH}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationFieldBatchCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationRequestCreate = createAsyncThunk(
  HR_APPLICATION_REQUEST_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_REQUEST}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationRequestCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationRequestAll = createAsyncThunk(
  HR_APPLICATION_REQUEST_ALL,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_REQUEST_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationRequestAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationRequestEdit = createAsyncThunk(
  HR_APPLICATION_REQUEST_EDIT,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_REQUEST_EDIT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationRequestEdit(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationRequestUpdate = createAsyncThunk(
  HR_APPLICATION_REQUEST_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.HR_APPLICATION_REQUEST_UPDATE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.hrApplicationRequestUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const hrApplicationClear = createAction(`${HR_APPLICATION}/clear`)

export const hrApplicationListClear = createAction(`${HR_APPLICATION_LIST}/clear`)

export const hrApplicationAllClear = createAction(`${HR_APPLICATION_ALL}/clear`)

export const hrApplicationTypesClear = createAction(`${HR_APPLICATION_TYPES}/clear`)

export const hrApplicationFieldBatchClear = createAction(`${HR_APPLICATION_FIELD_BATCH}/clear`)

export const hrApplicationRequestEditClear = createAction(`${HR_APPLICATION_REQUEST_EDIT}/clear`)

export const hrApplicationRequestUpdateClear = createAction(`${HR_APPLICATION_REQUEST_UPDATE}/clear`)
