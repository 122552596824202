import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  VACANCY_POSITION,
  VACANCY_POSITION_ACTIVATE,
  VACANCY_POSITION_ALL,
  VACANCY_POSITION_CREATE,
  VACANCY_POSITION_DELETE,
  VACANCY_POSITION_LIST,
} from '../constants/actionTypes'

const vacancyPositionsSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const vacancyPositionSelector = createSelector(vacancyPositionsSelector, data => data[VACANCY_POSITION])

export const vacancyPositionActivateSelector = createSelector(
  vacancyPositionsSelector,
  data => data[VACANCY_POSITION_ACTIVATE]
)

export const vacancyPositionAllSelector = createSelector(vacancyPositionsSelector, data => data[VACANCY_POSITION_ALL])

export const vacancyPositionCreateSelector = createSelector(
  vacancyPositionsSelector,
  data => data[VACANCY_POSITION_CREATE]
)

export const vacancyPositionDeleteSelector = createSelector(
  vacancyPositionsSelector,
  data => data[VACANCY_POSITION_DELETE]
)

export const vacancyPositionListSelector = createSelector(vacancyPositionsSelector, data => data[VACANCY_POSITION_LIST])
