import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'
import { DistributiveOmit } from 'react-redux'

import * as api from '../api'
import { TYPES, TYPES_CHILD } from '../constants/actionTypes'
import * as API from '../constants/api'

export const types = createAsyncThunk(
  TYPES,
  async (params: PayloadCreatorParams<`${typeof API.TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const rest = omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params) as DistributiveOmit<
        PayloadCreatorParams<'/types/{variable}', 'get'>,
        'onFulfilled' | 'onRejected' | 'onSettled' | 'config'
      >

      const response = await api.types(rest, params?.config)
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const typesChild = createAsyncThunk(
  TYPES_CHILD,
  async (params: PayloadCreatorParams<`${typeof API.TYPES_CHILD}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.typesChild(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const typesClear = createAction(`${TYPES}/clear`)

export const typesChildClear = createAction(`${TYPES_CHILD}/clear`)
