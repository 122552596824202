import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  FORM,
  FORM_ACTION_TYPE,
  FORM_ALL,
  FORM_FIELD_BATCH,
  FORM_LIST,
  FORM_REQUEST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CERTIFICATE,
  FORM_REQUEST_CHANGE_STATUS,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_LIST,
  FORM_TYPE,
  FORM_TYPE_ALL,
  FORM_TYPE_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const formSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const formListSelector = createSelector(formSelectors, prop(FORM_LIST))

export const formSelector = createSelector(formSelectors, prop(FORM))

export const formAllSelector = createSelector(formSelectors, prop(FORM_ALL))

export const formBatchSelector = createSelector(formSelectors, prop(FORM_FIELD_BATCH))

export const formActionTypeSelector = createSelector(formSelectors, prop(FORM_ACTION_TYPE))

export const formTypeSelector = createSelector(formSelectors, prop(FORM_TYPE))

export const formTypeListSelector = createSelector(formSelectors, prop(FORM_TYPE_LIST))

export const formTypeAllSelector = createSelector(formSelectors, prop(FORM_TYPE_ALL))

export const formRequestSelector = createSelector(formSelectors, prop(FORM_REQUEST))

export const formRequestChangeStatusSelector = createSelector(formSelectors, prop(FORM_REQUEST_CHANGE_STATUS))

export const formRequestCreateSelector = createSelector(formSelectors, prop(FORM_REQUEST_CREATE))

export const formRequestListSelector = createSelector(formSelectors, prop(FORM_REQUEST_LIST))

export const formRequestAllSelector = createSelector(formSelectors, prop(FORM_REQUEST_ALL))

export const formRequestFieldsSelector = createSelector(formSelectors, prop(FORM_REQUEST_FIELDS))

export const formRequestCertificateSelector = createSelector(formSelectors, prop(FORM_REQUEST_CERTIFICATE))
