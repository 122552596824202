import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '@helpers/createState'

export const MAIL = STATE.GLOBAL_STATE

const reducer = combineReducers({
  [STATE.SEND_MAIL]: createState(actionTypes.SEND_MAIL),
  [STATE.EMAIL_TEMPLATE_LIST]: createState(actionTypes.EMAIL_TEMPLATE_LIST),
  [STATE.EMAIL_TEMPLATE_CREATE]: createState(actionTypes.EMAIL_TEMPLATE_CREATE),
  [STATE.EMAIL_TEMPLATE_ALL]: createState(actionTypes.EMAIL_TEMPLATE_ALL),
  [STATE.EMAIL_TEMPLATE]: createState(actionTypes.EMAIL_TEMPLATE),
})


export default reducer