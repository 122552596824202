import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ATTENDANCE_REASON,
  ATTENDANCE_REASON_ALL,
  ATTENDANCE_REASON_CREATE,
  ATTENDANCE_REASON_DELETE,
  ATTENDANCE_REASON_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const attendanceReason = createAsyncThunk(
  ATTENDANCE_REASON,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_REASON}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceReason(omit(['onFulfilled', 'onRejected', 'onSettled'], params?.config)) //if get request
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceReasonAll = createAsyncThunk(
  ATTENDANCE_REASON_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.ATTENDANCE_REASON_ALL}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.attendanceReasonAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params?.config)) //if get request
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceReasonCreate = createAsyncThunk(
  ATTENDANCE_REASON_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_REASON}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceReasonCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params)) //if get request
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceReasonDelete = createAsyncThunk(
  ATTENDANCE_REASON_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_REASON}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceReasonDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled'], params?.config)
      ) //if get request
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceReasonList = createAsyncThunk(
  ATTENDANCE_REASON_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_REASON}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.attendanceReasonList(
        omit(['onFulfilled', 'onRejected', 'onSettled'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceReasonClear = createAction(`${ATTENDANCE_REASON}/clear`)

export const attendanceReasonAllClear = createAction(`${ATTENDANCE_REASON_ALL}/clear`)

export const attendanceReasonListClear = createAction(`${ATTENDANCE_REASON_LIST}/clear`)
