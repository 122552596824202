import { ApiFunc, getInstance } from '@src/helpers/httpClient'

import * as API from '../constants/api'

const authorized = getInstance()

export const internalExam: ApiFunc<`${typeof API.INTERNAL_EXAM}{id}`, 'get'> = data =>
  authorized.get(`${API.INTERNAL_EXAM}${'id' in data ? data.id : data.name}`)

export const internalExamList: ApiFunc<`${typeof API.INTERNAL_EXAM}`, 'get'> = data =>
  authorized.get(API.INTERNAL_EXAM, { params: data })

export const internalExamAll: ApiFunc<`${typeof API.INTERNAL_EXAM_ALL}`, 'get'> = data =>
  authorized.get(API.INTERNAL_EXAM_ALL, { params: data })

export const internalExamCreate: ApiFunc<`${typeof API.INTERNAL_EXAM}`, 'post'> = data =>
  authorized.post(API.INTERNAL_EXAM, data)

export const internalExamRedirect: ApiFunc<`${typeof API.INTERNAL_EXAM_REDIRECT}`, 'get'> = data =>
  authorized.get(API.INTERNAL_EXAM_REDIRECT, { params: data })

// export const internalExamPlace: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'get'> = data =>
//   authorized.get(`${API.INTERNAL_EXAM_PLACE}${'id' in data ? data.id : data.name}`)

// export const internalExamPlaceList: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}`, 'get'> = data =>
//   authorized.get(API.INTERNAL_EXAM_PLACE, { params: data })

// export const internalExamPlaceAll: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE_ALL}`, 'get'> = data =>
//   authorized.get(API.INTERNAL_EXAM_PLACE_ALL, { params: data })

// export const internalExamPlaceCreate: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}`, 'post'> = data =>
//   authorized.post(API.INTERNAL_EXAM_PLACE, data)
