import { DefaultState } from '@src/redux/types'

import { PAYMENTS, PAYMENTS_CREATE, PAYMENTS_DELETE } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [PAYMENTS]: DefaultState<`${typeof API.PAYMENTS}`, 'get'>
  [PAYMENTS_CREATE]: DefaultState<`${typeof API.PAYMENTS}`, 'post'>
  [PAYMENTS_DELETE]: DefaultState<`${typeof API.PAYMENTS}{id}`, 'delete'>
} = () => ({
  [PAYMENTS]: defaultState(),
  [PAYMENTS_CREATE]: defaultState(),
  [PAYMENTS_DELETE]: defaultState(),
})
