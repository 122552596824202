import { lazy } from 'react'
import { SUPER_ADMIN } from '@constants/userRoles'

export const PageEmbedRoutes = [
  {
    path: '/page-embed',
    component: lazy(() => import('../index')),
    meta: {
      access: [SUPER_ADMIN],
    },
  },
]
