import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  E_LIBRARY,
  E_LIBRARY_ALL,
  E_LIBRARY_CREATE,
  E_LIBRARY_DELETE,
  E_LIBRARY_LIST,
  E_LIBRARY_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const LIBRARY = GLOBAL_STATE

export const { reducer: eLibraryReducer } = createSlice({
  name: LIBRARY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.eLibrary.pending, state => {
        state[E_LIBRARY].loading = true
      })
      .addCase(actionCreators.eLibrary.rejected, (state, action) => {
        state[E_LIBRARY].loading = false
        state[E_LIBRARY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.eLibrary.fulfilled, (state, action) => {
        state[E_LIBRARY].loading = false
        state[E_LIBRARY].error = null
        state[E_LIBRARY].data = action.payload
      })

    builder
      .addCase(actionCreators.eLibraryAll.pending, state => {
        state[E_LIBRARY_ALL].loading = true
      })
      .addCase(actionCreators.eLibraryAll.rejected, (state, action) => {
        state[E_LIBRARY_ALL].loading = false
        state[E_LIBRARY_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.eLibraryAll.fulfilled, (state, action) => {
        state[E_LIBRARY_ALL].loading = false
        state[E_LIBRARY_ALL].error = null
        state[E_LIBRARY_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.eLibraryCreate.pending, state => {
        state[E_LIBRARY_CREATE].loading = true
      })
      .addCase(actionCreators.eLibraryCreate.rejected, (state, action) => {
        state[E_LIBRARY_CREATE].loading = false
        state[E_LIBRARY_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.eLibraryCreate.fulfilled, (state, action) => {
        state[E_LIBRARY_CREATE].loading = false
        state[E_LIBRARY_CREATE].error = null
        state[E_LIBRARY_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.eLibraryDelete.pending, state => {
        state[E_LIBRARY_DELETE].loading = true
      })
      .addCase(actionCreators.eLibraryDelete.rejected, (state, action) => {
        state[E_LIBRARY_DELETE].loading = false
        state[E_LIBRARY_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.eLibraryDelete.fulfilled, (state, action) => {
        state[E_LIBRARY_DELETE].loading = false
        state[E_LIBRARY_DELETE].error = null
        state[E_LIBRARY_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.eLibraryList.pending, state => {
        state[E_LIBRARY_LIST].loading = true
      })
      .addCase(actionCreators.eLibraryList.rejected, (state, action) => {
        state[E_LIBRARY_LIST].loading = false
        state[E_LIBRARY_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.eLibraryList.fulfilled, (state, action) => {
        state[E_LIBRARY_LIST].loading = false
        state[E_LIBRARY_LIST].error = null
        state[E_LIBRARY_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.eLibraryTypes.pending, state => {
        state[E_LIBRARY_TYPES].loading = true
      })
      .addCase(actionCreators.eLibraryTypes.rejected, (state, action) => {
        state[E_LIBRARY_TYPES].loading = false
        state[E_LIBRARY_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.eLibraryTypes.fulfilled, (state, action) => {
        state[E_LIBRARY_TYPES].loading = false
        state[E_LIBRARY_TYPES].error = null
        state[E_LIBRARY_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.eLibraryClear, state => {
      state[E_LIBRARY] = defaultState()
    })

    builder.addCase(actionCreators.eLibraryAllClear, state => {
      state[E_LIBRARY_ALL] = defaultState()
    })

    builder.addCase(actionCreators.eLibraryListClear, state => {
      state[E_LIBRARY_LIST] = defaultState()
    })

    builder.addCase(actionCreators.eLibraryTypesClear, state => {
      state[E_LIBRARY_TYPES] = defaultState()
    })
  },
})
