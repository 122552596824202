import { DefaultState } from '@src/redux/types'

import {
  HR_APPLICATION,
  HR_APPLICATION_ALL,
  HR_APPLICATION_CREATE,
  HR_APPLICATION_DELETE,
  HR_APPLICATION_FIELD_BATCH,
  HR_APPLICATION_FIELD_BATCH_CREATE,
  HR_APPLICATION_LIST,
  HR_APPLICATION_REQUEST_ALL,
  HR_APPLICATION_REQUEST_CREATE,
  HR_APPLICATION_REQUEST_EDIT,
  HR_APPLICATION_REQUEST_UPDATE,
  HR_APPLICATION_STATUS,
  HR_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [HR_APPLICATION]: DefaultState<`${typeof API.HR_APPLICATION_FORM}{id}`, 'get'>
  [HR_APPLICATION_ALL]: DefaultState<`${typeof API.HR_APPLICATION_ALL}`, 'get'>
  [HR_APPLICATION_CREATE]: DefaultState<`${typeof API.HR_APPLICATION_FORM}`, 'post'>
  [HR_APPLICATION_DELETE]: DefaultState<`${typeof API.HR_APPLICATION_FORM}{id}`, 'delete'>
  [HR_APPLICATION_FIELD_BATCH]: DefaultState<`${typeof API.HR_APPLICATION_FIELD_BATCH}{id}`, 'get'>
  [HR_APPLICATION_FIELD_BATCH_CREATE]: DefaultState<`${typeof API.HR_APPLICATION_FIELD_BATCH}`, 'post'>
  [HR_APPLICATION_LIST]: DefaultState<`${typeof API.HR_APPLICATION}`, 'get'>
  [HR_APPLICATION_REQUEST_ALL]: DefaultState<`${typeof API.HR_APPLICATION_REQUEST_ALL}`, 'get'>
  [HR_APPLICATION_REQUEST_CREATE]: DefaultState<`${typeof API.HR_APPLICATION_REQUEST}`, 'post'>
  [HR_APPLICATION_REQUEST_EDIT]: DefaultState<`${typeof API.HR_APPLICATION_REQUEST_EDIT}`, 'get'>
  [HR_APPLICATION_REQUEST_UPDATE]: DefaultState<`${typeof API.HR_APPLICATION_REQUEST_UPDATE}`, 'put'>
  [HR_APPLICATION_STATUS]: DefaultState<`${typeof API.HR_APPLICATION_STATUS}`, 'put'>
  [HR_APPLICATION_TYPES]: DefaultState<`${typeof API.HR_APPLICATION_TYPES}`, 'get'>
} = () => ({
  [HR_APPLICATION]: defaultState(),
  [HR_APPLICATION_ALL]: defaultState(),
  [HR_APPLICATION_CREATE]: defaultState(),
  [HR_APPLICATION_DELETE]: defaultState(),
  [HR_APPLICATION_FIELD_BATCH]: defaultState(),
  [HR_APPLICATION_FIELD_BATCH_CREATE]: defaultState(),
  [HR_APPLICATION_LIST]: defaultState(),
  [HR_APPLICATION_REQUEST_ALL]: defaultState(),
  [HR_APPLICATION_REQUEST_CREATE]: defaultState(),
  [HR_APPLICATION_REQUEST_EDIT]: defaultState(),
  [HR_APPLICATION_REQUEST_UPDATE]: defaultState(),
  [HR_APPLICATION_STATUS]: defaultState(),
  [HR_APPLICATION_TYPES]: defaultState(),
})
