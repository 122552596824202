import { nanoid } from 'nanoid'
import { propOr } from 'ramda'
import { useMemo } from 'react'
import { components } from 'react-select'
import { Badge, UncontrolledTooltip } from 'reactstrap'

function ValueContainer(props) {
  const selectedValues = props.getValue()
  const id = useMemo(() => props.selectProps.name || nanoid(), [props.selectProps.name])
  return (
    <div
      id={`value-container-${id}`}
      style={{
        alignItems: 'center',
        display: 'flex',
        flex: '1',
        flexWrap: 'wrap',
        position: 'relative',
        overflow: 'hidden',
        padding: '2px 8px',
        boxSizing: 'border-box',
        gap: '2px',
      }}
    >
      <components.ValueContainer {...props} />
      {props.isMulti && Array.isArray(selectedValues) && selectedValues.length > 3 ? (
        <UncontrolledTooltip target={`value-container-${id}`}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {Array.isArray(selectedValues)
              ? selectedValues.map(item => (
                  <Badge key={item.value} color='primary'>
                    {propOr('No label', 'label', item)}
                  </Badge>
                ))
              : null}
          </div>
        </UncontrolledTooltip>
      ) : null}
    </div>
  )
}

export default ValueContainer
