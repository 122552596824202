// ** Menu Components Imports
import HorizontalNavMenuLink from './HorizontalNavMenuLink'
import HorizontalNavMenuGroup from './HorizontalNavMenuGroup'
import {
  resolveHorizontalNavMenuItemComponent as resolveNavItemComponent,
  accessViewMenuGroup,
  accessViewMenuItem,
} from '@layouts/utils'

import { getUserInfo } from '@helpers/storageUserInfo'

const HorizontalNavMenuItems = props => {
  // ** Components Object
  const Components = {
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink,
  }

  // ** Render Nav Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]

    if (item.children) {
      return accessViewMenuGroup(item, getUserInfo()) && <TagName key={item.id || item.header} item={item} {...props} />
    }

    return accessViewMenuItem(item, getUserInfo()) && <TagName item={item} index={index} key={item.id} {...props} />
  })

  return RenderNavItems
}

export default HorizontalNavMenuItems
