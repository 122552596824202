import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const modulesOutcomeCreate: ApiFunc<`${typeof API.MODULES_OUTCOME_LIST}`, 'post'> = data =>
  instance.post(API.MODULES_OUTCOME_LIST, data)

export const modulesOutcomeList: ApiFunc<`${typeof API.MODULES_OUTCOME_LIST}`, 'get'> = data =>
  instance.get(API.MODULES_OUTCOME_LIST, {
    params: data,
  })

export const modulesOutcome: ApiFunc<`${typeof API.MODULES_OUTCOME}`, 'get'> = data =>
  instance.get(API.MODULES_OUTCOME, {
    params: data,
  })
