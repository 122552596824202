import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { CHECK_USER_OPEN_LINK, GLOBAL_STATE, USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/actionTypes'

const openLinkSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const generatedLinkSelector = createSelector(openLinkSelectors, prop(USER_OPEN_LINK))

export const checkOpenLinkSelector = createSelector(openLinkSelectors, prop(CHECK_USER_OPEN_LINK))

export const userPublicInfoSelector = createSelector(openLinkSelectors, prop(USER_PUBLIC_INFO))
