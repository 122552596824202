import * as ROUTE from '@constants/routes'
import { SuccessToast } from '@src/components/Toasts'
import { useAppDispatch } from '@src/hooks'
import { userProfileResetPassword } from '@store/actions'
import QueryString from 'qs'
import { prop } from 'ramda'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { UserProfileResetPasswordForm } from '../UserProfileResetPasswordForm'

function UserProfileResetPasswordModal({ isOpen, close }) {
  const dispatch = useAppDispatch()
  const { formatMessage: msg } = useIntl()

  const { search } = useLocation()
  const query = QueryString.parse(search)
  const token = prop('token', query)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
  })

  function onSubmit(data) {
    const new_password = prop('new_password', data)

    dispatch(
      userProfileResetPassword({
        token,
        new_password,
        onFulfilled: () => {
          close()
          toast.success(<SuccessToast text='success' />)
        },
      })
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} size='md' backdrop='static'>
        <ModalHeader toggle={close} tag={'h5'}>
          {msg({ id: 'Reset password' })}
        </ModalHeader>
        <ModalBody className='px-2 py-3'>
          <UserProfileResetPasswordForm
            handleSubmit={handleSubmit(onSubmit)}
            register={register}
            control={control}
            errors={errors}
            watch={watch}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default UserProfileResetPasswordModal
