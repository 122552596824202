import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_DELETE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_DELETE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_CREATE,
  STC_STEP_DELETE,
  STC_STEP_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const SHORT_TERM_COURSE = GLOBAL_STATE

export const { reducer: stcReducer } = createSlice({
  name: SHORT_TERM_COURSE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.stc.pending, state => {
        state[STC].loading = true
      })
      .addCase(actionCreators.stc.rejected, (state, action) => {
        state[STC].loading = false
        state[STC].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stc.fulfilled, (state, action) => {
        state[STC].loading = false
        state[STC].error = null
        state[STC].data = action.payload
      })

    builder
      .addCase(actionCreators.stcAll.pending, state => {
        state[STC_ALL].loading = true
      })
      .addCase(actionCreators.stcAll.rejected, (state, action) => {
        state[STC_ALL].loading = false
        state[STC_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcAll.fulfilled, (state, action) => {
        state[STC_ALL].loading = false
        state[STC_ALL].error = null
        state[STC_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.stcCreate.pending, state => {
        state[STC_CREATE].loading = true
      })
      .addCase(actionCreators.stcCreate.rejected, (state, action) => {
        state[STC_CREATE].loading = false
        state[STC_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcCreate.fulfilled, (state, action) => {
        state[STC_CREATE].loading = false
        state[STC_CREATE].error = null
        state[STC_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stcDelete.pending, state => {
        state[STC_DELETE].loading = true
      })
      .addCase(actionCreators.stcDelete.rejected, (state, action) => {
        state[STC_DELETE].loading = false
        state[STC_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcDelete.fulfilled, (state, action) => {
        state[STC_DELETE].loading = false
        state[STC_DELETE].error = null
        state[STC_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.stcList.pending, state => {
        state[STC_LIST].loading = true
      })
      .addCase(actionCreators.stcList.rejected, (state, action) => {
        state[STC_LIST].loading = false
        state[STC_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcList.fulfilled, (state, action) => {
        state[STC_LIST].loading = false
        state[STC_LIST].error = null
        state[STC_LIST].data = action.payload
      })

    //category
    builder
      .addCase(actionCreators.stcCategory.pending, state => {
        state[STC_CATEGORY].loading = true
      })
      .addCase(actionCreators.stcCategory.rejected, (state, action) => {
        state[STC_CATEGORY].loading = false
        state[STC_CATEGORY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcCategory.fulfilled, (state, action) => {
        state[STC_CATEGORY].loading = false
        state[STC_CATEGORY].error = null
        state[STC_CATEGORY].data = action.payload
      })

    builder
      .addCase(actionCreators.stcCategoryAll.pending, state => {
        state[STC_CATEGORY_ALL].loading = true
      })
      .addCase(actionCreators.stcCategoryAll.rejected, (state, action) => {
        state[STC_CATEGORY_ALL].loading = false
        state[STC_CATEGORY_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcCategoryAll.fulfilled, (state, action) => {
        state[STC_CATEGORY_ALL].loading = false
        state[STC_CATEGORY_ALL].error = null
        state[STC_CATEGORY_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.stcCategoryCreate.pending, state => {
        state[STC_CATEGORY_CREATE].loading = true
      })
      .addCase(actionCreators.stcCategoryCreate.rejected, (state, action) => {
        state[STC_CATEGORY_CREATE].loading = false
        state[STC_CATEGORY_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcCategoryCreate.fulfilled, (state, action) => {
        state[STC_CATEGORY_CREATE].loading = false
        state[STC_CATEGORY_CREATE].error = null
        state[STC_CATEGORY_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stcCategoryDelete.pending, state => {
        state[STC_CATEGORY_DELETE].loading = true
      })
      .addCase(actionCreators.stcCategoryDelete.rejected, (state, action) => {
        state[STC_CATEGORY_DELETE].loading = false
        state[STC_CATEGORY_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcCategoryDelete.fulfilled, (state, action) => {
        state[STC_CATEGORY_DELETE].loading = false
        state[STC_CATEGORY_DELETE].error = null
        state[STC_CATEGORY_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.stcCategoryList.pending, state => {
        state[STC_CATEGORY_LIST].loading = true
      })
      .addCase(actionCreators.stcCategoryList.rejected, (state, action) => {
        state[STC_CATEGORY_LIST].loading = false
        state[STC_CATEGORY_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcCategoryList.fulfilled, (state, action) => {
        state[STC_CATEGORY_LIST].loading = false
        state[STC_CATEGORY_LIST].error = null
        state[STC_CATEGORY_LIST].data = action.payload
      })

    //step
    builder
      .addCase(actionCreators.stcStep.pending, state => {
        state[STC_STEP].loading = true
      })
      .addCase(actionCreators.stcStep.rejected, (state, action) => {
        state[STC_STEP].loading = false
        state[STC_STEP].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcStep.fulfilled, (state, action) => {
        state[STC_STEP].loading = false
        state[STC_STEP].error = null
        state[STC_STEP].data = action.payload
      })

    builder
      .addCase(actionCreators.stcStepAll.pending, state => {
        state[STC_STEP_ALL].loading = true
      })
      .addCase(actionCreators.stcStepAll.rejected, (state, action) => {
        state[STC_STEP_ALL].loading = false
        state[STC_STEP_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcStepAll.fulfilled, (state, action) => {
        state[STC_STEP_ALL].loading = false
        state[STC_STEP_ALL].error = null
        state[STC_STEP_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.stcStepCreate.pending, state => {
        state[STC_STEP_CREATE].loading = true
      })
      .addCase(actionCreators.stcStepCreate.rejected, (state, action) => {
        state[STC_STEP_CREATE].loading = false
        state[STC_STEP_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcStepCreate.fulfilled, (state, action) => {
        state[STC_STEP_CREATE].loading = false
        state[STC_STEP_CREATE].error = null
        state[STC_STEP_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stcStepDelete.pending, state => {
        state[STC_STEP_DELETE].loading = true
      })
      .addCase(actionCreators.stcStepDelete.rejected, (state, action) => {
        state[STC_STEP_DELETE].loading = false
        state[STC_STEP_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcStepDelete.fulfilled, (state, action) => {
        state[STC_STEP_DELETE].loading = false
        state[STC_STEP_DELETE].error = null
        state[STC_STEP_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.stcStepList.pending, state => {
        state[STC_STEP_LIST].loading = true
      })
      .addCase(actionCreators.stcStepList.rejected, (state, action) => {
        state[STC_STEP_LIST].loading = false
        state[STC_STEP_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stcStepList.fulfilled, (state, action) => {
        state[STC_STEP_LIST].loading = false
        state[STC_STEP_LIST].error = null
        state[STC_STEP_LIST].data = action.payload
      })

    builder.addCase(actionCreators.stcClear, state => {
      state[STC] = defaultState()
    })

    builder.addCase(actionCreators.stcAllClear, state => {
      state[STC_ALL] = defaultState()
    })

    builder.addCase(actionCreators.stcCategoryClear, state => {
      state[STC_CATEGORY] = defaultState()
    })

    builder.addCase(actionCreators.stcCategoryListClear, state => {
      state[STC_CATEGORY_LIST] = defaultState()
    })

    builder.addCase(actionCreators.stcListClear, state => {
      state[STC_LIST] = defaultState()
    })

    builder.addCase(actionCreators.stcStepClear, state => {
      state[STC_STEP] = defaultState()
    })
  },
})
