import { isNil } from 'ramda/es'

const numbInputFieldValidator = (value, min = 0, max) => {
  if (isNil(value) || !value) return { message: 'This field is required', type: 'required' }
  if (!isNaN(min) && !isNaN(max)) {
    return !(value >= min && value <= max) ? { message: `Value must be more than ${min} and less than ${max}`, type: 'range' } : null
  }
  if (!isNaN(min)) {
    return value < min ? { message: `Value must be more than ${min}`, type: 'min' } : null
  }
  if (!isNaN(max)) {
    return value > max ? { message: `Value must not be more than ${max}`, type: 'max' } : null
  }
}

export default numbInputFieldValidator
