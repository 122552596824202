import { ApiFunc, getInstance } from '@src/helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const studentDocs: ApiFunc<`${typeof API.STUDENT_DOCUMENTS}`, 'get'> = data =>
  instance.get(API.STUDENT_DOCUMENTS, {
    params: data,
  })
