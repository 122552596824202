import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import * as TYPES from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const STATS = TYPES.GLOBAL_STATE

export const { reducer: statsReducer } = createSlice({
  name: STATS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.stats.pending, state => {
        state[TYPES.STATS].loading = true
      })
      .addCase(actionCreators.stats.rejected, (state, action) => {
        state[TYPES.STATS].loading = false
        state[TYPES.STATS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stats.fulfilled, (state, action) => {
        state[TYPES.STATS].loading = false
        state[TYPES.STATS].error = null
        state[TYPES.STATS].data = action.payload
      })

    builder.addCase(actionCreators.statsClear, state => {
      state[TYPES.STATS] = defaultState()
    })
  },
})
