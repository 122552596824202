import { DefaultState } from '@src/redux/types'

import {
  ACCOUNT,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_LIST,
  ACCOUNT_PASSWORD_RESET,
  ACCOUNT_PROFILE_PIC,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_REQUIRED_ACTIONS,
  ACCOUNT_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ACCOUNT]: DefaultState<`${typeof API.ACCOUNT}{id}`, 'get'>
  [ACCOUNT_CREATE]: DefaultState<`${typeof API.ACCOUNT}`, 'post'>
  [ACCOUNT_DELETE]: DefaultState<`${typeof API.ACCOUNT}{id}`, 'delete'>
  [ACCOUNT_LIST]: DefaultState<`${typeof API.ACCOUNT}`, 'get'>
  [ACCOUNT_PASSWORD_RESET]: DefaultState<`${typeof API.ACCOUNT_PASSWORD_RESET}`, 'put'>
  [ACCOUNT_PROFILE_PIC]: DefaultState<`${typeof API.ACCOUNT_PROFILE_PIC}`, 'put'>
  [ACCOUNT_PROFILE_UPDATE]: DefaultState<`${typeof API.ACCOUNT_PROFILE_UPDATE}`, 'put'>
  [ACCOUNT_REQUIRED_ACTIONS]: DefaultState<`${typeof API.ACCOUNT_REQUIRED_ACTION}`, 'put'>
  [ACCOUNT_TYPES]: DefaultState<`${typeof API.ACCOUNT_TYPES}`, 'get'>
} = () => ({
  [ACCOUNT]: defaultState(),
  [ACCOUNT_CREATE]: defaultState(),
  [ACCOUNT_DELETE]: defaultState(),
  [ACCOUNT_LIST]: defaultState(),
  [ACCOUNT_PASSWORD_RESET]: defaultState(),
  [ACCOUNT_PROFILE_PIC]: defaultState(),
  [ACCOUNT_PROFILE_UPDATE]: defaultState(),
  [ACCOUNT_REQUIRED_ACTIONS]: defaultState(),
  [ACCOUNT_TYPES]: defaultState(),
})
