export const APPLICATION_FORM = '/application_form/' as const
export const APPLICATION = '/application/' as const
export const APPLICATION_TYPES = `${APPLICATION}types` as const
export const APPLICATION_QR_SCAN = `${APPLICATION}qr_scan_check` as const
export const APPLICATION_DTM_UPLOAD = `${APPLICATION}dtm_file` as const
export const APPLICATION_STATUS = `${APPLICATION}status/` as const
export const APPLICATION_ALL = `${APPLICATION_FORM}all` as const
export const APPLICATION_FIELD_BATCH = `${APPLICATION_FORM}field/batch/` as const

// export const APPLICATION_CATEGORY = `${APPLICATION_FORM}category` as const
// export const APPLICATION_CATEGORY_ALL = `${APPLICATION_CATEGORY}all` as const

export const APPLICATION_REQUEST = APPLICATION
export const APPLICATION_REQUEST_ALL = `${APPLICATION_REQUEST}all` as const
export const APPLICATION_REQUEST_EDIT = `${APPLICATION_REQUEST}edit` as const
export const APPLICATION_REQUEST_UPDATE = `${APPLICATION_REQUEST}update` as const

// export const APPLICATION_SIGNUP = `${APPLICATION_FORM}signup` as const
// export const APPLICATION_SMS_SEND = `${APPLICATION_FORM}sms/send` as const
