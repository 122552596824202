import { DefaultState } from '@src/redux/types'

import {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_DELETE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
  ENROLLMENT_STUDENT_OWN,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ENROLLMENT]: DefaultState<`${typeof API.ENROLLMENT}{id}`, 'get'>
  [ENROLLMENT_ALL]: DefaultState<`${typeof API.ENROLLMENT_ALL}`, 'get'>
  [ENROLLMENT_CREATE]: DefaultState<`${typeof API.ENROLLMENT}`, 'post'>
  [ENROLLMENT_DELETE]: DefaultState<`${typeof API.ENROLLMENT}{id}`, 'delete'>
  [ENROLLMENT_LIST]: DefaultState<`${typeof API.ENROLLMENT}`, 'get'>
  [ENROLLMENT_COURSES]: DefaultState<`${typeof API.ENROLLMENT_COURSES}`, 'get'>
  [ENROLLMENT_STATUS]: DefaultState<`${typeof API.ENROLLMENT_STATUS}`, 'put'>
  [ENROLLMENT_STUDENT_OWN]: DefaultState<`${typeof API.ENROLLMENT_STUDENT_OWN}`, 'get'>
} = () => ({
  [ENROLLMENT]: defaultState(),
  [ENROLLMENT_ALL]: defaultState(),
  [ENROLLMENT_CREATE]: defaultState(),
  [ENROLLMENT_DELETE]: defaultState(),
  [ENROLLMENT_LIST]: defaultState(),
  [ENROLLMENT_COURSES]: defaultState(),
  [ENROLLMENT_STATUS]: defaultState(),
  [ENROLLMENT_STUDENT_OWN]: defaultState(),
})
