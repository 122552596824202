import { DefaultState } from '@src/redux/types'

import {
  GUEST,
  GUEST_ACCEPT_SEND_CONTRACT,
  GUEST_ALL,
  GUEST_APPOINTMENT_SCORE,
  GUEST_AVAILABILITY_DOCUMENTS,
  GUEST_AVAILABILITY_DOCUMENTS_UPDATE,
  GUEST_CHANGE_EDU_LANG,
  GUEST_CHANGE_STATUS,
  GUEST_CHANGE_STATUS_TRANSCRIPT,
  GUEST_CREATE_INVOICE,
  GUEST_CREATE_REFERENCE,
  GUEST_DELETE,
  GUEST_LIST,
  GUEST_PROFILE,
  GUEST_PROFILE_UPDATE,
  GUEST_RESET_COURSE,
  GUEST_RESET_EXAM,
  GUEST_TEACHING_EXPERIENCE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [GUEST]: DefaultState<`${typeof API.USER_GUEST}{id}`, 'get'>
  [GUEST_ALL]: DefaultState<`${typeof API.USER_GUEST_ALL}`, 'get'>
  [GUEST_LIST]: DefaultState<`${typeof API.USER_GUEST}`, 'get'>
  [GUEST_PROFILE]: DefaultState<`${typeof API.USER_GUEST_PROFILE}`, 'get'>
  [GUEST_AVAILABILITY_DOCUMENTS]: DefaultState<`${typeof API.USER_GUEST_AVAILABILITY_DOCUMENTS}`, 'get'>
  [GUEST_PROFILE_UPDATE]: DefaultState<`${typeof API.USER_GUEST_PROFILE}`, 'put'>
  [GUEST_AVAILABILITY_DOCUMENTS_UPDATE]: DefaultState<`${typeof API.USER_GUEST_AVAILABILITY_DOCUMENTS}`, 'put'>
  [GUEST_RESET_EXAM]: DefaultState<`${typeof API.USER_GUEST_RESET_EXAM}`, 'put'>
  [GUEST_TEACHING_EXPERIENCE]: DefaultState<`${typeof API.USER_GUEST_TEACHING_EXPERIENCE}`, 'put'>
  [GUEST_RESET_COURSE]: DefaultState<`${typeof API.USER_GUEST_RESET_COURSE}`, 'put'>
  [GUEST_CHANGE_STATUS]: DefaultState<`${typeof API.USER_GUEST_CHANGE_STATUS}`, 'put'>
  [GUEST_CHANGE_STATUS_TRANSCRIPT]: DefaultState<`${typeof API.USER_GUEST_CHANGE_STATUS_TRANSCRIPT}`, 'put'>
  [GUEST_APPOINTMENT_SCORE]: DefaultState<`${typeof API.USER_GUEST_APPOINTMENT_SCORE}`, 'put'>
  [GUEST_ACCEPT_SEND_CONTRACT]: DefaultState<`${typeof API.USER_GUEST_ACCEPT_SEND_CONTRACT}`, 'put'>
  [GUEST_CREATE_REFERENCE]: DefaultState<`${typeof API.USER_GUEST_REFERENCE}`, 'put'>
  [GUEST_CREATE_INVOICE]: DefaultState<`${typeof API.USER_GUEST_INVOICE}`, 'put'>
  [GUEST_CHANGE_EDU_LANG]: DefaultState<`${typeof API.USER_GUEST_CHANGE_EDU_LANG}`, 'post'>
  [GUEST_DELETE]: DefaultState<`${typeof API.USER_GUEST}{ids}`, 'delete'>
} = () => ({
  [GUEST]: defaultState(),
  [GUEST_ALL]: defaultState(),
  [GUEST_LIST]: defaultState(),
  [GUEST_PROFILE]: defaultState(),
  [GUEST_AVAILABILITY_DOCUMENTS]: defaultState(),
  [GUEST_PROFILE_UPDATE]: defaultState(),
  [GUEST_AVAILABILITY_DOCUMENTS_UPDATE]: defaultState(),
  [GUEST_RESET_EXAM]: defaultState(),
  [GUEST_TEACHING_EXPERIENCE]: defaultState(),
  [GUEST_RESET_COURSE]: defaultState(),
  [GUEST_CHANGE_STATUS]: defaultState(),
  [GUEST_CHANGE_STATUS_TRANSCRIPT]: defaultState(),
  [GUEST_APPOINTMENT_SCORE]: defaultState(),
  [GUEST_ACCEPT_SEND_CONTRACT]: defaultState(),
  [GUEST_CHANGE_EDU_LANG]: defaultState(),
  [GUEST_CREATE_REFERENCE]: defaultState(),
  [GUEST_CREATE_INVOICE]: defaultState(),
  [GUEST_DELETE]: defaultState(),
})
