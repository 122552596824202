import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GENERAL_APPLICATION,
  GENERAL_APPLICATION_ALL,
  GENERAL_APPLICATION_CREATE,
  GENERAL_APPLICATION_DELETE,
  GENERAL_APPLICATION_FIELD_BATCH,
  GENERAL_APPLICATION_FIELD_BATCH_CREATE,
  GENERAL_APPLICATION_LIST,
  GENERAL_APPLICATION_REQUEST,
  GENERAL_APPLICATION_REQUEST_EDIT,
  GENERAL_APPLICATION_STATUS,
  GENERAL_APPLICATION_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const GENERAL_ADMISSION = GLOBAL_STATE

export const { reducer: generalAdmissionReducer } = createSlice({
  name: GENERAL_ADMISSION,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.generalApplication.pending, state => {
        state[GENERAL_APPLICATION].loading = true
      })
      .addCase(actionCreators.generalApplication.rejected, (state, action) => {
        state[GENERAL_APPLICATION].loading = false
        state[GENERAL_APPLICATION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplication.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION].loading = false
        state[GENERAL_APPLICATION].error = null
        state[GENERAL_APPLICATION].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationAll.pending, state => {
        state[GENERAL_APPLICATION_ALL].loading = true
      })
      .addCase(actionCreators.generalApplicationAll.rejected, (state, action) => {
        state[GENERAL_APPLICATION_ALL].loading = false
        state[GENERAL_APPLICATION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationAll.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_ALL].loading = false
        state[GENERAL_APPLICATION_ALL].error = null
        state[GENERAL_APPLICATION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationCreate.pending, state => {
        state[GENERAL_APPLICATION_CREATE].loading = true
      })
      .addCase(actionCreators.generalApplicationCreate.rejected, (state, action) => {
        state[GENERAL_APPLICATION_CREATE].loading = false
        state[GENERAL_APPLICATION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationCreate.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_CREATE].loading = false
        state[GENERAL_APPLICATION_CREATE].error = null
        state[GENERAL_APPLICATION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationDelete.pending, state => {
        state[GENERAL_APPLICATION_DELETE].loading = true
      })
      .addCase(actionCreators.generalApplicationDelete.rejected, (state, action) => {
        state[GENERAL_APPLICATION_DELETE].loading = false
        state[GENERAL_APPLICATION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationDelete.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_DELETE].loading = false
        state[GENERAL_APPLICATION_DELETE].error = null
        state[GENERAL_APPLICATION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationFieldBatch.pending, state => {
        state[GENERAL_APPLICATION_FIELD_BATCH].loading = true
      })
      .addCase(actionCreators.generalApplicationFieldBatch.rejected, (state, action) => {
        state[GENERAL_APPLICATION_FIELD_BATCH].loading = false
        state[GENERAL_APPLICATION_FIELD_BATCH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationFieldBatch.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_FIELD_BATCH].loading = false
        state[GENERAL_APPLICATION_FIELD_BATCH].error = null
        state[GENERAL_APPLICATION_FIELD_BATCH].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationFieldBatchCreate.pending, state => {
        state[GENERAL_APPLICATION_FIELD_BATCH_CREATE].loading = true
      })
      .addCase(actionCreators.generalApplicationFieldBatchCreate.rejected, (state, action) => {
        state[GENERAL_APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[GENERAL_APPLICATION_FIELD_BATCH_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationFieldBatchCreate.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[GENERAL_APPLICATION_FIELD_BATCH_CREATE].error = null
        state[GENERAL_APPLICATION_FIELD_BATCH_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationList.pending, state => {
        state[GENERAL_APPLICATION_LIST].loading = true
      })
      .addCase(actionCreators.generalApplicationList.rejected, (state, action) => {
        state[GENERAL_APPLICATION_LIST].loading = false
        state[GENERAL_APPLICATION_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationList.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_LIST].loading = false
        state[GENERAL_APPLICATION_LIST].error = null
        state[GENERAL_APPLICATION_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationRequest.pending, state => {
        state[GENERAL_APPLICATION_REQUEST].loading = true
      })
      .addCase(actionCreators.generalApplicationRequest.rejected, (state, action) => {
        state[GENERAL_APPLICATION_REQUEST].loading = false
        state[GENERAL_APPLICATION_REQUEST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationRequest.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_REQUEST].loading = false
        state[GENERAL_APPLICATION_REQUEST].error = null
        state[GENERAL_APPLICATION_REQUEST].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationRequestEdit.pending, state => {
        state[GENERAL_APPLICATION_REQUEST_EDIT].loading = true
      })
      .addCase(actionCreators.generalApplicationRequestEdit.rejected, (state, action) => {
        state[GENERAL_APPLICATION_REQUEST_EDIT].loading = false
        state[GENERAL_APPLICATION_REQUEST_EDIT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationRequestEdit.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_REQUEST_EDIT].loading = false
        state[GENERAL_APPLICATION_REQUEST_EDIT].error = null
        state[GENERAL_APPLICATION_REQUEST_EDIT].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationChangeStatus.pending, state => {
        state[GENERAL_APPLICATION_STATUS].loading = true
      })
      .addCase(actionCreators.generalApplicationChangeStatus.rejected, (state, action) => {
        state[GENERAL_APPLICATION_STATUS].loading = false
        state[GENERAL_APPLICATION_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationChangeStatus.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_STATUS].loading = false
        state[GENERAL_APPLICATION_STATUS].error = null
        state[GENERAL_APPLICATION_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.generalApplicationTypes.pending, state => {
        state[GENERAL_APPLICATION_TYPES].loading = true
      })
      .addCase(actionCreators.generalApplicationTypes.rejected, (state, action) => {
        state[GENERAL_APPLICATION_TYPES].loading = false
        state[GENERAL_APPLICATION_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generalApplicationTypes.fulfilled, (state, action) => {
        state[GENERAL_APPLICATION_TYPES].loading = false
        state[GENERAL_APPLICATION_TYPES].error = null
        state[GENERAL_APPLICATION_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.generalApplicationClear, state => {
      state[GENERAL_APPLICATION] = defaultState()
    })

    builder.addCase(actionCreators.generalApplicationAllClear, state => {
      state[GENERAL_APPLICATION_ALL] = defaultState()
    })

    builder.addCase(actionCreators.generalApplicationFieldBatchClear, state => {
      state[GENERAL_APPLICATION_FIELD_BATCH] = defaultState()
    })

    builder.addCase(actionCreators.generalApplicationListClear, state => {
      state[GENERAL_APPLICATION_LIST] = defaultState()
    })

    builder.addCase(actionCreators.generalApplicationTypesClear, state => {
      state[GENERAL_APPLICATION_TYPES] = defaultState()
    })

    builder.addCase(actionCreators.generalApplicationRequestEditClear, state => {
      state[GENERAL_APPLICATION_REQUEST_EDIT] = defaultState()
    })
  },
})
