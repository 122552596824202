import { lazy } from 'react'
import { OPEN_SOURCE } from '../constants/route'

export const OpenSourceRoutes = [
  {
    path: OPEN_SOURCE,
    component: lazy(() => import('../views/OpenSourceLinks')),
    exact: true,
  },
]
