import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const contractAgreementAll: ApiFunc<`${typeof API.CONTRACT_AGREEMENT_ALL}`, 'get'> = data =>
  instance.get(API.CONTRACT_AGREEMENT_ALL, {
    params: data,
  })

export const contractAgreementOpen: ApiFunc<`${typeof API.CONTRACT_AGREEMENT_OPEN}`, 'get'> = data =>
  instance.get(API.CONTRACT_AGREEMENT_OPEN, {
    params: data,
  })

export const contractAgreementCurrent: ApiFunc<`${typeof API.CONTRACT_AGREEMENT_CURRENT}`, 'get'> = data =>
  instance.get(API.CONTRACT_AGREEMENT_CURRENT, {
    params: data,
  })

export const contractAgreementAccept: ApiFunc<`${typeof API.CONTRACT_AGREEMENT_ACCEPT}`, 'post'> = data =>
  instance.post(API.CONTRACT_AGREEMENT_ACCEPT, data)
