import { useIntl } from '@src/hooks/useIntl'
import { Target } from '@src/hooks/useSignal'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useSyncExternalStore } from 'use-sync-external-store/shim'

type Props = {
  signal: Target<{ state: boolean; text: string }>
}

export default function NotificationModal({ signal }: Props) {
  const { state, text } = useSyncExternalStore(signal.pureSubscribe, signal.getValue)
  const { formatMessage: t } = useIntl()

  function close() {
    signal(draft => {
      draft.state = false
      draft.text = ''
    })
  }

  return (
    <Modal isOpen={state}>
      <ModalHeader toggle={close}>
        <h3 style={{ color: 'var(--warning)' }}>{t({ id: 'warning' })}</h3>
      </ModalHeader>
      <ModalBody>
        <h5 dangerouslySetInnerHTML={{ __html: text }} />
      </ModalBody>
      <ModalFooter>
        <Col style={{ textAlign: 'center' }}>
          <Button color='primary' onClick={close}>
            Ok
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}
