export const GLOBAL_STATE = 'ShortCourseRegistration'

// export const SHORT_COURSE_REGISTRATION = 'SHORT_COURSE_REGISTRATION' as const
// export const SHORT_COURSE_REGISTRATIONS = 'SHORT_COURSE_REGISTRATIONS' as const
export const SHORT_COURSE_REGISTRATION_CREATE = 'SHORT_COURSE_REGISTRATION_CREATE' as const
// export const SHORT_COURSE_REGISTRATION_DELETE = 'SHORT_COURSE_REGISTRATION_DELETE' as const
// export const SHORT_COURSE_REGISTRATION_LIST = 'SHORT_COURSE_REGISTRATION_LIST' as const
// export const SHORT_COURSE_REGISTRATION_ALL = 'SHORT_COURSE_REGISTRATION_ALL' as const
// export const SHORT_COURSE_REGISTRATION_TYPES = 'SHORT_COURSE_REGISTRATION_TYPES' as const
// export const SHORT_COURSE_REGISTRATION_STATUS = 'SHORT_COURSE_REGISTRATION_STATUS' as const
// export const SHORT_COURSE_REGISTRATION_FIELD_BATCH = 'SHORT_COURSE_REGISTRATION_FIELD_BATCH' as const
// export const SHORT_COURSE_REGISTRATION_FIELD_BATCH_CREATE = 'SHORT_COURSE_REGISTRATION_FIELD_BATCH_CREATE' as const
// export const SHORT_COURSE_REGISTRATION_CATEGORY = 'SHORT_COURSE_REGISTRATION_CATEGORY' as const
// export const SHORT_COURSE_REGISTRATION_CATEGORY_CREATE = 'SHORT_COURSE_REGISTRATION_CATEGORY_CREATE' as const
// export const SHORT_COURSE_REGISTRATION_CATEGORY_DELETE = 'SHORT_COURSE_REGISTRATION_CATEGORY_DELETE' as const
// export const SHORT_COURSE_REGISTRATION_CATEGORY_LIST = 'SHORT_COURSE_REGISTRATION_CATEGORY_LIST' as const
// export const SHORT_COURSE_REGISTRATION_CATEGORY_ALL = 'SHORT_COURSE_REGISTRATION_CATEGORY_ALL' as const
// export const SHORT_COURSE_REGISTRATION_REQUEST = 'SHORT_COURSE_REGISTRATION_REQUEST' as const
// export const SHORT_COURSE_REGISTRATION_REQUEST_ALL = 'SHORT_COURSE_REGISTRATION_REQUEST_ALL' as const
// export const SHORT_COURSE_REGISTRATION_REQUEST_EDIT = 'SHORT_COURSE_REGISTRATION_REQUEST_EDIT' as const
// export const SHORT_COURSE_REGISTRATION_REQUEST_UPDATE = 'SHORT_COURSE_REGISTRATION_REQUEST_UPDATE' as const
