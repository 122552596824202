import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT_ATTEMPT_CREATE,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_BULK,
  ASSESSMENT_COMPONENT_CREATE,
  ASSESSMENT_COMPONENT_DELETE,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_LIST,
  ASSESSMENT_STUDENT,
  ASSESSMENT_STUDENT_CRITERIA_DETAILS,
  ASSESSMENT_STUDENT_UPDATED,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_SUBMIT,
  ATTEMPT_DELETE,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const assessmentSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const assessmentBulkSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_BULK))

export const assessmentListSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_LIST))

export const assessmentAttemptItemSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_ATTEMPT_ITEM))

export const assessmentAttemptCreateSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_ATTEMPT_CREATE))

export const assessmentCriteriaUpdateSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_CRITERIA_UPDATE))

export const assessmentCriteriaListSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_CRITERIA_LIST))

export const assessmentCopySelector = createSelector(assessmentSelectors, prop(ASSESSMENT_COPY))

export const assessmentSubmitSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_SUBMIT))

export const attemptDeleteSelector = createSelector(assessmentSelectors, prop(ATTEMPT_DELETE))

export const assessmentCriteriaTypesSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_CRITERIA_TYPES))

export const assessmentStudentUploadSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_STUDENT_UPLOAD))

export const assessmentStudentSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_STUDENT))

export const assessmentStudentUpdatedSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_STUDENT_UPDATED))

export const assessmentStudentCriteriaDetailsSelector = createSelector(
  assessmentSelectors,
  data => data[ASSESSMENT_STUDENT_CRITERIA_DETAILS]
)

export const assessmentCreateSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_CREATE))

export const assessmentComponentCreateSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_COMPONENT_CREATE))

export const assessmentComponentDeleteSelector = createSelector(assessmentSelectors, prop(ASSESSMENT_COMPONENT_DELETE))
