import { ApiFunc, getInstance } from '@helpers/httpClient'
import { API_URL } from '@src/constants/api'
import { getEduForm } from '@src/helpers/storageEduForm'
import { getInstitution } from '@src/helpers/storageInstitution'
import { getLanguage } from '@src/helpers/storageLanguage'
import { getToken } from '@src/helpers/storageToken'
import axios from 'axios'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const assessmentAmendment: ApiFunc<`${typeof API.ASSESSMENT_AMENDMENT}{id}`, 'get'> = data =>
  instance.get(`${API.ASSESSMENT_AMENDMENT}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const assessmentAmendmentCreate: ApiFunc<`${typeof API.ASSESSMENT_AMENDMENT}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_AMENDMENT, data)

export const assessmentAmendmentDelete: ApiFunc<`${typeof API.ASSESSMENT_AMENDMENT}{id}`, 'delete'> = data =>
  instance.delete(`${API.ASSESSMENT_AMENDMENT}${'id' in data ? prop('id', data) : data.name}`)

export const assessmentAmendmentList: ApiFunc<`${typeof API.ASSESSMENT_AMENDMENT}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_AMENDMENT, {
    params: data,
  })

export const assessmentAmendmentAll: ApiFunc<`${typeof API.ASSESSMENT_AMENDMENT_ALL}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_AMENDMENT_ALL, {
    params: data,
  })

export const assessmentAmendmentPublish: ApiFunc<`${typeof API.ASSESSMENT_AMENDMENT_PUBLISH}`, 'put'> = data =>
  instance.put(API.ASSESSMENT_AMENDMENT_PUBLISH, data)

export const assessmentMarkChangeStatus: ApiFunc<`${typeof API.ASSESSMENT_MARK_CHANGE_STATUS}`, 'put'> = data =>
  instance.put(API.ASSESSMENT_MARK_CHANGE_STATUS, data)

export const assessmentImportMarks: ApiFunc<`${typeof API.ASSESSMENT_IMPORT_MARKS}`, 'post'> = data =>
  axios.request({
    method: 'POST',
    url: `${API_URL}${API.ASSESSMENT_IMPORT_MARKS}`,
    data: data as unknown as FormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
      institution: getInstitution() || '1',
      'Accept-Language': getLanguage() || 'en',
      'edu-form': getEduForm() || '0',
    },
  })
