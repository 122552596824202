import { DefaultState } from '@src/redux/types'

import {
  FORM,
  FORM_ACTION_TYPE,
  FORM_ALL,
  FORM_CREATE,
  FORM_DELETE,
  FORM_FIELD_BATCH,
  FORM_FIELD_BATCH_CREATE,
  FORM_FIELD_CREATE,
  FORM_LIST,
  FORM_REQUEST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CERTIFICATE,
  FORM_REQUEST_CHANGE_STATUS,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_DELETE,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_LIST,
  FORM_TYPE,
  FORM_TYPE_ALL,
  FORM_TYPE_CREATE,
  FORM_TYPE_DELETE,
  FORM_TYPE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [FORM]: DefaultState<`${typeof API.FORM}{id}`, 'get'>
  [FORM_ACTION_TYPE]: DefaultState<`${typeof API.FORM_ACTION_TYPE}`, 'get'>
  [FORM_ALL]: DefaultState<`${typeof API.FORM_ALL}`, 'get'>
  [FORM_CREATE]: DefaultState<`${typeof API.FORM}`, 'post'>
  [FORM_DELETE]: DefaultState<`${typeof API.FORM}{id}`, 'delete'>
  [FORM_FIELD_BATCH]: DefaultState<`${typeof API.FORM_FIELD_BATCH}{id}`, 'get'>
  [FORM_FIELD_BATCH_CREATE]: DefaultState<`${typeof API.FORM_FIELD_BATCH}`, 'post'>
  [FORM_FIELD_CREATE]: DefaultState<`${typeof API.FORM_FIELD}`, 'post'>
  [FORM_LIST]: DefaultState<`${typeof API.FORM}`, 'get'>
  [FORM_REQUEST]: DefaultState<`${typeof API.FORM_REQUEST}{id}`, 'get'>
  [FORM_REQUEST_ALL]: DefaultState<`${typeof API.FORM_REQUEST_ALL}`, 'get'>
  [FORM_REQUEST_CERTIFICATE]: DefaultState<`${typeof API.FORM_REQUEST_CERTIFICATE}`, 'post'>
  [FORM_REQUEST_CHANGE_STATUS]: DefaultState<`${typeof API.FORM_REQUEST_CHANGE_STATUS}`, 'post'>
  [FORM_REQUEST_CREATE]: DefaultState<`${typeof API.FORM_REQUEST}`, 'post'>
  [FORM_REQUEST_DELETE]: DefaultState<`${typeof API.FORM_REQUEST}{id}`, 'delete'>
  [FORM_REQUEST_FIELDS]: DefaultState<`${typeof API.FORM_REQUEST_FIELDS}`, 'post'>
  [FORM_REQUEST_LIST]: DefaultState<`${typeof API.FORM_REQUEST}`, 'get'>
  [FORM_TYPE]: DefaultState<`${typeof API.FORM_TYPE}{id}`, 'get'>
  [FORM_TYPE_ALL]: DefaultState<`${typeof API.FORM_TYPE_ALL}`, 'get'>
  [FORM_TYPE_CREATE]: DefaultState<`${typeof API.FORM_TYPE}`, 'post'>
  [FORM_TYPE_DELETE]: DefaultState<`${typeof API.FORM_TYPE}{id}`, 'delete'>
  [FORM_TYPE_LIST]: DefaultState<`${typeof API.FORM_TYPE}`, 'get'>
} = () => ({
  [FORM]: defaultState(),
  [FORM_ACTION_TYPE]: defaultState(),
  [FORM_ALL]: defaultState(),
  [FORM_CREATE]: defaultState(),
  [FORM_DELETE]: defaultState(),
  [FORM_FIELD_BATCH]: defaultState(),
  [FORM_FIELD_BATCH_CREATE]: defaultState(),
  [FORM_FIELD_CREATE]: defaultState(),
  [FORM_LIST]: defaultState(),
  [FORM_REQUEST]: defaultState(),
  [FORM_REQUEST_ALL]: defaultState(),
  [FORM_REQUEST_CERTIFICATE]: defaultState(),
  [FORM_REQUEST_CHANGE_STATUS]: defaultState(),
  [FORM_REQUEST_CREATE]: defaultState(),
  [FORM_REQUEST_DELETE]: defaultState(),
  [FORM_REQUEST_FIELDS]: defaultState(),
  [FORM_REQUEST_LIST]: defaultState(),
  [FORM_TYPE]: defaultState(),
  [FORM_TYPE_ALL]: defaultState(),
  [FORM_TYPE_CREATE]: defaultState(),
  [FORM_TYPE_DELETE]: defaultState(),
  [FORM_TYPE_LIST]: defaultState(),
})
