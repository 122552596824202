import themeConfig from '@src/configs/themeConfig'

export const getStorageMenu = (): boolean => {
  try {
    const item = window.localStorage.getItem('menuHidden')
    return item ? JSON.parse(item) : null
  } catch (e) {
    console.warn(e)
  }
  return false
}

export const clearStorageMenu = () => {
  window.localStorage.removeItem('menuHidden')
}
