import { CSSProperties, ChangeEvent, ReactNode, forwardRef, useEffect } from 'react'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { Col, CustomInput, CustomInputProps, FormGroup, FormGroupProps, FormText, Label, LabelProps } from 'reactstrap'

type Props = Omit<CustomInputProps, 'type'> & {
  error?: FieldError
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void
  name: string
  style?: CSSProperties
  inline?: boolean
  inlineLabel?: ReactNode
  formGroupStyles?: CSSProperties
  labelProps?: LabelProps
  onUnmount?: () => void
  formGroupProps?: FormGroupProps
  required?: boolean
  value?: string | number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const RadioField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    inlineLabel,
    name,
    value,
    onChange,
    error,
    inline,
    disabled,
    id,
    className,
    row,
    fieldArrayId,
    isRequired,
    defaultChecked,
    defaultValue,
    idx,
    labelProps = {},
    onUnmount,
    required,
    handleChange = () => {},
    ...rest
  } = props

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onChange(e)
    handleChange(e)
  }

  const htmlId = id ? { id } : {}
  const htmlFor = { for: `${id}` || name }

  return (
    <FormGroup>
      {label && (
        <Label {...htmlFor} {...labelProps}>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}

      <Col>
        <CustomInput
          className={`custom-radio radio-success ${className}`}
          type='radio'
          id={id}
          name={name}
          value={value}
          // checked={value === checked}
          onChange={change}
          defaultValue={defaultValue}
          label={inlineLabel}
          inline={inline}
          disabled={disabled}
          {...htmlId}
          {...rest}
          // innerRef={ref}
        />

        {error ? <FormText color='primary'>{error.message || <FormattedMessage id='form_required' />}</FormText> : null}
      </Col>
    </FormGroup>
  )
})

export default RadioField
