import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { STATE, STATE_ALL, STATE_CREATE, STATE_DELETE, STATE_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const state = createAsyncThunk(
  STATE,
  async (params: PayloadCreatorParams<`${typeof API.STATE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.state(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stateCreate = createAsyncThunk(
  STATE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STATE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.stateCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stateDelete = createAsyncThunk(
  STATE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STATE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.stateDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stateList = createAsyncThunk(
  STATE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STATE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.stateList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stateAll = createAsyncThunk(
  STATE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.STATE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.stateAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stateClear = createAction(`${STATE}/clear`)

export const stateListClear = createAction(`${STATE_LIST}/clear`)

export const stateAllClear = createAction(`${STATE_ALL}/clear`)
