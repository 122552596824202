import { PasswordInputField } from '@components/HookForm'
import { LoadingButton } from '@components/Loaders'
import { useAppSelector } from '@hooks'
import { authSelector } from '@store/selector'
import { prop } from 'ramda'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Form, FormGroup, Label } from 'reactstrap'

export function UpdatePasswordForm({ handleSubmit, onSubmit, register, errors, control }) {
  const [password, setPassword] = useState('')
  const { formatMessage: msg } = useIntl()
  const auth = useAppSelector(authSelector)
  const loginLoading = prop('loading', auth)

  function handlePasswordChange(value) {
    setPassword(value)
  }

  return (
    <Form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <div className='d-flex justify-content-between'>
          <Label className='form-label' for='login-password'>
            {msg({ id: 'password' })}
          </Label>
        </div>

        <Controller
          render={({ field }) => (
            <PasswordInputField
              {...field}
              id='login-password'
              handleChange={handlePasswordChange}
              register={register}
              error={prop('password', errors)}
            />
          )}
          name='password'
          control={control}
          defaultValue={password}
          rules={{
            required: true,
            validate: value => value !== '',
          }}
        />
      </FormGroup>

      <LoadingButton
        loading={loginLoading}
        spinnerSize='sm'
        type='submit'
        onClick={handleSubmit(onSubmit)}
        color='primary'
        block
      >
        {msg({ id: 'signIn' })}
      </LoadingButton>
    </Form>
  )
}
