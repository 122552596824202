import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  STUDENT_CV,
  STUDENT_CV_COMMENTS,
  STUDENT_CV_CURATOR,
  STUDENT_CV_LIST,
  STUDENT_CV_PRINCIPAL,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const STUDENT_CVS = GLOBAL_STATE

export const { reducer: studentCVReducer } = createSlice({
  name: STUDENT_CVS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.studentCV.pending, state => {
        state[STUDENT_CV].loading = true
      })
      .addCase(actionCreators.studentCV.rejected, (state, action) => {
        state[STUDENT_CV].loading = false
        state[STUDENT_CV].error = action.payload as AxiosError
      })
      .addCase(actionCreators.studentCV.fulfilled, (state, action) => {
        state[STUDENT_CV].loading = false
        state[STUDENT_CV].error = null
        state[STUDENT_CV].data = action.payload
      })

    builder
      .addCase(actionCreators.studentCVList.pending, state => {
        state[STUDENT_CV_LIST].loading = true
      })
      .addCase(actionCreators.studentCVList.rejected, (state, action) => {
        state[STUDENT_CV_LIST].loading = false
        state[STUDENT_CV_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.studentCVList.fulfilled, (state, action) => {
        state[STUDENT_CV_LIST].loading = false
        state[STUDENT_CV_LIST].error = null
        state[STUDENT_CV_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.studentCVPrincipal.pending, state => {
        state[STUDENT_CV_PRINCIPAL].loading = true
      })
      .addCase(actionCreators.studentCVPrincipal.rejected, (state, action) => {
        state[STUDENT_CV_PRINCIPAL].loading = false
        state[STUDENT_CV_PRINCIPAL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.studentCVPrincipal.fulfilled, (state, action) => {
        state[STUDENT_CV_PRINCIPAL].loading = false
        state[STUDENT_CV_PRINCIPAL].error = null
        state[STUDENT_CV_PRINCIPAL].data = action.payload
      })

    builder
      .addCase(actionCreators.studentCVCurator.pending, state => {
        state[STUDENT_CV_CURATOR].loading = true
      })
      .addCase(actionCreators.studentCVCurator.rejected, (state, action) => {
        state[STUDENT_CV_CURATOR].loading = false
        state[STUDENT_CV_CURATOR].error = action.payload as AxiosError
      })
      .addCase(actionCreators.studentCVCurator.fulfilled, (state, action) => {
        state[STUDENT_CV_CURATOR].loading = false
        state[STUDENT_CV_CURATOR].error = null
        state[STUDENT_CV_CURATOR].data = action.payload
      })

    builder.addCase(actionCreators.studentCVClear, state => {
      state[STUDENT_CV] = defaultState()
    })

    builder.addCase(actionCreators.studentCVListClear, state => {
      state[STUDENT_CV_LIST] = defaultState()
    })

    builder.addCase(actionCreators.studentCVPrincipalClear, state => {
      state[STUDENT_CV_PRINCIPAL] = defaultState()
    })

    builder.addCase(actionCreators.studentCVCuratorClear, state => {
      state[STUDENT_CV_CURATOR] = defaultState()
    })
  },
})
