import {
  ADMIN_VIEWER,
  COURSE_LEADER,
  DEAN,
  EXAM_HEAD,
  MODERATOR_VIEWER,
  MODULE_LEADER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  STUDENTS_DEPARTMENT,
  SUPER_ADMIN,
  TEACHER,
} from '@constants/userRoles'
import { lazy } from 'react'

import {
  ASSESSMENT_MARK_AMENDMENT,
  ASSESSMENT_MARK_ENTRY_LIST,
  ASSESSMENT_MARK_ENTRY_REGISTRAR_LIST,
  MARK_ASSESSMENT,
  MARK_LESSON,
} from '../constants/route'

export const AssessmentMarkRoutes = [
  {
    path: ASSESSMENT_MARK_ENTRY_LIST,
    component: lazy(() => import('../views/MarkEntry')),
    meta: {
      access: [SUPER_ADMIN, EXAM_HEAD, ADMIN_VIEWER],
    },
  },
  {
    path: ASSESSMENT_MARK_ENTRY_REGISTRAR_LIST,
    component: lazy(() => import('../views/Marks')),
    meta: {
      access: [SUPER_ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, MODERATOR_VIEWER, ADMIN_VIEWER],
    },
  },
  {
    path: ASSESSMENT_MARK_AMENDMENT,
    component: lazy(() => import('../views/MarkAmendment')),
    meta: {
      access: [SUPER_ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, MODERATOR_VIEWER, ADMIN_VIEWER],
    },
  },
  {
    path: MARK_LESSON,
    component: lazy(() => import('../../Attendance/components/AttendanceStudents')),
    exact: true,
    appLayout: true,
    className: 'email-application',
    meta: {
      access: [
        SUPER_ADMIN,
        TEACHER,
        MODULE_LEADER,
        DEAN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        COURSE_LEADER,
        STUDENTS_DEPARTMENT,
        ADMIN_VIEWER,
      ],
      props: {
        type: 'ASSESSMENT',
      },
    },
  },
  {
    path: MARK_ASSESSMENT,
    component: lazy(() => import('../../AssessmentMarks/views/MarkAssessmentApp/MarkAssessmentApp')),
    exact: true,
    appLayout: true,
    className: 'email-application',
    meta: {
      // eslint-disable-next-line array-bracket-spacing
      access: [
        SUPER_ADMIN,
        TEACHER,
        MODULE_LEADER,
        DEAN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        COURSE_LEADER,
        STUDENTS_DEPARTMENT,
        ADMIN_VIEWER,
      ],
    },
  },
]
