import { SUPER_ADMIN, ADMIN, TEST_STUDENT, BACHELOR_STUDENT, REGISTRAR_OFFICER, COURSE_LEADER, STUDENTS_DEPARTMENT, DEAN } from '@src/constants/userRoles'
import { lazy } from 'react'

import { TIMETABLE } from '../constants/route'

export const TimetableRoutes = [
  {
    path: TIMETABLE,
    component: lazy(() => import('../views/Timetable')),
    meta: {
      access: [SUPER_ADMIN, ADMIN, TEST_STUDENT, BACHELOR_STUDENT, REGISTRAR_OFFICER, COURSE_LEADER, STUDENTS_DEPARTMENT, DEAN],
    },
  },
]
