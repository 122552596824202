import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_DELETE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
  ENROLLMENT_STUDENT_OWN,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ENROLLMENTS = GLOBAL_STATE

export const { reducer: enrollmentReducer } = createSlice({
  name: ENROLLMENTS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.enrollment.pending, state => {
        state[ENROLLMENT].loading = true
      })
      .addCase(actionCreators.enrollment.rejected, (state, action) => {
        state[ENROLLMENT].loading = false
        state[ENROLLMENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollment.fulfilled, (state, action) => {
        state[ENROLLMENT].loading = false
        state[ENROLLMENT].error = null
        state[ENROLLMENT].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentAll.pending, state => {
        state[ENROLLMENT_ALL].loading = true
      })
      .addCase(actionCreators.enrollmentAll.rejected, (state, action) => {
        state[ENROLLMENT_ALL].loading = false
        state[ENROLLMENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentAll.fulfilled, (state, action) => {
        state[ENROLLMENT_ALL].loading = false
        state[ENROLLMENT_ALL].error = null
        state[ENROLLMENT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentCourses.pending, state => {
        state[ENROLLMENT_COURSES].loading = true
      })
      .addCase(actionCreators.enrollmentCourses.rejected, (state, action) => {
        state[ENROLLMENT_COURSES].loading = false
        state[ENROLLMENT_COURSES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentCourses.fulfilled, (state, action) => {
        state[ENROLLMENT_COURSES].loading = false
        state[ENROLLMENT_COURSES].error = null
        state[ENROLLMENT_COURSES].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentCreate.pending, state => {
        state[ENROLLMENT_CREATE].loading = true
      })
      .addCase(actionCreators.enrollmentCreate.rejected, (state, action) => {
        state[ENROLLMENT_CREATE].loading = false
        state[ENROLLMENT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentCreate.fulfilled, (state, action) => {
        state[ENROLLMENT_CREATE].loading = false
        state[ENROLLMENT_CREATE].error = null
        state[ENROLLMENT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentDelete.pending, state => {
        state[ENROLLMENT_DELETE].loading = true
      })
      .addCase(actionCreators.enrollmentDelete.rejected, (state, action) => {
        state[ENROLLMENT_DELETE].loading = false
        state[ENROLLMENT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentDelete.fulfilled, (state, action) => {
        state[ENROLLMENT_DELETE].loading = false
        state[ENROLLMENT_DELETE].error = null
        state[ENROLLMENT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentList.pending, state => {
        state[ENROLLMENT_LIST].loading = true
      })
      .addCase(actionCreators.enrollmentList.rejected, (state, action) => {
        state[ENROLLMENT_LIST].loading = false
        state[ENROLLMENT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentList.fulfilled, (state, action) => {
        state[ENROLLMENT_LIST].loading = false
        state[ENROLLMENT_LIST].error = null
        state[ENROLLMENT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentStatus.pending, state => {
        state[ENROLLMENT_STATUS].loading = true
      })
      .addCase(actionCreators.enrollmentStatus.rejected, (state, action) => {
        state[ENROLLMENT_STATUS].loading = false
        state[ENROLLMENT_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentStatus.fulfilled, (state, action) => {
        state[ENROLLMENT_STATUS].loading = false
        state[ENROLLMENT_STATUS].error = null
        state[ENROLLMENT_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.enrollmentStudentOwn.pending, state => {
        state[ENROLLMENT_STUDENT_OWN].loading = true
      })
      .addCase(actionCreators.enrollmentStudentOwn.rejected, (state, action) => {
        state[ENROLLMENT_STUDENT_OWN].loading = false
        state[ENROLLMENT_STUDENT_OWN].error = action.payload as AxiosError
      })
      .addCase(actionCreators.enrollmentStudentOwn.fulfilled, (state, action) => {
        state[ENROLLMENT_STUDENT_OWN].loading = false
        state[ENROLLMENT_STUDENT_OWN].error = null
        state[ENROLLMENT_STUDENT_OWN].data = action.payload
      })

    builder.addCase(actionCreators.enrollmentClear, state => {
      state[ENROLLMENT] = defaultState()
    })

    builder.addCase(actionCreators.enrollmentAllClear, state => {
      state[ENROLLMENT_ALL] = defaultState()
    })

    builder.addCase(actionCreators.enrollmentCoursesClear, state => {
      state[ENROLLMENT_COURSES] = defaultState()
    })

    builder.addCase(actionCreators.enrollmentCreateClear, state => {
      state[ENROLLMENT_CREATE] = defaultState()
    })

    builder.addCase(actionCreators.enrollmentListClear, state => {
      state[ENROLLMENT_LIST] = defaultState()
    })

    builder.addCase(actionCreators.enrollmentStatusClear, state => {
      state[ENROLLMENT_STATUS] = defaultState()
    })

    builder.addCase(actionCreators.enrollmentStudentOwnClear, state => {
      state[ENROLLMENT_STUDENT_OWN] = defaultState()
    })
  },
})
