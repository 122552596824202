import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { COURSE, COURSE_ALL, COURSE_CREATE, COURSE_DELETE, COURSE_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const course = createAsyncThunk(
  COURSE,
  async (params: PayloadCreatorParams<`${typeof API.COURSE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.course(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseCreate = createAsyncThunk(
  COURSE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.COURSE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.courseCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseDelete = createAsyncThunk(
  COURSE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.COURSE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.courseDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseList = createAsyncThunk(
  COURSE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.COURSE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseAll = createAsyncThunk(
  COURSE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseClear = createAction(`${COURSE}/clear`)

export const courseListClear = createAction(`${COURSE_LIST}/clear`)

export const courseAllClear = createAction(`${COURSE_ALL}/clear`)
