/* eslint-disable */
import { definitions } from '@schema'
import { rolesMapSelector, rolesSelector } from '@store/selector'
import { any } from 'ramda'
import { useSelector } from 'react-redux'

export type RolesModule = typeof import('@constants/userRoles')

export type roleConstants = RolesModule[keyof RolesModule]

function useCheckRoles() {
  const roles = useSelector(rolesSelector) as unknown as roleConstants[] | undefined
  const rolesMap = useSelector(rolesMapSelector)

  /**
   * Returns true if at least one of user roles of the list match the predicate.
   *
   * Returns null if no roles found in userInfo
   *
   * @param predicate array of roles "['ADMIN', 'MODERATOR']", a string, RegExp or a predicate function
   *
   */
  function amI(
    predicate:
      | roleConstants[]
      | roleConstants
      | RegExp
      | ((roles: roleConstants[], roles_map: definitions['UserInfo']['roles_map']) => boolean)
  ) {
    if (!roles || !roles.length) return false

    if (!rolesMap || !predicate) {
      return null //amI returns null if userInfo data is not present
    }
    if (Array.isArray(predicate)) {
      return any(role => Object.prototype.hasOwnProperty.call(rolesMap, role), predicate)
    }
    if (typeof predicate === 'string') {
      return Object.prototype.hasOwnProperty.call(rolesMap, predicate)
    }
    if (predicate instanceof RegExp) {
      return any(role => predicate.test(role), roles)
    }
    if (typeof predicate === 'function') {
      return predicate(roles, rolesMap)
    }
  }

  return { amI, userInfoLoading: !roles, roles, rolesMap }
}

export default useCheckRoles
