import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const authorizedInstance = getInstance()

export const timetable: ApiFunc<`${typeof API.TIMETABLE}`, 'get'> = data =>
  authorizedInstance.get(API.TIMETABLE, { params: data })

export const timetableAll: ApiFunc<`${typeof API.TIMETABLE_ALL}`, 'get'> = data =>
  authorizedInstance.get(API.TIMETABLE_ALL, { params: data })

export const timetableGenerate: ApiFunc<`${typeof API.TIMETABLE_GENERATE}`, 'post'> = data =>
  authorizedInstance.post(API.TIMETABLE_GENERATE, data)
