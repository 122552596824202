import { RootState } from '@store/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
  ATTENDANCE_TABLE_STUDENT,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const attendancesSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const attendanceGroupSelector = createSelector(attendancesSelector, data => data[ATTENDANCE_GROUP])

export const attendanceTableSelector = createSelector(attendancesSelector, data => data[ATTENDANCE_TABLE])

export const attendanceTableCSVSelector = createSelector(attendancesSelector, data => data[ATTENDANCE_CSV])

export const attendanceSelector = createSelector(attendancesSelector, data => data[ATTENDANCE])

export const attendanceTableStudentSelector = createSelector(
  attendancesSelector,
  data => data[ATTENDANCE_TABLE_STUDENT]
)

export const attendanceTableModuleSelector = createSelector(attendancesSelector, data => data[ATTENDANCE_TABLE_MODULE])
