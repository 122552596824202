import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  COURSE_TRANSFER,
  COURSE_TRANSFER_COURSES,
  COURSE_TRANSFER_INFO,
  COURSE_TRANSFER_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const courseTransfer = createAsyncThunk(
  COURSE_TRANSFER,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_TRANSFER}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.courseTransfer(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseTransferList = createAsyncThunk(
  COURSE_TRANSFER_LIST,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_TRANSFER}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseTransferList(omit(['onFulfilled', 'onRejected', 'onSettled'], params?.config))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseTransferCourses = createAsyncThunk(
  COURSE_TRANSFER_COURSES,
  async (
    params: PayloadCreatorParams<`${typeof API.COURSE_TRANSFER_COURSES}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.courseTransferCourses(omit(['onFulfilled', 'onRejected', 'onSettled'], params?.config))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseTransferInfo = createAsyncThunk(
  COURSE_TRANSFER_INFO,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_TRANSFER_INFO}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseTransferInfo(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseTransferListClear = createAction(`${COURSE_TRANSFER_LIST}/clear`)

export const courseTransferCoursesClear = createAction(`${COURSE_TRANSFER_COURSES}/clear`)

export const courseTransferInfoClear = createAction(`${COURSE_TRANSFER_INFO}/clear`)
