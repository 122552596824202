import { DefaultState } from '@src/redux/types'

import {
  APPLICATION,
  APPLICATION_ALL,
  APPLICATION_CREATE,
  APPLICATION_DELETE,
  APPLICATION_FIELD_BATCH,
  APPLICATION_FIELD_BATCH_CREATE,
  APPLICATION_LIST,
  APPLICATION_REQUEST,
  APPLICATION_REQUEST_ALL,
  APPLICATION_REQUEST_EDIT,
  APPLICATION_REQUEST_UPDATE,
  APPLICATION_STATUS,
  APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [APPLICATION]: DefaultState<`${typeof API.APPLICATION_FORM}{id}`, 'get'>
  [APPLICATION_ALL]: DefaultState<`${typeof API.APPLICATION_ALL}`, 'get'>
  [APPLICATION_CREATE]: DefaultState<`${typeof API.APPLICATION_FORM}`, 'post'>
  [APPLICATION_DELETE]: DefaultState<`${typeof API.APPLICATION_FORM}{id}`, 'delete'>
  [APPLICATION_FIELD_BATCH]: DefaultState<`${typeof API.APPLICATION_FIELD_BATCH}{id}`, 'get'>
  [APPLICATION_FIELD_BATCH_CREATE]: DefaultState<`${typeof API.APPLICATION_FIELD_BATCH}`, 'post'>
  [APPLICATION_LIST]: DefaultState<`${typeof API.APPLICATION}`, 'get'>
  [APPLICATION_REQUEST]: DefaultState<`${typeof API.APPLICATION_REQUEST}`, 'post'>
  [APPLICATION_REQUEST_ALL]: DefaultState<`${typeof API.APPLICATION_REQUEST_ALL}`, 'get'>
  [APPLICATION_REQUEST_EDIT]: DefaultState<`${typeof API.APPLICATION_REQUEST_EDIT}`, 'get'>
  [APPLICATION_REQUEST_UPDATE]: DefaultState<`${typeof API.APPLICATION_REQUEST_UPDATE}`, 'put'>
  [APPLICATION_STATUS]: DefaultState<`${typeof API.APPLICATION_STATUS}`, 'put'>
  [APPLICATION_TYPES]: DefaultState<`${typeof API.APPLICATION_TYPES}`, 'get'>
} = () => ({
  [APPLICATION]: defaultState(),
  [APPLICATION_ALL]: defaultState(),
  [APPLICATION_CREATE]: defaultState(),
  [APPLICATION_DELETE]: defaultState(),
  [APPLICATION_FIELD_BATCH]: defaultState(),
  [APPLICATION_FIELD_BATCH_CREATE]: defaultState(),
  [APPLICATION_LIST]: defaultState(),
  [APPLICATION_REQUEST]: defaultState(),
  [APPLICATION_REQUEST_ALL]: defaultState(),
  [APPLICATION_REQUEST_EDIT]: defaultState(),
  [APPLICATION_REQUEST_UPDATE]: defaultState(),
  [APPLICATION_STATUS]: defaultState(),
  [APPLICATION_TYPES]: defaultState(),
})
