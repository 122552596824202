import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { prop } from 'ramda'

import {
  GLOBAL_STATE,
  INTERNAL_EXAM_APPLICANTS_LIST,
  INTERNAL_EXAM_PLACE,
  INTERNAL_EXAM_PLACE_ALL,
  INTERNAL_EXAM_PLACE_CREATE,
  INTERNAL_EXAM_PLACE_LIST,
} from '../constants/actionTypes'

const internalExamPlacesSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const internalExamPlaceSelector = createSelector(internalExamPlacesSelector, prop(INTERNAL_EXAM_PLACE))
export const internalExamPlaceAllSelector = createSelector(internalExamPlacesSelector, prop(INTERNAL_EXAM_PLACE_ALL))
export const internalExamPlaceListSelector = createSelector(internalExamPlacesSelector, prop(INTERNAL_EXAM_PLACE_LIST))
export const internalExamApplicantsListSelector = createSelector(
  internalExamPlacesSelector,
  prop(INTERNAL_EXAM_APPLICANTS_LIST)
)
export const internalExamPlaceCreateSelector = createSelector(
  internalExamPlacesSelector,
  prop(INTERNAL_EXAM_PLACE_CREATE)
)
