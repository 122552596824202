import { ASSESSMENT } from '../../Assessment/constants/api'

export const OFFENCE = `/offence/` as const
export const OFFENCE_ALL = `${OFFENCE}all` as const
export const OFFENCE_CHANGE_STATUS = `${OFFENCE}change/status` as const
export const OFFENCE_TYPE = `${OFFENCE}type/` as const
export const OFFENCE_TYPE_ALL = `${OFFENCE_TYPE}all` as const
export const OFFENCE_INVIGILATION = `${OFFENCE}invigilation/` as const
export const OFFENCE_INVIGILATION_ALL = `${OFFENCE_INVIGILATION}all` as const
export const OFFENCE_INVIGILATION_MINUTES = `${OFFENCE}invigilation_minutes/` as const
export const OFFENCE_INVIGILATION_MINUTES_ALL = `${OFFENCE_INVIGILATION_MINUTES}all` as const
export const OFFENCE_INVIGILATION_CONSULTATIONS = `${OFFENCE}consultation_reports/` as const
export const OFFENCE_INVIGILATION_CONSULTATIONS_ALL = `${OFFENCE_INVIGILATION_CONSULTATIONS}all` as const
export const OFFENCE_REPORT = `${OFFENCE}report/` as const
export const OFFENCE_REPORT_ALL = `${OFFENCE_REPORT}all` as const
export const OFFENCE_PUBLISH = `${OFFENCE}publish` as const
export const OFFENCE_UNPUBLISH = `${OFFENCE}unpublish` as const
export const OFFENCE_CHANGE_SUBJECT_BOARD_STATUS = `${ASSESSMENT}change/subjectboard/status` as const
export const REPORT_MISCONDUCT = `/report/` as const
