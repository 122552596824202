import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  APPLICATION,
  APPLICATION_ALL,
  APPLICATION_CREATE,
  APPLICATION_DELETE,
  APPLICATION_FIELD_BATCH,
  APPLICATION_FIELD_BATCH_CREATE,
  APPLICATION_LIST,
  APPLICATION_REQUEST,
  APPLICATION_REQUEST_ALL,
  APPLICATION_REQUEST_EDIT,
  APPLICATION_REQUEST_UPDATE,
  APPLICATION_STATUS,
  APPLICATION_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const STUDENT_ADMISSION = GLOBAL_STATE

export const { reducer: studentAdmissionReducer } = createSlice({
  name: STUDENT_ADMISSION,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.application.pending, state => {
        state[APPLICATION].loading = true
      })
      .addCase(actionCreators.application.rejected, (state, action) => {
        state[APPLICATION].loading = false
        state[APPLICATION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.application.fulfilled, (state, action) => {
        state[APPLICATION].loading = false
        state[APPLICATION].error = null
        state[APPLICATION].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationAll.pending, state => {
        state[APPLICATION_ALL].loading = true
      })
      .addCase(actionCreators.applicationAll.rejected, (state, action) => {
        state[APPLICATION_ALL].loading = false
        state[APPLICATION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationAll.fulfilled, (state, action) => {
        state[APPLICATION_ALL].loading = false
        state[APPLICATION_ALL].error = null
        state[APPLICATION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationCreate.pending, state => {
        state[APPLICATION_CREATE].loading = true
      })
      .addCase(actionCreators.applicationCreate.rejected, (state, action) => {
        state[APPLICATION_CREATE].loading = false
        state[APPLICATION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationCreate.fulfilled, (state, action) => {
        state[APPLICATION_CREATE].loading = false
        state[APPLICATION_CREATE].error = null
        state[APPLICATION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationDelete.pending, state => {
        state[APPLICATION_DELETE].loading = true
      })
      .addCase(actionCreators.applicationDelete.rejected, (state, action) => {
        state[APPLICATION_DELETE].loading = false
        state[APPLICATION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationDelete.fulfilled, (state, action) => {
        state[APPLICATION_DELETE].loading = false
        state[APPLICATION_DELETE].error = null
        state[APPLICATION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationFieldBatch.pending, state => {
        state[APPLICATION_FIELD_BATCH].loading = true
      })
      .addCase(actionCreators.applicationFieldBatch.rejected, (state, action) => {
        state[APPLICATION_FIELD_BATCH].loading = false
        state[APPLICATION_FIELD_BATCH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationFieldBatch.fulfilled, (state, action) => {
        state[APPLICATION_FIELD_BATCH].loading = false
        state[APPLICATION_FIELD_BATCH].error = null
        state[APPLICATION_FIELD_BATCH].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationFieldBatchCreate.pending, state => {
        state[APPLICATION_FIELD_BATCH_CREATE].loading = true
      })
      .addCase(actionCreators.applicationFieldBatchCreate.rejected, (state, action) => {
        state[APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[APPLICATION_FIELD_BATCH_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationFieldBatchCreate.fulfilled, (state, action) => {
        state[APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[APPLICATION_FIELD_BATCH_CREATE].error = null
        state[APPLICATION_FIELD_BATCH_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationList.pending, state => {
        state[APPLICATION_LIST].loading = true
      })
      .addCase(actionCreators.applicationList.rejected, (state, action) => {
        state[APPLICATION_LIST].loading = false
        state[APPLICATION_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationList.fulfilled, (state, action) => {
        state[APPLICATION_LIST].loading = false
        state[APPLICATION_LIST].error = null
        state[APPLICATION_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationRequest.pending, state => {
        state[APPLICATION_REQUEST].loading = true
      })
      .addCase(actionCreators.applicationRequest.rejected, (state, action) => {
        state[APPLICATION_REQUEST].loading = false
        state[APPLICATION_REQUEST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationRequest.fulfilled, (state, action) => {
        state[APPLICATION_REQUEST].loading = false
        state[APPLICATION_REQUEST].error = null
        state[APPLICATION_REQUEST].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationRequestAll.pending, state => {
        state[APPLICATION_REQUEST_ALL].loading = true
      })
      .addCase(actionCreators.applicationRequestAll.rejected, (state, action) => {
        state[APPLICATION_REQUEST_ALL].loading = false
        state[APPLICATION_REQUEST_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationRequestAll.fulfilled, (state, action) => {
        state[APPLICATION_REQUEST_ALL].loading = false
        state[APPLICATION_REQUEST_ALL].error = null
        state[APPLICATION_REQUEST_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationRequestEdit.pending, state => {
        state[APPLICATION_REQUEST_EDIT].loading = true
      })
      .addCase(actionCreators.applicationRequestEdit.rejected, (state, action) => {
        state[APPLICATION_REQUEST_EDIT].loading = false
        state[APPLICATION_REQUEST_EDIT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationRequestEdit.fulfilled, (state, action) => {
        state[APPLICATION_REQUEST_EDIT].loading = false
        state[APPLICATION_REQUEST_EDIT].error = null
        state[APPLICATION_REQUEST_EDIT].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationRequestUpdate.pending, state => {
        state[APPLICATION_REQUEST_UPDATE].loading = true
      })
      .addCase(actionCreators.applicationRequestUpdate.rejected, (state, action) => {
        state[APPLICATION_REQUEST_UPDATE].loading = false
        state[APPLICATION_REQUEST_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationRequestUpdate.fulfilled, (state, action) => {
        state[APPLICATION_REQUEST_UPDATE].loading = false
        state[APPLICATION_REQUEST_UPDATE].error = null
        state[APPLICATION_REQUEST_UPDATE].data = action.payload

        state[APPLICATION_REQUEST_EDIT].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationChangeStatus.pending, state => {
        state[APPLICATION_STATUS].loading = true
      })
      .addCase(actionCreators.applicationChangeStatus.rejected, (state, action) => {
        state[APPLICATION_STATUS].loading = false
        state[APPLICATION_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationChangeStatus.fulfilled, (state, action) => {
        state[APPLICATION_STATUS].loading = false
        state[APPLICATION_STATUS].error = null
        state[APPLICATION_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.applicationTypes.pending, state => {
        state[APPLICATION_TYPES].loading = true
      })
      .addCase(actionCreators.applicationTypes.rejected, (state, action) => {
        state[APPLICATION_TYPES].loading = false
        state[APPLICATION_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.applicationTypes.fulfilled, (state, action) => {
        state[APPLICATION_TYPES].loading = false
        state[APPLICATION_TYPES].error = null
        state[APPLICATION_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.applicationClear, state => {
      state[APPLICATION] = defaultState()
    })

    builder.addCase(actionCreators.applicationAllClear, state => {
      state[APPLICATION_ALL] = defaultState()
    })

    builder.addCase(actionCreators.applicationFieldBatchClear, state => {
      state[APPLICATION_FIELD_BATCH] = defaultState()
    })

    builder.addCase(actionCreators.applicationListClear, state => {
      state[APPLICATION_LIST] = defaultState()
    })

    builder.addCase(actionCreators.applicationRequestEditClear, state => {
      state[APPLICATION_REQUEST_EDIT] = defaultState()
    })

    builder.addCase(actionCreators.applicationRequestUpdateClear, state => {
      state[APPLICATION_REQUEST_UPDATE] = defaultState()
    })

    builder.addCase(actionCreators.applicationTypesClear, state => {
      state[APPLICATION_TYPES] = defaultState()
    })
  },
})
