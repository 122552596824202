import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  STUDENT_CV,
  STUDENT_CV_CHANGE_STATUS,
  STUDENT_CV_COMMENTS,
  STUDENT_CV_CREATE,
  STUDENT_CV_CURATOR,
  STUDENT_CV_GENERATE_CV,
  STUDENT_CV_GENERATE_OBJECTIVE,
  STUDENT_CV_HARD_SKILLS,
  STUDENT_CV_LIST,
  STUDENT_CV_PRINCIPAL,
  STUDENT_CV_SOFT_SKILLS,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const studentCV = createAsyncThunk(
  STUDENT_CV,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CV}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.studentCV(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVPrincipal = createAsyncThunk(
  STUDENT_CV_PRINCIPAL,
  async (
    params: PayloadCreatorParams<`${typeof API.STUDENT_CV_PRINCIPAL}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.studentCVPrincipal(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVCreate = createAsyncThunk(
  STUDENT_CV_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CV}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.studentCVCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVList = createAsyncThunk(
  STUDENT_CV_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CV}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.studentCVList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVCurator = createAsyncThunk(
  STUDENT_CV_CURATOR,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CV_CURATOR}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.studentCVCurator(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVComments = createAsyncThunk(
  STUDENT_CV_COMMENTS,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CV_REVIEW}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.studentCVComments(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVHardSkills = createAsyncThunk(
  STUDENT_CV_HARD_SKILLS,
  async (
    params: PayloadCreatorParams<`${typeof API.STUDENT_CV_HARD_SKILLS}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.studentCVHardSkills(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVSoftSkills = createAsyncThunk(
  STUDENT_CV_SOFT_SKILLS,
  async (
    params: PayloadCreatorParams<`${typeof API.STUDENT_CV_SOFT_SKILLS}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.studentCVSoftSkills(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVGenerateObjective = createAsyncThunk(
  STUDENT_CV_GENERATE_OBJECTIVE,
  async (
    params: PayloadCreatorParams<`${typeof API.STUDENT_CV_GENERATE_OBJECTIVE}`, 'post'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.studentCVGenerateObjective(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVGenerateCV = createAsyncThunk(
  STUDENT_CV_GENERATE_CV,
  async (
    params: PayloadCreatorParams<`${typeof API.STUDENT_CV_GENERATE_CV}`, 'post'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.studentCVGenerateCV(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVChangeStatus = createAsyncThunk(
  STUDENT_CV_CHANGE_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.STUDENT_CV_CHANGE_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.studentCVChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const studentCVClear = createAction(`${STUDENT_CV}/clear`)

export const studentCVListClear = createAction(`${STUDENT_CV_LIST}/clear`)

export const studentCVPrincipalClear = createAction(`${STUDENT_CV_PRINCIPAL}/clear`)

export const studentCVCuratorClear = createAction(`${STUDENT_CV_CURATOR}/clear`)
