import { ApiFunc, getInstance } from '@helpers/httpClient'
import { prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const moduleFileAll: ApiFunc<`${typeof API.MODULE_FILE_ALL}`, 'get'> = data =>
  instance.get(API.MODULE_FILE_ALL, {
    params: data,
  })

export const moduleFileList: ApiFunc<`${typeof API.MODULE_FILE}`, 'get'> = data =>
  instance.get(API.MODULE_FILE, {
    params: data,
  })

export const moduleFile: ApiFunc<`${typeof API.MODULE_FILE}{id}`, 'get'> = data =>
  instance.get(`${API.MODULE_FILE}${'id' in data ? prop('id', data) : data.name}`, {
    params: data,
  })

export const moduleFileDelete: ApiFunc<`${typeof API.MODULE_FILE}{id}`, 'delete'> = data =>
  instance.delete(`${API.MODULE_FILE}${'id' in data ? prop('id', data) : data.name}`)

export const moduleFileCreate: ApiFunc<`${typeof API.MODULE_FILE}`, 'post'> = data =>
  instance.post(API.MODULE_FILE, data)

// export const moduleFileActivate: ApiFunc<`${typeof API.MODULE_FILE_ACTIVATE}{id}`, 'put'> = data => instance.put(`${API.MODULE_FILE_ACTIVATE}${'id' in data ? prop('id', data) : data.name}`)
