import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const educationPlace: ApiFunc<`${typeof API.EDUCATION_PLACE}{id}`, 'get'> = data =>
  instance.get(`${API.EDUCATION_PLACE}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const educationPlaceCreate: ApiFunc<`${typeof API.EDUCATION_PLACE}`, 'post'> = data =>
  instance.post(API.EDUCATION_PLACE, data)

export const educationPlaceDelete: ApiFunc<`${typeof API.EDUCATION_PLACE}{id}`, 'delete'> = data =>
  instance.delete(`${API.EDUCATION_PLACE}${'id' in data ? prop('id', data) : data.name}`)

export const educationPlaceList: ApiFunc<`${typeof API.EDUCATION_PLACE}`, 'get'> = data =>
  instance.get(API.EDUCATION_PLACE, {
    params: data,
  })

export const educationPlaceAll: ApiFunc<`${typeof API.EDUCATION_PLACE_ALL}`, 'get'> = data =>
  instance.get(API.EDUCATION_PLACE_ALL, {
    params: data,
  })
