import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  OFFENCE,
  OFFENCE_ALL,
  OFFENCE_CHANGE_STATUS,
  OFFENCE_CHANGE_SUBJECT_BOARD_STATUS,
  OFFENCE_CREATE,
  OFFENCE_DELETE,
  OFFENCE_INVIGILATION,
  OFFENCE_INVIGILATION_ALL,
  OFFENCE_INVIGILATION_CONSULTATIONS,
  OFFENCE_INVIGILATION_CONSULTATIONS_ALL,
  OFFENCE_INVIGILATION_CONSULTATIONS_CREATE,
  OFFENCE_INVIGILATION_CONSULTATIONS_DELETE,
  OFFENCE_INVIGILATION_CREATE,
  OFFENCE_INVIGILATION_DELETE,
  OFFENCE_INVIGILATION_MINUTES,
  OFFENCE_INVIGILATION_MINUTES_ALL,
  OFFENCE_INVIGILATION_MINUTES_CREATE,
  OFFENCE_INVIGILATION_MINUTES_DELETE,
  OFFENCE_LIST,
  OFFENCE_PUBLISH,
  OFFENCE_REPORT_ALL,
  OFFENCE_REPORT_CREATE,
  OFFENCE_REPORT_LIST,
  OFFENCE_TYPE,
  OFFENCE_TYPE_ALL,
  OFFENCE_TYPE_CREATE,
  OFFENCE_TYPE_DELETE,
  OFFENCE_UNPUBLISH,
  REPORT_MISCONDUCT,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const offence = createAsyncThunk(
  OFFENCE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offence(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceList = createAsyncThunk(
  OFFENCE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceCreate = createAsyncThunk(
  OFFENCE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceAll = createAsyncThunk(
  OFFENCE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceDelete = createAsyncThunk(
  OFFENCE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilation = createAsyncThunk(
  OFFENCE_INVIGILATION,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceInvigilation(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationCreate = createAsyncThunk(
  OFFENCE_INVIGILATION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceInvigilationCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationAll = createAsyncThunk(
  OFFENCE_INVIGILATION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceInvigilationAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationDelete = createAsyncThunk(
  OFFENCE_INVIGILATION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceInvigilationDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationConsultations = createAsyncThunk(
  OFFENCE_INVIGILATION_CONSULTATIONS,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}{id}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationConsultations(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationConsultationsCreate = createAsyncThunk(
  OFFENCE_INVIGILATION_CONSULTATIONS_CREATE,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}`, 'post'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationConsultationsCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationConsultationsAll = createAsyncThunk(
  OFFENCE_INVIGILATION_CONSULTATIONS_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS_ALL}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationConsultationsAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationConsultationsDelete = createAsyncThunk(
  OFFENCE_INVIGILATION_CONSULTATIONS_DELETE,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}{id}`, 'delete'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationConsultationsDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationMinutes = createAsyncThunk(
  OFFENCE_INVIGILATION_MINUTES,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_MINUTES}{id}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationMinutes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationMinutesCreate = createAsyncThunk(
  OFFENCE_INVIGILATION_MINUTES_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_MINUTES}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceInvigilationMinutesCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationMinutesAll = createAsyncThunk(
  OFFENCE_INVIGILATION_MINUTES_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_MINUTES_ALL}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationMinutesAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceInvigilationMinutesDelete = createAsyncThunk(
  OFFENCE_INVIGILATION_MINUTES_DELETE,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_INVIGILATION_MINUTES}{id}`, 'delete'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceInvigilationMinutesDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceType = createAsyncThunk(
  OFFENCE_TYPE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_TYPE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceType(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceChangeStatus = createAsyncThunk(
  OFFENCE_CHANGE_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_CHANGE_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceTypeCreate = createAsyncThunk(
  OFFENCE_TYPE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_TYPE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceTypeCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceTypeAll = createAsyncThunk(
  OFFENCE_TYPE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_TYPE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceTypeAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceTypeDelete = createAsyncThunk(
  OFFENCE_TYPE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_TYPE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceTypeDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
export const offencePublish = createAsyncThunk(
  OFFENCE_PUBLISH,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_PUBLISH}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.offencePublish(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
export const offenceUnpublish = createAsyncThunk(
  OFFENCE_UNPUBLISH,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_UNPUBLISH}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceUnpublish(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceReportList = createAsyncThunk(
  OFFENCE_REPORT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_REPORT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceReportList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceReportCreate = createAsyncThunk(
  OFFENCE_REPORT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_REPORT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceReportCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceReportAll = createAsyncThunk(
  OFFENCE_REPORT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.OFFENCE_REPORT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.offenceReportAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
export const offenceChangeSubjectBoardStatus = createAsyncThunk(
  OFFENCE_CHANGE_SUBJECT_BOARD_STATUS,
  async (
    params: PayloadCreatorParams<`${typeof API.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS}`, 'put'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.offenceChangeSubjectBoardStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const reportMisconduct = createAsyncThunk(
  REPORT_MISCONDUCT,
  async (params: PayloadCreatorParams<`${typeof API.REPORT_MISCONDUCT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.reportMisconduct(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const offenceAllClear = createAction(`${OFFENCE_ALL}/clear`)

export const offenceListClear = createAction(`${OFFENCE_LIST}/clear`)

export const offenceClear = createAction(`${OFFENCE}/clear`)

export const offenceInvigilationAllClear = createAction(`${OFFENCE_INVIGILATION_ALL}/clear`)

export const offenceInvigilationClear = createAction(`${OFFENCE_INVIGILATION}/clear`)

export const offenceInvigilationConsultationsAllClear = createAction(`${OFFENCE_INVIGILATION_CONSULTATIONS_ALL}/clear`)

export const offenceInvigilationConsultationsClear = createAction(`${OFFENCE_INVIGILATION_CONSULTATIONS}/clear`)

export const offenceInvigilationMinutesAllClear = createAction(`${OFFENCE_INVIGILATION_MINUTES_ALL}/clear`)

export const offenceTypeClear = createAction(`${OFFENCE_TYPE_ALL}/clear`)
