import React, { useState } from 'react'
import Uppy from '@uppy/core'
import { DragDrop } from '@uppy/react'
import thumbnailGenerator from '@uppy/thumbnail-generator'
import Form from '@uppy/form'

function FileUploader(props) {
  const { name } = props
  const [previewArr, setPreviewArr] = useState([])

  const uppy = Uppy().use(Form, { endpoint: 'https://tusd.tusdemo.net/files' })

  uppy.use(thumbnailGenerator)
  uppy.on('thumbnail:generated', (file, preview) => {
    const arr = previewArr
    arr.push(preview)
    setPreviewArr([...arr])
  })

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => <img key={index} className='rounded mt-2 mr-1' src={src} alt='avatar' />)
    } else {
      return null
    }
  }

  return (
    <>
      <DragDrop uppy={uppy} inputName={name} />
      {renderPreview()}
    </>
  )
}

export default FileUploader
