import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_ALL,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const courseCampaign = createAsyncThunk(
  COURSE_CAMPAIGN,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_CAMPAIGN}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseCampaign(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseCampaignCreate = createAsyncThunk(
  COURSE_CAMPAIGN_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_CAMPAIGN}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.courseCampaignCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseCampaignDelete = createAsyncThunk(
  COURSE_CAMPAIGN_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_CAMPAIGN}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.courseCampaignDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseCampaignList = createAsyncThunk(
  COURSE_CAMPAIGN_LIST,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_CAMPAIGN}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseCampaignList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseCampaignAll = createAsyncThunk(
  COURSE_CAMPAIGN_ALL,
  async (params: PayloadCreatorParams<`${typeof API.COURSE_CAMPAIGN_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.courseCampaignAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const courseCampaignClear = createAction(`${COURSE_CAMPAIGN}/clear`)

export const courseCampaignListClear = createAction(`${COURSE_CAMPAIGN_LIST}/clear`)

export const courseCampaignAllClear = createAction(`${COURSE_CAMPAIGN_ALL}/clear`)
