import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
  ATTENDANCE_TABLE_STUDENT,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ATTENDANCES = GLOBAL_STATE

export const { reducer: attendanceReducer } = createSlice({
  name: ATTENDANCES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.attendanceCreate.pending, state => {
        state[ATTENDANCE].loading = true
      })
      .addCase(actionCreators.attendanceCreate.rejected, (state, action) => {
        state[ATTENDANCE].loading = false
        state[ATTENDANCE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceCreate.fulfilled, (state, action) => {
        state[ATTENDANCE].loading = false
        state[ATTENDANCE].error = null
        state[ATTENDANCE].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceTableCsv.pending, state => {
        state[ATTENDANCE_CSV].loading = true
      })
      .addCase(actionCreators.attendanceTableCsv.rejected, (state, action) => {
        state[ATTENDANCE_CSV].loading = false
        state[ATTENDANCE_CSV].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceTableCsv.fulfilled, (state, action) => {
        state[ATTENDANCE_CSV].loading = false
        state[ATTENDANCE_CSV].error = null
        state[ATTENDANCE_CSV].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceGroup.pending, state => {
        state[ATTENDANCE_GROUP].loading = true
      })
      .addCase(actionCreators.attendanceGroup.rejected, (state, action) => {
        state[ATTENDANCE_GROUP].loading = false
        state[ATTENDANCE_GROUP].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceGroup.fulfilled, (state, action) => {
        state[ATTENDANCE_GROUP].loading = false
        state[ATTENDANCE_GROUP].error = null
        state[ATTENDANCE_GROUP].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceTable.pending, state => {
        state[ATTENDANCE_TABLE].loading = true
      })
      .addCase(actionCreators.attendanceTable.rejected, (state, action) => {
        state[ATTENDANCE_TABLE].loading = false
        state[ATTENDANCE_TABLE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceTable.fulfilled, (state, action) => {
        state[ATTENDANCE_TABLE].loading = false
        state[ATTENDANCE_TABLE].error = null
        state[ATTENDANCE_TABLE].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceTableModule.pending, state => {
        state[ATTENDANCE_TABLE_MODULE].loading = true
      })
      .addCase(actionCreators.attendanceTableModule.rejected, (state, action) => {
        state[ATTENDANCE_TABLE_MODULE].loading = false
        state[ATTENDANCE_TABLE_MODULE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceTableModule.fulfilled, (state, action) => {
        state[ATTENDANCE_TABLE_MODULE].loading = false
        state[ATTENDANCE_TABLE_MODULE].error = null
        state[ATTENDANCE_TABLE_MODULE].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceTableStudent.pending, state => {
        state[ATTENDANCE_TABLE_STUDENT].loading = true
      })
      .addCase(actionCreators.attendanceTableStudent.rejected, (state, action) => {
        state[ATTENDANCE_TABLE_STUDENT].loading = false
        state[ATTENDANCE_TABLE_STUDENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceTableStudent.fulfilled, (state, action) => {
        state[ATTENDANCE_TABLE_STUDENT].loading = false
        state[ATTENDANCE_TABLE_STUDENT].error = null
        state[ATTENDANCE_TABLE_STUDENT].data = action.payload
      })

    builder.addCase(actionCreators.attendanceGroupClear, state => {
      state[ATTENDANCE_GROUP] = defaultState()
    })

    builder.addCase(actionCreators.attendanceTableClear, state => {
      state[ATTENDANCE_TABLE] = defaultState()
    })

    builder.addCase(actionCreators.attendanceTableModuleClear, state => {
      state[ATTENDANCE_TABLE_MODULE] = defaultState()
    })

    builder.addCase(actionCreators.attendanceTableStudentClear, state => {
      state[ATTENDANCE_TABLE_STUDENT] = defaultState()
    })
  },
})
