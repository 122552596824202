import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const application: ApiFunc<`${typeof API.APPLICATION_FORM}{id}`, 'get'> = data =>
  instance.get(`${API.APPLICATION_FORM}${'id' in data ? data.id : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const applicationCreate: ApiFunc<`${typeof API.APPLICATION_FORM}`, 'post'> = data =>
  instance.post(API.APPLICATION_FORM, data)

export const applicationQrScan: ApiFunc<`${typeof API.APPLICATION_QR_SCAN}`, 'post'> = data =>
  instance.post(API.APPLICATION_QR_SCAN, data)

export const applicationDtmFile: ApiFunc<`${typeof API.APPLICATION_DTM_UPLOAD}`, 'post'> = data =>
  instance.post(API.APPLICATION_DTM_UPLOAD, data)

export const applicationDelete: ApiFunc<`${typeof API.APPLICATION_FORM}{id}`, 'delete'> = data =>
  instance.delete(`${API.APPLICATION_FORM}${'id' in data ? data.id : data?.name}`)

export const applicationList: ApiFunc<`${typeof API.APPLICATION}`, 'get'> = data =>
  instance.get(API.APPLICATION, {
    params: data,
  })

export const applicationAll: ApiFunc<`${typeof API.APPLICATION_ALL}`, 'get'> = data =>
  instance.get(API.APPLICATION_ALL, {
    params: data,
  })

export const applicationTypes: ApiFunc<`${typeof API.APPLICATION_TYPES}`, 'get'> = data =>
  instance.get(API.APPLICATION_TYPES, {
    params: data,
  })

export const applicationStatus: ApiFunc<`${typeof API.APPLICATION_STATUS}`, 'put'> = data =>
  instance.put(API.APPLICATION_STATUS, data)

export const applicationFieldBatchCreate: ApiFunc<`${typeof API.APPLICATION_FIELD_BATCH}`, 'post'> = data =>
  instance.post(API.APPLICATION_FIELD_BATCH, data)

export const applicationFieldBatch: ApiFunc<`${typeof API.APPLICATION_FIELD_BATCH}{id}`, 'get'> = data =>
  instance.get(`${API.APPLICATION_FIELD_BATCH}${'id' in data ? data.id : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

//request
export const applicationRequest: ApiFunc<`${typeof API.APPLICATION_REQUEST}`, 'post'> = data =>
  instance.post(API.APPLICATION_REQUEST, data)

export const applicationRequestAll: ApiFunc<`${typeof API.APPLICATION_REQUEST_ALL}`, 'get'> = data =>
  instance.get(API.APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const applicationRequestEdit: ApiFunc<`${typeof API.APPLICATION_REQUEST_EDIT}`, 'get'> = data =>
  instance.get(API.APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const applicationRequestUpdate: ApiFunc<`${typeof API.APPLICATION_REQUEST_UPDATE}`, 'put'> = data =>
  instance.put(API.APPLICATION_REQUEST_UPDATE, data)
