import { DefaultState } from '@src/redux/types'

import {
  ACHIEVEMENT_RECORDS,
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_DELETE,
  ACHIEVEMENT_RECORDS_LIST,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ACHIEVEMENT_RECORDS]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS}{id}`, 'get'>
  [ACHIEVEMENT_RECORDS_ALL]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS_ALL}`, 'get'>
  [ACHIEVEMENT_RECORDS_CHANGE_STATUS]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS_CHANGE_STATUS}`, 'put'>
  [ACHIEVEMENT_RECORDS_DELETE]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS}{id}`, 'delete'>
  [ACHIEVEMENT_RECORDS_CREATE]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS}`, 'post'>
  [ACHIEVEMENT_RECORDS_LIST]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS}`, 'get'>
  [ACHIEVEMENT_RECORDS_STUDENT]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS_STUDENT}{id}`, 'get'>
  [ACHIEVEMENT_RECORDS_TYPES]: DefaultState<`${typeof API.ACHIEVEMENT_RECORDS_TYPES}`, 'get'>
} = () => ({
  [ACHIEVEMENT_RECORDS]: defaultState(),
  [ACHIEVEMENT_RECORDS_ALL]: defaultState(),
  [ACHIEVEMENT_RECORDS_CHANGE_STATUS]: defaultState(),
  [ACHIEVEMENT_RECORDS_CREATE]: defaultState(),
  [ACHIEVEMENT_RECORDS_DELETE]: defaultState(),
  [ACHIEVEMENT_RECORDS_LIST]: defaultState(),
  [ACHIEVEMENT_RECORDS_STUDENT]: defaultState(),
  [ACHIEVEMENT_RECORDS_TYPES]: defaultState(),
})
