import classNames from 'classnames'
import PropTypes from 'prop-types'
import { equals, includes, isEmpty, pipe, prop, propEq, propOr, reject, toLower, type } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Trash } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { Button, Card, Col, FormGroup, FormText, Label, Media, Row, Spinner } from 'reactstrap'

import jpgIcon from '../../assets/images/icons/jpg.png'
import { API_SCHEME, API_URL } from '../../constants/api'
import { FILE_UPLOAD_REACT } from '../../constants/fileUpload'
import { getToken } from '../../helpers/storageToken'
import FileTypeIcon from '../FileTypeIcon'

function formatBytes(bytes, decimals) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const SmartFileUpload = React.forwardRef((props, ref) => {
  const {
    label,
    value,
    handleChange = () => {},
    onFileRemove = () => {},
    onChange,
    name,
    error,
    touched,
    header,
    subHeader,
    footer,
    single = false,
    row,
    disabled,
    accept,
    dropzoneProps = {},
    formGroupStyle = {},
    uploadCallback,
    onDropAccepted,
    shouldHandleUpload = true,
  } = props

  const [files, setFiles] = useState([])

  useEffect(() => {
    setFiles(value || [])
  }, [value])

  const [load, setLoad] = useState(false)

  function handleAcceptedFiles(values, callback) {
    try {
      setLoad(true)
      const formData = new FormData()
      const filesList = Array.from(values)
      filesList.forEach(file => formData.append('files', file))

      fetch(`${API_URL}${FILE_UPLOAD_REACT}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          institution: '1',
        },
      })
        .then(res => res.json())
        .then(response => {
          setLoad(false)
          if (uploadCallback) {
            return uploadCallback({ response, files, onChange, isFile: true })
          }
          if (callback) {
            return callback({ response, files, onChange })
          }
          const data = prop('data', response)
          if (equals(type(files), 'Array' || 'Object') && equals(type(data), 'Array' || 'Object')) {
            onChange([...files, ...data])
            handleChange([...files, ...data])
          }
        })
    } catch (error) {
      throw new Error(error)
    }
  }

  function handleFileDelete(file) {
    const src = prop('src', file)
    const newFiles = reject(propEq('src', src), value)
    onChange(newFiles)
    handleChange(newFiles)
    onFileRemove(newFiles)
  }

  function Files() {
    if (load) {
      return (
        <Row>
          <Col sm={12}>
            <div className='text-center mt-2'>
              <Spinner color='success' size='lg' />
            </div>
          </Col>
        </Row>
      )
    }

    return (
      <Row>
        {files.map((item, index) => {
          const name = prop('original_name', item) || prop('name', item)
          const size = propOr(0, 'file_size', item) || prop('size', item)
          // const mime_type = propOr('text/csv', 'mime_type', item)
          const url = prop('original_url', item)
          let extension = pipe(propOr('', 'extension'), toLower)(item) || prop('type', item)
          extension =
            extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ? 'xlsx'
              : extension === 'application/vnd.ms-excel'
              ? 'xls'
              : extension

          const iconColor = equals(extension, 'pdf')
            ? 'var(--primary)'
            : includes(extension, ['doc', 'docx'])
            ? '#3788d8'
            : includes(extension, ['ppt', 'pptx'])
            ? 'var(--orange)'
            : includes(extension, ['xls', 'xlsx'])
            ? 'var(--green)'
            : 'var(--gray-dark)'

          return (
            <Col key={index}>
              <Card className='p-2 border rounded mb-0 custom-file-upload-styles' style={{ boxShadow: 'none' }}>
                <Media>
                  <div className='avatar-md font-weight-bold mr-1'>
                    <span
                      className='avatar-title rounded bg-soft-primary text-primary'
                      style={{ minWidth: '3.5rem', minHeight: '3.5rem' }}
                    >
                      {includes(extension, ['png', 'jpeg', 'jpg', 'bmp']) ? (
                        <Media left href='#'>
                          <Media object src={url} alt='Image' />
                        </Media>
                      ) : (
                        <a
                          href={url}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ cursor: url ? 'pointer' : 'initial' }}
                        >
                          <FileTypeIcon color={iconColor} type={extension} />
                        </a>
                      )}
                    </span>
                  </div>

                  <Media body>
                    <a
                      role='button'
                      className='d-inline-block text-muted font-weight-bold'
                      href={url}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ textOverflow: 'ellipsis', cursor: url ? 'pointer' : 'initial' }}
                    >
                      {name && name.length > 30 ? `${name?.slice(0, 30)}...` : name}
                    </a>
                    <div className='font-size-11'>{formatBytes(size)}</div>
                  </Media>

                  {!disabled && (
                    <div className='float-right'>
                      <a role='button' className='d-inline-block text-primary' onClick={() => handleFileDelete(item)}>
                        <Button.Ripple className='btn-icon rounded' color='danger'>
                          <Trash size={16} />
                        </Button.Ripple>
                      </a>
                    </div>
                  )}
                </Media>
              </Card>
            </Col>
          )
        })}
      </Row>
    )
  }

  function Upload() {
    if (single) {
      if (value && !isEmpty(value)) {
        return null
      }

      return (
        <div>
          <Dropzone
            accept={accept}
            // getFilesFromEvent={async e => {
            //   console.log('getFilesFromEvent', e)
            //   const files = []
            //   const fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files
            //   fileList.forEach(file => {
            //     files.push(file)
            //   })
            //   return files
            // }}
            onDropAccepted={(acceptedFiles, event) => {
              if (!shouldHandleUpload) {
                onChange(acceptedFiles)
                handleChange(acceptedFiles, event)
              } else {
                if (onDropAccepted) {
                  onDropAccepted({ files: acceptedFiles, handleAcceptedFiles, event })
                } else {
                  handleAcceptedFiles(acceptedFiles)
                }
              }
            }}
            {...dropzoneProps}
          >
            {({ getRootProps, getInputProps, isDragReject, isDragAccept }) => (
              <div
                className={classNames(
                  error || isDragReject ? 'dropzone-error text-center layout-bg' : 'dropzone text-center layout-bg'
                )}
                style={{
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  ...(isDragAccept
                    ? { border: '1px dashed #28c76f' }
                    : isDragReject
                    ? { border: '1px dashed #dc3545' }
                    : { border: '1px dashed #3788d8' }),
                }}
              >
                <div className='dz-message pt-1 needsclick' style={{ paddingBottom: '8rem' }} {...getRootProps()}>
                  <input {...getInputProps()} name={name} />
                  <i className='h3 text-muted uil-cloud-upload' />
                  {isDragReject ? (
                    <h5 className='text-danger'>Please, select accepted files</h5>
                  ) : isDragAccept ? (
                    <h5 className='text-success'>Drop here...</h5>
                  ) : (
                    header
                  )}
                  {subHeader}
                </div>
                {footer ? (equals(type(footer), 'Function') ? footer({ files, onChange }) : footer) : null}
              </div>
            )}
          </Dropzone>

          {error && (
            <FormText color='primary'>
              <FormattedMessage id='form_required' />
            </FormText>
          )}
        </div>
      )
    }

    return (
      <Fragment>
        <Dropzone
          accept={accept}
          onDropAccepted={(acceptedFiles, event) => {
            if (!shouldHandleUpload) {
              onChange(acceptedFiles)
              handleChange(acceptedFiles, event)
            } else {
              if (onDropAccepted) {
                onDropAccepted({ files: acceptedFiles, handleAcceptedFiles, event })
              } else {
                handleAcceptedFiles(acceptedFiles)
              }
            }
          }}
          {...dropzoneProps}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept }) => {
            return (
              <div
                className={classNames(
                  error || isDragReject ? 'dropzone-error text-center layout-bg' : 'dropzone text-center layout-bg'
                )}
                style={{
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  ...(isDragAccept
                    ? { border: '1px dashed #28c76f' }
                    : isDragReject
                    ? { border: '1px dashed #dc3545' }
                    : { border: '1px dashed #3788d8' }),
                }}
                {...getRootProps()}
              >
                <div
                  className='card-bg'
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: '1rem',
                    borderRadius: '6px',
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  {files.map((item, index) => {
                    const title = propOr('', 'title', item)
                    return (
                      <div
                        key={`file-${index}`}
                        style={{
                          margin: '0.5rem 0.5rem 0.5rem 0.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          width: '100px',
                        }}
                      >
                        <div
                          style={{
                            width: '4rem',
                            height: '3rem',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${jpgIcon})`,
                          }}
                        >
                          {/*<img src={jpgIcon} alt='File' height='30' />*/}
                        </div>
                        <small>{title && title.length > 11 ? `${title?.slice(0, 13)}...` : title}</small>
                      </div>
                    )
                  })}
                </div>
                <div className='dz-message pt-1 needsclick' style={{ paddingBottom: '8rem' }}>
                  <input {...getInputProps()} name={name} />
                  <i className='h3 text-muted uil-cloud-upload' />
                  {isDragReject ? (
                    <h5 className='text-danger'>Please, select accepted files</h5>
                  ) : isDragAccept ? (
                    <h5 className='text-success'>Drop here...</h5>
                  ) : (
                    header
                  )}
                  {subHeader}
                </div>
                {footer ? (equals(type(footer), 'Function') ? footer({ files, onChange }) : footer) : null}
              </div>
            )
          }}
        </Dropzone>

        {(touched || error) && (
          <FormText color='primary'>
            <FormattedMessage id='form_required' />
          </FormText>
        )}
      </Fragment>
    )
  }

  return (
    <FormGroup row={Boolean(row)} style={formGroupStyle}>
      {label && <Label>{label}</Label>}

      <Upload />
      <Files />
    </FormGroup>
  )
})

SmartFileUpload.defaultProps = {
  header: <h5>Drag files here or select</h5>,
  // subHeader: <span className='text-muted font-13'>Selected files are actually uploaded.</span>,
}

SmartFileUpload.propTypes = {
  label: PropTypes.string,
  single: PropTypes.bool,
  header: PropTypes.element,
  subHeader: PropTypes.element,
  single: PropTypes.bool,
  row: PropTypes.shape({
    label: PropTypes.number,
    input: PropTypes.number,
  }),
}

export default SmartFileUpload
