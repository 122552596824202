import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const attendance: ApiFunc<`${typeof API.ATTENDANCE}`, 'post'> = data => instance.post(API.ATTENDANCE, data)

export const attendanceCsv: ApiFunc<`${typeof API.ATTENDANCE_CSV}`, 'post'> = data =>
  instance.post(API.ATTENDANCE_CSV, data)

export const attendanceGroup: ApiFunc<`${typeof API.ATTENDANCE_GROUP}`, 'get'> = data =>
  instance.get(API.ATTENDANCE_GROUP, {
    params: data,
  })

export const attendanceTable: ApiFunc<`${typeof API.ATTENDANCE_TABLE}`, 'get'> = data =>
  instance.get(API.ATTENDANCE_TABLE, {
    params: data,
  })

export const attendanceTableStudent: ApiFunc<`${typeof API.ATTENDANCE_TABLE_STUDENT}`, 'get'> = data =>
  instance.get(API.ATTENDANCE_TABLE_STUDENT, {
    params: data,
  })

export const attendanceTableModule: ApiFunc<`${typeof API.ATTENDANCE_TABLE_MODULE}`, 'get'> = data =>
  instance.get(API.ATTENDANCE_TABLE_MODULE, {
    params: data,
  })
