import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const cclsModule: ApiFunc<`${typeof API.CCLS_MODULE}{id}`, 'get'> = data =>
  instance.get(`${API.CCLS_MODULE}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const modulesFilter: ApiFunc<`${typeof API.CCLS_MODULE}`, 'get'> = data =>
  instance.get(API.CCLS_MODULE, {
    params: data,
  })

export const cclsModuleCreate: ApiFunc<`${typeof API.CCLS_MODULE}`, 'post'> = data =>
  instance.post(API.CCLS_MODULE, data)

export const cclsModuleUpdate: ApiFunc<`${typeof API.CCLS_MODULE}`, 'post'> = data =>
  instance.post(`${API.CCLS_MODULE}${'id' in data ? prop('id', data) : ''}`, data)

export const cclsModuleTeacherUpdate: ApiFunc<`${typeof API.CCLS_MODULE_TEACHER}`, 'post'> = data =>
  instance.post(API.CCLS_MODULE_TEACHER, data)

export const cclsModuleDelete: ApiFunc<`${typeof API.CCLS_MODULE}{id}`, 'delete'> = data =>
  instance.delete(`${API.CCLS_MODULE}${'id' in data ? prop('id', data) : data?.name}`)

export const cclsModuleList: ApiFunc<`${typeof API.CCLS_MODULE}`, 'get'> = data =>
  instance.get(API.CCLS_MODULE, {
    params: data,
  })

export const cclsModuleAll: ApiFunc<`${typeof API.CCLS_MODULE_ALL}`, 'get'> = data =>
  instance.get(API.CCLS_MODULE_ALL, {
    params: data,
  })

export const cclsModuleArticulate: ApiFunc<`${typeof API.CCLS_MODULE_ARTICULATE}`, 'get'> = data =>
  instance.get(API.CCLS_MODULE_ARTICULATE, {
    params: data,
  })

export const cclsModuleArticulateAll: ApiFunc<`${typeof API.CCLS_MODULE_ARTICULATE_ALL}`, 'get'> = data =>
  instance.get(API.CCLS_MODULE_ARTICULATE_ALL, {
    params: data,
  })

export const cclsModuleRetakeUpload: ApiFunc<`${typeof API.CCLS_MODULE_RETAKE_UPLOAD}`, 'post'> = data =>
  instance.post(API.CCLS_MODULE_RETAKE_UPLOAD, data)
