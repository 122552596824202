import { ADMIN, BACHELOR_STUDENT, SUPER_ADMIN, SYSTEM_MODERATOR } from '@constants/userRoles'
import { lazy } from 'react'

import { ARTICULATE, ARTICULATE_CALLBACK, ARTICULATE_LESSON, ARTICULATE_MODULE } from '../constants/route'

export const Articulate = [
  {
    path: ARTICULATE,
    component: lazy(() => import('../views/ArticulateModules')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ADMIN, BACHELOR_STUDENT, SYSTEM_MODERATOR],
    },
  },
  {
    path: ARTICULATE_MODULE,
    component: lazy(() => import('../views/ArticulateModule')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ADMIN, BACHELOR_STUDENT, SYSTEM_MODERATOR],
    },
  },
  {
    path: ARTICULATE_LESSON,
    component: lazy(() => import('../views/ArticulateLesson')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ADMIN, BACHELOR_STUDENT, SYSTEM_MODERATOR],
    },
  },
  {
    path: ARTICULATE_CALLBACK,
    component: lazy(() => import('../views/ArticulateLessonCallback')),
    exact: true,
    meta: {
      isPublic: true,
    },
  },
]
