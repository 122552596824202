import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, STATE, STATE_ALL, STATE_CREATE, STATE_LIST } from '../constants/actionTypes'

const countryStateSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const stateSelector = createSelector(countryStateSelectors, prop(STATE))

export const stateListSelector = createSelector(countryStateSelectors, prop(STATE_LIST))

export const stateAllSelector = createSelector(countryStateSelectors, prop(STATE_ALL))

export const stateCreateSelector = createSelector(countryStateSelectors, prop(STATE_CREATE))
