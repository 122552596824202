import { RootState } from '@src/redux/store'
import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import * as actionTypes from '../constants/actionTypes'

const studentGlobalSelector = (state: RootState) => prop(actionTypes.GLOBAL_STATE, state)

export const studentAchievementsSelector = createSelector(
  studentGlobalSelector,
  prop(actionTypes.STUDENT_ACHIEVEMENT_RECORDS)
)

export const studentAllSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_ALL))

export const studentSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT))

export const studentDocumentSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_DOCUMENT))

export const studentProfileSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_PROFILE))

export const studentProfileUpdateSelector = createSelector(
  studentGlobalSelector,
  prop(actionTypes.STUDENT_PROFILE_UPDATE)
)

export const studentListSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_LIST))

export const studentGuardListSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_GUARD_LIST))

export const studentUsernameSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_USERNAME))

export const generateTranscriptSelector = createSelector(studentGlobalSelector, prop(actionTypes.GENERATE_TRANSCRIPT))

export const studentPersonalInfoUpdate = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_PERSONAL_INFO))

export const studentContactInfoUpdate = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_CONTACT_INFO))

export const studentSendSmsSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_SEND_SMS))

export const studentExportDataSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_EXPORT_DATA))

export const studentTransferSelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_TRANSFER))

export const studentResumeStudySelector = createSelector(studentGlobalSelector, prop(actionTypes.STUDENT_RESUME_STUDY))

export const studentDebitCreateSelector = createSelector(
  studentGlobalSelector,
  prop(actionTypes.STUDENT_DEBIT_DETAIL_CREATE)
)

export const studentDebitDeleteSelector = createSelector(
  studentGlobalSelector,
  prop(actionTypes.STUDENT_DEBIT_DETAIL_DELETE)
)

export const studentCallLetterDeleteSelector = createSelector(
  studentGlobalSelector,
  prop(actionTypes.STUDENT_CALL_LETTER_DELETE)
)

export const studentAllOptionsSelector = createSelector(studentAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({
          label: `${propOr('', 'lastname', item)} ${propOr('', 'firstname', item)} ${propOr('', 'midname', item)}`,
          value: item.id,
        }))
      : [],
  }
})
