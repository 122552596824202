import { lazy } from 'react'
import { ASSESSMENT_OFFENSE, ASSESSMENT_REPORT } from '../constants/route'
import {
  MODERATOR_VIEWER,
  MODULE_LEADER,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  SUPER_ADMIN,
} from '@constants/userRoles'

export const OffenceRoutes = [
  {
    path: ASSESSMENT_OFFENSE,
    component: lazy(() => import('../views/Offence')),
    meta: {
      access: [SUPER_ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, MODERATOR_VIEWER, MODULE_LEADER],
    },
  },
  {
    path: ASSESSMENT_REPORT,
    component: lazy(() => import('../views/ReportMisconduct')),
    meta: {
      access: [SUPER_ADMIN, MODULE_LEADER, MODERATOR_VIEWER],
    },
  },
]
