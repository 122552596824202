import { propOr } from 'ramda'
import React, { useEffect, useRef } from 'react'
import { components } from 'react-select'
import { getElementViewportInfo } from '../../../../helpers/getElementViewportInfo'

const Menu = props => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      const menuElement = propOr({}, 'current', ref)
      const menuChild = menuElement.firstChild
      const vpInfo = getElementViewportInfo(menuElement)
      const menuChildVpInfo = getElementViewportInfo(menuElement.firstChild)
      const menuElementRect = menuElement.getBoundingClientRect()

      // if (menuElement.scrollWidth < menuChild.scrollWidth) {
      //   menuElement.style.width = `${menuChild.scrollWidth}px`
      // }
      if (vpInfo.isPartiallyInViewport) {
        if (vpInfo.isOnBottomEdge) {
          menuElement.style.top = 'auto'
          menuElement.style.bottom = '100%'
        }
        if (vpInfo.isOnLeftEdge) {
          menuElement.style.left = '0'
        }
        if (vpInfo.isOnRightEdge) {
          menuElement.style.right = '0'
        }
      }
    }
  }, [ref.current])

  return (
    <components.Menu {...props} innerRef={ref}>
      {props.children}
    </components.Menu>
  )
}

export default Menu
