import { MyMessageDescriptor } from '@src/types/react-intl'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import React from 'react'
import { useIntl as useIntlOrigin } from 'react-intl'

declare module 'react-intl' {
  interface IntlFormatters {
    formatMessage(descriptor: MyMessageDescriptor, values?: Record<string, PrimitiveType>): string
    formatMessage(
      descriptor: MyMessageDescriptor,
      values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>
    ): string | React.ReactNodeArray
  }
}
export function useIntl() {
  return useIntlOrigin()
}
