import { path, prop } from 'ramda'
import { Routes } from './routes'

export function ResolvePublicRoutes(Layouts, SentryRoute) {
  return Routes.map(route => {
    const isPublic = path(['meta', 'isPublic'], route)
    if (isPublic) {
      const Component = prop('component', route)
      const path = prop('path', route)
      const isExact = prop('exact', route)
      return (
        <SentryRoute
          key={path}
          exact={isExact}
          path={path}
          render={props => {
            return (
              <Layouts.BlankLayout>
                <Component {...props} />
              </Layouts.BlankLayout>
            )
          }}
        />
      )
    } else return null
  })
}
