import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_LIST,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const assessmentECSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const assessmentECUpdateSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC_UPDATE))

export const assessmentECItemSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC))

export const assessmentECCreateSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC_CREATE))

export const assessmentECAllSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC_ALL))

export const assessmentECListSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC_LIST))

export const assessmentECPaperSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC_PAPER))

export const assessmentECStatusSelector = createSelector(assessmentECSelectors, prop(ASSESSMENT_EC_STATUS))

export const assessmentECOutcomeReportSelector = createSelector(
  assessmentECSelectors,
  prop(ASSESSMENT_EC_OUTCOME_REPORT)
)
