import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const jobPositionList: ApiFunc<`${typeof API.JOB_POSITION}`, 'get'> = data =>
  instance.get(API.JOB_POSITION, {
    params: data,
  })

export const jobPositionCreate: ApiFunc<`${typeof API.JOB_POSITION}`, 'post'> = data =>
  instance.post(API.JOB_POSITION, data)

export const jobPositionDelete: ApiFunc<`${typeof API.JOB_POSITION}{id}`, 'delete'> = data =>
  instance.delete(`${API.JOB_POSITION}${'id' in data ? prop('id', data) : data?.name}`)

export const jobPosition: ApiFunc<`${typeof API.JOB_POSITION}{id}`, 'get'> = data =>
  instance.get(`${API.JOB_POSITION}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const jobPositionAll: ApiFunc<`${typeof API.JOB_POSITION_ALL}`, 'get'> = data =>
  instance.get(API.JOB_POSITION_ALL, {
    params: data,
  })

export const jobPositionActivate: ApiFunc<`${typeof API.JOB_POSITION_ACTIVATE}{id}`, 'put'> = data =>
  instance.put(`${API.JOB_POSITION_ACTIVATE}${'id' in data ? prop('id', data) : data?.name}`)
