export const ASSESSMENT = '/assessment/' as const
export const ASSESSMENT_BULK = `${ASSESSMENT}bulk` as const
export const ASSESSMENT_ATTEMPT = `${ASSESSMENT}attempt/` as const
export const ASSESSMENT_RETAKE = `${ASSESSMENT}retake` as const
export const ASSESSMENT_COPY = `${ASSESSMENT}copy/` as const
export const ASSESSMENT_SUBMIT = `${ASSESSMENT}submit` as const
export const ASSESSMENT_STUDENT_UPLOAD = `${ASSESSMENT}homework` as const
export const ASSESSMENT_CRITERIA = `${ASSESSMENT}criteria` as const
export const ASSESSMENT_CRITERIA_TYPES = `${ASSESSMENT_CRITERIA}/types` as const
export const ASSESSMENT_CRITERIA_LIST = `${ASSESSMENT_CRITERIA}/list` as const
export const ASSESSMENT_STUDENT = `${ASSESSMENT}all` as const
export const ASSESSMENT_STUDENT_UPDATED = `${ASSESSMENT_STUDENT}/updated` as const
export const ASSESSMENT_COMPONENT = `${ASSESSMENT}component/` as const
export const ASSESSMENT_STUDENT_CRITERIA_DETAILS = `${ASSESSMENT}student/criteria-details` as const
export const ASSESSMENT_OVERALL_GRADE_HISTORY = `${ASSESSMENT}overall-grade-history` as const
