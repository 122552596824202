import * as roles from '@constants/userRoles'
import { lazy } from 'react'

import { CCLS_MODULE } from '../constants/route'

export const CCLSModuleRoutes = [
  {
    path: CCLS_MODULE,
    component: lazy(() => import('../views/CCLSModule')),
    meta: {
      access: [
        roles.SUPER_ADMIN,
        roles.ADMIN,
        roles.QUALITY_ASSURANCE,
        roles.QUALITY_ASSURANCE_HEAD,
        roles.MODERATOR_VIEWER,
        roles.REGISTRAR_HEAD,
        roles.REGISTRAR_OFFICER,
        roles.REGISTRAR_HELPER,
        roles.COURSE_LEADER,
        roles.ADMIN_VIEWER,
      ],
    },
  },
]
