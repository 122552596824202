import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, SHORT_COURSE_REGISTRATION_CREATE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { initialState } from './initialState'

export const SHORT_COURSE_REGISTRATION = GLOBAL_STATE

export const { reducer: shortCourseRegistrationReducer } = createSlice({
  name: SHORT_COURSE_REGISTRATION,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.shortCourseRegistrationCreate.pending, state => {
        state[SHORT_COURSE_REGISTRATION_CREATE].loading = true
      })
      .addCase(actionCreators.shortCourseRegistrationCreate.rejected, (state, action) => {
        state[SHORT_COURSE_REGISTRATION_CREATE].loading = false
        state[SHORT_COURSE_REGISTRATION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.shortCourseRegistrationCreate.fulfilled, (state, action) => {
        state[SHORT_COURSE_REGISTRATION_CREATE].loading = false
        state[SHORT_COURSE_REGISTRATION_CREATE].error = null
        state[SHORT_COURSE_REGISTRATION_CREATE].data = action.payload
      })
  },
})
