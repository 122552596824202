import { path, pathOr, propOr } from 'ramda'
import { useRef } from 'react'
import { AlignJustify } from 'react-feather'
import { components } from 'react-select'
import { UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'

const SelectAllIndicatorWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #ededed;
  display: flex;

  padding: 8;
  transition: color 150ms;
  :hover {
    color: hsl(0, 0%, 60%);
  }
`

const IndicatorsContainer = props => {
  const isMulti = path(['selectProps', 'isMulti'], props)
  const values = isMulti ? pathOr([], ['selectProps', 'value'], props) : []
  const options = propOr([], 'options', props)
  const indicatorRef = useRef(null)
  const showSelectAll = isMulti && options.length > 0 && options.length > values.length
  return (
    <div style={{ display: 'flex' }}>
      <components.IndicatorsContainer {...props}>
        {showSelectAll ? (
          <>
            <SelectAllIndicatorWrapper ref={indicatorRef} onMouseDown={e => setAllValues(e, props)}>
              <AlignJustify size={16} />
            </SelectAllIndicatorWrapper>
            <UncontrolledTooltip target={indicatorRef}>Select all options</UncontrolledTooltip>
          </>
        ) : null}
        {props.children}
      </components.IndicatorsContainer>
    </div>
  )
}

function setAllValues(e, props) {
  props?.setValue(props?.options)
  e.preventDefault()
  e.stopPropagation()
}

export default IndicatorsContainer
