import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  COUNTRY,
  COUNTRY_ALL,
  COUNTRY_CREATE,
  COUNTRY_DELETE,
  COUNTRY_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const COUNTRYS = GLOBAL_STATE

export const { reducer: countryReducer } = createSlice({
  name: COUNTRYS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.country.pending, state => {
        state[COUNTRY].loading = true
      })
      .addCase(actionCreators.country.rejected, (state, action) => {
        state[COUNTRY].loading = false
        state[COUNTRY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.country.fulfilled, (state, action) => {
        state[COUNTRY].loading = false
        state[COUNTRY].error = null
        state[COUNTRY].data = action.payload
      })

    builder
      .addCase(actionCreators.countryAll.pending, state => {
        state[COUNTRY_ALL].loading = true
      })
      .addCase(actionCreators.countryAll.rejected, (state, action) => {
        state[COUNTRY_ALL].loading = false
        state[COUNTRY_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.countryAll.fulfilled, (state, action) => {
        state[COUNTRY_ALL].loading = false
        state[COUNTRY_ALL].error = null
        state[COUNTRY_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.countryCreate.pending, state => {
        state[COUNTRY_CREATE].loading = true
      })
      .addCase(actionCreators.countryCreate.rejected, (state, action) => {
        state[COUNTRY_CREATE].loading = false
        state[COUNTRY_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.countryCreate.fulfilled, (state, action) => {
        state[COUNTRY_CREATE].loading = false
        state[COUNTRY_CREATE].error = null
        state[COUNTRY_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.countryDelete.pending, state => {
        state[COUNTRY_DELETE].loading = true
      })
      .addCase(actionCreators.countryDelete.rejected, (state, action) => {
        state[COUNTRY_DELETE].loading = false
        state[COUNTRY_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.countryDelete.fulfilled, (state, action) => {
        state[COUNTRY_DELETE].loading = false
        state[COUNTRY_DELETE].error = null
        state[COUNTRY_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.countryList.pending, state => {
        state[COUNTRY_LIST].loading = true
      })
      .addCase(actionCreators.countryList.rejected, (state, action) => {
        state[COUNTRY_LIST].loading = false
        state[COUNTRY_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.countryList.fulfilled, (state, action) => {
        state[COUNTRY_LIST].loading = false
        state[COUNTRY_LIST].error = null
        state[COUNTRY_LIST].data = action.payload
      })

    builder.addCase(actionCreators.countryClear, state => {
      state[COUNTRY] = defaultState()
    })

    builder.addCase(actionCreators.countryAllClear, state => {
      state[COUNTRY_ALL] = defaultState()
    })

    builder.addCase(actionCreators.countryListClear, state => {
      state[COUNTRY_LIST] = defaultState()
    })
  },
})
