import { PageLoader } from '@components/Loaders'
import { languages } from '@constants/types'
import { defaultOptionCreator } from '@helpers/defaultOptionCreator'
import { removeToken, removeUserInfo } from '@helpers/storageToken'
import { removeEduForm } from '@src/helpers/storageEduForm'
import { removeInstitution, removeInstitutionOption } from '@src/helpers/storageInstitution'
import { removeLanguage, removeLanguageOption } from '@src/helpers/storageLanguage'
import { clearStorageMenu } from '@src/helpers/storageLayout'
import { useAppDispatch, useAppSelector, useConfirmText, useUniversalModal } from '@src/hooks'
import { UserSettingsContext } from '@src/utility/context/UserSettings'
import * as actions from '@store/actions'
import * as selectors from '@store/selector'
import { head, map, path, prop, propEq, propOr } from 'ramda'
import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { IntlContext, useIntl } from 'react-intl'
import { useWindowSize } from 'react-use'
import { Card, CardBody, Col, Row } from 'reactstrap'

import EnrollmentForm from '../components/forms/EnrollmentForm'
import EnrollmentInfo from '../components/modals/EnrollmentInfoModal'

function EnrollmentPage() {
  const dispatch = useAppDispatch()
  const { formatMessage: msg } = useIntl()
  const { locale } = useContext(IntlContext)

  const { MySwal, handleConfirmText } = useConfirmText()
  const [infoModal, openInfoModal, closeInfoModal] = useUniversalModal()
  const { width } = useWindowSize()
  const { setEduForm, setInstitution } = useContext(UserSettingsContext)

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })
  const { data: student, loading: studentLoading } = useAppSelector(selectors.studentUsernameSelector)
  const { data: enrollmentCourses, loading: enrollmentCoursesLoading } = useAppSelector(
    selectors.enrollmentCoursesSelector
  )
  const userInfo = useAppSelector(selectors.authUserInfo)
  const { loading: enrollmentCreateLoading } = useAppSelector(selectors.enrollmentCreateSelector)
  const username = prop('username', userInfo)
  const userId = prop('id', userInfo)

  useEffect(() => {
    dispatch(actions.enrollmentCourses())
    userId &&
      dispatch(
        actions.studentUsername({
          username,
        })
      )
  }, [])

  const languageOptions = useMemo(
    () => [
      { label: msg({ id: 'Uzbek' }), value: languages.UZBEK },
      { label: msg({ id: 'Russian' }), value: languages.RUSSIAN },
      { label: msg({ id: 'English' }), value: languages.ENGLISH },
      { label: msg({ id: 'Other' }), value: languages.OTHER },
    ],
    [locale]
  )

  const genderOptions = useMemo(
    () => [
      { value: 'MALE', label: msg({ id: 'Male' }) },
      { value: 'FEMALE', label: msg({ id: 'Female' }) },
    ],
    [locale]
  )

  const firstname = prop('firstname', student)
  const lastname = prop('lastname', student)
  const midname = prop('midname', student)
  const permanentAddress = prop('permanent_address', student)
  const currentAddress = prop('current_address', student)
  const birthdate = prop('birthdate', student)
  const nativeLanguage = prop('native_language', student)
  const gender = prop('gender', student)
  const passportId = prop('passport_id', student)
  const guards = propOr([], 'guards', student)
  const guard = head(guards) || undefined
  const contactPhone = propOr('', 'contact_phone', student)
  const homePhone = propOr('', 'home_phone', student)
  const addPhone1 = propOr('', 'additional_phone1', student)
  const addPhone2 = propOr('', 'additional_phone2', student)
  const guardMobilePhone = propOr('', 'contact_phone', guard)
  const guardHomePhone = propOr('', 'home_phone', guard)
  const guardAddress = propOr('', 'current_address', guard)
  const guardEmail = propOr('', 'email', guard)
  const contactEmail = prop('email', student)
  const courseOptions = map(
    item => ({
      value: prop('id', item),
      label: prop('title', item),
    }),
    enrollmentCourses || []
  )

  const initialValues = {
    email: contactEmail,
    permanent_address: permanentAddress,
    contact_phone: contactPhone,
    home_phone: homePhone,
    current_address: currentAddress,
    native_language: defaultOptionCreator(nativeLanguage, languageOptions),
    course_id: courseOptions.length === 1 ? head(courseOptions) : [],
    additional_phone1: addPhone1,
    additional_phone2: addPhone2,
    guard_mobile_phone: guardMobilePhone,
    guard_home_phone: guardHomePhone,
    guard_address: guardAddress,
    guard_email: guardEmail,
    gender: genderOptions.find(option => propEq('value', gender, option)),
    birthdate,
  }

  useEffect(() => {
    reset({
      ...getValues(),
      ...initialValues,
    })
  }, [student, enrollmentCourses])

  function onSubmit(data) {
    return handleConfirmText(({ res, success }) => {
      if (prop('isConfirmed', res)) {
        dispatch(
          actions.enrollmentCreate({
            ...data,
            gender: path(['gender', 'value'], data),
            course_id: path(['course_id', 'value'], data),
            native_language: path(['native_language', 'value'], data),
            openModal: openInfoModal,
            onRejected(err) {
              MySwal.fire({
                title: 'Error!',
                text: err,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            },
          })
        )
      }
    })
  }

  function handleCancel() {
    removeToken()
    removeUserInfo()
    removeInstitution()
    removeLanguage()
    removeEduForm()
    removeInstitutionOption()
    removeLanguageOption()
    dispatch(actions.authLogout())
    clearStorageMenu()
  }

  function proceed() {
    dispatch(actions.authUserInfo())
      .unwrap()
      .then(res => {
        setInstitution(res.user_info.selected_institution)
        setEduForm(res.user_info.selected_education_form)
      })
  }

  return (
    <>
      <Row>
        <Col className='col-12'>
          <h2 className='text-center'>{msg({ id: 'enrollment' })}</h2>
        </Col>
        <Col sm={12}>
          <Card className={` ${width > 768 ? 'ml-auto mr-auto w-75' : ''}`}>
            <CardBody>
              <Row className={`w-100 ${width < 578 ? 'd-block' : ''}`}>
                <Col className='col-sm-12 mb-4'>
                  <h4>{msg({ id: 'personalInfo' })}</h4>
                </Col>
                <Col className='col-sm-12 col-md-4'>
                  <ul className='list-group'>
                    <li className='list-group-item'>
                      <span className='font-weight-bold'>{msg({ id: 'firstname' })}: </span>
                      {firstname}
                    </li>
                    <li className='list-group-item'>
                      <span className='font-weight-bold'>{msg({ id: 'lastname' })}: </span>
                      {lastname}
                    </li>
                  </ul>
                </Col>

                <Col className='col-sm-12 col-md-4'>
                  <ul className='list-group'>
                    <li className='list-group-item'>
                      <span className='font-weight-bold'>{msg({ id: 'midname' })}: </span>
                      {midname}
                    </li>
                    <li className='list-group-item'>
                      <span className='font-weight-bold'>{msg({ id: 'passportID' })}: </span>
                      {passportId}
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <PageLoader loading={studentLoading}>
            <EnrollmentForm
              handleSubmit={handleSubmit}
              register={register}
              control={control}
              onSubmit={onSubmit}
              guards={guards}
              errors={errors}
              enrollmentCreateLoading={enrollmentCreateLoading}
              handleCancel={handleCancel}
              languageOptions={languageOptions}
              courseOptions={courseOptions}
              enrollmentCoursesLoading={enrollmentCoursesLoading}
              genderOptions={genderOptions}
            />
          </PageLoader>
        </Col>
      </Row>

      <EnrollmentInfo isOpen={infoModal.state} item={infoModal.item} close={closeInfoModal} proceed={proceed} />
    </>
  )
}

export default EnrollmentPage
