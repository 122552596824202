import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import * as actionTypes from '../constants/actionTypes'
import * as actions from './actions'
import { defaultState, initialState } from './initialState'

const studentSlice = createSlice({
  name: actionTypes.GLOBAL_STATE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actions.generateTranscript.pending, state => {
        state[actionTypes.GENERATE_TRANSCRIPT].loading = true
      })
      .addCase(actions.generateTranscript.rejected, (state, action) => {
        state[actionTypes.GENERATE_TRANSCRIPT].loading = false
        state[actionTypes.GENERATE_TRANSCRIPT].error = action.payload as AxiosError
      })
      .addCase(actions.generateTranscript.fulfilled, (state, action) => {
        state[actionTypes.GENERATE_TRANSCRIPT].loading = false
        state[actionTypes.GENERATE_TRANSCRIPT].error = null
        state[actionTypes.GENERATE_TRANSCRIPT].data = action.payload
      })

    builder
      .addCase(actions.student.pending, state => {
        state[actionTypes.STUDENT].loading = true
      })
      .addCase(actions.student.rejected, (state, action) => {
        state[actionTypes.STUDENT].loading = false
        state[actionTypes.STUDENT].error = action.payload as AxiosError
      })
      .addCase(actions.student.fulfilled, (state, action) => {
        state[actionTypes.STUDENT].loading = false
        state[actionTypes.STUDENT].error = null
        state[actionTypes.STUDENT].data = action.payload
      })

    builder
      .addCase(actions.studentProfile.pending, state => {
        state[actionTypes.STUDENT_PROFILE].loading = true
      })
      .addCase(actions.studentProfile.rejected, (state, action) => {
        state[actionTypes.STUDENT_PROFILE].loading = false
        state[actionTypes.STUDENT_PROFILE].error = action.payload as AxiosError
      })
      .addCase(actions.studentProfile.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_PROFILE].loading = false
        state[actionTypes.STUDENT_PROFILE].error = null
        state[actionTypes.STUDENT_PROFILE].data = action.payload
      })

    builder
      .addCase(actions.studentProfileUpdate.pending, state => {
        state[actionTypes.STUDENT_PROFILE_UPDATE].loading = true
      })
      .addCase(actions.studentProfileUpdate.rejected, (state, action) => {
        state[actionTypes.STUDENT_PROFILE_UPDATE].loading = false
        state[actionTypes.STUDENT_PROFILE_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actions.studentProfileUpdate.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_PROFILE_UPDATE].loading = false
        state[actionTypes.STUDENT_PROFILE_UPDATE].error = null
        state[actionTypes.STUDENT_PROFILE_UPDATE].data = action.payload
      })

    builder
      .addCase(actions.studentList.pending, state => {
        state[actionTypes.STUDENT_LIST].loading = true
      })
      .addCase(actions.studentList.rejected, (state, action) => {
        state[actionTypes.STUDENT_LIST].loading = false
        state[actionTypes.STUDENT_LIST].error = action.payload as AxiosError
      })
      .addCase(actions.studentList.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_LIST].loading = false
        state[actionTypes.STUDENT_LIST].error = null
        state[actionTypes.STUDENT_LIST].data = action.payload
      })

    builder
      .addCase(actions.studentAchievementRecords.pending, state => {
        state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS].loading = true
      })
      .addCase(actions.studentAchievementRecords.rejected, (state, action) => {
        state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS].loading = false
        state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS].error = action.payload as AxiosError
      })
      .addCase(actions.studentAchievementRecords.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS].loading = false
        state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS].error = null
        state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS].data = action.payload
      })

    builder
      .addCase(actions.studentAll.pending, state => {
        state[actionTypes.STUDENT_ALL].loading = true
      })
      .addCase(actions.studentAll.rejected, (state, action) => {
        state[actionTypes.STUDENT_ALL].loading = false
        state[actionTypes.STUDENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actions.studentAll.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_ALL].loading = false
        state[actionTypes.STUDENT_ALL].error = null
        state[actionTypes.STUDENT_ALL].data = action.payload
      })

    builder
      .addCase(actions.studentGuardCreate.pending, state => {
        state[actionTypes.STUDENT_GUARD_CREATE].loading = true
      })
      .addCase(actions.studentGuardCreate.rejected, (state, action) => {
        state[actionTypes.STUDENT_GUARD_CREATE].loading = false
        state[actionTypes.STUDENT_GUARD_CREATE].error = action.payload as AxiosError
      })
      .addCase(actions.studentGuardCreate.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_GUARD_CREATE].loading = false
        state[actionTypes.STUDENT_GUARD_CREATE].error = null
        state[actionTypes.STUDENT_GUARD_CREATE].data = action.payload
      })

    builder
      .addCase(actions.studentGuardList.pending, state => {
        state[actionTypes.STUDENT_GUARD_LIST].loading = true
      })
      .addCase(actions.studentGuardList.rejected, (state, action) => {
        state[actionTypes.STUDENT_GUARD_LIST].loading = false
        state[actionTypes.STUDENT_GUARD_LIST].error = action.payload as AxiosError
      })
      .addCase(actions.studentGuardList.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_GUARD_LIST].loading = false
        state[actionTypes.STUDENT_GUARD_LIST].error = null
        state[actionTypes.STUDENT_GUARD_LIST].data = action.payload
      })

    builder
      .addCase(actions.studentUsername.pending, state => {
        state[actionTypes.STUDENT_USERNAME].loading = true
      })
      .addCase(actions.studentUsername.rejected, (state, action) => {
        state[actionTypes.STUDENT_USERNAME].loading = false
        state[actionTypes.STUDENT_USERNAME].error = action.payload as AxiosError
      })
      .addCase(actions.studentUsername.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_USERNAME].loading = false
        state[actionTypes.STUDENT_USERNAME].error = null
        state[actionTypes.STUDENT_USERNAME].data = action.payload
      })

    builder
      .addCase(actions.studentPersonalInfo.pending, state => {
        state[actionTypes.STUDENT_PERSONAL_INFO].loading = true
      })
      .addCase(actions.studentPersonalInfo.rejected, (state, action) => {
        state[actionTypes.STUDENT_PERSONAL_INFO].loading = false
        state[actionTypes.STUDENT_PERSONAL_INFO].error = action.payload as AxiosError
      })
      .addCase(actions.studentPersonalInfo.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_PERSONAL_INFO].loading = false
        state[actionTypes.STUDENT_PERSONAL_INFO].error = null
        state[actionTypes.STUDENT_PERSONAL_INFO].data = action.payload
      })

    builder
      .addCase(actions.studentDelete.pending, state => {
        state[actionTypes.STUDENT_DELETE].loading = true
      })
      .addCase(actions.studentDelete.rejected, (state, action) => {
        state[actionTypes.STUDENT_DELETE].loading = false
        state[actionTypes.STUDENT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actions.studentDelete.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_DELETE].loading = false
        state[actionTypes.STUDENT_DELETE].error = null
        state[actionTypes.STUDENT_DELETE].data = action.payload
      })

    builder
      .addCase(actions.studentContactInfo.pending, state => {
        state[actionTypes.STUDENT_CONTACT_INFO].loading = true
      })
      .addCase(actions.studentContactInfo.rejected, (state, action) => {
        state[actionTypes.STUDENT_CONTACT_INFO].loading = false
        state[actionTypes.STUDENT_CONTACT_INFO].error = action.payload as AxiosError
      })
      .addCase(actions.studentContactInfo.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_CONTACT_INFO].loading = false
        state[actionTypes.STUDENT_CONTACT_INFO].error = null
        state[actionTypes.STUDENT_CONTACT_INFO].data = action.payload
      })

    builder
      .addCase(actions.studentStatusUpdate.pending, state => {
        state[actionTypes.STUDENT_STATUS_UPDATE].loading = true
      })
      .addCase(actions.studentStatusUpdate.rejected, (state, action) => {
        state[actionTypes.STUDENT_STATUS_UPDATE].loading = false
        state[actionTypes.STUDENT_STATUS_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actions.studentStatusUpdate.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_STATUS_UPDATE].loading = false
        state[actionTypes.STUDENT_STATUS_UPDATE].error = null
        state[actionTypes.STUDENT_STATUS_UPDATE].data = action.payload
      })

    builder
      .addCase(actions.studentMarkUpdate.pending, state => {
        state[actionTypes.STUDENT_MARK_UPDATE].loading = true
      })
      .addCase(actions.studentMarkUpdate.rejected, (state, action) => {
        state[actionTypes.STUDENT_MARK_UPDATE].loading = false
        state[actionTypes.STUDENT_MARK_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actions.studentMarkUpdate.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_MARK_UPDATE].loading = false
        state[actionTypes.STUDENT_MARK_UPDATE].error = null
        state[actionTypes.STUDENT_MARK_UPDATE].data = action.payload
      })

    builder
      .addCase(actions.studentDocumentCreate.pending, state => {
        state[actionTypes.STUDENT_DOCUMENT].loading = true
      })
      .addCase(actions.studentDocumentCreate.rejected, (state, action) => {
        state[actionTypes.STUDENT_DOCUMENT].loading = false
        state[actionTypes.STUDENT_DOCUMENT].error = action.payload as AxiosError
      })
      .addCase(actions.studentDocumentCreate.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_DOCUMENT].loading = false
        state[actionTypes.STUDENT_DOCUMENT].error = null
        state[actionTypes.STUDENT_DOCUMENT].data = action.payload
      })

    builder
      .addCase(actions.studentDebitDetailCreate.pending, state => {
        state[actionTypes.STUDENT_DEBIT_DETAIL_CREATE].loading = true
      })
      .addCase(actions.studentDebitDetailCreate.rejected, (state, action) => {
        state[actionTypes.STUDENT_DEBIT_DETAIL_CREATE].loading = false
        state[actionTypes.STUDENT_DEBIT_DETAIL_CREATE].error = action.payload as AxiosError
      })
      .addCase(actions.studentDebitDetailCreate.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_DEBIT_DETAIL_CREATE].loading = false
        state[actionTypes.STUDENT_DEBIT_DETAIL_CREATE].error = null
        state[actionTypes.STUDENT_DEBIT_DETAIL_CREATE].data = action.payload
      })

    builder
      .addCase(actions.studentDebitDetailDelete.pending, state => {
        state[actionTypes.STUDENT_DEBIT_DETAIL_DELETE].loading = true
      })
      .addCase(actions.studentDebitDetailDelete.rejected, (state, action) => {
        state[actionTypes.STUDENT_DEBIT_DETAIL_DELETE].loading = false
        state[actionTypes.STUDENT_DEBIT_DETAIL_DELETE].error = action.payload as AxiosError
      })
      .addCase(actions.studentDebitDetailDelete.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_DEBIT_DETAIL_DELETE].loading = false
        state[actionTypes.STUDENT_DEBIT_DETAIL_DELETE].error = null
        state[actionTypes.STUDENT_DEBIT_DETAIL_DELETE].data = action.payload
      })

    builder
      .addCase(actions.studentCallLetterDelete.pending, state => {
        state[actionTypes.STUDENT_CALL_LETTER_DELETE].loading = true
      })
      .addCase(actions.studentCallLetterDelete.rejected, (state, action) => {
        state[actionTypes.STUDENT_CALL_LETTER_DELETE].loading = false
        state[actionTypes.STUDENT_CALL_LETTER_DELETE].error = action.payload as AxiosError
      })
      .addCase(actions.studentCallLetterDelete.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_CALL_LETTER_DELETE].loading = false
        state[actionTypes.STUDENT_CALL_LETTER_DELETE].error = null
        state[actionTypes.STUDENT_CALL_LETTER_DELETE].data = action.payload
      })

    builder
      .addCase(actions.studentSendSms.pending, state => {
        state[actionTypes.STUDENT_SEND_SMS].loading = true
      })
      .addCase(actions.studentSendSms.rejected, (state, action) => {
        state[actionTypes.STUDENT_SEND_SMS].loading = false
        state[actionTypes.STUDENT_SEND_SMS].error = action.payload as AxiosError
      })
      .addCase(actions.studentSendSms.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_SEND_SMS].loading = false
        state[actionTypes.STUDENT_SEND_SMS].error = null
        state[actionTypes.STUDENT_SEND_SMS].data = action.payload
      })

    builder
      .addCase(actions.studentExportData.pending, state => {
        state[actionTypes.STUDENT_EXPORT_DATA].loading = true
      })
      .addCase(actions.studentExportData.rejected, (state, action) => {
        state[actionTypes.STUDENT_EXPORT_DATA].loading = false
        state[actionTypes.STUDENT_EXPORT_DATA].error = action.payload as AxiosError
      })
      .addCase(actions.studentExportData.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_EXPORT_DATA].loading = false
        state[actionTypes.STUDENT_EXPORT_DATA].error = null
        state[actionTypes.STUDENT_EXPORT_DATA].data = action.payload
      })

    builder
      .addCase(actions.studentTransfer.pending, state => {
        state[actionTypes.STUDENT_TRANSFER].loading = true
      })
      .addCase(actions.studentTransfer.rejected, (state, action) => {
        state[actionTypes.STUDENT_TRANSFER].loading = false
        state[actionTypes.STUDENT_TRANSFER].error = action.payload as AxiosError
      })
      .addCase(actions.studentTransfer.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_TRANSFER].loading = false
        state[actionTypes.STUDENT_TRANSFER].error = null
        state[actionTypes.STUDENT_TRANSFER].data = action.payload
      })

    builder
      .addCase(actions.studentResumeStudy.pending, state => {
        state[actionTypes.STUDENT_RESUME_STUDY].loading = true
      })
      .addCase(actions.studentResumeStudy.rejected, (state, action) => {
        state[actionTypes.STUDENT_RESUME_STUDY].loading = false
        state[actionTypes.STUDENT_RESUME_STUDY].error = action.payload as AxiosError
      })
      .addCase(actions.studentResumeStudy.fulfilled, (state, action) => {
        state[actionTypes.STUDENT_RESUME_STUDY].loading = false
        state[actionTypes.STUDENT_RESUME_STUDY].error = null
        state[actionTypes.STUDENT_RESUME_STUDY].data = action.payload
      })

    builder.addCase(actions.studentProfileClear, state => {
      state[actionTypes.STUDENT_PROFILE] = defaultState()
    })
    builder.addCase(actions.studentClear, state => {
      state[actionTypes.STUDENT] = defaultState()
    })
    builder.addCase(actions.studentListClear, state => {
      state[actionTypes.STUDENT_LIST] = defaultState()
    })
    builder.addCase(actions.studentAchievementRecordsClear, state => {
      state[actionTypes.STUDENT_ACHIEVEMENT_RECORDS] = defaultState()
    })
    builder.addCase(actions.studentAllClear, state => {
      state[actionTypes.STUDENT_ALL] = defaultState()
    })
    builder.addCase(actions.studentGuardListClear, state => {
      state[actionTypes.STUDENT_GUARD_LIST] = defaultState()
    })
    builder.addCase(actions.studentUsernameClear, state => {
      state[actionTypes.STUDENT_USERNAME] = defaultState()
    })
  },
})

export const STUDENT = actionTypes.GLOBAL_STATE

export const { reducer: studentReducer } = studentSlice
