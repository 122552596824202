import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { DEBIT_CREATE, DEBIT_DELETE, DEBIT_LIST, DEBIT_OWN, GLOBAL_STATE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const DEBIT = GLOBAL_STATE

export const { reducer: debitReducer } = createSlice({
  name: DEBIT,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.debitList.pending, state => {
        state[DEBIT_LIST].loading = true
      })
      .addCase(actionCreators.debitList.rejected, (state, action) => {
        state[DEBIT_LIST].loading = false
        state[DEBIT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.debitList.fulfilled, (state, action) => {
        state[DEBIT_LIST].loading = false
        state[DEBIT_LIST].error = null
        state[DEBIT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.debitOwn.pending, state => {
        state[DEBIT_OWN].loading = true
      })
      .addCase(actionCreators.debitOwn.rejected, (state, action) => {
        state[DEBIT_OWN].loading = false
        state[DEBIT_OWN].error = action.payload as AxiosError
      })
      .addCase(actionCreators.debitOwn.fulfilled, (state, action) => {
        state[DEBIT_OWN].loading = false
        state[DEBIT_OWN].error = null
        state[DEBIT_OWN].data = action.payload
      })

    builder
      .addCase(actionCreators.debitCreate.pending, state => {
        state[DEBIT_CREATE].loading = true
      })
      .addCase(actionCreators.debitCreate.rejected, (state, action) => {
        state[DEBIT_CREATE].loading = false
        state[DEBIT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.debitCreate.fulfilled, (state, action) => {
        state[DEBIT_CREATE].loading = false
        state[DEBIT_CREATE].error = null
        state[DEBIT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.debitDelete.pending, state => {
        state[DEBIT_DELETE].loading = true
      })
      .addCase(actionCreators.debitDelete.rejected, (state, action) => {
        state[DEBIT_DELETE].loading = false
        state[DEBIT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.debitDelete.fulfilled, (state, action) => {
        state[DEBIT_DELETE].loading = false
        state[DEBIT_DELETE].error = null
        state[DEBIT_DELETE].data = action.payload
      })

    builder.addCase(actionCreators.debitListClear, state => {
      state[DEBIT_LIST] = defaultState()
    })
  },
})
