import { BACHELOR_STUDENT, MASTER_STUDENT, TEST_STUDENT } from '@constants/userRoles'
import { lazy } from 'react'

import { STUDENT_DOCS } from '../constants/route'

export const StudentDocsRoutes = [
  {
    path: STUDENT_DOCS,
    component: lazy(() => import('../views/StudentDocs')),
    meta: {
      access: [BACHELOR_STUDENT, TEST_STUDENT, MASTER_STUDENT],
    },
  },
]
