import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  JOB_POSITION,
  JOB_POSITION_ACTIVATE,
  JOB_POSITION_ALL,
  JOB_POSITION_CREATE,
  JOB_POSITION_LIST,
} from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const jobPositionSelector = createSelector(selectors, prop(JOB_POSITION))

export const jobPositionActivateSelector = createSelector(selectors, prop(JOB_POSITION_ACTIVATE))

export const jobPositionAllSelector = createSelector(selectors, prop(JOB_POSITION_ALL))

export const jobPositionCreateSelector = createSelector(selectors, prop(JOB_POSITION_CREATE))

export const jobPositionListSelector = createSelector(selectors, prop(JOB_POSITION_LIST))
