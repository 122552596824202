import { getEduForm, getSelectedEduForm, setEduForm, setSelectedEduForm } from '@helpers/storageEduForm'
import {
  getInstitution,
  getInstitutionOption,
  setInstitutionId,
  setInstitutionOption,
} from '@helpers/storageInstitution'
import { useAppDispatch } from '@src/hooks'
import { Target, useSignalConsumer } from '@src/hooks/useSignal'
import { updateUserSettings } from '@store/actions'
import { PropsWithChildren, createContext } from 'react'

type Props = {
  signal: Target<{
    institution_id: number
    institution: { label: string; value: number }
    education_form_id: number
    education_form: { label: string; value: number }
    key: string
  }>
} & PropsWithChildren

const defaultValue = {
  institution_id: getInstitution(),
  institution: getInstitutionOption(),
  education_form: getSelectedEduForm(),
  education_form_id: getEduForm(),
  key: `institution_id=${getInstitution()},education_form_id=${getEduForm()}`,
  setInstitution: (option: { label: string; value: number }) => {},
  setEduForm: (option: { label: string; value: number }) => {},
}

export const UserSettingsContext = createContext(defaultValue)

export const UserSettingsProvider = ({ children, signal }: Props) => {
  const dispatch = useAppDispatch()
  const { state } = useSignalConsumer(signal)

  function setEducationForm(option: { label: string; value: number }) {
    dispatch((dispatch, getStore) => {
      dispatch(
        updateUserSettings({
          institution_id: state.institution_id,
          education_form: option?.value,
          locale: getStore().Auth.userInfo?.selected_locale?.value || 'uz',
          onFulfilled: () => {
            setEduForm(option?.value)
            setSelectedEduForm(JSON.stringify(option))
            signal.value = {
              ...signal.value,
              education_form: option,
              education_form_id: option?.value,
              key: `institution_id=${signal.value.institution_id},education_form_id=${option?.value}`,
            }
          },
        })
      )
    })
  }

  function setInstitution(option: { label: string; value: number }) {
    dispatch((dispatch, getStore) => {
      dispatch(
        updateUserSettings({
          institution_id: option?.value,
          education_form: state.education_form_id,
          locale: getStore().Auth.userInfo?.selected_locale?.value || 'uz',
          onFulfilled: () => {
            setInstitutionId(option?.value)
            setInstitutionOption(JSON.stringify(option))
            signal.value = {
              ...signal.value,
              institution_id: option?.value,
              institution: option,
              key: `institution_id=${option?.value},education_form_id=${signal.value.education_form_id}`,
            }
          },
        })
      )
    })
  }

  return (
    <UserSettingsContext.Provider
      key={state.key}
      value={{
        education_form: state.education_form,
        institution_id: state.institution_id,
        institution: state.institution,
        education_form_id: state.education_form,
        key: state.key,
        setInstitution,
        setEduForm: setEducationForm,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}
