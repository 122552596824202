import { useLatest } from '@src/hooks'
import { Target, Updater } from '@src/hooks/useSignal'
import { selectThemeColors } from '@utils'
import classNames from 'classnames'
import React, {
  CSSProperties,
  FocusEvent,
  JSX,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import Select, { ActionMeta, GroupBase, Props, SelectInstance, StylesConfig } from 'react-select'
import { FormGroup, FormText, Label } from 'reactstrap'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector'

import { IndicatorsContainer, Menu, MultiValueContainer, SelectContainer, ValueContainer } from './components'

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

type Signal<Options, A> = Target<A extends Options ? Options : A>

export interface IOption {
  readonly value: unknown // string | number | boolean
  readonly label: string | JSX.Element
  readonly [key: string]: unknown
}

type SelectProps<
  Option,
  Group extends GroupBase<Option>,
  IsMulti extends boolean = false,
  Normalize extends boolean = false,
  SignalValue = void
> = Omit<Props<Option, IsMulti, Group>, 'options' | 'onChange' | 'isMulti'> & {
  options?: readonly Option[]
  isMulti?: IsMulti
  menuZIndex?: CSSProperties['zIndex']
  formGroupStyles?: CSSProperties
  formGroupProps?: Record<string, string>
  error?: Record<string, unknown>
  style?: StylesConfig
  label?: string | ReactNode
  signal?: Signal<readonly Option[], SignalValue>
  className?: string
  name: string
  disabled?: boolean
  enableMenuPortal?: boolean
  unregister?: (name: string) => void
  // signalUpdater?: Updater<Signal<readonly Option[], SignalValue>['value'], readonly Option[]>
  signalUpdater?: Updater<
    Signal<readonly Option[], SignalValue>['value'],
    { loading: boolean; options: readonly Option[] } | readonly Option[]
  >
  onChange: (
    value: IsMulti extends true ? readonly Option[] : Option | null,
    actionMeta?: ActionMeta<Option>,
    name?: string
  ) => void
  handleChange?: (value: IsMulti extends true ? readonly Option[] : Option | null, name?: string) => void
  onBeforeChange?: (newValue: IsMulti extends true ? readonly Option[] : Option | null, name?: string) => void
  onUnmount?: () => void
}

function SelectField<
  A extends IOption,
  SignalValue extends Record<string, unknown> | readonly A[],
  M extends boolean = false
>(props: SelectProps<A, GroupBase<A>, M, false, SignalValue>) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const ref = useRef<SelectInstance<A, M, GroupBase<A>>>(null)
  const {
    options,
    id,
    components,
    name,
    menuZIndex,
    style,
    label,
    required,
    error,
    className,
    isMulti,
    placeholder,
    disabled,
    autoFocus,
    isLoading,
    isClearable,
    enableMenuPortal,
    formGroupStyles,
    formGroupProps,
    value,
    onBeforeChange,
    onChange,
    handleChange,
    onFocus,
    signalUpdater,
    signal,
    onUnmount,
    ...rest
  } = props

  const [inputValue, setInputValue] = useState('')

  let signalOptions: readonly A[] | undefined = undefined
  let signalLoading = false

  if (signal) {
    const signalUpdaterLatest = useLatest(signalUpdater)
    const store = useSyncExternalStoreWithSelector(signal.pureSubscribe, signal.getValue, null, selection =>
      signalUpdaterLatest.current ? signalUpdaterLatest.current(selection) : selection
    )

    signalOptions = Array.isArray(store) ? options : 'options' in store ? (store.options as readonly A[]) : undefined
    signalLoading = 'loading' in store ? (store.loading as boolean) : false
  }

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])

  useEffect(() => {
    if (
      (!value || (value && isMultiOption(value) && !value.length)) &&
      ((options && options?.length === 1) || (signalOptions && signalOptions.length === 1)) &&
      required
    ) {
      const first = options ? options[0] : signalOptions ? signalOptions[0] : null
      setValue(first as M extends true ? readonly A[] : A | null)
    }
  }, [value, options, signalOptions])

  useEffect(() => {
    /* Reconsider this logic! Need to remove option (if selected) which doesn't exist in list of options */
    // if (value && isMultiOption(value) && (options?.length || signalOptions?.length)) {
    //   const found = findValue(value, options || signalOptions)
    //   if (found && isMultiOption(found)) {
    //     const filtered = (options ? options : signalOptions || [])?.flatMap(item => {
    //       return found.find(selectedOption => selectedOption.value === item.value) ? [item] : []
    //     })
    //     setValue(filtered as unknown as M extends true ? readonly A[] : A | null)
    //   } else if (!found && found && isMultiOption(found)) {
    //     setValue(null as M extends true ? readonly A[] : A | null)
    //   }
    // }
  }, [options, signalOptions])

  const menuPortal: StylesConfig<IOption>['menuPortal'] = provided => {
    if (enableMenuPortal) {
      const rect = wrapperRef.current?.getBoundingClientRect()
      return {
        ...provided,
        zIndex: 9999,
        position: 'absolute',
        top: rect?.top || 0 + window.scrollY + (rect?.height || 0),
      }
    }
    return provided
  }

  function setValue(value: M extends true ? readonly A[] : A | null, actionMeta?: ActionMeta<A>) {
    onBeforeChange && onBeforeChange(value, name)
    onChange(value, actionMeta, name)
    handleChange && handleChange(value, name)
  }

  function change(option: M extends true ? readonly A[] : A | null, actionMeta: ActionMeta<A>) {
    if (actionMeta?.action === 'select-option' && isMulti) {
      setInputValue(inputValue)
    }
    setValue(option, actionMeta)
  }

  const focus = (e: FocusEvent<HTMLInputElement, Element>) => {
    onFocus && onFocus(e)
  }

  function closeMenu() {
    ref.current?.blur()
  }

  function openMenu() {
    ref.current?.focus()
  }

  function findValue(option: readonly A[] | A | null, options: typeof props.options | typeof signalOptions) {
    if (!option) return
    if (!isMultiOption(option)) {
      return options?.find(item => item.value === option.value)
    } else if (isMultiOption(option) && Array.isArray(option)) {
      return options?.filter(item => option.find(selectedOption => selectedOption.value === item.value))
    }
  }

  function isMultiOption(option: readonly A[] | A | null): option is readonly A[] {
    return !!isMulti
  }

  const htmlId = id ? { id } : {}

  return (
    <FormGroup style={formGroupStyles} {...(formGroupProps ? formGroupProps : {})}>
      {label && (
        <Label>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <div ref={wrapperRef}>
        <Select
          {...rest}
          ref={ref}
          value={value}
          id={id}
          onChange={change}
          className={classNames(
            error ? `react-select-error is-invalid react-select ${className}` : `react-select ${className}`
          )}
          inputValue={inputValue}
          onInputChange={(inputValue, actionMeta) => {
            setInputValue(inputValue)
          }}
          escapeClearsValue
          isMulti={props.isMulti}
          classNamePrefix='select'
          hideSelectedOptions={false}
          closeMenuOnSelect={!isMulti}
          menuShouldScrollIntoView={false}
          {...(placeholder ? { placeholder } : {})}
          styles={{
            control: provided => {
              return {
                ...provided,
                border: '1px solid #e2e7f1',
                flexWrap: 'nowrap',
                display: 'flex',
              }
            },
            input: provided => ({
              ...provided,
              color: 'inherit',
            }),
            multiValue: provided => ({
              ...provided,
              margin: '0.2rem',
            }),
            menu: provided => ({
              ...provided,
              zIndex: menuZIndex || '10',
            }),
            menuPortal,
            clearIndicator: provided => ({
              ...provided,
              padding: '6px',
            }),
            dropdownIndicator(base) {
              return {
                ...base,
                padding: '4px',
              }
            },
            ...(style ? { style } : {}),
          }}
          onFocus={focus}
          isDisabled={disabled}
          autoFocus={autoFocus}
          isLoading={isLoading || signalLoading}
          isClearable={isClearable}
          captureMenuScroll
          theme={selectThemeColors}
          name={name}
          // menuPortalTarget={menuPortalTarget ? menuPortalTarget : document.body}
          options={options || signalOptions}
          onMenuClose={closeMenu}
          onMenuOpen={openMenu}
          // menuPosition='fixed'
          // menuPlacement='auto'
          {...htmlId}
          components={{
            SelectContainer,
            Menu,
            IndicatorsContainer,
            MultiValueContainer,
            ValueContainer,
            ...(components ? components : {}),
          }}
        />
      </div>
      {error && (
        <FormText color='danger'>
          <FormattedMessage id='form_required' />
        </FormText>
      )}
    </FormGroup>
  )
}

const ForwardedSelectField = forwardRef(SelectField)

export default ForwardedSelectField
