import {
  ADMIN,
  BACHELOR_STUDENT,
  MASTER_STUDENT,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@constants/userRoles'
import { lazy } from 'react'

import { STUDENT_LESSONS_LIST } from '../constants/route'

export const LessonRoutes = [
  // {
  //   path: LESSONS,
  //   component: lazy(() => import('../../Attendance/views/Attendance')),
  //   meta: {
  //     access: [
  //       SUPER_ADMIN,
  //       REGISTRAR_HEAD,
  //       REGISTRAR_OFFICER,
  //       REGISTRAR_HELPER,
  //       PROGRAMME_MANAGER,
  //       TEACHING_AND_LEARNING_OFFICER,
  //       TEACHER,
  //       MODULE_LEADER,
  //     ],
  //   },
  // },
  {
    path: STUDENT_LESSONS_LIST,
    component: lazy(() => import('../views/StudentLessonList')),
    exact: true,
    meta: {
      access: [
        BACHELOR_STUDENT,
        MASTER_STUDENT,
        TEST_STUDENT,
        SUPER_ADMIN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        ADMIN,
      ],
    },
  },
]
