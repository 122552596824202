import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, ROLE_ALL, ROLE_LIST } from '../constants/actionTypes'

const roleSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const roleAllSelector = createSelector(roleSelectors, prop(ROLE_ALL))

export const roleListSelector = createSelector(roleSelectors, prop(ROLE_LIST))
