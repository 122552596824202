import { DefaultState } from '@src/redux/types'

import {
  LESSON,
  LESSON_ALL,
  LESSON_ARTICULATE,
  LESSON_ARTICULATE_ALL,
  LESSON_ARTICULATE_CREATE,
  LESSON_ARTICULATE_DELETE,
  LESSON_ASSESSMENT,
  LESSON_ASSESSMENT_ALL,
  LESSON_CREATE,
  LESSON_DELETE,
  LESSON_HOMEWORK_UPLOAD,
  LESSON_LIST,
  LESSON_MARK_ASSESSMENT,
  LESSON_SORTING,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [LESSON]: DefaultState<`${typeof API.LESSON}{id}`, 'get'>
  [LESSON_ALL]: DefaultState<`${typeof API.LESSON_ALL}`, 'get'>
  [LESSON_ASSESSMENT]: DefaultState<`${typeof API.LESSON_ASSESSMENT}{id}`, 'get'>
  [LESSON_ASSESSMENT_ALL]: DefaultState<`${typeof API.LESSON_ASSESSMENT_ALL}`, 'get'>
  [LESSON_CREATE]: DefaultState<`${typeof API.LESSON}`, 'post'>
  [LESSON_DELETE]: DefaultState<`${typeof API.LESSON}{id}`, 'delete'>
  [LESSON_HOMEWORK_UPLOAD]: DefaultState<`${typeof API.LESSON_HOMEWORK_UPLOAD}`, 'post'>
  [LESSON_LIST]: DefaultState<`${typeof API.LESSON}`, 'get'>
  [LESSON_MARK_ASSESSMENT]: DefaultState<`${typeof API.LESSON_ASSESSMENT}`, 'post'>
  [LESSON_SORTING]: DefaultState<`${typeof API.LESSON_SORTING}`, 'put'>
  [LESSON_ARTICULATE_ALL]: DefaultState<`${typeof API.LESSON_ARTICULATE_ALL}{cclsModuleId}`, 'get'>
  // [LESSON_ARTICULATE]: DefaultState<`${typeof API.LESSON_ARTICULATE}{id}`, 'get'>
  [LESSON_ARTICULATE_CREATE]: DefaultState<`${typeof API.LESSON_ARTICULATE}`, 'post'>
  [LESSON_ARTICULATE_DELETE]: DefaultState<`${typeof API.LESSON_ARTICULATE}{id}`, 'delete'>
} = () => ({
  [LESSON]: defaultState(),
  [LESSON_ALL]: defaultState(),
  [LESSON_ASSESSMENT]: defaultState(),
  [LESSON_ASSESSMENT_ALL]: defaultState(),
  [LESSON_CREATE]: defaultState(),
  [LESSON_DELETE]: defaultState(),
  [LESSON_HOMEWORK_UPLOAD]: defaultState(),
  [LESSON_LIST]: defaultState(),
  [LESSON_MARK_ASSESSMENT]: defaultState(),
  [LESSON_SORTING]: defaultState(),
  [LESSON_ARTICULATE_ALL]: defaultState(),
  [LESSON_ARTICULATE_CREATE]: defaultState(),
  // [LESSON_ARTICULATE]: defaultState(),
  [LESSON_ARTICULATE_DELETE]: defaultState(),
})
