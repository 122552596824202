import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  CCLS_MODULE,
  CCLS_MODULES_FILTER,
  CCLS_MODULE_ALL,
  CCLS_MODULE_ARTICULATE,
  CCLS_MODULE_ARTICULATE_ALL,
  CCLS_MODULE_CREATE,
  CCLS_MODULE_DELETE,
  CCLS_MODULE_LIST,
  CCLS_MODULE_RETAKE_UPLOAD,
  CCLS_MODULE_TEACHER_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const cclsModuleArticulateAll = createAsyncThunk(
  CCLS_MODULE_ARTICULATE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE_ARTICULATE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleArticulateAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleArticulate = createAsyncThunk(
  CCLS_MODULE_ARTICULATE,
  async (
    params: PayloadCreatorParams<`${typeof API.CCLS_MODULE_ARTICULATE}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.cclsModuleArticulate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModule = createAsyncThunk(
  CCLS_MODULE,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModule(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModulesFilter = createAsyncThunk(
  CCLS_MODULES_FILTER,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesFilter(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleCreate = createAsyncThunk(
  CCLS_MODULE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleTeacherUpdate = createAsyncThunk(
  CCLS_MODULE_TEACHER_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE_TEACHER}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleTeacherUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleDelete = createAsyncThunk(
  CCLS_MODULE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleList = createAsyncThunk(
  CCLS_MODULE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleAll = createAsyncThunk(
  CCLS_MODULE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleRetakeUpload = createAsyncThunk(
  CCLS_MODULE_RETAKE_UPLOAD,
  async (params: PayloadCreatorParams<`${typeof API.CCLS_MODULE_RETAKE_UPLOAD}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.cclsModuleRetakeUpload(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const cclsModuleArticulateClear = createAction(`${CCLS_MODULE_ARTICULATE}/clear`)

export const cclsModuleArticulateAllClear = createAction(`${CCLS_MODULE_ARTICULATE_ALL}/clear`)

export const cclsModulesFilterClear = createAction(`${CCLS_MODULES_FILTER}/clear`)

export const cclsModuleClear = createAction(`${CCLS_MODULE}/clear`)

export const cclsModuleListClear = createAction(`${CCLS_MODULE_LIST}/clear`)

export const cclsModuleAllClear = createAction(`${CCLS_MODULE_ALL}/clear`)
