import { DefaultState } from '@src/redux/types'

import { COURSE, COURSE_ALL, COURSE_CREATE, COURSE_DELETE, COURSE_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [COURSE]: DefaultState<`${typeof API.COURSE}{id}`, 'get'>
  [COURSE_ALL]: DefaultState<`${typeof API.COURSE_ALL}`, 'get'>
  [COURSE_CREATE]: DefaultState<`${typeof API.COURSE}`, 'post'>
  [COURSE_DELETE]: DefaultState<`${typeof API.COURSE}{id}`, 'delete'>
  [COURSE_LIST]: DefaultState<`${typeof API.COURSE}`, 'get'>
} = () => ({
  [COURSE]: defaultState(),
  [COURSE_ALL]: defaultState(),
  [COURSE_CREATE]: defaultState(),
  [COURSE_DELETE]: defaultState(),
  [COURSE_LIST]: defaultState(),
})
