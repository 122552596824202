import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  CALENDAR_EVENT,
  CALENDAR_EVENT_ALL,
  CALENDAR_EVENT_CREATE,
  CALENDAR_EVENT_DELETE,
  CALENDAR_EVENT_LIST,
  CALENDAR_EVENT_LIST_STUDENT,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const CALENDAR = GLOBAL_STATE

export const { reducer: calendarReducer } = createSlice({
  name: CALENDAR,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.calendarEvent.pending, state => {
        state[CALENDAR_EVENT].loading = true
      })
      .addCase(actionCreators.calendarEvent.rejected, (state, action) => {
        state[CALENDAR_EVENT].loading = false
        state[CALENDAR_EVENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.calendarEvent.fulfilled, (state, action) => {
        state[CALENDAR_EVENT].loading = false
        state[CALENDAR_EVENT].error = null
        state[CALENDAR_EVENT].data = action.payload
      })

    builder
      .addCase(actionCreators.calendarEventAll.pending, state => {
        state[CALENDAR_EVENT_ALL].loading = true
      })
      .addCase(actionCreators.calendarEventAll.rejected, (state, action) => {
        state[CALENDAR_EVENT_ALL].loading = false
        state[CALENDAR_EVENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.calendarEventAll.fulfilled, (state, action) => {
        state[CALENDAR_EVENT_ALL].loading = false
        state[CALENDAR_EVENT_ALL].error = null
        state[CALENDAR_EVENT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.calendarEventCreate.pending, state => {
        state[CALENDAR_EVENT_CREATE].loading = true
      })
      .addCase(actionCreators.calendarEventCreate.rejected, (state, action) => {
        state[CALENDAR_EVENT_CREATE].loading = false
        state[CALENDAR_EVENT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.calendarEventCreate.fulfilled, (state, action) => {
        state[CALENDAR_EVENT_CREATE].loading = false
        state[CALENDAR_EVENT_CREATE].error = null
        state[CALENDAR_EVENT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.calendarEventDelete.pending, state => {
        state[CALENDAR_EVENT_DELETE].loading = true
      })
      .addCase(actionCreators.calendarEventDelete.rejected, (state, action) => {
        state[CALENDAR_EVENT_DELETE].loading = false
        state[CALENDAR_EVENT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.calendarEventDelete.fulfilled, (state, action) => {
        state[CALENDAR_EVENT_DELETE].loading = false
        state[CALENDAR_EVENT_DELETE].error = null
        state[CALENDAR_EVENT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.calendarEventList.pending, state => {
        state[CALENDAR_EVENT_LIST].loading = true
      })
      .addCase(actionCreators.calendarEventList.rejected, (state, action) => {
        state[CALENDAR_EVENT_LIST].loading = false
        state[CALENDAR_EVENT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.calendarEventList.fulfilled, (state, action) => {
        state[CALENDAR_EVENT_LIST].loading = false
        state[CALENDAR_EVENT_LIST].error = null
        state[CALENDAR_EVENT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.calendarEventListStudent.pending, state => {
        state[CALENDAR_EVENT_LIST_STUDENT].loading = true
      })
      .addCase(actionCreators.calendarEventListStudent.rejected, (state, action) => {
        state[CALENDAR_EVENT_LIST_STUDENT].loading = false
        state[CALENDAR_EVENT_LIST_STUDENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.calendarEventListStudent.fulfilled, (state, action) => {
        state[CALENDAR_EVENT_LIST_STUDENT].loading = false
        state[CALENDAR_EVENT_LIST_STUDENT].error = null
        state[CALENDAR_EVENT_LIST_STUDENT].data = action.payload
      })

    builder.addCase(actionCreators.calendarEventClear, state => {
      state[CALENDAR_EVENT] = defaultState()
    })

    builder.addCase(actionCreators.calendarEventAllClear, state => {
      state[CALENDAR_EVENT_ALL] = defaultState()
    })

    builder.addCase(actionCreators.calendarEventListClear, state => {
      state[CALENDAR_EVENT_LIST] = defaultState()
    })
  },
})
