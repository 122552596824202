import { isNil } from 'ramda'
import { useState } from 'react'

import { dispatchActions } from '../helpers/dispatchActions'

export function useUniversalModal(
  onOpen = () => {},
  onClose = () => {},
  dispatchArgs = { actions: [], dispatch: () => {}, fetchParams: {} }
) {
  const [state, setState] = useState({
    state: false,
    item: undefined,
  })
  function setNewItem(item) {
    return setState(state => ({
      ...state,
      item,
    }))
  }

  function onStateOpen(...args) {
    let item
    if (args.length > 1) {
      item = args[1]
    } else if (!isNil(args[0]) && !args[0].nativeEvent) {
      item = args[0]
    }

    onOpen(item)

    setState({
      state: true,
      item,
    })

    dispatchActions(dispatchArgs)
  }

  function onStateClose() {
    onClose()

    return setState(state => ({
      ...state,
      state: false,
    }))
  }

  return [state, onStateOpen, onStateClose, setNewItem]
}
