import { nanoid } from 'nanoid'
import { pathOr } from 'ramda'
import { useState } from 'react'
import { components } from 'react-select'
import { UncontrolledTooltip } from 'reactstrap'

function MultiValueContainer(props) {
  const [id] = useState(() => nanoid())

  return (
    <div id={`multi-value-${id}`}>
      <components.MultiValueContainer {...props} />
      <UncontrolledTooltip target={`multi-value-${id}`}>
        {pathOr('No label', ['data', 'label'], props)}
      </UncontrolledTooltip>
    </div>
  )
}

export default MultiValueContainer
