// import { getInstance } from '@helpers/httpClient'
// import { dissoc, prop } from 'ramda'
// import {
//   INTERNAL_EXAM_PLACE,
//   INTERNAL_EXAM_PLACE_ALL,
//   INTERNAL_EXAM_PLACE_CREATE,
//   INTERNAL_EXAM_PLACE_DELETE,
//   INTERNAL_EXAM_PLACE_LIST,
// } from '../constants/actionTypes'
// import * as API from '../constants/api'
// const instance = getInstance()
// export const internalExamPlace = data =>
//   instance.get(`${API.INTERNAL_EXAM_PLACE}${prop('id', data)}`, { params: dissoc('id', data) })
// export const internalExamPlaceList = data =>
//   instance.get(API.INTERNAL_EXAM_PLACE, {
//     params: data,
//   })
// export const internalExamPlaceCreate = data => instance.post(API.INTERNAL_EXAM_PLACE, data)
// export const internalExamPlaceDelete = data => instance.delete(`${API.INTERNAL_EXAM_PLACE}${prop('id', data)}`)
// export const internalExamPlaceAll = data =>
//   instance.get(API.INTERNAL_EXAM_PLACE_ALL, {
//     params: data,
//   })
// export default {
//   [INTERNAL_EXAM_PLACE]: internalExamPlace,
//   [INTERNAL_EXAM_PLACE_LIST]: internalExamPlaceList,
//   [INTERNAL_EXAM_PLACE_ALL]: internalExamPlaceAll,
//   [INTERNAL_EXAM_PLACE_CREATE]: internalExamPlaceCreate,
//   [INTERNAL_EXAM_PLACE_DELETE]: internalExamPlaceDelete,
// }
import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const internalExamPlace: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'get'> = data =>
  instance.get(`${API.INTERNAL_EXAM_PLACE}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const internalExamPlaceList: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}`, 'get'> = data =>
  instance.get(API.INTERNAL_EXAM_PLACE, {
    params: data,
  })

export const internalExamPlaceCreate: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}`, 'post'> = data =>
  instance.post(API.INTERNAL_EXAM_PLACE, data)

// export const internalExamPlaceDelete: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'delete'> = data =>
//   instance.delete(`${API.INTERNAL_EXAM_PLACE}${'id' in data ? prop('id', data) : data?.name}`)

export const internalExamPlaceAll: ApiFunc<`${typeof API.INTERNAL_EXAM_PLACE_ALL}`, 'get'> = data =>
  instance.get(API.INTERNAL_EXAM_PLACE_ALL, {
    params: data,
  })

export const internalExamApplicantsList: ApiFunc<`${typeof API.INTERNAL_EXAM_APPLICANTS}{id}`, 'get'> = data =>
  instance.get(`${API.INTERNAL_EXAM_APPLICANTS}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })
