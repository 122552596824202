import { ApiFunc, getInstance } from '@helpers/httpClient'
import { getToken } from '@helpers/storageToken'
import { API_SCHEME, API_URL } from '@src/constants/api'
import axios from 'axios'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const accountingContracts: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS}{id}`, 'get'> = data =>
  instance.get(`${API.ACCOUNTING_CONTRACTS}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const accountingContractsCreate: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS}`, 'post'> = data =>
  instance.post(API.ACCOUNTING_CONTRACTS, data)

export const accountingContractsImport: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS_IMPORT}`, 'post'> = data => {
  // const uploadedFile = prop('file', data)
  // const formData = new FormData()
  // formData.append('file', uploadedFile)
  // formData.append('limit', prop('limit', data))
  // formData.append('ignore_errors', propOr(false, 'ignore_errors', data))
  return axios.request({
    method: 'POST',
    url: `${API_URL}${API.ACCOUNTING_CONTRACTS_IMPORT}`,
    data: (data as unknown as { formData: FormData }).formData,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      institution: '1',
      'Content-Type': 'multipart/form-data',
    },
  })
}

// export const accountingContractsAll: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS_ALL}`, 'get'> = data => instance.get(API.ACCOUNTING_CONTRACTS_ALL, { params: data })

export const accountingContractsList: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS_LIST}`, 'get'> = data =>
  instance.get(API.ACCOUNTING_CONTRACTS_LIST, { params: data })

export const accountingContractsTypes: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS_TYPES}`, 'get'> = data =>
  instance.get(API.ACCOUNTING_CONTRACTS_TYPES, { params: data })

export const accountingContractsAssign: ApiFunc<`${typeof API.ACCOUNTING_CONTRACTS_ASSIGN}`, 'post'> = data =>
  instance.post(API.ACCOUNTING_CONTRACTS_ASSIGN, data)
