import { DefaultState } from '@src/redux/types'

import { LEVEL_ALL } from '../../constants/level/actionTypes'
import * as API from '../../constants/level/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [LEVEL_ALL]: DefaultState<`${typeof API.LEVEL_ALL}`, 'get'>
} = () => ({
  [LEVEL_ALL]: defaultState(),
})
