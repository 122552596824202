import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  CATEGORY,
  CATEGORY_ALL,
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const CATEGORYS = GLOBAL_STATE

export const { reducer: categoryReducer } = createSlice({
  name: CATEGORYS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.category.pending, state => {
        state[CATEGORY].loading = true
      })
      .addCase(actionCreators.category.rejected, (state, action) => {
        state[CATEGORY].loading = false
        state[CATEGORY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.category.fulfilled, (state, action) => {
        state[CATEGORY].loading = false
        state[CATEGORY].error = null
        state[CATEGORY].data = action.payload
      })

    builder
      .addCase(actionCreators.categoryAll.pending, state => {
        state[CATEGORY_ALL].loading = true
      })
      .addCase(actionCreators.categoryAll.rejected, (state, action) => {
        state[CATEGORY_ALL].loading = false
        state[CATEGORY_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.categoryAll.fulfilled, (state, action) => {
        state[CATEGORY_ALL].loading = false
        state[CATEGORY_ALL].error = null
        state[CATEGORY_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.categoryCreate.pending, state => {
        state[CATEGORY_CREATE].loading = true
      })
      .addCase(actionCreators.categoryCreate.rejected, (state, action) => {
        state[CATEGORY_CREATE].loading = false
        state[CATEGORY_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.categoryCreate.fulfilled, (state, action) => {
        state[CATEGORY_CREATE].loading = false
        state[CATEGORY_CREATE].error = null
        state[CATEGORY_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.categoryDelete.pending, state => {
        state[CATEGORY_DELETE].loading = true
      })
      .addCase(actionCreators.categoryDelete.rejected, (state, action) => {
        state[CATEGORY_DELETE].loading = false
        state[CATEGORY_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.categoryDelete.fulfilled, (state, action) => {
        state[CATEGORY_DELETE].loading = false
        state[CATEGORY_DELETE].error = null
        state[CATEGORY_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.categoryList.pending, state => {
        state[CATEGORY_LIST].loading = true
      })
      .addCase(actionCreators.categoryList.rejected, (state, action) => {
        state[CATEGORY_LIST].loading = false
        state[CATEGORY_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.categoryList.fulfilled, (state, action) => {
        state[CATEGORY_LIST].loading = false
        state[CATEGORY_LIST].error = null
        state[CATEGORY_LIST].data = action.payload
      })

    builder.addCase(actionCreators.categoryClear, state => {
      state[CATEGORY] = defaultState()
    })

    builder.addCase(actionCreators.categoryAllClear, state => {
      state[CATEGORY_ALL] = defaultState()
    })

    builder.addCase(actionCreators.categoryListClear, state => {
      state[CATEGORY_LIST] = defaultState()
    })
  },
})
