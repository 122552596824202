import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { COUNTRY, COUNTRY_ALL, COUNTRY_CREATE, COUNTRY_LIST, GLOBAL_STATE } from '../constants/actionTypes'

const countrySelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const countrySelector = createSelector(countrySelectors, prop(COUNTRY))

export const countryListSelector = createSelector(countrySelectors, prop(COUNTRY_LIST))

export const countryAllSelector = createSelector(countrySelectors, prop(COUNTRY_ALL))

export const countryCreateSelector = createSelector(countrySelectors, prop(COUNTRY_CREATE))
