import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACCOUNTING_CONTRACTS, 
  ACCOUNTING_CONTRACTS_ASSIGN,
  ACCOUNTING_CONTRACTS_CREATE,
  ACCOUNTING_CONTRACTS_IMPORT,
  ACCOUNTING_CONTRACTS_LIST,
  ACCOUNTING_CONTRACTS_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const accountingContractsSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

// export const accountingContractsAllSelector = createSelector(
//   accountingContractsSelectors,
//   prop(ACCOUNTING_CONTRACTS_ALL)
// )

export const accountingContractsCreateSelector = createSelector(
  accountingContractsSelectors,
  prop(ACCOUNTING_CONTRACTS_CREATE)
)

export const accountingContractsListSelector = createSelector(
  accountingContractsSelectors,
  prop(ACCOUNTING_CONTRACTS_LIST)
)

export const accountingContractsSelector = createSelector(accountingContractsSelectors, prop(ACCOUNTING_CONTRACTS))

export const accountingContractsImportSelector = createSelector(
  accountingContractsSelectors,
  prop(ACCOUNTING_CONTRACTS_IMPORT)
)

export const accountingContractsTypesSelector = createSelector(
  accountingContractsSelectors,
  prop(ACCOUNTING_CONTRACTS_TYPES)
)

export const accountingContractsAssignSelector = createSelector(
  accountingContractsSelectors,
  prop(ACCOUNTING_CONTRACTS_ASSIGN)
)
