import { equals, propOr } from 'ramda'
import { ChangeEvent, ComponentPropsWithRef, FocusEvent, forwardRef, useEffect } from 'react'
import { FieldError } from 'react-hook-form'
import InputMask, { Props } from 'react-input-mask'
import { FormattedMessage } from 'react-intl'
import { FormGroup, FormGroupProps, FormText, Label } from 'reactstrap'

type InputMaskProps = Omit<Props, 'onChange' | 'mask'> &
  ComponentPropsWithRef<'input'> & {
    label?: string
    className?: string
    // id?: string
    active?: boolean
    mask?: string | (string | RegExp)[]
    value?: string | number
    onChange?: (e: ChangeEvent<HTMLInputElement> | string | number) => void
    disabled?: boolean
    handleChange?: (value: string | number) => void
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void
    error?: FieldError
    placeholder?: string
    formGroupProps?: FormGroupProps
    invalid?: boolean
    type?: 'tel' | string
    beforeMaskedValueChange?: Record<string, unknown>
    formatChars?: string
    onUnmount?: () => void
  }

const PhoneField = forwardRef<HTMLInputElement, InputMaskProps>((props, ref) => {
  const {
    label,
    className = '',
    id = '',
    active,
    autoFocus,
    value,
    onChange,
    disabled,
    handleChange = () => {},
    onBlur,
    error,
    placeholder = '(___) __-___-____',
    formGroupProps = {},
    invalid,
    mask = '(99) 999-99-99',
    type = 'tel',
    maskChar = '_',
    beforeMaskedValueChange,
    formatChars,
    onUnmount,
  } = props

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  function change(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    if (value) {
      onChange && onChange(value)
      handleChange(value)
    }
  }

  const beforeMaskedValueChangeProp = beforeMaskedValueChange ? { beforeMaskedValueChange } : {}

  return (
    <FormGroup {...formGroupProps}>
      {label && <Label {...htmlFor}>{label}</Label>}

      <InputMask
        // {...props}
        value={value}
        className={`form-control ${error || invalid ? 'is-invalid' : ''} ${className}`}
        onChange={change}
        onBlur={onBlur}
        // active={active}
        {...htmlId}
        type={type}
        maskChar={maskChar}
        autoComplete='new-password'
        mask={mask}
        disabled={disabled}
        readOnly={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        inputRef={ref}
        {...beforeMaskedValueChangeProp}
        // formatChars={}
        // invalid={error || invalid ? 'true' : undefined}
      />

      {error && (
        // <FormText color='danger'>{propOr('This field is', 'message', error)}</FormText>
        <FormText color='danger'>
          <FormattedMessage
            id={equals(error?.type, 'required') ? 'form_required' : propOr('form_required', 'message', error)}
          />
        </FormText>
      )}
    </FormGroup>
  )
})

export default PhoneField
