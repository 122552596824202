import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'
import { DefaultState } from '@src/redux/types'

import { GLOBAL_STATE, TYPES, TYPES_CHILD } from '../constants/actionTypes'
import * as API from '../constants/api'
import { types, typesChild, typesChildClear, typesClear } from './actions'

const defaultState = () => ({ loading: false, error: null, data: null })

const initialState: () => {
  [TYPES]: DefaultState<`${typeof API.TYPES}`, 'get'>
  [TYPES_CHILD]: DefaultState<`${typeof API.TYPES_CHILD}`, 'get'>
} = () => ({
  [TYPES]: defaultState(),
  [TYPES_CHILD]: defaultState(),
})

export const TYPE = GLOBAL_STATE

export const { reducer: typesReducer } = createSlice({
  name: TYPE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(types.pending, state => {
        state[TYPES].loading = true
      })
      .addCase(types.rejected, (state, action) => {
        state[TYPES].loading = false
        state[TYPES].error = action.payload as AxiosError
      })
      .addCase(types.fulfilled, (state, action) => {
        state[TYPES].loading = false
        state[TYPES].error = null
        state[TYPES].data = action.payload
      })

    builder
      .addCase(typesChild.pending, state => {
        state[TYPES_CHILD].loading = true
      })
      .addCase(typesChild.rejected, (state, action) => {
        state[TYPES_CHILD].loading = false
        state[TYPES_CHILD].error = action.payload as AxiosError
      })
      .addCase(typesChild.fulfilled, (state, action) => {
        state[TYPES_CHILD].loading = false
        state[TYPES_CHILD].error = null
        state[TYPES_CHILD].data = action.payload
      })

    builder.addCase(typesClear, state => {
      state[TYPES] = defaultState()
    })

    builder.addCase(typesChildClear, state => {
      state[TYPES_CHILD] = defaultState()
    })
  },
})
