import { DefaultState } from '@src/redux/types'

import { REPORT, REPORT_CREATE, REPORT_FIELDS, REPORT_FIELDS_FILTER, REPORT_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [REPORT]: DefaultState<`${typeof API.REPORT}{id}`, 'get'>
  [REPORT_CREATE]: DefaultState<`${typeof API.REPORT}`, 'post'>
  [REPORT_FIELDS]: DefaultState<`${typeof API.REPORT_FIELDS}`, 'get'>
  [REPORT_FIELDS_FILTER]: DefaultState<`${typeof API.REPORT_FIELDS_FILTER}`, 'get'>
  [REPORT_LIST]: DefaultState<`${typeof API.REPORT_LIST}`, 'get'>
} = () => ({
  [REPORT]: defaultState(),
  [REPORT_CREATE]: defaultState(),
  [REPORT_FIELDS]: defaultState(),
  [REPORT_FIELDS_FILTER]: defaultState(),
  [REPORT_LIST]: defaultState(),
})
