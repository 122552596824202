import { DefaultState } from '@src/redux/types'

import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [TYPES.OFFENCE]: DefaultState<`${typeof API.OFFENCE}{id}`, 'get'>
  [TYPES.OFFENCE_ALL]: DefaultState<`${typeof API.OFFENCE_ALL}`, 'get'>
  [TYPES.OFFENCE_CHANGE_STATUS]: DefaultState<`${typeof API.OFFENCE_CHANGE_STATUS}`, 'put'>
  [TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS]: DefaultState<`${typeof API.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS}`, 'put'>
  [TYPES.OFFENCE_CREATE]: DefaultState<`${typeof API.OFFENCE}`, 'post'>
  [TYPES.OFFENCE_DELETE]: DefaultState<`${typeof API.OFFENCE}{id}`, 'delete'>
  [TYPES.OFFENCE_INVIGILATION]: DefaultState<`${typeof API.OFFENCE_INVIGILATION}{id}`, 'get'>
  [TYPES.OFFENCE_INVIGILATION_ALL]: DefaultState<`${typeof API.OFFENCE_INVIGILATION_ALL}`, 'get'>
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS]: DefaultState<
    `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}{id}`,
    'get'
  >
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL]: DefaultState<
    `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS_ALL}`,
    'get'
  >
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE]: DefaultState<
    `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}`,
    'post'
  >
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE]: DefaultState<
    `${typeof API.OFFENCE_INVIGILATION_CONSULTATIONS}{id}`,
    'delete'
  >
  [TYPES.OFFENCE_INVIGILATION_CREATE]: DefaultState<`${typeof API.OFFENCE_INVIGILATION}`, 'post'>
  [TYPES.OFFENCE_INVIGILATION_DELETE]: DefaultState<`${typeof API.OFFENCE_INVIGILATION}{id}`, 'delete'>
  [TYPES.OFFENCE_INVIGILATION_MINUTES]: DefaultState<`${typeof API.OFFENCE_INVIGILATION_MINUTES}{id}`, 'get'>
  [TYPES.OFFENCE_INVIGILATION_MINUTES_ALL]: DefaultState<`${typeof API.OFFENCE_INVIGILATION_MINUTES_ALL}`, 'get'>
  [TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE]: DefaultState<`${typeof API.OFFENCE_INVIGILATION_MINUTES}`, 'post'>
  [TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE]: DefaultState<`${typeof API.OFFENCE_INVIGILATION_MINUTES}{id}`, 'delete'>
  [TYPES.OFFENCE_LIST]: DefaultState<`${typeof API.OFFENCE}`, 'get'>
  [TYPES.OFFENCE_PUBLISH]: DefaultState<`${typeof API.OFFENCE_PUBLISH}`, 'put'>
  [TYPES.OFFENCE_REPORT_ALL]: DefaultState<`${typeof API.OFFENCE_REPORT_ALL}`, 'get'>
  [TYPES.OFFENCE_REPORT_CREATE]: DefaultState<`${typeof API.OFFENCE_REPORT}`, 'post'>
  [TYPES.OFFENCE_REPORT_LIST]: DefaultState<`${typeof API.OFFENCE_REPORT}`, 'get'>
  [TYPES.OFFENCE_TYPE]: DefaultState<`${typeof API.OFFENCE_TYPE}{id}`, 'get'>
  [TYPES.OFFENCE_TYPE_ALL]: DefaultState<`${typeof API.OFFENCE_TYPE_ALL}`, 'get'>
  [TYPES.OFFENCE_TYPE_CREATE]: DefaultState<`${typeof API.OFFENCE_TYPE}`, 'post'>
  [TYPES.OFFENCE_TYPE_DELETE]: DefaultState<`${typeof API.OFFENCE_TYPE}{id}`, 'delete'>
  [TYPES.OFFENCE_UNPUBLISH]: DefaultState<`${typeof API.OFFENCE_UNPUBLISH}`, 'put'>
  [TYPES.REPORT_MISCONDUCT]: DefaultState<`${typeof API.REPORT_MISCONDUCT}`, 'post'>
} = () => ({
  [TYPES.OFFENCE]: defaultState(),
  [TYPES.OFFENCE_ALL]: defaultState(),
  [TYPES.OFFENCE_CHANGE_STATUS]: defaultState(),
  [TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS]: defaultState(),
  [TYPES.OFFENCE_CREATE]: defaultState(),
  [TYPES.OFFENCE_DELETE]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_ALL]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_CREATE]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_DELETE]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_MINUTES]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_MINUTES_ALL]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE]: defaultState(),
  [TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE]: defaultState(),
  [TYPES.OFFENCE_LIST]: defaultState(),
  [TYPES.OFFENCE_PUBLISH]: defaultState(),
  [TYPES.OFFENCE_REPORT_ALL]: defaultState(),
  [TYPES.OFFENCE_REPORT_CREATE]: defaultState(),
  [TYPES.OFFENCE_REPORT_LIST]: defaultState(),
  [TYPES.OFFENCE_TYPE]: defaultState(),
  [TYPES.OFFENCE_TYPE_ALL]: defaultState(),
  [TYPES.OFFENCE_TYPE_CREATE]: defaultState(),
  [TYPES.OFFENCE_TYPE_DELETE]: defaultState(),
  [TYPES.OFFENCE_UNPUBLISH]: defaultState(),
  [TYPES.REPORT_MISCONDUCT]: defaultState(),
})
