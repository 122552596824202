import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { CATEGORY, CATEGORY_ALL, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const category = createAsyncThunk(
  CATEGORY,
  async (params: PayloadCreatorParams<`${typeof API.CATEGORY}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.category(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const categoryAll = createAsyncThunk(
  CATEGORY_ALL,
  async (params: PayloadCreatorParams<`${typeof API.CATEGORY_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.categoryAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const categoryCreate = createAsyncThunk(
  CATEGORY_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.CATEGORY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.categoryCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const categoryDelete = createAsyncThunk(
  CATEGORY_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.CATEGORY}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.categoryDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const categoryList = createAsyncThunk(
  CATEGORY_LIST,
  async (params: PayloadCreatorParams<`${typeof API.CATEGORY}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.categoryList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const categoryClear = createAction(`${CATEGORY}/clear`)

export const categoryAllClear = createAction(`${CATEGORY_ALL}/clear`)

export const categoryListClear = createAction(`${CATEGORY_LIST}/clear`)
