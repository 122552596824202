import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'
import { DefaultState } from '@src/redux/types'

import { GLOBAL_STATE, INTRO_VIDEOS } from '../constants/actionTypes'
import * as API from '../constants/api'
import * as actionCreators from './actions'

const defaultState = () => ({ loading: false, error: null, data: null })

const initialState: () => {
  [INTRO_VIDEOS]: DefaultState<`${typeof API.VIDEO_TUTORIAL}`, 'get'>
} = () => ({
  [INTRO_VIDEOS]: defaultState(),
})

export const INTTRO_VIDEO = GLOBAL_STATE

export const { reducer: introVideosReducer } = createSlice({
  name: INTTRO_VIDEO,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.introVideos.pending, state => {
        state[INTRO_VIDEOS].loading = true
      })
      .addCase(actionCreators.introVideos.rejected, (state, action) => {
        state[INTRO_VIDEOS].loading = false
        state[INTRO_VIDEOS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.introVideos.fulfilled, (state, action) => {
        state[INTRO_VIDEOS].loading = false
        state[INTRO_VIDEOS].error = null
        state[INTRO_VIDEOS].data = action.payload
      })

    builder.addCase(actionCreators.introVideosClear, state => {
      state[INTRO_VIDEOS] = defaultState()
    })
  },
})
