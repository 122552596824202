import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { SHORT_COURSE_REGISTRATION_CREATE } from '../constants/actionTypes'
import * as API from '../constants/api'

export const shortCourseRegistrationCreate = createAsyncThunk(
  SHORT_COURSE_REGISTRATION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.shortCourseRegistrationCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
// export const shortCourseRegistration = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION].pending,
//   payload,
// })

// export const shortCourseRegistrationClear = () => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION].clear,
// })

// export const shortCourseRegistrationDelete = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_DELETE].pending,
//   payload,
// })

// export const shortCourseRegistrationList = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_LIST].pending,
//   payload,
// })

// export const shortCourseRegistrationListClear = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_LIST].clear,
//   payload,
// })

// export const shortCourseRegistrationAll = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_ALL].pending,
//   payload,
// })

// export const shortCourseRegistrationAllClear = () => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_ALL].clear,
// })

// export const shortCourseRegistrationTypes = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_TYPES].pending,
//   payload,
// })

// export const shortCourseRegistrationTypesClear = () => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_TYPES].clear,
// })

// export const shortCourseRegistrationChangeStatus = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_STATUS].pending,
//   payload,
// })

// export const shortCourseRegistrationFieldBatch = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_FIELD_BATCH].pending,
//   payload,
// })

// export const shortCourseRegistrationFieldBatchClear = () => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_FIELD_BATCH].clear,
// })

// export const shortCourseRegistrationFieldBatchCreate = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_FIELD_BATCH_CREATE].pending,
//   payload,
// })

// export const shortCourseRegistrationCategory = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_CATEGORY].pending,
//   payload,
// })

// export const shortCourseRegistrationCategoryCreate = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_CATEGORY_CREATE].pending,
//   payload,
// })

// export const shortCourseRegistrationCategoryDelete = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_CATEGORY_DELETE].pending,
//   payload,
// })

// export const shortCourseRegistrationCategoryList = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_CATEGORY_LIST].pending,
//   payload,
// })

// export const shortCourseRegistrationCategoryListClear = () => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_CATEGORY_LIST].clear,
// })

// export const shortCourseRegistrationCategoryAll = payload => ({
//   type: actionTypes[SHORT_COURSE_REGISTRATION_CATEGORY_ALL].pending,
//   payload,
// })

// export const shortCourseRegistrationRequest = payload => ({
//   type: actionTypes[TYPES.SHORT_COURSE_REGISTRATION_REQUEST].pending,
//   payload,
// })

// export const shortCourseRegistrationRequestAll = payload => ({
//   type: actionTypes[TYPES.SHORT_COURSE_REGISTRATION_REQUEST_ALL].pending,
//   payload,
// })

// export const shortCourseRegistrationRequestEdit = payload => ({
//   type: actionTypes[TYPES.SHORT_COURSE_REGISTRATION_REQUEST_EDIT].pending,
//   payload,
// })

// export const shortCourseRegistrationRequestEditClear = () => ({
//   type: actionTypes[TYPES.SHORT_COURSE_REGISTRATION_REQUEST_EDIT].clear,
// })

// export const shortCourseRegistrationRequestUpdate = payload => ({
//   type: actionTypes[TYPES.SHORT_COURSE_REGISTRATION_REQUEST_UPDATE].pending,
//   payload,
// })
