import { DefaultState } from '@src/redux/types'

import {
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_DELETE,
  ASSESSMENT_EC_LIST,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ASSESSMENT_EC_LIST]: DefaultState<`${typeof API.ASSESSMENT_EC}`, 'get'>
  [ASSESSMENT_EC]: DefaultState<`${typeof API.ASSESSMENT_EC}{id}`, 'get'>
  [ASSESSMENT_EC_ALL]: DefaultState<`${typeof API.ASSESSMENT_EC_ALL}`, 'get'>
  [ASSESSMENT_EC_CREATE]: DefaultState<`${typeof API.ASSESSMENT_EC}`, 'post'>
  [ASSESSMENT_EC_DELETE]: DefaultState<`${typeof API.ASSESSMENT_EC}{id}`, 'delete'>
  [ASSESSMENT_EC_OUTCOME_REPORT]: DefaultState<`${typeof API.ASSESSMENT_EC_OUTCOME_REPORT}`, 'post'>
  [ASSESSMENT_EC_PAPER]: DefaultState<`${typeof API.ASSESSMENT_EC_PAPER}`, 'post'>
  [ASSESSMENT_EC_STATUS]: DefaultState<`${typeof API.ASSESSMENT_EC_CHANGE_STATUS}`, 'put'>
  [ASSESSMENT_EC_UPDATE]: DefaultState<`${typeof API.ASSESSMENT_EC_UPDATE}`, 'post'>
} = () => ({
  [ASSESSMENT_EC]: defaultState(),
  [ASSESSMENT_EC_LIST]: defaultState(),
  [ASSESSMENT_EC_ALL]: defaultState(),
  [ASSESSMENT_EC_CREATE]: defaultState(),
  [ASSESSMENT_EC_DELETE]: defaultState(),
  [ASSESSMENT_EC_OUTCOME_REPORT]: defaultState(),
  [ASSESSMENT_EC_PAPER]: defaultState(),
  [ASSESSMENT_EC_STATUS]: defaultState(),
  [ASSESSMENT_EC_UPDATE]: defaultState(),
})
