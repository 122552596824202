import { pipe, prop, equals } from 'ramda/es'
import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { useCss } from 'react-use'

export const RELATIONSHIP = {
  FATHER: 'FATHER',
  MOTHER: 'MOTHER',
}

function EnrollmentGuards(props) {
  const relationship = pipe(prop('relationship'), relationship => {
    if (equals(relationship, RELATIONSHIP.FATHER)) {
      return 'Father'
    }

    return 'Mother'
  })(props)
  const firstname = prop('firstname', props)
  const lastname = prop('lastname', props)
  const midname = prop('midname', props)
  // const nationality = prop('nationality', props)
  // const placeOfBirth = prop('place_of_birth', props)
  // const birthdate = prop('birthdate', props)

  const guardsTitleCss = useCss({
    '@media (max-width: 1150px)': {
      flex: '0 0 100%',
      maxWidth: '100%',
      justifyContent: 'center',
    },
  })

  return (
    <Fragment>
      <Row className='w-100 mb-3 mr-sm-1 ml-sm-1'>
        <Col className={`${guardsTitleCss} col-md-2 text-sm-center text-lg-left mb-sm-3`}>
          <span className='font-weight-bold pl-sm-0'>{relationship}:</span>
        </Col>
        <Col>
          <ul className='list-group '>
            <li className='list-group-item'>
              <span className='font-weight-bold'>First Name: </span>
              {firstname}
            </li>
            <li className='list-group-item'>
              <span className='font-weight-bold'>Last Name: </span>
              {lastname}
            </li>
            <li className='list-group-item'>
              <span className='font-weight-bold'>Middle Name: </span>
              {midname}
            </li>
          </ul>
        </Col>
      </Row>
    </Fragment>
  )
}

export default EnrollmentGuards
