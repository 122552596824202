import Avatar from '@components/avatar'
import { equals, type } from 'ramda'
import { X } from 'react-feather'
import { useIntl } from 'react-intl'

const ErrorToast = ({ text = '' }) => {
  const { formatMessage: msg } = useIntl()

  return (
    <>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='danger' icon={<X size={12} />} />
          <h6 className='toast-title'>{msg({ id: 'Error' })}!</h6>
        </div>
        {/*<small className='text-muted'>11 Min Ago</small>*/}
      </div>
      <div className='toastify-body'>
        <span aria-label='toast-text' style={{ wordBreak: 'break-word' }}>
          {text && msg({ id: equals(type(text), 'String') ? text : 'Error' })}
        </span>
      </div>
    </>
  )
}
export default ErrorToast
