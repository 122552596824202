export const GLOBAL_STATE = 'CCLSModule'

export const CCLS_MODULE = 'CCLS_MODULE' as const
export const CCLS_MODULE_CREATE = 'CCLS_MODULE_CREATE' as const
export const CCLS_MODULE_TEACHER_UPDATE = 'CCLS_MODULE_TEACHER_UPDATE' as const
export const CCLS_MODULE_DELETE = 'CCLSMODULE_DELETE' as const
export const CCLS_MODULE_LIST = 'CCLS_MODULE_LIST' as const
export const CCLS_MODULE_ALL = 'CCLS_MODULE_ALL' as const
export const CCLS_MODULES_FILTER = 'CCLS_MODULES_FILTER' as const
export const CCLS_MODULE_ARTICULATE = 'CCLS_MODULE_ARTICULATE' as const
export const CCLS_MODULE_ARTICULATE_ALL = 'CCLS_MODULE_ARTICULATE_ALL' as const
export const CCLS_MODULE_RETAKE_UPLOAD = 'CCLS_MODULE_RETAKE_UPLOAD' as const
