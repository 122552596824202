import { ApiFunc, getInstance } from '@helpers/httpClient'
import { prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

// export const assessment: ApiFunc<`${typeof API.ASSESSMENT}`, 'get'> = data =>
//   instance.get(`${API.ASSESSMENT}${'id' in data ? prop('id', data) : data.name}`, {
//     params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
//   })

export const assessmentCreate: ApiFunc<`${typeof API.ASSESSMENT}`, 'post'> = data => instance.post(API.ASSESSMENT, data)

export const assessmentStudentUpload: ApiFunc<`${typeof API.ASSESSMENT_STUDENT_UPLOAD}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_STUDENT_UPLOAD, data)

// export const assessmentDelete: ApiFunc<`${typeof API.ASSESSMENT}`, 'delete'> = data =>
//   instance.delete(`${API.ASSESSMENT}${'id' in data ? prop('id', data) : data.name}`)

export const assessmentList: ApiFunc<`${typeof API.ASSESSMENT}`, 'get'> = data =>
  instance.get(API.ASSESSMENT, {
    params: data,
  })

export const assessmentBulkCreate: ApiFunc<`${typeof API.ASSESSMENT_BULK}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_BULK, data)

export const assessmentAttemptCreate: ApiFunc<`${typeof API.ASSESSMENT_ATTEMPT}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_ATTEMPT, data)

export const assessmentAttempt: ApiFunc<`${typeof API.ASSESSMENT_ATTEMPT}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_ATTEMPT, {
    params: data,
  })

// export const assessmentAttemptDelete: ApiFunc<`${typeof API.ASSESSMENT_ATTEMPT}`, 'delete'> = data =>
//   instance.delete(`${API.ASSESSMENT_ATTEMPT}${'id' in data ? prop('id', data) : data.name}`)

export const assessmentCopy: ApiFunc<`${typeof API.ASSESSMENT_COPY}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_COPY, data)

export const assessmentSubmit: ApiFunc<`${typeof API.ASSESSMENT_SUBMIT}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_SUBMIT, data)

export const assessmentCriteriaUpdate: ApiFunc<`${typeof API.ASSESSMENT_CRITERIA}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_CRITERIA, data)

export const assessmentRetake: ApiFunc<`${typeof API.ASSESSMENT_RETAKE}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_RETAKE, data)

export const assessmentRetakeDelete: ApiFunc<`${typeof API.ASSESSMENT_RETAKE}`, 'delete'> = data =>
  instance.delete(API.ASSESSMENT_RETAKE, { params: data })

export const assessmentCriteriaList: ApiFunc<`${typeof API.ASSESSMENT_CRITERIA_LIST}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_CRITERIA_LIST, {
    params: data,
  })

export const attemptDelete: ApiFunc<`${typeof API.ASSESSMENT_ATTEMPT}`, 'delete'> = data =>
  instance.delete(API.ASSESSMENT_ATTEMPT, {
    params: data,
  })

export const assessmentCriteriaTypes: ApiFunc<`${typeof API.ASSESSMENT_CRITERIA_TYPES}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_CRITERIA_TYPES, {
    params: data,
  })

export const assessmentStudent: ApiFunc<`${typeof API.ASSESSMENT_STUDENT}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_STUDENT, {
    params: data,
  })

export const assessmentStudentUpdated: ApiFunc<`${typeof API.ASSESSMENT_STUDENT_UPDATED}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_STUDENT_UPDATED, {
    params: data,
  })

export const assessmentComponentCreate: ApiFunc<`${typeof API.ASSESSMENT_COMPONENT}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_COMPONENT, data)

export const assessmentComponentDelete: ApiFunc<`${typeof API.ASSESSMENT_COMPONENT}{id}`, 'delete'> = data =>
  instance.delete(`${API.ASSESSMENT_COMPONENT}${'id' in data ? prop('id', data) : data.name}`)

export const assessmentStudentCriteriaDetails: ApiFunc<
  `${typeof API.ASSESSMENT_STUDENT_CRITERIA_DETAILS}`,
  'get'
> = data =>
  instance.get(API.ASSESSMENT_STUDENT_CRITERIA_DETAILS, {
    params: data,
  })

export const assessmentOverallGradeHistory: ApiFunc<`${typeof API.ASSESSMENT_OVERALL_GRADE_HISTORY}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_OVERALL_GRADE_HISTORY, {
    params: data,
  })
