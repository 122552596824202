import axios from 'axios'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'

export const toastError = (e: unknown) => {
  let error: { message: string; status?: number } = { message: 'An unknown error occurred: ' }
  if (axios.isAxiosError(e)) {
    const status = e.response?.status
    const message = pathOr('Something went wrong', ['response', 'data', 'message'], e)
    toast.error(message, { style: { wordBreak: 'break-word' } })
    error.message = message
    error.status = status
  } else {
    error = (e as Record<string, Error>)?.data
    toast.error(pathOr('Something went wrong', ['data', 'message'], e), { style: { wordBreak: 'break-word' } })
    console.error(error.message, e)
  }
  return error
}
