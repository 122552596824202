import {
  ACADEMIC_MENTOR,
  ADMIN,
  ADMIN_VIEWER,
  AMO_CRM_OPERATOR,
  BACHELOR_STUDENT,
  COURSE_LEADER,
  DEAN,
  MASTER_STUDENT,
  MODULE_LEADER,
  PROGRAMME_MANAGER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  STUDENTS_DEPARTMENT,
  SUPER_ADMIN,
  SYSTEM_MODERATOR,
  TEACHER,
  TEACHING_AND_LEARNING_OFFICER,
  TEST_STUDENT,
} from '@constants/userRoles'
import { lazy } from 'react'

import { LESSONS } from '../../Lesson/constants/route'
import { ATTENDANCE_LESSONS, ATTENDANCE_LIST, ATTENDANCE_STUDENTS } from '../constants/route'

export const AttendanceRoutes = [
  {
    path: LESSONS,
    component: lazy(() => import('../views/Attendance')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_HELPER,
        PROGRAMME_MANAGER,
        TEACHING_AND_LEARNING_OFFICER,
        TEACHER,
        MODULE_LEADER,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        COURSE_LEADER,
        DEAN,
        SYSTEM_MODERATOR,
        ADMIN_VIEWER,
      ],
    },
  },
  {
    path: ATTENDANCE_LESSONS,
    component: lazy(() => import('../components/AttendanceLessons')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        TEACHER,
        MODULE_LEADER,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        COURSE_LEADER,
        ADMIN,
        DEAN,
        SYSTEM_MODERATOR,
        ADMIN_VIEWER,
      ],
    },
  },
  {
    path: ATTENDANCE_STUDENTS,
    component: lazy(() => import('../components/AttendanceStudents')),
    exact: true,
    appLayout: true,
    className: 'email-application',
    meta: {
      access: [
        SUPER_ADMIN,
        TEACHER,
        MODULE_LEADER,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        COURSE_LEADER,
        ADMIN,
        STUDENTS_DEPARTMENT,
        DEAN,
        ADMIN_VIEWER,
      ],
      props: {
        type: 'ATTENDANCE',
      },
    },
  },
  {
    path: ATTENDANCE_LIST,
    component: lazy(() => import('../views/AttendanceList')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        TEACHER,
        TEACHING_AND_LEARNING_OFFICER,
        PROGRAMME_MANAGER,
        BACHELOR_STUDENT,
        MASTER_STUDENT,
        TEST_STUDENT,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_HELPER,
        ACADEMIC_MENTOR,
        DEAN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        COURSE_LEADER,
        STUDENTS_DEPARTMENT,
        MODULE_LEADER,
        AMO_CRM_OPERATOR,
        ADMIN_VIEWER,
      ],
    },
  },
]
