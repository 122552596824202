import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const userOpenLink: ApiFunc<`${typeof API.USER_OPEN_LINK}`, 'post'> = data =>
  instance.post(API.USER_OPEN_LINK, data)

export const checkUserOpenLink: ApiFunc<`${typeof API.USER_OPEN_LINK}`, 'get'> = data =>
  instance.get(API.USER_OPEN_LINK, {
    params: data,
  })

export const userPublicInfo: ApiFunc<`${typeof API.USER_PUBLIC_INFO}`, 'get'> = data =>
  instance.get(API.USER_PUBLIC_INFO, {
    params: data,
  })
