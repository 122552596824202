import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/actionTypes'

const moduleFileSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const moduleFileListSelector = createSelector(moduleFileSelectors, prop(MODULE_FILE_LIST))

export const moduleFileAllSelector = createSelector(moduleFileSelectors, prop(MODULE_FILE_ALL))

export const moduleFileSelector = createSelector(moduleFileSelectors, prop(MODULE_FILE))

export const moduleFileCreateSelector = createSelector(moduleFileSelectors, prop(MODULE_FILE_CREATE))

export const moduleFileDeleteSelector = createSelector(moduleFileSelectors, prop(MODULE_FILE_DELETE))
