import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

// export const shortCourseRegistration: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION_FORM}{id}`, 'get'> = data =>
//   instance.get(`${API.SHORT_COURSE_REGISTRATION_FORM}${prop('id', data)}`, {
//     params: dissoc('id', data),
//   })

export const shortCourseRegistrationCreate: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'post'> = data =>
  instance.post(API.SHORT_COURSE_REGISTRATION, data)

// export const shortCourseRegistrationDelete: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.delete(`${API.SHORT_COURSE_REGISTRATION_FORM}${prop('id', data)}`)

// export const shortCourseRegistrationList: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.SHORT_COURSE_REGISTRATION, {
//     params: data,
//   })

// export const shortCourseRegistrationAll: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.SHORT_COURSE_REGISTRATION_ALL, {
//     params: data,
//   })

// export const shortCourseRegistrationTypes: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.SHORT_COURSE_REGISTRATION_TYPES, {
//     params: data,
//   })

// export const shortCourseRegistrationStatus: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data => instance.put(API.SHORT_COURSE_REGISTRATION_STATUS, data)

// export const shortCourseRegistrationFieldBatchCreate: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.post(API.SHORT_COURSE_REGISTRATION_FIELD_BATCH, data)

// export const shortCourseRegistrationFieldBatch: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(`${API.SHORT_COURSE_REGISTRATION_FIELD_BATCH}${prop('id', data)}`, {
//     params: dissoc('id', data),
// })

//category
// export const shortCourseRegistrationCategory: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(`${API.HR_APPLICATION_CATEGORY}${prop('id', data)}`, {
//     params: dissoc('id', data),
//   })

// export const shortCourseRegistrationCategoryCreate: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data => instance.post(API.HR_APPLICATION_CATEGORY, data)

// export const shortCourseRegistrationCategoryDelete: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.delete(`${API.HR_APPLICATION_CATEGORY}${prop('id', data)}`)

// export const shortCourseRegistrationCategoryList: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.HR_APPLICATION_CATEGORY, {
//     params: data,
//   })

// export const shortCourseRegistrationCategoryAll: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.HR_APPLICATION_CATEGORY_ALL, {
//     params: data,
//   })

// //request
// export const shortCourseRegistrationRequest: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data => instance.post(API.HR_APPLICATION_REQUEST, data)

// export const shortCourseRegistrationRequestAll: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.HR_APPLICATION_REQUEST_ALL, {
//     params: data,
//   })

// export const shortCourseRegistrationRequestEdit: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data =>
//   instance.get(API.HR_APPLICATION_REQUEST_EDIT, {
//     params: data,
//   })

// export const shortCourseRegistrationRequestUpdate: ApiFunc<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'get'> = data => instance.put(API.HR_APPLICATION_REQUEST_UPDATE, data)
