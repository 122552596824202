import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_DELETE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
  ENROLLMENT_STUDENT_OWN,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const enrollment = createAsyncThunk(
  ENROLLMENT,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollment(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentCreate = createAsyncThunk(
  ENROLLMENT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentDelete = createAsyncThunk(
  ENROLLMENT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentList = createAsyncThunk(
  ENROLLMENT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentAll = createAsyncThunk(
  ENROLLMENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentStatus = createAsyncThunk(
  ENROLLMENT_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentCourses = createAsyncThunk(
  ENROLLMENT_COURSES,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT_COURSES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentCourses(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentStudentOwn = createAsyncThunk(
  ENROLLMENT_STUDENT_OWN,
  async (params: PayloadCreatorParams<`${typeof API.ENROLLMENT_STUDENT_OWN}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.enrollmentStudentOwn(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const enrollmentClear = createAction(`${ENROLLMENT}/clear`)

export const enrollmentCreateClear = createAction(`${ENROLLMENT_CREATE}/clear`)

export const enrollmentListClear = createAction(`${ENROLLMENT_LIST}/clear`)

export const enrollmentAllClear = createAction(`${ENROLLMENT_ALL}/clear`)

export const enrollmentStatusClear = createAction(`${ENROLLMENT_STATUS}/clear`)

export const enrollmentCoursesClear = createAction(`${ENROLLMENT_COURSES}/clear`)

export const enrollmentStudentOwnClear = createAction(`${ENROLLMENT_STUDENT_OWN}/clear`)
