// ** Custom Components
// ** Default Avatar Image
import defaultAvatar from '@assets/images/avatars/avatar_placeholder.jpg'
import Avatar from '@components/avatar'
import { removeEduForm } from '@helpers/storageEduForm'
import { removeInstitution, removeInstitutionOption } from '@helpers/storageInstitution'
import { removeLanguage, removeLanguageOption } from '@helpers/storageLanguage'
import { getToken, removeToken, removeUserInfo } from '@helpers/storageToken'
// Helpers
import { getUserInfo } from '@helpers/storageUserInfo'
// ** Utils
// import { isUserLoggedIn } from '@utils'
// ** Store & Actions
import { useAppDispatch } from '@hooks'
import { clearStorageMenu } from '@src/helpers/storageLayout'
import { authLogout } from '@store/actions'
import { prop, propOr } from 'ramda'
// ** React Imports
import { useEffect, useState } from 'react'
import { Lock, Power } from 'react-feather'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
// ** Third Party Components
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const UserDropdown = ({ openModal }) => {
  // ** Store Vars
  const dispatch = useAppDispatch()
  const { formatMessage: msg } = useIntl()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (getToken() !== null) {
      setUserData(getUserInfo())
    }
  }, [])

  //** Vars
  const userAvatar = propOr(defaultAvatar, 'avatar', userData)
  const firstname = prop('firstname', userData)
  const lastname = prop('lastname', userData)
  const fullname = userData ? `${firstname} ${lastname}` : 'Unknown'
  const email = propOr('Anonymous', 'email', userData)
  const username = propOr(email, 'username', userData)

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-flex d-none'>
          <span className='user-name font-weight-bold'>{fullname}</span>
          <span className='user-status'>{username}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>

      <DropdownMenu right>
        {/*<DropdownItem tag={Link} to='#' onClick={e => e.preventDefault()}>*/}
        {/*  <User size={14} className='mr-75' />*/}
        {/*  <span className='align-middle'>Profile</span>*/}
        {/*</DropdownItem>*/}

        {/*<DropdownItem tag={Link} to='#' onClick={e => e.preventDefault()}>*/}
        {/*  <Mail size={14} className='mr-75' />*/}
        {/*  <span className='align-middle'>Inbox</span>*/}
        {/*</DropdownItem>*/}

        {/*<DropdownItem tag={Link} to='#' onClick={e => e.preventDefault()}>*/}
        {/*  <CheckSquare size={14} className='mr-75' />*/}
        {/*  <span className='align-middle'>Tasks</span>*/}
        {/*</DropdownItem>*/}

        {/*<DropdownItem tag={Link} to='#' onClick={e => e.preventDefault()}>*/}
        {/*  <MessageSquare size={14} className='mr-75' />*/}
        {/*  <span className='align-middle'>Chats</span>*/}
        {/*</DropdownItem>*/}

        <DropdownItem onClick={openModal}>
          <Lock size={14} className='mr-75' />
          <span className='align-middle'>{msg({ id: 'Reset password' })}</span>
        </DropdownItem>

        <DropdownItem
          tag={Link}
          to='/login'
          onClick={() =>
            dispatch(() => {
              removeToken()
              removeUserInfo()
              removeInstitution()
              removeLanguage()
              removeEduForm()
              removeInstitutionOption()
              removeLanguageOption()
              dispatch(authLogout())
              clearStorageMenu()
            })
          }
        >
          <Power size={14} className='mr-75' />
          <span className='align-middle'>{msg({ id: 'logout' })}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
