import { dissoc, equals, trim, prop } from 'ramda/es'
import React, { Fragment, useMemo } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { indexedMap } from '../../helpers/indexedMap'

/* THIS COMPONENT SHOULD BE TESTED IN OLDER VERSIONS OF REACT-HOOK-FORM. WORKS ON v7.12.2 */

function CodeInputField(props) {
  const {
    label,
    placeholder,
    disabled,
    className,
    style = {},
    id = '',
    handleChange = () => {},
    register,
    value,
    name,
    errors,
    formGroupStyles,
    formGroupClassNames,
    invalid,
    required = true,
    length = 6,
    setValue,
    setFocus,
    formTextProps = {
      color: 'danger',
    },
  } = props

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  const mockArray = useMemo(() => new Array(length).fill(0), [])

  function onKeyPress(e, onChange, idx) {
    if (e.key.match(/[0-9]/g)) {
      const isLast = idx + 1 === mockArray.length
      e.preventDefault()
      setValue(`code.${idx}`, e.key)
      !isLast &&
      setFocus(`code.${idx + 1}`)
    } else if (equals(e.key, 'Backspace')) {
      const isFirst = idx - 1 >= 0
      e.preventDefault()
      setValue(`code.${idx}`, '')
      isFirst &&
      setFocus(`code.${idx - 1}`)
    }
  }

  function onPaste(e) {
    const paste = trim((e.clipboardData || window.clipboardData).getData('text'))
    const numbers = paste.match(/([0-9])/g)
    numbers &&
    numbers.forEach((digit, idx) => {
      idx < mockArray.length &&
      setValue(`code.${idx}`, digit)
    })
    e.preventDefault()
  }

  const onWheel = e => {
    e &&
    e.target.blur()
  }

  const formGroupStylesProps = formGroupStyles ? { style: formGroupStyles } : {}
  const formGroupClassNamesProps = formGroupClassNames ? { className: formGroupClassNames } : {}
  return (
    <div className='auth-input-wrapper d-flex align-items-center justify-content-between'>
      <FormGroup className='d-flex mb-0' {...formGroupStylesProps} {...formGroupClassNamesProps}>
        {label && <Label {...htmlFor}>{label}</Label>}
        {
          indexedMap((_, idx) => {
            const { ref: registeredRef, ...rest } = register(`${name}.${idx}`, { required })
            // const error = path([name, idx], errors)
            return (
              <Fragment key={`codeInput-${idx}`}>
                <Input
                  {...dissoc('onChange', rest)}
                  label={label}
                  placeholder={placeholder}
                  min='1'
                  max='1'
                  type='number'
                  defaultValue={value}
                  disabled={disabled}
                  onPaste={onPaste}
                  onKeyDown={e => onKeyPress(e, prop('onChange', rest), idx)}
                  className={`auth-input height-50 text-center numeral-mask mx-25 mb-1 form-control ${className}`}
                  {...htmlId}
                  onWheel={onWheel}
                  style={{ ...style }}
                  innerRef={registeredRef}
                  // invalid={!!invalid || !!error}
                />

                {/*{error && (*/}
                {/*  <FormText color='danger'>*/}
                {/*    {customErrorMessage ? (*/}
                {/*      equals(prop('type', error), 'min') ? (*/}
                {/*        `Value should not be less than ${min}`*/}
                {/*      ) : equals(prop('type', error), 'max') ? (*/}
                {/*        `Value should not exceed ${maxLength}`*/}
                {/*      ) : equals(prop('message', error), 'definite') ? (*/}
                {/*        `Value should be ${definite} characters long`*/}
                {/*      ) : (*/}
                {/*        prop('message', error)*/}
                {/*      )*/}
                {/*    ) : (*/}
                {/*      <FormattedMessage id={equals(prop('message', error), 'range') ? 'number_range' : 'form_required'} />*/}
                {/*    )}*/}
                {/*  </FormText>*/}
                {/*)}*/}
              </Fragment>
            )
          })(mockArray)
        }

      </FormGroup>
    </div>
  )
}

CodeInputField.defaultProps = {
  type: 'text',
  disabled: false,
  maxLength: '1000',
  style: {},
}

export default CodeInputField
