import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const jobOfferList: ApiFunc<`${typeof API.JOB_OFFER}`, 'get'> = data =>
  instance.get(API.JOB_OFFER, {
    params: data,
  })

export const jobOfferChangeStatus: ApiFunc<`${typeof API.JOB_OFFER_CHANGE_STATUS}`, 'put'> = data =>
  instance.put(API.JOB_OFFER_CHANGE_STATUS, data)

export const jobOfferCreate: ApiFunc<`${typeof API.JOB_OFFER}`, 'post'> = data => instance.post(API.JOB_OFFER, data)

export const jobOfferStudentAll: ApiFunc<`${typeof API.JOB_OFFER_STUDENT_ALL}`, 'get'> = data =>
  instance.get(API.JOB_OFFER_STUDENT_ALL, {
    params: data,
  })
