import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  STUDENT_CV,
  STUDENT_CV_CURATOR,
  STUDENT_CV_LIST,
  STUDENT_CV_PRINCIPAL,
} from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const studentCVSelector = createSelector(selectors, prop(STUDENT_CV))

export const studentCVListSelector = createSelector(selectors, prop(STUDENT_CV_LIST))

export const studentCVPrincipalSelector = createSelector(selectors, prop(STUDENT_CV_PRINCIPAL))

export const studentCVCuratorSelector = createSelector(selectors, prop(STUDENT_CV_CURATOR))
