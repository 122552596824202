import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  REPORT,
  REPORT_CREATE,
  REPORT_FIELDS,
  REPORT_FIELDS_FILTER,
  REPORT_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const REPORTS = GLOBAL_STATE

export const { reducer: reportReducer } = createSlice({
  name: REPORTS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.report.pending, state => {
        state[REPORT].loading = true
      })
      .addCase(actionCreators.report.rejected, (state, action) => {
        state[REPORT].loading = false
        state[REPORT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.report.fulfilled, (state, action) => {
        state[REPORT].loading = false
        state[REPORT].error = null
        state[REPORT].data = action.payload
      })

    builder
      .addCase(actionCreators.reportCreate.pending, state => {
        state[REPORT_CREATE].loading = true
      })
      .addCase(actionCreators.reportCreate.rejected, (state, action) => {
        state[REPORT_CREATE].loading = false
        state[REPORT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.reportCreate.fulfilled, (state, action) => {
        state[REPORT_CREATE].loading = false
        state[REPORT_CREATE].error = null
        state[REPORT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.reportFields.pending, state => {
        state[REPORT_FIELDS].loading = true
      })
      .addCase(actionCreators.reportFields.rejected, (state, action) => {
        state[REPORT_FIELDS].loading = false
        state[REPORT_FIELDS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.reportFields.fulfilled, (state, action) => {
        state[REPORT_FIELDS].loading = false
        state[REPORT_FIELDS].error = null
        state[REPORT_FIELDS].data = action.payload
      })

    builder
      .addCase(actionCreators.reportFieldsFilter.pending, state => {
        state[REPORT_FIELDS_FILTER].loading = true
      })
      .addCase(actionCreators.reportFieldsFilter.rejected, (state, action) => {
        state[REPORT_FIELDS_FILTER].loading = false
        state[REPORT_FIELDS_FILTER].error = action.payload as AxiosError
      })
      .addCase(actionCreators.reportFieldsFilter.fulfilled, (state, action) => {
        state[REPORT_FIELDS_FILTER].loading = false
        state[REPORT_FIELDS_FILTER].error = null
        state[REPORT_FIELDS_FILTER].data = action.payload
      })

    builder
      .addCase(actionCreators.reportList.pending, state => {
        state[REPORT_LIST].loading = true
      })
      .addCase(actionCreators.reportList.rejected, (state, action) => {
        state[REPORT_LIST].loading = false
        state[REPORT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.reportList.fulfilled, (state, action) => {
        state[REPORT_LIST].loading = false
        state[REPORT_LIST].error = null
        state[REPORT_LIST].data = action.payload
      })

    builder.addCase(actionCreators.reportClear, state => {
      state[REPORT] = defaultState()
    })

    builder.addCase(actionCreators.reportFieldsClear, state => {
      state[REPORT_FIELDS] = defaultState()
    })

    builder.addCase(actionCreators.reportFieldsFilterClear, state => {
      state[REPORT_FIELDS_FILTER] = defaultState()
    })

    builder.addCase(actionCreators.reportListClear, state => {
      state[REPORT_LIST] = defaultState()
    })
  },
})
