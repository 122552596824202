export const STUDENT = '/student/' as const
export const STUDENT_STATUS = `${STUDENT}status` as const
export const STUDENT_SEND_SMS = `${STUDENT}sms` as const
export const STUDENT_TRANSFER = `${STUDENT}transfer` as const
export const STUDENT_PROFILE = `${STUDENT}profile` as const
export const STUDENT_ALL = `${STUDENT}all` as const
export const STUDENT_GUARD = `${STUDENT}guard/` as const
export const STUDENT_USERNAME = `${STUDENT}username/` as const
export const STUDENT_ACHIEVEMENT_RECORDS = `${STUDENT}ar_records/` as const
export const STUDENT_PERSONAL_INFO = `${STUDENT}personal_info` as const
export const STUDENT_CONTACT_INFO = `${STUDENT}contact_info` as const
export const GENERATE_TRANSCRIPT = `${STUDENT}transcript` as const
export const STUDENT_DOCUMENT = `${STUDENT}document` as const
export const STUDENT_DEBIT_DETAIL = `${STUDENT}debit_detail/` as const
export const ACHIEVEMENT_RECORDS_STUDENT = `achievement_records/student/` as const
export const STUDENT_EXPORT_DATA = `${STUDENT}export_data` as const
export const STUDENT_CALL_LETTER_DELETE = `${STUDENT}call_letter/` as const
export const STUDENT_RESUME_STUDY = `${STUDENT}resume_study` as const
export const STUDENT_MARK_UPDATE = `${STUDENT}update_mark` as const
export const STUDENT_INVOICE_CREATE = `${STUDENT}create/invoice` as const
export const STUDENT_UPDATE_PASSPORT = `${STUDENT}update-passport` as const
export const STUDENT_CARD_PRINT = `${STUDENT}card/` as const
export const STUDENT_CARD_ALL = `${STUDENT_CARD_PRINT}all` as const
