import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const categoryList: ApiFunc<`${typeof API.CATEGORY}`, 'get'> = data =>
  instance.get(API.CATEGORY, {
    params: data,
  })

export const categoryCreate: ApiFunc<`${typeof API.CATEGORY}`, 'post'> = data => instance.post(API.CATEGORY, data)

export const categoryDelete: ApiFunc<`${typeof API.CATEGORY}{id}`, 'delete'> = data =>
  instance.delete(`${API.CATEGORY}${'id' in data ? prop('id', data) : data.name}`)

export const category: ApiFunc<`${typeof API.CATEGORY}{id}`, 'get'> = data =>
  instance.get(`${API.CATEGORY}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const categoryAll: ApiFunc<`${typeof API.CATEGORY_ALL}`, 'get'> = data =>
  instance.get(API.CATEGORY_ALL, {
    params: data,
  })
