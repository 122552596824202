import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  CATEGORY,
  CATEGORY_ALL,
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const categorySelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const categorySelector = createSelector(categorySelectors, prop(CATEGORY))

export const categoryAllSelector = createSelector(categorySelectors, prop(CATEGORY_ALL))

export const categoryCreateSelector = createSelector(categorySelectors, prop(CATEGORY_CREATE))

export const categoryDeleteSelector = createSelector(categorySelectors, prop(CATEGORY_DELETE))

export const categoryListSelector = createSelector(categorySelectors, prop(CATEGORY_LIST))
