export const GROUP = '/group/' as const
export const GROUP_ALL = `${GROUP}all` as const
export const GROUP_ALL_SHORT = `${GROUP_ALL}/short` as const
export const GROUP_ALL_FOR_ASSESSMENT = `${GROUP_ALL}/for-assessment` as const
export const GROUP_ASSIGN = `${GROUP}assign` as const
export const GROUP_ASSIGN_MENTOR = `${GROUP}assign_mentor` as const
export const GROUP_GROUPING = `${GROUP}grouping` as const
export const GROUP_SHUFFLE = `${GROUP}shuffle` as const
export const GROUP_STUDENT_TRANSFER = `${GROUP}student/transfer` as const
export const GROUP_EXPORT = `${GROUP}export` as const
