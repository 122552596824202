import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  DEPARTMENT,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_LABELS,
  DEPARTMENT_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const departmentSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const departmentSelector = createSelector(departmentSelectors, prop(DEPARTMENT))

export const departmentAllSelector = createSelector(departmentSelectors, prop(DEPARTMENT_ALL))

export const departmentListSelector = createSelector(departmentSelectors, prop(DEPARTMENT_LIST))

export const departmentCreateSelector = createSelector(departmentSelectors, prop(DEPARTMENT_CREATE))

export const departmentLabelsSelector = createSelector(departmentSelectors, prop(DEPARTMENT_LABELS))
