import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const staff: ApiFunc<`${typeof API.STAFF}{id}`, 'get'> = data =>
  instance.get(`${API.STAFF}${'id' in data ? data.id : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

// export const staffCreate: ApiFunc<`${typeof API.STAFF}`, 'post'> = data => instance.post(API.STAFF, data)

export const staffDelete: ApiFunc<`${typeof API.STAFF}{id}`, 'delete'> = data =>
  instance.delete(`${API.STAFF}${'id' in data ? data.id : data?.name}`)

export const staffList: ApiFunc<`${typeof API.STAFF}`, 'get'> = data =>
  instance.get(API.STAFF, {
    params: data,
  })

export const staffAll: ApiFunc<`${typeof API.STAFF_ALL}`, 'get'> = data =>
  instance.get(API.STAFF_ALL, {
    params: data,
  })

export const staffByDepartment: ApiFunc<`${typeof API.STAFF_BY_DEPARTMENT}`, 'get'> = data =>
  instance.get(API.STAFF_BY_DEPARTMENT, {
    params: data,
  })

export const staffUsername: ApiFunc<`${typeof API.STAFF_USERNAME}{username}`, 'get'> = data =>
  instance.get(`${API.STAFF_USERNAME}${'username' in data ? data.username : data?.name}`, {
    params: 'username' in data ? dissoc('username', data) : dissoc('name', data),
  })
