export const STC = '/short_term_course/' as const
export const STC_ALL = `${STC}all` as const
export const STC_CATEGORY = `${STC}category/` as const
export const STC_CATEGORY_ALL = `${STC}category/all` as const

export const STC_STEP = `${STC}step/` as const
export const STC_STEP_ALL = `${STC}step/all` as const

// export const STC_STEP_COMPLETE = `${STC}complete/` as const
// export const STC_STEP_TYPE = `${STC}step_type/`
// export const SHTC_LISTENER_REGISTERED = `${SHTC}listener_registered`
// export const SHTC_CHAPTER = `${SHTC}chapter/`
// export const SHTC_CHAPTER_ALL = `${SHTC_CHAPTER}all`

// export const SHTC_TOPIC = `${SHTC}topic/`
// export const SHTC_TOPIC_ALL = `${SHTC_TOPIC}all`

// export const SHORT_TERM_COURSE_TOPIC_MATERIALS = `${SHTC_TOPIC}materials/`
// export const SHORT_TERM_COURSE_TOPIC_PROGRESS_INFO = `${SHTC_TOPIC}progress/info/`
// export const SHORT_TERM_COURSE_TOPIC_PROGRESS_RESET = `${SHTC_TOPIC}progress/reset`
// export const SHORT_TERM_COURSE_TOPIC_PROGRESS_VIDEO_UPDATE = `${SHTC_TOPIC}progress/video/update`
// export const SHORT_TERM_COURSE_TOPIC_STEP_TYPE = `${SHTC_TOPIC}step/type`
// export const SHORT_TERM_COURSE_TOPIC_TEST_ATTEMPT_CREATE = `${SHTC_TOPIC}test/attempt/create`

// export const SHTC_LISTENER = `${SHTC}listener/`
// export const SHTC_LISTENER_ALL = `${SHTC}all`
// export const SHTC_LISTENER_ACTIVATE = `${SHTC}activate/`

// export const SHTC_FOR_LISTENER = `${SHTC}for_listener/`
// export const SHTC_FOR_LISTENER_CHAPTER = `${SHTC_FOR_LISTENER}chapter/`
// export const SHTC_FOR_LISTENER_TOPIC = `${SHTC_FOR_LISTENER}topic/`
// export const SHTC_FOR_LISTENER_STEP_COMPLETE = `${SHTC_FOR_LISTENER}step/complete`
