import { DefaultState } from '@src/redux/types'

import {
  VACANCY_POSITION,
  VACANCY_POSITION_ACTIVATE,
  VACANCY_POSITION_ALL,
  VACANCY_POSITION_CREATE,
  VACANCY_POSITION_DELETE,
  VACANCY_POSITION_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [VACANCY_POSITION]: DefaultState<`${typeof API.VACANCY_POSITION}{id}`, 'get'>
  [VACANCY_POSITION_ACTIVATE]: DefaultState<`${typeof API.VACANCY_POSITION_ACTIVATE}{id}`, 'put'>
  [VACANCY_POSITION_ALL]: DefaultState<`${typeof API.VACANCY_POSITION_ALL}`, 'get'>
  [VACANCY_POSITION_CREATE]: DefaultState<`${typeof API.VACANCY_POSITION}`, 'post'>
  [VACANCY_POSITION_DELETE]: DefaultState<`${typeof API.VACANCY_POSITION}{id}`, 'delete'>
  [VACANCY_POSITION_LIST]: DefaultState<`${typeof API.VACANCY_POSITION}`, 'get'>
} = () => ({
  [VACANCY_POSITION]: defaultState(),
  [VACANCY_POSITION_ACTIVATE]: defaultState(),
  [VACANCY_POSITION_ALL]: defaultState(),
  [VACANCY_POSITION_CREATE]: defaultState(),
  [VACANCY_POSITION_DELETE]: defaultState(),
  [VACANCY_POSITION_LIST]: defaultState(),
})
