import { PAYMENTS } from '@constants/routes'
import { ACCOUNTING_HEAD, BACHELOR_STUDENT, MASTER_STUDENT, SUPER_ADMIN } from '@constants/userRoles'
import { lazy } from 'react'

export const PaymentsRoutes = [
  {
    path: PAYMENTS,
    component: lazy(() => import('../views/PaymentsPage')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ACCOUNTING_HEAD, BACHELOR_STUDENT, MASTER_STUDENT],
    },
  },
]
