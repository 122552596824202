import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GENERAL_APPLICATION,
  GENERAL_APPLICATION_ALL,
  GENERAL_APPLICATION_FIELD_BATCH,
  GENERAL_APPLICATION_LIST,
  GENERAL_APPLICATION_REQUEST_EDIT,
  GENERAL_APPLICATION_STATUS,
  GENERAL_APPLICATION_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const generalAdmissionSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const generalApplicationSelector = createSelector(generalAdmissionSelectors, prop(GENERAL_APPLICATION))

export const generalApplicationListSelector = createSelector(generalAdmissionSelectors, prop(GENERAL_APPLICATION_LIST))

export const generalApplicationTypesSelector = createSelector(
  generalAdmissionSelectors,
  prop(GENERAL_APPLICATION_TYPES)
)

export const generalApplicationStatusSelector = createSelector(
  generalAdmissionSelectors,
  prop(GENERAL_APPLICATION_STATUS)
)

export const generalApplicationAllSelector = createSelector(generalAdmissionSelectors, prop(GENERAL_APPLICATION_ALL))

export const generalApplicationFieldBatchSelector = createSelector(
  generalAdmissionSelectors,
  prop(GENERAL_APPLICATION_FIELD_BATCH)
)

// request
// export const generalApplicationRequestAll = createSelector(
//   generalAdmissionSelectors,
//   prop(GENERAL_APPLICATION_REQUEST_ALL)
// )

export const generalApplicationRequestEdit = createSelector(
  generalAdmissionSelectors,
  prop(GENERAL_APPLICATION_REQUEST_EDIT)
)

// export const generalApplicationRequestUpdate = createSelector(
//   generalAdmissionSelectors,
//   prop(GENERAL_APPLICATION_REQUEST_UPDATE)
// )
