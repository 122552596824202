import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_DELETE,
  ASSESSMENT_EC_LIST,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const assessmentECList = createAsyncThunk(
  ASSESSMENT_EC_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECItem = createAsyncThunk(
  ASSESSMENT_EC,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesItem(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECCreate = createAsyncThunk(
  ASSESSMENT_EC_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECUpdate = createAsyncThunk(
  ASSESSMENT_EC_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC_UPDATE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECOutcomeReport = createAsyncThunk(
  ASSESSMENT_EC_OUTCOME_REPORT,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC_OUTCOME_REPORT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesOutcomeReport(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECPaper = createAsyncThunk(
  ASSESSMENT_EC_PAPER,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC_PAPER}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesPaper(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECChangeStatus = createAsyncThunk(
  ASSESSMENT_EC_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC_CHANGE_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECAll = createAsyncThunk(
  ASSESSMENT_EC_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECDelete = createAsyncThunk(
  ASSESSMENT_EC_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_EC}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.extenuatingCircumstancesDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentECListClear = createAction(`${ASSESSMENT_EC_LIST}/clear`)
