import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACHIEVEMENT_RECORDS,
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_LIST,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const achievementRecordsSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const studentAchievementRecordsSelector = createSelector(achievementRecordsSelectors, prop(ACHIEVEMENT_RECORDS))

export const achievementRecordsListSelector = createSelector(
  achievementRecordsSelectors,
  prop(ACHIEVEMENT_RECORDS_LIST)
)

export const achievementRecordsAllSelector = createSelector(achievementRecordsSelectors, prop(ACHIEVEMENT_RECORDS_ALL))

export const achievementRecordsStudentSelector = createSelector(
  achievementRecordsSelectors,
  prop(ACHIEVEMENT_RECORDS_STUDENT)
)

export const achievementRecordsTypesSelector = createSelector(
  achievementRecordsSelectors,
  prop(ACHIEVEMENT_RECORDS_TYPES)
)

export const achievementRecordsStatusChangeSelector = createSelector(
  achievementRecordsSelectors,
  prop(ACHIEVEMENT_RECORDS_CHANGE_STATUS)
)

export const achievementRecordsCreateSelector = createSelector(
  achievementRecordsSelectors,
  prop(ACHIEVEMENT_RECORDS_CREATE)
)
