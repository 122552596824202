export const GLOBAL_STATE = 'Guests'

export const GUEST = 'GUEST' as const
export const GUEST_LIST = 'GUEST_LIST' as const
export const GUEST_ALL = 'GUEST_ALL' as const
export const GUEST_PROFILE = 'GUEST_PROFILE' as const
export const GUEST_PROFILE_UPDATE = 'GUEST_PROFILE_UPDATE' as const
export const GUEST_CHANGE_EDU_LANG = 'GUEST_CHANGE_EDU_LANG' as const
export const GUEST_CREATE_REFERENCE = 'GUEST_CREATE_REFERENCE' as const
export const GUEST_CREATE_INVOICE = 'GUEST_CREATE_INVOICE' as const
export const GUEST_CHANGE_STATUS = 'GUEST_CHANGE_STATUS' as const
export const GUEST_CHANGE_STATUS_TRANSCRIPT = 'GUEST_CHANGE_STATUS_TRANSCRIPT' as const
export const GUEST_RESET_EXAM = 'GUEST_RESET_EXAM' as const
export const GUEST_TEACHING_EXPERIENCE = 'GUEST_TEACHING_EXPERIENCE' as const
export const GUEST_RESET_COURSE = 'GUEST_RESET_COURSE' as const
export const GUEST_APPOINTMENT_SCORE = 'GUEST_APPOINTMENT_SCORE' as const
export const GUEST_APPOINTMENT_SCORE_IMPORT = 'GUEST_APPOINTMENT_SCORE_IMPORT' as const
export const GUEST_DELETE = 'GUEST_DELETE' as const
export const GUEST_ACCEPT_SEND_CONTRACT = 'GUEST_ACCEPT_SEND_CONTRACT' as const
export const GUEST_AVAILABILITY_DOCUMENTS = 'GUEST_AVAILABILITY_DOCUMENTS' as const
export const GUEST_AVAILABILITY_DOCUMENTS_UPDATE = 'GUEST_AVAILABILITY_DOCUMENTS_UPDATE' as const
