import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  EDUCATION_PLACE,
  EDUCATION_PLACE_ALL,
  EDUCATION_PLACE_CREATE,
  EDUCATION_PLACE_DELETE,
  EDUCATION_PLACE_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const EDUCATION_PLACES = GLOBAL_STATE

export const { reducer: educationPlaceReducer } = createSlice({
  name: EDUCATION_PLACES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.educationPlace.pending, state => {
        state[EDUCATION_PLACE].loading = true
      })
      .addCase(actionCreators.educationPlace.rejected, (state, action) => {
        state[EDUCATION_PLACE].loading = false
        state[EDUCATION_PLACE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.educationPlace.fulfilled, (state, action) => {
        state[EDUCATION_PLACE].loading = false
        state[EDUCATION_PLACE].error = null
        state[EDUCATION_PLACE].data = action.payload
      })

    builder
      .addCase(actionCreators.educationPlaceAll.pending, state => {
        state[EDUCATION_PLACE_ALL].loading = true
      })
      .addCase(actionCreators.educationPlaceAll.rejected, (state, action) => {
        state[EDUCATION_PLACE_ALL].loading = false
        state[EDUCATION_PLACE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.educationPlaceAll.fulfilled, (state, action) => {
        state[EDUCATION_PLACE_ALL].loading = false
        state[EDUCATION_PLACE_ALL].error = null
        state[EDUCATION_PLACE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.educationPlaceCreate.pending, state => {
        state[EDUCATION_PLACE_CREATE].loading = true
      })
      .addCase(actionCreators.educationPlaceCreate.rejected, (state, action) => {
        state[EDUCATION_PLACE_CREATE].loading = false
        state[EDUCATION_PLACE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.educationPlaceCreate.fulfilled, (state, action) => {
        state[EDUCATION_PLACE_CREATE].loading = false
        state[EDUCATION_PLACE_CREATE].error = null
        state[EDUCATION_PLACE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.educationPlaceDelete.pending, state => {
        state[EDUCATION_PLACE_DELETE].loading = true
      })
      .addCase(actionCreators.educationPlaceDelete.rejected, (state, action) => {
        state[EDUCATION_PLACE_DELETE].loading = false
        state[EDUCATION_PLACE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.educationPlaceDelete.fulfilled, (state, action) => {
        state[EDUCATION_PLACE_DELETE].loading = false
        state[EDUCATION_PLACE_DELETE].error = null
        state[EDUCATION_PLACE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.educationPlaceList.pending, state => {
        state[EDUCATION_PLACE_LIST].loading = true
      })
      .addCase(actionCreators.educationPlaceList.rejected, (state, action) => {
        state[EDUCATION_PLACE_LIST].loading = false
        state[EDUCATION_PLACE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.educationPlaceList.fulfilled, (state, action) => {
        state[EDUCATION_PLACE_LIST].loading = false
        state[EDUCATION_PLACE_LIST].error = null
        state[EDUCATION_PLACE_LIST].data = action.payload
      })

    builder.addCase(actionCreators.educationPlaceClear, state => {
      state[EDUCATION_PLACE] = defaultState()
    })

    builder.addCase(actionCreators.educationPlaceAllClear, state => {
      state[EDUCATION_PLACE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.educationPlaceListClear, state => {
      state[EDUCATION_PLACE_LIST] = defaultState()
    })
  },
})
