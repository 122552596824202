import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  COURSE_TRANSFER,
  COURSE_TRANSFER_COURSES,
  COURSE_TRANSFER_INFO,
  COURSE_TRANSFER_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const COURSE_TRANSERS = GLOBAL_STATE

export const { reducer: courseTransferReducer } = createSlice({
  name: COURSE_TRANSERS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.courseTransfer.pending, state => {
        state[COURSE_TRANSFER].loading = true
      })
      .addCase(actionCreators.courseTransfer.rejected, (state, action) => {
        state[COURSE_TRANSFER].loading = false
        state[COURSE_TRANSFER].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseTransfer.fulfilled, (state, action) => {
        state[COURSE_TRANSFER].loading = false
        state[COURSE_TRANSFER].error = null
        state[COURSE_TRANSFER].data = action.payload
      })

    builder
      .addCase(actionCreators.courseTransferList.pending, state => {
        state[COURSE_TRANSFER_LIST].loading = true
      })
      .addCase(actionCreators.courseTransferList.rejected, (state, action) => {
        state[COURSE_TRANSFER_LIST].loading = false
        state[COURSE_TRANSFER_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseTransferList.fulfilled, (state, action) => {
        state[COURSE_TRANSFER_LIST].loading = false
        state[COURSE_TRANSFER_LIST].error = null
        state[COURSE_TRANSFER_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.courseTransferCourses.pending, state => {
        state[COURSE_TRANSFER_COURSES].loading = true
      })
      .addCase(actionCreators.courseTransferCourses.rejected, (state, action) => {
        state[COURSE_TRANSFER_COURSES].loading = false
        state[COURSE_TRANSFER_COURSES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseTransferCourses.fulfilled, (state, action) => {
        state[COURSE_TRANSFER_COURSES].loading = false
        state[COURSE_TRANSFER_COURSES].error = null
        state[COURSE_TRANSFER_COURSES].data = action.payload
      })

    builder
      .addCase(actionCreators.courseTransferInfo.pending, state => {
        state[COURSE_TRANSFER_INFO].loading = true
      })
      .addCase(actionCreators.courseTransferInfo.rejected, (state, action) => {
        state[COURSE_TRANSFER_INFO].loading = false
        state[COURSE_TRANSFER_INFO].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseTransferInfo.fulfilled, (state, action) => {
        state[COURSE_TRANSFER_INFO].loading = false
        state[COURSE_TRANSFER_INFO].error = null
        state[COURSE_TRANSFER_INFO].data = action.payload
      })

    builder.addCase(actionCreators.courseTransferListClear, state => {
      state[COURSE_TRANSFER_LIST] = defaultState()
    })

    builder.addCase(actionCreators.courseTransferCoursesClear, state => {
      state[COURSE_TRANSFER_COURSES] = defaultState()
    })

    builder.addCase(actionCreators.courseTransferInfoClear, state => {
      state[COURSE_TRANSFER_INFO] = defaultState()
    })
  },
})
