import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  CONTRACT_AGREEMENT_ACCEPT,
  CONTRACT_AGREEMENT_ALL,
  CONTRACT_AGREEMENT_CURRENT,
  CONTRACT_AGREEMENT_OPEN,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const contractAgreementAll = createAsyncThunk(
  CONTRACT_AGREEMENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.CONTRACT_AGREEMENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.contractAgreementAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const contractAgreementAccept = createAsyncThunk(
  CONTRACT_AGREEMENT_ACCEPT,
  async (params: PayloadCreatorParams<`${typeof API.CONTRACT_AGREEMENT_ACCEPT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.contractAgreementAccept(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const contractAgreementCurrent = createAsyncThunk(
  CONTRACT_AGREEMENT_CURRENT,
  async (params: PayloadCreatorParams<`${typeof API.CONTRACT_AGREEMENT_CURRENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.contractAgreementCurrent(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const contractAgreementOpen = createAsyncThunk(
  CONTRACT_AGREEMENT_OPEN,
  async (params: PayloadCreatorParams<`${typeof API.CONTRACT_AGREEMENT_OPEN}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.contractAgreementOpen(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const contractAgreementOpenClear = createAction(`${CONTRACT_AGREEMENT_OPEN}/clear`)

export const contractAgreementAllClear = createAction(`${CONTRACT_AGREEMENT_ALL}/clear`)

export const contractAgreementCurrentClear = createAction(`${CONTRACT_AGREEMENT_CURRENT}/clear`)
