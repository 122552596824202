import { DefaultState } from '@src/redux/types'

import { DEBIT_CREATE, DEBIT_DELETE, DEBIT_LIST, DEBIT_OWN } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [DEBIT_LIST]: DefaultState<`${typeof API.DEBIT}`, 'get'>
  [DEBIT_OWN]: DefaultState<`${typeof API.DEBIT_OWN}`, 'get'>
  [DEBIT_CREATE]: DefaultState<`${typeof API.DEBIT}`, 'post'>
  [DEBIT_DELETE]: DefaultState<`${typeof API.DEBIT}{id}`, 'delete'>
} = () => ({
  [DEBIT_LIST]: defaultState(),
  [DEBIT_OWN]: defaultState(),
  [DEBIT_CREATE]: defaultState(),
  [DEBIT_DELETE]: defaultState(),
})
