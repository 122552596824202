import { DefaultState } from '@src/redux/types'

import { CATEGORY, CATEGORY_ALL, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [CATEGORY]: DefaultState<`${typeof API.CATEGORY}{id}`, 'get'>
  [CATEGORY_ALL]: DefaultState<`${typeof API.CATEGORY_ALL}`, 'get'>
  [CATEGORY_CREATE]: DefaultState<`${typeof API.CATEGORY}`, 'post'>
  [CATEGORY_DELETE]: DefaultState<`${typeof API.CATEGORY}{id}`, 'delete'>
  [CATEGORY_LIST]: DefaultState<`${typeof API.CATEGORY}`, 'get'>
} = () => ({
  [CATEGORY]: defaultState(),
  [CATEGORY_ALL]: defaultState(),
  [CATEGORY_CREATE]: defaultState(),
  [CATEGORY_DELETE]: defaultState(),
  [CATEGORY_LIST]: defaultState(),
})
