import { ApiFunc, getInstance } from '@src/helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const debitList: ApiFunc<`${typeof API.DEBIT}`, 'get'> = data =>
  instance.get(API.DEBIT, {
    params: data,
  })

export const debitOwn: ApiFunc<`${typeof API.DEBIT_OWN}`, 'get'> = data =>
  instance.get(API.DEBIT_OWN, {
    params: data,
  })

export const debitCreate: ApiFunc<`${typeof API.DEBIT}`, 'post'> = data => instance.post(API.DEBIT, data)

export const debitDelete: ApiFunc<`${typeof API.DEBIT}{id}`, 'delete'> = data =>
  instance.delete(`${API.DEBIT}${'id' in data ? data.id : data.name}`)
