import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { CHECK_USER_OPEN_LINK, GLOBAL_STATE, USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const OPEN_LINK = GLOBAL_STATE

export const { reducer: openLinkReducer } = createSlice({
  name: OPEN_LINK,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.checkOpenLink.pending, state => {
        state[CHECK_USER_OPEN_LINK].loading = true
      })
      .addCase(actionCreators.checkOpenLink.rejected, (state, action) => {
        state[CHECK_USER_OPEN_LINK].loading = false
        state[CHECK_USER_OPEN_LINK].error = action.payload as AxiosError
      })
      .addCase(actionCreators.checkOpenLink.fulfilled, (state, action) => {
        state[CHECK_USER_OPEN_LINK].loading = false
        state[CHECK_USER_OPEN_LINK].error = null
        state[CHECK_USER_OPEN_LINK].data = action.payload
      })

    builder
      .addCase(actionCreators.generateOpenLink.pending, state => {
        state[USER_OPEN_LINK].loading = true
      })
      .addCase(actionCreators.generateOpenLink.rejected, (state, action) => {
        state[USER_OPEN_LINK].loading = false
        state[USER_OPEN_LINK].error = action.payload as AxiosError
      })
      .addCase(actionCreators.generateOpenLink.fulfilled, (state, action) => {
        state[USER_OPEN_LINK].loading = false
        state[USER_OPEN_LINK].error = null
        state[USER_OPEN_LINK].data = action.payload
      })

    builder
      .addCase(actionCreators.userPublicInfo.pending, state => {
        state[USER_PUBLIC_INFO].loading = true
      })
      .addCase(actionCreators.userPublicInfo.rejected, (state, action) => {
        state[USER_PUBLIC_INFO].loading = false
        state[USER_PUBLIC_INFO].error = action.payload as AxiosError
      })
      .addCase(actionCreators.userPublicInfo.fulfilled, (state, action) => {
        state[USER_PUBLIC_INFO].loading = false
        state[USER_PUBLIC_INFO].error = null
        state[USER_PUBLIC_INFO].data = action.payload
      })

    builder.addCase(actionCreators.openLinkClear, state => {
      state[USER_OPEN_LINK] = defaultState()
    })

    builder.addCase(actionCreators.userPublicInfoClear, state => {
      state[USER_PUBLIC_INFO] = defaultState()
    })
  },
})
