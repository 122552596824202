import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, PAYMENTS, PAYMENTS_CREATE, PAYMENTS_DELETE } from '../constants/actionTypes'

const Selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const paymentsListSelector = createSelector(Selectors, prop(PAYMENTS))
export const paymentsCreateSelector = createSelector(Selectors, prop(PAYMENTS_CREATE))
export const paymentsDeleteSelector = createSelector(Selectors, prop(PAYMENTS_DELETE))
