import { DefaultState } from '@src/redux/types'

import {
  CCLS_MODULE,
  CCLS_MODULES_FILTER,
  CCLS_MODULE_ALL,
  CCLS_MODULE_ARTICULATE,
  CCLS_MODULE_ARTICULATE_ALL,
  CCLS_MODULE_CREATE,
  CCLS_MODULE_DELETE,
  CCLS_MODULE_LIST,
  CCLS_MODULE_TEACHER_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [CCLS_MODULE]: DefaultState<`${typeof API.CCLS_MODULE}{id}`, 'get'>
  [CCLS_MODULES_FILTER]: DefaultState<`${typeof API.CCLS_MODULE}`, 'get'>
  [CCLS_MODULE_ALL]: DefaultState<`${typeof API.CCLS_MODULE_ALL}`, 'get'>
  [CCLS_MODULE_CREATE]: DefaultState<`${typeof API.CCLS_MODULE}`, 'post'>
  [CCLS_MODULE_DELETE]: DefaultState<`${typeof API.CCLS_MODULE}{id}`, 'delete'>
  [CCLS_MODULE_LIST]: DefaultState<`${typeof API.CCLS_MODULE}`, 'get'>
  [CCLS_MODULE_TEACHER_UPDATE]: DefaultState<`${typeof API.CCLS_MODULE}`, 'post'>
  [CCLS_MODULE_ARTICULATE_ALL]: DefaultState<`${typeof API.CCLS_MODULE_ARTICULATE_ALL}`, 'get'>
  [CCLS_MODULE_ARTICULATE]: DefaultState<`${typeof API.CCLS_MODULE_ARTICULATE}`, 'get'>
} = () => ({
  [CCLS_MODULE]: defaultState(),
  [CCLS_MODULES_FILTER]: defaultState(),
  [CCLS_MODULE_ALL]: defaultState(),
  [CCLS_MODULE_CREATE]: defaultState(),
  [CCLS_MODULE_DELETE]: defaultState(),
  [CCLS_MODULE_LIST]: defaultState(),
  [CCLS_MODULE_TEACHER_UPDATE]: defaultState(),
  [CCLS_MODULE_ARTICULATE_ALL]: defaultState(),
  [CCLS_MODULE_ARTICULATE]: defaultState(),
})
