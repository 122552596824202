import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  VACANCY_POSITION,
  VACANCY_POSITION_ACTIVATE,
  VACANCY_POSITION_ALL,
  VACANCY_POSITION_CREATE,
  VACANCY_POSITION_DELETE,
  VACANCY_POSITION_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const vacancyPosition = createAsyncThunk(
  VACANCY_POSITION,
  async (params: PayloadCreatorParams<`${typeof API.VACANCY_POSITION}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.vacancyPositionGetByID(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const vacancyPositionActivate = createAsyncThunk(
  VACANCY_POSITION_ACTIVATE,
  async (params: PayloadCreatorParams<`${typeof API.VACANCY_POSITION_ACTIVATE}{id}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.vacancyPositionActivate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const vacancyPositionAll = createAsyncThunk(
  VACANCY_POSITION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.VACANCY_POSITION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.vacancyPositionAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const vacancyPositionCreate = createAsyncThunk(
  VACANCY_POSITION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.VACANCY_POSITION}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.vacancyPositionCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const vacancyPositionDelete = createAsyncThunk(
  VACANCY_POSITION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.VACANCY_POSITION}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.vacancyPositionDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const vacancyPositionList = createAsyncThunk(
  VACANCY_POSITION_LIST,
  async (params: PayloadCreatorParams<`${typeof API.VACANCY_POSITION}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.vacancyPositionList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const vacancyPositionClear = createAction(`${VACANCY_POSITION}/clear`)

export const vacancyPositionListClear = createAction(`${VACANCY_POSITION_LIST}/clear`)

export const vacancyPositionAllClear = createAction(`${VACANCY_POSITION_ALL}/clear`)
