import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  APPLICATION,
  APPLICATION_ALL,
  APPLICATION_FIELD_BATCH,
  APPLICATION_LIST,
  APPLICATION_REQUEST_ALL,
  APPLICATION_REQUEST_EDIT,
  APPLICATION_REQUEST_UPDATE,
  APPLICATION_STATUS,
  APPLICATION_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const admissionSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const applicationSelector = createSelector(admissionSelectors, prop(APPLICATION))
export const applicationListSelector = createSelector(admissionSelectors, prop(APPLICATION_LIST))
export const applicationTypesSelector = createSelector(admissionSelectors, prop(APPLICATION_TYPES))
export const applicationStatusSelector = createSelector(admissionSelectors, prop(APPLICATION_STATUS))
export const applicationAllSelector = createSelector(admissionSelectors, prop(APPLICATION_ALL))
export const applicationFieldBatchSelector = createSelector(admissionSelectors, prop(APPLICATION_FIELD_BATCH))

// request
export const applicationRequestAll = createSelector(admissionSelectors, prop(APPLICATION_REQUEST_ALL))
export const applicationRequestEdit = createSelector(admissionSelectors, prop(APPLICATION_REQUEST_EDIT))
export const applicationRequestUpdate = createSelector(admissionSelectors, prop(APPLICATION_REQUEST_UPDATE))
