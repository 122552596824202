import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { COURSE, COURSE_ALL, COURSE_CREATE, COURSE_DELETE, COURSE_LIST, GLOBAL_STATE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const COURSE_CREATES = GLOBAL_STATE

export const { reducer: courseCreateReducer } = createSlice({
  name: COURSE_CREATES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.course.pending, state => {
        state[COURSE].loading = true
      })
      .addCase(actionCreators.course.rejected, (state, action) => {
        state[COURSE].loading = false
        state[COURSE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.course.fulfilled, (state, action) => {
        state[COURSE].loading = false
        state[COURSE].error = null
        state[COURSE].data = action.payload
      })

    builder
      .addCase(actionCreators.courseAll.pending, state => {
        state[COURSE_ALL].loading = true
      })
      .addCase(actionCreators.courseAll.rejected, (state, action) => {
        state[COURSE_ALL].loading = false
        state[COURSE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseAll.fulfilled, (state, action) => {
        state[COURSE_ALL].loading = false
        state[COURSE_ALL].error = null
        state[COURSE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.courseCreate.pending, state => {
        state[COURSE_CREATE].loading = true
      })
      .addCase(actionCreators.courseCreate.rejected, (state, action) => {
        state[COURSE_CREATE].loading = false
        state[COURSE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseCreate.fulfilled, (state, action) => {
        state[COURSE_CREATE].loading = false
        state[COURSE_CREATE].error = null
        state[COURSE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.courseDelete.pending, state => {
        state[COURSE_DELETE].loading = true
      })
      .addCase(actionCreators.courseDelete.rejected, (state, action) => {
        state[COURSE_DELETE].loading = false
        state[COURSE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseDelete.fulfilled, (state, action) => {
        state[COURSE_DELETE].loading = false
        state[COURSE_DELETE].error = null
        state[COURSE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.courseList.pending, state => {
        state[COURSE_LIST].loading = true
      })
      .addCase(actionCreators.courseList.rejected, (state, action) => {
        state[COURSE_LIST].loading = false
        state[COURSE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseList.fulfilled, (state, action) => {
        state[COURSE_LIST].loading = false
        state[COURSE_LIST].error = null
        state[COURSE_LIST].data = action.payload
      })

    builder.addCase(actionCreators.courseClear, state => {
      state[COURSE] = defaultState()
    })

    builder.addCase(actionCreators.courseAllClear, state => {
      state[COURSE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.courseListClear, state => {
      state[COURSE_LIST] = defaultState()
    })
  },
})
