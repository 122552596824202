import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  VACANCY_POSITION,
  VACANCY_POSITION_ACTIVATE,
  VACANCY_POSITION_ALL,
  VACANCY_POSITION_CREATE,
  VACANCY_POSITION_DELETE,
  VACANCY_POSITION_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const VACANCY_POSITIONS = GLOBAL_STATE

const vacancyPositionSlice = createSlice({
  name: VACANCY_POSITIONS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.vacancyPosition.pending, state => {
        state[VACANCY_POSITION].loading = true
      })
      .addCase(actionCreators.vacancyPosition.rejected, (state, action) => {
        state[VACANCY_POSITION].loading = false
        state[VACANCY_POSITION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.vacancyPosition.fulfilled, (state, action) => {
        state[VACANCY_POSITION].loading = false
        state[VACANCY_POSITION].error = null
        state[VACANCY_POSITION].data = action.payload
      })

    builder
      .addCase(actionCreators.vacancyPositionActivate.pending, state => {
        state[VACANCY_POSITION_ACTIVATE].loading = true
      })
      .addCase(actionCreators.vacancyPositionActivate.rejected, (state, action) => {
        state[VACANCY_POSITION_ACTIVATE].loading = false
        state[VACANCY_POSITION_ACTIVATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.vacancyPositionActivate.fulfilled, (state, action) => {
        state[VACANCY_POSITION_ACTIVATE].loading = false
        state[VACANCY_POSITION_ACTIVATE].error = null
        state[VACANCY_POSITION_ACTIVATE].data = action.payload
      })

    builder
      .addCase(actionCreators.vacancyPositionAll.pending, state => {
        state[VACANCY_POSITION_ALL].loading = true
      })
      .addCase(actionCreators.vacancyPositionAll.rejected, (state, action) => {
        state[VACANCY_POSITION_ALL].loading = false
        state[VACANCY_POSITION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.vacancyPositionAll.fulfilled, (state, action) => {
        state[VACANCY_POSITION_ALL].loading = false
        state[VACANCY_POSITION_ALL].error = null
        state[VACANCY_POSITION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.vacancyPositionCreate.pending, state => {
        state[VACANCY_POSITION_CREATE].loading = true
      })
      .addCase(actionCreators.vacancyPositionCreate.rejected, (state, action) => {
        state[VACANCY_POSITION_CREATE].loading = false
        state[VACANCY_POSITION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.vacancyPositionCreate.fulfilled, (state, action) => {
        state[VACANCY_POSITION_CREATE].loading = false
        state[VACANCY_POSITION_CREATE].error = null
        state[VACANCY_POSITION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.vacancyPositionDelete.pending, state => {
        state[VACANCY_POSITION_DELETE].loading = true
      })
      .addCase(actionCreators.vacancyPositionDelete.rejected, (state, action) => {
        state[VACANCY_POSITION_DELETE].loading = false
        state[VACANCY_POSITION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.vacancyPositionDelete.fulfilled, (state, action) => {
        state[VACANCY_POSITION_DELETE].loading = false
        state[VACANCY_POSITION_DELETE].error = null
        state[VACANCY_POSITION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.vacancyPositionList.pending, state => {
        state[VACANCY_POSITION_LIST].loading = true
      })
      .addCase(actionCreators.vacancyPositionList.rejected, (state, action) => {
        state[VACANCY_POSITION_LIST].loading = false
        state[VACANCY_POSITION_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.vacancyPositionList.fulfilled, (state, action) => {
        state[VACANCY_POSITION_LIST].loading = false
        state[VACANCY_POSITION_LIST].error = null
        state[VACANCY_POSITION_LIST].data = action.payload
      })

    builder.addCase(actionCreators.vacancyPositionClear, state => {
      state[VACANCY_POSITION] = defaultState()
    })

    builder.addCase(actionCreators.vacancyPositionListClear, state => {
      state[VACANCY_POSITION_LIST] = defaultState()
    })

    builder.addCase(actionCreators.vacancyPositionAllClear, state => {
      state[VACANCY_POSITION_ALL] = defaultState()
    })
  },
})

export const { reducer: vacancyPositionReducer } = vacancyPositionSlice
