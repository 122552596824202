import { DefaultState } from '@src/redux/types'

import { STATE, STATE_ALL, STATE_CREATE, STATE_DELETE, STATE_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [STATE]: DefaultState<`${typeof API.STATE}{id}`, 'get'>
  [STATE_ALL]: DefaultState<`${typeof API.STATE_ALL}`, 'get'>
  [STATE_CREATE]: DefaultState<`${typeof API.STATE}`, 'post'>
  [STATE_DELETE]: DefaultState<`${typeof API.STATE}{id}`, 'delete'>
  [STATE_LIST]: DefaultState<`${typeof API.STATE}`, 'get'>
} = () => ({
  [STATE]: defaultState(),
  [STATE_ALL]: defaultState(),
  [STATE_CREATE]: defaultState(),
  [STATE_DELETE]: defaultState(),
  [STATE_LIST]: defaultState(),
})
