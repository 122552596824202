import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  HR_APPLICATION,
  HR_APPLICATION_ALL,
  HR_APPLICATION_CREATE,
  HR_APPLICATION_DELETE,
  HR_APPLICATION_FIELD_BATCH,
  HR_APPLICATION_FIELD_BATCH_CREATE,
  HR_APPLICATION_LIST,
  HR_APPLICATION_REQUEST_ALL,
  HR_APPLICATION_REQUEST_CREATE,
  HR_APPLICATION_REQUEST_EDIT,
  HR_APPLICATION_REQUEST_UPDATE,
  HR_APPLICATION_STATUS,
  HR_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const HR = GLOBAL_STATE

export const { reducer: hrReducer } = createSlice({
  name: HR,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.hrApplication.pending, state => {
        state[HR_APPLICATION].loading = true
      })
      .addCase(actionCreators.hrApplication.rejected, (state, action) => {
        state[HR_APPLICATION].loading = false
        state[HR_APPLICATION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplication.fulfilled, (state, action) => {
        state[HR_APPLICATION].loading = false
        state[HR_APPLICATION].error = null
        state[HR_APPLICATION].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationAll.pending, state => {
        state[HR_APPLICATION_ALL].loading = true
      })
      .addCase(actionCreators.hrApplicationAll.rejected, (state, action) => {
        state[HR_APPLICATION_ALL].loading = false
        state[HR_APPLICATION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationAll.fulfilled, (state, action) => {
        state[HR_APPLICATION_ALL].loading = false
        state[HR_APPLICATION_ALL].error = null
        state[HR_APPLICATION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationChangeStatus.pending, state => {
        state[HR_APPLICATION_STATUS].loading = true
      })
      .addCase(actionCreators.hrApplicationChangeStatus.rejected, (state, action) => {
        state[HR_APPLICATION_STATUS].loading = false
        state[HR_APPLICATION_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationChangeStatus.fulfilled, (state, action) => {
        state[HR_APPLICATION_STATUS].loading = false
        state[HR_APPLICATION_STATUS].error = null
        state[HR_APPLICATION_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationCreate.pending, state => {
        state[HR_APPLICATION_CREATE].loading = true
      })
      .addCase(actionCreators.hrApplicationCreate.rejected, (state, action) => {
        state[HR_APPLICATION_CREATE].loading = false
        state[HR_APPLICATION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationCreate.fulfilled, (state, action) => {
        state[HR_APPLICATION_CREATE].loading = false
        state[HR_APPLICATION_CREATE].error = null
        state[HR_APPLICATION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationDelete.pending, state => {
        state[HR_APPLICATION_DELETE].loading = true
      })
      .addCase(actionCreators.hrApplicationDelete.rejected, (state, action) => {
        state[HR_APPLICATION_DELETE].loading = false
        state[HR_APPLICATION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationDelete.fulfilled, (state, action) => {
        state[HR_APPLICATION_DELETE].loading = false
        state[HR_APPLICATION_DELETE].error = null
        state[HR_APPLICATION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationFieldBatch.pending, state => {
        state[HR_APPLICATION_FIELD_BATCH].loading = true
      })
      .addCase(actionCreators.hrApplicationFieldBatch.rejected, (state, action) => {
        state[HR_APPLICATION_FIELD_BATCH].loading = false
        state[HR_APPLICATION_FIELD_BATCH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationFieldBatch.fulfilled, (state, action) => {
        state[HR_APPLICATION_FIELD_BATCH].loading = false
        state[HR_APPLICATION_FIELD_BATCH].error = null
        state[HR_APPLICATION_FIELD_BATCH].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationFieldBatchCreate.pending, state => {
        state[HR_APPLICATION_FIELD_BATCH_CREATE].loading = true
      })
      .addCase(actionCreators.hrApplicationFieldBatchCreate.rejected, (state, action) => {
        state[HR_APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[HR_APPLICATION_FIELD_BATCH_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationFieldBatchCreate.fulfilled, (state, action) => {
        state[HR_APPLICATION_FIELD_BATCH_CREATE].loading = false
        state[HR_APPLICATION_FIELD_BATCH_CREATE].error = null
        state[HR_APPLICATION_FIELD_BATCH_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationList.pending, state => {
        state[HR_APPLICATION_LIST].loading = true
      })
      .addCase(actionCreators.hrApplicationList.rejected, (state, action) => {
        state[HR_APPLICATION_LIST].loading = false
        state[HR_APPLICATION_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationList.fulfilled, (state, action) => {
        state[HR_APPLICATION_LIST].loading = false
        state[HR_APPLICATION_LIST].error = null
        state[HR_APPLICATION_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationRequestAll.pending, state => {
        state[HR_APPLICATION_REQUEST_ALL].loading = true
      })
      .addCase(actionCreators.hrApplicationRequestAll.rejected, (state, action) => {
        state[HR_APPLICATION_REQUEST_ALL].loading = false
        state[HR_APPLICATION_REQUEST_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationRequestAll.fulfilled, (state, action) => {
        state[HR_APPLICATION_REQUEST_ALL].loading = false
        state[HR_APPLICATION_REQUEST_ALL].error = null
        state[HR_APPLICATION_REQUEST_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationRequestCreate.pending, state => {
        state[HR_APPLICATION_REQUEST_CREATE].loading = true
      })
      .addCase(actionCreators.hrApplicationRequestCreate.rejected, (state, action) => {
        state[HR_APPLICATION_REQUEST_CREATE].loading = false
        state[HR_APPLICATION_REQUEST_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationRequestCreate.fulfilled, (state, action) => {
        state[HR_APPLICATION_REQUEST_CREATE].loading = false
        state[HR_APPLICATION_REQUEST_CREATE].error = null
        state[HR_APPLICATION_REQUEST_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationRequestEdit.pending, state => {
        state[HR_APPLICATION_REQUEST_EDIT].loading = true
      })
      .addCase(actionCreators.hrApplicationRequestEdit.rejected, (state, action) => {
        state[HR_APPLICATION_REQUEST_EDIT].loading = false
        state[HR_APPLICATION_REQUEST_EDIT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationRequestEdit.fulfilled, (state, action) => {
        state[HR_APPLICATION_REQUEST_EDIT].loading = false
        state[HR_APPLICATION_REQUEST_EDIT].error = null
        state[HR_APPLICATION_REQUEST_EDIT].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationRequestUpdate.pending, state => {
        state[HR_APPLICATION_REQUEST_UPDATE].loading = true
      })
      .addCase(actionCreators.hrApplicationRequestUpdate.rejected, (state, action) => {
        state[HR_APPLICATION_REQUEST_UPDATE].loading = false
        state[HR_APPLICATION_REQUEST_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationRequestUpdate.fulfilled, (state, action) => {
        state[HR_APPLICATION_REQUEST_UPDATE].loading = false
        state[HR_APPLICATION_REQUEST_UPDATE].error = null
        state[HR_APPLICATION_REQUEST_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.hrApplicationTypes.pending, state => {
        state[HR_APPLICATION_TYPES].loading = true
      })
      .addCase(actionCreators.hrApplicationTypes.rejected, (state, action) => {
        state[HR_APPLICATION_TYPES].loading = false
        state[HR_APPLICATION_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.hrApplicationTypes.fulfilled, (state, action) => {
        state[HR_APPLICATION_TYPES].loading = false
        state[HR_APPLICATION_TYPES].error = null
        state[HR_APPLICATION_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.hrApplicationClear, state => {
      state[HR_APPLICATION] = defaultState()
    })

    builder.addCase(actionCreators.hrApplicationAllClear, state => {
      state[HR_APPLICATION_ALL] = defaultState()
    })

    builder.addCase(actionCreators.hrApplicationFieldBatchClear, state => {
      state[HR_APPLICATION_FIELD_BATCH] = defaultState()
    })

    builder.addCase(actionCreators.hrApplicationListClear, state => {
      state[HR_APPLICATION_LIST] = defaultState()
    })

    builder.addCase(actionCreators.hrApplicationRequestEditClear, state => {
      state[HR_APPLICATION_REQUEST_EDIT] = defaultState()
    })

    builder.addCase(actionCreators.hrApplicationRequestUpdateClear, state => {
      state[HR_APPLICATION_REQUEST_UPDATE] = defaultState()
    })

    builder.addCase(actionCreators.hrApplicationTypesClear, state => {
      state[HR_APPLICATION_TYPES] = defaultState()
    })
  },
})
