import { DefaultState } from '@src/redux/types'

import { MODULES_OUTCOME, MODULES_OUTCOME_CREATE, MODULES_OUTCOME_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [MODULES_OUTCOME]: DefaultState<`${typeof API.MODULES_OUTCOME}`, 'get'>
  [MODULES_OUTCOME_CREATE]: DefaultState<`${typeof API.MODULES_OUTCOME_LIST}`, 'post'>
  [MODULES_OUTCOME_LIST]: DefaultState<`${typeof API.MODULES_OUTCOME_LIST}`, 'get'>
} = () => ({
  [MODULES_OUTCOME]: defaultState(),
  [MODULES_OUTCOME_CREATE]: defaultState(),
  [MODULES_OUTCOME_LIST]: defaultState(),
})
