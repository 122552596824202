const GENERAL = 'general'
export const GENERAL_ADMISSION = `/${GENERAL}-admission`
export const GENERAL_MY_APPLICATIONS = `/guest-application/general`
export const GENERAL_APPLICATIONS = `/${GENERAL}-applications`
export const GENERAL_MY_APPLICATIONS_CREATE = `${GENERAL_MY_APPLICATIONS}/create`
export const GENERAL_APPLICATIONS_EDIT = `${GENERAL_APPLICATIONS}/edit`

export const GENERAL_ADMIN = `${GENERAL_ADMISSION}/admin`
export const GENERAL_ADMIN_APPLICATION_FORM = `${GENERAL_ADMIN}/application-form`
export const GENERAL_ADMIN_APPLICATION_CREATE = `${GENERAL_ADMIN_APPLICATION_FORM}/create`
export const GENERAL_ADMIN_APPLICATION_CATEGORY = `${GENERAL_ADMIN}/category`
