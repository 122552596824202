import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { MODULES, MODULES_ALL, MODULES_CREATE, MODULES_DELETE, MODULES_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const modules = createAsyncThunk(
  MODULES,
  async (params: PayloadCreatorParams<`${typeof API.MODULES}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.modules(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesCreate = createAsyncThunk(
  MODULES_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.MODULES}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesDelete = createAsyncThunk(
  MODULES_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.MODULES}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesList = createAsyncThunk(
  MODULES_LIST,
  async (params: PayloadCreatorParams<`${typeof API.MODULES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesAll = createAsyncThunk(
  MODULES_ALL,
  async (params: PayloadCreatorParams<`${typeof API.MODULES_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesClear = createAction(`${MODULES}/clear`)

export const modulesListClear = createAction(`${MODULES_LIST}/clear`)

export const modulesAllClear = createAction(`${MODULES_ALL}/clear`)
