import { EMAIL_VERIFICATION, ENROLLMENT_PAGE, HOME, UPDATE_PASSWORD } from '@constants/routes'
import { BACHELOR_STUDENT, GUEST_USER, MASTER_STUDENT, TEST_STUDENT } from '@constants/userRoles'
import { AxiosError } from '@helpers/httpClient'
import { removeEduForm, setEduForm, setSelectedEduForm } from '@helpers/storageEduForm'
import {
  removeInstitution,
  removeInstitutionOption,
  setInstitutionId,
  setInstitutionOption,
} from '@helpers/storageInstitution'
import {
  getLanguage,
  removeLanguage,
  removeLanguageOption,
  setLanguage,
  setLanguageOption,
} from '@helpers/storageLanguage'
import { getToken, removeToken, removeUserInfo, setToken } from '@helpers/storageToken'
import { getUserInfo, setUserInfo } from '@helpers/storageUserInfo'
import { AnyAction, Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { definitions } from '@schema'
import { clearStorageMenu, getStorageMenu } from '@src/helpers/storageLayout'
import { layoutMenuHidden } from '@store/layout/reducer'
import { FulfilledAction, PayloadCreatorParams, PendingAction, RejectedAction } from '@store/types'
import axios from 'axios'
import { push } from 'connected-react-router'
import { omit, path, pathOr } from 'ramda'
import { toast } from 'react-toastify'

import {
  checkGuestUser as checkGuestUserApi,
  userInfoHistory as getUserInfoHistory,
  resetPassword as resetPasswordApi,
  resetPasswordRequest,
  saveUserSettings,
  sendSms as sendSmsApi,
  signIn,
  signUp,
  userInfo as userInfoApi,
  userProfileResetPassword as userProfileResetPasswordApi,
  userUpdatePassword,
  verifyEmail as verifyEmailApi,
} from '../api'
import {
  AUTH_LOGIN,
  CHECK_GUEST_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST,
  SIGN_UP,
  SMS_SEND,
  USER_INFO,
  USER_INFO_HISTORY,
  USER_PROFILE_RESET_PASSWORD,
  USER_SETTINGS,
  USER_UPDATE_PASSWORD,
  VERIFY_EMAIL,
} from '../constants/actionTypes'

export const setUserInfoToStorage = (payload: { token: string | null; user_info: definitions['UserInfo'] }) => {
  const token = payload.token
  const userInfo = payload.user_info

  const institutionId = pathOr<1 | 2>(1, ['selected_institution', 'value'], userInfo)
  const educationForm = pathOr(null, ['selected_education_form', 'value'], userInfo)

  setToken(token)
  setUserInfo(userInfo)
  setInstitutionId(institutionId)
  setInstitutionOption(JSON.stringify(userInfo.selected_institution))
  setSelectedEduForm(JSON.stringify(userInfo.selected_education_form))
  setEduForm(educationForm)
  setLanguage(userInfo.selected_locale?.value || getLanguage())
  setLanguageOption(JSON.stringify(userInfo.selected_locale))
  return { token, userInfo }
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  if (action.type.endsWith('/rejected')) {
    const type = action.type.replace('/rejected', '')
    return (
      type !== `${AUTH}/${USER_INFO}` && type !== `${AUTH}/${USER_UPDATE_PASSWORD}` && type !== `${AUTH}/${AUTH_LOGIN}`
    )
  }
  return false
}

function isSettledAction(action: AnyAction): action is FulfilledAction | RejectedAction {
  if (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')) {
    const type = action.type.replace('/fulfilled', '').replace('/rejected', '')
    return (
      type !== `${AUTH}/${USER_INFO}` && type !== `${AUTH}/${USER_UPDATE_PASSWORD}` && type !== `${AUTH}/${AUTH_LOGIN}`
    )
  }
  return false
}

const isPendingAction = (action: AnyAction): action is PendingAction => {
  if (action.type.endsWith('/pending')) {
    const type = action.type.replace('/pending', '')
    return (
      type !== `${AUTH}/${USER_INFO}` && type !== `${AUTH}/${USER_UPDATE_PASSWORD}` && type !== `${AUTH}/${AUTH_LOGIN}`
    )
  }
  return false
}

export const AUTH = 'Auth'

function redirectUser(payload: definitions['AuthResponseDTO'], dispatch: Dispatch) {
  const userInfo = payload.user_info
  if (userInfo.required_actions?.find(item => item === 'VERIFY_EMAIL')) {
    dispatch(push(EMAIL_VERIFICATION))
  } else if (userInfo.required_actions?.find(item => item === 'UPDATE_PASSWORD')) {
    dispatch(push(UPDATE_PASSWORD))
  } else if (userInfo.required_actions?.find(item => item === 'ENROLLMENT_REQUIRED')) {
    dispatch(push(ENROLLMENT_PAGE))
  } else if (payload.redirect_url) {
    dispatch(push(payload.redirect_url))
  } else if (
    Object.prototype.hasOwnProperty.call(userInfo.roles_map, BACHELOR_STUDENT) ||
    Object.prototype.hasOwnProperty.call(userInfo.roles_map, TEST_STUDENT) ||
    Object.prototype.hasOwnProperty.call(userInfo.roles_map, MASTER_STUDENT)
  ) {
    dispatch(push(HOME))
  } else {
    dispatch(push(HOME))
  }
}

export const authLogin = createAsyncThunk(
  AUTH_LOGIN,
  async (params: PayloadCreatorParams<'/signin', 'post'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await signIn(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data, dispatch)
      if (path(['roles_map', GUEST_USER], userInfo)) {
        dispatch(layoutMenuHidden(true))
      } else if (typeof getStorageMenu() === 'boolean') {
        dispatch(layoutMenuHidden(getStorageMenu()))
      } else {
        dispatch(layoutMenuHidden(false))
      }
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e), { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }

      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authPasswordReset = createAsyncThunk(
  USER_UPDATE_PASSWORD,
  async (params: PayloadCreatorParams<'/user/own/password', 'put'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await userUpdatePassword(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data, dispatch)
      if (path(['roles_map', GUEST_USER], userInfo)) {
        dispatch(layoutMenuHidden(true))
      } else if (typeof getStorageMenu() === 'boolean') {
        dispatch(layoutMenuHidden(getStorageMenu()))
      } else {
        dispatch(layoutMenuHidden(false))
      }
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const userProfileResetPassword = createAsyncThunk(
  USER_PROFILE_RESET_PASSWORD,
  async (params: PayloadCreatorParams<'/user/profile/password', 'put'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await userProfileResetPasswordApi(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data, dispatch)
      if (path(['roles_map', GUEST_USER], userInfo)) {
        dispatch(layoutMenuHidden(true))
      } else if (typeof getStorageMenu() === 'boolean') {
        dispatch(layoutMenuHidden(getStorageMenu()))
      } else {
        dispatch(layoutMenuHidden(false))
      }
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authResetPasswordRequest = createAsyncThunk(
  RESET_PASSWORD_REQUEST,
  async (
    params: PayloadCreatorParams<'/action_verification_email/reset/password/request', 'post'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await resetPasswordRequest(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authResetPassword = createAsyncThunk(
  RESET_PASSWORD,
  async (params: PayloadCreatorParams<'/action_verification_email/reset/password', 'post'>, { rejectWithValue }) => {
    try {
      const response = await resetPasswordApi(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authUserInfo = createAsyncThunk(
  USER_INFO,
  async (params: PayloadCreatorParams<'/user/me/info', 'get'> | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response = await userInfoApi(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      const { userInfo } = setUserInfoToStorage({ token: getToken(), user_info: response.data }) as {
        token: string
        userInfo: definitions['UserInfo']
      }
      response.data = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      if (path(['roles_map', GUEST_USER], userInfo)) {
        dispatch(layoutMenuHidden(true))
      } else if (typeof getStorageMenu() === 'boolean') {
        dispatch(layoutMenuHidden(getStorageMenu()))
      } else {
        dispatch(layoutMenuHidden(false))
      }
      if (userInfo.required_actions?.find(item => item === 'VERIFY_EMAIL')) {
        dispatch(push(EMAIL_VERIFICATION))
      } else if (userInfo.required_actions?.find(item => item === 'UPDATE_PASSWORD')) {
        dispatch(push(UPDATE_PASSWORD))
      } else if (userInfo.required_actions?.find(item => item === 'ENROLLMENT_REQUIRED')) {
        dispatch(push(ENROLLMENT_PAGE))
      }
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      dispatch(() => {
        removeToken()
        removeUserInfo()
        removeInstitution()
        removeLanguage()
        removeEduForm()
        removeInstitutionOption()
        removeLanguageOption()
        dispatch(authLogout())
        clearStorageMenu()
      })
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const verifyEmail = createAsyncThunk(
  VERIFY_EMAIL,
  async (params: PayloadCreatorParams<'/verify-email', 'post'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await verifyEmailApi(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info }) as {
        token: string
        userInfo: definitions['UserInfo']
      }
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data, dispatch)
      if (path(['roles_map', GUEST_USER], userInfo)) {
        dispatch(layoutMenuHidden(true))
      } else if (typeof getStorageMenu() === 'boolean') {
        dispatch(layoutMenuHidden(getStorageMenu()))
      } else {
        dispatch(layoutMenuHidden(false))
      }
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authSignUp = createAsyncThunk(
  SIGN_UP,
  async (params: PayloadCreatorParams<'/signup', 'post'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await signUp(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data, dispatch)
      if (path(['roles_map', GUEST_USER], userInfo)) {
        dispatch(layoutMenuHidden(true))
      } else if (typeof getStorageMenu() === 'boolean') {
        dispatch(layoutMenuHidden(getStorageMenu()))
      } else {
        dispatch(layoutMenuHidden(false))
      }
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        // toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const sendSms = createAsyncThunk(
  SMS_SEND,
  async (params: PayloadCreatorParams<'/sms/send', 'post'>, { rejectWithValue }) => {
    try {
      const response = await sendSmsApi(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const updateUserSettings = createAsyncThunk(
  USER_SETTINGS,
  async (params: PayloadCreatorParams<'/user/settings/', 'post'>, { rejectWithValue }) => {
    try {
      const response = await saveUserSettings(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const userInfoHistory = createAsyncThunk(
  USER_INFO_HISTORY,
  async (params: PayloadCreatorParams<'/user/info_history/', 'get'>, { rejectWithValue }) => {
    try {
      const response = await getUserInfoHistory(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const checkGuestUser = createAsyncThunk(
  CHECK_GUEST_USER,
  async (params: PayloadCreatorParams<'/check-guest-user', 'post'>, { rejectWithValue }) => {
    try {
      const response = await checkGuestUserApi(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = (e.response && e.response.data) || ''
        toast.error(pathOr('', ['response', 'data', 'message'], e) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

const initialState = () => ({
  token: getToken(),
  userInfo: getUserInfo(),
  login: null,
  error: null,
  [VERIFY_EMAIL]: {
    loading: false,
    error: null,
    data: null,
  },
  [USER_INFO_HISTORY]: {
    loading: false,
    error: null,
    data: {},
  },
  [SIGN_UP]: {
    loading: false,
    error: null,
    data: null,
  },
  [SMS_SEND]: {
    loading: false,
    error: null,
    data: null,
  },
  [CHECK_GUEST_USER]: {
    loading: false,
    error: null,
    data: null,
  },
  loading: false,
})

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    authLogout(state) {
      state.token = null
      state.userInfo = null
    },
    authUserInfoClear(state) {
      state.token = null
      state.userInfo = null
    },
    userInfoHistoryClear(state) {
      state[USER_INFO_HISTORY].loading = false
      state[USER_INFO_HISTORY].error = null
      state[USER_INFO_HISTORY].data = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authLogin.pending, state => {
        state.loading = true
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
      })
      .addCase(authLogin.rejected, (state, action) => {
        ;(state.error as unknown as AxiosError) = action.payload as AxiosError
        state.loading = false
      })

    builder
      .addCase(authPasswordReset.pending, state => {
        state.loading = true
      })
      .addCase(authPasswordReset.rejected, (state, action) => {
        ;(state.error as unknown as AxiosError) = action.payload as AxiosError
        state.loading = false
      })
      .addCase(authPasswordReset.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
      })

    builder
      .addCase(userProfileResetPassword.pending, state => {
        state.loading = true
      })
      .addCase(userProfileResetPassword.rejected, (state, action) => {
        ;(state.error as unknown as AxiosError) = action.payload as AxiosError
        state.loading = false
      })
      .addCase(userProfileResetPassword.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
      })

    builder
      .addCase(authUserInfo.pending, state => {
        state.loading = true
      })
      .addCase(authUserInfo.rejected, (state, action) => {
        ;(state.error as unknown) = action.payload
        state.loading = false
      })
      .addCase(authUserInfo.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.userInfo = action.payload
      })

    builder
      .addCase(userInfoHistory.pending, state => {
        state[USER_INFO_HISTORY].loading = true
      })
      .addCase(userInfoHistory.rejected, (state, action) => {
        ;(state[USER_INFO_HISTORY].error as unknown) = action.payload
        state[USER_INFO_HISTORY].loading = false
      })
      .addCase(userInfoHistory.fulfilled, (state, action) => {
        state[USER_INFO_HISTORY].loading = false
        state[USER_INFO_HISTORY].error = null
        state[USER_INFO_HISTORY].data = action.payload
      })
    builder.addCase(verifyEmail.pending, state => {
      state[VERIFY_EMAIL].loading = true
    })
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state[VERIFY_EMAIL].loading = false
      ;(state.error as unknown) = action.payload
    })
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state[VERIFY_EMAIL].error = null
      state.token = action.payload.token
      state.userInfo = action.payload.user_info
    })

    builder
      .addCase(authSignUp.pending, state => {
        state[SIGN_UP].loading = true
      })
      .addCase(authSignUp.rejected, (state, action) => {
        state[SIGN_UP].loading = false
        ;(state.error as unknown) = action.payload
      })
      .addCase(authSignUp.fulfilled, (state, action) => {
        state[SIGN_UP].loading = false
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
        state[SIGN_UP].error = null
      })

    builder
      .addCase(sendSms.pending, state => {
        state[SMS_SEND].loading = true
      })
      .addCase(sendSms.rejected, (state, action) => {
        state[SMS_SEND].loading = false
        ;(state.error as unknown) = action.payload
      })
      .addCase(sendSms.fulfilled, state => {
        state[SMS_SEND].loading = false
        state[SMS_SEND].error = null
      })
    builder
      .addCase(checkGuestUser.pending, state => {
        state[CHECK_GUEST_USER].loading = true
      })
      .addCase(checkGuestUser.rejected, (state, action) => {
        state[CHECK_GUEST_USER].loading = false
        ;(state.error as unknown) = action.payload
      })
      .addCase(checkGuestUser.fulfilled, state => {
        state[CHECK_GUEST_USER].loading = false
        state[CHECK_GUEST_USER].error = null
      })

    // builder.addMatcher(isPendingAction, (state, action) => {
    //   const type = action.type.replace('/pending', '') as `${typeof AUTH}/${
    //     | ActionTypesModule['VERIFY_EMAIL']
    //     | ActionTypesModule['SIGN_UP']
    //     | ActionTypesModule['SMS_SEND']}`
    //   state[type].loading = true
    // })
    // builder.addMatcher(isRejectedAction, (state, action) => {
    //   const type = action.type.replace('/rejected', '') as `${typeof AUTH}/${
    //     | ActionTypesModule['VERIFY_EMAIL']
    //     | ActionTypesModule['SIGN_UP']
    //     | ActionTypesModule['SMS_SEND']}`
    //   ;(state[type]['error'] as unknown) = action.payload
    // })
    // builder.addMatcher(isSettledAction, (state, action) => {
    //   const type = action.type.replace('/rejected', '').replace(' / fulfilled', '') as `${typeof AUTH}/${
    //     | ActionTypesModule['VERIFY_EMAIL']
    //     | ActionTypesModule['SIGN_UP']
    //     | ActionTypesModule['SMS_SEND']}`
    //   state[type].loading = false
    // })
  },
})

export const {
  reducer: authReducer,
  actions: { authLogout, authUserInfoClear, userInfoHistoryClear },
} = authSlice
