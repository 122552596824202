import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, SHORT_COURSE_REGISTRATION_CREATE } from '../constants/actionTypes'

const shortCourseRegistrationSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const shortCourseRegistrationCreateSelector = createSelector(
  shortCourseRegistrationSelectors,
  prop(SHORT_COURSE_REGISTRATION_CREATE)
)
