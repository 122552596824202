import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import * as actionTypes from '../constants/actionTypes'
import * as API from '../constants/api'

export const lessonArticulateProgress = createAsyncThunk(
  actionTypes.LESSON_ARTICULATE_PROGRESS,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ARTICULATE_PROGRESS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonArticulateProgress(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonArticulateAll = createAsyncThunk(
  actionTypes.LESSON_ARTICULATE_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.LESSON_ARTICULATE_ALL}{cclsModuleId}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.lessonArticulateAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonArticulateDelete = createAsyncThunk(
  actionTypes.LESSON_ARTICULATE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ARTICULATE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonArticulateDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonArticulateCreate = createAsyncThunk(
  actionTypes.LESSON_ARTICULATE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ARTICULATE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonArticulateCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lesson = createAsyncThunk(
  actionTypes.LESSON,
  async (params: PayloadCreatorParams<`${typeof API.LESSON}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.lesson(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonCreate = createAsyncThunk(
  actionTypes.LESSON_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.LESSON}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonDelete = createAsyncThunk(
  actionTypes.LESSON_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.LESSON}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonList = createAsyncThunk(
  actionTypes.LESSON_LIST,
  async (params: PayloadCreatorParams<`${typeof API.LESSON}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonAll = createAsyncThunk(
  actionTypes.LESSON_ALL,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonSorting = createAsyncThunk(
  actionTypes.LESSON_SORTING,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_SORTING}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonSorting(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonHomeworkUpload = createAsyncThunk(
  actionTypes.LESSON_HOMEWORK_UPLOAD,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_HOMEWORK_UPLOAD}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonHomeworkUpload(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonAssessment = createAsyncThunk(
  actionTypes.LESSON_ASSESSMENT,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ASSESSMENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonAssessment(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonAssessmentAll = createAsyncThunk(
  actionTypes.LESSON_ASSESSMENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ASSESSMENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonAssessmentAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonMarkAssessment = createAsyncThunk(
  actionTypes.LESSON_MARK_ASSESSMENT,
  async (params: PayloadCreatorParams<`${typeof API.LESSON_ASSESSMENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.lessonMarkAssessment(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const lessonArticulateClear = createAction(`${actionTypes.LESSON_ARTICULATE}/clear`)

export const lessonArticulateAllClear = createAction(`${actionTypes.LESSON_ARTICULATE_ALL}/clear`)

export const lessonClear = createAction(`${actionTypes.LESSON}/clear`)

export const lessonListClear = createAction(`${actionTypes.LESSON_LIST}/clear`)

export const lessonAllClear = createAction(`${actionTypes.LESSON_ALL}/clear`)

export const lessonHomeworkUploadClear = createAction(`${actionTypes.LESSON_HOMEWORK_UPLOAD}/clear`)

export const lessonAssessmentClear = createAction(`${actionTypes.LESSON_ASSESSMENT}/clear`)

export const lessonAssessmentAllClear = createAction(`${actionTypes.LESSON_ASSESSMENT_ALL}/clear`)
