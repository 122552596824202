import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, JOB_OFFER_CREATE, JOB_OFFER_LIST, JOB_OFFER_STUDENT_ALL } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const JOB_OFFER = GLOBAL_STATE

export const { reducer: jobOfferReducer } = createSlice({
  name: JOB_OFFER,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.jobOfferCreate.pending, state => {
        state[JOB_OFFER_CREATE].loading = true
      })
      .addCase(actionCreators.jobOfferCreate.rejected, (state, action) => {
        state[JOB_OFFER_CREATE].loading = false
        state[JOB_OFFER_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobOfferCreate.fulfilled, (state, action) => {
        state[JOB_OFFER_CREATE].loading = false
        state[JOB_OFFER_CREATE].error = null
        state[JOB_OFFER_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.jobOfferList.pending, state => {
        state[JOB_OFFER_LIST].loading = true
      })
      .addCase(actionCreators.jobOfferList.rejected, (state, action) => {
        state[JOB_OFFER_LIST].loading = false
        state[JOB_OFFER_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobOfferList.fulfilled, (state, action) => {
        state[JOB_OFFER_LIST].loading = false
        state[JOB_OFFER_LIST].error = null
        state[JOB_OFFER_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.jobOfferStudentAll.pending, state => {
        state[JOB_OFFER_STUDENT_ALL].loading = true
      })
      .addCase(actionCreators.jobOfferStudentAll.rejected, (state, action) => {
        state[JOB_OFFER_STUDENT_ALL].loading = false
        state[JOB_OFFER_STUDENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobOfferStudentAll.fulfilled, (state, action) => {
        state[JOB_OFFER_STUDENT_ALL].loading = false
        state[JOB_OFFER_STUDENT_ALL].error = null
        state[JOB_OFFER_STUDENT_ALL].data = action.payload
      })

    builder.addCase(actionCreators.jobOfferListClear, state => {
      state[JOB_OFFER_LIST] = defaultState()
    })

    builder.addCase(actionCreators.jobOfferStudentAllClear, state => {
      state[JOB_OFFER_STUDENT_ALL] = defaultState()
    })
  },
})
