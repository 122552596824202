import { getDataFromState } from '@helpers/get'
import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  HR_APPLICATION,
  HR_APPLICATION_ALL,
  HR_APPLICATION_FIELD_BATCH,
  HR_APPLICATION_LIST,
  HR_APPLICATION_REQUEST_ALL,
  HR_APPLICATION_REQUEST_EDIT,
  HR_APPLICATION_REQUEST_UPDATE,
  HR_APPLICATION_STATUS,
  HR_APPLICATION_TYPES,
} from '../constants/actionTypes'

const hrAdmissionSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const hrApplicationSelector = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION))

export const hrApplicationListSelector = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_LIST))

export const hrApplicationTypesSelector = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_TYPES))

export const hrApplicationStatusSelector = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_STATUS))

export const hrApplicationAllSelector = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_ALL))
export const hrApplicationFieldBatchSelector = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_FIELD_BATCH))

// request
export const hrApplicationRequestAll = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_REQUEST_ALL))
export const hrApplicationRequestEdit = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_REQUEST_EDIT))

export const hrApplicationRequestUpdate = createSelector(hrAdmissionSelectors, prop(HR_APPLICATION_REQUEST_UPDATE))
