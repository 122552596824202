import { getInstance } from '@helpers/httpClient'
import * as API from '../constants/api'
import { dissoc, prop } from 'ramda/es'

export const sendMail = data => getInstance().post(API.SEND_MAIL, data)

export const emailTemplate = data => getInstance().get(`${API.EMAIL_TEMPLATE}${prop('id', data)}`, {
  params: dissoc('id', data),
})

export const emailTemplateCreate = data => getInstance().post(API.EMAIL_TEMPLATE, data)

export const emailTemplateDelete = data => getInstance().delete(`${API.EMAIL_TEMPLATE}${prop('id', data)}`)

export const emailTemplateList = data => getInstance().get(API.EMAIL_TEMPLATE, {
  params: data,
})

export const emailTemplateAll = data => getInstance().get(API.EMAIL_TEMPLATE_ALL, {
  params: data,
})

