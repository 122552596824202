import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  JOB_POSITION,
  JOB_POSITION_ACTIVATE,
  JOB_POSITION_ALL,
  JOB_POSITION_CREATE,
  JOB_POSITION_DELETE,
  JOB_POSITION_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const JOB_POSITIONS = GLOBAL_STATE

export const { reducer: jobPositionReducer } = createSlice({
  name: JOB_POSITIONS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.jobPosition.pending, state => {
        state[JOB_POSITION].loading = true
      })
      .addCase(actionCreators.jobPosition.rejected, (state, action) => {
        state[JOB_POSITION].loading = false
        state[JOB_POSITION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobPosition.fulfilled, (state, action) => {
        state[JOB_POSITION].loading = false
        state[JOB_POSITION].error = null
        state[JOB_POSITION].data = action.payload
      })

    builder
      .addCase(actionCreators.jobPositionActivate.pending, state => {
        state[JOB_POSITION_ACTIVATE].loading = true
      })
      .addCase(actionCreators.jobPositionActivate.rejected, (state, action) => {
        state[JOB_POSITION_ACTIVATE].loading = false
        state[JOB_POSITION_ACTIVATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobPositionActivate.fulfilled, (state, action) => {
        state[JOB_POSITION_ACTIVATE].loading = false
        state[JOB_POSITION_ACTIVATE].error = null
        state[JOB_POSITION_ACTIVATE].data = action.payload
      })

    builder
      .addCase(actionCreators.jobPositionAll.pending, state => {
        state[JOB_POSITION_ALL].loading = true
      })
      .addCase(actionCreators.jobPositionAll.rejected, (state, action) => {
        state[JOB_POSITION_ALL].loading = false
        state[JOB_POSITION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobPositionAll.fulfilled, (state, action) => {
        state[JOB_POSITION_ALL].loading = false
        state[JOB_POSITION_ALL].error = null
        state[JOB_POSITION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.jobPositionCreate.pending, state => {
        state[JOB_POSITION_CREATE].loading = true
      })
      .addCase(actionCreators.jobPositionCreate.rejected, (state, action) => {
        state[JOB_POSITION_CREATE].loading = false
        state[JOB_POSITION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobPositionCreate.fulfilled, (state, action) => {
        state[JOB_POSITION_CREATE].loading = false
        state[JOB_POSITION_CREATE].error = null
        state[JOB_POSITION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.jobPositionDelete.pending, state => {
        state[JOB_POSITION_DELETE].loading = true
      })
      .addCase(actionCreators.jobPositionDelete.rejected, (state, action) => {
        state[JOB_POSITION_DELETE].loading = false
        state[JOB_POSITION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobPositionDelete.fulfilled, (state, action) => {
        state[JOB_POSITION_DELETE].loading = false
        state[JOB_POSITION_DELETE].error = null
        state[JOB_POSITION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.jobPositionList.pending, state => {
        state[JOB_POSITION_LIST].loading = true
      })
      .addCase(actionCreators.jobPositionList.rejected, (state, action) => {
        state[JOB_POSITION_LIST].loading = false
        state[JOB_POSITION_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.jobPositionList.fulfilled, (state, action) => {
        state[JOB_POSITION_LIST].loading = false
        state[JOB_POSITION_LIST].error = null
        state[JOB_POSITION_LIST].data = action.payload
      })

    builder.addCase(actionCreators.jobPositionClear, state => {
      state[JOB_POSITION] = defaultState()
    })

    builder.addCase(actionCreators.jobPositionAllClear, state => {
      state[JOB_POSITION_ALL] = defaultState()
    })

    builder.addCase(actionCreators.jobPositionListClear, state => {
      state[JOB_POSITION_LIST] = defaultState()
    })
  },
})
