import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ASSESSMENT_ATTEMPT_CREATE,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_BULK,
  ASSESSMENT_COMPONENT_CREATE,
  ASSESSMENT_COMPONENT_DELETE,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_LIST,
  ASSESSMENT_STUDENT,
  ASSESSMENT_STUDENT_CRITERIA_DETAILS,
  ASSESSMENT_STUDENT_UPDATED,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_SUBMIT,
  ATTEMPT_DELETE,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ASSESSMENT = GLOBAL_STATE

export const { reducer: assessmentReducer } = createSlice({
  name: ASSESSMENT,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.assessmentAttemptCreate.pending, state => {
        state[ASSESSMENT_ATTEMPT_CREATE].loading = true
      })
      .addCase(actionCreators.assessmentAttemptCreate.rejected, (state, action) => {
        state[ASSESSMENT_ATTEMPT_CREATE].loading = false
        state[ASSESSMENT_ATTEMPT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAttemptCreate.fulfilled, (state, action) => {
        state[ASSESSMENT_ATTEMPT_CREATE].loading = false
        state[ASSESSMENT_ATTEMPT_CREATE].error = null
        state[ASSESSMENT_ATTEMPT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentAttempt.pending, state => {
        state[ASSESSMENT_ATTEMPT_ITEM].loading = true
      })
      .addCase(actionCreators.assessmentAttempt.rejected, (state, action) => {
        state[ASSESSMENT_ATTEMPT_ITEM].loading = false
        state[ASSESSMENT_ATTEMPT_ITEM].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAttempt.fulfilled, (state, action) => {
        state[ASSESSMENT_ATTEMPT_ITEM].loading = false
        state[ASSESSMENT_ATTEMPT_ITEM].error = null
        state[ASSESSMENT_ATTEMPT_ITEM].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentBulk.pending, state => {
        state[ASSESSMENT_BULK].loading = true
      })
      .addCase(actionCreators.assessmentBulk.rejected, (state, action) => {
        state[ASSESSMENT_BULK].loading = false
        state[ASSESSMENT_BULK].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentBulk.fulfilled, (state, action) => {
        state[ASSESSMENT_BULK].loading = false
        state[ASSESSMENT_BULK].error = null
        state[ASSESSMENT_BULK].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentComponentCreate.pending, state => {
        state[ASSESSMENT_COMPONENT_CREATE].loading = true
      })
      .addCase(actionCreators.assessmentComponentCreate.rejected, (state, action) => {
        state[ASSESSMENT_COMPONENT_CREATE].loading = false
        state[ASSESSMENT_COMPONENT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentComponentCreate.fulfilled, (state, action) => {
        state[ASSESSMENT_COMPONENT_CREATE].loading = false
        state[ASSESSMENT_COMPONENT_CREATE].error = null
        state[ASSESSMENT_COMPONENT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentComponentDelete.pending, state => {
        state[ASSESSMENT_COMPONENT_DELETE].loading = true
      })
      .addCase(actionCreators.assessmentComponentDelete.rejected, (state, action) => {
        state[ASSESSMENT_COMPONENT_DELETE].loading = false
        state[ASSESSMENT_COMPONENT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentComponentDelete.fulfilled, (state, action) => {
        state[ASSESSMENT_COMPONENT_DELETE].loading = false
        state[ASSESSMENT_COMPONENT_DELETE].error = null
        state[ASSESSMENT_COMPONENT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentCopy.pending, state => {
        state[ASSESSMENT_COPY].loading = true
      })
      .addCase(actionCreators.assessmentCopy.rejected, (state, action) => {
        state[ASSESSMENT_COPY].loading = false
        state[ASSESSMENT_COPY].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentCopy.fulfilled, (state, action) => {
        state[ASSESSMENT_COPY].loading = false
        state[ASSESSMENT_COPY].error = null
        state[ASSESSMENT_COPY].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentCreate.pending, state => {
        state[ASSESSMENT_CREATE].loading = true
      })
      .addCase(actionCreators.assessmentCreate.rejected, (state, action) => {
        state[ASSESSMENT_CREATE].loading = false
        state[ASSESSMENT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentCreate.fulfilled, (state, action) => {
        state[ASSESSMENT_CREATE].loading = false
        state[ASSESSMENT_CREATE].error = null
        state[ASSESSMENT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentCriteriaList.pending, state => {
        state[ASSESSMENT_CRITERIA_LIST].loading = true
      })
      .addCase(actionCreators.assessmentCriteriaList.rejected, (state, action) => {
        state[ASSESSMENT_CRITERIA_LIST].loading = false
        state[ASSESSMENT_CRITERIA_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentCriteriaList.fulfilled, (state, action) => {
        state[ASSESSMENT_CRITERIA_LIST].loading = false
        state[ASSESSMENT_CRITERIA_LIST].error = null
        state[ASSESSMENT_CRITERIA_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentCriteriaTypes.pending, state => {
        state[ASSESSMENT_CRITERIA_TYPES].loading = true
      })
      .addCase(actionCreators.assessmentCriteriaTypes.rejected, (state, action) => {
        state[ASSESSMENT_CRITERIA_TYPES].loading = false
        state[ASSESSMENT_CRITERIA_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentCriteriaTypes.fulfilled, (state, action) => {
        state[ASSESSMENT_CRITERIA_TYPES].loading = false
        state[ASSESSMENT_CRITERIA_TYPES].error = null
        state[ASSESSMENT_CRITERIA_TYPES].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentCriteriaUpdate.pending, state => {
        state[ASSESSMENT_CRITERIA_UPDATE].loading = true
      })
      .addCase(actionCreators.assessmentCriteriaUpdate.rejected, (state, action) => {
        state[ASSESSMENT_CRITERIA_UPDATE].loading = false
        state[ASSESSMENT_CRITERIA_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentCriteriaUpdate.fulfilled, (state, action) => {
        state[ASSESSMENT_CRITERIA_UPDATE].loading = false
        state[ASSESSMENT_CRITERIA_UPDATE].error = null
        state[ASSESSMENT_CRITERIA_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentList.pending, state => {
        state[ASSESSMENT_LIST].loading = true
      })
      .addCase(actionCreators.assessmentList.rejected, (state, action) => {
        state[ASSESSMENT_LIST].loading = false
        state[ASSESSMENT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentList.fulfilled, (state, action) => {
        state[ASSESSMENT_LIST].loading = false
        state[ASSESSMENT_LIST].error = null
        state[ASSESSMENT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentStudent.pending, state => {
        state[ASSESSMENT_STUDENT].loading = true
      })
      .addCase(actionCreators.assessmentStudent.rejected, (state, action) => {
        state[ASSESSMENT_STUDENT].loading = false
        state[ASSESSMENT_STUDENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentStudent.fulfilled, (state, action) => {
        state[ASSESSMENT_STUDENT].loading = false
        state[ASSESSMENT_STUDENT].error = null
        state[ASSESSMENT_STUDENT].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentStudentUpdated.pending, state => {
        state[ASSESSMENT_STUDENT_UPDATED].loading = true
      })
      .addCase(actionCreators.assessmentStudentUpdated.rejected, (state, action) => {
        state[ASSESSMENT_STUDENT_UPDATED].loading = false
        state[ASSESSMENT_STUDENT_UPDATED].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentStudentUpdated.fulfilled, (state, action) => {
        state[ASSESSMENT_STUDENT_UPDATED].loading = false
        state[ASSESSMENT_STUDENT_UPDATED].error = null
        state[ASSESSMENT_STUDENT_UPDATED].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentStudentUpload.pending, state => {
        state[ASSESSMENT_STUDENT_UPLOAD].loading = true
      })
      .addCase(actionCreators.assessmentStudentUpload.rejected, (state, action) => {
        state[ASSESSMENT_STUDENT_UPLOAD].loading = false
        state[ASSESSMENT_STUDENT_UPLOAD].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentStudentUpload.fulfilled, (state, action) => {
        state[ASSESSMENT_STUDENT_UPLOAD].loading = false
        state[ASSESSMENT_STUDENT_UPLOAD].error = null
        state[ASSESSMENT_STUDENT_UPLOAD].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentSubmit.pending, state => {
        state[ASSESSMENT_SUBMIT].loading = true
      })
      .addCase(actionCreators.assessmentSubmit.rejected, (state, action) => {
        state[ASSESSMENT_SUBMIT].loading = false
        state[ASSESSMENT_SUBMIT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentSubmit.fulfilled, (state, action) => {
        state[ASSESSMENT_SUBMIT].loading = false
        state[ASSESSMENT_SUBMIT].error = null
        state[ASSESSMENT_SUBMIT].data = action.payload
      })

    builder
      .addCase(actionCreators.attemptDelete.pending, state => {
        state[ATTEMPT_DELETE].loading = true
      })
      .addCase(actionCreators.attemptDelete.rejected, (state, action) => {
        state[ATTEMPT_DELETE].loading = false
        state[ATTEMPT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attemptDelete.fulfilled, (state, action) => {
        state[ATTEMPT_DELETE].loading = false
        state[ATTEMPT_DELETE].error = null
        state[ATTEMPT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentStudentCriteriaDetails.pending, state => {
        state[ASSESSMENT_STUDENT_CRITERIA_DETAILS].loading = true
      })
      .addCase(actionCreators.assessmentStudentCriteriaDetails.rejected, (state, action) => {
        state[ASSESSMENT_STUDENT_CRITERIA_DETAILS].loading = false
        state[ASSESSMENT_STUDENT_CRITERIA_DETAILS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentStudentCriteriaDetails.fulfilled, (state, action) => {
        state[ASSESSMENT_STUDENT_CRITERIA_DETAILS].loading = false
        state[ASSESSMENT_STUDENT_CRITERIA_DETAILS].error = null
        state[ASSESSMENT_STUDENT_CRITERIA_DETAILS].data = action.payload
      })

    builder.addCase(actionCreators.assessmentStudentCriteriaDetailsClear, state => {
      state[ASSESSMENT_STUDENT_CRITERIA_DETAILS] = initialState().ASSESSMENT_STUDENT_CRITERIA_DETAILS
    })

    builder.addCase(actionCreators.assessmentAttemptItemClear, state => {
      state[ASSESSMENT_ATTEMPT_ITEM] = defaultState()
    })

    builder.addCase(actionCreators.assessmentBulkClear, state => {
      state[ASSESSMENT_BULK] = defaultState()
    })

    builder.addCase(actionCreators.assessmentComponentCreateClear, state => {
      state[ASSESSMENT_COMPONENT_CREATE] = defaultState()
    })

    builder.addCase(actionCreators.assessmentCriteriaListClear, state => {
      state[ASSESSMENT_CRITERIA_LIST] = defaultState()
    })

    builder.addCase(actionCreators.assessmentCriteriaTypesClear, state => {
      state[ASSESSMENT_CRITERIA_TYPES] = defaultState()
    })

    builder.addCase(actionCreators.assessmentListClear, state => {
      state[ASSESSMENT_LIST] = defaultState()
    })

    builder.addCase(actionCreators.assessmentStudentClear, state => {
      state[ASSESSMENT_STUDENT] = defaultState()
    })

    builder.addCase(actionCreators.assessmentStudentUpdatedClear, state => {
      state[ASSESSMENT_STUDENT_UPDATED] = defaultState()
    })
  },
})
