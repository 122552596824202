import { RootState } from '@src/redux/store'
import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import {
  CCLS_MODULE,
  CCLS_MODULES_FILTER,
  CCLS_MODULE_ALL,
  CCLS_MODULE_ARTICULATE,
  CCLS_MODULE_ARTICULATE_ALL,
  CCLS_MODULE_CREATE,
  CCLS_MODULE_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const cclsModuleSelector = createSelector(selectors, prop(CCLS_MODULE))

export const cclsModuleCreateSelector = createSelector(selectors, prop(CCLS_MODULE_CREATE))

export const cclsModuleListSelector = createSelector(selectors, prop(CCLS_MODULE_LIST))

export const cclsModuleAllSelector = createSelector(selectors, prop(CCLS_MODULE_ALL))

export const cclsModulesFilter = createSelector(selectors, prop(CCLS_MODULES_FILTER))

export const cclsModuleAllOptionsSelector = createSelector(cclsModuleAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({
          label: `${propOr('', 'title', item)} (${propOr('', 'semester_title', item)})`,
          value: item.id,
          isAuthorized: prop('authorized', item),
        }))
      : [],
  }
})

export const cclsModuleArticulateAllSelector = createSelector(selectors, prop(CCLS_MODULE_ARTICULATE_ALL))

export const cclsModuleArticulateSelector = createSelector(selectors, prop(CCLS_MODULE_ARTICULATE))
