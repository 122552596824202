import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import * as TYPES from '../constants/actionTypes'

const assessmentOffenceSelectors = (state: RootState) => prop(TYPES.GLOBAL_STATE, state)

export const offenceListSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_LIST))

export const offenceInvigilationAllSelector = createSelector(
  assessmentOffenceSelectors,
  prop(TYPES.OFFENCE_INVIGILATION_ALL)
)

export const offenceInvigilationConsultationsAllSelector = createSelector(
  assessmentOffenceSelectors,
  prop(TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL)
)

export const offenceInvigilationMinutesAllSelector = createSelector(
  assessmentOffenceSelectors,
  prop(TYPES.OFFENCE_INVIGILATION_MINUTES_ALL)
)

export const offenceStatusSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_CHANGE_STATUS))

export const offenceAllSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_ALL))

export const offenceReportAllSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_REPORT_ALL))

export const offenceReportListSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_REPORT_LIST))

export const offenceChangeSubjectBoardStatusSelector = createSelector(
  assessmentOffenceSelectors,
  prop(TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS)
)

export const offenceTypeAllSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_TYPE_ALL))

export const reportMisconductSelector = createSelector(assessmentOffenceSelectors, prop(TYPES.REPORT_MISCONDUCT))

export const offenceReportCreate = createSelector(assessmentOffenceSelectors, prop(TYPES.OFFENCE_REPORT_CREATE))
