import { areObjsEq } from '@helpers/areObjsEq'
import { getPage } from '@helpers/get'
import { useAppDispatch, useAppSelector } from '@hooks'
import * as stateSelectors from '@store/selector'
import { parse } from 'qs'
import queryString from 'query-string'
import {
  dissoc,
  equals,
  forEach,
  includes,
  isEmpty,
  isNil,
  map,
  omit,
  path,
  pathOr,
  pick,
  pickBy,
  pipe,
  prop,
  propOr,
  type,
} from 'ramda'
import { useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export function useFilter(props) {
  const {
    queryIdNames = [],
    actions = { listClear: () => {} },
    filterDependencyKey,
    defaultSize = 10,
    provideSPSS = true, // SPSS stands for: search, page, sort, size. If 'true',
    // then SPSS will be added to the query and provided as fetching params within the filter request.
    // You can also specify which one you want to add to the query by providing an Object
    defaultQueryValue = {},
  } = props
  const { pathname, query, location } = useAppSelector(stateSelectors.routerSelector)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const search = propOr('', 'search', query)
  const page = +propOr('0', 'page', query)
  const sort = propOr('', 'sort', query)
  const size = propOr(defaultSize, 'size', query)

  const queryIds = useMemo(() => {
    return map(id => {
      return !isNaN(id) ? (isEmpty(id) ? id : +id) : id
    })(parse(location.search, { ignoreQueryPrefix: true }) || {})
  }, [location.search])

  const cache = useRef({
    spss: {
      // search: undefined,
      // page: undefined,
      // sort: undefined,
      // size: undefined,
    },
    queryIds: {},
    fetched: [],
  })

  useEffect(() => {
    if (prop('initialFetchData', actions)) {
      const action = prop('initialFetchData', actions)
      const params = propOr({}, 'initialFetchDataParams', actions)
      if (equals(type(action, 'Function'))) {
        dispatch(action(params))
        return () => {
          const clearAction = propOr(() => {}, 'initialFetchDataClear', actions)
          dispatch(clearAction())
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(defaultQueryValue)) {
      const filteredDefaultQueryValue = pickBy((value, key) => !prop(key, queryIds))(defaultQueryValue || {})
      !isEmpty(filteredDefaultQueryValue) &&
        history.push({
          pathname,
          search: queryString.stringify(filteredDefaultQueryValue),
        })
    }
  }, [])

  useEffect(() => {
    const cachedQueryIds = cache.current.queryIds
    const cachedSPSS = cache.current.spss
    const ids = omit(['search', 'size', 'page', 'sort'], queryIds)
    const spss = pick(['search', 'size', 'page', 'sort'], queryIds)
    const initialFetch = cache.current.initialFetch
    const processedParams = getQueryParams({
      provideSPSS,
      getPageParams: getPage({
        ...queryIds,
        search: decodeURI(search),
        page,
        sort: decodeURIComponent(sort),
        size,
      }),
      queryIds,
    })
    if (!areObjsEq(cachedQueryIds, ids)) {
      // const processedParams = getQueryParams({
      //   provideSPSS,
      //   getPageParams: getPage({
      //     ...queryIds,
      //     search: decodeURI(search),
      //     page,
      //     sort: decodeURIComponent(sort),
      //     size,
      //   }),
      //   queryIds,
      // })
      filterByDependencies({ processedParams, cache, queryIds: ids })
    } else if (
      !areObjsEq(cachedSPSS, spss) ||
      (!initialFetch &&
        isEmpty(queryIds) &&
        (!filterDependencyKey || (filterDependencyKey && isEmpty(filterDependencyKey))))
    ) {
      // const processedParams = getQueryParams({
      //   provideSPSS,
      //   getPageParams: getPage({
      //     ...queryIds,
      //     search: decodeURI(search),
      //     page,
      //     sort: decodeURIComponent(sort),
      //     size,
      //   }),
      //   queryIds,
      // })
      filterBySPSS({ processedParams, cache, queryIds })
    }
    if (!areObjsEq(cache.queryIds, queryIds)) {
      fetchFields()
    }
  }, [query])

  // useEffect(() => {
  //   const initialFetch = path(['current', 'initialFetch'], cache)
  //   const fetched = path(['current', 'fetched'], cache)
  //   forEach(name => {
  //     const deltaQueryId = queryIds[name] && !equals(path(['current', name], cache), queryIds[name])
  //     if (deltaQueryId) {
  //       const action = path([name, 'fetchAction'], actions)
  //       const params = path([name, 'params'], actions) || {}
  //
  //       if (action && equals(type(action), 'Function')) {
  //         const fetchFuncName = action.name
  //         const deltaFetched = includes(fetchFuncName, fetched)
  //         if (!initialFetch && !deltaFetched) {
  //           dispatch(action({ ...omit(['search', 'page', 'sort'], query), ...params }))
  //           cache.current.fetched.push(fetchFuncName)
  //         } else if (initialFetch && deltaQueryId) {
  //           dispatch(action({ ...omit(['search', 'page', 'sort'], query), ...params }))
  //         }
  //       } else if (Array.isArray(action)) {
  //         forEach(act => {
  //           const fetchFuncName = act.name
  //           const deltaFetched = !includes(fetchFuncName, fetched)
  //           if (!initialFetch && deltaFetched) {
  //             dispatch(act({ ...omit(['search', 'page', 'sort'], query), ...params }))
  //             cache.current.fetched.push(fetchFuncName)
  //           } else if (initialFetch && deltaQueryId) {
  //             dispatch(act({ ...omit(['search', 'page', 'sort'], query), ...params }))
  //           }
  //         })(action)
  //       }
  //       cache.current[name] = queryIds[name]
  //     }
  //   })(queryIdNames)
  //   cache.current.initialFetch = true
  // }, [queryIds])

  useEffect(() => {
    return () => {
      dispatch(actions.listClear())
      forEach(name => {
        if (path(['current', name], cache)) {
          const action = path([name, 'clearAction'], actions)
          action && equals(type(action), 'Function')
            ? dispatch(action())
            : Array.isArray(action) &&
              forEach(act => {
                dispatch(act())
              })(action)
        }
      })(queryIdNames)
    }
  }, [])

  function fetchFields() {
    const initialFetch = path(['current', 'initialFetch'], cache)
    const fetched = path(['current', 'fetched'], cache)
    forEach(name => {
      const deltaQueryId = queryIds[name] && !equals(path(['current', name], cache), queryIds[name])
      if (deltaQueryId) {
        const action =
          !isNil(path([name, 'fetchAction'], actions)) &&
          (equals(type(path([name, 'fetchAction'], actions)), 'Object')
            ? path([name, 'fetchAction', 'action'], actions)
            : path([name, 'fetchAction'], actions))
        const params = path([name, 'params'], actions) || {}

        if (action && equals(type(action), 'Function')) {
          const fetchFuncName = pathOr('', [name, 'fetchAction', 'name'], actions)
          const deltaFetched = includes(fetchFuncName, fetched)
          if (!initialFetch && !deltaFetched) {
            dispatch(action({ ...omit(['search', 'page', 'sort', 'size'], query), ...params }))
            cache.current.fetched.push(fetchFuncName)
          } else if (initialFetch && deltaQueryId) {
            dispatch(action({ ...omit(['search', 'page', 'sort', 'size'], query), ...params }))
          }
        } else if (Array.isArray(action)) {
          forEach(arg => {
            const action = !isNil(arg) && (equals(type(arg), 'Object') ? prop('action', arg) : arg)
            const fetchFuncName = propOr('', 'name', arg)
            const deltaFetched = !includes(fetchFuncName, fetched)
            if (!initialFetch && deltaFetched) {
              dispatch(
                action({ ...omit(['search', 'page', 'sort', 'size'], query), ...params, actionName: fetchFuncName })
              )
              cache.current.fetched.push(fetchFuncName)
            } else if (initialFetch && deltaQueryId) {
              dispatch(
                action({ ...omit(['search', 'page', 'sort', 'size'], query), ...params, actionName: fetchFuncName })
              )
            }
          })(action)
        }
        cache.current[name] = queryIds[name]
      }
    })(queryIdNames)
    cache.current.initialFetch = true
  }

  function getQueryParams({ provideSPSS, getPageParams, queryIds }) {
    return pipe(getPageParams => {
      if (provideSPSS && !equals(type(provideSPSS), 'Object')) {
        return getPageParams
      } else if (provideSPSS && equals(type(provideSPSS), 'Object')) {
        return pipe(queryParams => {
          const obj = { ...queryParams }
          for (const queryParamsKey in provideSPSS) {
            // eslint-disable-next-line no-prototype-builtins
            if (provideSPSS.hasOwnProperty(queryParamsKey)) {
              if (!isNil(prop(queryParamsKey, provideSPSS))) {
                !prop(queryParamsKey, provideSPSS) && delete obj[queryParamsKey]
              }
            }
          }
          return obj
        })(getPageParams)
      } else if (!provideSPSS) {
        return { ...queryIds }
      }
    })(getPageParams)
  }

  function filterByDependencies({ processedParams, cache, queryIds = {} }) {
    const cachedQueryIds = cache.current.queryIds
    if (cachedQueryIds) {
      if (filterDependencyKey) {
        if (equals(type(filterDependencyKey), 'Array')) {
          forEach(key => {
            if (prop(key, queryIds) && !equals(prop(key, queryIds), cache.current?.queryIds[key])) {
              dispatch(actions.filterAction(processedParams))
              cache.current.queryIds = queryIds
            }
          })(filterDependencyKey)
        } else if (queryIds[filterDependencyKey]) {
          dispatch(actions.filterAction(processedParams))
          cache.current.queryIds = queryIds
        }
      } else if (!areObjsEq(cachedQueryIds, queryIds)) {
        prop('filterAction', actions) && dispatch(actions.filterAction(processedParams))
        cache.current.queryIds = queryIds
      }
    } else {
      prop('filterAction', actions) && dispatch(actions.filterAction(processedParams))
      cache.current.queryIds = queryIds
    }
  }

  function filterBySPSS({ processedParams, cache, queryIds }) {
    const deltaSPSS =
      search !== cache.current?.spss.search ||
      page !== cache.current?.spss.page ||
      sort !== cache.current?.spss.sort ||
      size !== cache.current?.spss.size
    if (deltaSPSS) {
      dispatch(actions.filterAction(processedParams))
      const deltaSearch = search !== cache.current?.spss.search ? { search } : {}
      const deltaPage = page !== cache.current?.spss.page ? { page } : {}
      const deltaSort = sort !== cache.current?.spss.sort ? { sort } : {}
      const deltaSize = size !== cache.current?.spss.size ? { size } : {}
      // console.log({
      //   ...deltaSearch,
      //   ...deltaPage,
      //   ...deltaSort,
      //   ...deltaSize,
      // })
      cache.current.spss = {
        ...deltaSearch,
        ...deltaPage,
        ...deltaSort,
        ...deltaSize,
      }
      // cache.current.spss.search = search
      // cache.current.spss.page = page
      // cache.current.spss.sort = sort
      // cache.current.spss.size = size
    }
  }

  function defineQuery({ queryValue, queryName }) {
    return queryValue
      ? { [queryName]: decodeURIComponent(queryValue) }
      : !provideSPSS || (equals(type(prop(queryName, provideSPSS)), 'Boolean') && !prop(queryName, provideSPSS))
      ? {}
      : { [queryName]: decodeURIComponent(queryValue) }
  }

  function queryPush(value, id) {
    const searchQuery = defineQuery({ queryValue: search, queryName: 'search' })
    const sortQuery = defineQuery({ queryValue: sort, queryName: 'sort' })
    const pageQuery = defineQuery({ queryValue: page, queryName: 'page' })
    const sizeQuery =
      !provideSPSS || (equals(type(prop('size', provideSPSS)), 'Boolean') && !prop('size', provideSPSS)) ? {} : { size }

    const queryObj = value
      ? { ...query, ...searchQuery, ...sortQuery, ...sizeQuery, ...pageQuery, [id]: value }
      : { ...dissoc([id], query), ...searchQuery, ...sortQuery, ...pageQuery, ...sizeQuery }

    history.push({
      pathname,
      search: queryString.stringify(queryObj, { arrayFormat: 'comma' }),
    })
  }
  return [queryIds, queryPush, query, pathname]
}
