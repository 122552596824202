import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_DELETE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_AMENDMENT_PUBLISH,
  ASSESSMENT_MARK_STATUS,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const assessmentAmendment = createAsyncThunk(
  ASSESSMENT_AMENDMENT,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_AMENDMENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAmendment(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAmendmentCreate = createAsyncThunk(
  ASSESSMENT_AMENDMENT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_AMENDMENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAmendmentCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAmendmentDelete = createAsyncThunk(
  ASSESSMENT_AMENDMENT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_AMENDMENT}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAmendmentDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAmendmentList = createAsyncThunk(
  ASSESSMENT_AMENDMENT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_AMENDMENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAmendmentList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAmendmentAll = createAsyncThunk(
  ASSESSMENT_AMENDMENT_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_AMENDMENT_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAmendmentAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAmendmentPublish = createAsyncThunk(
  ASSESSMENT_AMENDMENT_PUBLISH,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_AMENDMENT_PUBLISH}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAmendmentPublish(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentMarkStatusChange = createAsyncThunk(
  ASSESSMENT_MARK_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_MARK_CHANGE_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentMarkChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAmendmentClear = createAction(`${ASSESSMENT_AMENDMENT}/clear`)

export const assessmentAmendmentListClear = createAction(`${ASSESSMENT_AMENDMENT_LIST}/clear`)

export const assessmentAmendmentAllClear = createAction(`${ASSESSMENT_AMENDMENT_ALL}/clear`)
