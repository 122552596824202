import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  GUEST,
  GUEST_ACCEPT_SEND_CONTRACT,
  GUEST_ALL,
  GUEST_APPOINTMENT_SCORE,
  GUEST_AVAILABILITY_DOCUMENTS,
  GUEST_AVAILABILITY_DOCUMENTS_UPDATE,
  GUEST_CHANGE_EDU_LANG,
  GUEST_CHANGE_STATUS,
  GUEST_CHANGE_STATUS_TRANSCRIPT,
  GUEST_CREATE_INVOICE,
  GUEST_CREATE_REFERENCE,
  GUEST_DELETE,
  GUEST_LIST,
  GUEST_PROFILE,
  GUEST_PROFILE_UPDATE,
  GUEST_RESET_COURSE,
  GUEST_RESET_EXAM,
  GUEST_TEACHING_EXPERIENCE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const guest = createAsyncThunk(
  GUEST,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.guest(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestAll = createAsyncThunk(
  GUEST_ALL,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.guestAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestList = createAsyncThunk(
  GUEST_LIST,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.guestList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestProfile = createAsyncThunk(
  GUEST_PROFILE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_PROFILE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.guestProfile(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestAvailabilityDocuments = createAsyncThunk(
  GUEST_AVAILABILITY_DOCUMENTS,
  async (
    params: PayloadCreatorParams<`${typeof API.USER_GUEST_AVAILABILITY_DOCUMENTS}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.guestAvailabilityDocuments(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestProfileUpdate = createAsyncThunk(
  GUEST_PROFILE_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_PROFILE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestProfileUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestAvailabilityDocumentsUpdate = createAsyncThunk(
  GUEST_AVAILABILITY_DOCUMENTS_UPDATE,
  async (
    params: PayloadCreatorParams<`${typeof API.USER_GUEST_AVAILABILITY_DOCUMENTS}`, 'put'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.guestAvailabilityDocumentsUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestChangeEduLang = createAsyncThunk(
  GUEST_CHANGE_EDU_LANG,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_CHANGE_EDU_LANG}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.guestChangeEduLang(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestResetExam = createAsyncThunk(
  GUEST_RESET_EXAM,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_RESET_EXAM}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestResetExam(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestReferenceCreate = createAsyncThunk(
  GUEST_CREATE_REFERENCE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_REFERENCE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestReferenceCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestInvoiceCreate = createAsyncThunk(
  GUEST_CREATE_INVOICE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_INVOICE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestInvoiceCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestResetCourse = createAsyncThunk(
  GUEST_RESET_COURSE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_RESET_COURSE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestResetCourse(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestChangeStatus = createAsyncThunk(
  GUEST_CHANGE_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_CHANGE_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestChangeStatusTranscript = createAsyncThunk(
  GUEST_CHANGE_STATUS_TRANSCRIPT,
  async (
    params: PayloadCreatorParams<`${typeof API.USER_GUEST_CHANGE_STATUS_TRANSCRIPT}`, 'put'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.guestChangeStatusTranscript(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestAppointmentScore = createAsyncThunk(
  GUEST_APPOINTMENT_SCORE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_APPOINTMENT_SCORE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestAppointmentScore(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestDelete = createAsyncThunk(
  GUEST_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST}{ids}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.guestDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestTeachingExperience = createAsyncThunk(
  GUEST_TEACHING_EXPERIENCE,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_TEACHING_EXPERIENCE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestTeachingExperience(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestAcceptSendContract = createAsyncThunk(
  GUEST_ACCEPT_SEND_CONTRACT,
  async (params: PayloadCreatorParams<`${typeof API.USER_GUEST_ACCEPT_SEND_CONTRACT}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.guestAcceptSendContract(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const guestClear = createAction(`${GUEST}/clear`)

export const guestAllClear = createAction(`${GUEST_ALL}/clear`)

export const guestListClear = createAction(`${GUEST_LIST}/clear`)

export const guestProfileClear = createAction(`${GUEST_PROFILE}/clear`)
