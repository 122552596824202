import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { ROLE, ROLE_ALL, ROLE_CREATE, ROLE_DELETE, ROLE_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const role = createAsyncThunk(
  ROLE,
  async (params: PayloadCreatorParams<`${typeof API.ROLE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.role(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const roleCreate = createAsyncThunk(
  ROLE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ROLE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.roleCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const roleDelete = createAsyncThunk(
  ROLE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ROLE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.roleDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const roleList = createAsyncThunk(
  ROLE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ROLE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.roleList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const roleAll = createAsyncThunk(
  ROLE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ROLE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.roleAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const roleClear = createAction(`${ROLE}/clear`)

export const roleListClear = createAction(`${ROLE_LIST}/clear`)

export const roleAllClear = createAction(`${ROLE_ALL}/clear`)
