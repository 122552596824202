// ** React Imports
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Plus, Minus } from 'react-feather'
import { InputGroup, InputGroupAddon, Input, Button, FormGroup, Label, FormText } from 'reactstrap'
import { equals, prop } from 'ramda/es'
import { FormattedMessage } from 'react-intl'

// ** Styles
import './number-input.scss'

// ** Keycode Vars
const KEYCODE_UP = 38
const KEYCODE_DOWN = 40

const NumberInput = forwardRef((props, ref) => {
  // ** Props
  const {
    min,
    max,
    step,
    size,
    wrap,
    value,
    style,
    upIcon,
    type,
    disabled,
    readonly,
    onChange,
    handleChange = () => {},
    name,
    error,
    register,
    label,
    invalid,
    downIcon,
    vertical,
    className,
    onDecrement,
    onIncrement,
    inputClassName,
    formGroupProps = {},
    labelProps = {},
  } = props

  const { ref: registeredRef, ...rest } = register(name)

  // ** Handle btn down click
  const handleDecrement = (e) => {
    e.preventDefault()
    const numberValue = Number(value)
    if (!disabled && !readonly) {
      // ** If count equals or smaller than min then return and do nothing
      if (!wrap && numberValue <= min) {
        return
      }

      // ** Returns the decreased count based on wrap & and min prop
      const countCondition = () => {
        if (numberValue - step < min) {
          if (wrap) {
            return max
          } else {
            return min
          }
        } else {
          return numberValue - step
        }
      }

      change(countCondition())

      if (onDecrement) {
        onIncrement(value)
      }
    }
  }

  // ** Handle btn up click
  const handleIncrement = (e) => {
    e.preventDefault()
    const numberValue = Number(value)

    if (!disabled && !readonly) {
      // ** If count is equals or larger than min then return and do nothing
      if (!wrap && numberValue >= max) {
        return
      }

      // ** Returns the Increased count based on wrap & and max prop
      const countCondition = () => {
        if (numberValue + step > max) {
          if (wrap) {
            return min
          } else {
            return max
          }
        } else {
          return numberValue + step
        }
      }

      change(countCondition())

      if (onIncrement) {
        onIncrement(value)
      }
    }
  }

  // ** Handle input change
  const handleInputChange = e => {
    // change(Number(e.target.value))
    change(e.target.value)
  }

  // ** Handle Arrow Up & Down
  const handleKeyDown = e => {
    e.preventDefault()
    if (!readonly) {
      if (e.keyCode === KEYCODE_UP) {
        handleIncrement()
      }
      if (e.keyCode === KEYCODE_DOWN) {
        handleDecrement()
      }
    }
  }

  function change(value) {
    const valueWithDecimal = parseFloat(value).toFixed(1)
    onChange(valueWithDecimal)
    handleChange(valueWithDecimal)
  }

  const onWheel = equals(type, 'number') ? {
    onWheel: e => {
      e &&
      e.target.blur()
    },
  } : {}

  return (
    <FormGroup {...formGroupProps}>
      {label && <Label {...labelProps}>{label}</Label>}

      <InputGroup
        className={classnames('number-input', {
          disabled,
          readonly,
          [className]: className,
          'vertical-number-input': vertical,
          [`vertical-number-input-${size}`]: vertical && size,
        })}
        {...(style ? { style } : {})}
      >
        <InputGroupAddon addonType='prepend' onClick={handleDecrement}>
          <Button
            className='btn-icon'
            color='transparent'
            {...(size ? { size } : {})}
            disabled={(!wrap && value <= min) || disabled || readonly}
          >
            {downIcon}
          </Button>
        </InputGroupAddon>
        <Input
          {...rest}
          name={name}
          step={step}
          value={value}
          disabled={disabled}
          readOnly={readonly}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          className={classnames({
            [inputClassName]: inputClassName,
          })}
          {...onWheel}
          invalid={!!invalid || !!error}
          {...(size ? { bsSize: size } : {})}
        />
        <InputGroupAddon addonType='append' onClick={handleIncrement}>
          <Button
            onClick={e => {
              e.preventDefault()
            }}
            className='btn-icon'
            color='transparent'
            {...(size ? { size } : {})}
            disabled={(!wrap && value >= max) || disabled || readonly}
          >
            {upIcon}
          </Button>
        </InputGroupAddon>
      </InputGroup>

      {error && (
        <FormText color='danger'>
          <FormattedMessage id={equals(prop('type', error), 'range') ? 'number_range' : 'form_required'} values={{min, max}} />
        </FormText>
      )}
    </FormGroup>
  )
})

export default NumberInput

// ** Default Props
NumberInput.defaultProps = {
  min: 0,
  step: 0.1,
  value: '0',
  wrap: false,
  max: Infinity,
  disabled: false,
  readonly: false,
  downIcon: <Minus size={14} />,
  upIcon: <Plus size={14} />,
}

// ** PropTypes
NumberInput.propTypes = {
  wrap: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.string,
  style: PropTypes.object,
  vertical: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  upIcon: PropTypes.element,
  downIcon: PropTypes.element,
  className: PropTypes.string,
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func,
  inputClassName: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
}
