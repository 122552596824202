import classNames from 'classnames'
import PropTypes from 'prop-types'
import { equals, head, isEmpty, map, pipe, prop, propEq, propOr, reject, toLower, type } from 'ramda/es'
import React, { Fragment, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { File, Trash } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { Button, Card, Col, FormGroup, FormText, Label, Media, Row, Spinner } from 'reactstrap'

import { API_SCHEME, API_URL } from '../../constants/api'
import { FILE_UPLOAD_REACT } from '../../constants/fileUpload'
import { getToken } from '../../helpers/storageToken'

function formatBytes(bytes, decimals) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const FileBigPreviewUploadField = React.forwardRef((props, ref) => {
  const {
    label,
    value,
    handleChange = () => {},
    onChange,
    name,
    register,
    isRequired,
    error,
    touched,
    header,
    subHeader,
    single,
    row,
    disabled,
    formGroupStyle = {},
    normalize,
  } = props

  const [files, setFiles] = useState([])

  useEffect(() => {
    if (normalize) {
    } else {
      setFiles(value || [])
    }
  }, [value])

  const [load, setLoad] = useState(false)

  function handleAcceptedFiles(values) {
    setLoad(true)

    // eslint-disable-next-line no-undef
    const formData = new FormData()
    const filesList = Array.from(values)
    filesList.forEach(file => formData.append('files', file))

    // eslint-disable-next-line no-undef
    fetch(`${API_URL}${FILE_UPLOAD_REACT}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        institution: '1',
      },
    })
      .then(res => res.json())
      .then(response => {
        const data = prop('data', response)
        if (equals(type(files), 'Array' || 'Object') && equals(type(data), 'Array' || 'Object')) {
          if (normalize) {
            const collected = [...files, ...data]
            const normalized =
              collected.length > 1
                ? map(item => {
                    return item ? propOr('', 'src', item) : item
                  })(collected)
                : propOr('', 'src', head(collected))
            onChange(normalized)
            handleChange(normalized)
          } else {
            onChange([...files, ...data])
            handleChange([...files, ...data])
          }
        }
        setLoad(false)
      })
  }

  function handleFileDelete(file) {
    const src = prop('src', file)
    const newFiles = reject(propEq('src', src), value)
    onChange(newFiles)
    handleChange(newFiles)
  }

  function Files() {
    if (load) {
      return (
        <Row>
          <Col sm={12}>
            <div className='text-center mt-2'>
              <Spinner color='success' size='lg' />
            </div>
          </Col>
        </Row>
      )
    }

    return (
      <Row>
        {files.map((item, index) => {
          const name = prop('original_name', item)
          const size = propOr(0, 'file_size', item)
          // const mime_type = propOr('text/csv', 'mime_type', item)
          const url = prop('original_url', item)
          const extension = pipe(propOr('', 'extension'), toLower)(item)

          return (
            <Col key={index}>
              <Card className='p-2 border rounded mb-2' style={{ boxShadow: 'none' }}>
                <Media>
                  <Row>
                    <Col md={12}>
                      <div className='avatar-md font-weight-bold mr-1'>
                        <span
                          className='avatar-title rounded bg-soft-primary text-primary'
                          style={{ minWidth: '3.5rem', minHeight: '3.5rem' }}
                        >
                          {((extension === 'png' ||
                            extension === 'jpeg' ||
                            extension === 'jpg' ||
                            extension === 'bmp') && (
                            // <img
                            //   src={url}
                            //   alt=''
                            //   style={{
                            //     objectFit: 'cover',
                            //     objectPosition: 'center',
                            //     width: '100%',
                            //     height: '100%',
                            //     borderRadius: '8px',
                            //   }}
                            // />
                            <Media left href='#'>
                              <Media object src={url} width='100%' alt='Image' />
                            </Media>
                          )) || (
                            <a href={url} target='_blank' rel='noopener noreferrer'>
                              <Button.Ripple className='btn-icon rounded' outline color='secondary'>
                                <File size={20} />
                              </Button.Ripple>
                            </a>
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {/*<Media body>*/}
                  {/*  <a*/}
                  {/*    role='button'*/}
                  {/*    className='d-inline-block text-muted font-weight-bold'*/}
                  {/*    href={url}*/}
                  {/*    target='_blank'*/}
                  {/*    rel='noopener noreferrer'*/}
                  {/*    style={{ textOverflow: 'ellipsis' }}*/}
                  {/*  >*/}
                  {/*    {name && name.length > 30 ? `${name?.slice(0, 30)}...` : name}*/}
                  {/*  </a>*/}
                  {/*  <div className='font-size-11'>{formatBytes(size)}</div>*/}
                  {/*</Media>*/}

                  <Row>
                    <Col md={12}>
                      <div className='float-right'>
                        <a role='button' className='p-2 text-primary' onClick={() => handleFileDelete(item)}>
                          <Button.Ripple className='btn-icon rounded' color='danger'>
                            <Trash size={16} />
                          </Button.Ripple>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Card>
            </Col>
          )
        })}
      </Row>
    )
  }

  const smLabel = propOr(12, 'label', row)
  const smInput = propOr(12, 'input', row)

  function Upload() {
    if (single) {
      if (value && !isEmpty(value)) {
        return null
      }

      return (
        <div>
          <Dropzone
            multiple={!single}
            disabled={disabled}
            onDropAccepted={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={classNames(touched || error ? 'dropzone-error text-center' : 'dropzone text-center')}>
                <div className='dz-message py-2 needsclick' {...getRootProps()}>
                  <input {...getInputProps()} name={name} readOnly={disabled} />
                  <i className='h3 text-muted uil-cloud-upload' />
                  {header}
                  {subHeader}
                </div>
              </div>
            )}
          </Dropzone>

          {error && (
            <FormText color='primary'>
              <FormattedMessage id='form_required' />
            </FormText>
          )}
        </div>
      )
    }

    return (
      <Fragment>
        <Dropzone
          multiple={!single}
          disabled={disabled}
          onDropAccepted={acceptedFiles => {
            handleAcceptedFiles(acceptedFiles)
          }}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div className={classNames(touched || error ? 'dropzone-error text-center' : 'dropzone text-center')}>
                <div className='dz-message py-2 needsclick' {...getRootProps()}>
                  <input {...getInputProps()} name={name} readOnly={disabled} />
                  <i className='h3 text-muted uil-cloud-upload' />
                  {header}
                  {subHeader}
                </div>
              </div>
            )
          }}
        </Dropzone>

        {error && (
          <FormText color='primary'>
            {' '}
            <FormattedMessage id='form_required' />
          </FormText>
        )}
      </Fragment>
    )
  }

  return (
    <FormGroup row={Boolean(row)} style={formGroupStyle}>
      {label && <Label>{label}</Label>}

      <Upload />
      <Files />
    </FormGroup>
  )
})

FileBigPreviewUploadField.defaultProps = {
  header: <h5>Drag files here or select</h5>,
  // subHeader: <span className='text-muted font-13'>Selected files are actually uploaded.</span>,
}

FileBigPreviewUploadField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.string, PropTypes.node]),
  header: PropTypes.element,
  subHeader: PropTypes.element,
  single: PropTypes.bool,
  row: PropTypes.shape({
    label: PropTypes.number,
    input: PropTypes.number,
  }),
}

export default FileBigPreviewUploadField
