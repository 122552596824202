import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  APPLICATION,
  APPLICATION_ALL,
  APPLICATION_CREATE,
  APPLICATION_DELETE,
  APPLICATION_FIELD_BATCH,
  APPLICATION_FIELD_BATCH_CREATE,
  APPLICATION_LIST,
  APPLICATION_REQUEST,
  APPLICATION_REQUEST_ALL,
  APPLICATION_REQUEST_EDIT,
  APPLICATION_REQUEST_UPDATE,
  APPLICATION_STATUS,
  APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const application = createAsyncThunk(
  APPLICATION,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_FORM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.application(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationCreate = createAsyncThunk(
  APPLICATION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_FORM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationDelete = createAsyncThunk(
  APPLICATION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_FORM}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationList = createAsyncThunk(
  APPLICATION_LIST,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationAll = createAsyncThunk(
  APPLICATION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationTypes = createAsyncThunk(
  APPLICATION_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationChangeStatus = createAsyncThunk(
  APPLICATION_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationFieldBatch = createAsyncThunk(
  APPLICATION_FIELD_BATCH,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_FIELD_BATCH}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationFieldBatch(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationFieldBatchCreate = createAsyncThunk(
  APPLICATION_FIELD_BATCH_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_FIELD_BATCH}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationFieldBatchCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationRequest = createAsyncThunk(
  APPLICATION_REQUEST,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_REQUEST}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationRequest(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationRequestAll = createAsyncThunk(
  APPLICATION_REQUEST_ALL,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_REQUEST_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationRequestAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationRequestEdit = createAsyncThunk(
  APPLICATION_REQUEST_EDIT,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_REQUEST_EDIT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationRequestEdit(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationRequestUpdate = createAsyncThunk(
  APPLICATION_REQUEST_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.APPLICATION_REQUEST_UPDATE}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.applicationRequestUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const applicationClear = createAction(`${APPLICATION}/clear`)

export const applicationListClear = createAction(`${APPLICATION_LIST}/clear`)

export const applicationAllClear = createAction(`${APPLICATION_ALL}/clear`)

export const applicationTypesClear = createAction(`${APPLICATION_TYPES}/clear`)

export const applicationFieldBatchClear = createAction(`${APPLICATION_FIELD_BATCH}/clear`)

export const applicationRequestEditClear = createAction(`${APPLICATION_REQUEST_EDIT}/clear`)

export const applicationRequestUpdateClear = createAction(`${APPLICATION_REQUEST_UPDATE}/clear`)
