import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { CHECK_USER_OPEN_LINK, USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/actionTypes'
import * as API from '../constants/api'

export const generateOpenLink = createAsyncThunk(
  USER_OPEN_LINK,
  async (params: PayloadCreatorParams<`${typeof API.USER_OPEN_LINK}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.userOpenLink(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const checkOpenLink = createAsyncThunk(
  CHECK_USER_OPEN_LINK,
  async (params: PayloadCreatorParams<`${typeof API.USER_OPEN_LINK}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.checkUserOpenLink(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const userPublicInfo = createAsyncThunk(
  USER_PUBLIC_INFO,
  async (params: PayloadCreatorParams<`${typeof API.USER_PUBLIC_INFO}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.userPublicInfo(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const openLinkClear = createAction(`${USER_OPEN_LINK}clear`)

export const userPublicInfoClear = createAction(`${USER_PUBLIC_INFO}clear`)
