import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../../api/level/index'
import { LEVEL_ALL } from '../../constants/level/actionTypes'
import * as API from '../../constants/level/api'

export const levelAll = createAsyncThunk(
  LEVEL_ALL,
  async (params: PayloadCreatorParams<`${typeof API.LEVEL_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.levelAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const levelAllClear = createAction(`${LEVEL_ALL}/clear`)
