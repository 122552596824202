import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  GUEST,
  GUEST_ACCEPT_SEND_CONTRACT,
  GUEST_ALL,
  GUEST_APPOINTMENT_SCORE,
  GUEST_AVAILABILITY_DOCUMENTS,
  GUEST_AVAILABILITY_DOCUMENTS_UPDATE,
  GUEST_CHANGE_EDU_LANG,
  GUEST_CHANGE_STATUS,
  GUEST_CHANGE_STATUS_TRANSCRIPT,
  GUEST_CREATE_INVOICE,
  GUEST_CREATE_REFERENCE,
  GUEST_LIST,
  GUEST_PROFILE,
  GUEST_PROFILE_UPDATE,
  GUEST_RESET_COURSE,
  GUEST_RESET_EXAM,
  GUEST_TEACHING_EXPERIENCE,
} from '../constants/actionTypes'

const Selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const guestAllSelector = createSelector(Selectors, prop(GUEST_ALL))
export const guestListSelector = createSelector(Selectors, prop(GUEST_LIST))
export const guestSelector = createSelector(Selectors, prop(GUEST))
export const guestProfileSelector = createSelector(Selectors, prop(GUEST_PROFILE))
export const guestProfileUpdateSelector = createSelector(Selectors, prop(GUEST_PROFILE_UPDATE))
export const guestAvailabilityDocumentsUpdateSelector = createSelector(
  Selectors,
  prop(GUEST_AVAILABILITY_DOCUMENTS_UPDATE)
)
export const guestResetExamSelector = createSelector(Selectors, prop(GUEST_RESET_EXAM))
export const guestTeachingExperienceSelector = createSelector(Selectors, prop(GUEST_TEACHING_EXPERIENCE))
export const guestResetCourseSelector = createSelector(Selectors, prop(GUEST_RESET_COURSE))
export const guestCreateReferenceSelector = createSelector(Selectors, prop(GUEST_CREATE_REFERENCE))
export const guestCreateInvoiceSelector = createSelector(Selectors, prop(GUEST_CREATE_INVOICE))
export const guestChangeEduLangSelector = createSelector(Selectors, prop(GUEST_CHANGE_EDU_LANG))
export const guestAcceptSendContractSelector = createSelector(Selectors, prop(GUEST_ACCEPT_SEND_CONTRACT))
export const guestChangeStatusSelector = createSelector(Selectors, prop(GUEST_CHANGE_STATUS))
export const guestChangeStatusTranscriptSelector = createSelector(Selectors, prop(GUEST_CHANGE_STATUS_TRANSCRIPT))
export const guestAppointmentScoreSelector = createSelector(Selectors, prop(GUEST_APPOINTMENT_SCORE))
export const guestAvailabilityDocumentsSelector = createSelector(Selectors, prop(GUEST_AVAILABILITY_DOCUMENTS))
