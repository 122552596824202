import { CheckboxField, FlatpickrField, InputField, PhoneField, SelectField } from '@components/HookForm'
import { selectFieldValidator } from '@helpers/validators'
import { AvForm } from 'availity-reactstrap-validation-safe'
import { head, prop } from 'ramda'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useCss, useWindowSize } from 'react-use'
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap'

import EnrollmentGuards from '../EnrollmentGuards'

function EnrollmentForm(props) {
  const {
    control,
    register,
    handleSubmit,
    onSubmit,
    guards,
    errors,
    courseOptions,
    enrollmentCoursesLoading,
    languageOptions,
    genderOptions,
    handleCancel = () => {},
  } = props
  const { formatMessage: msg } = useIntl()

  const { width } = useWindowSize()
  const guard = head(guards) || undefined
  const guardsFieldCss = useCss({
    '@media (max-width: 1150px)': {
      flex: '0 0 70%',
      maxWidth: '70%',
      paddingLeft: '28px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
  const guardsTitleCss = useCss({
    '@media (max-width: 1150px)': {
      flex: '0 0 100%',
      maxWidth: '100%',
      justifyContent: 'center',
    },
  })
  const contactsFieldCss = useCss({
    '@media (max-width: 1320px)': {
      flex: '0 0 100%',
      maxWidth: '100%',
    },
  })
  const rowFlexCss = useCss({
    '@media (min-width: 768px) and (max-width: 1400px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
  })

  return (
    <>
      {guard ? (
        <Col sm={12} className='p-0'>
          <Card className={`${width > 768 ? 'w-75 ml-auto mr-auto' : ''}`}>
            <CardBody>
              <Row className='w-100'>
                <Col className='col-sm-12 mb-4'>
                  <h4>{msg({ id: 'Guardian information' })}</h4>
                </Col>
                <Col sm={12} className='p-0'>
                  <>
                    <EnrollmentGuards {...guard} />
                    <Row className='w-100'>
                      <Col className={`${guardsTitleCss} col-md-3 text-sm-center text-lg-left mb-sm-3 mt-sm-1`}>
                        <span className='font-weight-bold pl-3 '>{msg({ id: 'contactInformation' })}:</span>
                      </Col>
                      <Col className={`${guardsFieldCss} col-sm-12 col-md-4 ml-lg-3`}>
                        <Controller
                          render={({ field }) => (
                            <PhoneField
                              {...field}
                              label={`${msg({ id: 'contactPhone' })}:`}
                              mask='+\9\9\8\(99) 999 99 99'
                              register={register}
                            />
                          )}
                          name='guard_mobile_phone'
                          control={control}
                        />

                        <Controller
                          render={({ field }) => (
                            <PhoneField
                              {...field}
                              mask='+\9\9\8\(99) 999 99 99'
                              label={`${msg({ id: 'homePhone' })}:`}
                              register={register}
                            />
                          )}
                          name='guard_home_phone'
                          control={control}
                        />
                      </Col>

                      <Col className={`${guardsFieldCss} col-sm-12 col-md-4`}>
                        <Controller
                          render={({ field }) => (
                            <InputField {...field} label={`${msg({ id: 'address' })}:`} register={register} />
                          )}
                          name='guard_address'
                          control={control}
                        />

                        <Controller
                          render={({ field }) => (
                            <InputField
                              {...field}
                              label={`${msg({ id: 'email' })}:`}
                              type='email'
                              register={register}
                            />
                          )}
                          name='guard_email'
                          control={control}
                        />
                      </Col>
                    </Row>
                  </>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ) : null}

      <Col className='p-0'>
        <Card className={`${width > 768 ? 'w-75 ml-auto mr-auto' : ''}`}>
          <AvForm onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <Row>
                <Col sm={12} className='p-0'>
                  <Row className='px-1'>
                    <Col className='col-sm-12 mb-4 _w-xs-100'>
                      <h4>{msg({ id: 'contactInformation' })}</h4>
                    </Col>

                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <InputField {...field} label={msg({ id: 'email' })} type='email' register={register} />
                        )}
                        name='email'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <InputField {...field} label={msg({ id: 'permanentAddress' })} register={register} />
                        )}
                        name='permanent_address'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <InputField {...field} label={msg({ id: 'currentAddress' })} register={register} />
                        )}
                        name='current_address'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <SelectField
                            {...field}
                            label={msg({ id: 'Select first language' })}
                            register={register}
                            options={languageOptions}
                          />
                        )}
                        name='native_language'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <SelectField
                            {...field}
                            label={msg({ id: 'Select course' })}
                            register={register}
                            options={courseOptions}
                            isLoading={enrollmentCoursesLoading}
                            error={prop('course_id', errors)}
                          />
                        )}
                        name='course_id'
                        control={control}
                        rules={{
                          required: true,
                          validate: selectFieldValidator,
                        }}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <PhoneField
                            {...field}
                            mask='+\9\9\8\(99) 999 99 99'
                            label={`${msg({ id: 'phone' })}:`}
                            register={register}
                          />
                        )}
                        name='contact_phone'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <PhoneField
                            {...field}
                            mask='+\9\9\8\(99) 999 99 99'
                            label={`${msg({ id: 'homePhone' })}:`}
                            register={register}
                          />
                        )}
                        name='home_phone'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <PhoneField
                            {...field}
                            mask='+\9\9\8\(99) 999 99 99'
                            label={`${msg({ id: 'additionalPhone' })} #1`}
                            register={register}
                          />
                        )}
                        name='additional_phone1'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <PhoneField
                            {...field}
                            mask='+\9\9\8\(99) 999 99 99'
                            label={`${msg({ id: 'additionalPhone' })} #2`}
                            register={register}
                          />
                        )}
                        name='additional_phone2'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <FlatpickrField
                            {...field}
                            options={{
                              altInput: true,
                              altFormat: 'F j, Y',
                              dateFormat: 'Y-m-d',
                              disableMobile: 'true',
                            }}
                            register={register}
                            label={msg({ id: 'birthdate' })}
                            error={prop('birthdate', errors)}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                        name='birthdate'
                        control={control}
                      />
                    </Col>
                    <Col className={`${contactsFieldCss} col-lg-4 col-md-12`}>
                      <Controller
                        render={({ field }) => (
                          <SelectField {...field} label={msg({ id: 'gender' })} options={genderOptions} />
                        )}
                        name='gender'
                        control={control}
                      />
                    </Col>
                  </Row>

                  <Row className={`${rowFlexCss} w-100 d-md-flex mt-2`}>
                    <Col className='col-md-12 _w-xs-100 mb-3'>
                      <Controller
                        render={({ field }) => (
                          <CheckboxField
                            {...field}
                            register={register}
                            inlineLabel={msg({
                              id: 'By accepting this form, I confirm that the information above is correct to the best of my knowledge',
                            })}
                            error={prop('confirmation', errors)}
                          />
                        )}
                        name='confirmation'
                        control={control}
                        rules={{
                          required: true,
                          validate: value => (value ? null : 'required'),
                        }}
                      />
                    </Col>

                    <Col className='col-md-12 _w-xs-100 mb-3'>
                      <Controller
                        render={({ field }) => (
                          <CheckboxField
                            {...field}
                            register={register}
                            inlineLabel={msg({
                              id: 'If there have been changes in my personal information, I shall use Personal Information Change service to update my personal details.',
                            })}
                            error={prop('change_service', errors)}
                          />
                        )}
                        name='change_service'
                        control={control}
                        rules={{
                          required: true,
                          validate: value => (value ? null : 'required'),
                        }}
                      />
                    </Col>

                    <Col className='col-md-12 _w-xs-100 mb-3'>
                      <Controller
                        render={({ field }) => (
                          <CheckboxField
                            {...field}
                            register={register}
                            inlineLabel={msg({
                              id: 'I will undertake studies in the current academic year and will be a student for duration of the academic year. (This status can later be changed through submission of withdrawal or interruption forms).',
                            })}
                            error={prop('status', errors)}
                          />
                        )}
                        name='status'
                        control={control}
                        rules={{
                          required: true,
                          validate: value => (value ? null : 'required'),
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </AvForm>
          <CardFooter>
            <Row>
              <Col sm={12}>
                <Button
                  // loading={enrollmentCreateLoading}
                  // spinnerSize='sm'
                  className='btn float-right'
                  onClick={handleSubmit(onSubmit)}
                  color='primary'
                >
                  {msg({ id: 'Enroll' })}
                </Button>
                <Button.Ripple
                  outline
                  className='btn float-right'
                  color='primary'
                  style={{ marginRight: '10px' }}
                  onClick={handleCancel}
                >
                  {msg({ id: 'cancel' })}
                </Button.Ripple>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </>
  )
}

export default EnrollmentForm
