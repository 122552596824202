import { createAction as importAction } from '@helpers/createAction'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ACCOUNTING_CONTRACTS,
  ACCOUNTING_CONTRACTS_ASSIGN,
  ACCOUNTING_CONTRACTS_CREATE,
  ACCOUNTING_CONTRACTS_IMPORT,
  ACCOUNTING_CONTRACTS_LIST,
  ACCOUNTING_CONTRACTS_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const accountingContracts = createAsyncThunk(
  ACCOUNTING_CONTRACTS,
  async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.accountingContracts(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const accountingContractsCreate = createAsyncThunk(
  ACCOUNTING_CONTRACTS_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.accountingContractsCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const accountingContractsImport = (payload: { [key: string]: string }) => ({
  type: importAction(ACCOUNTING_CONTRACTS_IMPORT).pending,
  payload,
})

// export const accountingContractsImport = createAsyncThunk(
//   ACCOUNTING_CONTRACTS_IMPORT,
//   async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS_IMPORT}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.accountingContractsImport(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// async () => {
//       setLoading(true)
//       try {
//         const response = await accountingContractsImport({
//           ...data,
//           formData,
//         })
//         if (prop('error_logs', response.data)) {
//           let code = ''
//           prop('error_logs', response.data).forEach((str, idx) => {
//             if (idx === 0) {
//               code = `\n${str}\n`
//             } else {
//               code = `${code}${str}\n`
//             }
//           })
//           setErrorLogs(code)
//         }
//         dispatch(actions.accountingContractsList(query))
//         toast.success(<SuccessToast text='File has been imported successfully' />)
//         close()
//       } catch (e) {
//         toast.error(<ErrorToast text='File cannot be imported due to errors!' />)
//       } finally {
//         setLoading(false)
//       }
//     }

// export const accountingContractsAll = createAsyncThunk(
//   ACCOUNTING_CONTRACTS_ALL,
//   async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS_ALL}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await api.accountingContractsAll(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const accountingContractsList = createAsyncThunk(
  ACCOUNTING_CONTRACTS_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS_LIST}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.accountingContractsList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const accountingContractsTypes = createAsyncThunk(
  ACCOUNTING_CONTRACTS_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.accountingContractsTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const accountingContractsAssign = createAsyncThunk(
  ACCOUNTING_CONTRACTS_ASSIGN,
  async (params: PayloadCreatorParams<`${typeof API.ACCOUNTING_CONTRACTS_ASSIGN}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.accountingContractsAssign(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const accountingContractsClear = createAction(`${ACCOUNTING_CONTRACTS}/clear`)

// export const accountingContractsAllClear = createAction(`${ACCOUNTING_CONTRACTS_ALL}/clear`)

export const accountingContractsListClear = createAction(`${ACCOUNTING_CONTRACTS_LIST}/clear`)

export const accountingContractsTypesClear = createAction(`${ACCOUNTING_CONTRACTS_TYPES}/clear`)

export const accountingContractsAssignClear = createAction(`${ACCOUNTING_CONTRACTS_ASSIGN}/clear`)
