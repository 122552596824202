import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  INTERNAL_EXAM_APPLICANTS_LIST,
  INTERNAL_EXAM_PLACE,
  INTERNAL_EXAM_PLACE_ALL,
  INTERNAL_EXAM_PLACE_CREATE,
  INTERNAL_EXAM_PLACE_LIST,
} from '../constants/actionTypes'
import * as actions from './actions'
import { defaultState, initialState } from './initialState'

export const INTERNAL_EXAM_PLACES = GLOBAL_STATE

const internalExamPlaceSlice = createSlice({
  name: INTERNAL_EXAM_PLACES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actions.internalExamPlace.pending, state => {
        state[INTERNAL_EXAM_PLACE].loading = true
      })
      .addCase(actions.internalExamPlace.rejected, (state, action) => {
        state[INTERNAL_EXAM_PLACE].loading = false
        state[INTERNAL_EXAM_PLACE].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamPlace.fulfilled, (state, action) => {
        state[INTERNAL_EXAM_PLACE].loading = false
        state[INTERNAL_EXAM_PLACE].error = null
        state[INTERNAL_EXAM_PLACE].data = action.payload
      })

    builder
      .addCase(actions.internalExamPlaceAll.pending, state => {
        state[INTERNAL_EXAM_PLACE_ALL].loading = true
      })
      .addCase(actions.internalExamPlaceAll.rejected, (state, action) => {
        state[INTERNAL_EXAM_PLACE_ALL].loading = false
        state[INTERNAL_EXAM_PLACE_ALL].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamPlaceAll.fulfilled, (state, action) => {
        state[INTERNAL_EXAM_PLACE_ALL].loading = false
        state[INTERNAL_EXAM_PLACE_ALL].error = null
        state[INTERNAL_EXAM_PLACE_ALL].data = action.payload
      })

    builder
      .addCase(actions.internalExamPlaceList.pending, state => {
        state[INTERNAL_EXAM_PLACE_LIST].loading = true
      })
      .addCase(actions.internalExamPlaceList.rejected, (state, action) => {
        state[INTERNAL_EXAM_PLACE_LIST].loading = false
        state[INTERNAL_EXAM_PLACE_LIST].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamPlaceList.fulfilled, (state, action) => {
        state[INTERNAL_EXAM_PLACE_LIST].loading = false
        state[INTERNAL_EXAM_PLACE_LIST].error = null
        state[INTERNAL_EXAM_PLACE_LIST].data = action.payload
      })

    builder
      .addCase(actions.internalExamPlaceCreate.pending, state => {
        state[INTERNAL_EXAM_PLACE_CREATE].loading = true
      })
      .addCase(actions.internalExamPlaceCreate.rejected, (state, action) => {
        state[INTERNAL_EXAM_PLACE_CREATE].loading = false
        state[INTERNAL_EXAM_PLACE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamPlaceCreate.fulfilled, (state, action) => {
        state[INTERNAL_EXAM_PLACE_CREATE].loading = false
        state[INTERNAL_EXAM_PLACE_CREATE].error = null
        state[INTERNAL_EXAM_PLACE_CREATE].data = action.payload
      })

    builder
      .addCase(actions.internalExamApplicantsList.pending, state => {
        state[INTERNAL_EXAM_APPLICANTS_LIST].loading = true
      })
      .addCase(actions.internalExamApplicantsList.rejected, (state, action) => {
        state[INTERNAL_EXAM_APPLICANTS_LIST].loading = false
        state[INTERNAL_EXAM_APPLICANTS_LIST].error = action.payload as AxiosError
      })
      .addCase(actions.internalExamApplicantsList.fulfilled, (state, action) => {
        state[INTERNAL_EXAM_APPLICANTS_LIST].loading = false
        state[INTERNAL_EXAM_APPLICANTS_LIST].error = null
        state[INTERNAL_EXAM_APPLICANTS_LIST].data = action.payload
      })

    builder.addCase(actions.internalExamPlaceClear, state => {
      state[INTERNAL_EXAM_PLACE] = defaultState()
    })

    builder.addCase(actions.internalExamPlaceAllClear, state => {
      state[INTERNAL_EXAM_PLACE_ALL] = defaultState()
    })

    builder.addCase(actions.internalExamPlaceListClear, state => {
      state[INTERNAL_EXAM_PLACE_LIST] = defaultState()
    })

    builder.addCase(actions.internalExamApplicantsListClear, state => {
      state[INTERNAL_EXAM_APPLICANTS_LIST] = defaultState()
    })
  },
})

export const { reducer: internalExamPlaceReducer } = internalExamPlaceSlice
