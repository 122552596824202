import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const hrApplication: ApiFunc<`${typeof API.HR_APPLICATION_FORM}{id}`, 'get'> = data =>
  instance.get(`${API.HR_APPLICATION_FORM}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const hrApplicationCreate: ApiFunc<`${typeof API.HR_APPLICATION_FORM}`, 'post'> = data =>
  instance.post(API.HR_APPLICATION_FORM, data)

export const hrApplicationDelete: ApiFunc<`${typeof API.HR_APPLICATION_FORM}{id}`, 'delete'> = data =>
  instance.delete(`${API.HR_APPLICATION_FORM}${'id' in data ? prop('id', data) : data?.name}`)

export const hrApplicationList: ApiFunc<`${typeof API.HR_APPLICATION}`, 'get'> = data =>
  instance.get(API.HR_APPLICATION, {
    params: data,
  })

export const hrApplicationAll: ApiFunc<`${typeof API.HR_APPLICATION_ALL}`, 'get'> = data =>
  instance.get(API.HR_APPLICATION_ALL, {
    params: data,
  })

export const hrApplicationTypes: ApiFunc<`${typeof API.HR_APPLICATION_TYPES}`, 'get'> = data =>
  instance.get(API.HR_APPLICATION_TYPES, {
    params: data,
  })

export const hrApplicationStatus: ApiFunc<`${typeof API.HR_APPLICATION_STATUS}`, 'put'> = data =>
  instance.put(API.HR_APPLICATION_STATUS, data)

export const hrApplicationFieldBatchCreate: ApiFunc<`${typeof API.HR_APPLICATION_FIELD_BATCH}`, 'post'> = data =>
  instance.post(API.HR_APPLICATION_FIELD_BATCH, data)

export const hrApplicationFieldBatch: ApiFunc<`${typeof API.HR_APPLICATION_FIELD_BATCH}{id}`, 'get'> = data =>
  instance.get(`${API.HR_APPLICATION_FIELD_BATCH}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

//requst
export const hrApplicationRequestCreate: ApiFunc<`${typeof API.HR_APPLICATION_REQUEST}`, 'post'> = data =>
  instance.post(API.HR_APPLICATION_REQUEST, data)

export const hrApplicationRequestAll: ApiFunc<`${typeof API.HR_APPLICATION_REQUEST_ALL}`, 'get'> = data =>
  instance.get(API.HR_APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const hrApplicationRequestEdit: ApiFunc<`${typeof API.HR_APPLICATION_REQUEST_EDIT}`, 'get'> = data =>
  instance.get(API.HR_APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const hrApplicationRequestUpdate: ApiFunc<`${typeof API.HR_APPLICATION_REQUEST_UPDATE}`, 'put'> = data =>
  instance.put(API.HR_APPLICATION_REQUEST_UPDATE, data)
