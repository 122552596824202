import { path, prop } from 'ramda'
import { createSelector } from 'reselect'

import * as GLOBAL_STATE from '../constants/state'
import { RootState } from './store'

export * from '@module/AcademicMisconduct/redux/selector'
export * from '@module/AcademicYears/redux/selector'
export * from '@module/Auth/redux/selector'
export * from '@module/Account/redux/selector'
export * from '@module/Accounting/redux/selector'
export * from '@module/AchievementRecords/redux/selector'
export * from '@module/Assessment/redux/selector'
export * from '@module/AssessmentEC/redux/selector'
export * from '@module/AssessmentMarks/redux/selector'
export * from '@module/Attendance/redux/selector'
export * from '@module/AttendanceReason/redux/selector'
export * from '@module/Calendar/redux/selector'
export * from '@module/Category/redux/selector'
export * from '@module/City/redux/selector'
export * from '@module/ComposeEmail/redux/selector'
export * from '@module/ContractInformation/redux/selector'
export * from '@module/Country/redux/selector'
export * from '@module/Course/activate/redux/selector'
export * from '@module/Course/create/redux/selector'
export * from '@module/CourseTransfer/redux/selector'
export * from '@module/Debit/redux/selector'
export * from '@module/Departments/redux/selector'
export * from '@module/EducationPlace/redux/selector'
export * from '@module/Enrollment/redux/selector'
export * from '@module/Feedback/redux/selector'
export * from '@module/Files/ModuleFiles/redux/selector'
export * from '@module/Forms/redux/selector'
export * from '@module/GeneralAdmission/redux/selector'
export * from '@module/Groups/redux/selector'
export * from '@module/GuestUsers/redux/selector'
export * from '@module/HRAdmission/redux/selector'
export * from '@module/Institution/redux/selector'
export * from '@module/InternalExam/redux/selector'
export * from '@module/InternalExamPlace/redux/selector'
export * from '@module/IntroVideos/redux/selector'
export * from '@module/JobOffer/redux/selector'
export * from '@module/JobPosition/redux/selector'
export * from '@module/Lesson/redux/selector'
export * from '@module/Library/redux/selector'
export * from '@module/ModuleOutcome/redux/selector'
export * from '@module/Modules/activate/redux/selector'
export * from '@module/Modules/create/redux/selector'
export * from '@module/OpenLink/redux/selector'
export * from '@module/Payments/redux/selector'
export * from '@module/Player/redux/selector'
export * from '@module/QualityAssurance/redux/level/selector'
export * from '@module/Reports/redux/selector'
export * from '@module/Roles/redux/selector'
export * from '@module/Semester/redux/selector'
export * from '@module/ShortCourseAdmission/redux/selector'
export * from '@module/ShortTermCourse/redux/selector'
export * from '@module/ShortTermCourseRegistration/redux/selector'
export * from '@module/Staff/redux/selector'
export * from '@module/State/redux/selector'
export * from '@module/Stats/redux/selector'
export * from '@module/Student/redux/selector'
export * from '@module/StudentAdmission/redux/selector'
export * from '@module/StudentCV/redux/selector'
export * from '@module/StudentDashboard/redux/selector'
export * from '@module/StudentDocs/redux/selector'
export * from '@module/Timetable/redux/selectors'
export * from '@module/Types/redux/selector'
export * from '@module/VacancyPosition/redux/selector'

export const skinSelector = createSelector(path([GLOBAL_STATE.LAYOUT, 'skin']), data => data)

export const routerSelector = createSelector(
  (store: RootState) => ({
    location: store.router.location,
    pathname: store.router.location.pathname,
    query: store.router.location.query,
    state: store.router.location.state,
  }),
  data => data
)

export const querySearchSelector = createSelector(routerSelector, selected => selected.query.search)

export const querySizeSelector = createSelector(routerSelector, selected => selected.query.size)

export const queryPageSelector = createSelector(routerSelector, selected => selected.query.page)

export const queryIdSelector = createSelector(routerSelector, selected => selected.query.id)
