import { ADMIN, SUPER_ADMIN } from '@src/constants/userRoles'
import { lazy } from 'react'

import {
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST,
  UPDATE_PASSWORD,
  USER_INFO_HISTORY,
} from '../constants/route'

export const AuthRoutes = [
  {
    path: LOGIN,
    component: lazy(() => import('../views/Login')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true,
    },
  },
  {
    path: REGISTER,
    component: lazy(() => import('../views/Register')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true,
    },
  },
  {
    path: UPDATE_PASSWORD,
    component: lazy(() => import('../views/UpdatePassword')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true,
    },
  },
  {
    path: RESET_PASSWORD_REQUEST,
    component: lazy(() => import('../views/ResetPasswordRequest')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true,
    },
  },
  {
    path: RESET_PASSWORD,
    component: lazy(() => import('../views/ResetPassword')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true,
    },
  },

  {
    path: USER_INFO_HISTORY,
    component: lazy(() => import('../views/UserInfoHistory')),
    meta: {
      access: [SUPER_ADMIN, ADMIN],
    },
  },
]
