import { DefaultState } from '@src/redux/types'

import {
  ATTENDANCE_REASON,
  ATTENDANCE_REASON_ALL,
  ATTENDANCE_REASON_CREATE,
  ATTENDANCE_REASON_DELETE,
  ATTENDANCE_REASON_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ATTENDANCE_REASON]: DefaultState<`${typeof API.ATTENDANCE_REASON}{id}`, 'get'>
  [ATTENDANCE_REASON_ALL]: DefaultState<`${typeof API.ATTENDANCE_REASON_ALL}`, 'get'>
  [ATTENDANCE_REASON_CREATE]: DefaultState<`${typeof API.ATTENDANCE_REASON}`, 'post'>
  [ATTENDANCE_REASON_DELETE]: DefaultState<`${typeof API.ATTENDANCE_REASON}{id}`, 'delete'>
  [ATTENDANCE_REASON_LIST]: DefaultState<`${typeof API.ATTENDANCE_REASON}`, 'get'>
} = () => ({
  [ATTENDANCE_REASON]: defaultState(),
  [ATTENDANCE_REASON_ALL]: defaultState(),
  [ATTENDANCE_REASON_CREATE]: defaultState(),
  [ATTENDANCE_REASON_DELETE]: defaultState(),
  [ATTENDANCE_REASON_LIST]: defaultState(),
})
