import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ATTENDANCE_REASON,
  ATTENDANCE_REASON_ALL,
  ATTENDANCE_REASON_CREATE,
  ATTENDANCE_REASON_DELETE,
  ATTENDANCE_REASON_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ATTENDANCE_REASONS = GLOBAL_STATE

export const { reducer: attendanceReasonReducer } = createSlice({
  name: ATTENDANCE_REASONS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.attendanceReason.pending, state => {
        state[ATTENDANCE_REASON].loading = true
      })
      .addCase(actionCreators.attendanceReason.rejected, (state, action) => {
        state[ATTENDANCE_REASON].loading = false
        state[ATTENDANCE_REASON].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceReason.fulfilled, (state, action) => {
        state[ATTENDANCE_REASON].loading = false
        state[ATTENDANCE_REASON].error = null
        state[ATTENDANCE_REASON].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceReasonAll.pending, state => {
        state[ATTENDANCE_REASON_ALL].loading = true
      })
      .addCase(actionCreators.attendanceReasonAll.rejected, (state, action) => {
        state[ATTENDANCE_REASON_ALL].loading = false
        state[ATTENDANCE_REASON_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceReasonAll.fulfilled, (state, action) => {
        state[ATTENDANCE_REASON_ALL].loading = false
        state[ATTENDANCE_REASON_ALL].error = null
        state[ATTENDANCE_REASON_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceReasonCreate.pending, state => {
        state[ATTENDANCE_REASON_CREATE].loading = true
      })
      .addCase(actionCreators.attendanceReasonCreate.rejected, (state, action) => {
        state[ATTENDANCE_REASON_CREATE].loading = false
        state[ATTENDANCE_REASON_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceReasonCreate.fulfilled, (state, action) => {
        state[ATTENDANCE_REASON_CREATE].loading = false
        state[ATTENDANCE_REASON_CREATE].error = null
        state[ATTENDANCE_REASON_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceReasonDelete.pending, state => {
        state[ATTENDANCE_REASON_DELETE].loading = true
      })
      .addCase(actionCreators.attendanceReasonDelete.rejected, (state, action) => {
        state[ATTENDANCE_REASON_DELETE].loading = false
        state[ATTENDANCE_REASON_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceReasonDelete.fulfilled, (state, action) => {
        state[ATTENDANCE_REASON_DELETE].loading = false
        state[ATTENDANCE_REASON_DELETE].error = null
        state[ATTENDANCE_REASON_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.attendanceReasonList.pending, state => {
        state[ATTENDANCE_REASON_LIST].loading = true
      })
      .addCase(actionCreators.attendanceReasonList.rejected, (state, action) => {
        state[ATTENDANCE_REASON_LIST].loading = false
        state[ATTENDANCE_REASON_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.attendanceReasonList.fulfilled, (state, action) => {
        state[ATTENDANCE_REASON_LIST].loading = false
        state[ATTENDANCE_REASON_LIST].error = null
        state[ATTENDANCE_REASON_LIST].data = action.payload
      })

    builder.addCase(actionCreators.attendanceReasonClear, state => {
      state[ATTENDANCE_REASON] = defaultState()
    })

    builder.addCase(actionCreators.attendanceReasonAllClear, state => {
      state[ATTENDANCE_REASON_ALL] = defaultState()
    })

    builder.addCase(actionCreators.attendanceReasonListClear, state => {
      state[ATTENDANCE_REASON_LIST] = defaultState()
    })
  },
})
