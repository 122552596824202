import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  CCLS_MODULE,
  CCLS_MODULES_FILTER,
  CCLS_MODULE_ALL,
  CCLS_MODULE_ARTICULATE,
  CCLS_MODULE_ARTICULATE_ALL,
  CCLS_MODULE_CREATE,
  CCLS_MODULE_DELETE,
  CCLS_MODULE_LIST,
  CCLS_MODULE_TEACHER_UPDATE,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const CCLS_MODULES = GLOBAL_STATE

export const { reducer: cclsModuleReducer } = createSlice({
  name: CCLS_MODULES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.cclsModule.pending, state => {
        state[CCLS_MODULE].loading = true
      })
      .addCase(actionCreators.cclsModule.rejected, (state, action) => {
        state[CCLS_MODULE].loading = false
        state[CCLS_MODULE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModule.fulfilled, (state, action) => {
        state[CCLS_MODULE].loading = false
        state[CCLS_MODULE].error = null
        state[CCLS_MODULE].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModulesFilter.pending, state => {
        state[CCLS_MODULES_FILTER].loading = true
      })
      .addCase(actionCreators.cclsModulesFilter.rejected, (state, action) => {
        state[CCLS_MODULES_FILTER].loading = false
        state[CCLS_MODULES_FILTER].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModulesFilter.fulfilled, (state, action) => {
        state[CCLS_MODULES_FILTER].loading = false
        state[CCLS_MODULES_FILTER].error = null
        state[CCLS_MODULES_FILTER].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleAll.pending, state => {
        state[CCLS_MODULE_ALL].loading = true
      })
      .addCase(actionCreators.cclsModuleAll.rejected, (state, action) => {
        state[CCLS_MODULE_ALL].loading = false
        state[CCLS_MODULE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleAll.fulfilled, (state, action) => {
        state[CCLS_MODULE_ALL].loading = false
        state[CCLS_MODULE_ALL].error = null
        state[CCLS_MODULE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleCreate.pending, state => {
        state[CCLS_MODULE_CREATE].loading = true
      })
      .addCase(actionCreators.cclsModuleCreate.rejected, (state, action) => {
        state[CCLS_MODULE_CREATE].loading = false
        state[CCLS_MODULE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleCreate.fulfilled, (state, action) => {
        state[CCLS_MODULE_CREATE].loading = false
        state[CCLS_MODULE_CREATE].error = null
        state[CCLS_MODULE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleDelete.pending, state => {
        state[CCLS_MODULE_DELETE].loading = true
      })
      .addCase(actionCreators.cclsModuleDelete.rejected, (state, action) => {
        state[CCLS_MODULE_DELETE].loading = false
        state[CCLS_MODULE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleDelete.fulfilled, (state, action) => {
        state[CCLS_MODULE_DELETE].loading = false
        state[CCLS_MODULE_DELETE].error = null
        state[CCLS_MODULE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleList.pending, state => {
        state[CCLS_MODULE_LIST].loading = true
      })
      .addCase(actionCreators.cclsModuleList.rejected, (state, action) => {
        state[CCLS_MODULE_LIST].loading = false
        state[CCLS_MODULE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleList.fulfilled, (state, action) => {
        state[CCLS_MODULE_LIST].loading = false
        state[CCLS_MODULE_LIST].error = null
        state[CCLS_MODULE_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleTeacherUpdate.pending, state => {
        state[CCLS_MODULE_TEACHER_UPDATE].loading = true
      })
      .addCase(actionCreators.cclsModuleTeacherUpdate.rejected, (state, action) => {
        state[CCLS_MODULE_TEACHER_UPDATE].loading = false
        state[CCLS_MODULE_TEACHER_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleTeacherUpdate.fulfilled, (state, action) => {
        state[CCLS_MODULE_TEACHER_UPDATE].loading = false
        state[CCLS_MODULE_TEACHER_UPDATE].error = null
        state[CCLS_MODULE_TEACHER_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleArticulate.pending, state => {
        state[CCLS_MODULE_ARTICULATE].loading = true
      })
      .addCase(actionCreators.cclsModuleArticulate.rejected, (state, action) => {
        state[CCLS_MODULE_ARTICULATE].loading = false
        state[CCLS_MODULE_ARTICULATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleArticulate.fulfilled, (state, action) => {
        state[CCLS_MODULE_ARTICULATE].loading = false
        state[CCLS_MODULE_ARTICULATE].error = null
        state[CCLS_MODULE_ARTICULATE].data = action.payload
      })

    builder
      .addCase(actionCreators.cclsModuleArticulateAll.pending, state => {
        state[CCLS_MODULE_ARTICULATE_ALL].loading = true
      })
      .addCase(actionCreators.cclsModuleArticulateAll.rejected, (state, action) => {
        state[CCLS_MODULE_ARTICULATE_ALL].loading = false
        state[CCLS_MODULE_ARTICULATE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.cclsModuleArticulateAll.fulfilled, (state, action) => {
        state[CCLS_MODULE_ARTICULATE_ALL].loading = false
        state[CCLS_MODULE_ARTICULATE_ALL].error = null
        state[CCLS_MODULE_ARTICULATE_ALL].data = action.payload
      })

    builder.addCase(actionCreators.cclsModuleArticulateClear, state => {
      state[CCLS_MODULE_ARTICULATE] = defaultState()
    })

    builder.addCase(actionCreators.cclsModuleArticulateAllClear, state => {
      state[CCLS_MODULE_ARTICULATE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.cclsModuleClear, state => {
      state[CCLS_MODULE] = defaultState()
    })

    builder.addCase(actionCreators.cclsModuleAllClear, state => {
      state[CCLS_MODULE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.cclsModuleListClear, state => {
      state[CCLS_MODULE_LIST] = defaultState()
    })

    builder.addCase(actionCreators.cclsModulesFilterClear, state => {
      state[CCLS_MODULES_FILTER] = defaultState()
    })
  },
})
