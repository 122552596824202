import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import { Controller } from 'react-hook-form'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

import { indexedMap } from '../../helpers/indexedMap'
import { SelectField } from '../HookForm'

function HookFormFilter({ fields, control, handleFilterClick = () => {}, filterButton = false }) {
  return (
    <Row>
      <Col>
        <Card>
          <CardBody style={{ paddingBottom: '4px' }}>
            <Row className='align-items-center'>
              {indexedMap((field, index) => {
                const name = prop('name', field)
                const options = prop('options', field)
                const onFocus = propOr(() => {}, 'onFocus', field)
                const handleChange = prop('onChange', field)
                const label = prop('label', field)
                const isLoading = prop('isLoading', field)
                const shouldRenderField = propOr(true, 'shouldRenderField', field)
                const md = propOr('', 'md', field)
                const rules = propOr({}, 'rules', field)
                const required = prop('required', field)
                const isClearable = prop('isClearable', field)
                const normalize = prop('normalize', field)
                const isMulti = propOr(false, 'isMulti', field)

                return (
                  <Col key={`${index}-field`} className='' md={md}>
                    {shouldRenderField ? (
                      <Controller
                        name={name}
                        control={control}
                        shouldUnregister
                        render={({ field }) => {
                          return (
                            <SelectField
                              {...field}
                              label={label}
                              options={options}
                              onFocus={onFocus}
                              isLoading={isLoading}
                              rules={rules}
                              handleChange={handleChange}
                              isMulti={isMulti}
                              isClearable={isClearable}
                              normalize={normalize}
                              required={required}
                            />
                          )
                        }}
                        // defaultValue={defaultValue}
                      />
                    ) : null}
                  </Col>
                )
              }, fields)}
            </Row>
            {filterButton && (
              <Row>
                <Col className='text-right'>
                  <Button onClick={handleFilterClick}>Filter</Button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

HookFormFilter.defaultProps = {
  fields: [],
}

HookFormFilter.propTypes = {
  fields: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  register: PropTypes.func,
  handleFilterClick: PropTypes.func,
  filterButton: PropTypes.bool,
}

export default HookFormFilter
