export const GLOBAL_STATE = 'Enrollment'

export const ENROLLMENT = 'ENROLLMENT' as const
export const ENROLLMENT_CREATE = 'ENROLLMENT_CREATE' as const
export const ENROLLMENT_DELETE = 'ENROLLMENT_DELETE' as const
export const ENROLLMENT_LIST = 'ENROLLMENT_LIST' as const
export const ENROLLMENT_ALL = 'ENROLLMENT_ALL' as const
export const ENROLLMENT_STATUS = 'ENROLLMENT_STATUS' as const
export const ENROLLMENT_COURSES = 'ENROLLMENT_COURSES' as const
export const ENROLLMENT_STUDENT_OWN = 'ENROLLMENT_STUDENT_OWN' as const
