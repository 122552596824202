import { DefaultState } from '@src/redux/types'

import { ROLE, ROLE_ALL, ROLE_CREATE, ROLE_DELETE, ROLE_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ROLE]: DefaultState<`${typeof API.ROLE}{id}`, 'get'>
  [ROLE_ALL]: DefaultState<`${typeof API.ROLE_ALL}`, 'get'>
  [ROLE_CREATE]: DefaultState<`${typeof API.ROLE}`, 'post'>
  [ROLE_DELETE]: DefaultState<`${typeof API.ROLE}{id}`, 'delete'>
  [ROLE_LIST]: DefaultState<`${typeof API.ROLE}`, 'get'>
} = () => ({
  [ROLE]: defaultState(),
  [ROLE_ALL]: defaultState(),
  [ROLE_CREATE]: defaultState(),
  [ROLE_DELETE]: defaultState(),
  [ROLE_LIST]: defaultState(),
})
