import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ASSESSMENT_ATTEMPT_CREATE,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_BULK,
  ASSESSMENT_COMPONENT_CREATE,
  ASSESSMENT_COMPONENT_DELETE,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_LIST,
  ASSESSMENT_OVERALL_GRADE_HISTORY,
  ASSESSMENT_RETAKE,
  ASSESSMENT_RETAKE_DELETE,
  ASSESSMENT_STUDENT,
  ASSESSMENT_STUDENT_CRITERIA_DETAILS,
  ASSESSMENT_STUDENT_UPDATED,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_SUBMIT,
  ATTEMPT_DELETE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const assessmentCreate = createAsyncThunk(
  ASSESSMENT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentList = createAsyncThunk(
  ASSESSMENT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentBulk = createAsyncThunk(
  ASSESSMENT_BULK,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_BULK}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentBulkCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAttemptCreate = createAsyncThunk(
  ASSESSMENT_ATTEMPT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_ATTEMPT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAttemptCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentAttempt = createAsyncThunk(
  ASSESSMENT_ATTEMPT_ITEM,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_ATTEMPT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentAttempt(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentCopy = createAsyncThunk(
  ASSESSMENT_COPY,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_COPY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentCopy(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentSubmit = createAsyncThunk(
  ASSESSMENT_SUBMIT,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_SUBMIT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentSubmit(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentCriteriaUpdate = createAsyncThunk(
  ASSESSMENT_CRITERIA_UPDATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_CRITERIA}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentCriteriaUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentCriteriaList = createAsyncThunk(
  ASSESSMENT_CRITERIA_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_CRITERIA_LIST}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentCriteriaList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attemptDelete = createAsyncThunk(
  ATTEMPT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_ATTEMPT}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.attemptDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentCriteriaTypes = createAsyncThunk(
  ASSESSMENT_CRITERIA_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_CRITERIA_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentCriteriaTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentStudentUpload = createAsyncThunk(
  ASSESSMENT_STUDENT_UPLOAD,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_STUDENT_UPLOAD}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentStudentUpload(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentStudent = createAsyncThunk(
  ASSESSMENT_STUDENT,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_STUDENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentStudent(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentStudentUpdated = createAsyncThunk(
  ASSESSMENT_STUDENT_UPDATED,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_STUDENT_UPDATED}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentStudentUpdated(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentComponentCreate = createAsyncThunk(
  ASSESSMENT_COMPONENT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_COMPONENT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentComponentCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentComponentDelete = createAsyncThunk(
  ASSESSMENT_COMPONENT_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_COMPONENT}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentComponentDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentStudentCriteriaDetails = createAsyncThunk(
  ASSESSMENT_STUDENT_CRITERIA_DETAILS,
  async (
    params: PayloadCreatorParams<`${typeof API.ASSESSMENT_STUDENT_CRITERIA_DETAILS}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.assessmentStudentCriteriaDetails(
        omit(['onFulfilled', 'onRejected', 'onSettled'], params)
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentRetakeDelete = createAsyncThunk(
  ASSESSMENT_RETAKE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_RETAKE}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentRetakeDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentRetake = createAsyncThunk(
  ASSESSMENT_RETAKE,
  async (params: PayloadCreatorParams<`${typeof API.ASSESSMENT_RETAKE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.assessmentRetake(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentOverallGradeHistory = createAsyncThunk(
  ASSESSMENT_OVERALL_GRADE_HISTORY,
  async (
    params: PayloadCreatorParams<`${typeof API.ASSESSMENT_OVERALL_GRADE_HISTORY}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.assessmentOverallGradeHistory(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const assessmentListClear = createAction(`${ASSESSMENT_LIST}/clear`)

export const assessmentBulkClear = createAction(`${ASSESSMENT_BULK}/clear`)

export const assessmentAttemptItemClear = createAction(`${ASSESSMENT_ATTEMPT_ITEM}/clear`)

export const assessmentCriteriaListClear = createAction(`${ASSESSMENT_CRITERIA_LIST}/clear`)

export const assessmentCriteriaTypesClear = createAction(`${ASSESSMENT_CRITERIA_TYPES}/clear`)

export const assessmentStudentClear = createAction(`${ASSESSMENT_STUDENT}/clear`)

export const assessmentStudentUpdatedClear = createAction(`${ASSESSMENT_STUDENT_UPDATED}/clear`)

export const assessmentStudentCriteriaDetailsClear = createAction(`${ASSESSMENT_STUDENT_CRITERIA_DETAILS}/clear`)

export const assessmentComponentCreateClear = createAction(`${ASSESSMENT_COMPONENT_CREATE}/clear`)
