import { DefaultState } from '@src/redux/types'

import { CHECK_USER_OPEN_LINK, USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [CHECK_USER_OPEN_LINK]: DefaultState<`${typeof API.USER_OPEN_LINK}`, 'get'>
  [USER_OPEN_LINK]: DefaultState<`${typeof API.USER_OPEN_LINK}`, 'post'>
  [USER_PUBLIC_INFO]: DefaultState<`${typeof API.USER_PUBLIC_INFO}`, 'get'>
} = () => ({
  [CHECK_USER_OPEN_LINK]: defaultState(),
  [USER_OPEN_LINK]: defaultState(),
  [USER_PUBLIC_INFO]: defaultState(),
})
