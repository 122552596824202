import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_DELETE,
  ASSESSMENT_EC_LIST,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ASSESSMENTS_EC = GLOBAL_STATE

export const { reducer: assessmentECReducer } = createSlice({
  name: ASSESSMENTS_EC,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.assessmentECList.pending, state => {
        state[ASSESSMENT_EC_LIST].loading = true
      })
      .addCase(actionCreators.assessmentECList.rejected, (state, action) => {
        state[ASSESSMENT_EC_LIST].loading = false
        state[ASSESSMENT_EC_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECList.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_LIST].loading = false
        state[ASSESSMENT_EC_LIST].error = null
        state[ASSESSMENT_EC_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECItem.pending, state => {
        state[ASSESSMENT_EC].loading = true
      })
      .addCase(actionCreators.assessmentECItem.rejected, (state, action) => {
        state[ASSESSMENT_EC].loading = false
        state[ASSESSMENT_EC].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECItem.fulfilled, (state, action) => {
        state[ASSESSMENT_EC].loading = false
        state[ASSESSMENT_EC].error = null
        state[ASSESSMENT_EC].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECAll.pending, state => {
        state[ASSESSMENT_EC_ALL].loading = true
      })
      .addCase(actionCreators.assessmentECAll.rejected, (state, action) => {
        state[ASSESSMENT_EC_ALL].loading = false
        state[ASSESSMENT_EC_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECAll.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_ALL].loading = false
        state[ASSESSMENT_EC_ALL].error = null
        state[ASSESSMENT_EC_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECChangeStatus.pending, state => {
        state[ASSESSMENT_EC_STATUS].loading = true
      })
      .addCase(actionCreators.assessmentECChangeStatus.rejected, (state, action) => {
        state[ASSESSMENT_EC_STATUS].loading = false
        state[ASSESSMENT_EC_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECChangeStatus.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_STATUS].loading = false
        state[ASSESSMENT_EC_STATUS].error = null
        state[ASSESSMENT_EC_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECCreate.pending, state => {
        state[ASSESSMENT_EC_CREATE].loading = true
      })
      .addCase(actionCreators.assessmentECCreate.rejected, (state, action) => {
        state[ASSESSMENT_EC_CREATE].loading = false
        state[ASSESSMENT_EC_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECCreate.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_CREATE].loading = false
        state[ASSESSMENT_EC_CREATE].error = null
        state[ASSESSMENT_EC_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECDelete.pending, state => {
        state[ASSESSMENT_EC_DELETE].loading = true
      })
      .addCase(actionCreators.assessmentECDelete.rejected, (state, action) => {
        state[ASSESSMENT_EC_DELETE].loading = false
        state[ASSESSMENT_EC_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECDelete.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_DELETE].loading = false
        state[ASSESSMENT_EC_DELETE].error = null
        state[ASSESSMENT_EC_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECOutcomeReport.pending, state => {
        state[ASSESSMENT_EC_OUTCOME_REPORT].loading = true
      })
      .addCase(actionCreators.assessmentECOutcomeReport.rejected, (state, action) => {
        state[ASSESSMENT_EC_OUTCOME_REPORT].loading = false
        state[ASSESSMENT_EC_OUTCOME_REPORT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECOutcomeReport.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_OUTCOME_REPORT].loading = false
        state[ASSESSMENT_EC_OUTCOME_REPORT].error = null
        state[ASSESSMENT_EC_OUTCOME_REPORT].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECPaper.pending, state => {
        state[ASSESSMENT_EC_PAPER].loading = true
      })
      .addCase(actionCreators.assessmentECPaper.rejected, (state, action) => {
        state[ASSESSMENT_EC_PAPER].loading = false
        state[ASSESSMENT_EC_PAPER].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECPaper.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_PAPER].loading = false
        state[ASSESSMENT_EC_PAPER].error = null
        state[ASSESSMENT_EC_PAPER].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentECUpdate.pending, state => {
        state[ASSESSMENT_EC_UPDATE].loading = true
      })
      .addCase(actionCreators.assessmentECUpdate.rejected, (state, action) => {
        state[ASSESSMENT_EC_UPDATE].loading = false
        state[ASSESSMENT_EC_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentECUpdate.fulfilled, (state, action) => {
        state[ASSESSMENT_EC_UPDATE].loading = false
        state[ASSESSMENT_EC_UPDATE].error = null
        state[ASSESSMENT_EC_UPDATE].data = action.payload
      })

    builder.addCase(actionCreators.assessmentECListClear, state => {
      state[ASSESSMENT_EC_LIST] = defaultState()
    })
  },
})
