// ** React Imports
// ** Config
import themeConfig from '@configs/themeConfig'
import { useAppSelector } from '@hooks'
import { skinSelector } from '@store/selector'
import { useEffect } from 'react'
// ** Third Party Components
import { Circle, Disc, X } from 'react-feather'
import { NavLink } from 'react-router-dom'

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props

  const skin = useAppSelector(skinSelector)

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div className='navbar-header' style={{ height: '84px' }}>
      <ul className='nav navbar-nav flex-row' style={{ height: '100%' }}>
        <li
          className='nav-item'
          style={{ marginRight: 'auto', marginLeft: menuCollapsed && !menuHover ? '' : 'auto', height: '100%' }}
        >
          <NavLink to='/' className='navbar-brand text-center m-0' style={{ height: '100%' }}>
            <img
              src={themeConfig.app[skin === 'dark' ? 'appLogoLight' : 'appLogo']}
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', objectPosition: 'center' }}
              alt='logo'
              width={menuCollapsed && !menuHover ? 50 : 120}
            />
          </NavLink>
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <Toggler />
            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
