import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { DASHBOARD_STUDENT, GLOBAL_STATE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const STUDENT_DASHBOARD = GLOBAL_STATE

export const { reducer: studentDashboardReducer } = createSlice({
  name: STUDENT_DASHBOARD,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.dashboardStudent.pending, state => {
        state[DASHBOARD_STUDENT].loading = true
      })
      .addCase(actionCreators.dashboardStudent.rejected, (state, action) => {
        state[DASHBOARD_STUDENT].loading = false
        state[DASHBOARD_STUDENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.dashboardStudent.fulfilled, (state, action) => {
        state[DASHBOARD_STUDENT].loading = false
        state[DASHBOARD_STUDENT].error = null
        state[DASHBOARD_STUDENT].data = action.payload
      })

    builder.addCase(actionCreators.dashboardStudentClear, state => {
      state[DASHBOARD_STUDENT] = defaultState()
    })
  },
})
