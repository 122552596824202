// ** React Imports
import { useState, forwardRef } from 'react'

// ** MUI Imports
// ** Third Party Imports
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

//const CustomInput = forwardRef((props, ref) => {
//  const startDate = format(props.start, 'MM/dd/yyyy')
//   const endDate = props.end !== null ?  - {format(props.end, 'MM/dd/yyyy')} : null
//   const value = {startDate}{endDate !== null ? endDate : ''}

//   return <TextField inputRef={ref} label={props.label || ''} {...props} value={value} />
//})

const DatePickerField = () => {
  // ** States
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(addDays(new Date(), 15))
  const [startDateRange, setStartDateRange] = useState(new Date())
  const [endDateRange, setEndDateRange] = useState(addDays(new Date(), 45))

  const handleOnChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleOnChangeRange = dates => {
    const [start, end] = dates
    setStartDateRange(start)
    setEndDateRange(end)
  }

  return (
    <>
      <DatePicker
        selectsRange
        monthsShown={2}
        endDate={endDateRange}
        selected={startDateRange}
        startDate={startDateRange}
        shouldCloseOnSelect={false}
        id='date-range-picker-months'
        onChange={handleOnChangeRange}
        popperPlacement='auto'
        // customInput={<CustomInput label='Multiple Months' end={endDateRange} start={startDateRange} />}
      />
    </>
  )
}

export default DatePickerField
