import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, MODULES_OUTCOME, MODULES_OUTCOME_CREATE, MODULES_OUTCOME_LIST } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const MODULES_OUTCOMES = GLOBAL_STATE

export const { reducer: moduleOutcomeReducer } = createSlice({
  name: MODULES_OUTCOMES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.modulesOutcome.pending, state => {
        state[MODULES_OUTCOME].loading = true
      })
      .addCase(actionCreators.modulesOutcome.rejected, (state, action) => {
        state[MODULES_OUTCOME].loading = false
        state[MODULES_OUTCOME].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesOutcome.fulfilled, (state, action) => {
        state[MODULES_OUTCOME].loading = false
        state[MODULES_OUTCOME].error = null
        state[MODULES_OUTCOME].data = action.payload
      })

    builder
      .addCase(actionCreators.modulesOutcomeCreate.pending, state => {
        state[MODULES_OUTCOME_CREATE].loading = true
      })
      .addCase(actionCreators.modulesOutcomeCreate.rejected, (state, action) => {
        state[MODULES_OUTCOME_CREATE].loading = false
        state[MODULES_OUTCOME_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesOutcomeCreate.fulfilled, (state, action) => {
        state[MODULES_OUTCOME_CREATE].loading = false
        state[MODULES_OUTCOME_CREATE].error = null
        state[MODULES_OUTCOME_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.modulesOutcomeList.pending, state => {
        state[MODULES_OUTCOME_LIST].loading = true
      })
      .addCase(actionCreators.modulesOutcomeList.rejected, (state, action) => {
        state[MODULES_OUTCOME_LIST].loading = false
        state[MODULES_OUTCOME_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesOutcomeList.fulfilled, (state, action) => {
        state[MODULES_OUTCOME_LIST].loading = false
        state[MODULES_OUTCOME_LIST].error = null
        state[MODULES_OUTCOME_LIST].data = action.payload
      })

    builder.addCase(actionCreators.modulesOutcomeClear, state => {
      state[MODULES_OUTCOME] = defaultState()
    })

    builder.addCase(actionCreators.modulesOutcomeListClear, state => {
      state[MODULES_OUTCOME_LIST] = defaultState()
    })
  },
})
