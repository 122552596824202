import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ACHIEVEMENT_RECORDS,
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_DELETE,
  ACHIEVEMENT_RECORDS_LIST,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const achievementRecords = createAsyncThunk(
  ACHIEVEMENT_RECORDS,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecord(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsAll = createAsyncThunk(
  ACHIEVEMENT_RECORDS_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecordsAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsList = createAsyncThunk(
  ACHIEVEMENT_RECORDS_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecordsList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsTypes = createAsyncThunk(
  ACHIEVEMENT_RECORDS_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecordsTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsChangeStatus = createAsyncThunk(
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  async (
    params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS_CHANGE_STATUS}`, 'put'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.achievementRecordsChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsCreate = createAsyncThunk(
  ACHIEVEMENT_RECORDS_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecordsCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsStudent = createAsyncThunk(
  ACHIEVEMENT_RECORDS_STUDENT,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS_STUDENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecordsStudent(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsDelete = createAsyncThunk(
  ACHIEVEMENT_RECORDS_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.ACHIEVEMENT_RECORDS}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.achievementRecordsDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const achievementRecordsAllClear = createAction(`${ACHIEVEMENT_RECORDS_ALL}/clear`)

export const achievementRecordsListClear = createAction(`${ACHIEVEMENT_RECORDS_LIST}/clear`)

export const achievementRecordsTypesClear = createAction(`${ACHIEVEMENT_RECORDS_TYPES}/clear`)

export const achievementRecordsStudentClear = createAction(`${ACHIEVEMENT_RECORDS_STUDENT}/clear`)
