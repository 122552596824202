import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ACHIEVEMENT_RECORDS,
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_DELETE,
  ACHIEVEMENT_RECORDS_LIST,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ACHIEVEMENTS_RECORDS = GLOBAL_STATE

export const { reducer: achievementRecordsReducer } = createSlice({
  name: ACHIEVEMENTS_RECORDS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.achievementRecords.pending, state => {
        state[ACHIEVEMENT_RECORDS].loading = true
      })
      .addCase(actionCreators.achievementRecords.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS].loading = false
        state[ACHIEVEMENT_RECORDS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecords.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS].loading = false
        state[ACHIEVEMENT_RECORDS].error = null
        state[ACHIEVEMENT_RECORDS].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsAll.pending, state => {
        state[ACHIEVEMENT_RECORDS_ALL].loading = true
      })
      .addCase(actionCreators.achievementRecordsAll.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_ALL].loading = false
        state[ACHIEVEMENT_RECORDS_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsAll.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_ALL].loading = false
        state[ACHIEVEMENT_RECORDS_ALL].error = null
        state[ACHIEVEMENT_RECORDS_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsChangeStatus.pending, state => {
        state[ACHIEVEMENT_RECORDS_CHANGE_STATUS].loading = true
      })
      .addCase(actionCreators.achievementRecordsChangeStatus.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_CHANGE_STATUS].loading = false
        state[ACHIEVEMENT_RECORDS_CHANGE_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsChangeStatus.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_CHANGE_STATUS].loading = false
        state[ACHIEVEMENT_RECORDS_CHANGE_STATUS].error = null
        state[ACHIEVEMENT_RECORDS_CHANGE_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsCreate.pending, state => {
        state[ACHIEVEMENT_RECORDS_CREATE].loading = true
      })
      .addCase(actionCreators.achievementRecordsCreate.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_CREATE].loading = false
        state[ACHIEVEMENT_RECORDS_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsCreate.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_CREATE].loading = false
        state[ACHIEVEMENT_RECORDS_CREATE].error = null
        state[ACHIEVEMENT_RECORDS_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsDelete.pending, state => {
        state[ACHIEVEMENT_RECORDS_DELETE].loading = true
      })
      .addCase(actionCreators.achievementRecordsDelete.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_DELETE].loading = false
        state[ACHIEVEMENT_RECORDS_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsDelete.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_DELETE].loading = false
        state[ACHIEVEMENT_RECORDS_DELETE].error = null
        state[ACHIEVEMENT_RECORDS_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsList.pending, state => {
        state[ACHIEVEMENT_RECORDS_LIST].loading = true
      })
      .addCase(actionCreators.achievementRecordsList.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_LIST].loading = false
        state[ACHIEVEMENT_RECORDS_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsList.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_LIST].loading = false
        state[ACHIEVEMENT_RECORDS_LIST].error = null
        state[ACHIEVEMENT_RECORDS_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsStudent.pending, state => {
        state[ACHIEVEMENT_RECORDS_STUDENT].loading = true
      })
      .addCase(actionCreators.achievementRecordsStudent.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_STUDENT].loading = false
        state[ACHIEVEMENT_RECORDS_STUDENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsStudent.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_STUDENT].loading = false
        state[ACHIEVEMENT_RECORDS_STUDENT].error = null
        state[ACHIEVEMENT_RECORDS_STUDENT].data = action.payload
      })

    builder
      .addCase(actionCreators.achievementRecordsTypes.pending, state => {
        state[ACHIEVEMENT_RECORDS_TYPES].loading = true
      })
      .addCase(actionCreators.achievementRecordsTypes.rejected, (state, action) => {
        state[ACHIEVEMENT_RECORDS_TYPES].loading = false
        state[ACHIEVEMENT_RECORDS_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.achievementRecordsTypes.fulfilled, (state, action) => {
        state[ACHIEVEMENT_RECORDS_TYPES].loading = false
        state[ACHIEVEMENT_RECORDS_TYPES].error = null
        state[ACHIEVEMENT_RECORDS_TYPES].data = action.payload
      })

    builder.addCase(actionCreators.achievementRecordsAllClear, state => {
      state[ACHIEVEMENT_RECORDS_ALL] = defaultState()
    })

    builder.addCase(actionCreators.achievementRecordsListClear, state => {
      state[ACHIEVEMENT_RECORDS_LIST] = defaultState()
    })

    builder.addCase(actionCreators.achievementRecordsStudentClear, state => {
      state[ACHIEVEMENT_RECORDS_STUDENT] = defaultState()
    })

    builder.addCase(actionCreators.achievementRecordsTypesClear, state => {
      state[ACHIEVEMENT_RECORDS_TYPES] = defaultState()
    })
  },
})
