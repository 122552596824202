import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { REPORT, REPORT_CREATE, REPORT_FIELDS, REPORT_FIELDS_FILTER, REPORT_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const report = createAsyncThunk(
  REPORT,
  async (params: PayloadCreatorParams<`${typeof API.REPORT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.report(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const reportCreate = createAsyncThunk(
  REPORT_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.REPORT}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.reportCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const reportAll = createAsyncThunk(
//   REPORT_ALL,
//   async (params: PayloadCreatorParams<`${typeof API.REPORT_ALL}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await api.reportAll(omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params), params?.config)
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const reportList = createAsyncThunk(
  REPORT_LIST,
  async (params: PayloadCreatorParams<`${typeof API.REPORT_LIST}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.reportList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const reportFields = createAsyncThunk(
  REPORT_FIELDS,
  async (params: PayloadCreatorParams<`${typeof API.REPORT_FIELDS}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.reportFields(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const reportFieldsFilter = createAsyncThunk(
  REPORT_FIELDS_FILTER,
  async (params: PayloadCreatorParams<`${typeof API.REPORT_FIELDS_FILTER}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.reportFieldsFilter(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const reportAllClear = createAction(`${REPORT_ALL}/clear`)

export const reportListClear = createAction(`${REPORT_LIST}/clear`)

export const reportClear = createAction(`${REPORT}/clear`)

export const reportFieldsClear = createAction(`${REPORT_FIELDS}/clear`)

export const reportFieldsFilterClear = createAction(`${REPORT_FIELDS_FILTER}/clear`)
