import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const calendarEvent: ApiFunc<`${typeof API.CALENDAR_EVENT}{id}`, 'get'> = data =>
  instance.get(`${API.CALENDAR_EVENT}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const calendarEventCreate: ApiFunc<`${typeof API.CALENDAR_EVENT}`, 'post'> = data =>
  instance.post(API.CALENDAR_EVENT, data)

export const calendarEventDelete: ApiFunc<`${typeof API.CALENDAR_EVENT}{id}`, 'delete'> = data =>
  instance.delete(`${API.CALENDAR_EVENT}${'id' in data ? prop('id', data) : data.name}`)

export const calendarEventList: ApiFunc<`${typeof API.CALENDAR_EVENT}`, 'get'> = data =>
  instance.get(API.CALENDAR_EVENT, {
    params: data,
  })

export const calendarEventListStudent: ApiFunc<`${typeof API.CALENDAR_EVENT_STUDENT}`, 'get'> = data =>
  instance.get(API.CALENDAR_EVENT_STUDENT, {
    params: data,
  })

export const calendarEventAll: ApiFunc<`${typeof API.CALENDAR_EVENT_ALL}`, 'get'> = data =>
  instance.get(API.CALENDAR_EVENT_ALL, {
    params: data,
  })
