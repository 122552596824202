import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_LIST,
  FEEDBACK_STATUS,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const Selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const feedbackAllSelector = createSelector(Selectors, prop(FEEDBACK_ALL))

export const feedbackCreateSelector = createSelector(Selectors, prop(FEEDBACK_CREATE))

export const feedbackEditSelector = createSelector(Selectors, prop(FEEDBACK_EDIT))

export const feedbackListSelector = createSelector(Selectors, prop(FEEDBACK_LIST))

export const feedbackStatusSelector = createSelector(Selectors, prop(FEEDBACK_STATUS))

export const feedbackTypesSelector = createSelector(Selectors, prop(FEEDBACK_TYPES))

export const feedbackUpdateSelector = createSelector(Selectors, prop(FEEDBACK_UPDATE))
