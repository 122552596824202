export const GLOBAL_STATE = 'AchievementRecords'

export const ACHIEVEMENT_RECORDS = 'ACHIEVEMENT_RECORDS' as const
export const ACHIEVEMENT_RECORDS_ALL = 'ACHIEVEMENT_RECORDS_ALL' as const
export const ACHIEVEMENT_RECORDS_TYPES = 'ACHIEVEMENT_RECORDS_TYPES' as const
export const ACHIEVEMENT_RECORDS_CHANGE_STATUS = 'ACHIEVEMENT_RECORDS_CHANGE_STATUS' as const
export const ACHIEVEMENT_RECORDS_STUDENT = 'ACHIEVEMENT_RECORDS_STUDENT' as const
export const ACHIEVEMENT_RECORDS_LIST = 'ACHIEVEMENT_RECORDS_LIST' as const
export const ACHIEVEMENT_RECORDS_CREATE = 'ACHIEVEMENT_RECORDS_CREATE' as const
export const ACHIEVEMENT_RECORDS_DELETE = 'ACHIEVEMENT_RECORDS_DELETE' as const
