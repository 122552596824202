import { DefaultState } from '@src/redux/types'

import {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_ALL,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_DELETE,
  ACADEMIC_YEAR_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ACADEMIC_YEAR]: DefaultState<`${typeof API.ACADEMIC_YEAR}{id}`, 'get'>
  [ACADEMIC_YEAR_ALL]: DefaultState<`${typeof API.ACADEMIC_YEAR_ALL}`, 'get'>
  [ACADEMIC_YEAR_CREATE]: DefaultState<`${typeof API.ACADEMIC_YEAR}`, 'post'>
  [ACADEMIC_YEAR_DELETE]: DefaultState<`${typeof API.ACADEMIC_YEAR}{id}`, 'delete'>
  [ACADEMIC_YEAR_LIST]: DefaultState<`${typeof API.ACADEMIC_YEAR}`, 'get'>
} = () => ({
  [ACADEMIC_YEAR]: defaultState(),
  [ACADEMIC_YEAR_ALL]: defaultState(),
  [ACADEMIC_YEAR_CREATE]: defaultState(),
  [ACADEMIC_YEAR_DELETE]: defaultState(),
  [ACADEMIC_YEAR_LIST]: defaultState(),
})
