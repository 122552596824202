import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  GUEST,
  GUEST_ACCEPT_SEND_CONTRACT,
  GUEST_ALL,
  GUEST_APPOINTMENT_SCORE,
  GUEST_AVAILABILITY_DOCUMENTS,
  GUEST_AVAILABILITY_DOCUMENTS_UPDATE,
  GUEST_CHANGE_EDU_LANG,
  GUEST_CHANGE_STATUS,
  GUEST_CHANGE_STATUS_TRANSCRIPT,
  GUEST_CREATE_INVOICE,
  GUEST_CREATE_REFERENCE,
  GUEST_DELETE,
  GUEST_LIST,
  GUEST_PROFILE,
  GUEST_PROFILE_UPDATE,
  GUEST_RESET_COURSE,
  GUEST_RESET_EXAM,
  GUEST_TEACHING_EXPERIENCE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const GUEST_USERS = GLOBAL_STATE

export const { reducer: guestsReducer } = createSlice({
  name: GUEST_USERS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.guest.pending, state => {
        state[GUEST].loading = true
      })
      .addCase(actionCreators.guest.rejected, (state, action) => {
        state[GUEST].loading = false
        state[GUEST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guest.fulfilled, (state, action) => {
        state[GUEST].loading = false
        state[GUEST].error = null
        state[GUEST].data = action.payload
      })

    builder
      .addCase(actionCreators.guestAll.pending, state => {
        state[GUEST_ALL].loading = true
      })
      .addCase(actionCreators.guestAll.rejected, (state, action) => {
        state[GUEST_ALL].loading = false
        state[GUEST_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestAll.fulfilled, (state, action) => {
        state[GUEST_ALL].loading = false
        state[GUEST_ALL].error = null
        state[GUEST_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.guestList.pending, state => {
        state[GUEST_LIST].loading = true
      })
      .addCase(actionCreators.guestList.rejected, (state, action) => {
        state[GUEST_LIST].loading = false
        state[GUEST_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestList.fulfilled, (state, action) => {
        state[GUEST_LIST].loading = false
        state[GUEST_LIST].error = null
        state[GUEST_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.guestProfile.pending, state => {
        state[GUEST_PROFILE].loading = true
      })
      .addCase(actionCreators.guestProfile.rejected, (state, action) => {
        state[GUEST_PROFILE].loading = false
        state[GUEST_PROFILE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestProfile.fulfilled, (state, action) => {
        state[GUEST_PROFILE].loading = false
        state[GUEST_PROFILE].error = null
        state[GUEST_PROFILE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestAvailabilityDocuments.pending, state => {
        state[GUEST_AVAILABILITY_DOCUMENTS].loading = true
      })
      .addCase(actionCreators.guestAvailabilityDocuments.rejected, (state, action) => {
        state[GUEST_AVAILABILITY_DOCUMENTS].loading = false
        state[GUEST_AVAILABILITY_DOCUMENTS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestAvailabilityDocuments.fulfilled, (state, action) => {
        state[GUEST_AVAILABILITY_DOCUMENTS].loading = false
        state[GUEST_AVAILABILITY_DOCUMENTS].error = null
        state[GUEST_AVAILABILITY_DOCUMENTS].data = action.payload
      })

    builder
      .addCase(actionCreators.guestProfileUpdate.pending, state => {
        state[GUEST_PROFILE_UPDATE].loading = true
      })
      .addCase(actionCreators.guestProfileUpdate.rejected, (state, action) => {
        state[GUEST_PROFILE_UPDATE].loading = false
        state[GUEST_PROFILE_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestProfileUpdate.fulfilled, (state, action) => {
        state[GUEST_PROFILE_UPDATE].loading = false
        state[GUEST_PROFILE_UPDATE].error = null
        state[GUEST_PROFILE_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestAvailabilityDocumentsUpdate.pending, state => {
        state[GUEST_AVAILABILITY_DOCUMENTS_UPDATE].loading = true
      })
      .addCase(actionCreators.guestAvailabilityDocumentsUpdate.rejected, (state, action) => {
        state[GUEST_AVAILABILITY_DOCUMENTS_UPDATE].loading = false
        state[GUEST_AVAILABILITY_DOCUMENTS_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestAvailabilityDocumentsUpdate.fulfilled, (state, action) => {
        state[GUEST_AVAILABILITY_DOCUMENTS_UPDATE].loading = false
        state[GUEST_AVAILABILITY_DOCUMENTS_UPDATE].error = null
        state[GUEST_AVAILABILITY_DOCUMENTS_UPDATE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestResetExam.pending, state => {
        state[GUEST_RESET_EXAM].loading = true
      })
      .addCase(actionCreators.guestResetExam.rejected, (state, action) => {
        state[GUEST_RESET_EXAM].loading = false
        state[GUEST_RESET_EXAM].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestResetExam.fulfilled, (state, action) => {
        state[GUEST_RESET_EXAM].loading = false
        state[GUEST_RESET_EXAM].error = null
        state[GUEST_RESET_EXAM].data = action.payload
      })

    builder
      .addCase(actionCreators.guestReferenceCreate.pending, state => {
        state[GUEST_CREATE_REFERENCE].loading = true
      })
      .addCase(actionCreators.guestReferenceCreate.rejected, (state, action) => {
        state[GUEST_CREATE_REFERENCE].loading = false
        state[GUEST_CREATE_REFERENCE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestReferenceCreate.fulfilled, (state, action) => {
        state[GUEST_CREATE_REFERENCE].loading = false
        state[GUEST_CREATE_REFERENCE].error = null
        state[GUEST_CREATE_REFERENCE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestInvoiceCreate.pending, state => {
        state[GUEST_CREATE_INVOICE].loading = true
      })
      .addCase(actionCreators.guestInvoiceCreate.rejected, (state, action) => {
        state[GUEST_CREATE_INVOICE].loading = false
        state[GUEST_CREATE_INVOICE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestInvoiceCreate.fulfilled, (state, action) => {
        state[GUEST_CREATE_INVOICE].loading = false
        state[GUEST_CREATE_INVOICE].error = null
        state[GUEST_CREATE_INVOICE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestResetCourse.pending, state => {
        state[GUEST_RESET_COURSE].loading = true
      })
      .addCase(actionCreators.guestResetCourse.rejected, (state, action) => {
        state[GUEST_RESET_COURSE].loading = false
        state[GUEST_RESET_COURSE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestResetCourse.fulfilled, (state, action) => {
        state[GUEST_RESET_COURSE].loading = false
        state[GUEST_RESET_COURSE].error = null
        state[GUEST_RESET_COURSE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestTeachingExperience.pending, state => {
        state[GUEST_TEACHING_EXPERIENCE].loading = true
      })
      .addCase(actionCreators.guestTeachingExperience.rejected, (state, action) => {
        state[GUEST_TEACHING_EXPERIENCE].loading = false
        state[GUEST_TEACHING_EXPERIENCE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestTeachingExperience.fulfilled, (state, action) => {
        state[GUEST_TEACHING_EXPERIENCE].loading = false
        state[GUEST_TEACHING_EXPERIENCE].error = null
        state[GUEST_TEACHING_EXPERIENCE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestAcceptSendContract.pending, state => {
        state[GUEST_ACCEPT_SEND_CONTRACT].loading = true
      })
      .addCase(actionCreators.guestAcceptSendContract.rejected, (state, action) => {
        state[GUEST_ACCEPT_SEND_CONTRACT].loading = false
        state[GUEST_ACCEPT_SEND_CONTRACT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestAcceptSendContract.fulfilled, (state, action) => {
        state[GUEST_ACCEPT_SEND_CONTRACT].loading = false
        state[GUEST_ACCEPT_SEND_CONTRACT].error = null
        state[GUEST_ACCEPT_SEND_CONTRACT].data = action.payload
      })

    builder
      .addCase(actionCreators.guestChangeEduLang.pending, state => {
        state[GUEST_CHANGE_EDU_LANG].loading = true
      })
      .addCase(actionCreators.guestChangeEduLang.rejected, (state, action) => {
        state[GUEST_CHANGE_EDU_LANG].loading = false
        state[GUEST_CHANGE_EDU_LANG].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestChangeEduLang.fulfilled, (state, action) => {
        state[GUEST_CHANGE_EDU_LANG].loading = false
        state[GUEST_CHANGE_EDU_LANG].error = null
        state[GUEST_CHANGE_EDU_LANG].data = action.payload
      })

    builder
      .addCase(actionCreators.guestChangeStatus.pending, state => {
        state[GUEST_CHANGE_STATUS].loading = true
      })
      .addCase(actionCreators.guestChangeStatus.rejected, (state, action) => {
        state[GUEST_CHANGE_STATUS].loading = false
        state[GUEST_CHANGE_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestChangeStatus.fulfilled, (state, action) => {
        state[GUEST_CHANGE_STATUS].loading = false
        state[GUEST_CHANGE_STATUS].error = null
        state[GUEST_CHANGE_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.guestChangeStatusTranscript.pending, state => {
        state[GUEST_CHANGE_STATUS_TRANSCRIPT].loading = true
      })
      .addCase(actionCreators.guestChangeStatusTranscript.rejected, (state, action) => {
        state[GUEST_CHANGE_STATUS_TRANSCRIPT].loading = false
        state[GUEST_CHANGE_STATUS_TRANSCRIPT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestChangeStatusTranscript.fulfilled, (state, action) => {
        state[GUEST_CHANGE_STATUS_TRANSCRIPT].loading = false
        state[GUEST_CHANGE_STATUS_TRANSCRIPT].error = null
        state[GUEST_CHANGE_STATUS_TRANSCRIPT].data = action.payload
      })

    builder
      .addCase(actionCreators.guestAppointmentScore.pending, state => {
        state[GUEST_APPOINTMENT_SCORE].loading = true
      })
      .addCase(actionCreators.guestAppointmentScore.rejected, (state, action) => {
        state[GUEST_APPOINTMENT_SCORE].loading = false
        state[GUEST_APPOINTMENT_SCORE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestAppointmentScore.fulfilled, (state, action) => {
        state[GUEST_APPOINTMENT_SCORE].loading = false
        state[GUEST_APPOINTMENT_SCORE].error = null
        state[GUEST_APPOINTMENT_SCORE].data = action.payload
      })

    builder
      .addCase(actionCreators.guestDelete.pending, state => {
        state[GUEST_DELETE].loading = true
      })
      .addCase(actionCreators.guestDelete.rejected, (state, action) => {
        state[GUEST_DELETE].loading = false
        state[GUEST_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.guestDelete.fulfilled, (state, action) => {
        state[GUEST_DELETE].loading = false
        state[GUEST_DELETE].error = null
        state[GUEST_DELETE].data = action.payload
      })

    builder.addCase(actionCreators.guestClear, state => {
      state[GUEST] = defaultState()
    })

    builder.addCase(actionCreators.guestAllClear, state => {
      state[GUEST_ALL] = defaultState()
    })

    builder.addCase(actionCreators.guestListClear, state => {
      state[GUEST_LIST] = defaultState()
    })

    builder.addCase(actionCreators.guestProfileClear, state => {
      state[GUEST_PROFILE] = defaultState()
    })
  },
})
