export const GLOBAL_STATE = 'Assessment'

// export const ASSESSMENT = 'ASSESSMENT' as const
export const ASSESSMENT_CREATE = 'ASSESSMENT_CREATE' as const
// export const ASSESSMENT_DELETE = 'ASSESSMENT_DELETE' as const
export const ASSESSMENT_LIST = 'ASSESSMENT_LIST' as const
export const ASSESSMENT_BULK = 'ASSESSMENT_BULK' as const
export const ASSESSMENT_RETAKE = 'ASSESSMENT_RETAKE' as const
export const ASSESSMENT_RETAKE_DELETE = 'ASSESSMENT_RETAKE_DELETE' as const
export const ASSESSMENT_ATTEMPT_CREATE = 'ASSESSMENT_ATTEMPT_CREATE' as const
export const ASSESSMENT_ATTEMPT_ITEM = 'ASSESSMENT_ATTEMPT_ITEM' as const
// export const ASSESSMENT_ATTEMPT_DELETE = 'ASSESSMENT_ATTEMPT_DELETE' as const
export const ASSESSMENT_SUBMIT = 'ASSESSMENT_SUBMIT' as const
export const ASSESSMENT_COPY = 'ASSESSMENT_COPY' as const
// export const ASSESSMENT_CRITERIA = 'ASSESSMENT_CRITERIA' as const
export const ASSESSMENT_CRITERIA_UPDATE = 'ASSESSMENT_CRITERIA_UPDATE' as const
export const ASSESSMENT_CRITERIA_LIST = 'ASSESSMENT_CRITERIA_LIST' as const
export const ATTEMPT_DELETE = 'ATTEMPT_DELETE' as const
export const ASSESSMENT_CRITERIA_TYPES = 'ASSESSMENT_CRITERIA_TYPES' as const
export const ASSESSMENT_STUDENT_UPLOAD = 'ASSESSMENT_STUDENT_UPLOAD' as const
export const ASSESSMENT_STUDENT = 'ASSESSMENT_STUDENT' as const
export const ASSESSMENT_STUDENT_UPDATED = 'ASSESSMENT_STUDENT_UPDATED' as const
export const ASSESSMENT_COMPONENT_CREATE = 'ASSESSMENT_COMPONENT_CREATE' as const
export const ASSESSMENT_COMPONENT_DELETE = 'ASSESSMENT_COMPONENT_DELETE' as const
export const ASSESSMENT_STUDENT_CRITERIA_DETAILS = 'ASSESSMENT_STUDENT_CRITERIA_DETAILS' as const
export const ASSESSMENT_OVERALL_GRADE_HISTORY = 'ASSESSMENT_OVERALL_GRADE_HISTORY' as const
