import { DefaultState } from '@src/redux/types'

import {
  DEPARTMENT,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_LABELS,
  DEPARTMENT_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [DEPARTMENT]: DefaultState<`${typeof API.DEPARTMENT}{id}`, 'get'>
  [DEPARTMENT_ALL]: DefaultState<`${typeof API.DEPARTMENT_ALL}`, 'get'>
  [DEPARTMENT_CREATE]: DefaultState<`${typeof API.DEPARTMENT}`, 'post'>
  [DEPARTMENT_DELETE]: DefaultState<`${typeof API.DEPARTMENT}{id}`, 'delete'>
  [DEPARTMENT_LABELS]: DefaultState<`${typeof API.DEPARTMENT_LABELS}`, 'get'>
  [DEPARTMENT_LIST]: DefaultState<`${typeof API.DEPARTMENT}`, 'get'>
} = () => ({
  [DEPARTMENT]: defaultState(),
  [DEPARTMENT_ALL]: defaultState(),
  [DEPARTMENT_CREATE]: defaultState(),
  [DEPARTMENT_DELETE]: defaultState(),
  [DEPARTMENT_LABELS]: defaultState(),
  [DEPARTMENT_LIST]: defaultState(),
})
