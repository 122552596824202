import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, MODULES, MODULES_ALL, MODULES_LIST } from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const modulesSelector = createSelector(selectors, prop(MODULES))

export const modulesListSelector = createSelector(selectors, prop(MODULES_LIST))

export const modulesAllSelector = createSelector(selectors, prop(MODULES_ALL))
