import { skinSelector } from '@store/selector'
import { selectThemeColors } from '@utils'
import classNames from 'classnames'
import { dissoc, path } from 'ramda'
import { CSSProperties, MouseEvent, ReactNode, forwardRef, useRef } from 'react'
import { Trash } from 'react-feather'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { GroupBase, OptionProps, SelectInstance, components as reactSelectComponents } from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import { Button, FormGroup, FormText, Label } from 'reactstrap'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    handleDeleteOption?: (
      e: MouseEvent<HTMLButtonElement>,
      option: Option,
      selectProps: OptionProps<Option, IsMulti, Group>['selectProps']
    ) => void
  }
}

// const CustomControl = ({ children, innerRef, ...props }) => {
//   function appendOption(e) {
//     !isEmpty(prop('options', props)) && props.selectProps.handleCreateOption(path(['selectProps', 'inputValue'], props))
//   }

//   return (
//     <div>
//       <reactSelectComponents.Control {...props}>
//         {path(['selectProps', 'inputValue'], props) ? (
//           <Button
//             color='flat-primary'
//             onClick={appendOption}
//             style={{
//               padding: '0.5rem 0.8rem 0.5rem 0.8rem',
//               borderTopRightRadius: '0',
//               borderBottomRightRadius: '0',
//               borderTopLeftRadius: '0.2rem',
//               borderBottomLeftRadius: '0.2rem',
//             }}
//           >
//             <Check size={16} />
//           </Button>
//         ) : null}
//         {children}
//       </reactSelectComponents.Control>
//     </div>
//   )
// }

type CustomOptionProps<Option, Group extends GroupBase<Option>, IsMulti extends boolean = true> = OptionProps<
  Option,
  IsMulti,
  Group
> & {
  // selectProps: OptionProps<Option, IsMulti, Group>['selectProps'] & {
  //   handleDeleteOption?: (
  //     e: MouseEvent<HTMLButtonElement>,
  //     option: Option,
  //     selectProps: OptionProps<Option, IsMulti, Group>['selectProps']
  //   ) => void
  // }
}

function CustomOption<A extends IOption, M extends boolean = true>(props: CustomOptionProps<A, GroupBase<A>, M>) {
  const isDeletable = path(['data', 'deletable'], props)

  return (
    <reactSelectComponents.Option {...props} innerRef={props.innerRef}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{props.children}</span>
        {isDeletable ? (
          <Button
            onClick={
              props.selectProps.handleDeleteOption
                ? e => {
                    props.selectProps.handleDeleteOption &&
                      props.selectProps.handleDeleteOption(e, props.data, props.selectProps)
                  }
                : undefined
            }
            color='flat-danger'
            style={{
              padding: '0.5rem',
            }}
          >
            <Trash size={16} />
          </Button>
        ) : null}
      </div>
    </reactSelectComponents.Option>
  )
}

const components = {
  Option: CustomOption,
  // Control: CustomControl,
}

export interface IOption {
  readonly value: string | number | boolean
  readonly label: string
  readonly [key: string]: unknown
}

type SelectProps<Option, Group extends GroupBase<Option>, IsMulti extends boolean = true> = Omit<
  CreatableProps<Option, IsMulti, Group>,
  'isMulti'
> & {
  label?: ReactNode
  disabled?: boolean
  className?: string
  menuZIndex?: CSSProperties['zIndex']
  error?: FieldError
  isMulti?: IsMulti
  formGroupStyles?: CSSProperties
  handleDeleteOption?: (
    e: MouseEvent<HTMLButtonElement>,
    option: Option,
    selectProps: OptionProps<Option, IsMulti, Group>['selectProps']
  ) => void
  handleCreateOption: (prop: { name?: string; value: string }) => void
}

function CreatableSelectField<A extends IOption, M extends boolean = true>(props: SelectProps<A, GroupBase<A>, M>) {
  const {
    label,
    disabled,
    className,
    name,
    isMulti = true,
    isLoading,
    menuZIndex = '10',
    error,
    value,
    handleCreateOption,
    isClearable,
    id,
    placeholder = 'Select or Type to create',
    formGroupStyles = {},
  } = props

  const selectRef = useRef<SelectInstance<A, M, GroupBase<A>>>(null)
  const skin = useSelector(skinSelector)

  function createOption(inputValue: string) {
    if (!inputValue) return
    handleCreateOption && handleCreateOption({ value: inputValue, name })
  }

  function closeMenu() {
    selectRef.current?.blur()
  }

  function openMenu() {
    selectRef.current?.focus()
  }

  const htmlFor = id ? { for: id } : {}
  const htmlId = id ? { id } : {}

  return (
    <FormGroup style={formGroupStyles}>
      {label && <Label {...htmlFor}>{label}</Label>}
      <CreatableSelect
        {...(dissoc('menuIsOpen', props) as Omit<typeof props, 'menuIsOpen'>)}
        name={name}
        placeholder={placeholder}
        components={components}
        styles={{
          control: provided => {
            return {
              ...(provided as { [key: string]: string }),
              alignItems: 'stretch',
            }
          },
          menu: provided => ({
            ...(provided as { [key: string]: string }),
            zIndex: menuZIndex,
          }),
          input: provided => {
            return {
              ...(provided as { [key: string]: string }),
              color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b',
            }
          },
        }}
        isClearable={isClearable}
        isMulti={isMulti as M}
        value={value}
        onCreateOption={createOption}
        classNamePrefix='select'
        menuShouldScrollIntoView={false}
        isLoading={isLoading}
        className={classNames(
          error ? `react-select-error is-invalid react-select ${className}` : `react-select ${className}`
        )}
        ref={selectRef}
        isDisabled={disabled}
        onMenuClose={closeMenu}
        onMenuOpen={openMenu}
        {...htmlId}
        theme={selectThemeColors}
      />
      {error && (
        <FormText color='danger' style={{ paddingLeft: '15px' }}>
          <FormattedMessage id='form_required' />
        </FormText>
      )}
    </FormGroup>
  )
}

const ForwardedCreatableSelect = forwardRef(CreatableSelectField)

export default ForwardedCreatableSelect
