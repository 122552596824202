import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  FORM,
  FORM_ACTION_TYPE,
  FORM_ALL,
  FORM_CREATE,
  FORM_DELETE,
  FORM_FIELD_BATCH,
  FORM_FIELD_BATCH_CREATE,
  FORM_FIELD_CREATE,
  FORM_LIST,
  FORM_REQUEST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CERTIFICATE,
  FORM_REQUEST_CHANGE_STATUS,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_DELETE,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_LIST,
  FORM_TYPE,
  FORM_TYPE_ALL,
  FORM_TYPE_CREATE,
  FORM_TYPE_DELETE,
  FORM_TYPE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const form = createAsyncThunk(
  FORM,
  async (params: PayloadCreatorParams<`${typeof API.FORM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.form(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formCreate = createAsyncThunk(
  FORM_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.FORM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formDelete = createAsyncThunk(
  FORM_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.FORM}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.formDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formList = createAsyncThunk(
  FORM_LIST,
  async (params: PayloadCreatorParams<`${typeof API.FORM}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formAll = createAsyncThunk(
  FORM_ALL,
  async (params: PayloadCreatorParams<`${typeof API.FORM_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formActionType = createAsyncThunk(
  FORM_ACTION_TYPE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_ACTION_TYPE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formActionType(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formFieldCreate = createAsyncThunk(
  FORM_FIELD_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_FIELD}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formFieldCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formFieldBatch = createAsyncThunk(
  FORM_FIELD_BATCH,
  async (params: PayloadCreatorParams<`${typeof API.FORM_FIELD_BATCH}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formFieldBatch(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formFieldBatchCreate = createAsyncThunk(
  FORM_FIELD_BATCH_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_FIELD_BATCH}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formFieldBatchCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formType = createAsyncThunk(
  FORM_TYPE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_TYPE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formType(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formTypeCreate = createAsyncThunk(
  FORM_TYPE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_TYPE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formTypeCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formTypeDelete = createAsyncThunk(
  FORM_TYPE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_TYPE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.formTypeDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formTypeList = createAsyncThunk(
  FORM_TYPE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.FORM_TYPE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formTypeList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formTypeAll = createAsyncThunk(
  FORM_TYPE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.FORM_TYPE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formTypeAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequest = createAsyncThunk(
  FORM_REQUEST,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequest(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestCreate = createAsyncThunk(
  FORM_REQUEST_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestDelete = createAsyncThunk(
  FORM_REQUEST_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestList = createAsyncThunk(
  FORM_REQUEST_LIST,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestAll = createAsyncThunk(
  FORM_REQUEST_ALL,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestFields = createAsyncThunk(
  FORM_REQUEST_FIELDS,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST_FIELDS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestFields(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestChangeStatus = createAsyncThunk(
  FORM_REQUEST_CHANGE_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST_CHANGE_STATUS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestChangeStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formRequestCertificate = createAsyncThunk(
  FORM_REQUEST_CERTIFICATE,
  async (params: PayloadCreatorParams<`${typeof API.FORM_REQUEST_CERTIFICATE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.formRequestCertificate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const formClear = createAction(`${FORM}/clear`)

export const formListClear = createAction(`${FORM_LIST}/clear`)

export const formAllClear = createAction(`${FORM_ALL}/clear`)

export const formFieldBatchClear = createAction(`${FORM_FIELD_BATCH}/clear`)

export const formTypeListClear = createAction(`${FORM_TYPE_LIST}/clear`)

export const formTypeAllClear = createAction(`${FORM_TYPE_ALL}/clear`)

export const formRequestClear = createAction(`${FORM_REQUEST}/clear`)

export const formRequestListClear = createAction(`${FORM_REQUEST_LIST}/clear`)

export const formRequestAllClear = createAction(`${FORM_REQUEST_ALL}/clear`)

export const formRequestFieldsClear = createAction(`${FORM_REQUEST_FIELDS}/clear`)
