import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const moduleFile = createAsyncThunk(
  MODULE_FILE,
  async (params: PayloadCreatorParams<`${typeof API.MODULE_FILE}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.moduleFile(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const moduleFileCreate = createAsyncThunk(
  MODULE_FILE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.MODULE_FILE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.moduleFileCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const moduleFileDelete = createAsyncThunk(
  MODULE_FILE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.MODULE_FILE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.moduleFileDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const moduleFileAll = createAsyncThunk(
  MODULE_FILE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.MODULE_FILE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.moduleFileAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const moduleFileList = createAsyncThunk(
  MODULE_FILE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.MODULE_FILE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.moduleFileList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const moduleFileAllClear = createAction(`${MODULE_FILE_ALL}/clear`)

export const moduleFileListClear = createAction(`${MODULE_FILE_LIST}/clear`)
