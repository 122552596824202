import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_DELETE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_AMENDMENT_PUBLISH,
  ASSESSMENT_MARK_STATUS,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ASSESSMENT_MARK = GLOBAL_STATE

export const { reducer: assessmentMarkReducer } = createSlice({
  name: ASSESSMENT_MARK,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.assessmentAmendment.pending, state => {
        state[ASSESSMENT_AMENDMENT].loading = true
      })
      .addCase(actionCreators.assessmentAmendment.rejected, (state, action) => {
        state[ASSESSMENT_AMENDMENT].loading = false
        state[ASSESSMENT_AMENDMENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAmendment.fulfilled, (state, action) => {
        state[ASSESSMENT_AMENDMENT].loading = false
        state[ASSESSMENT_AMENDMENT].error = null
        state[ASSESSMENT_AMENDMENT].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentAmendmentAll.pending, state => {
        state[ASSESSMENT_AMENDMENT_ALL].loading = true
      })
      .addCase(actionCreators.assessmentAmendmentAll.rejected, (state, action) => {
        state[ASSESSMENT_AMENDMENT_ALL].loading = false
        state[ASSESSMENT_AMENDMENT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAmendmentAll.fulfilled, (state, action) => {
        state[ASSESSMENT_AMENDMENT_ALL].loading = false
        state[ASSESSMENT_AMENDMENT_ALL].error = null
        state[ASSESSMENT_AMENDMENT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentAmendmentCreate.pending, state => {
        state[ASSESSMENT_AMENDMENT_CREATE].loading = true
      })
      .addCase(actionCreators.assessmentAmendmentCreate.rejected, (state, action) => {
        state[ASSESSMENT_AMENDMENT_CREATE].loading = false
        state[ASSESSMENT_AMENDMENT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAmendmentCreate.fulfilled, (state, action) => {
        state[ASSESSMENT_AMENDMENT_CREATE].loading = false
        state[ASSESSMENT_AMENDMENT_CREATE].error = null
        state[ASSESSMENT_AMENDMENT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentAmendmentDelete.pending, state => {
        state[ASSESSMENT_AMENDMENT_DELETE].loading = true
      })
      .addCase(actionCreators.assessmentAmendmentDelete.rejected, (state, action) => {
        state[ASSESSMENT_AMENDMENT_DELETE].loading = false
        state[ASSESSMENT_AMENDMENT_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAmendmentDelete.fulfilled, (state, action) => {
        state[ASSESSMENT_AMENDMENT_DELETE].loading = false
        state[ASSESSMENT_AMENDMENT_DELETE].error = null
        state[ASSESSMENT_AMENDMENT_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentAmendmentList.pending, state => {
        state[ASSESSMENT_AMENDMENT_LIST].loading = true
      })
      .addCase(actionCreators.assessmentAmendmentList.rejected, (state, action) => {
        state[ASSESSMENT_AMENDMENT_LIST].loading = false
        state[ASSESSMENT_AMENDMENT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAmendmentList.fulfilled, (state, action) => {
        state[ASSESSMENT_AMENDMENT_LIST].loading = false
        state[ASSESSMENT_AMENDMENT_LIST].error = null
        state[ASSESSMENT_AMENDMENT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentAmendmentPublish.pending, state => {
        state[ASSESSMENT_AMENDMENT_PUBLISH].loading = true
      })
      .addCase(actionCreators.assessmentAmendmentPublish.rejected, (state, action) => {
        state[ASSESSMENT_AMENDMENT_PUBLISH].loading = false
        state[ASSESSMENT_AMENDMENT_PUBLISH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentAmendmentPublish.fulfilled, (state, action) => {
        state[ASSESSMENT_AMENDMENT_PUBLISH].loading = false
        state[ASSESSMENT_AMENDMENT_PUBLISH].error = null
        state[ASSESSMENT_AMENDMENT_PUBLISH].data = action.payload
      })

    builder
      .addCase(actionCreators.assessmentMarkStatusChange.pending, state => {
        state[ASSESSMENT_MARK_STATUS].loading = true
      })
      .addCase(actionCreators.assessmentMarkStatusChange.rejected, (state, action) => {
        state[ASSESSMENT_MARK_STATUS].loading = false
        state[ASSESSMENT_MARK_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.assessmentMarkStatusChange.fulfilled, (state, action) => {
        state[ASSESSMENT_MARK_STATUS].loading = false
        state[ASSESSMENT_MARK_STATUS].error = null
        state[ASSESSMENT_MARK_STATUS].data = action.payload
      })

    builder.addCase(actionCreators.assessmentAmendmentClear, state => {
      state[ASSESSMENT_AMENDMENT] = defaultState()
    })

    builder.addCase(actionCreators.assessmentAmendmentAllClear, state => {
      state[ASSESSMENT_AMENDMENT_ALL] = defaultState()
    })

    builder.addCase(actionCreators.assessmentAmendmentListClear, state => {
      state[ASSESSMENT_AMENDMENT_LIST] = defaultState()
    })
  },
})
