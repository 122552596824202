const SHORT_TERM_COURSE = 'st-course'
export const ST_COURSE = `/${SHORT_TERM_COURSE}-admission`
export const ST_COURSE_MY_APPLICATIONS = `/guest-application/course`
export const ST_COURSE_APPLICATIONS = `/${SHORT_TERM_COURSE}-applications`
export const ST_COURSE_MY_APPLICATIONS_CREATE = `${ST_COURSE_MY_APPLICATIONS}/create`
export const ST_COURSE_APPLICATIONS_EDIT = `${ST_COURSE_APPLICATIONS}/edit`

export const ST_COURSE_ADMIN = `${ST_COURSE}/admin`
export const ST_COURSE_ADMIN_APPLICATION_FORM = `${ST_COURSE_ADMIN}/application-form`
export const ST_COURSE_ADMIN_APPLICATION_CREATE = `${ST_COURSE_ADMIN_APPLICATION_FORM}/create`
export const ST_COURSE_ADMIN_APPLICATION_CATEGORY = `${ST_COURSE_ADMIN}/category`
