import { DefaultState } from '@src/redux/types'

import {
  COURSE_TRANSFER,
  COURSE_TRANSFER_COURSES,
  COURSE_TRANSFER_INFO,
  COURSE_TRANSFER_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [COURSE_TRANSFER]: DefaultState<`${typeof API.COURSE_TRANSFER}`, 'post'>
  [COURSE_TRANSFER_LIST]: DefaultState<`${typeof API.COURSE_TRANSFER}`, 'get'>
  [COURSE_TRANSFER_COURSES]: DefaultState<`${typeof API.COURSE_TRANSFER_COURSES}`, 'get'>
  [COURSE_TRANSFER_INFO]: DefaultState<`${typeof API.COURSE_TRANSFER_INFO}`, 'get'>
} = () => ({
  [COURSE_TRANSFER]: defaultState(),
  [COURSE_TRANSFER_LIST]: defaultState(),
  [COURSE_TRANSFER_COURSES]: defaultState(),
  [COURSE_TRANSFER_INFO]: defaultState(),
})
