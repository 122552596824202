import { DefaultState } from '@src/redux/types'

import {
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_DELETE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_DELETE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_CREATE,
  STC_STEP_DELETE,
  STC_STEP_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [STC]: DefaultState<`${typeof API.STC}{id}`, 'get'>
  [STC_ALL]: DefaultState<`${typeof API.STC_ALL}`, 'get'>
  [STC_CREATE]: DefaultState<`${typeof API.STC}`, 'post'>
  [STC_DELETE]: DefaultState<`${typeof API.STC}{id}`, 'delete'>
  [STC_LIST]: DefaultState<`${typeof API.STC}`, 'get'>
  //category
  [STC_CATEGORY]: DefaultState<`${typeof API.STC_CATEGORY}{id}`, 'get'>
  [STC_CATEGORY_ALL]: DefaultState<`${typeof API.STC_CATEGORY_ALL}`, 'get'>
  [STC_CATEGORY_CREATE]: DefaultState<`${typeof API.STC_CATEGORY}`, 'post'>
  [STC_CATEGORY_DELETE]: DefaultState<`${typeof API.STC_CATEGORY}{id}`, 'delete'>
  [STC_CATEGORY_LIST]: DefaultState<`${typeof API.STC_CATEGORY}`, 'get'>
  //step
  [STC_STEP]: DefaultState<`${typeof API.STC_STEP}{id}`, 'get'>
  [STC_STEP_ALL]: DefaultState<`${typeof API.STC_STEP_ALL}`, 'get'>
  [STC_STEP_CREATE]: DefaultState<`${typeof API.STC_STEP}`, 'post'>
  [STC_STEP_DELETE]: DefaultState<`${typeof API.STC_STEP}{id}`, 'delete'>
  [STC_STEP_LIST]: DefaultState<`${typeof API.STC_STEP}`, 'get'>
} = () => ({
  [STC]: defaultState(),
  [STC_ALL]: defaultState(),
  [STC_CREATE]: defaultState(),
  [STC_DELETE]: defaultState(),
  [STC_LIST]: defaultState(),
  //category
  [STC_CATEGORY]: defaultState(),
  [STC_CATEGORY_ALL]: defaultState(),
  [STC_CATEGORY_CREATE]: defaultState(),
  [STC_CATEGORY_DELETE]: defaultState(),
  [STC_CATEGORY_LIST]: defaultState(),
  //step
  [STC_STEP]: defaultState(),
  [STC_STEP_ALL]: defaultState(),
  [STC_STEP_CREATE]: defaultState(),
  [STC_STEP_DELETE]: defaultState(),
  [STC_STEP_LIST]: defaultState(),
})
