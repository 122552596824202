import {
  ADMIN,
  ADMIN_VIEWER,
  MODERATOR_VIEWER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_OFFICER,
  SUPER_ADMIN,
} from '@constants/userRoles'
import { lazy } from 'react'

import { ACADEMIC_YEARS } from '../constants/route'

export const AcademicYearsRoutes = [
  {
    path: ACADEMIC_YEARS,
    component: lazy(() => import('../views/AcademicYears')),
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN_VIEWER,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        MODERATOR_VIEWER,
        REGISTRAR_OFFICER,
        ADMIN,
      ],
    },
  },
]
