import { ApiFunc, getInstance } from '@helpers/httpClient'
import { flatten, join, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const extenuatingCircumstancesList: ApiFunc<`${typeof API.ASSESSMENT_EC}`, 'get'> = data => {
  let key: keyof typeof data
  const adjustedObj: Record<typeof key | string, string | never> = {}

  for (key in data) {
    if (Array.isArray(data[key])) {
      adjustedObj[key] = join(',', flatten([data[key]]))
    }
  }
  // const adjustedObj = {}
  // for (const key in data) {
  //   if (data[key]) {
  //     if (equals(type(data[key], 'Array'))) {
  //       adjustedObj[key] = join(',', flatten([data[key]]))
  //     }
  //   }
  // }
  return instance.get(API.ASSESSMENT_EC, {
    params: adjustedObj,
  })
}

export const extenuatingCircumstancesPaper: ApiFunc<`${typeof API.ASSESSMENT_EC_PAPER}`, 'post'> = data => {
  return instance.post(API.ASSESSMENT_EC_PAPER, data)
}

export const extenuatingCircumstancesUpdate: ApiFunc<`${typeof API.ASSESSMENT_EC_UPDATE}`, 'post'> = data => {
  return instance.post(API.ASSESSMENT_EC_UPDATE, data)
}

export const extenuatingCircumstancesOutcomeReport: ApiFunc<
  `${typeof API.ASSESSMENT_EC_OUTCOME_REPORT}`,
  'post'
> = data => {
  return instance.post(API.ASSESSMENT_EC_OUTCOME_REPORT, data)
}

export const extenuatingCircumstancesChangeStatus: ApiFunc<`${typeof API.ASSESSMENT_EC_CHANGE_STATUS}`, 'put'> = data =>
  instance.put(API.ASSESSMENT_EC_CHANGE_STATUS, data)

export const extenuatingCircumstancesCreate: ApiFunc<`${typeof API.ASSESSMENT_EC}`, 'post'> = data =>
  instance.post(API.ASSESSMENT_EC, data)

export const extenuatingCircumstancesItem: ApiFunc<`${typeof API.ASSESSMENT_EC}{id}`, 'get'> = data =>
  instance.get(`${API.ASSESSMENT_EC}${'id' in data ? prop('id', data) : data.name}`)

export const extenuatingCircumstancesAll: ApiFunc<`${typeof API.ASSESSMENT_EC_ALL}`, 'get'> = data =>
  instance.get(API.ASSESSMENT_EC_ALL, {
    params: data,
  })
export const extenuatingCircumstancesDelete: ApiFunc<`${typeof API.ASSESSMENT_EC}{id}`, 'delete'> = data =>
  instance.delete(`${API.ASSESSMENT_EC}${'id' in data ? prop('id', data) : data.name}`)
