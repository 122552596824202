import FileTypeIcon from '@components/FileTypeIcon'
import VideoModal from '@components/Modals/VideoModal'
import { API_URL, UPLOAD_API } from '@constants/api'
import { FILE_UPLOAD_REACT } from '@constants/fileUpload'
import { getToken } from '@helpers/storageToken'
import { useSignal } from '@hooks'
import { endpoint } from '@src/helpers/httpClient'
import { definitions } from '@src/types/schema'
import axios, { AxiosResponse } from 'axios'
import classNames from 'classnames'
import { equals, forEach, includes, isEmpty, last, pipe, prop, propEq, propOr, split, toLower } from 'ramda'
import {
  CSSProperties,
  ComponentPropsWithRef,
  ElementType,
  FocusEvent,
  Fragment,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Dropzone, { Accept, DropEvent } from 'react-dropzone'
import { Download, PlayCircle, X } from 'react-feather'
import { FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Card, Col, FormGroup, FormText, Label, Media, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'

import UILoader from '../ui-loader'

export type FileType = definitions['MediaDTO'] & Partial<File>

function formatBytes(bytes: number, decimals: number) {
  if (bytes === 0) return ''
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const StyledX = styled('span')`
  cursor: pointer;
  color: #6e6b7b;
  &:hover {
    color: var(--danger);
    opacity: 0.9;
  }
`

const StyledPlay = styled('span')`
  cursor: pointer;
  color: #6e6b7b;
  &:hover {
    color: var(--primary);
  }
`

type FilesProps = {
  files: FileType[] | null
  load?: boolean
  as?: ElementType
  wrapperStyles?: CSSProperties
  wrapperClassName?: string
  errorText?: string
  disabled?: boolean
  width?: string | number
  height?: string | number
  handleFileDelete?: (file: definitions['MediaDTO']) => void
  onDownload?: (file: definitions['MediaDTO']) => void
}

type FileUploadProps = Omit<ComponentPropsWithRef<'input'>, 'value' | 'accept' | 'onChange'> & {
  handleChange?: <T extends definitions['MediaDTO'] | definitions['FileDetailsInfo'] | File>(
    files: T[],
    event?: DropEvent
  ) => void
  label?: string
  error?: FieldError
  name: string
  value?: FileType[]
  allows?: string
  header?: ReactNode
  wrapperClassName?: string
  subHeader?: ReactNode
  externalLoading?: boolean
  single?: boolean
  accept?: Accept
  definite?: number
  customErrorMessage?: boolean
  formGroupStyle?: CSSProperties
  onDropAccepted?: <T extends File>(params: {
    files: T[]
    handleAcceptedFiles: (value: T[], event?: DropEvent) => void
    event: DropEvent
  }) => void
  uploadOnDrop?: boolean
  onChange: <T extends definitions['MediaDTO'] | definitions['FileDetailsInfo'] | File>(
    files: T[],
    event?: DropEvent
  ) => void
  onUnmount?: () => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

export function Files({
  files,
  load,
  as,
  wrapperStyles = {},
  wrapperClassName = 'col-12 col-md-6 col-lg-6',
  errorText,
  disabled,
  width,
  handleFileDelete,
  height,
  onDownload,
}: FilesProps) {
  const videoModal = useSignal<{ state: boolean; item: definitions['MediaDTO'] | null }>({ state: false, item: null })
  const Wrapper = as ? as : Col
  const fileElements = useMemo(
    () =>
      Array.isArray(files)
        ? files.map(item => {
            const name = propOr<string, FileType, string>(item.name || '', 'original_name', item)
            const size = propOr<0, typeof item, number>(propOr(0, 'size', item), 'file_size', item)
            // const mime_type = propOr('text/csv', 'mime_type', item)
            const url = prop('original_url', item)
            const extension = pipe(propOr('', 'extension'), toLower)(item) || last(split('.', item?.name || ''))
            const iconColor = equals(extension, 'pdf')
              ? 'var(--primary)'
              : includes(extension, ['doc', 'docx'])
              ? '#3788d8'
              : includes(extension, ['mp4'])
              ? 'var(--purple)'
              : includes(extension, ['ppt', 'pptx'])
              ? 'var(--orange)'
              : includes(extension, ['xls', 'xlsx'])
              ? 'var(--green)'
              : 'var(--gray-dark)'

            return (
              <Wrapper key={url || name} className={wrapperClassName} style={{ ...wrapperStyles }}>
                <Card className='p-1 border rounded mb-2' style={{ boxShadow: 'none', position: 'relative' }}>
                  <Media>
                    <div className='avatar-md font-weight-bold mr-1'>
                      <span
                        className='avatar-title rounded bg-soft-primary text-primary'
                        style={{ minWidth: '3.5rem', minHeight: '3.5rem' }}
                      >
                        {includes(extension, ['png', 'jpeg', 'jpg', 'bmp']) ? (
                          <Media left href='#'>
                            <Media object src={url} width={width} height={height} alt='Image' />
                          </Media>
                        ) : (
                          <a
                            href={url}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={() => {
                              onDownload && onDownload(item)
                            }}
                          >
                            <FileTypeIcon
                              color={iconColor}
                              type={extension}
                              width={width?.toString()}
                              height={height?.toString()}
                            />
                          </a>
                        )}
                      </span>
                    </div>

                    <Media body>
                      <a
                        role='button'
                        className='d-inline-block text-muted font-weight-bold'
                        href={url}
                        target='_blank'
                        onClick={() => {
                          onDownload && onDownload(item)
                        }}
                        rel='noopener noreferrer'
                        style={{ textOverflow: 'ellipsis', lineBreak: 'anywhere' }}
                      >
                        {name && name.length > 30 ? `${name?.slice(0, 30)}...` : name}
                      </a>
                      <div className='font-size-11'>{formatBytes(size, 0)}</div>
                    </Media>

                    {extension === 'mp4' && url ? (
                      <div style={{ alignSelf: 'center', margin: '0 12px' }}>
                        <StyledPlay
                          onClick={e => {
                            e.stopPropagation()
                            videoModal.value = {
                              state: true,
                              item,
                            }
                          }}
                        >
                          <PlayCircle size={22} />
                        </StyledPlay>
                      </div>
                    ) : url ? (
                      <a
                        href={url}
                        target='_blank'
                        rel='noreferrer'
                        style={{ display: 'block', alignSelf: 'center', margin: '0 12px' }}
                        onClick={() => {
                          onDownload && onDownload(item)
                        }}
                      >
                        <StyledPlay>
                          <Download size={22} />
                        </StyledPlay>
                      </a>
                    ) : null}
                  </Media>
                  {!disabled ? (
                    <div style={{ position: 'absolute', right: '6px', top: '6px' }}>
                      <StyledX onClick={handleFileDelete ? () => handleFileDelete(item) : undefined}>
                        <X size={16} />
                      </StyledX>
                    </div>
                  ) : null}
                </Card>
              </Wrapper>
            )
          })
        : null,
    [files?.length]
  )
  if (errorText) {
    return (
      <Row>
        <Col sm={12}>
          <div className='text-center mt-2 text-danger'>{errorText}</div>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row className='mt-1'>{fileElements}</Row>
      <VideoModal signal={videoModal} />
    </>
  )
}

const FileUploadField = forwardRef<HTMLInputElement, FileUploadProps>((props, ref) => {
  const {
    label,
    value,
    handleChange,
    onChange,
    name,
    maxLength,
    allows,
    disabled,
    error,
    header,
    subHeader,
    wrapperClassName,
    single,
    width = 48,
    formGroupStyle = {},
    required,
    accept,
    onDropAccepted,
    externalLoading = false,
    uploadOnDrop = true,
  } = props

  const allowedExtensions = (allows ? allows.split(',') : []).map(item => item.trim())
  const [files, setFiles] = useState<FileType[] | null>(null)

  const { formatMessage: msg } = useIntl()

  useEffect(() => {
    setFiles(value || [])
  }, [value])

  const [load, setLoad] = useState(externalLoading)
  const [errorText, setErrorText] = useState('')

  function handleAcceptedFiles(values: File[], event?: DropEvent) {
    const formData = new FormData()
    const filesList = Array.from(values)
    filesList.forEach(file => formData.append('files', file))

    let allFilesAreAllowed = true
    let allFilesAreProperSize = true
    if (allowedExtensions.length > 0) {
      forEach(item => {
        const name = item.name
        const size = item.size
        const index = name.lastIndexOf('.')
        if (index > -1) {
          const extension = name.slice(index + 1)
          if (!allowedExtensions.includes(extension)) {
            allFilesAreAllowed = false
          }
        }
        if (maxLength) {
          if (maxLength < size) {
            allFilesAreProperSize = false
          }
        }
      }, values)
    }

    if (!allFilesAreAllowed) {
      const errorMsg = msg({ id: 'only_formats_allowed' }).replace('#formats', allows || '')
      setErrorText(errorMsg)
      return
    }

    if (!allFilesAreProperSize) {
      const errorMsg = msg({ id: 'only_size_allowed' }).replace('#maxSize', formatBytes(maxLength || 0, 0))
      setErrorText(errorMsg)
      return
    }

    setErrorText('')
    setLoad(true)

    axios
      .post<FormData, AxiosResponse<endpoint[typeof UPLOAD_API]['post']['responses']['200']['schema']>>(
        `${API_URL}${FILE_UPLOAD_REACT}`,
        formData,
        {
          headers: { Authorization: `Bearer ${getToken()}`, institution: '1' },
        }
      )
      .then(response => {
        const data = response.data.data
        if (Array.isArray(files) && Array.isArray(data)) {
          onChange([...files, ...data], event)
          handleChange && handleChange([...files, ...data], event)
        }
        setLoad(false)
      })
      .catch(err => {
        setLoad(false)
        console.warn(err)
      })
  }

  function handleFileDelete(file: definitions['MediaDTO']) {
    const src = prop('src', file)
    if (Array.isArray(value)) {
      const newFiles = value.filter(item => !propEq('src', src)(item))
      onChange(newFiles)
      handleChange && handleChange(newFiles)
    }
  }

  function Upload() {
    if (single) {
      if (value && !isEmpty(value)) {
        return null
      }

      return (
        <div>
          <Dropzone
            multiple={!single}
            disabled={disabled}
            accept={accept}
            onDropAccepted={(acceptedFiles, event) => {
              if (!uploadOnDrop) {
                onChange(acceptedFiles)
                handleChange && handleChange(acceptedFiles, event)
              } else if (onDropAccepted) {
                onDropAccepted({ files: acceptedFiles, handleAcceptedFiles, event })
              } else {
                handleAcceptedFiles(acceptedFiles, event)
              }
            }}
          >
            {({ getRootProps, getInputProps, isFileDialogActive, isDragReject, isDragAccept }) => (
              <div
                className={classNames(error ? 'dropzone-error text-center' : 'dropzone text-center')}
                style={{
                  ...(isFileDialogActive ? { borderColor: 'var(--primary)' } : {}),
                  ...(isDragAccept
                    ? { border: '1px dashed var(--success)' }
                    : isDragReject
                    ? { border: '1px dashed var(--danger)' }
                    : {}),
                }}
              >
                <UILoader blocking={load || externalLoading}>
                  <div className='dz-message py-2 needsclick' {...getRootProps()}>
                    <input {...getInputProps()} name={name} readOnly={disabled} />
                    <i className='h3 text-muted uil-cloud-upload' />
                    <h5>{msg({ id: 'Drag files here or select' })}</h5>
                    {isDragReject ? (
                      <h5 className='text-danger'>{msg({ id: 'Please, select accepted file formats' })}</h5>
                    ) : isDragAccept ? (
                      <h5 className='text-success'>{msg({ id: 'Drag files here or select' })}</h5>
                    ) : header ? (
                      header
                    ) : (
                      <span className='text-muted font-13'>{msg({ id: 'files_drag' })}</span>
                    )}
                  </div>
                </UILoader>
              </div>
            )}
          </Dropzone>

          {error ? <FormText color='danger'>{'message' in error ? error.message : <>{error}</>}</FormText> : null}
        </div>
      )
    }

    return (
      <Fragment>
        <Dropzone
          multiple={!single}
          disabled={disabled}
          accept={accept}
          onDropAccepted={(acceptedFiles, event) => {
            if (!uploadOnDrop) {
              onChange(acceptedFiles)
              handleChange && handleChange(acceptedFiles, event)
            } else if (onDropAccepted) {
              onDropAccepted({ files: acceptedFiles, handleAcceptedFiles, event })
            } else {
              handleAcceptedFiles(acceptedFiles)
            }
          }}
        >
          {({ getRootProps, getInputProps, isFileDialogActive, isDragReject, isDragAccept }) => {
            return (
              <div
                className={classNames(error ? 'dropzone-error text-center' : 'dropzone text-center')}
                style={{
                  ...(isFileDialogActive ? { borderColor: 'var(--primary)' } : {}),
                  ...(isDragAccept
                    ? { border: '1px dashed var(--success)' }
                    : isDragReject
                    ? { border: '1px dashed var(--danger)' }
                    : {}),
                }}
              >
                <UILoader blocking={load || externalLoading}>
                  <div className='dz-message py-2 needsclick' {...getRootProps()}>
                    <input {...getInputProps()} name={name} readOnly={disabled} />
                    <i className='h3 text-muted uil-cloud-upload' />
                    <h5>{msg({ id: 'Drag files here or select' })}</h5>
                    {isDragReject ? (
                      <h5 className='text-danger'>Please, select accepted file formats</h5>
                    ) : isDragAccept ? (
                      <h5 className='text-success'>{msg({ id: 'Drag files here or select' })}</h5>
                    ) : header ? (
                      header
                    ) : (
                      <span className='text-muted font-13'>{msg({ id: 'files_drag' })}</span>
                    )}
                    {subHeader}
                  </div>
                </UILoader>
              </div>
            )
          }}
        </Dropzone>

        {error ? <FormText color='danger'>{'message' in error ? error.message : <>{error}</>}</FormText> : null}
      </Fragment>
    )
  }

  return (
    <FormGroup style={formGroupStyle}>
      {label && (
        <Label>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      {!disabled ? <Upload /> : null}

      <Files
        files={files}
        wrapperClassName={wrapperClassName}
        errorText={errorText}
        disabled={disabled}
        width={width}
        handleFileDelete={handleFileDelete}
      />
    </FormGroup>
  )
})

export default FileUploadField
