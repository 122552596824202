// ** React Imports
import '@assets/scss/style.scss'
// ** Ripple Button
import '@components/ripple-button'
// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner'
// ** Toast & ThemeColors Context
import ability from '@configs/acl/ability'
import { store } from '@store/store'
// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'
// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/themes/prism-tomorrow.css'
import { propOr } from 'ramda'
import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { AlertCircle } from 'react-feather'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// ** Redux Imports
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Alert } from 'reactstrap'

// ** Core styles
import '@core/assets/fonts/feather/iconfont.css'
import '@core/styles/core.scss'

import App from './App'
import GoogleTag from './components/GoogleTag'
import MetaPixel from './components/MetaPixel'
import { getEduForm, getSelectedEduForm } from './helpers/storageEduForm'
import { getInstitution, getInstitutionOption } from './helpers/storageInstitution'
import { getLanguage, getLanguageOption } from './helpers/storageLanguage'
import { createSignal } from './hooks/useSignal'
// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { AbilityContext } from './utility/context/Can'
// ** Intl
import { IntlProviderWrapper } from './utility/context/Internationalization'
import { ThemeContext } from './utility/context/ThemeColors'
import { UserSettingsProvider } from './utility/context/UserSettings'

export const institutionSignal = createSignal({
  institution_id: getInstitution(),
  institution: getInstitutionOption(),
  education_form: getSelectedEduForm(),
  education_form_id: getEduForm(),
  key: `institution_id=${getInstitution()},education_form_id=${getEduForm()}`,
})

export const localeSignal = createSignal({
  locale: getLanguage(),
  locale_option: getLanguageOption(),
})

const SentryWrapper = props => {
  const children = propOr(null, 'children', props)

  const errorFallback = (
    <Alert color='danger'>
      <div className='alert-body'>
        <AlertCircle size={15} /> <span className='ms-1'>An error has occurred.</span>
      </div>
    </Alert>
  )

  return (
    <ErrorBoundary
      fallback={errorFallback}
      onError={err => {
        console.log('ErrorBoundary', err)
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

const Wrapper = () => {
  // const { id: userId } = useValidUserInfo()

  // useEffect(() => {
  //   !userId && localStorage.clear()
  // }, [userId])

  return (
    <Provider store={store}>
      {process.env.NODE_ENV === 'production' ? (
        <>
          <GoogleTag />
          <MetaPixel />
        </>
      ) : null}
      <SentryWrapper>
        <IntlProviderWrapper signal={localeSignal}>
          <UserSettingsProvider signal={institutionSignal}>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <App />
                  <ToastContainer newestOnTop />
                </ThemeContext>
              </AbilityContext.Provider>
            </Suspense>
          </UserSettingsProvider>
        </IntlProviderWrapper>
      </SentryWrapper>
    </Provider>
  )
}

ReactDOM.render(<Wrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
