import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { STAFF, STAFF_ALL, STAFF_DELETE, STAFF_LIST, STAFF_USERNAME } from '../constants/actionTypes'
import * as API from '../constants/api'

export const staff = createAsyncThunk(
  STAFF,
  async (params: PayloadCreatorParams<`${typeof API.STAFF}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.staff(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const staffDelete = createAsyncThunk(
  STAFF_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STAFF}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.staffDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const staffList = createAsyncThunk(
  STAFF_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STAFF}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.staffList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const staffAll = createAsyncThunk(
  STAFF_ALL,
  async (params: PayloadCreatorParams<`${typeof API.STAFF_ALL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.staffAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const staffUsername = createAsyncThunk(
  STAFF_USERNAME,
  async (params: PayloadCreatorParams<`${typeof API.STAFF_USERNAME}{username}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.staffUsername(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const staffListClear = createAction(`${STAFF_LIST}/clear`)

export const staffAllClear = createAction(`${STAFF_ALL}/clear`)

export const staffUsernameClear = createAction(`${STAFF_USERNAME}/clear`)
