import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { PAYMENTS, PAYMENTS_CREATE, PAYMENTS_DELETE } from '../constants/actionTypes'
import * as API from '../constants/api'

export const paymentsList = createAsyncThunk(
  PAYMENTS,
  async (params: PayloadCreatorParams<`${typeof API.PAYMENTS}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.paymentsList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const paymentsCreate = createAsyncThunk(
  PAYMENTS_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.PAYMENTS}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.paymentsCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params)) //if get request
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const paymentsDelete = createAsyncThunk(
  PAYMENTS_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PAYMENTS}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.paymentsDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const paymentsListClear = createAction(`${PAYMENTS}/clear`)
