import { ApiFunc, getInstance } from '@helpers/httpClient'

import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_LIST,
  FEEDBACK_STATUS,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

const instance = getInstance()

export const feedbackAll: ApiFunc<`${typeof API.FEEDBACK_ALL}`, 'get'> = data =>
  instance.get(API.FEEDBACK_ALL, { params: data })

export const feedbackList: ApiFunc<`${typeof API.FEEDBACK}`, 'get'> = data =>
  instance.get(API.FEEDBACK, { params: data })

export const feedbackCreate: ApiFunc<`${typeof API.FEEDBACK}`, 'post'> = data => instance.post(API.FEEDBACK, data)

export const feedbackTypes: ApiFunc<`${typeof API.FEEDBACK_TYPES}`, 'get'> = data =>
  instance.get(API.FEEDBACK_TYPES, { params: data })

export const feedbackEdit: ApiFunc<`${typeof API.FEEDBACK_EDIT}`, 'get'> = data =>
  instance.get(API.FEEDBACK_EDIT, { params: data })

export const feedbackUpdate: ApiFunc<`${typeof API.FEEDBACK_UPDATE}`, 'put'> = data =>
  instance.put(API.FEEDBACK_UPDATE, data)

export const feedbackStatus: ApiFunc<`${typeof API.FEEDBACK_STATUS}`, 'put'> = data =>
  instance.put(API.FEEDBACK_STATUS, data)
