import { mailSaga } from '@module/ComposeEmail/redux/saga'
import { playerSaga } from '@module/Player/redux/saga'
import { all } from 'redux-saga/effects'

export default function* rootSaga() {
  try {
    yield all([playerSaga(), mailSaga()])
  } catch (err) {
    console.warn(err)
  }
}
