import { useSignalConsumer } from '@src/hooks'
import { Target } from '@src/hooks/useSignal'
import { FC, useEffect, useState } from 'react'
import { IconProps } from 'react-feather'
import { Button, ButtonProps, Spinner } from 'reactstrap'

type Props = {
  loading?: boolean
  spinnerSize?: 'sm' | 'md' | 'lg' | 'xl'
  spinnerColor?: string
  activateTimer?: boolean
  msc?: number
  IconLeft?: FC<IconProps>
  IconRight?: FC<IconProps>
  countdown?: number
  signal?: Target<boolean>
} & ButtonProps

function LoadingButton(props: Props) {
  const {
    children,
    loading = false,
    disabled,
    spinnerSize = 'sm',
    spinnerColor = 'light',
    activateTimer,
    msc = 1000,
    IconLeft,
    IconRight,
    countdown = 60000,
    signal,
    ...rest
  } = props

  signal && useSignalConsumer(signal)

  const [timer, setTimer] = useState(countdown)
  const secsLeft = timer / msc

  useEffect(() => {
    if (activateTimer && timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevState => prevState - msc)
      }, msc)
      return () => {
        setTimer(countdown)
        clearInterval(interval)
      }
    }
  }, [activateTimer])

  return (
    <>
      <Button {...rest} disabled={loading || activateTimer || signal?.value || disabled}>
        {activateTimer ? (
          <>{secsLeft === 60 ? '1:00' : `0:${secsLeft < 10 ? `0${secsLeft}` : secsLeft}`}</>
        ) : loading || signal?.value ? (
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <span style={{ visibility: 'hidden' }}>{children}</span>
            <div style={{ position: 'absolute', transform: ' translateX(-50%)', left: '50%' }}>
              <Spinner className='loading-button' size={spinnerSize} color={spinnerColor} />
            </div>
          </div>
        ) : IconLeft ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <IconLeft size={16} />
            <span>{children}</span>
          </div>
        ) : IconRight ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <span>{children}</span>
            <IconRight size={16} />
          </div>
        ) : (
          <span>{children}</span>
        )}
      </Button>
    </>
  )
}

export default LoadingButton
