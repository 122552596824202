import { DefaultState } from '@src/redux/types'

import * as actionTypes from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ data: null, loading: false, error: null })

export const initialState = (): {
  [actionTypes.INTERNAL_EXAM_CREATE]: DefaultState<`${typeof API.INTERNAL_EXAM}`, 'post'>
  [actionTypes.INTERNAL_EXAM]: DefaultState<`${typeof API.INTERNAL_EXAM}{id}`, 'get'>
  [actionTypes.INTERNAL_EXAM_ALL]: DefaultState<`${typeof API.INTERNAL_EXAM_ALL}`, 'get'>
  [actionTypes.INTERNAL_EXAM_LIST]: DefaultState<`${typeof API.INTERNAL_EXAM}`, 'get'>

  // [actionTypes.INTERNAL_EXAM_PLACE_CREATE]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}`, 'post'>
  // [actionTypes.INTERNAL_EXAM_PLACE]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'get'>
  // [actionTypes.INTERNAL_EXAM_PLACE_ALL]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE_ALL}`, 'get'>
  // [actionTypes.INTERNAL_EXAM_PLACE_LIST]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}`, 'get'>
} => ({
  [actionTypes.INTERNAL_EXAM_CREATE]: defaultState(),
  [actionTypes.INTERNAL_EXAM]: defaultState(),
  [actionTypes.INTERNAL_EXAM_ALL]: defaultState(),
  [actionTypes.INTERNAL_EXAM_LIST]: defaultState(),

  //   [actionTypes.INTERNAL_EXAM_PLACE_CREATE]: defaultState(),
  //   [actionTypes.INTERNAL_EXAM_PLACE]: defaultState(),
  //   [actionTypes.INTERNAL_EXAM_PLACE_ALL]: defaultState(),
  //   [actionTypes.INTERNAL_EXAM_PLACE_LIST]: defaultState(),
})
