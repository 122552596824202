import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  INSTITUTION_PAGE_SETTINGS,
  INSTITUTION_PAGE_SETTINGS_ALL,
  INSTITUTION_PAGE_SETTINGS_IDENTITY,
  INSTITUTION_PAGE_SETTINGS_LIST,
} from '../constants/actionTypes'

const selector = (state: RootState) => prop(GLOBAL_STATE, state)

export const institutionPageSettings = createSelector(selector, prop(INSTITUTION_PAGE_SETTINGS))

export const institutionPageSettingsList = createSelector(selector, prop(INSTITUTION_PAGE_SETTINGS_LIST))

export const institutionPageSettingsAll = createSelector(selector, prop(INSTITUTION_PAGE_SETTINGS_ALL))

export const institutionPageSettingsIdentity = createSelector(selector, prop(INSTITUTION_PAGE_SETTINGS_IDENTITY))
