// ** React Imports
// ** Core Language Data
import { getLanguage, getLanguageOption, setLanguage, setLanguageOption } from '@helpers/storageLanguage'
import { useAppDispatch, useSignalConsumer } from '@hooks'
// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesRu from '@src/assets/data/locales/ru.json'
import userMessagesUz from '@src/assets/data/locales/uz.json'
import { Target } from '@src/hooks/useSignal'
import { updateUserSettings } from '@store/actions'
import { PropsWithChildren, createContext } from 'react'
// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Menu msg obj
const menuMessages = {
  en: { ...userMessagesEn },
  ru: { ...userMessagesRu },
  uz: { ...userMessagesUz },
}

type Props = {
  signal: Target<{
    locale: 'en' | 'ru' | 'uz'
    locale_option: { label: string; value: 'en' | 'ru' | 'uz' } | null
  }>
} & PropsWithChildren

const defaultValue = {
  locale: getLanguage(),
  locale_option: getLanguageOption(),
  switchLanguageServer: (lang: { label: string; value: 'en' | 'ru' | 'uz' }) => {},
  switchLocaleLocally: (lang: { label: string; value: 'en' | 'ru' | 'uz' }) => {},
}

// ** Create Context
const Context = createContext(defaultValue)

const IntlProviderWrapper = ({ children, signal }: Props) => {
  const dispatch = useAppDispatch()
  const { state: locale } = useSignalConsumer(signal)

  // ** States
  // const [messages, setMessages] = useState(menuMessages[defaultValue.locale])

  // ** Switches Language
  const switchLanguageServer = (lang: { label: string; value: 'en' | 'ru' | 'uz' }) => {
    dispatch((dispatch, getStore) => {
      const userInfo = getStore().Auth.userInfo
      if (userInfo) {
        dispatch(
          updateUserSettings({
            institution_id: userInfo.selected_institution?.value,
            education_form: userInfo.selected_education_form?.value,
            locale: lang.value,
            onFulfilled() {
              // setMessages(menuMessages[lang.value])
              setLanguageOption(JSON.stringify(lang))
              setLanguage(lang.value)
              signal.value = {
                locale: lang.value,
                locale_option: lang,
              }
            },
          })
        )
      }
    })
  }

  const switchLocaleLocally = (lang: { label: string; value: 'en' | 'ru' | 'uz' }) => {
    signal.value = {
      locale: lang.value,
      locale_option: lang,
    }
    setLanguageOption(JSON.stringify(lang))
    setLanguage(lang.value)
  }

  return (
    <Context.Provider
      key={locale.locale}
      value={{ locale: locale.locale, switchLanguageServer, switchLocaleLocally, locale_option: locale.locale_option }}
    >
      <IntlProvider
        onError={() => {}}
        key={locale.locale}
        locale={locale.locale}
        messages={menuMessages[locale.locale]}
        defaultLocale={defaultValue.locale}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
