import { OffenceRoutes } from '@module/AcademicMisconduct/router'
import { AcademicYearsRoutes } from '@module/AcademicYears/router'
import { AccountRoutes } from '@module/Account/router'
import { AccountingRoutes } from '@module/Accounting/router'
import { AchievementRecordsRoutes } from '@module/AchievementRecords/router'
import { Articulate } from '@module/Articulate/router'
import { ECRoutes } from '@module/AssessmentEC/router'
import { AssessmentMarkRoutes } from '@module/AssessmentMarks/router'
import { AttendanceRoutes } from '@module/Attendance/router'
import { AttendanceReasonRoutes } from '@module/AttendanceReason/router'
import { AuthRoutes } from '@module/Auth/router'
import { CalendarRoutes } from '@module/Calendar/router'
import { ContractAgreementRoutes } from '@module/ContractInformation/router'
import { CourseCampaignRoutes } from '@module/Course/activate/router'
import { CourseCreateRoutes } from '@module/Course/create/router'
import { CourseTransferRoutes } from '@module/CourseTransfer/router'
import { DebitRoutes } from '@module/Debit/router'
import { DepartmentsRoutes } from '@module/Departments/router'
import { DocumentManagement } from '@module/DocManagement/router'
import { EnrollmentListRoutes } from '@module/Enrollment/router'
import { FileRoutes } from '@module/Files/ModuleFiles/router'
import { FormRoutes } from '@module/Forms/router'
import { GeneralAdmissionRoutes } from '@module/GeneralAdmission/router'
import { GroupingRoutes } from '@module/Groups/router'
import { GuestUsersRoutes } from '@module/GuestUsers/router'
import { HRAdmissionRoutes } from '@module/HRAdmission/router'
import { InstitutionRoutes } from '@module/Institution/router'
import { InternalExamPlaceRoutes } from '@module/InternalExamPlace/router'
import { JobOfferRoutes } from '@module/JobOffer/router'
import { JobPositionRoutes } from '@module/JobPosition/router'
import { LessonRoutes } from '@module/Lesson/router'
import { LibraryRoutes } from '@module/Library/router'
import { ModulesOutcomeRoutes } from '@module/ModuleOutcome/router'
import { CCLSModuleRoutes } from '@module/Modules/activate/router'
import { ModulesCreateRoutes } from '@module/Modules/create/router'
import { OpenSourceRoutes } from '@module/OpenSource/router'
import { PageEmbedRoutes } from '@module/PageEmbedTest/router'
import { PaymentsRoutes } from '@module/Payments/router'
import { ReportRoutes } from '@module/Reports/router'
import { RolesRoutes } from '@module/Roles/router'
import { StCourseAdmissionRoutes } from '@module/ShortCourseAdmission/router'
import { ShortTermCourseRoutes } from '@module/ShortTermCourse/router'
import { StaffRoutes } from '@module/Staff/router'
import { StudentRoutes } from '@module/Student/router'
import { AdmissionRoutes } from '@module/StudentAdmission/router'
import { StudentCVRoutes } from '@module/StudentCV/router'
import { StudentDashboardRoutes } from '@module/StudentDashboard/router'
import { StudentDocsRoutes } from '@module/StudentDocs/router'
import { TimetableRoutes } from '@module/Timetable/router'
import { VacancyPositionRoutes } from '@module/VacancyPosition/router'
import { lazy } from 'react'

// ** Document title
const TemplateTitle = '%s - EduLab University'

// ** Default Route
const DefaultRoute = '/home'

// ** Merge Routes
const Routes = [
  ...AuthRoutes,
  ...AccountRoutes,
  ...AccountingRoutes,
  ...RolesRoutes,
  ...AcademicYearsRoutes,
  ...LessonRoutes,
  ...AttendanceReasonRoutes,
  ...GroupingRoutes,
  ...CourseCreateRoutes,
  ...CourseCampaignRoutes,
  ...CourseTransferRoutes,
  ...DebitRoutes,
  ...InstitutionRoutes,
  ...InternalExamPlaceRoutes,
  ...ShortTermCourseRoutes,
  ...ModulesCreateRoutes,
  ...CCLSModuleRoutes,
  ...StudentRoutes,
  ...StaffRoutes,
  ...EnrollmentListRoutes,
  ...CalendarRoutes,
  ...AssessmentMarkRoutes,
  ...OffenceRoutes,
  ...ECRoutes,
  ...ReportRoutes,
  ...ModulesOutcomeRoutes,
  ...FileRoutes,
  ...AchievementRecordsRoutes,
  ...AttendanceRoutes,
  ...StudentDashboardRoutes,
  ...FormRoutes,
  ...DepartmentsRoutes,
  ...HRAdmissionRoutes,
  ...StCourseAdmissionRoutes,
  ...LibraryRoutes,
  ...AdmissionRoutes,
  ...GeneralAdmissionRoutes,
  ...JobOfferRoutes,
  ...JobPositionRoutes,
  ...VacancyPositionRoutes,
  ...GuestUsersRoutes,
  ...ContractAgreementRoutes,
  ...OpenSourceRoutes,
  ...PageEmbedRoutes,
  ...PaymentsRoutes,
  ...Articulate,
  ...TimetableRoutes,
  ...StudentDocsRoutes,
  ...DocumentManagement,
  ...StudentCVRoutes,
  {
    path: '/home',
    component: lazy(() => import('@src/views/Home')),
  },
  {
    path: '/error',
    component: lazy(() => import('@src/views/Error')),
    layout: 'BlankLayout',
    meta: {
      access: ['SUPER_ADMIN'],
    },
  },
]

export { DefaultRoute, TemplateTitle, Routes }
