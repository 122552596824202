import { DefaultState } from '@src/redux/types'

import {
  INTERNAL_EXAM_APPLICANTS_LIST,
  INTERNAL_EXAM_PLACE,
  INTERNAL_EXAM_PLACE_ALL,
  INTERNAL_EXAM_PLACE_CREATE,
  INTERNAL_EXAM_PLACE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [INTERNAL_EXAM_PLACE]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'get'>
  [INTERNAL_EXAM_PLACE_ALL]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE_ALL}`, 'get'>
  [INTERNAL_EXAM_PLACE_CREATE]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}`, 'post'>
  // [INTERNAL_EXAM_PLACE_DELETE]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}{id}`, 'delete'>
  [INTERNAL_EXAM_PLACE_LIST]: DefaultState<`${typeof API.INTERNAL_EXAM_PLACE}`, 'get'>
  [INTERNAL_EXAM_APPLICANTS_LIST]: DefaultState<`${typeof API.INTERNAL_EXAM_APPLICANTS}{id}`, 'get'>
} = () => ({
  [INTERNAL_EXAM_PLACE]: defaultState(),
  [INTERNAL_EXAM_PLACE_ALL]: defaultState(),
  [INTERNAL_EXAM_PLACE_CREATE]: defaultState(),
  // [INTERNAL_EXAM_PLACE_DELETE]: defaultState(),
  [INTERNAL_EXAM_PLACE_LIST]: defaultState(),
  [INTERNAL_EXAM_APPLICANTS_LIST]: defaultState(),
})
