import { toastError } from '@helpers/toastError'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const timetable = createAsyncThunk(
  TYPES.TIMETABLE,
  async (params: PayloadCreatorParams<`${typeof API.TIMETABLE}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.timetable(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const timetableAll = createAsyncThunk(
  TYPES.TIMETABLE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.TIMETABLE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.timetableAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const timetableGenerate = createAsyncThunk(
  TYPES.TIMETABLE_GENERATE,
  async (params: PayloadCreatorParams<`${typeof API.TIMETABLE_GENERATE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.timetableGenerate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
