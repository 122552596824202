import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, STAFF, STAFF_ALL, STAFF_LIST, STAFF_USERNAME } from '../constants/actionTypes'

const staffSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const staffAllSelector = createSelector(staffSelectors, prop(STAFF_ALL))

export const staffSelector = createSelector(staffSelectors, prop(STAFF))

export const staffUsernameSelector = createSelector(staffSelectors, prop(STAFF_USERNAME))

export const staffListSelector = createSelector(staffSelectors, prop(STAFF_LIST))

export const staffAllOptionsSelector = createSelector(staffAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({
          label: `${item.lastname} ${item.firstname} ${item.midname} (${item.username})`,
          value: item.id,
        }))
      : [],
  }
})
