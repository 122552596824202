import { ASSESSMENT } from '../../Assessment/constants/route'

export { ASSESSMENT }
export const ASSESSMENT_MARK_ENTRY = `${ASSESSMENT}mark-entry`
export const ASSESSMENT_MARK_ENTRY_LIST = `/mark-entry`
export const ASSESSMENT_MARK_ENTRY_REGISTRAR = `${ASSESSMENT}/registrar-mark-entry`
export const ASSESSMENT_MARK_ENTRY_REGISTRAR_LIST = `/marks`
export const ASSESSMENT_MARK_AMENDMENT = `/mark-amendment`
export const ASSESSMENT_MARK_AMENDMENT_LIST = `${ASSESSMENT_MARK_AMENDMENT}/list`

export const MARK_LESSON = '/mark-lesson'
export const MARK_ASSESSMENT = '/mark-assessment'
