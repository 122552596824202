import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const eLibraryList: ApiFunc<`${typeof API.E_LIBRARY}`, 'get'> = data =>
  instance.get(API.E_LIBRARY, {
    params: data,
  })

export const eLibraryCreate: ApiFunc<`${typeof API.E_LIBRARY}`, 'post'> = data => instance.post(API.E_LIBRARY, data)

export const eLibraryDelete: ApiFunc<`${typeof API.E_LIBRARY}{id}`, 'delete'> = data =>
  instance.delete(`${API.E_LIBRARY}${'id' in data ? prop('id', data) : data?.name}`)

export const eLibrary: ApiFunc<`${typeof API.E_LIBRARY}{id}`, 'get'> = data =>
  instance.get(`${API.E_LIBRARY}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const eLibraryTypes: ApiFunc<`${typeof API.E_LIBRARY_TYPES}`, 'get'> = data =>
  instance.get(API.E_LIBRARY_TYPES, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const eLibraryAll: ApiFunc<`${typeof API.E_LIBRARY_ALL}`, 'get'> = data =>
  instance.get(API.E_LIBRARY_ALL, {
    params: data,
  })
