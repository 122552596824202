import { lazy } from 'react'
import * as ROUTE from '../constants/route'
import * as ROLES from '@constants/userRoles'

export const StCourseAdmissionRoutes = [
  {
    path: ROUTE.ST_COURSE_MY_APPLICATIONS,
    component: lazy(() => import('../views/MyApplications')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.ST_COURSE_APPLICATIONS,
    component: lazy(() => import('../views/AdminApplications')),
    exact: true,
    meta: {
      access: [ROLES.ADMISSION_MODERATOR, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.ST_COURSE_MY_APPLICATIONS_CREATE,
    component: lazy(() => import('../views/MyApplicationsCreate')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.ST_COURSE_APPLICATIONS_EDIT,
    component: lazy(() => import('../views/AdminApplicationsEdit')),
    exact: true,
    meta: {
      access: [ROLES.ADMISSION_MODERATOR, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.ST_COURSE_ADMIN_APPLICATION_FORM,
    component: lazy(() => import('../views/AdminAdmissionApplication')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.ST_COURSE_ADMIN_APPLICATION_CREATE,
    component: lazy(() => import('../views/AdminAdmissionApplicationCreate')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.SUPER_ADMIN],
    },
  },
  {
    path: ROUTE.ST_COURSE_ADMIN_APPLICATION_CATEGORY,
    component: lazy(() => import('../views/AdminAdmissionApplicationCategory')),
    exact: true,
    meta: {
      access: [ROLES.GUEST_USER, ROLES.SUPER_ADMIN],
    },
  },
]
