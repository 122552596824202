import { DefaultState } from '@src/redux/types'

import { COUNTRY, COUNTRY_ALL, COUNTRY_CREATE, COUNTRY_DELETE, COUNTRY_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [COUNTRY]: DefaultState<`${typeof API.COUNTRY}{id}`, 'get'>
  [COUNTRY_ALL]: DefaultState<`${typeof API.COUNTRY_ALL}`, 'get'>
  [COUNTRY_CREATE]: DefaultState<`${typeof API.COUNTRY}`, 'post'>
  [COUNTRY_DELETE]: DefaultState<`${typeof API.COUNTRY}{id}`, 'delete'>
  [COUNTRY_LIST]: DefaultState<`${typeof API.COUNTRY}`, 'get'>
} = () => ({
  [COUNTRY]: defaultState(),
  [COUNTRY_ALL]: defaultState(),
  [COUNTRY_CREATE]: defaultState(),
  [COUNTRY_DELETE]: defaultState(),
  [COUNTRY_LIST]: defaultState(),
})
