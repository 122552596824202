import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import * as TYPES from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const ASSESSMENT_OFFENCE = TYPES.GLOBAL_STATE

const offenceSlice = createSlice({
  name: ASSESSMENT_OFFENCE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.offence.pending, state => {
        state[TYPES.OFFENCE].loading = true
      })
      .addCase(actionCreators.offence.rejected, (state, action) => {
        state[TYPES.OFFENCE].loading = false
        state[TYPES.OFFENCE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offence.fulfilled, (state, action) => {
        state[TYPES.OFFENCE].loading = false
        state[TYPES.OFFENCE].error = null
        state[TYPES.OFFENCE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceAll.pending, state => {
        state[TYPES.OFFENCE_ALL].loading = true
      })
      .addCase(actionCreators.offenceAll.rejected, (state, action) => {
        state[TYPES.OFFENCE_ALL].loading = false
        state[TYPES.OFFENCE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceAll.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_ALL].loading = false
        state[TYPES.OFFENCE_ALL].error = null
        state[TYPES.OFFENCE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceChangeStatus.pending, state => {
        state[TYPES.OFFENCE_CHANGE_STATUS].loading = true
      })
      .addCase(actionCreators.offenceChangeStatus.rejected, (state, action) => {
        state[TYPES.OFFENCE_CHANGE_STATUS].loading = false
        state[TYPES.OFFENCE_CHANGE_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceChangeStatus.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_CHANGE_STATUS].loading = false
        state[TYPES.OFFENCE_CHANGE_STATUS].error = null
        state[TYPES.OFFENCE_CHANGE_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceChangeSubjectBoardStatus.pending, state => {
        state[TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].loading = true
      })
      .addCase(actionCreators.offenceChangeSubjectBoardStatus.rejected, (state, action) => {
        state[TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].loading = false
        state[TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceChangeSubjectBoardStatus.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].loading = false
        state[TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].error = null
        state[TYPES.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceCreate.pending, state => {
        state[TYPES.OFFENCE_CREATE].loading = true
      })
      .addCase(actionCreators.offenceCreate.rejected, (state, action) => {
        state[TYPES.OFFENCE_CREATE].loading = false
        state[TYPES.OFFENCE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceCreate.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_CREATE].loading = false
        state[TYPES.OFFENCE_CREATE].error = null
        state[TYPES.OFFENCE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceDelete.pending, state => {
        state[TYPES.OFFENCE_DELETE].loading = true
      })
      .addCase(actionCreators.offenceDelete.rejected, (state, action) => {
        state[TYPES.OFFENCE_DELETE].loading = false
        state[TYPES.OFFENCE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceDelete.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_DELETE].loading = false
        state[TYPES.OFFENCE_DELETE].error = null
        state[TYPES.OFFENCE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilation.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION].loading = true
      })
      .addCase(actionCreators.offenceInvigilation.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION].loading = false
        state[TYPES.OFFENCE_INVIGILATION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilation.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION].loading = false
        state[TYPES.OFFENCE_INVIGILATION].error = null
        state[TYPES.OFFENCE_INVIGILATION].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationAll.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_ALL].loading = true
      })
      .addCase(actionCreators.offenceInvigilationAll.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_ALL].loading = false
        state[TYPES.OFFENCE_INVIGILATION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationAll.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_ALL].loading = false
        state[TYPES.OFFENCE_INVIGILATION_ALL].error = null
        state[TYPES.OFFENCE_INVIGILATION_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationConsultations.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS].loading = true
      })
      .addCase(actionCreators.offenceInvigilationConsultations.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationConsultations.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS].error = null
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationConsultationsAll.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL].loading = true
      })
      .addCase(actionCreators.offenceInvigilationConsultationsAll.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationConsultationsAll.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL].error = null
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationConsultationsCreate.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].loading = true
      })
      .addCase(actionCreators.offenceInvigilationConsultationsCreate.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationConsultationsCreate.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].error = null
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationConsultationsDelete.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].loading = true
      })
      .addCase(actionCreators.offenceInvigilationConsultationsDelete.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationConsultationsDelete.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].error = null
        state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationCreate.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_CREATE].loading = true
      })
      .addCase(actionCreators.offenceInvigilationCreate.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CREATE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationCreate.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_CREATE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_CREATE].error = null
        state[TYPES.OFFENCE_INVIGILATION_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationDelete.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_DELETE].loading = true
      })
      .addCase(actionCreators.offenceInvigilationDelete.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_DELETE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationDelete.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_DELETE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_DELETE].error = null
        state[TYPES.OFFENCE_INVIGILATION_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationMinutes.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES].loading = true
      })
      .addCase(actionCreators.offenceInvigilationMinutes.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationMinutes.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES].error = null
        state[TYPES.OFFENCE_INVIGILATION_MINUTES].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationMinutesAll.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL].loading = true
      })
      .addCase(actionCreators.offenceInvigilationMinutesAll.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationMinutesAll.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL].error = null
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationMinutesCreate.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE].loading = true
      })
      .addCase(actionCreators.offenceInvigilationMinutesCreate.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationMinutesCreate.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE].error = null
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceInvigilationMinutesDelete.pending, state => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE].loading = true
      })
      .addCase(actionCreators.offenceInvigilationMinutesDelete.rejected, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceInvigilationMinutesDelete.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE].loading = false
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE].error = null
        state[TYPES.OFFENCE_INVIGILATION_MINUTES_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceList.pending, state => {
        state[TYPES.OFFENCE_LIST].loading = true
      })
      .addCase(actionCreators.offenceList.rejected, (state, action) => {
        state[TYPES.OFFENCE_LIST].loading = false
        state[TYPES.OFFENCE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceList.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_LIST].loading = false
        state[TYPES.OFFENCE_LIST].error = null
        state[TYPES.OFFENCE_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.offencePublish.pending, state => {
        state[TYPES.OFFENCE_PUBLISH].loading = true
      })
      .addCase(actionCreators.offencePublish.rejected, (state, action) => {
        state[TYPES.OFFENCE_PUBLISH].loading = false
        state[TYPES.OFFENCE_PUBLISH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offencePublish.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_PUBLISH].loading = false
        state[TYPES.OFFENCE_PUBLISH].error = null
        state[TYPES.OFFENCE_PUBLISH].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceReportAll.pending, state => {
        state[TYPES.OFFENCE_REPORT_ALL].loading = true
      })
      .addCase(actionCreators.offenceReportAll.rejected, (state, action) => {
        state[TYPES.OFFENCE_REPORT_ALL].loading = false
        state[TYPES.OFFENCE_REPORT_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceReportAll.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_REPORT_ALL].loading = false
        state[TYPES.OFFENCE_REPORT_ALL].error = null
        state[TYPES.OFFENCE_REPORT_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceReportCreate.pending, state => {
        state[TYPES.OFFENCE_REPORT_CREATE].loading = true
      })
      .addCase(actionCreators.offenceReportCreate.rejected, (state, action) => {
        state[TYPES.OFFENCE_REPORT_CREATE].loading = false
        state[TYPES.OFFENCE_REPORT_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceReportCreate.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_REPORT_CREATE].loading = false
        state[TYPES.OFFENCE_REPORT_CREATE].error = null
        state[TYPES.OFFENCE_REPORT_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceReportList.pending, state => {
        state[TYPES.OFFENCE_REPORT_LIST].loading = true
      })
      .addCase(actionCreators.offenceReportList.rejected, (state, action) => {
        state[TYPES.OFFENCE_REPORT_LIST].loading = false
        state[TYPES.OFFENCE_REPORT_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceReportList.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_REPORT_LIST].loading = false
        state[TYPES.OFFENCE_REPORT_LIST].error = null
        state[TYPES.OFFENCE_REPORT_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceType.pending, state => {
        state[TYPES.OFFENCE_TYPE].loading = true
      })
      .addCase(actionCreators.offenceType.rejected, (state, action) => {
        state[TYPES.OFFENCE_TYPE].loading = false
        state[TYPES.OFFENCE_TYPE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceType.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_TYPE].loading = false
        state[TYPES.OFFENCE_TYPE].error = null
        state[TYPES.OFFENCE_TYPE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceTypeAll.pending, state => {
        state[TYPES.OFFENCE_TYPE_ALL].loading = true
      })
      .addCase(actionCreators.offenceTypeAll.rejected, (state, action) => {
        state[TYPES.OFFENCE_TYPE_ALL].loading = false
        state[TYPES.OFFENCE_TYPE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceTypeAll.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_TYPE_ALL].loading = false
        state[TYPES.OFFENCE_TYPE_ALL].error = null
        state[TYPES.OFFENCE_TYPE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceTypeCreate.pending, state => {
        state[TYPES.OFFENCE_TYPE_CREATE].loading = true
      })
      .addCase(actionCreators.offenceTypeCreate.rejected, (state, action) => {
        state[TYPES.OFFENCE_TYPE_CREATE].loading = false
        state[TYPES.OFFENCE_TYPE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceTypeCreate.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_TYPE_CREATE].loading = false
        state[TYPES.OFFENCE_TYPE_CREATE].error = null
        state[TYPES.OFFENCE_TYPE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceTypeDelete.pending, state => {
        state[TYPES.OFFENCE_TYPE_DELETE].loading = true
      })
      .addCase(actionCreators.offenceTypeDelete.rejected, (state, action) => {
        state[TYPES.OFFENCE_TYPE_DELETE].loading = false
        state[TYPES.OFFENCE_TYPE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceTypeDelete.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_TYPE_DELETE].loading = false
        state[TYPES.OFFENCE_TYPE_DELETE].error = null
        state[TYPES.OFFENCE_TYPE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.offenceUnpublish.pending, state => {
        state[TYPES.OFFENCE_UNPUBLISH].loading = true
      })
      .addCase(actionCreators.offenceUnpublish.rejected, (state, action) => {
        state[TYPES.OFFENCE_UNPUBLISH].loading = false
        state[TYPES.OFFENCE_UNPUBLISH].error = action.payload as AxiosError
      })
      .addCase(actionCreators.offenceUnpublish.fulfilled, (state, action) => {
        state[TYPES.OFFENCE_UNPUBLISH].loading = false
        state[TYPES.OFFENCE_UNPUBLISH].error = null
        state[TYPES.OFFENCE_UNPUBLISH].data = action.payload
      })

    builder
      .addCase(actionCreators.reportMisconduct.pending, state => {
        state[TYPES.REPORT_MISCONDUCT].loading = true
      })
      .addCase(actionCreators.reportMisconduct.rejected, (state, action) => {
        state[TYPES.REPORT_MISCONDUCT].loading = false
        state[TYPES.REPORT_MISCONDUCT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.reportMisconduct.fulfilled, (state, action) => {
        state[TYPES.REPORT_MISCONDUCT].loading = false
        state[TYPES.REPORT_MISCONDUCT].error = null
        state[TYPES.REPORT_MISCONDUCT].data = action.payload
      })

    builder.addCase(actionCreators.offenceClear, state => {
      state[TYPES.OFFENCE] = defaultState()
    })

    builder.addCase(actionCreators.offenceAllClear, state => {
      state[TYPES.OFFENCE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.offenceInvigilationAllClear, state => {
      state[TYPES.OFFENCE_INVIGILATION_ALL] = defaultState()
    })

    builder.addCase(actionCreators.offenceInvigilationClear, state => {
      state[TYPES.OFFENCE_INVIGILATION] = defaultState()
    })

    builder.addCase(actionCreators.offenceInvigilationConsultationsAllClear, state => {
      state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS_ALL] = defaultState()
    })

    builder.addCase(actionCreators.offenceInvigilationConsultationsClear, state => {
      state[TYPES.OFFENCE_INVIGILATION_CONSULTATIONS] = defaultState()
    })

    builder.addCase(actionCreators.offenceInvigilationMinutesAllClear, state => {
      state[TYPES.OFFENCE_INVIGILATION_MINUTES_ALL] = defaultState()
    })

    builder.addCase(actionCreators.offenceListClear, state => {
      state[TYPES.OFFENCE_LIST] = defaultState()
    })

    builder.addCase(actionCreators.offenceTypeClear, state => {
      state[TYPES.OFFENCE_TYPE] = defaultState()
    })
  },
})

export const { reducer: offenceReducer } = offenceSlice
