import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, JOB_OFFER_CREATE, JOB_OFFER_LIST, JOB_OFFER_STUDENT_ALL } from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const jobOfferCreateSelector = createSelector(selectors, prop(JOB_OFFER_CREATE))

export const jobOfferListSelector = createSelector(selectors, prop(JOB_OFFER_LIST))

export const jobOfferStudentAllSelector = createSelector(selectors, prop(JOB_OFFER_STUDENT_ALL))
