import { ApiFunc, getInstance } from '@src/helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const courseTransfer: ApiFunc<`${typeof API.COURSE_TRANSFER}`, 'post'> = data =>
  instance.post(API.COURSE_TRANSFER, data)

export const courseTransferList: ApiFunc<`${typeof API.COURSE_TRANSFER}`, 'get'> = data =>
  instance.get(API.COURSE_TRANSFER, { params: data })

export const courseTransferInfo: ApiFunc<`${typeof API.COURSE_TRANSFER_INFO}`, 'get'> = data =>
  instance.get(API.COURSE_TRANSFER_INFO, { params: data })

export const courseTransferCourses: ApiFunc<`${typeof API.COURSE_TRANSFER_COURSES}`, 'get'> = data =>
  instance.get(API.COURSE_TRANSFER_COURSES, { params: data })
