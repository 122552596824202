import { DefaultState } from '@src/redux/types'

import {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_DELETE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_AMENDMENT_PUBLISH,
  ASSESSMENT_MARK_STATUS,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ASSESSMENT_AMENDMENT]: DefaultState<`${typeof API.ASSESSMENT_AMENDMENT}{id}`, 'get'>
  [ASSESSMENT_AMENDMENT_ALL]: DefaultState<`${typeof API.ASSESSMENT_AMENDMENT_ALL}`, 'get'>
  [ASSESSMENT_AMENDMENT_CREATE]: DefaultState<`${typeof API.ASSESSMENT_AMENDMENT}`, 'post'>
  [ASSESSMENT_AMENDMENT_DELETE]: DefaultState<`${typeof API.ASSESSMENT_AMENDMENT}{id}`, 'delete'>
  [ASSESSMENT_AMENDMENT_LIST]: DefaultState<`${typeof API.ASSESSMENT_AMENDMENT}`, 'get'>
  [ASSESSMENT_AMENDMENT_PUBLISH]: DefaultState<`${typeof API.ASSESSMENT_AMENDMENT_PUBLISH}`, 'put'>
  [ASSESSMENT_MARK_STATUS]: DefaultState<`${typeof API.ASSESSMENT_MARK_CHANGE_STATUS}`, 'put'>
} = () => ({
  [ASSESSMENT_AMENDMENT]: defaultState(),
  [ASSESSMENT_AMENDMENT_ALL]: defaultState(),
  [ASSESSMENT_AMENDMENT_CREATE]: defaultState(),
  [ASSESSMENT_AMENDMENT_DELETE]: defaultState(),
  [ASSESSMENT_AMENDMENT_LIST]: defaultState(),
  [ASSESSMENT_AMENDMENT_PUBLISH]: defaultState(),
  [ASSESSMENT_MARK_STATUS]: defaultState(),
})
