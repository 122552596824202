import { DefaultState } from '@src/redux/types'

import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_LIST,
  FEEDBACK_STATUS,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [FEEDBACK_ALL]: DefaultState<`${typeof API.FEEDBACK_ALL}`, 'get'>
  [FEEDBACK_EDIT]: DefaultState<`${typeof API.FEEDBACK_EDIT}`, 'get'>
  [FEEDBACK_CREATE]: DefaultState<`${typeof API.FEEDBACK}`, 'post'>
  [FEEDBACK_LIST]: DefaultState<`${typeof API.FEEDBACK}`, 'get'>
  [FEEDBACK_STATUS]: DefaultState<`${typeof API.FEEDBACK_STATUS}`, 'put'>
  [FEEDBACK_TYPES]: DefaultState<`${typeof API.FEEDBACK_TYPES}`, 'get'>
  [FEEDBACK_UPDATE]: DefaultState<`${typeof API.FEEDBACK_UPDATE}`, 'put'>
} = () => ({
  [FEEDBACK_ALL]: defaultState(),
  [FEEDBACK_EDIT]: defaultState(),
  [FEEDBACK_CREATE]: defaultState(),
  [FEEDBACK_LIST]: defaultState(),
  [FEEDBACK_STATUS]: defaultState(),
  [FEEDBACK_TYPES]: defaultState(),
  [FEEDBACK_UPDATE]: defaultState(),
})
