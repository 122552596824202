import { DefaultState } from '@src/redux/types'

import {
  GROUP_ALL,
  GROUP_ALL_FOR_ASSESSMENT,
  GROUP_ALL_SHORT,
  GROUP_ASSIGN,
  GROUP_ASSIGNED_LIST,
  GROUP_DELETE,
  GROUP_EDIT,
  GROUP_EXPORT,
  GROUP_LIST,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [GROUP_ALL]: DefaultState<`${typeof API.GROUP_ALL}`, 'get'>
  [GROUP_ALL_SHORT]: DefaultState<`${typeof API.GROUP_ALL_SHORT}`, 'get'>
  [GROUP_ALL_FOR_ASSESSMENT]: DefaultState<`${typeof API.GROUP_ALL_FOR_ASSESSMENT}`, 'get'>
  [GROUP_LIST]: DefaultState<`${typeof API.GROUP}`, 'get'>
  [GROUP_ASSIGN]: DefaultState<`${typeof API.GROUP_ASSIGN}`, 'post'>
  [GROUP_ASSIGNED_LIST]: DefaultState<`${typeof API.GROUP_ASSIGN}`, 'get'>
  [GROUP_EDIT]: DefaultState<`${typeof API.GROUP}`, 'post'>
  [GROUP_DELETE]: DefaultState<`${typeof API.GROUP}{id}`, 'delete'>
  [GROUP_SHUFFLE]: DefaultState<`${typeof API.GROUP_SHUFFLE}`, 'post'>
  [GROUP_STUDENT_TRANSFER]: DefaultState<`${typeof API.GROUP_STUDENT_TRANSFER}`, 'post'>
  [GROUP_EXPORT]: DefaultState<`${typeof API.GROUP_EXPORT}`, 'get'>
} = () => ({
  [GROUP_ALL]: defaultState(),
  [GROUP_ALL_SHORT]: defaultState(),
  [GROUP_ALL_FOR_ASSESSMENT]: defaultState(),
  [GROUP_LIST]: defaultState(),
  [GROUP_ASSIGN]: defaultState(),
  [GROUP_ASSIGNED_LIST]: defaultState(),
  [GROUP_EDIT]: defaultState(),
  [GROUP_DELETE]: defaultState(),
  [GROUP_SHUFFLE]: defaultState(),
  [GROUP_STUDENT_TRANSFER]: defaultState(),
  [GROUP_EXPORT]: defaultState(),
})
