export const GLOBAL_STATE = 'Forms'

export const FORM = 'FORM' as const
export const FORM_CREATE = 'FORM_CREATE' as const
export const FORM_DELETE = 'FORM_DELETE' as const
export const FORM_LIST = 'FORM_LIST' as const
export const FORM_ALL = 'FORM_ALL' as const
export const FORM_ACTION_TYPE = 'FORM_ACTION_TYPE' as const
export const FORM_FIELD_CREATE = 'FORM_FIELD_CREATE' as const
// export const FORM_FIELD_DELETE = 'FORM_FIELD_DELETE' as const
export const FORM_FIELD_BATCH = 'FORM_FIELD_BATCH' as const
export const FORM_FIELD_BATCH_CREATE = 'FORM_FIELD_BATCH_CREATE' as const
export const FORM_TYPE = 'FORM_TYPE' as const
export const FORM_TYPE_CREATE = 'FORM_TYPE_CREATE' as const
export const FORM_TYPE_DELETE = 'FORM_TYPE_DELETE' as const
export const FORM_TYPE_LIST = 'FORM_TYPE_LIST' as const
export const FORM_TYPE_ALL = 'FORM_TYPE_ALL' as const
export const FORM_REQUEST = 'FORM_REQUEST' as const
export const FORM_REQUEST_STATUS = 'FORM_REQUEST_STATUS' as const
export const FORM_REQUEST_CREATE = 'FORM_REQUEST_CREATE' as const
export const FORM_REQUEST_DELETE = 'FORM_REQUEST_DELETE' as const
export const FORM_REQUEST_LIST = 'FORM_REQUEST_LIST' as const
export const FORM_REQUEST_ALL = 'FORM_REQUEST_ALL' as const
export const FORM_REQUEST_CHANGE_STATUS = 'FORM_REQUEST_CHANGE_STATUS' as const
export const FORM_REQUEST_FIELDS = 'FORM_REQUEST_FIELDS' as const
export const FORM_REQUEST_CERTIFICATE = 'FORM_REQUEST_CERTIFICATE' as const
