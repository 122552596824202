import React from 'react'
import { equals } from 'ramda/es'

function TableLoader({ children, isContentEmpty = false, isLoading = false, tag = 'div' }) {
  const Wrapper = ({ children }) => {
    return equals(tag, 'div') ? (
      <div className='preloader'>{children}</div>
    ) : equals(tag, 'span') ? (
      <span className='preloader'>{children}</span>
    ) : equals(tag, 'tr') ? (
      <tr className='preloader'>
        <td colSpan='99' className='text-center'>
          {children}
        </td>
      </tr>
    ) : null
  }
  if (isLoading) {
    // return <PreLoader />
    return (
      <Wrapper className='preloader'>
        <span className='spinner-border avatar-sm text-primary m-2' role='status' />
      </Wrapper>
    )
  }

  if (isContentEmpty) {
    return (
      <tr>
        <td
          colSpan='99'
          className='text-center'
          // style={{
          //   position: 'absolute',
          //   // backgroundColor: 'rgba(0, 0, 0, .03)',
          //   width: '100%',
          //   textAlign: 'center',
          //   minHeight: '37px',
          //   display: 'flex',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          //   padding: '0',
          // }}
        >
          <span>No records to show</span>
        </td>
      </tr>
    )
  }

  return children
}

export default TableLoader
