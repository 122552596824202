export const HOME = '/home'

export * from '@module/AcademicMisconduct/constants/route'
export * from '@module/AcademicYears/constants/route'
export * from '@module/Accounting/constants/route'
export * from '@module/Account/constants/route'
export * from '@module/AchievementRecords/constants/route'
export * from '@module/Assessment/constants/route'
export * from '@module/AssessmentEC/constants/route'
export * from '@module/AssessmentMarks/constants/route'
export * from '@module/Attendance/constants/route'
export * from '@module/AttendanceReason/constants/route'
export * from '@module/Auth/constants/route'
export * from '@module/Calendar/constants/route'
export * from '@module/Course/create/constants/route'
export * from '@module/Course/activate/constants/route'
export * from '@module/CourseTransfer/constants/route'
export * from '@module/Debit/constants/route'
export * from '@module/Enrollment/constants/route'
export * from '@module/Files/ModuleFiles/constants/route'
export * from '@module/Forms/constants/route'
export * from '@module/Groups/constants/route'
export * from '@module/Institution/constants/route'
export * from '@module/InternalExamPlace/constants/route'
export * from '@module/Lesson/constants/route'
export * from '@module/ModuleOutcome/constants/route'
export * from '@module/Modules/create/constants/route'
export * from '@module/Modules/activate/constants/route'
// export * from '@module/QualityAssurance/constants/'
export * from '@module/Reports/constants/route'
export * from '@module/Roles/constants/route'
// export * from '@module/Semester/constants/'
export * from '@module/ShortTermCourse/constants/route'
// export * from '@module/Staff/constants/'
export * from '@module/Student/constants/route'
export * from '@module/StudentCV/constants/route'
export * from '@module/StudentDashboard/constants/route'
export * from '@module/StudentDocs/constants/route'
export * from '@module/HRAdmission/constants/route'
export * from '@module/ShortCourseAdmission/constants/route'
export * from '@module/Library/constants/route'
export * from '@module/JobOffer/constants/route'
export * from '@module/JobPosition/constants/route'
export * from '@module/VacancyPosition/constants/route'
export * from '@module/StudentAdmission/constants/route'
export * from '@module/GeneralAdmission/constants/route'
export * from '@module/GuestUsers/constants/route'
export * from '@module/OpenSource/constants/route'
export * from '@module/Payments/constants/route'
export * from '@module/Articulate/constants/route'
export * from '@module/Timetable/constants/route'
export * from '@module/DocManagement/constants/route'
