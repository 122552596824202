import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, PAYMENTS, PAYMENTS_CREATE, PAYMENTS_DELETE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const EXTRNAL_PAYMENTS = GLOBAL_STATE

export const { reducer: paymentsReducer } = createSlice({
  name: EXTRNAL_PAYMENTS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.paymentsList.pending, state => {
        state[PAYMENTS].loading = true
      })
      .addCase(actionCreators.paymentsList.rejected, (state, action) => {
        state[PAYMENTS].loading = false
        state[PAYMENTS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.paymentsList.fulfilled, (state, action) => {
        state[PAYMENTS].loading = false
        state[PAYMENTS].error = null
        state[PAYMENTS].data = action.payload
      })

    builder
      .addCase(actionCreators.paymentsCreate.pending, state => {
        state[PAYMENTS_CREATE].loading = true
      })
      .addCase(actionCreators.paymentsCreate.rejected, (state, action) => {
        state[PAYMENTS_CREATE].loading = false
        state[PAYMENTS_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.paymentsCreate.fulfilled, (state, action) => {
        state[PAYMENTS_CREATE].loading = false
        state[PAYMENTS_CREATE].error = null
        state[PAYMENTS_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.paymentsDelete.pending, state => {
        state[PAYMENTS_DELETE].loading = true
      })
      .addCase(actionCreators.paymentsDelete.rejected, (state, action) => {
        state[PAYMENTS_DELETE].loading = false
        state[PAYMENTS_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.paymentsDelete.fulfilled, (state, action) => {
        state[PAYMENTS_DELETE].loading = false
        state[PAYMENTS_DELETE].error = null
        state[PAYMENTS_DELETE].data = action.payload
      })

    builder.addCase(actionCreators.paymentsListClear, state => {
      state[PAYMENTS] = defaultState()
    })
  },
})
