import { lazy } from 'react'

import { GUEST_USER, SUPER_ADMIN } from '../../../constants/userRoles'
import { CONTRACT_AGREEMENT, CONTRACT_PAGE } from '../constants/route'

export const ContractAgreementRoutes = [
  {
    path: CONTRACT_AGREEMENT,
    component: lazy(() => import('../views/ContractAgreement')),
    exact: true,
    meta: {
      layout: 'blank',
      isPublic: true,
    },
  },
  {
    path: CONTRACT_PAGE,
    component: lazy(() => import('../views/ContractPage')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, GUEST_USER],
    },
  },
]
