import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as apiOrigin from '../api/stcOrigin'
import * as apiStep from '../api/stcStep'
import {
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_DELETE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_DELETE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_CREATE,
  STC_STEP_DELETE,
  STC_STEP_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const stc = createAsyncThunk(
  STC,
  async (params: PayloadCreatorParams<`${typeof API.STC}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stc(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCreate = createAsyncThunk(
  STC_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STC}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcDelete = createAsyncThunk(
  STC_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STC}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcList = createAsyncThunk(
  STC_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STC}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcAll = createAsyncThunk(
  STC_ALL,
  async (params: PayloadCreatorParams<`${typeof API.STC_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// Category
export const stcCategory = createAsyncThunk(
  STC_CATEGORY,
  async (params: PayloadCreatorParams<`${typeof API.STC_CATEGORY}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcCategory(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryCreate = createAsyncThunk(
  STC_CATEGORY_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STC_CATEGORY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcCategoryCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryDelete = createAsyncThunk(
  STC_CATEGORY_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STC_CATEGORY}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcCategoryDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryList = createAsyncThunk(
  STC_CATEGORY_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STC_CATEGORY}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcCategoryList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryAll = createAsyncThunk(
  STC_CATEGORY_ALL,
  async (params: PayloadCreatorParams<`${typeof API.STC_CATEGORY_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiOrigin.stcCategoryAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// step
export const stcStep = createAsyncThunk(
  STC_STEP,
  async (params: PayloadCreatorParams<`${typeof API.STC_STEP}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiStep.stcStep(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepCreate = createAsyncThunk(
  STC_STEP_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.STC_STEP}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await apiStep.stcStepCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepDelete = createAsyncThunk(
  STC_STEP_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.STC_STEP}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await apiStep.stcStepDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepList = createAsyncThunk(
  STC_STEP_LIST,
  async (params: PayloadCreatorParams<`${typeof API.STC_STEP}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiStep.stcStepList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepAll = createAsyncThunk(
  STC_STEP_ALL,
  async (params: PayloadCreatorParams<`${typeof API.STC_STEP_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await apiStep.stcStepAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcClear = createAction(`${STC}/clear`)

export const stcListClear = createAction(`${STC_LIST}/clear`)

export const stcAllClear = createAction(`${STC_ALL}/clear`)

export const stcCategoryClear = createAction(`${STC_CATEGORY}/clear`)

export const stcCategoryListClear = createAction(`${STC_CATEGORY_LIST}/clear`)

export const stcStepClear = createAction(`${STC_STEP}/clear`)
