import { DefaultState } from '@src/redux/types'

import {
  INSTITUTION_PAGE_SETTINGS,
  INSTITUTION_PAGE_SETTINGS_ALL,
  INSTITUTION_PAGE_SETTINGS_CREATE,
  INSTITUTION_PAGE_SETTINGS_DELETE,
  INSTITUTION_PAGE_SETTINGS_IDENTITY,
  INSTITUTION_PAGE_SETTINGS_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [INSTITUTION_PAGE_SETTINGS]: DefaultState<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>
  [INSTITUTION_PAGE_SETTINGS_ALL]: DefaultState<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>
  [INSTITUTION_PAGE_SETTINGS_CREATE]: DefaultState<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'post'>
  [INSTITUTION_PAGE_SETTINGS_DELETE]: DefaultState<`${typeof API.INSTITUTION_PAGE_SETTINGS}{id}`, 'delete'>
  [INSTITUTION_PAGE_SETTINGS_IDENTITY]: DefaultState<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>
  [INSTITUTION_PAGE_SETTINGS_LIST]: DefaultState<`${typeof API.INSTITUTION_PAGE_SETTINGS}`, 'get'>
} = () => ({
  [INSTITUTION_PAGE_SETTINGS]: defaultState(),
  [INSTITUTION_PAGE_SETTINGS_ALL]: defaultState(),
  [INSTITUTION_PAGE_SETTINGS_CREATE]: defaultState(),
  [INSTITUTION_PAGE_SETTINGS_DELETE]: defaultState(),
  [INSTITUTION_PAGE_SETTINGS_IDENTITY]: defaultState(),
  [INSTITUTION_PAGE_SETTINGS_LIST]: defaultState(),
})
