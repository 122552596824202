import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  CONTRACT_AGREEMENT_ACCEPT,
  CONTRACT_AGREEMENT_ALL,
  CONTRACT_AGREEMENT_CURRENT,
  CONTRACT_AGREEMENT_OPEN,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const contractAgreementSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const contractAgreementAcceptSelector = createSelector(
  contractAgreementSelectors,
  prop(CONTRACT_AGREEMENT_ACCEPT)
)

export const contractAgreementAllSelector = createSelector(contractAgreementSelectors, prop(CONTRACT_AGREEMENT_ALL))

export const contractAgreementCurrentSelector = createSelector(
  contractAgreementSelectors,
  prop(CONTRACT_AGREEMENT_CURRENT)
)

export const contractAgreementOpenSelector = createSelector(contractAgreementSelectors, prop(CONTRACT_AGREEMENT_OPEN))
