export const ST_COURSE = '/st_course/' as const
export const ST_COURSE_APPLICATION_FORM = `${ST_COURSE}application_form/` as const
export const ST_COURSE_APPLICATION = `${ST_COURSE}application/` as const
export const ST_COURSE_APPLICATION_TYPES = `${ST_COURSE_APPLICATION}types` as const
export const ST_COURSE_APPLICATION_STATUS = `${ST_COURSE_APPLICATION}status/` as const
export const ST_COURSE_APPLICATION_ALL = `${ST_COURSE_APPLICATION_FORM}all` as const
export const ST_COURSE_APPLICATION_FIELD_BATCH = `${ST_COURSE_APPLICATION_FORM}field/batch/` as const

// export const ST_COURSE_APPLICATION_CATEGORY = `${ST_COURSE_APPLICATION_FORM}category/` as const
// export const ST_COURSE_APPLICATION_CATEGORY_ALL = `${ST_COURSE_APPLICATION_CATEGORY}all/` as const

export const ST_COURSE_APPLICATION_REQUEST = ST_COURSE_APPLICATION
export const ST_COURSE_APPLICATION_REQUEST_ALL = `${ST_COURSE_APPLICATION_REQUEST}all` as const
export const ST_COURSE_APPLICATION_REQUEST_EDIT = `${ST_COURSE_APPLICATION_REQUEST}edit` as const
export const ST_COURSE_APPLICATION_REQUEST_UPDATE = `${ST_COURSE_APPLICATION_REQUEST}update` as const

export const ST_COURSE_APPLICATION_SIGNUP = `${ST_COURSE_APPLICATION_FORM}signup` as const
export const ST_COURSE_APPLICATION_SMS_SEND = `${ST_COURSE_APPLICATION_FORM}sms/send` as const
