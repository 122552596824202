// ** React Imports
import classnames from 'classnames'
// ** Third Party Components
import PropTypes from 'prop-types'
import { equals, prop } from 'ramda'
import { Fragment, forwardRef, useEffect, useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'

const InputPasswordField = forwardRef((props, ref) => {
  // ** Props
  const {
    label,
    hideIcon,
    showIcon,
    visible,
    className,
    htmlFor,
    placeholder,
    iconSize,
    inputClassName,
    handleChange = () => {},
    name,
    register,
    error,
    isSubmitted,
    customErrorMessage = false,
    renderInputGroupText = () => {},
    onChange = () => {},
    onUnmount,
    ...rest
  } = props

  const { ref: registeredRef, ...registerRest } = register(name)

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])
  // ** State
  const [inputVisibility, setInputVisibility] = useState(visible)

  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = iconSize ? iconSize : 14

    if (inputVisibility === false) {
      return hideIcon ? hideIcon : <Eye size={size} />
    } else {
      return showIcon ? showIcon : <EyeOff size={size} />
    }
  }

  function change(e) {
    const { value } = e.target
    onChange(value)
    handleChange(value)
  }

  return (
    <Fragment>
      {label ? <Label for={htmlFor}>{label}</Label> : null}
      <InputGroup className={classnames('input-group-merge', { 'is-invalid': error })}>
        {renderInputGroupText()}
        <Input
          {...rest}
          {...registerRest}
          name={name}
          onChange={change}
          type={inputVisibility === false ? 'password' : 'text'}
          placeholder={placeholder ? placeholder : '············'}
          className={classnames({
            [inputClassName]: inputClassName,
          })}
          /*eslint-disable */
          {...(label && htmlFor
            ? {
                id: htmlFor,
              }
            : {})}
          innerRef={registeredRef}
          /*eslint-enable */
        />
        <InputGroupAddon addonType='append' onClick={() => setInputVisibility(!inputVisibility)}>
          <InputGroupText className='cursor-pointer'>{renderIcon()}</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      {error && (
        <FormText color='danger'>
          <FormattedMessage id={equals(prop('message', error), 'range') ? 'number_range' : 'form_required'} />
        </FormText>
      )}
    </Fragment>
  )
})

export default InputPasswordField

// ** PropTypes
InputPasswordField.propTypes = {
  hideIcon: PropTypes.node,
  showIcon: PropTypes.node,
  visible: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  iconSize: PropTypes.number,
  inputClassName: PropTypes.string,
  label(props, propName, componentName) {
    // ** If label is defined and htmlFor is undefined throw error
    if (props[propName] && props['htmlFor'] === 'undefined') {
      throw new Error('htmlFor prop is required when label prop is present')
    }
  },
  htmlFor(props, propName, componentName) {
    // ** If htmlFor is defined and label is undefined throw error
    if (props[propName] && props['label'] === 'undefined') {
      throw new Error('label prop is required when htmlFor prop is present')
    }
  },
}

// ** Default Props
InputPasswordField.defaultProps = {
  visible: false,
}
