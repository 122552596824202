import { DefaultState } from '@src/redux/types'

import { MODULES, MODULES_ALL, MODULES_CREATE, MODULES_DELETE, MODULES_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [MODULES]: DefaultState<`${typeof API.MODULES}{id}`, 'get'>
  [MODULES_ALL]: DefaultState<`${typeof API.MODULES_ALL}`, 'get'>
  [MODULES_CREATE]: DefaultState<`${typeof API.MODULES}`, 'post'>
  [MODULES_DELETE]: DefaultState<`${typeof API.MODULES}{id}`, 'delete'>
  [MODULES_LIST]: DefaultState<`${typeof API.MODULES}`, 'get'>
} = () => ({
  [MODULES]: defaultState(),
  [MODULES_ALL]: defaultState(),
  [MODULES_CREATE]: defaultState(),
  [MODULES_DELETE]: defaultState(),
  [MODULES_LIST]: defaultState(),
})
