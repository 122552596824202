export const USER = '/user/'
export const USER_SETTINGS = `${USER}settings/`
export const USER_INFO = `${USER}me/info`
export const USER_ACTION_LOG = `${USER}action/log`
export const USER_UPDATE_OWN_PASSWORD = `${USER}own/password`
export const SIGN_IN = '/signin'
export const RESET_PASSWORD_REQUEST = '/action_verification_email/reset/password/request'
export const RESET_PASSWORD = '/action_verification_email/reset/password'
export const SIGN_UP = `/signup`
export const SMS_SEND = `/admission/sms/send`
export const VERIFY_EMAIl = `/verify-email`
export const CHECK_GUEST_USER = '/check-guest-user'
export const USER_PROFILE_RESET_PASSWORD = `${USER}profile/password`
export const USER_INFO_HISTORY = `${USER}info_history/`
