// ** Vertical Menu Components
// ** Utils
import { useCheckRoles } from '@hooks'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@layouts/utils'
import { find, prop, propOr } from 'ramda'

import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'

const VerticalMenuNavItems = props => {
  // ** Components Object
  const Components = {
    VerticalNavMenuSectionHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map(item => {
    const TagName = Components[resolveNavItemComponent(item)]
    const { amI, userInfoLoading } = useCheckRoles()

    if (userInfoLoading) {
      return null
    }
    // if (item.children) {
    //   return accessViewMenuGroup(item, getUserInfo()) && <TagName key={item.id || item.header} item={item} {...props} />
    // }
    if (item.children) {
      const accessible = find(
        child => amI(propOr([], 'access', child)) || !prop('access', child),
        propOr([], 'children', item)
      )
      return accessible && <TagName key={item.id || item.header} item={item} {...props} />
    }

    // return accessViewMenuItem(item, getUserInfo()) && <TagName key={item.id || item.header} item={item} {...props} />
    return (
      (amI(propOr([], 'access', item)) || !prop('access', item)) && (
        <TagName key={item.id || item.header} item={item} {...props} />
      )
    )
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
