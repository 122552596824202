import { DefaultState } from '@src/redux/types'

import {
  EDUCATION_PLACE,
  EDUCATION_PLACE_ALL,
  EDUCATION_PLACE_CREATE,
  EDUCATION_PLACE_DELETE,
  EDUCATION_PLACE_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [EDUCATION_PLACE]: DefaultState<`${typeof API.EDUCATION_PLACE}{id}`, 'get'>
  [EDUCATION_PLACE_ALL]: DefaultState<`${typeof API.EDUCATION_PLACE_ALL}`, 'get'>
  [EDUCATION_PLACE_CREATE]: DefaultState<`${typeof API.EDUCATION_PLACE}`, 'post'>
  [EDUCATION_PLACE_DELETE]: DefaultState<`${typeof API.EDUCATION_PLACE}{id}`, 'delete'>
  [EDUCATION_PLACE_LIST]: DefaultState<`${typeof API.EDUCATION_PLACE}`, 'get'>
} = () => ({
  [EDUCATION_PLACE]: defaultState(),
  [EDUCATION_PLACE_ALL]: defaultState(),
  [EDUCATION_PLACE_CREATE]: defaultState(),
  [EDUCATION_PLACE_DELETE]: defaultState(),
  [EDUCATION_PLACE_LIST]: defaultState(),
})
