import { components } from 'react-select'

const SelectContainer = ({ children, ...props }) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: e => {
          e.stopPropagation()
        },
      }}
    >
      {children}
    </components.SelectContainer>
  )
}

export default SelectContainer
