import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, STATE, STATE_ALL, STATE_CREATE, STATE_DELETE, STATE_LIST } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const STATES = GLOBAL_STATE

export const { reducer: stateReducer } = createSlice({
  name: STATES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.state.pending, state => {
        state[STATE].loading = true
      })
      .addCase(actionCreators.state.rejected, (state, action) => {
        state[STATE].loading = false
        state[STATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.state.fulfilled, (state, action) => {
        state[STATE].loading = false
        state[STATE].error = null
        state[STATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stateAll.pending, state => {
        state[STATE_ALL].loading = true
      })
      .addCase(actionCreators.stateAll.rejected, (state, action) => {
        state[STATE_ALL].loading = false
        state[STATE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stateAll.fulfilled, (state, action) => {
        state[STATE_ALL].loading = false
        state[STATE_ALL].error = null
        state[STATE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.stateCreate.pending, state => {
        state[STATE_CREATE].loading = true
      })
      .addCase(actionCreators.stateCreate.rejected, (state, action) => {
        state[STATE_CREATE].loading = false
        state[STATE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stateCreate.fulfilled, (state, action) => {
        state[STATE_CREATE].loading = false
        state[STATE_CREATE].error = null
        state[STATE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.stateDelete.pending, state => {
        state[STATE_DELETE].loading = true
      })
      .addCase(actionCreators.stateDelete.rejected, (state, action) => {
        state[STATE_DELETE].loading = false
        state[STATE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stateDelete.fulfilled, (state, action) => {
        state[STATE_DELETE].loading = false
        state[STATE_DELETE].error = null
        state[STATE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.stateList.pending, state => {
        state[STATE_LIST].loading = true
      })
      .addCase(actionCreators.stateList.rejected, (state, action) => {
        state[STATE_LIST].loading = false
        state[STATE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.stateList.fulfilled, (state, action) => {
        state[STATE_LIST].loading = false
        state[STATE_LIST].error = null
        state[STATE_LIST].data = action.payload
      })

    builder.addCase(actionCreators.stateClear, state => {
      state[STATE] = defaultState()
    })

    builder.addCase(actionCreators.stateAllClear, state => {
      state[STATE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.stateListClear, state => {
      state[STATE_LIST] = defaultState()
    })
  },
})
