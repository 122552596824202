import { path } from 'ramda'
import { createSelector } from 'reselect'
import { GLOBAL_STATE, PLAYER_PIP, PLAYER_STATE } from '../constants/state'

export const playerStateSelector = createSelector(path([GLOBAL_STATE, PLAYER_STATE]), data => data)

export const playerPipSelector = createSelector(path([GLOBAL_STATE, PLAYER_PIP]), data => data)

export const playerSelectors = {
  playerStateSelector,
  playerPipSelector,
}
