import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, MODULES_OUTCOME, MODULES_OUTCOME_CREATE, MODULES_OUTCOME_LIST } from '../constants/actionTypes'

const selectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const modulesOutcomeSelector = createSelector(selectors, prop(MODULES_OUTCOME))

export const modulesOutcomeCreateSelector = createSelector(selectors, prop(MODULES_OUTCOME_CREATE))

export const modulesOutcomeListSelector = createSelector(selectors, prop(MODULES_OUTCOME_LIST))
