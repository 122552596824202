import { ApiFunc, getInstance } from '@helpers/httpClient'
import { API_URL } from '@src/constants/api'
import { getToken } from '@src/helpers/storageToken'
import axios from 'axios'

import * as API from '../constants/api'

const instance = getInstance()

export const guest: ApiFunc<`${typeof API.USER_GUEST}{id}`, 'get'> = data =>
  instance.get(`${API.USER_GUEST}${'id' in data ? data.id : ''}`, {
    params: data,
  })

export const guestList: ApiFunc<`${typeof API.USER_GUEST}`, 'get'> = data =>
  instance.get(API.USER_GUEST, {
    params: data,
  })

export const guestFullTimeList: ApiFunc<`${typeof API.USER_GUEST_FULL_TIME}`, 'get'> = data =>
  instance.get(API.USER_GUEST_FULL_TIME, {
    params: data,
  })

export const guestAll: ApiFunc<`${typeof API.USER_GUEST_ALL}`, 'get'> = data =>
  instance.get(API.USER_GUEST_ALL, {
    params: data,
  })

export const guestAvailabilityDocuments: ApiFunc<`${typeof API.USER_GUEST_AVAILABILITY_DOCUMENTS}`, 'get'> = data =>
  instance.get(API.USER_GUEST_AVAILABILITY_DOCUMENTS, {
    params: data,
  })

export const guestAvailabilityDocumentsUpdate: ApiFunc<`${typeof API.USER_GUEST_AVAILABILITY_DOCUMENTS}`, 'put'> = (
  data,
  config
) => instance.put(API.USER_GUEST_AVAILABILITY_DOCUMENTS, data, config)

export const guestProfile: ApiFunc<`${typeof API.USER_GUEST_PROFILE}`, 'get'> = data =>
  instance.get(API.USER_GUEST_PROFILE, {
    params: data,
  })

export const guestProfileUpdate: ApiFunc<`${typeof API.USER_GUEST_PROFILE}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_PROFILE, data, config)

export const voucherCreate: ApiFunc<`${typeof API.USER_GUEST_VOUCHER}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_VOUCHER, data, config)

export const guestReferenceCreate: ApiFunc<`${typeof API.USER_GUEST_REFERENCE}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_REFERENCE, data, config)

export const guestInvoiceCreate: ApiFunc<`${typeof API.USER_GUEST_INVOICE}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_INVOICE, data, config)

export const guestChangeEduLang: ApiFunc<`${typeof API.USER_GUEST_CHANGE_EDU_LANG}`, 'post'> = data =>
  instance.post(API.USER_GUEST_CHANGE_EDU_LANG, data)

export const guestChangeStatus: ApiFunc<`${typeof API.USER_GUEST_CHANGE_STATUS}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_CHANGE_STATUS, data, config)

export const guestResetExam: ApiFunc<`${typeof API.USER_GUEST_RESET_EXAM}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_RESET_EXAM, data, config)

export const guestResetCourse: ApiFunc<`${typeof API.USER_GUEST_RESET_COURSE}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_RESET_COURSE, data, config)

export const guestSubjectCert: ApiFunc<`${typeof API.USER_GUEST_SUBJECT_CERT}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_SUBJECT_CERT, data, config)

export const guestAppointmentScore: ApiFunc<`${typeof API.USER_GUEST_APPOINTMENT_SCORE}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_APPOINTMENT_SCORE, data, config)

export const guestDelete: ApiFunc<`${typeof API.USER_GUEST}{ids}`, 'delete'> = data =>
  instance.delete(`${API.USER_GUEST}${'ids' in data ? data.ids : ''}`)

export const guestAppointmentScoreImport: ApiFunc<
  `${typeof API.USER_GUEST_APPOINTMENT_SCORE_IMPORT}`,
  'post'
> = data => {
  return axios.request({
    method: 'POST',
    url: `${API_URL}${API.USER_GUEST_APPOINTMENT_SCORE_IMPORT}`,
    data: (data as unknown as { formData: FormData }).formData,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      institution: '1',
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const guestChangeStatusTranscript: ApiFunc<`${typeof API.USER_GUEST_CHANGE_STATUS_TRANSCRIPT}`, 'put'> = (
  data,
  config
) => instance.put(API.USER_GUEST_CHANGE_STATUS_TRANSCRIPT, data, config)

export const guestTeachingExperience: ApiFunc<`${typeof API.USER_GUEST_TEACHING_EXPERIENCE}`, 'put'> = (data, config) =>
  instance.put(API.USER_GUEST_TEACHING_EXPERIENCE, data, config)

export const guestAcceptSendContract: ApiFunc<`${typeof API.USER_GUEST_ACCEPT_SEND_CONTRACT}`, 'put'> = (
  data,
  config
) => instance.put(API.USER_GUEST_ACCEPT_SEND_CONTRACT, data, config)
