import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const modules: ApiFunc<`${typeof API.MODULES}{id}`, 'get'> = data =>
  instance.get(`${API.MODULES}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const modulesCreate: ApiFunc<`${typeof API.MODULES}`, 'post'> = data => instance.post(API.MODULES, data)

export const modulesDelete: ApiFunc<`${typeof API.MODULES}{id}`, 'delete'> = data =>
  instance.delete(`${API.MODULES}${'id' in data ? prop('id', data) : data?.name}`)

export const modulesList: ApiFunc<`${typeof API.MODULES}`, 'get'> = data =>
  instance.get(API.MODULES, {
    params: data,
  })

export const modulesAll: ApiFunc<`${typeof API.MODULES_ALL}`, 'get'> = data =>
  instance.get(API.MODULES_ALL, {
    params: data,
  })
