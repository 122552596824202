import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { prop, propOr } from 'ramda'

import * as actionTypes from '../constants/actionTypes'
import { INTERNAL_EXAM_STATE } from './reducer'

const internalExamGlobalSelector = (state: RootState) => prop(INTERNAL_EXAM_STATE, state)

export const internalExamSelector = createSelector(internalExamGlobalSelector, prop(actionTypes.INTERNAL_EXAM))
export const internalExamAllSelector = createSelector(internalExamGlobalSelector, prop(actionTypes.INTERNAL_EXAM_ALL))
export const internalExamAllOptionsSelector = createSelector(
  internalExamGlobalSelector,
  internalExamAllSelector,
  (_, selected) => {
    return {
      data: Array.isArray(selected?.data)
        ? selected.data.map(item => ({
            value: item.id,
            label: `${propOr('', 'date', item)}, ${propOr('', 'time', item)} (${propOr(
              '',
              'current_capacity',
              item
            )}/${propOr('', 'max_capacity', item)})`,
          }))
        : null,
      loading: selected.loading,
    }
  }
)
export const internalExamListSelector = createSelector(internalExamGlobalSelector, prop(actionTypes.INTERNAL_EXAM_LIST))
export const internalExamCreateSelector = createSelector(
  internalExamGlobalSelector,
  prop(actionTypes.INTERNAL_EXAM_CREATE)
)

// export const internalExamPlaceSelector = createSelector(
//   internalExamGlobalSelector,
//   prop(actionTypes.INTERNAL_EXAM_PLACE)
// )
// export const internalExamPlaceAllSelector = createSelector(
//   internalExamGlobalSelector,
//   prop(actionTypes.INTERNAL_EXAM_PLACE_ALL)
// )
// export const internalExamPlaceListSelector = createSelector(
//   internalExamGlobalSelector,
//   prop(actionTypes.INTERNAL_EXAM_PLACE_LIST)
// )
// export const internalExamPlaceCreateSelector = createSelector(
//   internalExamGlobalSelector,
//   prop(actionTypes.INTERNAL_EXAM_PLACE_CREATE)
// )
