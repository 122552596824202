import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const report: ApiFunc<`${typeof API.REPORT}{id}`, 'get'> = data =>
  instance.get(`${API.REPORT}${'id' in data ? data.id : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const reportCreate: ApiFunc<`${typeof API.REPORT}`, 'post'> = data => instance.post(API.REPORT, data)

export const reportMisconductListCreate: ApiFunc<`${typeof API.REPORT_MISCONDUCT_LIST}`, 'post'> = data =>
  instance.post(API.REPORT_MISCONDUCT_LIST, data)

// export const reportAll: ApiFunc<`${typeof API.REPORT_ALL}`, 'get'> = data => instance.get(API.REPORT_ALL, { params: data })

export const reportList: ApiFunc<`${typeof API.REPORT_LIST}`, 'get'> = data =>
  instance.get(API.REPORT_LIST, { params: data })

export const reportFields: ApiFunc<`${typeof API.REPORT_FIELDS}`, 'get'> = data =>
  instance.get(API.REPORT_FIELDS, { params: data })

export const reportFieldsFilter: ApiFunc<`${typeof API.REPORT_FIELDS_FILTER}`, 'get'> = data =>
  instance.get(API.REPORT_FIELDS_FILTER, { params: data })
