import { DefaultState } from '@src/redux/types'

import {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
  ATTENDANCE_TABLE_STUDENT,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [ATTENDANCE]: DefaultState<`${typeof API.ATTENDANCE}`, 'post'>
  [ATTENDANCE_CSV]: DefaultState<`${typeof API.ATTENDANCE_CSV}`, 'post'>
  [ATTENDANCE_GROUP]: DefaultState<`${typeof API.ATTENDANCE_GROUP}`, 'get'>
  [ATTENDANCE_TABLE]: DefaultState<`${typeof API.ATTENDANCE_TABLE}`, 'get'>
  [ATTENDANCE_TABLE_MODULE]: DefaultState<`${typeof API.ATTENDANCE_TABLE_MODULE}`, 'get'>
  [ATTENDANCE_TABLE_STUDENT]: DefaultState<`${typeof API.ATTENDANCE_TABLE_STUDENT}`, 'get'>
} = () => ({
  [ATTENDANCE]: defaultState(),
  [ATTENDANCE_CSV]: defaultState(),
  [ATTENDANCE_GROUP]: defaultState(),
  [ATTENDANCE_TABLE]: defaultState(),
  [ATTENDANCE_TABLE_MODULE]: defaultState(),
  [ATTENDANCE_TABLE_STUDENT]: defaultState(),
})
