import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
  ATTENDANCE_TABLE_STUDENT,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const attendanceCreate = createAsyncThunk(
  ATTENDANCE,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.attendance(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceGroup = createAsyncThunk(
  ATTENDANCE_GROUP,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_GROUP}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceGroup(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceTable = createAsyncThunk(
  ATTENDANCE_TABLE,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_TABLE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceTable(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceTableStudent = createAsyncThunk(
  ATTENDANCE_TABLE_STUDENT,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_TABLE_STUDENT}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceTableStudent(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceTableCsv = createAsyncThunk(
  ATTENDANCE_CSV,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_CSV}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceCsv(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceTableModule = createAsyncThunk(
  ATTENDANCE_TABLE_MODULE,
  async (params: PayloadCreatorParams<`${typeof API.ATTENDANCE_TABLE_MODULE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.attendanceTableModule(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const attendanceGroupClear = createAction(`${ATTENDANCE_GROUP}/clear`)

export const attendanceTableStudentClear = createAction(`${ATTENDANCE_TABLE_STUDENT}/clear`)

export const attendanceTableClear = createAction(`${ATTENDANCE_TABLE}/clear`)

export const attendanceTableModuleClear = createAction(`${ATTENDANCE_TABLE_MODULE}/clear`)
