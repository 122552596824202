import { dissoc, prop } from 'ramda'
import { ApiFunc, getInstance } from '@helpers/httpClient'
import * as API from '../constants/api'

const instance = getInstance()

export const department: ApiFunc<`${typeof API.DEPARTMENT}{id}`, 'get'> = data =>
  instance.get(`${API.DEPARTMENT}${'id' in data ? prop('id', data) : data.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const departmentCreate: ApiFunc<`${typeof API.DEPARTMENT}`, 'post'> = data => instance.post(API.DEPARTMENT, data)

export const departmentDelete: ApiFunc<`${typeof API.DEPARTMENT}{id}`, 'delete'> = data => instance.delete(`${API.DEPARTMENT}${'id' in data ? prop('id', data) : data.name}`)

export const departmentList: ApiFunc<`${typeof API.DEPARTMENT}`, 'get'> = data =>
  instance.get(API.DEPARTMENT, {
    params: data,
  })

export const departmentAll: ApiFunc<`${typeof API.DEPARTMENT_ALL}`, 'get'> = data =>
  instance.get(API.DEPARTMENT_ALL, {
    params: data,
  })

export const departmentLabels: ApiFunc<`${typeof API.DEPARTMENT_LABELS}`, 'get'> = data =>
  instance.get(API.DEPARTMENT_LABELS, {
    params: data,
  })
