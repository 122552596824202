export const GLOBAL_STATE = 'HRAdmission'

export const HR_APPLICATION = 'HR_APPLICATION' as const
export const HR_APPLICATIONS = 'HR_APPLICATIONS' as const
export const HR_APPLICATION_CREATE = 'HR_APPLICATION_CREATE' as const
export const HR_APPLICATION_DELETE = 'HR_APPLICATION_DELETE' as const
export const HR_APPLICATION_LIST = 'HR_APPLICATION_LIST' as const
export const HR_APPLICATION_ALL = 'HR_APPLICATION_ALL' as const
export const HR_APPLICATION_TYPES = 'HR_APPLICATION_TYPES' as const
export const HR_APPLICATION_STATUS = 'HR_APPLICATION_STATUS' as const

export const HR_APPLICATION_FIELD_BATCH = 'HR_APPLICATION_FIELD_BATCH' as const
export const HR_APPLICATION_FIELD_BATCH_CREATE = 'HR_APPLICATION_FIELD_BATCH_CREATE' as const

// category
// export const HR_APPLICATION_CATEGORY = 'HR_APPLICATION_CATEGORY' as const
// export const HR_APPLICATION_CATEGORY_CREATE = 'HR_APPLICATION_CATEGORY_CREATE' as const
// export const HR_APPLICATION_CATEGORY_DELETE = 'HR_APPLICATION_CATEGORY_DELETE' as const
// export const HR_APPLICATION_CATEGORY_LIST = 'HR_APPLICATION_CATEGORY_LIST' as const
// export const HR_APPLICATION_CATEGORY_ALL = 'HR_APPLICATION_CATEGORY_ALL' as const

// request
export const HR_APPLICATION_REQUEST_CREATE = 'HR_APPLICATION_REQUEST_CREATE' as const
export const HR_APPLICATION_REQUEST_ALL = 'HR_APPLICATION_REQUEST_ALL' as const
export const HR_APPLICATION_REQUEST_EDIT = 'HR_APPLICATION_REQUEST_EDIT' as const
export const HR_APPLICATION_REQUEST_UPDATE = 'HR_APPLICATION_REQUEST_UPDATE' as const
