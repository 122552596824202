import { lazy } from 'react'
import {
  SHORT_TERM_COURSE_CATEGORY_LIST,
  SHORT_TERM_COURSE_LIST,
  SHORT_TERM_COURSE_CHAPTER,
  SHORT_TERM_COURSE_STUDENT,
  SHORT_TERM_COURSE_STUDENT_INTRODUCTION,
  SHORT_TERM_COURSE_STUDENT_CHAPTER,
  SHTC_LISTENER,
  SHTC_LISTENER_LIST,
} from '../constants/route'
import * as ROLES from '@constants/userRoles'
import { SHORT_TERM_COURSE_LISTENER } from '@constants/userRoles'

export const ShortTermCourseRoutes = [
  {
    exact: true,
    path: SHORT_TERM_COURSE_CATEGORY_LIST,
    component: lazy(() => import('../views/CategoryList')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
  {
    exact: true,
    path: SHORT_TERM_COURSE_LIST,
    component: lazy(() => import('../views/CourseList')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
  {
    exact: true,
    path: SHORT_TERM_COURSE_CHAPTER,
    component: lazy(() => import('../views/CourseChapter')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
  {
    exact: true,
    path: SHORT_TERM_COURSE_STUDENT,
    component: lazy(() => import('../views/Student')),
    meta: {
      access: [ROLES.SUPER_ADMIN, ROLES.SHORT_TERM_COURSE_LISTENER],
    },
  },
  {
    exact: true,
    path: SHORT_TERM_COURSE_STUDENT_INTRODUCTION,
    component: lazy(() => import('../views/StudentIntroduction')),
    meta: {
      access: [ROLES.SUPER_ADMIN, ROLES.SHORT_TERM_COURSE_LISTENER],
    },
  },
  {
    exact: true,
    path: SHORT_TERM_COURSE_STUDENT_CHAPTER,
    component: lazy(() => import('../views/StudentChapter')),
    meta: {
      access: [ROLES.SUPER_ADMIN, ROLES.SHORT_TERM_COURSE_LISTENER],
    },
  },
  {
    exact: true,
    path: SHTC_LISTENER,
    component: lazy(() => import('../views/Listener')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
  {
    exact: true,
    path: SHTC_LISTENER_LIST,
    component: lazy(() => import('../views/ListenerList')),
    meta: {
      access: [ROLES.SUPER_ADMIN],
    },
  },
]
