import { DefaultState } from '@src/redux/types'

import {
  CONTRACT_AGREEMENT_ACCEPT,
  CONTRACT_AGREEMENT_ALL,
  CONTRACT_AGREEMENT_CURRENT,
  CONTRACT_AGREEMENT_OPEN,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [CONTRACT_AGREEMENT_ALL]: DefaultState<`${typeof API.CONTRACT_AGREEMENT_ALL}`, 'get'>
  [CONTRACT_AGREEMENT_CURRENT]: DefaultState<`${typeof API.CONTRACT_AGREEMENT_CURRENT}`, 'get'>
  [CONTRACT_AGREEMENT_OPEN]: DefaultState<`${typeof API.CONTRACT_AGREEMENT_OPEN}`, 'get'>
  [CONTRACT_AGREEMENT_ACCEPT]: DefaultState<`${typeof API.CONTRACT_AGREEMENT_ACCEPT}`, 'post'>
} = () => ({
  [CONTRACT_AGREEMENT_ALL]: defaultState(),
  [CONTRACT_AGREEMENT_CURRENT]: defaultState(),
  [CONTRACT_AGREEMENT_OPEN]: defaultState(),
  [CONTRACT_AGREEMENT_ACCEPT]: defaultState(),
})
