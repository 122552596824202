import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const MODULE_FILES = GLOBAL_STATE

export const { reducer: moduleFileReducer } = createSlice({
  name: MODULE_FILES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.moduleFile.pending, state => {
        state[MODULE_FILE].loading = true
      })
      .addCase(actionCreators.moduleFile.rejected, (state, action) => {
        state[MODULE_FILE].loading = false
        state[MODULE_FILE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.moduleFile.fulfilled, (state, action) => {
        state[MODULE_FILE].loading = false
        state[MODULE_FILE].error = null
        state[MODULE_FILE].data = action.payload
      })

    builder
      .addCase(actionCreators.moduleFileAll.pending, state => {
        state[MODULE_FILE_ALL].loading = true
      })
      .addCase(actionCreators.moduleFileAll.rejected, (state, action) => {
        state[MODULE_FILE_ALL].loading = false
        state[MODULE_FILE_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.moduleFileAll.fulfilled, (state, action) => {
        state[MODULE_FILE_ALL].loading = false
        state[MODULE_FILE_ALL].error = null
        state[MODULE_FILE_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.moduleFileCreate.pending, state => {
        state[MODULE_FILE_CREATE].loading = true
      })
      .addCase(actionCreators.moduleFileCreate.rejected, (state, action) => {
        state[MODULE_FILE_CREATE].loading = false
        state[MODULE_FILE_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.moduleFileCreate.fulfilled, (state, action) => {
        state[MODULE_FILE_CREATE].loading = false
        state[MODULE_FILE_CREATE].error = null
        state[MODULE_FILE_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.moduleFileDelete.pending, state => {
        state[MODULE_FILE_DELETE].loading = true
      })
      .addCase(actionCreators.moduleFileDelete.rejected, (state, action) => {
        state[MODULE_FILE_DELETE].loading = false
        state[MODULE_FILE_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.moduleFileDelete.fulfilled, (state, action) => {
        state[MODULE_FILE_DELETE].loading = false
        state[MODULE_FILE_DELETE].error = null
        state[MODULE_FILE_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.moduleFileList.pending, state => {
        state[MODULE_FILE_LIST].loading = true
      })
      .addCase(actionCreators.moduleFileList.rejected, (state, action) => {
        state[MODULE_FILE_LIST].loading = false
        state[MODULE_FILE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.moduleFileList.fulfilled, (state, action) => {
        state[MODULE_FILE_LIST].loading = false
        state[MODULE_FILE_LIST].error = null
        state[MODULE_FILE_LIST].data = action.payload
      })

    builder.addCase(actionCreators.moduleFileAllClear, state => {
      state[MODULE_FILE_ALL] = defaultState()
    })

    builder.addCase(actionCreators.moduleFileListClear, state => {
      state[MODULE_FILE_LIST] = defaultState()
    })
  },
})
