import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import { MODULES_OUTCOME, MODULES_OUTCOME_CREATE, MODULES_OUTCOME_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const modulesOutcome = createAsyncThunk(
  MODULES_OUTCOME,
  async (params: PayloadCreatorParams<`${typeof API.MODULES_OUTCOME}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesOutcome(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesOutcomeCreate = createAsyncThunk(
  MODULES_OUTCOME_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.MODULES_OUTCOME_LIST}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesOutcomeCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesOutcomeList = createAsyncThunk(
  MODULES_OUTCOME_LIST,
  async (params: PayloadCreatorParams<`${typeof API.MODULES_OUTCOME_LIST}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.modulesOutcomeList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const modulesOutcomeClear = createAction(`${MODULES_OUTCOME}/clear`)

export const modulesOutcomeListClear = createAction(`${MODULES_OUTCOME_LIST}/clear`)
