const GENERAL = '/general/' as const
export const GENERAL_APPLICATION_FORM = `${GENERAL}application_form/` as const
export const GENERAL_APPLICATION = `${GENERAL}application/` as const
export const GENERAL_APPLICATION_TYPES = `${GENERAL_APPLICATION}types` as const
export const GENERAL_APPLICATION_STATUS = `${GENERAL_APPLICATION}status/` as const
export const GENERAL_APPLICATION_ALL = `${GENERAL_APPLICATION_FORM}all` as const
export const GENERAL_APPLICATION_FIELD_BATCH = `${GENERAL_APPLICATION_FORM}field/batch/` as const

// export const GENERAL_APPLICATION_CATEGORY = `${GENERAL_APPLICATION_FORM}category/` as const
// export const GENERAL_APPLICATION_CATEGORY_ALL = `${GENERAL_APPLICATION_CATEGORY}all/` as const

export const GENERAL_APPLICATION_REQUEST = GENERAL_APPLICATION
export const GENERAL_APPLICATION_REQUEST_ALL = `${GENERAL_APPLICATION_REQUEST}all` as const
export const GENERAL_APPLICATION_REQUEST_EDIT = `${GENERAL_APPLICATION_REQUEST}edit` as const
export const GENERAL_APPLICATION_REQUEST_UPDATE = `${GENERAL_APPLICATION_REQUEST}update` as const

export const GENERAL_APPLICATION_SIGNUP = `${GENERAL_APPLICATION_FORM}signup` as const
export const GENERAL_APPLICATION_SMS_SEND = `${GENERAL_APPLICATION_FORM}sms/send` as const
