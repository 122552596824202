import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  ST_COURSE_APPLICATION,
  ST_COURSE_APPLICATION_ALL,
  ST_COURSE_APPLICATION_CREATE,
  ST_COURSE_APPLICATION_DELETE,
  ST_COURSE_APPLICATION_FIELD_BATCH,
  ST_COURSE_APPLICATION_FIELD_BATCH_CREATE,
  ST_COURSE_APPLICATION_LIST,
  ST_COURSE_APPLICATION_REQUEST,
  ST_COURSE_APPLICATION_REQUEST_ALL,
  ST_COURSE_APPLICATION_REQUEST_EDIT,
  ST_COURSE_APPLICATION_REQUEST_UPDATE,
  ST_COURSE_APPLICATION_STATUS,
  ST_COURSE_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const stCourseApplication = createAsyncThunk(
  ST_COURSE_APPLICATION,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_FORM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplication(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationCreate = createAsyncThunk(
  ST_COURSE_APPLICATION_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_FORM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplicationCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationDelete = createAsyncThunk(
  ST_COURSE_APPLICATION_DELETE,
  async (
    params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_FORM}{id}`, 'delete'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.stCourseApplicationDelete(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationList = createAsyncThunk(
  ST_COURSE_APPLICATION_LIST,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplicationList(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationAll = createAsyncThunk(
  ST_COURSE_APPLICATION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplicationAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationTypes = createAsyncThunk(
  ST_COURSE_APPLICATION_TYPES,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_TYPES}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplicationTypes(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationChangeStatus = createAsyncThunk(
  ST_COURSE_APPLICATION_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplicationStatus(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationFieldBatch = createAsyncThunk(
  ST_COURSE_APPLICATION_FIELD_BATCH,
  async (
    params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_FIELD_BATCH}{id}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.stCourseApplicationFieldBatch(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationFieldBatchCreate = createAsyncThunk(
  ST_COURSE_APPLICATION_FIELD_BATCH_CREATE,
  async (
    params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_FIELD_BATCH}`, 'post'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.stCourseApplicationFieldBatchCreate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationRequest = createAsyncThunk(
  ST_COURSE_APPLICATION_REQUEST,
  async (params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_REQUEST}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.stCourseApplicationRequest(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationRequestAll = createAsyncThunk(
  ST_COURSE_APPLICATION_REQUEST_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_REQUEST_ALL}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.stCourseApplicationRequestAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationRequestEdit = createAsyncThunk(
  ST_COURSE_APPLICATION_REQUEST_EDIT,
  async (
    params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_REQUEST_EDIT}`, 'get'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.stCourseApplicationRequestEdit(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationRequestUpdate = createAsyncThunk(
  ST_COURSE_APPLICATION_REQUEST_UPDATE,
  async (
    params: PayloadCreatorParams<`${typeof API.ST_COURSE_APPLICATION_REQUEST_UPDATE}`, 'put'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.stCourseApplicationRequestUpdate(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stCourseApplicationClear = createAction(`${ST_COURSE_APPLICATION}/clear`)

export const stCourseApplicationListClear = createAction(`${ST_COURSE_APPLICATION_LIST}/clear`)

export const stCourseApplicationAllClear = createAction(`${ST_COURSE_APPLICATION_ALL}/clear`)

export const stCourseApplicationTypesClear = createAction(`${ST_COURSE_APPLICATION_TYPES}/clear`)

export const stCourseApplicationFieldBatchClear = createAction(`${ST_COURSE_APPLICATION_FIELD_BATCH}/clear`)

export const stCourseApplicationRequestEditClear = createAction(`${ST_COURSE_APPLICATION_REQUEST_EDIT}/clear`)

export const stCourseApplicationRequestUpdateClear = createAction(`${ST_COURSE_APPLICATION_REQUEST_UPDATE}/clear`)
