import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const stcStep: ApiFunc<`${typeof API.STC_STEP}{id}`, 'get'> = data =>
  instance.get(`${API.STC_STEP}${'id' in data ? data.id : ''}`)

export const stcStepAll: ApiFunc<`${typeof API.STC_STEP_ALL}`, 'get'> = data =>
  instance.get(API.STC_STEP_ALL, {
    params: data,
  })

export const stcStepList: ApiFunc<`${typeof API.STC_STEP}`, 'get'> = data =>
  instance.get(API.STC_STEP, {
    params: data,
  })

export const stcStepCreate: ApiFunc<`${typeof API.STC_STEP}`, 'post'> = data => instance.post(API.STC_STEP, data)

export const stcStepDelete: ApiFunc<`${typeof API.STC_STEP}{id}`, 'delete'> = data =>
  instance.delete(`${API.STC_STEP}${'id' in data ? data.id : data?.name}`)

// export const stcStepComplete: ApiFunc<`${typeof API.STC_STEP_COMPLETE}`, 'post'> = data =>
//   instance.post(API.STC_STEP_COMPLETE, data)
