import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../../constants/level/api'

const instance = getInstance()

// export const level: ApiFunc<`${typeof API.LEVEL}{id}`, 'get'> = data =>
//   instance.get(`${API.LEVEL}${prop('id', data)}`, {
//     params: dissoc('id', data),
//   })

// export const levelCreate: ApiFunc<`${typeof API.LEVEL}`, 'get'> = data => instance.post(API.LEVEL, data)

// export const levelDelete: ApiFunc<`${typeof API.LEVEL}`, 'get'> = data => instance.delete(`${API.LEVEL}${prop('id', data)}`)

// export const levelList: ApiFunc<`${typeof API.LEVEL}`, 'get'> = data =>
//   instance.get(API.LEVEL, {
//     params: data,
//   })

export const levelAll: ApiFunc<`${typeof API.LEVEL_ALL}`, 'get'> = data =>
  instance.get(API.LEVEL_ALL, {
    params: data,
  })
