import { STORAGE_LANGUAGE, STORAGE_LANGUAGE_OPTION } from '@constants/storage'

const DEFAULT_LANGUAGE = 'uz'
const DEFAULT_LANGUAGE_OPTION = { label: 'Uz' as const, value: 'uz' as const }

export const getLanguage = () => {
  const availableLangs: Record<string, 'en' | 'uz' | 'ru'> = {
    en: 'en',
    uz: 'uz',
    ru: 'ru',
  }
  const storageLanguage = localStorage.getItem(STORAGE_LANGUAGE)

  return storageLanguage ? availableLangs[storageLanguage] : DEFAULT_LANGUAGE
}

export const getLanguageOption = (): { label: string; value: string } | null => {
  try {
    const languageOption = localStorage.getItem(STORAGE_LANGUAGE_OPTION)
    if (languageOption) {
      return JSON.parse(languageOption)
    }
  } catch (e) {
    console.error('Could retrieve selected locale: ', e)
  }
  return DEFAULT_LANGUAGE_OPTION
}

export const setLanguageOption = (option: string) => {
  localStorage.setItem(STORAGE_LANGUAGE_OPTION, option)
}

export const removeLanguageOption = () => {
  localStorage.removeItem(STORAGE_LANGUAGE_OPTION)
}

export const setLanguage = (lang: 'en' | 'ru' | 'uz') => {
  localStorage.setItem(STORAGE_LANGUAGE, lang)
}

export const removeLanguage = () => {
  localStorage.removeItem(STORAGE_LANGUAGE)
}
