import { DefaultState } from '@src/redux/types'

import { SHORT_COURSE_REGISTRATION_CREATE } from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [SHORT_COURSE_REGISTRATION_CREATE]: DefaultState<`${typeof API.SHORT_COURSE_REGISTRATION}`, 'post'>
} = () => ({
  [SHORT_COURSE_REGISTRATION_CREATE]: defaultState(),
})
