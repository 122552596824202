import { lazy } from 'react'
import { DEPARTMENTS } from '../constants/route'
import * as roles from '@constants/userRoles'

export const DepartmentsRoutes = [
  {
    path: DEPARTMENTS,
    component: lazy(() => import('../views/Departments')),
    meta: {
      access: [
        roles.SUPER_ADMIN,
        roles.ADMIN,
        roles.REGISTRAR_HEAD,
        roles.REGISTRAR_OFFICER,
        roles.REGISTRAR_HELPER,
        roles.HR_HEAD,
        roles.HR_MANAGER,
      ],
    },
  },
]
