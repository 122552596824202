import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  MODULES,
  MODULES_ALL,
  MODULES_CREATE,
  MODULES_DELETE,
  MODULES_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const MODULE = GLOBAL_STATE

export const { reducer: modulesReducer } = createSlice({
  name: MODULE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.modules.pending, state => {
        state[MODULES].loading = true
      })
      .addCase(actionCreators.modules.rejected, (state, action) => {
        state[MODULES].loading = false
        state[MODULES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modules.fulfilled, (state, action) => {
        state[MODULES].loading = false
        state[MODULES].error = null
        state[MODULES].data = action.payload
      })

    builder
      .addCase(actionCreators.modulesAll.pending, state => {
        state[MODULES_ALL].loading = true
      })
      .addCase(actionCreators.modulesAll.rejected, (state, action) => {
        state[MODULES_ALL].loading = false
        state[MODULES_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesAll.fulfilled, (state, action) => {
        state[MODULES_ALL].loading = false
        state[MODULES_ALL].error = null
        state[MODULES_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.modulesCreate.pending, state => {
        state[MODULES_CREATE].loading = true
      })
      .addCase(actionCreators.modulesCreate.rejected, (state, action) => {
        state[MODULES_CREATE].loading = false
        state[MODULES_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesCreate.fulfilled, (state, action) => {
        state[MODULES_CREATE].loading = false
        state[MODULES_CREATE].error = null
        state[MODULES_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.modulesDelete.pending, state => {
        state[MODULES_DELETE].loading = true
      })
      .addCase(actionCreators.modulesDelete.rejected, (state, action) => {
        state[MODULES_DELETE].loading = false
        state[MODULES_DELETE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesDelete.fulfilled, (state, action) => {
        state[MODULES_DELETE].loading = false
        state[MODULES_DELETE].error = null
        state[MODULES_DELETE].data = action.payload
      })

    builder
      .addCase(actionCreators.modulesList.pending, state => {
        state[MODULES_LIST].loading = true
      })
      .addCase(actionCreators.modulesList.rejected, (state, action) => {
        state[MODULES_LIST].loading = false
        state[MODULES_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.modulesList.fulfilled, (state, action) => {
        state[MODULES_LIST].loading = false
        state[MODULES_LIST].error = null
        state[MODULES_LIST].data = action.payload
      })

    builder.addCase(actionCreators.modulesClear, state => {
      state[MODULES] = defaultState()
    })

    builder.addCase(actionCreators.modulesAllClear, state => {
      state[MODULES_ALL] = defaultState()
    })

    builder.addCase(actionCreators.modulesListClear, state => {
      state[MODULES_LIST] = defaultState()
    })
  },
})
