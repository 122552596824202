import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const stCourseApplication: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_FORM}{id}`, 'get'> = data =>
  instance.get(`${API.ST_COURSE_APPLICATION_FORM}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

export const stCourseApplicationCreate: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_FORM}`, 'post'> = data =>
  instance.post(API.ST_COURSE_APPLICATION_FORM, data)

export const stCourseApplicationDelete: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_FORM}{id}`, 'delete'> = data =>
  instance.delete(`${API.ST_COURSE_APPLICATION_FORM}${'id' in data ? prop('id', data) : data?.name}`)

export const stCourseApplicationList: ApiFunc<`${typeof API.ST_COURSE_APPLICATION}`, 'get'> = data =>
  instance.get(API.ST_COURSE_APPLICATION, {
    params: data,
  })

export const stCourseApplicationAll: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_ALL}`, 'get'> = data =>
  instance.get(API.ST_COURSE_APPLICATION_ALL, {
    params: data,
  })

export const stCourseApplicationTypes: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_TYPES}`, 'get'> = data =>
  instance.get(API.ST_COURSE_APPLICATION_TYPES, {
    params: data,
  })

export const stCourseApplicationStatus: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_STATUS}`, 'put'> = data =>
  instance.put(API.ST_COURSE_APPLICATION_STATUS, data)

export const stCourseApplicationFieldBatchCreate: ApiFunc<
  `${typeof API.ST_COURSE_APPLICATION_FIELD_BATCH}`,
  'post'
> = data => instance.post(API.ST_COURSE_APPLICATION_FIELD_BATCH, data)

export const stCourseApplicationFieldBatch: ApiFunc<
  `${typeof API.ST_COURSE_APPLICATION_FIELD_BATCH}{id}`,
  'get'
> = data =>
  instance.get(`${API.ST_COURSE_APPLICATION_FIELD_BATCH}${'id' in data ? prop('id', data) : data?.name}`, {
    params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
  })

//category
// export const stCourseApplicationCategory: ApiFunc<`${typeof API.ST_COURSE_APPLICATION}`, 'get'> = data =>
//   instance.get(`${API.ST_COURSE_APPLICATION_CATEGORY}${'id' in data ? prop('id', data) : data?.name}`, {
//     params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
//   })

// export const stCourseApplicationCategoryCreate: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_CATEGORY}`, 'get'> = data =>
//   instance.post(API.ST_COURSE_APPLICATION_CATEGORY, data)

// export const stCourseApplicationCategoryDelete: ApiFunc<`${typeof API.ST_COURSE_APPLICATION}`, 'get'> = data =>
//   instance.delete(`${API.ST_COURSE_APPLICATION_CATEGORY}${'id' in data ? prop('id', data) : data?.name}`)

// export const stCourseApplicationCategoryList: ApiFunc<`${typeof API.ST_COURSE_APPLICATION}`, 'get'> = data =>
//   instance.get(API.ST_COURSE_APPLICATION_CATEGORY, {
//     params: data,
//   })

// export const stCourseApplicationCategoryAll: ApiFunc<`${typeof API.ST_COURSE_APPLICATION}`, 'get'> = data =>
//   instance.get(API.ST_COURSE_APPLICATION_CATEGORY_ALL, {
//     params: data,
//   })

//request
export const stCourseApplicationRequest: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_REQUEST}`, 'post'> = data =>
  instance.post(API.ST_COURSE_APPLICATION_REQUEST, data)

export const stCourseApplicationRequestAll: ApiFunc<`${typeof API.ST_COURSE_APPLICATION_REQUEST_ALL}`, 'get'> = data =>
  instance.get(API.ST_COURSE_APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const stCourseApplicationRequestEdit: ApiFunc<
  `${typeof API.ST_COURSE_APPLICATION_REQUEST_EDIT}`,
  'get'
> = data =>
  instance.get(API.ST_COURSE_APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const stCourseApplicationRequestUpdate: ApiFunc<
  `${typeof API.ST_COURSE_APPLICATION_REQUEST_UPDATE}`,
  'put'
> = data => instance.put(API.ST_COURSE_APPLICATION_REQUEST_UPDATE, data)
