import { useSignalConsumer } from '@hooks'
import { propOr } from 'ramda'
import { useIntl } from 'react-intl'
import ReactPlayer from 'react-player'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function VideoModal({ signal }) {
  const { formatMessage: msg } = useIntl()
  useSignalConsumer(signal)

  function closeModal() {
    signal.value = {
      state: false,
      item: null,
    }
  }

  return (
    <Modal isOpen={signal.value.state} size='lg'>
      <ModalHeader toggle={closeModal}>{propOr('', 'original_name', signal.value.item)}</ModalHeader>
      <ModalBody>
        <ReactPlayer url={signal.value.item?.original_url} controls muted width='600' height='300' />
      </ModalBody>
      <ModalFooter className='border-0'>
        <Button color='flat-secondary' className='mr-auto' onClick={closeModal}>
          {msg({ id: 'close' })}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default VideoModal
