import { InputField } from '@components/HookForm'
import { LoadingButton } from '@components/Loaders'
import { equals, length, prop } from 'ramda'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Form, FormGroup, Label } from 'reactstrap'

export function UserProfileResetPasswordForm({ handleSubmit, register, control, errors, watch }) {
  const { formatMessage: msg } = useIntl()
  const [password, setPassword] = useState('')

  function handlePasswordChange(value) {
    setPassword(value)
  }

  const newPassword = watch('new_password', false)
  const newPasswordMatch = watch('new_password_match', false)

  const pswrd = newPassword && length(newPassword) >= 5
  const pswrdMatch = newPasswordMatch && length(newPasswordMatch)
  const isEqual = newPassword && newPasswordMatch && equals(newPassword, newPasswordMatch)
  const isMatchPassword = (pswrd && pswrdMatch && isEqual) || false

  return (
    <Form className='auth-login-form' onSubmit={handleSubmit}>
      <FormGroup>
        <div className='d-flex justify-content-between'>
          <Label className='form-label' for='login-password'>
            {msg({ id: 'Please enter new password (minimum 5 characters)' })}
          </Label>
        </div>

        <Controller
          name='new_password'
          control={control}
          rules={{ required: true }}
          defaultValue={password}
          render={({ field }) => (
            <InputField
              {...field}
              type='text'
              id='new_password'
              handleChange={handlePasswordChange}
              register={register}
              error={prop('new_password', errors)}
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <div className='d-flex justify-content-between'>
          <Label className='form-label' for='login-password'>
            {msg({ id: 'Repeat your password' })}
          </Label>
        </div>

        <Controller
          name='new_password_match'
          control={control}
          rules={{ required: true }}
          defaultValue={password}
          render={({ field }) => (
            <InputField
              {...field}
              type='text'
              id='new_password_match'
              handleChange={handlePasswordChange}
              register={register}
              error={prop('new_password_match', errors)}
            />
          )}
        />
      </FormGroup>

      <LoadingButton
        spinnerSize='sm'
        type='submit'
        onClick={handleSubmit}
        color='primary'
        block
        disabled={!isMatchPassword}
      >
        {msg({ id: 'Change password' })}
      </LoadingButton>
    </Form>
  )
}
