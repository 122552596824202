export const GLOBAL_STATE = 'Account'

export const ACCOUNT = 'ACCOUNT' as const
export const ACCOUNT_CREATE = 'ACCOUNT_CREATE' as const
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE' as const
export const ACCOUNT_LIST = 'ACCOUNT_LIST' as const
export const ACCOUNT_PASSWORD_RESET = 'ACCOUNT_PASSWORD_RESET' as const
export const ACCOUNT_REQUIRED_ACTIONS = 'ACCOUNT_REQUIRED_ACTIONS' as const
export const ACCOUNT_PROFILE_PIC = 'ACCOUNT_PROFILE_PIC' as const
export const ACCOUNT_PROFILE_UPDATE = 'ACCOUNT_PROFILE_UPDATE' as const
export const ACCOUNT_TYPES = 'ACCOUNT_TYPES' as const
