export const GLOBAL_STATE = 'AssessmentEC'

export const ASSESSMENT_EC = 'ASSESSMENT_EC' as const
export const ASSESSMENT_EC_LIST = 'ASSESSMENT_EC_LIST' as const
export const ASSESSMENT_EC_CREATE = 'ASSESSMENT_EC_CREATE' as const
export const ASSESSMENT_EC_ALL = 'ASSESSMENT_EC_ALL' as const
export const ASSESSMENT_EC_DELETE = 'ASSESSMENT_EC_DELETE' as const
export const ASSESSMENT_EC_STATUS = 'ASSESSMENT_EC_STATUS' as const
export const ASSESSMENT_EC_UPDATE = 'ASSESSMENT_EC_UPDATE' as const
export const ASSESSMENT_EC_OUTCOME_REPORT = 'ASSESSMENT_EC_OUTCOME_REPORT' as const
export const ASSESSMENT_EC_PAPER = 'ASSESSMENT_EC_PAPER' as const
