export const USER_GUEST = '/user/guest/' as const
export const USER_GUEST_FULL_TIME = `${USER_GUEST}full-time` as const
export const USER_GUEST_AVAILABILITY_DOCUMENTS = `${USER_GUEST}availability_documents` as const
export const USER_GUEST_VOUCHER = `${USER_GUEST}create/voucher` as const
export const USER_GUEST_REFERENCE = `${USER_GUEST}create/reference` as const
export const USER_GUEST_INVOICE = `${USER_GUEST}create/invoice` as const
export const USER_GUEST_ALL = `${USER_GUEST}all` as const
export const USER_GUEST_PROFILE = `${USER_GUEST}profile` as const
export const USER_GUEST_CHANGE_EDU_LANG = `${USER_GUEST}change/edu_lang` as const
export const USER_GUEST_CHANGE_STATUS = `${USER_GUEST}change/status` as const
export const USER_GUEST_RESET_EXAM = `${USER_GUEST}reset/exam` as const
export const USER_GUEST_RESET_COURSE = `${USER_GUEST}reset/course` as const
export const USER_GUEST_SUBJECT_CERT = `${USER_GUEST}subject/certificate` as const
export const USER_GUEST_APPOINTMENT_SCORE = `${USER_GUEST}appointment/score` as const
export const USER_GUEST_APPOINTMENT_SCORE_IMPORT = `${USER_GUEST}appointment/score/import` as const
export const USER_GUEST_CHANGE_STATUS_TRANSCRIPT = `${USER_GUEST}change/status/transcript` as const
export const USER_GUEST_TEACHING_EXPERIENCE = `${USER_GUEST}teaching_experience` as const
export const USER_GUEST_ACCEPT_SEND_CONTRACT = `${USER_GUEST}accept/send_contract` as const
