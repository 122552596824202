import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const dashboardStudent: ApiFunc<`${typeof API.DASHBOARD_STUDENT}`, 'get'> = data =>
  instance.get(API.DASHBOARD_STUDENT, {
    params: data,
  })
