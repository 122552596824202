import React, { useState } from 'react'
import { Alert, Button, Modal, ModalFooter, ModalHeader } from 'reactstrap'
import { propOr } from 'ramda/es'
import { Info } from 'react-feather'
import { useIntl } from 'react-intl'

function EnrollmentInfo({ item = {}, close, isOpen, proceed = () => {} }) {
  const [isDisabled, setDisabled] = useState(true)
  const infoText = propOr('', 'info_text', item)
  const link = propOr('', 'info_link', item)
  const { formatMessage: msg } = useIntl()

  function handleLink() {
    window.open(link, '_self')
    setDisabled(false)
  }

  return (
    <>
      <Modal toggle={close} isOpen={isOpen} size='lg' backdrop='static'>
        <ModalHeader style={{ display: 'block' }}>
          <Alert color='info' style={{ display: 'block', width: '100%' }}>
            <h5 className='alert-heading m-0'>
              <Info size={22} style={{ marginBottom: '3px', marginRight: '10px' }} />
              {msg({ id: 'You must print and fill in the following document' })}:{' '}
              <Button tag='a' onClick={handleLink}>
                Download link
              </Button>
            </h5>
            <div className='alert-body' style={{ fontSize: '16px' }}>
              {infoText}
            </div>
          </Alert>
        </ModalHeader>
        <ModalFooter>
          <div>
            <span
              className='d-inline-block'
              tabIndex='0'
              data-bs-toggle='tooltip'
              id='proceed_btn'
              title={isDisabled ? msg({ id: 'Please, download the file first' }) : ''}
            >
              <button
                className='btn btn-success'
                onMouseEnter={e => e.stopPropagation()}
                disabled={isDisabled}
                type='button'
                onClick={proceed}
              >
                {msg({ id: 'Proceed' })}
              </button>
            </span>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default EnrollmentInfo
