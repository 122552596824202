// ** React Imports
// ** Vertical Menu Items Array
import { useAppSelector } from '@src/hooks'
import navigation from '@src/navigation/vertical'
import { authUserInfo } from '@store/selector'
import { UserSettingsContext } from '@utility/context/UserSettings'
// ** Third Party Components
import classnames from 'classnames'
import { isEmpty, propOr } from 'ramda'
import { Fragment, useContext, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import styled from 'styled-components'

// ** Vertical Menu Components
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'

const StyledDropdownItem = styled(DropdownItem)`
  width: 100% !important;
  &:hover {
    color: ${({ $isDark }) => ($isDark ? 'var(--white)' : '#6e6b7b')} !important;
    background-color: ${({ $isDark }) => ($isDark ? '#161d31' : '#f1f1f1')} !important;
  }
`

const Sidebar = props => {
  // ** Props
  const { menuCollapsed, routerProps, menu, currentActiveItem, skin } = props

  const history = useHistory()
  const { formatMessage: msg } = useIntl()
  const { education_form, education_form_id, institution, institution_id, setEduForm, setInstitution } =
    useContext(UserSettingsContext)
  const userInfo = useAppSelector(authUserInfo)

  const isDark = skin === 'dark'

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [activeItem, setActiveItem] = useState(null)

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    if (menuCollapsed) {
      setMenuHover(true)
    }
  }

  const renderInstitutionOptions = () =>
    propOr([], 'institutions', userInfo).map(item => (
      <StyledDropdownItem
        key={item.value}
        $isDark={isDark}
        active={institution_id === item.value}
        onClick={() => {
          history.replace({
            search: '',
          })
          setInstitution(item)
        }}
      >
        {propOr('', 'label', item)}
      </StyledDropdownItem>
    ))

  const renderEduFormOptions = () =>
    propOr([], 'education_forms', userInfo).map(item => (
      <StyledDropdownItem
        key={item.value}
        $isDark={isDark}
        active={education_form_id === item.value}
        onClick={() => {
          history.replace({
            search: '',
          })
          setEduForm(item)
        }}
      >
        {propOr('', 'label', item)}
      </StyledDropdownItem>
    ))

  // ** Scroll Menu
  const scrollMenu = container => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else {
      if (shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.remove('d-block')
      }
    }
  }

  return (
    <Fragment>
      <div
        className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
          expanded: menuHover || menuCollapsed === false,
          'menu-light': skin !== 'semi-dark' && skin !== 'dark',
          'menu-dark': skin === 'semi-dark' || skin === 'dark',
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu(props)
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
            {/* Vertical Menu Header Shadow */}
            <div className='shadow-bottom' ref={shadowRef} />
            <div className='main-menu-content' style={{ overflowY: 'auto', height: 'calc(100% - 84px)' }}>
              <div className='d-flex d-md-none edu-add-info mt-1 justify-content-between' style={{ padding: '10px' }}>
                {userInfo?.institutions && !isEmpty(userInfo.institutions) ? (
                  <UncontrolledDropdown tag='div'>
                    <DropdownToggle color='flat-default-blue' caret>
                      {propOr(msg({ id: 'branch' }), 'label', institution)}
                    </DropdownToggle>
                    <DropdownMenu>{renderInstitutionOptions()}</DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}

                {userInfo?.education_forms && !isEmpty(userInfo.education_forms) ? (
                  <UncontrolledDropdown tag='div'>
                    <DropdownToggle color='flat-secondary' caret>
                      {propOr(msg({ id: 'EducationForm' }), 'label', education_form)}
                    </DropdownToggle>
                    <DropdownMenu>{renderEduFormOptions()}</DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}
              </div>
              <hr />
              <ul className='navigation navigation-main'>
                <VerticalNavMenuItems
                  items={navigation}
                  groupActive={groupActive}
                  setGroupActive={setGroupActive}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  groupOpen={groupOpen}
                  setGroupOpen={setGroupOpen}
                  routerProps={routerProps}
                  menuCollapsed={menuCollapsed}
                  menuHover={menuHover}
                  currentActiveItem={currentActiveItem}
                />
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
