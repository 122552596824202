import classnames from 'classnames'
import { CSSProperties, ComponentPropsWithRef, ElementType, ReactNode, forwardRef } from 'react'
import { Badge } from 'reactstrap'

type Props = {
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light-primary'
    | 'light-secondary'
    | 'light-success'
    | 'light-danger'
    | 'light-info'
    | 'light-warning'
    | 'light-dark'
  imgClassName?: string
  initials?: boolean
  size?: 'sm' | 'lg' | 'xl'
  badgeUp?: boolean
  content?: string
  icon?: ReactNode
  badgeColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light-primary'
    | 'light-secondary'
    | 'light-success'
    | 'light-danger'
    | 'light-info'
    | 'light-warning'
    | 'light-dark'
  badgeText?: string
  img?: string
  imgHeight?: string | number
  imgWidth?: string | number
  status?: 'online' | 'offline' | 'away' | 'busy'
  tag?: ElementType
  contentStyles?: CSSProperties
  absoluteIcon?: ReactNode | null
} & ComponentPropsWithRef<'div'>

const Avatar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  // ** Props
  const {
    color,
    className,
    imgClassName = '',
    initials,
    size,
    badgeUp,
    content = '',
    icon,
    badgeColor,
    badgeText,
    img,
    imgHeight,
    imgWidth,
    status,
    tag: Tag = 'div',
    contentStyles,
    absoluteIcon = null,
    ...rest
  } = props

  // ** Function to extract initials from content
  const getInitials = (str: string) => {
    const results: string[] = []
    const wordArray = str.split(' ')
    wordArray.forEach(e => {
      results.push(e[0])
    })
    return results.join('')
  }

  return (
    <Tag
      className={classnames('avatar', {
        [className || '']: className,
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size,
      })}
      ref={ref}
      {...rest}
    >
      {!img || img === undefined ? (
        <>
          <span
            className={classnames('avatar-content', {
              'position-relative': badgeUp,
            })}
            style={{ width: `${imgWidth}px`, height: `${imgHeight}px`, ...contentStyles }}
          >
            {initials ? getInitials(content) : content}
            {icon ? icon : null}
            {badgeUp ? (
              <Badge color={badgeColor ? badgeColor : 'primary'} className='badge-sm badge-up' pill>
                {badgeText ? badgeText : '0'}
              </Badge>
            ) : null}
          </span>
          {absoluteIcon}
        </>
      ) : (
        <>
          <img
            className={classnames({
              [imgClassName]: imgClassName,
            })}
            src={img}
            style={{ objectFit: 'cover', objectPosition: 'center' }}
            alt='avatarImg'
            height={imgHeight && !size ? imgHeight : 32}
            width={imgWidth && !size ? imgWidth : 32}
          />
          {absoluteIcon}
        </>
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size,
          })}
        ></span>
      ) : null}
    </Tag>
  )
})

export default Avatar
