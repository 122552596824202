import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_LIST,
  FEEDBACK_STATUS,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const FEEDBACK = GLOBAL_STATE

export const { reducer: feedbackReducer } = createSlice({
  name: FEEDBACK,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.feedbackAll.pending, state => {
        state[FEEDBACK_ALL].loading = true
      })
      .addCase(actionCreators.feedbackAll.rejected, (state, action) => {
        state[FEEDBACK_ALL].loading = false
        state[FEEDBACK_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackAll.fulfilled, (state, action) => {
        state[FEEDBACK_ALL].loading = false
        state[FEEDBACK_ALL].error = null
        state[FEEDBACK_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.feedbackCreate.pending, state => {
        state[FEEDBACK_CREATE].loading = true
      })
      .addCase(actionCreators.feedbackCreate.rejected, (state, action) => {
        state[FEEDBACK_CREATE].loading = false
        state[FEEDBACK_CREATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackCreate.fulfilled, (state, action) => {
        state[FEEDBACK_CREATE].loading = false
        state[FEEDBACK_CREATE].error = null
        state[FEEDBACK_CREATE].data = action.payload
      })

    builder
      .addCase(actionCreators.feedbackEdit.pending, state => {
        state[FEEDBACK_EDIT].loading = true
      })
      .addCase(actionCreators.feedbackEdit.rejected, (state, action) => {
        state[FEEDBACK_EDIT].loading = false
        state[FEEDBACK_EDIT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackEdit.fulfilled, (state, action) => {
        state[FEEDBACK_EDIT].loading = false
        state[FEEDBACK_EDIT].error = null
        state[FEEDBACK_EDIT].data = action.payload
      })

    builder
      .addCase(actionCreators.feedbackList.pending, state => {
        state[FEEDBACK_LIST].loading = true
      })
      .addCase(actionCreators.feedbackList.rejected, (state, action) => {
        state[FEEDBACK_LIST].loading = false
        state[FEEDBACK_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackList.fulfilled, (state, action) => {
        state[FEEDBACK_LIST].loading = false
        state[FEEDBACK_LIST].error = null
        state[FEEDBACK_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.feedbackStatus.pending, state => {
        state[FEEDBACK_STATUS].loading = true
      })
      .addCase(actionCreators.feedbackStatus.rejected, (state, action) => {
        state[FEEDBACK_STATUS].loading = false
        state[FEEDBACK_STATUS].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackStatus.fulfilled, (state, action) => {
        state[FEEDBACK_STATUS].loading = false
        state[FEEDBACK_STATUS].error = null
        state[FEEDBACK_STATUS].data = action.payload
      })

    builder
      .addCase(actionCreators.feedbackTypes.pending, state => {
        state[FEEDBACK_TYPES].loading = true
      })
      .addCase(actionCreators.feedbackTypes.rejected, (state, action) => {
        state[FEEDBACK_TYPES].loading = false
        state[FEEDBACK_TYPES].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackTypes.fulfilled, (state, action) => {
        state[FEEDBACK_TYPES].loading = false
        state[FEEDBACK_TYPES].error = null
        state[FEEDBACK_TYPES].data = action.payload
      })

    builder
      .addCase(actionCreators.feedbackUpdate.pending, state => {
        state[FEEDBACK_UPDATE].loading = true
      })
      .addCase(actionCreators.feedbackUpdate.rejected, (state, action) => {
        state[FEEDBACK_UPDATE].loading = false
        state[FEEDBACK_UPDATE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.feedbackUpdate.fulfilled, (state, action) => {
        state[FEEDBACK_UPDATE].loading = false
        state[FEEDBACK_UPDATE].error = null
        state[FEEDBACK_UPDATE].data = action.payload
      })

    builder.addCase(actionCreators.feedbackAllClear, state => {
      state[FEEDBACK_ALL] = defaultState()
    })

    builder.addCase(actionCreators.feedbackEditClear, state => {
      state[FEEDBACK_EDIT] = defaultState()
    })

    builder.addCase(actionCreators.feedbackListClear, state => {
      state[FEEDBACK_LIST] = defaultState()
    })

    builder.addCase(actionCreators.feedbackTypesClear, state => {
      state[FEEDBACK_TYPES] = defaultState()
    })
  },
})
